import React, { Component } from 'react';
import { Layout, Form, Input, Button, Checkbox, Select, Radio, TreeSelect, notification, message, Upload, Empty, ConfigProvider, Result, Divider } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request'
import * as cont from '../config/constant'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal';
class Registerment extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render(){
         return (<>
       <div className="rement">
           <h4 style={{lineHeight:"60px"}}>北京大学数字人文研究中心</h4>
           <h4>用户注册及隐私协议</h4>
       <p className="p1">本协议适用于您使用“吾与点”古籍自动整理平台网站(以下简称"本网站")所提供的各种工具和服务(以下简称"服务")。</p>
       <p className="p1">1. 总则</p>
       <p className="p1">您通过用户注册页面进入本网站即表示您已经同意自己与本网站签订本协议。本网站可随时执行全权决定更改“条款”。如“条款”有任何变更，将在本网站上相关页面公布更改内容。经修订的“条款”一经在本网站上公布后，立即自动生效。如果不同意所改动的内容，用户可以主动取消已获得的用户服务。如果用户继续享用用户服务，则视为接受“条款”的变动。</p>
       <p className="p1">2. 用户资料及账号安全</p>
       <p className="p1">用户的资料包括您在注册、使用本网站服务过程中、在任何公开信息场合或通过任何电子邮件形式，向本网站或其他用户提供的任何资料。您应对提供的资料负全部责任，而本网站仅作为您在网上发布和刊登用户资料的被动渠道。</p>
       <p className="p1">如果您在本网站注册，您同意：</p>
       <p className="p1">根据本网站所刊载的会员资料的要求，提供关于您的真实、准确、完整和反映当前情况的资料；</p>
       <p className="p1">本网站维持并及时更新会员资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或本网站有合理理由怀疑该资料不真实、不准确、不完整或不能反映当前情况，本网站有权暂停或终止您在本网站的注册身份及资料；</p>
       <p className="p1">在登记过程中，您将填写会员注册账号和密码。您须自行负责对您的会员注册账号和密码保密，且须对您在会员注册账号和密码下发生的所有活动承担责任。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通知本网站。</p>
       <p className="p1">3. 言论规则</p>
       <p className="p1">用户在使用本网站过程中，不得发布侵犯党和国家利益的言论，不得发布泄露国家机密的言论，不得发布有损害他们声誉的言论，不得发布侵犯他人版权的言论等。</p>
       <p className="p1">用户在本网站上所上传的文章所引起的版权问题以及因文章内包含毁谤、诋毁、攻击他人的信息等一切纠纷或后果由用户自行承担，本网站概不负责。</p>
       <p className="p1">本网站尊重他人的任何权利，同时也要求我们的使用者也尊重他人之权利。本网站在适当情况下，有权自行决定删除侵犯他人权利的文章、终止侵害或违反他人权利之使用者的帐号。</p>
       <p className="p1">4. 版权声明</p>
       <p className="p1">本网站对所有开发的或合作开发的服务的知识产权拥有所有权或使用权，这些服务受到适用的知识产权、版权、商标、服务商标、专利或其他法律的保护。用户只能在本网站授权下才能使用这些内容，而不能擅自复制、仿造、篡改这些内容或创造与内容有关的派生产品及其他方式加以利用。</p>
       <p className="p1">5. 隐私保护</p>
       <p className="p1">保护用户隐私是本网站的一项基本政策，本网站保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本网站的非公开内容，但下列情况除外：</p>
       <p className="p1">① 事先获得用户的明确授权；</p>
       <p className="p1">② 根据有关的法律法规要求；</p>
       <p className="p1">③ 按照相关政府主管部门的要求；</p>
       <p className="p1">④ 为维护社会公众的利益；</p>
       <p className="p1">⑤ 为维护本网站的合法权益。</p>
       <p className="p1">本网站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本网站同等的保护用户隐私的责任，则本网站有权将用户的注册资料等提供给该第三方。</p>
       <p className="p1">在不透露单个用户隐私资料的前提下，本网站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
       <p className="p1">6. 免责声明</p>
       <p className="p1">用户明确同意其使用本网站服务所存在的风险将完全由其自己承担；因其使用本网站服务而产生的一切后果也由其自己承担，本网站对用户不承担任何责任。</p>
       
       
       </div>
       </>
    )
    }
}
export default withRouter(Registerment);