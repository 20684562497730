
import React, { useState, useEffect } from 'react'
import { Radio, Popover, Modal, Tabs, Card, Avatar, Row, Button, notification, message } from 'antd'
import convert from '../../../utils/locale_zh_convert'
import { CloseOutlined } from '@ant-design/icons'
import * as cont from './../../../config/constant'
import request from './../../../utils/request'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'

//word下载预览模态框
function TableRowModal(props) {
    const { locale, data, viewpicture, children } = props
    const [visible, setVisible] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [dataSouce, setDataSouce] = useState([])

    const getFiles = () => {
        request(cont.getOCR_TextList, {
            body: {
                task_id: data.id
            }
        }, props).then(data => {
            if (data.code == 0) {
                if (data.list && data.list.length > 0) {
                    let fs = []
                    fs = data.list.filter(t => t.ocr_status === 'Success')
                    setDataSouce(fs)
                } else {
                    notification.error({ description: '未知错误' })
                }
            } else if (data.code == 1) {
                notification.error({ description: data.errmsg ? data.errmsg : '未知错误' })
            }
        })

    }
    const exportPdf = () => {
        // 要导出的dom节点，注意如果使用class控制样式，一定css规则
        const element = document.getElementById('doc');
        // element.style.background = "#FFFFFF";

        html2canvas(element).then((canvas) => {

            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            //一页pdf显示html页面生成的canvas高度;
            var pageHeight = contentWidth / 592.28 * 841.89;
            //未生成pdf的html页面高度
            var leftHeight = contentHeight;
            //页面偏移
            var position = 0;
            //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            var imgWidth = 595.28;
            var imgHeight = 592.28 / contentWidth * contentHeight;

            var pageData = canvas.toDataURL('image/jpeg', 1.0);

            var pdf = new jsPDF('', 'pt', 'a4');

            //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
            //当内容未超过pdf一页显示的范围，无需分页
            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    //避免添加空白页
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }
            pdf.save("处理后文本.pdf");
        })
    };
    const download = (type) => {
        let content = { ...data }

        if (!content) {
            message.error({ top: 300, content: '无内容!' })
            return;
        }

        let ct = content.page.lines.map((i, indx) => { return i.words.map(t => t.text).join('') })
        if (ct.length > 0) {
            if (type === 'pdf') {
                this.exportPdf()
            } else {
                let c = ct.join('\n')
                console.log(c)
                let blob = new Blob([c], {
                    type: "application/msword;charset=utf-8",
                    endings: 'native'
                });
                saveAs(blob, '处理后文本.' + type, { autoBom: true })
            }
        } else {
            message.error({ top: 300, content: '无内容!' })
        }
    }
    useEffect(() => {
        visible2 && getFiles()
    }, [visible2])
    return (
        <>
            <Popover overlayClassName='ss223445' placement="bottom"
                destroyTooltipOnHide={true}
                title={
                    <div>
                        <span style={{ fontWeight: "bold" }}>{convert('请选择导出格式', locale)}</span>
                        <span onClick={() => { setVisible(false) }} style={{ float: 'right' }}><CloseOutlined /></span>
                    </div>}
                visible={visible}
                onVisibleChange={(visible) => {
                    setVisible(visible)
                }}
                content={<div>
                    <Radio.Group  {...props} style={{ width: '100%' }}>
                        <Radio style={{
                            display: 'block',
                            height: '30px',
                            lineHeight: '30px',
                        }} value={1}>
                            {convert('纯文本', locale)}
                        </Radio>
                        {/* <Radio style={{
                            display: 'block',
                            height: '30px',
                            lineHeight: '30px',
                        }} value={2}>
                            <span>{convert('word', locale)}</span>
                            <span onClick={(e) => {
                                setVisible(false)
                                setVisible2(true)
                            }} style={{ marginLeft: '10%' }}><a style={{ color: "#be8e44" }}>{convert('预览', locale)}</a></span>

                        </Radio>

                        <Radio style={{
                            display: 'block',
                            height: '30px',
                            lineHeight: '30px',
                        }} value={3}>
                            {convert('pdf', locale)}
                        </Radio> */}
                        <div className='dibu'>
                            <Button onClick={() => {
                                download('txt')
                                setVisible(false)
                            }}>导出当前页</Button>
                            {/* <Button>导出全部</Button> */}
                        </div>
                    </Radio.Group>
                </div>} trigger="click">
                {children}
            </Popover>
            <Modal
                title={convert('word导出预览', locale)}
                visible={visible2}
                onCancel={() => { setVisible2(false) }}
                footer={null}
                // centered={true}
                width='80%'
                keyboard={false}
                className='add12333'
            >
                <div>
                    <Tabs defaultActiveKey="1" tabPosition='left' tabBarStyle={{ width: '20%' }} style={{ height: 500 }}>
                        {dataSouce && dataSouce.map((item, index) => { //[data],后续加入后台功能后,这里可能变为array,所以这里 暂时 写为[data]
                            let content = item.ocr_content && JSON.parse(item.ocr_content)
                            return <Tabs.TabPane style={{ maxHeight: 500, overflowY: 'auto' }} tab={
                                <Card
                                    cover={<Avatar shape="square" style={{ height: '100%' }}
                                        src={`${cont.getPicture}?id=${item.picture_id}`} />}>
                                </Card>
                            } key={index} >
                                <Avatar onClick={() => {
                                    viewpicture(dataSouce.map(it => {
                                        it.url = `${cont.getPicture}?id=${it.picture_id}`
                                        return it
                                    }), index)
                                }}
                                    shape="square" style={{ height: '70%', width: '70%' }}
                                    src={`${cont.getPicture}?id=${item.picture_id}`} />
                                {
                                    content.page.lines.map((i, indx) => <Row key={indx}><p>{i.words.map(t => t.text)}</p></Row>)
                                }
                            </Tabs.TabPane>
                        }
                        )}
                    </Tabs>
                </div>
            </Modal>
        </>
    )
}

export default (TableRowModal)