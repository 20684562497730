/**
 * 标记
 * @param {*} source      要标记的原文本
 * @param {*} _ner_index  要标记的类型及下标集
 * @returns   返回标记后的文本
 */
export default function doText(source, _ner_index) { 

    let _ner_index_para = _ner_index;
    // let cc = this.state.textcontent.replace((/ο/g), "。")
    let tempContent = source;
    let dd = Array.from(source)
    // tpn 代表地点 liter 代表书名 peop 代表人名 offi 代表官职 date 代表时间
    let arr = { tpn: [], liter: [], peop: [], offi: [], date: [] };
    //段落数组  现在段落的换行符都被去除了，只有一个段落
    // let indexParaArr=[];
    //每个段落
    //存储下标的数组，用于替换
    let indexArr = [];
    if (_ner_index_para && _ner_index_para.length > 0) {
        for (let i = 0; i < _ner_index_para.length; i++) {
            let _ner_index_inner = _ner_index_para[i];

            if (_ner_index_inner && _ner_index_inner.length > 0) {
                for (let j = 0; j < _ner_index_inner.length; j++) {
                    let _ner_index = _ner_index_inner[j];
                    for (let key in _ner_index) {
                        //包含对应key
                        if (arr.hasOwnProperty(key)) {
                            let keyword = "";
                            for (let n = 0; n < _ner_index[key].length; n++) {
                                let aa = [...dd]
                                console.log(aa.length)
                                aa = aa.splice(_ner_index[key][n][0], (_ner_index[key][n][1] - _ner_index[key][n][0]) + 1)
                                keyword = aa.join("");
                                // keyword = tempContent.substring(_ner_index[key][n][0], _ner_index[key][n][1] + 1);
                                let indexItem = {};
                                indexItem.startIndex = _ner_index[key][n][0];
                                indexItem.endIndex = _ner_index[key][n][1];
                                indexItem.keyword = keyword;
                                indexItem.type = key;
                                indexArr.push(indexItem);
                            }
                        }
                    }
                }
            }
        }
    }

    //indexArr 数组进行重新排序
    let content = ''
    if (indexArr && indexArr.length > 0) {
        //倒序--顺序
        indexArr.sort(function (a, b) { return a.startIndex - b.startIndex });
        for (let x = 0; x < dd.length; x++) {
            let targetItem = "";
            for (let i = 0; i < indexArr.length; i++) {
                let tempItem = indexArr[i];
                let tempStartIndex = tempItem.startIndex;
                if (x == tempStartIndex) {
                    targetItem = tempItem;
                    break
                }
            }

            if (targetItem) {
                let tempStartIndex = targetItem.startIndex;
                let tempEndIndex = targetItem.endIndex;
                let tempKeyword = targetItem.keyword;
                let tempType = targetItem.type;

                let indexRange = tempStartIndex + "_" + tempEndIndex;
                //添加span标签
                let replaceKeyWord = instanceWithIndex(tempType, tempKeyword, indexRange);
                content = content + replaceKeyWord;
                let num = tempEndIndex - tempStartIndex;
                x = x + num;
            } else {
                let temp = dd[x];
                content = content + temp + "";
            }
        }
    }
    return tempContent = content;
}
/**
 * 给标记的文字添加span
 * @param {*} name  标记类型名字
 * @param {*} item  要标记文字
 * @param {*} indexRange   文字下标
 * @returns   返回添加后的span信息
 */
export function instanceWithIndex(name, item, indexRange) {
    if (item) {
        return "<span class='allclass " + name + "'  data-index='" + indexRange + "'>" + item + "</span>"
        //return "<span style='border-bottom-style:solid;border-bottom-color:black;border-bottom-width:2px'>" + item + "</span>"
    } else {
        return "<span></span>"
    }
}
/**
 * 删除标签
 * @param {*} _ner_index 后台返回的标记结果
 * @param {*} type       自动标记的标记类型
 * @param {*} intArr     要删除的标记下标
 * @returns   返回更改后的标记结果 要返回给后台保存
 */
export function deleteTagsIndex(_ner_index, type, intArr) {
    if (_ner_index && _ner_index.length > 0) {
        for (let i = 0; i < _ner_index.length; i++) {
            let _ner_index_inner = _ner_index[i];
            if (_ner_index_inner && _ner_index_inner.length > 0) {
                // 删除元素
                for (let j = 0; j < _ner_index_inner.length; j++) {
                    let _ner_index = _ner_index_inner[j];
                    for (let key in _ner_index) {
                        //循环几种标签
                        //包含对应key
                        if (type == key) {
                            for (let k = 0; k < _ner_index[key].length; k++) {
                                let arr = _ner_index[key][k] //[1,2]
                                if (arr[0] == intArr[0] && arr[1] == intArr[1]) {
                                    _ner_index[key].splice(k, 1)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return _ner_index;
}

/**
 * 修改标签
 * @param {*} _ner_index 后台返回的标记结果
 * @param {*} type       自动标记的标记类型
 * @param {*} intArr     要修改的标记下标
 * @param {*} tag_key    要修改的标记类型
 * @returns   返回修改后的标记结果 要返回给后台保存
 */
export function updateTagIndex(_ner_index, type, intArr, tag_key) {
    if (_ner_index && _ner_index.length > 0) {
        for (let i = 0; i < _ner_index.length; i++) {
            let _ner_index_inner = _ner_index[i];
            if (_ner_index_inner && _ner_index_inner.length > 0) {
                // 删除元素
                for (let j = 0; j < _ner_index_inner.length; j++) {
                    let _ner_index = _ner_index_inner[j];
                    for (let key in _ner_index) {
                        //循环几种标签
                        //包含对应key
                        if (type == key) {
                            for (let k = 0; k < _ner_index[key].length; k++) {
                                let arr = _ner_index[key][k] //[1,2]
                                if (arr[0] == intArr[0] && arr[1] == intArr[1]) {
                                    _ner_index[key].splice(k, 1)
                                }
                            }
                        }
                    }
                }
                // 添加元素
                let isExit = IsExitMask(_ner_index_inner, tag_key)
                if (isExit) {
                    for (let j = 0; j < _ner_index_inner.length; j++) {
                        let _ner_index = _ner_index_inner[j];
                        for (let key in _ner_index) {
                            if (tag_key == key) {
                                let a = 0;
                                for (let k = 0; k < _ner_index[key].length; k++) {
                                    if (intArr[0] <= _ner_index[key][k][0]) {
                                        a = k
                                        break
                                    }
                                }
                                _ner_index[key].splice(a, 0, intArr);
                            }
                        }
                    }
                } else {
                    let obj = {}
                    let array = []
                    array.push(intArr);
                    obj[tag_key] = array;
                    _ner_index_inner.push(obj)
                    break
                }
            }
        }
    }
    return _ner_index;
}

/**
 * 添加标签
 * @param {*} _ner_index 后台返回的标记结果
 * @param {*} intArr     要修改的标记下标
 * @param {*} tag_key    要修改的标记类型
 * @returns   返回修改后的标记结果 要返回给后台保存
 */
export function addTagIndex(_ner_index, tag_key, intArr) {
    if (_ner_index && _ner_index.length > 0) {
        for (let i = 0; i < _ner_index.length; i++) {
            let _ner_index_inner = _ner_index[i];
            if (_ner_index_inner && _ner_index_inner.length > 0) {
                // 添加元素
                let isExit = IsExitMask(_ner_index_inner, tag_key)
                if (isExit) {
                    for (let j = 0; j < _ner_index_inner.length; j++) {
                        let _ner_index = _ner_index_inner[j];
                        for (let key in _ner_index) {
                            if (tag_key == key) {
                                let a = 0;
                                for (let k = 0; k < _ner_index[key].length; k++) {
                                    if (intArr[0] <= _ner_index[key][k][0]) {
                                        a = k
                                        break
                                    }
                                }
                                _ner_index[key].splice(a, 0, intArr);
                            }
                        }
                    }
                } else {
                    let obj = {}
                    let array = []
                    array.push(intArr);
                    obj[tag_key] = array;
                    _ner_index_inner.push(obj)
                    break
                }
            }
        }
    }
    return _ner_index;
}

/**
 * 添加标签时，判断标记结果集里是否存在该类型
 * @param {*} _ner_index_inner     后台返回的标记结果
 * @param {*} tag_key              要添加的标记类型
 * @returns  返回  TRUE存在  FALSE 不存在
 */
export function IsExitMask(_ner_index_inner, tag_key) {
    if (_ner_index_inner) {
        for (let j = 0; j < _ner_index_inner.length; j++) {
            let _ner_index = _ner_index_inner[j];
            if (_ner_index.hasOwnProperty(tag_key)) {
                return true;
            }
        }
    }
    return false;
}

/**
 * 点击标签 --实体链接--保存要存的ID
 * @param {*} _ner_index 
 * @param {*} type 
 * @param {*} intArr 
 * @param {*} selectIdValue 
 */
export function saveIndex(_ner_index, type, intArr, selectIdValue){
    if (_ner_index && _ner_index.length > 0) {
        for (let i = 0; i < _ner_index.length; i++) {
            let _ner_index_inner = _ner_index[i];
            if (_ner_index_inner && _ner_index_inner.length > 0) {
                for (let j = 0; j < _ner_index_inner.length; j++) {
                    let _ner_index = _ner_index_inner[j];
                    for (let key in _ner_index) {
                        //循环几种标签
                        //包含对应key
                        if (type == key) {
                            for (let k = 0; k < _ner_index[key].length; k++) {
                                let arr = _ner_index[key][k] //[1,2]
                                if (arr[0] == intArr[0] && arr[1] == intArr[1]) {
                                    arr.splice(2,0,selectIdValue);
                                    console.log(arr);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return _ner_index;
}