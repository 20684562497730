import React from 'react';
import { Route, HashRouter as Router, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from "react-transition-group";

import App from './App'
import Distinguish from '../src/components/home/Distinguish'
import AutomaticDu from '../src/components/home/AutomaticDu'
import AutomaticDuDisposed from '../src/components/home/AutomaticDuDisposed'
// import LoginPage from '../src/components/home/Login'
import NamedEntity from '../src/components/home/NamedEntity'
import NamedEntityDisposed from '../src/components/home/NamedEntityDisposed'
import Collation from '../src/components/home/Collation'
import ViewDetails from '../src/components/home/ViewDetails'
import PersonalCenter from '../src/components/home/PersonalCenter'
import Login from './components/Login'
import VerifyEmail from './components/VerifyEmail'
import Register from './components/Register'
import Registerment from './components/registerment'
import Usehelp from './components/usehelp'
import Personaldata from './components/personaldata'
// import Home from './components/Home'
import RegisterResult from './components/RegisterResult'
import ForgetPwd from './components/ForgetPwd'
import TextContent from './components/ocr/detail/detail'
import TaskList from './components/ocr/tasklist/taskList'
import MyFiles from './components/MyFiles';
import Personal from './components/Personal';

import BiaoDian from '../src/components/home/BiaoDian'
import BiaoDianDisposed from '../src/components/home/BiaoDianDisposed';

import Segmentation  from '../src/components/home/Segmentation';
import SegmentationDisposed from '../src/components/home/SegmentationDisposed';

import RelationExt from '../src/components/home/RelationExt';
import RelationExtDisposed from '../src/components/home/RelationExtDisposed';


const history = createBrowserHistory()

class Routes extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Router history={history} basename={window.BASE_URL}>
                <Route render={({ location }) => {
                    return (
                        <TransitionGroup>
                            <CSSTransition
                                classNames='fade'
                                timeout={300}
                            >
                                <App>
                                    <Switch>
                                        <Route path='/' exact children={(match, ...rest) => {
                                            return (

                                                match && <BiaoDian {...rest} />
                                            )
                                        }} />
                                        <Route path='/register' exact children={(match, ...rest) => {
                                            return (
                                                match && <Register {...rest} />
                                            )
                                        }} />

                                        <Route path='/login' exact children={(match, ...rest) => {
                                            return (
                                                match && <Login {...rest} />

                                            )
                                        }} />
                                         <Route path='/verifyEmail' exact children={(match, ...rest) => {
                                            return (
                                                match && <VerifyEmail {...rest} />

                                            )
                                        }} />
                                        <Route path='/Registerment' exact children={(match, ...rest) => {
                                            return (
                                                match && <Registerment {...rest} />

                                            )
                                        }} />
                                        <Route path='/Usehelp' exact children={(match, ...rest) => {
                                            return (
                                                match && <Usehelp {...rest} />

                                            )
                                        }} />
                                        <Route path='/personaldata' exact children={(match, ...rest) => {
                                            return (
                                                match && <Personaldata {...rest} />

                                            )
                                        }} />
                                        <Route path='/personal' exact children={(match, ...rest) => {
                                            return (
                                                match && <Personal {...rest} />
                                            )
                                        }} />
                                        <Route path='/myfiles' exact children={(match, ...rest) => {
                                            return (
                                                match && <MyFiles {...rest} />
                                            )
                                        }} />
                                        <Route path='/register_result/:result_code' exact children={(match, ...rest) => {
                                            return (
                                                match && <RegisterResult {...rest} />
                                            )
                                        }} />
                                        <Route path='/forgetpwd' exact children={(match, ...rest) => {
                                            return (
                                                match && <ForgetPwd {...rest} />
                                            )
                                        }}></Route >
                                        <Route path="/ocr">
                                            <Switch>
                                                <Route exact path="/ocr/list"
                                                    children={(match, ...rest) => {
                                                        return (
                                                            match && <TaskList {...rest} />
                                                        )
                                                    }}
                                                />
                                                <Route exact path="/ocr/text/:uid"
                                                    children={(match, ...rest) => {
                                                        return (
                                                            match && <TextContent {...rest} />
                                                        )
                                                    }}
                                                />
                                                <Redirect from="/ocr" to="/ocr/list#3" />
                                            </Switch>
                                        </Route>
                                        <Route path='/AutomaticDu' exact children={(match, ...rest) => {
                                            return (
                                                match && <AutomaticDu {...rest} />
                                            )
                                        }} />
                                        <Route path='/AutomaticDuDisposed' exact children={(match, ...rest) => {
                                            return (
                                                match && <AutomaticDuDisposed {...rest} />
                                            )
                                        }} />

                                        <Route path='/BiaoDian' exact children={(match, ...rest) => {
                                            return (
                                                match && <BiaoDian {...rest} />
                                            )
                                        }} />
                                        <Route path='/BiaoDianDisposed' exact children={(match, ...rest) => {
                                            return (
                                                match && <BiaoDianDisposed {...rest} />
                                            )
                                        }} />
                                        <Route path='/Segmentation' exact children={(match, ...rest) => {
                                            return (
                                                match && <Segmentation {...rest} />
                                            )
                                        }} />
                                        <Route path='/SegmentationDisposed' exact children={(match, ...rest) => {
                                            return (
                                                match && <SegmentationDisposed {...rest} />
                                            )
                                        }} />
                                        <Route path='/RelationExt' exact children={(match, ...rest) => {
                                            return (
                                                match && <RelationExt {...rest} />
                                            )
                                        }} />
                                        <Route path='/RelationExtDisposed' exact children={(match, ...rest) => {
                                            return (
                                                match && <RelationExtDisposed {...rest} />
                                            )
                                        }} />
                                        {/* <Route path='/Login' exact children={(match, ...rest) => {
                                            return (
                                                match && <LoginPage {...rest} />
                                            )
                                        }} /> */}
                                        <Route path='/NamedEntity' exact children={(match, ...rest) => {
                                            return (
                                                match && <NamedEntity {...rest} />
                                            )
                                        }} />
                                        <Route path='/NamedEntityDisposed' exact children={(match, ...rest) => {
                                            return (
                                                match && <NamedEntityDisposed {...rest} />
                                            )
                                        }} />
                                        <Route path='/Collation' exact children={(match, ...rest) => {
                                            return (
                                                match && <Collation {...rest} />
                                            )
                                        }} />
                                        <Route path='/ViewDetails' exact children={(match, ...rest) => {
                                            return (
                                                match && <ViewDetails {...rest} />
                                            )
                                        }} />
                                        <Route path='/PersonalCenter' exact children={(match, ...rest) => {
                                            return (
                                                match && <PersonalCenter {...rest} />
                                            )
                                        }} />
                                    </Switch>
                                </App>
                            </CSSTransition>
                        </TransitionGroup>
                    )
                }}>
                </Route>
            </Router >
        )
    }
}
function mapStateToProps(state) {
    return {
        // login: state.login
    }
}
export default connect(mapStateToProps)(Routes)
