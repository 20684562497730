import React, { Component } from 'react';
import { Layout, Input, Button, DatePicker } from 'antd';
import './Collation.css';
import convert from '../../utils/locale_zh_convert';
import dayjs from 'dayjs';
import { withRouter, Link } from 'react-router-dom'
const { Content } = Layout;
const { Search } = Input;
function onChange(date, dateString) {
  console.log(date, dateString);
}

class Collation extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
  }
  render() {
    return (
      <Content>
        <div className='content'>
          <div className='Ctop'>
            <span>{convert("校勘列表", this.locale)}</span>
            <div className='Right'>
              <Button>{convert("全部(20)", this.locale)}</Button>
              <Button>{convert("待审核(7)", this.locale)}</Button>
              <Button>{convert("审核中(10)", this.locale)}</Button>
              <Button>{convert("审核完成(2)", this.locale)}</Button>
              <Button>{convert("驳回(1)", this.locale)}</Button>
              <div>
                <span>{convert("选择日期：", this.locale)}</span>
                <DatePicker onChange={onChange} />
              </div>
              <Search
                placeholder={convert("请输入...", this.locale)}
                size="large"
                onSearch={value => console.log(value)}
              />
            </div>
          </div>
          <div className='Centers'>
            <div className='Headers'>
              <span>{convert("名称", this.locale)}</span>
              <span>{convert("作者", this.locale)}</span>
              <span>{convert("提交人", this.locale)}</span>
              <span>{convert("提交时间", this.locale)}</span>
              <span>{convert("状态", this.locale)}</span>
              <span>{convert("操作", this.locale)}</span>
            </div>
            <div className='BodysList'>
              <div className='Item'>
                <span>
                  <div></div>
                  {convert("李太白文集", this.locale)}
                </span>
                <span>{convert("[東漢] 鄭玄", this.locale)}</span>
                <span>{convert("李华华", this.locale)}</span>
                <span>{convert("07-16 17:18", this.locale)}</span>
                <span style={{ color: '#DD2222' }}>{convert("未审核", this.locale)}</span>
                <Link to='/ViewDetails'><span style={{ color: "#3DA6CF" }}>{convert("审核", this.locale)}</span></Link>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default withRouter(Collation);
