
let initState = {}
export function TodoHandleDataReducer(state = initState, action) {

    switch (action.type) {
        case 'todoHandleAutomaticDu': {
            return action.data.todoHandleData;
        }
        default:
            return state

    }
}