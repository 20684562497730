import React, { createRef, useEffect, useState, useRef } from 'react'
import RcViewer from '@hanyk/rc-viewer';


//图片查看器
function ImgViewer(props) {
    const viewer = useRef()
    const { imgView, onChange } = props
    const { options } = imgView

    let defaultOptions = Object.assign({
        "inline": false,
        "button": true,
        "navbar": true,
        "title": false,
        "toolbar": {
            zoomIn: 4,
            zoomOut: 4,
            oneToOne: 4,
            reset: 4,
            prev: {
                show: 4,
                click: (e) => {
                    prev(e)
                }
            },
            play: {
                show: 0,
                size: 'large',
            },
            next: {
                show: 4,
                click: (e) => {
                    next(e)
                }
            },
            rotateLeft: 4,
            rotateRight: 4,
            flipHorizontal: 4,
            flipVertical: 4,
        },
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "initialViewIndex": 0,
        url(image) {
            return image.src.replace('carimages', 'carimages');
        }
    }, options)

    useEffect(() => {
        if (imgView && imgView.list.length > 0 && !defaultOptions.inline) {
            if (viewer && imgView.show) {
                viewer.current.viewer.show(false);
                viewer.current.viewer.view(defaultOptions.initialViewIndex);
                onChange && onChange(viewer.current.viewer.index)
            }
        }

    }, [imgView])

    const prev = (e) => {
        viewer.current.viewer.prev(false)
        onChange && onChange(viewer.current.viewer.index)
    }
    const next = (e) => {
        viewer.current.viewer.next(false)
        onChange && onChange(viewer.current.viewer.index)
    }

    return (
        imgView.show && <div className="viewer-wrapper">
            <RcViewer options={defaultOptions} className="viewer" ref={viewer}>
                <ul className="images" style={{ display: 'none' }} >
                    {
                        imgView.list.map((item, index) => <li key={index}><img src={item.url} /></li>)
                    }
                </ul>
            </RcViewer>
        </div>
    )
}
export default (ImgViewer)