import React, { Component } from 'react';
import { Layout, Form, Input, Button, Checkbox, Select, Radio, TreeSelect, notification, message, Upload, Empty, ConfigProvider, Result, Divider } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request'
import * as cont from '../config/constant'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal';
import imgurl from "../images/ewm.png"
import reqwest from "reqwest"
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
class Usehelp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showstate:1,
            advice:"",
            advice1:"",
            advice2:"",
            advice3:"",
        }
    }
    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
        }
    }
    backadvice = () => {
      reqwest({
        url: '/wyd/feedback',
        method: 'post',
        type: 'json', 
        // contentType: "application/json",
        headers:{"WYD-TOKEN":localStorage.getItem("token")},
        data:{content:this.state.advice,
          phone:this.state.advice1,
          email:this.state.advice3,
          name:this.state.advice2
        },
        success: function (data) {
          if (data.code == 200) {
           message.success("提交成功！")
          }else if(data.code ==500){
            message.error(data.message)
          }
        }, error: (err) => {
       
            let mes = JSON.parse(err.response)
message.error(mes.message)
         
        }
      })
    }
    render(){
         return (<>
       <div className="rement2">
           <div className="leftnav">
<p className="p1 p2" style={{fontWeight:"800",color:"black"}}>目录</p>
<p className="p1 p2" style={{fontWeight:"800",color:"black"}} onClick={()=>{this.setState({showstate:1})}}>一、系统说明</p>
<p className="p1 p2" onClick={() =>{this.setState({showstate:1});this.scrollToAnchor("bbgx")}}>◎："吾与点"1.2版更新说明</p>
<p className="p1 p2" onClick={() =>{this.setState({showstate:1});this.scrollToAnchor("gnsm")}}>◎：功能说明</p>
<p className="p1 p2" onClick={() =>{this.setState({showstate:1});this.scrollToAnchor("lxwm")}}>◎：联系我们</p>
<p className="p1 p2" style={{fontWeight:"800",color:"black"}} onClick={()=>{this.setState({showstate:2})}}>二、问题反馈</p>
<p className="p1 p2" onClick={()=>{this.setState({showstate:2})}}>Q1：句读反应速度慢。</p>
<p className="p1 p2" onClick={()=>{this.setState({showstate:2})}}>Q2：繁体目前对于繁体是如何处理的</p>
{/* <p className="p1 p2" onClick={()=>{this.setState({showstate:2})}}>Q3：为什么标点结果没有《》 和“” ？</p> */}
<p className="p1 p2" onClick={()=>{this.setState({showstate:3})}}>Q3：我要反馈</p>
           </div>
           <div className="rightcont" style={{display:this.state.showstate == 1 ? "block" :"none"}}>
           <p className="p1" style={{fontSize:"20px",fontWeight:"800",textAlign:"center"}}>系统说明</p>
<p className="p1" style={{fontSize:"18px",fontWeight:"800"}} id="bbgx">"吾与点"1.2版更新说明</p>
<p className="p1">与上一版相比，吾与点1.2更新如下：</p>
<p className="p1">1. 增加了对古文的现代标点。目前的标点模型支持七种现代标点（，；、：。！？）。目前，断句模型在古文语料上的F值达到94.90%，在古典诗文和词文的F值分别达到99.39%和97.61%。标点模型在古文混合语料上的F值接近90%。</p>
<p className="p1">2. 增加了自动分词功能。“吾与点”自动分词模型具备跨时代分词能力，可实现对上古、中古、近古以及现代汉语文本的分词，在四个时代的平均F值达到98%。由于算法使用的训练语料的分词粒度比较细，因此分词模型的分词粒度也比较细。</p>
<p className="p1">3. 专名识别模型尚未更新。目前在史部文献的封闭测试F值为98%，在明清小说上测试F值为80%左右。</p>

<p className="p1">1. 用户可通过复制文本到待处理文本框中，或者上传文件（上传文件支持 UTF-8 编码的 txt 纯文本）两种方式上传文本，单次复制字数限制为8000字，单次上传文件大小不超过2万字。待文本粘贴完成、文件上传完成之后，即可点击“运行”按钮进行句读；</p>
<p className="p1">注意：为保证预测结果的正确率，请不要附带一些无意义的特殊字符如空格、换行符、tab符等；当有多个用户同时使用时，需要排队等待。</p>
<p className="p1">2. 平台返回的断句符号与正文字体颜色一致。用户可对断句结果进行修改，修改之后的断句符显示为红色。</p>

<p className="p1" style={{fontSize:"18px",fontWeight:"800"}} id="gnsm">功能说明</p>
<p className="p1"><b>1. 提交待测试文本：</b>将文本复制到待处理文本框中，点击“运行”按钮进行测试。为保证预测结果的正确率，请不要附带一些无意义的特殊字符如空格、换行符、tab符等；当有多个用户同时使用时，需要排队等待。</p>
<p className="p1"><b>2. 测试文本的字数和频次限制：</b>为减轻服务器压力，用户单次测试字数不超过800字，一日测试次数不超过24次。如有特殊需求，请与我们邮件联系。</p>
<p className="p1"><b>3. 自动标点/自动句读：</b>平台默认返回自动标点后的结果，用户可点击"标点/句读"在自动标点和自动句读间切换。目前，吾与点提供七种现代标点的自动标注（，；、：。！？），自动句读的断句符号为"〇"。</p>
<p className="p1"><b>4. 自动分词：</b>词与词之间以空格作为分隔符，对两字词或多字词标以下划线。</p>
<p className="p1"><b>5. 专名识别：</b>"吾与点"目前识别的实体类型包括：人名、地名、年代、职官、书名。平台返回的专名识别结果以不同颜色的文字高亮来区分不同类型的实体。</p>
<p className="p1"><b>6. 乱码问题的解决：</b>为避免由于超字符集出现的乱码情况，您的计算机必须具备一个支持这些字的Unicode字体（尤其是支持「CJK扩展A区」至「D区」中的字）， 否则您的计算机将无法显示这些字。此外，您的计算机必须全面支持统一码（Unicode），包括所谓代理字（Surrogates）。</p>

<p className="p1" style={{fontSize:"18px",fontWeight:"800"}} id="lxwm">联系我们</p>
<p className="p1">“吾与点古文智能处理平台”目前提供小规模文本的开放测试，欢迎您反馈测试意见和功能建议。如有更多需求，也欢迎您与我们联系：</p>
<p className="p1"><a style={{color: "#096dd9"}} target="blank" href="mailto:gdhc@pku.edu.cn">E-mail: gdhc@pku.edu.cn</a>   </p>
<p className="p1">北京大学数字人文研究中心</p>

<p className="p1"><a style={{color: "#096dd9"}} target="blank" href="http://digitalhumanities.pku.edu.cn/">Website：http://dhlab.pku.edu.cn/</a> </p>
<p className="p1"><a style={{color: "#096dd9"}} target="blank" href="http://digitalhumanities.pku.edu.cn/">&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;http://pkudh.org</a> </p>
<p className="p1">更多问题，请扫码关注"北京大学数字人文开放实验室"公众号。后台回复"吾与点用户体验"，可进入用户交流群。</p>
<p><img style={{float: "left", marginLeft: "120px"}} src={imgurl}></img></p>

</div>

<div className="rightcont" style={{display:this.state.showstate == 2 ? "block" :"none"}}>
<p className="p1" style={{fontSize:"18px",fontWeight:"800",textAlign:"center"}}>问题反馈</p>
<p className="p1" style={{fontSize:"18px",fontWeight:"800"}}>“吾与点古籍自动整理平台”目前处于公开测试阶段，若您在平台使用过程中遇见任何问题，欢迎向我们反馈。</p>
<p className="p1" style={{fontSize:"18px",fontWeight:"800"}}>Q1：句读反应速度慢。</p>
<p className="p1">A1：句读模型基于预训练语言模型，模型参数较多，并且为保证断句的准确率，我们在处理长文本时，采用滑动窗口的方式也会降低句读的反应速度。</p>
<p className="p1" style={{fontSize:"18px",fontWeight:"800"}}>Q2：繁体目前对于繁体是如何处理的</p>



<p className="p1">A2：“吾与点古籍自动整理平台”句读功能现可处理简体和繁体文本。目前后台句读模型基于简体预训练语言模型，对繁体文本的处理需要经过后台进行繁简转换。后续将会发布基于繁体古文预训练语言模型的句读模型，专门用于处理繁体文本。</p>
{/* <p className="p1" style={{fontSize:"18px",fontWeight:"800"}}>Q3：为什么标点结果没有《》 和“” ？</p>
<p className="p1">A3：目前“吾与点古籍自动整理平台”仅提供八种现代汉语标点  。，、？！；：…… ，《》和“”的标注比一般点号的标注难度大，暂不提供。</p> */}



</div>


<div className="rightcont" style={{display:this.state.showstate == 3 ? "block" :"none"}}>
<Form {...layout}>








<Form.Item
              label={convert('反馈内容', this.locale)}
              name="content"
              rules={[{ message: convert('请输入您的问题或建议!', this.locale), required: true }]}
            >
               <textarea style={{    width: "100%",outline:"none",
    border: "1px solid #d9d9d9"}}
   
    placeholder={convert('请输入您的您的问题或建议', this.locale)}
    value={this.state.advice} 
    onChange={e=>{this.setState({advice:e.target.value})}}
    /> 
                </Form.Item>
                <Form.Item
              label={convert('手机号', this.locale)}
              name="phone"
              rules={[{ message: convert('请输入手机!', this.locale), required: true }]}
            >
               <Input placeholder={convert('请输入您的手机号',
                this.locale)}
                value={this.state.advice1} 
    onChange={e=>{this.setState({advice1:e.target.value})}}
                /> 
                </Form.Item>
                <Form.Item
              label={convert('姓名', this.locale)}
              name="name"
            
              rules={[{ message: convert('请输入姓名!', this.locale), required: false }]}
            >
               <Input placeholder={convert('请输入您的姓名',
                this.locale)}
                value={this.state.advice2} 
    onChange={e=>{this.setState({advice2:e.target.value})}}
                /> 
                </Form.Item>
                <Form.Item
              label={convert('邮箱', this.locale)}
              name="e-mail"
              rules={[{ message: convert('请输入邮箱!', this.locale), required: false }]}
            >
               <Input placeholder={convert('请输入您的邮箱',
                this.locale)}
                value={this.state.advice3} 
    onChange={e=>{this.setState({advice3:e.target.value})}}
                /> 
                </Form.Item>






                </Form>
                <Button className='pri-btn'
                type="primary" 
                style={{ width: '80px', marginBottom: '30px' }}
                onClick={()=>{this.backadvice()}}
                >{
                convert('提交反馈', this.locale)
              }</Button>
                {/* <Button className='pri-btn' type="" style={{ width: '80px', marginBottom: '30px',marginLeft:"30px" }}>{
                convert('取消', this.locale)
              }</Button> */}
              {/* <a href=>调用邮箱本地发送邮件</a> */}

</div>




       </div>
       </>
    )
    }
}
export default withRouter(Usehelp);