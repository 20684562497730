import React, { useState, useEffect, useRef, createRef } from 'react'

import { Layout, Radio, Row, Col, Card, Typography, Input, Divider, Table, message, Popover, Pagination } from 'antd'
import { withRouter } from 'react-router'
import Icon, { ExportOutlined, CaretRightOutlined, ProfileFilled, UpOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import convert from '../../../utils/locale_zh_convert'
import * as cont from '../../../config/constant'
import './detail.css'
import { connect } from 'react-redux';
import PictureViewer from '../components/imgViewer'
import TableRowModal from '../components/tableRowModal'
import CanvasImg from '../components/canvasImg'
import Choices from '../components/choices'
import ToolBar from '../components/toolbar'
import { useKeyPress, useHistoryTravel } from 'ahooks';

const { Content } = Layout

function Detection(props) {
    // 生成canvas画布
    const locale = dayjs.locale()
    const { files, modifyList, setModifyList, ssssss } = props;

    const { value, setValue, backLength, forwardLength, back, forward, go, reset } = useHistoryTravel([]);
    const [currentViewindex, setCurrentViewindex] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const [imgView, setImgView] = useState({ show: false, list: [] });
    const [selectedLineKeys, setSelectedLineKeys] = useState();
    const [clickWord, setClickWord] = useState();
    const [selectWord, setSelectWord] = useState();//所选中字的坐标
    const [totalCount, setTotalCount] = useState(0);
    const [selectPositionKeys, setSelectPositionKeys] = useState([]);//点击图片的的位置
    // const [modifyList, setModifyList] = useState([]);
    const [similarWordKeys, setSimilarWordKeys] = useState([]); //当前页相同字的坐标
    const [AllsimilarWordKeys, setAllsimilarWordKeys] = useState([])//所有相似的字的坐标
    const [similarModalShow, setSimilarModalShow] = useState(false);
    const [similarEditing, setSimilarEditing] = useState(false);
    const [positionData, setPositionData] = useState([]);//图片文字切割坐标,宽高列表
    const [allpositionData, setallPositionData] = useState([]);//每张图片文字切割坐标,宽高列表
    const [lines, setLines] = useState([]);//
    // const [tmpImg, setTmpImg] = useState();//临时图片,用于裁剪
    const [imgOnLoad, setImgOnLoad] = useState(false);
    const [startEdit, setStartEdit] = useState(false);
    const [exportType, setExportType] = useState(1);

    const [currText, setCurrText] = useState('')
    const multiSelect = useRef(false);
    const initScaleSize = 1
    const [scaleSize, setScaleSize] = useState(initScaleSize)
    //acup 与其方法
    const [acup, setAcup] = useState([]);
    //向集合里保存更改的value
    const handleSave = (text, keys) => {
        const p = { ...currentPage }
        const page = { ...p.page }
        const lines = [...page.lines]
        for (var i = 0; i < keys.length; i++) {
            let v = keys[i]
            let p = v.split('_');
            if (p.length !== 2) {
                return
            }
            const ix = parseInt(p[0])
            const index = parseInt(p[1])
            const targetline = { ...lines[ix] };
            const targetwords = [...targetline.words]
            const targetword = { ...targetwords[index], ...{ text: text } }
            targetwords.splice(index, 1, targetword)
            lines.splice(ix, 1, { ...targetline, ...{ words: targetwords } })
        }

        let newPage = {
            ...currentPage,
            ...{
                page: {
                    lines: lines
                }
            }
        }

        ssssss(newPage);

        // console.log(text);
        setCurrText(text);
        setCurrentPage(newPage)
        setValue([...(value && value), { ...newPage }])
        let pkdsd = files[currentViewindex]
        pkdsd.ocr_content = JSON.stringify(newPage);
        files.splice(currentViewindex, 1, pkdsd);
    }

    function getCurrentPage() {
        if (!value || value.length <= 0) {
            return
        }
        // console.log(value);
        console.log(acup);
        const cup = { ...value[value.length - 1] }
        setCurrentPage(cup)
        setTotalCount(cup && cup.page.lines && cup.page.lines.reduce(
            (total, currentValue, currentIndex, arr) => {
                return total + currentValue.words.length
            }, 0)
        )
        //当前页图片分割后的所有坐标数据
        let posData = []
        let alposData = []
        cup && cup.page.lines && cup.page.lines.forEach((line, index) => {
            line.words.forEach((it, indx) => {
                posData.push(
                    {
                        index: indx,
                        key: index + '_' + indx,
                        col: index + 1,
                        x: it.position[0] - line.position[0],
                        y: it.position[1],
                        width: it.position[2] - it.position[0],
                        height: it.position[3] - it.position[1],
                        wordFlag: it.wordFlag,
                        text: it.text,
                        choices: it.choices,
                        linePosition: line.position,
                        line_offset: line.position[0],
                    }
                )
            })
        })
        for (let i = 0; i < acup.length; i++) {
            acup && acup[i].page.lines && acup[i].page.lines.forEach((line, index) => {
                line.words.forEach((it, indx) => {
                    alposData.push(
                        {
                            index: indx,
                            key: index + '_' + indx,
                            col: index + 1,
                            x: it.position[0] - line.position[0],
                            y: it.position[1],
                            width: it.position[2] - it.position[0],
                            height: it.position[3] - it.position[1],
                            wordFlag: it.wordFlag,
                            text: it.text,
                            choices: it.choices,
                            linePosition: line.position,
                            line_offset: line.position[0],
                            page: i//代表哪一页的数据 第0页 第一页
                        }
                    )
                })
            })
        }
        setallPositionData(alposData)
        console.log(alposData);

        setPositionData(posData)




    }

    function show(fileList) {
        setImgView({
            show: !imgView.show,
            list: [...fileList],
        })
    }

    useEffect(() => {
        if (files && files.length > 0) {
            const p = { ...files[currentViewindex] }
            // let cup = p && JSON.parse(p.ocr_content)
            let cup = p;
            setValue([cup])
            // if (files[currentViewindex].picture_id) {
            //     setImgOnLoad(false)
            //     // const img = document.createElement('img');//创建临时图片,用于裁剪, 
            //     const img = document.getElementById('currentPageImg');//图片容器 ,放置临时图片,用于裁剪 建议用图片容器,不需要每次翻页时创建图片对象
            //     img.src = `${cont.getPicture}?id=${files[currentViewindex].picture_id}`;
            //     img.onload = () => { setImgOnLoad(true) }
            // }

            if (files[currentViewindex]) {
                setImgOnLoad(false)
                // const img = document.createElement('img');//创建临时图片,用于裁剪, 
                const img = document.getElementById('currentPageImg');//图片容器 ,放置临时图片,用于裁剪 建议用图片容器,不需要每次翻页时创建图片对象
                img.src = `${files[currentViewindex].img}`;
                img.onload = () => { setImgOnLoad(true) }
            }
            setCurrText('')
            setSimilarWordKeys([])
            setSimilarModalShow(false)
            setScaleSize(1)
        }
    }, [currentViewindex, files])
    useEffect(() => {
        if (files && files.length > 0) {
            const ap = []
            for (let i = 0; i < files.length; i++) {
                ap[i] = { ...files[i] }
                // acup[i] = JSON.parse(ap[i].ocr_content)
                acup[i] = ap[i];
            }
            console.log(acup);
            setAcup(acup)
        }
    }, [files])
    useEffect(() => {
        if (currentPage && currentPage.page.lines) {
            var data = [...currentPage.page.lines]
            data.forEach((item, index) => {
                item.key = index;
            })
            setLines(data.reverse())
        } else {
            setLines([])
        }
    }, [currentPage])

    useEffect(() => {
        getCurrentPage();
    }, [value])

    useEffect(() => {
        if (selectWord) {
            setSimilarEditing(false)
            let cw = positionData.find(item => item.key === selectWord)
            console.log(cw)

            let acw = allpositionData.find(item => item.key === selectWord)
            console.log(acw)

            if (cw) {
                setCurrText(cw.text)
                let sws = positionData.filter(item => item.text === cw.text).map(item => item.key)
                //选中的字对应的坐标，有多个就显示多个
                console.log(sws);

                let asws = allpositionData.filter(item => item.text === cw.text).map(item => item.key)
                console.log(asws);
                console.log(asws.length);

                setAllsimilarWordKeys(asws)
                //当前页：currentViewindex  0/1
                // AllsimilarWordKeys[currentViewindex]=similarWordKeys
                // AllsimilarWordKeys[currentViewindex]=similarWordKeys
                // setAllsimilarWordKeys(AllsimilarWordKeys)
                // console.log(AllsimilarWordKeys);
                //是否多选
                if (multiSelect.current) {
                    if (similarWordKeys.indexOf(selectWord) === -1) {
                        setSimilarWordKeys([...similarWordKeys, selectWord])
                    }
                } else {
                    setSimilarWordKeys(sws)
                }
                setSimilarModalShow(true)
            } else {
                setCurrText('')
                setSimilarWordKeys([])
                setSimilarModalShow(false)
            }
        } else {
            setCurrText('')
            setSimilarWordKeys([])
            setSimilarModalShow(false)
        }
    }, [selectWord])

    useEffect(() => {
        if (value.length > 1) {
            let cp = value[value.length - 1]
            if (cp) {
                setCurrentPage({ ...cp })
            }
        }
    }, [value])

    useKeyPress(
        ['up', 'down', 'left', 'right'],
        event => {
            if (clickWord) {
                let clickWordIndex = clickWord.split('_');
                let lineIndex = parseInt(clickWordIndex[0])
                let wordIndex = parseInt(clickWordIndex[1])

                if (event.key === 'ArrowDown') {
                    wordIndex = wordIndex + 1
                }
                if (event.key === 'ArrowUp') {
                    wordIndex = wordIndex - 1
                }
                if (event.key === 'ArrowLeft') {
                    lineIndex = lineIndex + 1
                }
                if (event.key === 'ArrowRight') {
                    lineIndex = lineIndex - 1
                }
                let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
                if (!word) {
                    if (event.key === 'ArrowDown') {
                        wordIndex = 0
                        lineIndex = lineIndex + 1
                        let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
                        if (!word) {
                            lineIndex = 0
                        }
                    }

                    if (event.key === 'ArrowUp') {
                        wordIndex = 0
                        lineIndex = lineIndex - 1
                        let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
                        if (words && words.length > 0) {
                            word = words.pop()
                        } else {
                            word = positionData.pop()
                        }
                        lineIndex = word.key.split('_')[0]
                        wordIndex = word.key.split('_')[1]
                    }
                    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                        let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
                        if (words && words.length > 0) {
                            let word = words.pop()
                            wordIndex = word.key.split('_')[1]
                        } else {
                            let word_wei = event.key === 'ArrowRight' ? positionData.pop() : positionData[0]
                            lineIndex = word_wei.key.split('_')[0]
                            let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
                            if (!word) {
                                word = word_wei
                            }
                            wordIndex = word.key.split('_')[1]
                        }

                    }

                }
                setSelectPositionKeys([lineIndex + '_' + wordIndex])
                setSelectedLineKeys([lineIndex])
                setClickWord(lineIndex + '_' + wordIndex)
                setSelectWord(lineIndex + '_' + wordIndex)
                setCurrText(positionData.find(item => item.key === lineIndex + '_' + wordIndex).text)
                setStartEdit(true)
            }
        },
        {
            events: ['keyup'],
            target: document.getElementById("keyboart_")
        }
    )
    useKeyPress(
        ['ctrl.y'],
        event => {
            forward();
        },
        {
            events: ['keyup'],
            target: document.getElementById("keyboart_")
        }
    )
    useKeyPress(
        ['ctrl.z'],
        event => {
            back();
        },
        {
            events: ['keyup'],
            target: document.getElementById("keyboart_")
        }
    )
    useKeyPress(
        ['tab'],
        event => {
            setSelectPositionKeys(['0_0']);
            setSelectedLineKeys([0]);
            setClickWord('0_0');
            setSelectWord('0_0');
            setCurrText(positionData.find(item => item.key === '0_0').text);
            setStartEdit(true);
        },
        {
            events: ['keyup'],
            target: document//.getElementById("keyboart_")
        }
    )

    useKeyPress(
        KeyboardEvent => {
            var agent = navigator.userAgent.toLowerCase();
            var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
            if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {

            }
            if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {

            }
            if (isMac) {
                return KeyboardEvent.key === 'Command'
            } else {
                return KeyboardEvent.key === 'Control'
            }
        },
        event => {
            if (event.type === 'keyup') {
                multiSelect.current = false
            } else {
                multiSelect.current = true
            }
        },
        {
            events: ['keydown', 'keyup'],
            target: document.getElementById("keyboart_")
        }
    )

    const Tub1s = props => <Icon component={cont.Tub1} {...props} />;
    const Tub2s = props => <Icon component={cont.Tub2} {...props} />;
    const text1 = <span>快捷键</span>
    const content1 = (
        <div className='box'>
            <div style={{ display: "flex" }}>
                <p className='xia'>选字</p>
                <div className='jiantou'>
                    <div style={{ textAlign: 'center' }}>
                        <UpOutlined />
                    </div>
                    <div>
                        <LeftOutlined /><DownOutlined /><RightOutlined />
                    </div>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <p>首字</p><span>Tab</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>撤销</p><span>Ctrl</span><span className='jia'>+</span><span>Z</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>恢复</p><span>Ctrl</span><span className='jia'>+</span><span>Y</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>删除</p><span>Delete</span>
            </div>
        </div>
    )

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: '0',
                margin: 0,
                minHeight: 280,
            }}
        >
            <img id="currentPageImg" style={{ display: 'none' }} />
            <Card
                id="keyboart_"
                tabIndex="1"
                title={files[currentViewindex] && files[currentViewindex].name.split('.').slice(0, files[currentViewindex].name.split('.').length - 1)}
                headStyle={{ textAlign: 'left', backgroundColor: 'rgb(84, 125, 166)', height: 40, border: "1px solid #ccc", color: '#fff' }}
                extra={<Row style={{ color: '#FFF', height: '24px' }}>{convert('当前第', locale)}
                    {/* <Radio.Group defaultValue="a" size="large" >
                        <Radio.Button value="a"><Tub1s className="tubiao" /></Radio.Button>
                        <Radio.Button value="b"><Tub2s className="tubiao" /></Radio.Button>
                    </Radio.Group> */}
                    <Pagination
                        responsive={true}
                        pageSize={1} simple
                        current={currentViewindex + 1}
                        onChange={(page, pageSize) => {
                            setCurrentViewindex(page - 1)
                        }}
                        total={files && files.length}
                    />
                    {convert('页', locale)}<Divider type='vertical'></Divider>
                    <TableRowModal
                        locale={locale}
                        onChange={(e) => {
                            setExportType(e.target.value)
                        }}
                        value={exportType}
                        data={currentPage}
                        viewpicture={show}
                        children={<span style={{
                            color: '#fff',
                            // lineHeight: '50px',
                            marginLeft: 10
                        }}>{convert('导出', locale)}&nbsp;<ExportOutlined style={{ fontSize: 20, verticalAlign: 'sub' }} /></span>}
                    /></Row>}>
                <div style={{ margin: '0 5%', textAlign: "left" }} id='content'>
                    <div className='guHanZi' style={{ display: 'flex', overflowX: "scroll" }}>
                        {
                            lines.map((item, indx) => {
                                return <div key={item.key} style={{
                                    display: 'inline-flex',
                                    // float: 'right',
                                    borderLeft: '1px solid #f00',
                                    borderRight: '1px solid #f00',
                                    marginRight: -1,
                                    padding: '0px 10px'
                                }}>
                                    <div>
                                        <CanvasImg
                                            key={item.key}
                                            img={document.getElementById("currentPageImg")}
                                            onload={imgOnLoad}
                                            positionData={positionData.filter(t => t.col === (item.key + 1))}
                                            selectPositionKeys={selectPositionKeys}
                                            sx={item.position[0]}
                                            // sy={item.position[1]}
                                            swidth={item.position[2] - item.position[0]}
                                            // sheight={item.position[3] - item.position[1]}
                                            onSelect={(selectKeys, selectPosition, positionList) => {
                                                if (selectPosition) {
                                                    setSelectedLineKeys([selectPosition.col - 1])
                                                    setClickWord(selectPosition.key)
                                                    setSelectWord(selectPosition.key)
                                                    // setSelectPositionKeys([selectPosition.key])
                                                    setCurrText(selectPosition.text)
                                                    // setStartEdit(true)
                                                    if (multiSelect.current) {
                                                        if (selectPositionKeys.indexOf(selectPosition.key) === -1) {
                                                            console.log(selectPositionKeys)
                                                            setSelectPositionKeys([...selectPositionKeys, selectPosition.key])
                                                        }

                                                    } else {
                                                        setSelectPositionKeys([selectPosition.key])
                                                        setStartEdit(true)
                                                    }
                                                }
                                            }}
                                            current={currentViewindex + 1}
                                            onPageChange={(page) => {
                                                setCurrentViewindex(page - 1)
                                            }}
                                            pageSize={1}
                                            total={files && files.length}
                                            showFooter={false}
                                            initScaleSize={scaleSize}
                                        />
                                    </div>

                                    <div style={{ width: item.position[2] - item.position[0], height: item.position[3] - item.position[1], position: 'relative', zoom: scaleSize }}>
                                        {
                                            item.words && item.words.map((word, indx) =>
                                                <div key={item.key + '_' + indx}
                                                    // className={(item.key + '_' + indx) === clickWord ? 'active' : ''}
                                                    style={{
                                                        left: word.position[0] - item.position[0],
                                                        position: 'absolute',
                                                        top: word.position[1],
                                                        width: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                        height: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                        fontSize: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                        lineHeight: `${word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1]}px`,
                                                        transform: 'scale(0.9, 1)'
                                                    }}><Choices
                                                        key={item.key + '_' + indx}
                                                        startEdit={!multiSelect.current && clickWord === item.key + '_' + indx}
                                                        onClick={
                                                            (e) => {
                                                                setSelectedLineKeys([item.key])
                                                                setClickWord(item.key + '_' + indx)
                                                                setSelectWord(item.key + '_' + indx)
                                                                // setSelectPositionKeys([item.key + '_' + indx])
                                                                setCurrText(word.text)
                                                                // setStartEdit(true)
                                                                if (multiSelect.current) {
                                                                    if (selectPositionKeys.indexOf(item.key + '_' + indx) === -1) {
                                                                        console.log(selectPositionKeys)
                                                                        setSelectPositionKeys([...selectPositionKeys, item.key + '_' + indx])
                                                                    }

                                                                } else {
                                                                    setSelectPositionKeys([item.key + '_' + indx])
                                                                    setStartEdit(true)
                                                                }
                                                            }
                                                        }
                                                        choices={word.choices}
                                                        text={word.text}
                                                        scaleSize={scaleSize}
                                                        editable
                                                        style={{
                                                            width: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                            height: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                            fontSize: word.position[2] - word.position[0] > word.position[3] - word.position[1] ? word.position[2] - word.position[0] : word.position[3] - word.position[1],
                                                        }}
                                                        modified={modifyList.find(m => m.position === item.key + '_' + indx && m.value === word.text && m.change)}
                                                        currKey={item.key + '_' + indx}
                                                        selectKeys={selectPositionKeys}
                                                        // placement="right"
                                                        onSelect={(value) => {
                                                            if (value !== word.text) {
                                                                var mo = modifyList.find(item => item.position === selectedLineKeys[0] + '_' + indx)
                                                                if (mo) {
                                                                    mo.value = value;
                                                                    mo.change = value !== mo.old_value
                                                                } else {
                                                                    mo = {
                                                                        position: selectedLineKeys[0] + '_' + indx,
                                                                        old_value: word.text,
                                                                        value: value,
                                                                        change: value !== word.text,
                                                                        posi_page: currentViewindex
                                                                    }
                                                                    modifyList.push(mo)
                                                                }
                                                                //保存的修改记录
                                                                setModifyList(modifyList)
                                                                // word.text = value;

                                                                handleSave(value, selectPositionKeys)
                                                            }
                                                            setStartEdit(false)
                                                        }} /></div>
                                            )
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div style={{ position: 'fixed', right: 0, top: "50%", }}>
                    <Popover overlayClassName="sad2232" placement="left" title={text1} content={content1} trigger="click">
                        <ProfileFilled style={{ fontSize: "40px" }} />
                    </Popover>
                </div>

                <div className='tongji' id="tongji" style={{ visibility: similarModalShow ? 'unset' : 'hidden' }}>
                    <div className='tjtitle'>
                        <span className='lan'>[{currText}]</span>字集合，本页共<span className='hg'>{similarWordKeys.length}</span>个，共<span className='hg'>{AllsimilarWordKeys.length}</span>个
                    </div>
                    <div>
                        <div className='tjbody' >
                            {
                                similarWordKeys.map(item => {
                                    let it = positionData.find(it => it.key === item)
                                    if (!it) {
                                        return
                                    }
                                    return < CanvasImg
                                        style={{ backgroundColor: selectPositionKeys.indexOf(it.key) > -1 ? '#adbac7' : '' }}
                                        onClick={
                                            () => {
                                                setSelectedLineKeys([it.col - 1])
                                                setClickWord(it.key)
                                                if (multiSelect.current) {
                                                    if (selectPositionKeys.indexOf(it.key) === -1) {
                                                        setSelectPositionKeys([...selectPositionKeys, it.key])
                                                    }
                                                } else {
                                                    setSelectPositionKeys([it.key])
                                                    setStartEdit(true)
                                                }
                                            }
                                        }
                                        key={it.key}
                                        img={document.getElementById("currentPageImg")}
                                        // img={tmpImg}
                                        sx={it.x + it.line_offset} sy={it.y} swidth={it.width} sheight={it.height}
                                        onload={imgOnLoad} />
                                })
                            }

                        </div>
                        <Divider style={{ borderColor: '#abbac7', margin: '10px 0' }}></Divider>
                        <div className='tjbody' >
                            {
                                similarModalShow && <Choices
                                    startEdit={similarEditing}
                                    choices={positionData.reduce((choices, item) => {
                                        if (selectPositionKeys.indexOf(item.key) > -1) {
                                            Array.from(item.choices).forEach(c => {
                                                if (choices.indexOf(c) === -1) {
                                                    choices.push(c)
                                                }
                                            })
                                        }
                                        return choices
                                    }, []).join('')}
                                    onClick={
                                        (e) => {
                                            setSimilarEditing(true)
                                        }
                                    }
                                    text={currText}
                                    scaleSize={scaleSize}
                                    editable
                                    key={similarWordKeys[0]}
                                    currKey={similarWordKeys[0]}
                                    selectKeys={similarWordKeys}
                                    // placement="right"
                                    onSelect={(value) => {
                                        console.log(value);
                                        console.log("AAA");
                                        if (value !== currText) {
                                            selectPositionKeys.forEach(key => {
                                                var mo = modifyList.find(item => item.position === key)
                                                if (mo) {
                                                    mo.value = value;
                                                    mo.change = value !== mo.old_value
                                                } else {
                                                    mo = {
                                                        position: key,
                                                        old_value: currText,
                                                        value: value,
                                                        change: value !== currText,
                                                        posi_page: currentViewindex
                                                    }
                                                    modifyList.push(mo)
                                                }
                                            })

                                            setModifyList(modifyList)
                                            setCurrText(value);
                                            console.log(selectPositionKeys)
                                            handleSave(value, selectPositionKeys)
                                        }
                                    }} />
                            }
                        </div>
                    </div>
                    {/* <div>
                        <Radio.Group onChange={e => {
                            if (e.target.value !== currText) {
                                setCurrText(e.target.value);
                                selectPositionKeys.forEach(key => {
                                    var mo = modifyList.find(item => item.position === key)
                                    if (mo) {
                                        mo.value = e.target.value;
                                        mo.change = e.target.value !== mo.old_value
                                    } else {
                                        mo = {
                                            position: key,
                                            old_value: currText,
                                            value: e.target.value,
                                            change: e.target.value !== currText
                                        }
                                        modifyList.push(mo)
                                    }
                                })

                                setModifyList(modifyList)
                                // word.text = value;
                                console.log(selectPositionKeys)
                                handleSave(e.target.value, selectPositionKeys)
                            }
                        }}>
                            {positionData.reduce((choices, item) => {
                                if (selectPositionKeys.indexOf(item.key) > -1) {
                                    choices = [...choices, ...item.choices]
                                }
                                return Array.from(new Set(choices.join('').split('')))
                            }, []).join('').split('').map((item, index) => <Radio.Button key={index} value={item}>{item}</Radio.Button>)}
                        </Radio.Group>
                    </div> */}
                </div>
            </Card>
            {
                lines.length > 0 && <ToolBar
                    total={
                        files && files.length
                    }
                    pageSize={1}
                    onPageChange={(page) => {
                        setCurrentViewindex(page - 1)
                    }}
                    initScaleSize={initScaleSize}
                    onScale={(value) => {
                        setScaleSize(value)
                    }}
                    onResetScale={() => {
                        setScaleSize(initScaleSize)
                    }}
                    current={currentViewindex + 1}
                    locale={locale}
                />
            }

        </Content >
    )
}
export default Detection