import { combineReducers, createStore, applyMiddleware } from '../../node_modules/redux';
import thunk from 'redux-thunk';
import { loginReducer } from './LoginReducer';
// import { AddrReducer } from './AddrReducer';
// import { StepReducer } from './StepReducer';
// import { TaskListReducer } from './TaskListReducer';
import { TextData } from './TextData';
import { DealTextContentReducer } from './DealTextContentReducer';
import { DuDataReducer } from './DuDataReducer';
import { HandledDataReducer } from './HandledDataReducer';
import { TodoHandleDataReducer } from './TodoHandleDataReducer';
import { HandledDataToNameEntityReducer } from './HandledDataToNameEntityReducer';
import { FileUpdateReducer} from './FileUpdateReducer'
const rootReducer = combineReducers({
    login: loginReducer,
    // addrList: AddrReducer,
    // step: StepReducer,
    // taskList: TaskListReducer,
    text: TextData,
    dealTextContent: DealTextContentReducer,
    duData:DuDataReducer,
    handledData:HandledDataReducer,
    todoHandleData:TodoHandleDataReducer,
    handledDataToNameEntity:HandledDataToNameEntityReducer,
    filedata:FileUpdateReducer,
});

let store = createStore(rootReducer, applyMiddleware(thunk));
export default store;

