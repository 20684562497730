/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  Layout, Popover, Button, Upload, Modal,
  Progress, Input, message, Radio, Row, Menu, Badge, Switch,
  Dropdown, Space, Slider, InputNumber, Col, Tooltip, Select
} from 'antd';
import './SegmentationDisposed.css';
import convert from '../../utils/locale_zh_convert';
import { UploadOutlined, DeleteOutlined, FormOutlined, ArrowLeftOutlined, DownloadOutlined, CopyOutlined, ExportOutlined, ImportOutlined, ArrowRightOutlined, SaveOutlined, FileTextOutlined, MinusOutlined, PlusOutlined, QuestionOutlined, ZoomInOutlined, ZoomOutOutlined, FontSizeOutlined, CaretRightFilled, CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import reqwest from 'reqwest'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import htmlDocx from 'html-docx-js/dist/html-docx';
import 'braft-editor/dist/index.css';
import { set_deal_content } from './../../actions/DealTextContentAction'
import { connect } from 'react-redux';
import { req } from './../../utils/requestUtils'
import back from "../../images/back.svg"
import goback from "../../images/back.png"
import goNameEntity from "../../images/goNamedEntity.png"
import rt1 from "../../images/rt1.svg"
import loading from "../../images/loading.svg"
import PubSub from 'pubsub-js';
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JSZipUtils from 'jszip-utils'
import ImageModule from "docxtemplater-image-module-free";


const ButtonGroup = Button.Group;
const { Content } = Layout;
const content = (
  <div>
    <p>“编辑”模式暂不开放</p>
  </div>
)

class SegmentationDisposed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_type: "fast",
      count: 18,
      duData: this.props.duData,
      show2: true,
      showstate: 1,
      edit: false,
      deskHeight: document.body.clientHeight,
      visible1: false,
      highContent: [],
      showContent:[],
      fildName: ''
    }
    this._deal_textcontent = this.props.duData._deal_textcontent ? this.props.duData._deal_textcontent : [];////处理后文本缓存
    this.sl__deal_textcontent = this.props.duData.sl__deal_textcontent ? this.props.duData.sl__deal_textcontent : [];
    this.fildID = this.props.duData.fildID;
  }

  // 保存
  resave = () => {
    if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
    // let content = document.getElementsByClassName("content")[0].innerText
    let content = document.getElementById("edit").innerText;
    console.log(content);
    let fildName = this.state.fildName;
    let _this = this
    reqwest({
      url: '/wyd/result/' + this.fildID,
      method: 'put',
      type: 'json',
      headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
      data: { body: content, fileTitle: fildName },
      success: function (data) {
        if (data.code == 200) {
          message.success("保存成功！")
        } else {
          message.error("保存失败！")
        }


      }, error: (err) => {

        // message.error(err.status + ':' + err.statusText)
        message.error("保存失败！")

      }
    })
  }
  handleOk = () => {
    this.setState({
      visible1: false
    }, () => {
      this.resave()
    })
  }
  // 导出
  download = (type) => {
    if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
    let content = this.sl__deal_textcontent
    console.log('sl_deal_textcontent is:',this.sl__deal_textcontent);
    console.log('_deal_textcontent is',this._deal_textcontent)
    if (content.length > 0) {
      if (type === 'pdf') {
        this.exportPdf()
      } else if (type === "html") {
        // let c = content.join('\n')
        // let aaa = document.getElementsByClassName("content")[0]
        let aaa = document.getElementById("edit");

        let c = aaa.innerHTML
        let blob = new Blob([c], {
          type: "application/msword;charset=utf-8",
          endings: 'native'
        });
        saveAs(blob, '处理后文本.' + type, { autoBom: true })
      } else if (type === "xml") {
        // let c = content.join('\n')
        // let aaa = document.getElementsByClassName("content")[0]
        let aaa = document.getElementById("edit");

        let c = aaa.innerHTML
        let blob = new Blob([c], {
          type: "application/msword;charset=utf-8",
          endings: 'native'
        });
        saveAs(blob, '处理后文本.' + type, { autoBom: true })

      } else if (type === "word") {
        let aaa = document.getElementById("edit");
        let textcontent = aaa.innerHTML;
        let pagecontent = `<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body style='line-height: 1.8em;letter-spacing:3px'>${textcontent}</body></html>`
        const converted = htmlDocx.asBlob(pagecontent);
        // 用 FielSaver.js里的保存方法 进行输出
        saveAs(converted, '处理后文本.doc');
        // let aaa = document.getElementById("edit");
        // let c = aaa.innerText
        // let blob = new Blob([c], {
        //   type: "application/msword;charset=utf-8",
        //   endings: 'native'
        // });
        // saveAs(blob, '处理后文本.' + type, { autoBom: true })
      } else if (type === "txt") {
        let aaa = document.getElementById("edit");
        let c = aaa.innerText
        let blob = new Blob([c], {
          type: "application/msword;charset=utf-8",
          endings: 'native'
        });
        saveAs(blob, '处理后文本.' + type, { autoBom: true })
      }
    } else {
      message.error({ top: 300, content: '无内容!' })
    }
  }
  // 导出PDF 
  exportPdf = () => {
    // 要导出的dom节点，注意如果使用class控制样式，一定css规则
    // const element = document.getElementsByClassName('content')[0];
    const element = document.getElementById("edit");
    // element.style.background = "#FFFFFF";
    html2canvas(element, {
      useCORS: true,// 是否允许网页中img元素跨域，这个设置需要img元素支持及服务器支持
      allowTaint: true,// 支持跨域
      // width: htmlDom.clientWidth, //dom 原始宽度
      // height: htmlDom.clientHeight,
      scrollY: 0,
      scrollX: 0,
      scale: 2// 这个影响生成图片的清晰度
    }).then(function (canvas) {
      var pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
      var ctx = canvas.getContext("2d"),
        a4w = 190,
        a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
        imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
        renderedHeight = 0;
      while (renderedHeight < canvas.height) {
        var page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
        //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        page
          .getContext("2d")
          .putImageData(
            ctx.getImageData(
              0,
              renderedHeight,
              canvas.width,
              Math.min(imgHeight, canvas.height - renderedHeight)
            ),
            0,
            0
          );
        pdf.setFontSize(20)
        pdf.addImage(
          page.toDataURL("image/jpeg", 1.0),
          "JPEG",
          10,
          10,
          a4w,
          Math.min(a4h, (a4w * page.height) / page.width)
        ); //添加图像到页面，保留10mm边距
        renderedHeight += imgHeight;
        if (renderedHeight < canvas.height) {
          pdf.addPage();
        } //如果后面还有内容，添加一个空页
      }
      pdf.save("处理后文本.pdf");
    })
  };

  // 将句读后的文本跳转到命名实体
  goNameEntityDisposed = (flag) => {
    let editObj = document.getElementById("edit");
    let content = editObj.innerText;
    var obj = {}
    obj.deal_textcontent = content
    obj.fildID = this.fildID;
    //执行专名查询
    obj.executeNamedEntity = flag;
    this.props.dispatch({ type: "initNamedEntity", data: { handledDataToNameEntity: obj } });
    this.props.history.push('/NamedEntity')
  }


  componentDidMount() {
    // this.props.dispatch({ type: "currentPage", data: { currentPage: "AutomaticDuDisposed" } });
    // 用于从我的文件点击修改，将文本数据带过来渲染进行操作；
    console.log('componentDidMount start did');
    if(this._deal_textcontent){
      let init_text = this._deal_textcontent[0];
      console.log('_deal_textcontent',this._deal_textcontent);
      //var reg = new RegExp("ο", "g");
      let str = init_text.replace(new RegExp("〇 ", "g"),'〇');
      //str = str.replace(new RegExp(" 〇", "g"),'〇');
      //str = str.replace(new RegExp("〇", "g"),' 〇 ');
      str = str.replace(/〔/g,' 〔 ')
      str = str.replace(/〕/g,' 〕 ')
      str = str.replace(/」/g,' 」 ')
      str = str.replace(/「/g,' 「 ')
      str = str.replace(/{/g,' { ')
      str = str.replace(/}/g,' } ')
      str = str.replace(/〈/g,' 〈 ')
      str = str.replace(/〉/g,' 〉 ')
      str = str.replace(/\[/g,' [ ')
      str = str.replace(/\]/g,' ] ')
      str = str.replace(/□/g,' □ ')


      str = str.replace(/\s\s+/g,' ')
      console.log('str',str);


      //console.log('str',this.state.showContent);
      const str_words = str.split(' ');
      console.log('str_words is',str_words)
      let keywordsarr = [];
      for(let i = 0; i < str_words.length; i +=1){
        if(str_words[i].length>1){ //问题出在这儿
          keywordsarr.push(str_words[i]);
        }
      }
      str = str.replace(/〔\s+/g,'〔')
      str = str.replace(/\s+〕/g,'〕')
      str = str.replace(/「\s+/g,'「')
      str = str.replace(/\s+」/g,'」')
      str = str.replace(/\[\s+/g,'\[')
      str = str.replace(/\s+]/g,']')
      str = str.replace(/{\s+/g,'{')
      str = str.replace(/\s+}/g,'}')
      str = str.replace(/〈\s+/g,'〈')
      str = str.replace(/\s+〉/g,'〉')
      str = str.replace(/\s+□/g,'□')
      str = str.replace(/□\s+/g,'□')
      this.setState({
        highContent:keywordsarr,
      })
      //str = str.replace(new RegExp(" 。 ", "g"),'。');
      this.setState({
        showContent:[str],
      })
      console.log('两个字以上的字符数组为:',keywordsarr);
    }

    if (this.props.filedata && this.props.filedata.fileContent && this.props.filedata.fileContent.length > 0) {
      this._deal_textcontent = this.props.filedata.fileContent;
      this._deal_textcontent = this._deal_textcontent.split(/[(\r\n)\r\n]+/);
      //this._deal_textcontent = this._deal_textcontent.split(" "); 
      this.fildID = this.props.filedata.fildID;
      console.log('start _deal_textcontent is',this._deal_textcontent)
      this.setState({
        showstate: 1,
        // fildName: this.props.filedata.filename
      })
      let arr = []
      for (let i = 0; i < this._deal_textcontent.length; i++) {
        arr.push([this._deal_textcontent[i].split("")])
      }
      this.sl__deal_textcontent = arr;
      //this.sl__deal_textcontent = this._deal_textcontent.split(" ");
      console.log('start sl__deal_textcontent is',this.sl__deal_textcontent)
    }

    if (this.props.duData && this.props.duData._deal_textcontent && this.props.duData._deal_textcontent.length > 0) {
      this._deal_textcontent = this.props.duData._deal_textcontent ? this.props.duData._deal_textcontent : [];////处理后文本缓存
      this.sl__deal_textcontent = this.props.duData.sl__deal_textcontent ? this.props.duData.sl__deal_textcontent : [];
      this.fildID = this.props.duData.fildID;
    }
    let _this = this;
    PubSub.unsubscribe('gotoNamedEntity');
    PubSub.subscribe("gotoNamedEntity", function (mes, data) {
      _this.goNameEntityDisposed(0);
    });

  }


  //   放大
  increase = () => {
    let count = this.state.count + 2;

    if (count > 48) {
      count = 48;
    }
    // let ziwen = document.getElementsByClassName("content")[0]
    let ziwen = document.getElementById("edit");
    ziwen.style.fontSize = count + "px"
    this.setState({ count });
  };
  //   缩小
  decline = () => {
    let count = this.state.count - 2;
    if (count < 12) {
      count = 12;
    }
    // let ziwen = document.getElementsByClassName("content")[0]
    let ziwen = document.getElementById("edit");
    ziwen.style.fontSize = count + "px"
    this.setState({ count });
  };
  //   编辑
  edit = () => {
    if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
    if (this.sl__deal_textcontent.length == 0) {
      message.warning("无内容！")
      return
    }
    this.setState({ edit: !this.state.edit })
    if (this.state.edit == true) {
      message.success({ top: 300, content: '编辑功能已关闭！' })
    } else {
      message.success({ top: 300, content: '编辑功能已开启！' })
    }
  }
  //   清空
  delet = () => {
    //  let doc = document.getElementsByClassName("content")[0]
    //  doc.innerHTML =""
    // let _this = this
    // this.sl__deal_textcontent =[]
    if (this.sl__deal_textcontent.length == 0) {
      message.warning("无内容！")
      return
    }
    this.setState({
      show2: false
    })
  }
  //   复制
  copyone = () => {
    if (this.sl__deal_textcontent.length == 0) {
      message.warning("无内容！")
      return
    }
    // var Url2 = document.getElementsByClassName("content")[0].innerText
    var Url2 = document.getElementById('edit').innerText
    var oInput = document.createElement('input');
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    message.success({ top: 300, content: '复制成功' })
  }
  //   关闭底部建议字体提示
  closethis = () => {
    let doc = document.getElementsByClassName("closethis")[0]
    doc.style.display = "none"
  }

  insertHtmlAtCaret = (html) => {
    if (this.state.edit !== true) { return }
    if (window.getSelection) {
      // IE9 and non-IE
      var sel = window.getSelection(),
        range = sel.getRangeAt(0);

      if (sel.getRangeAt && sel.rangeCount) {
        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(), node, lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if (document.selection && document.selection.type != "Control") {
      // IE < 9
      document.selection.createRange().pasteHTML(html);
    }
  }



  render() {
    const dowloadMenu =
      <Menu className='woc123'>
        <Menu.Item onClick={() => {
          this.download('txt')
        }}>
          <a target="_blank" rel="noopener noreferrer">
            txt
          </a>
        </Menu.Item>
        <Menu.Item onClick={() => {
          this.download('word')
        }}>
          <a target="_blank" rel="noopener noreferrer" >
            word
          </a>
        </Menu.Item>
        <Menu.Item onClick={() => {
          this.download('pdf')
        }}>
          <a target="_blank" rel="noopener noreferrer">
            pdf
          </a>
        </Menu.Item>
        <Menu.Item onClick={() => {
          this.download('html')
        }}>
          <a target="_blank" rel="noopener noreferrer" >
            html
          </a>
        </Menu.Item>
        {/* <Menu.Item onClick={() => {
          this.download('xml')
        }}>
          <a target="_blank" rel="noopener noreferrer" >
            xml
          </a>
        </Menu.Item> */}
      </Menu >
    //var reg = new RegExp(/[\u4e00-\u9fa5]/g, "g")  //正则表达式，匹配所有汉字
    var reg = new RegExp('秋',"g")

    //正则匹配，分词结果两个字以上的词组加高亮/下划线
    const highLights = (str) => { 
      //keyword = ["人"];
      const keyWordArr = this.state.highContent;//两个字以上的字符数组
      //let str = this._deal_textcontent[0];
      let re;
      let str_del = str;
      keyWordArr.sort((a, b) => b.length - a.length)  //将数组按字符串长度降序排列，匹配时先匹配长的字符串，不然会出现先替换"二十七"后无法替换"二十七年"
      console.log('keyWordArr is ',keyWordArr)
      //var reg2 = new RegExp('秋',"g")
      //str = this.sl__deal_textcontent;
      for(let n = 0; n < keyWordArr.length; n +=1) {
        re = new RegExp(`${keyWordArr[n]}`,"g");
        str_del = str_del.replace(re,`<span style="border-bottom-style:solid;border-bottom-color:black;border-bottom-width:0.5px">${keyWordArr[n]}</span>`); //color:#279EF3;

      }
      console.log('str_del',str_del)
      //let val = `<span style="color:#279EF3;">${keyWordArr[0]}</span>`
      //let str_del = str.replace(reg2, val)
      //str = str
      return str_del
    }

    return (
      <Content>
        <div className='content'>
          <div className='Acon'>
            <div className='Acon_R' id="doc">
              <Space style={{ "width": "100%", "display": "flex", height: "32px", marginBottom: "10px" }} >

                <span style={{ display: "flex" }}>

                  <Tooltip title="重新上传">
                    <button className='goback' onClick={() => { this.props.history.push('/Segmentation') }} title='重新上传'>
                      <img src={goback} alt='重新上传' style={{ width: '100%', height: ' 100%', paddingBottom: '5px' }} />
                    </button>
                  </Tooltip>

                  <Button className="resave wyd_btn"
                    icon={<SaveOutlined />}
                    // onPressEnter={e => {
                    //   if (this.state.textcontent) {
                    //     this.jd()
                    //   } else {
                    //     this.setState({
                    //       visible1: true
                    //     })
                    //   }
                    // }}
                    onClick={() => {
                      if (!localStorage.getItem("token")) {
                        message.warning("暂不支持此功能！"); return
                      };
                      this.setState({
                        visible1: true
                      })
                      // this.resave()
                    }}>
                    {convert('保存', this.locale)}</Button>

                  <Dropdown className="r_btn" overlay={dowloadMenu} placement="bottomCenter">
                    <Button className="wyd_btn" icon={<DownloadOutlined />}>
                      {/* <span style={{marginRight:"5px"}}><img src={out}/></span> */} 导出</Button>
                  </Dropdown>


                  {/* <Button className="resave wyd_btn" style={{border: 'none', background: 'none', color: '#096dd9'}}
                    onClick={() => { this.goNameEntityDisposed(1) }}>
                    {convert('专名识别', this.locale)}
                  </Button> */}
                  
                  



                  {/* <Button className="back wyd_btn" icon={<ArrowLeftOutlined />} onClick={() => { this.props.history.push('/AutomaticDu') }}></Button> */}


                  {/* <Button className="r_btn wyd_btn"
                        icon={<CaretRightOutlined />}
                        style={{ marginLeft: "0" }}
                        type="primary"
                        onClick={() => {
                            this.sl__deal_textcontent = []
                            if (this.state.textcontent) {
                                this.jd()
                            } else {
                                message.error('待处理文本不能为空')
                            }
                        }}> {convert('运行', this.locale)}</Button> */}
                </span>
                <Radio.Group style={{
                  "marginRight": "20px",
                  "float": "left"
                }} buttonStyle='solid' onChange={e => {
                  this.setState({
                    search_type: e.target.value
                  })
                }} value={this.state.search_type}>
                  <Radio.Button style={{
                    display: "none",
                    "background": this.state.search_type == "fast"
                      ? "rgba(113, 166, 197,1)" : "white",
                    "color": this.state.search_type == "fast" ? "white" : "black",
                    "border": "1px solid rgba(113, 166, 197,1)"
                  }} value='fast' ><span style={{ marginRight: "5px" }}>
                      <img src={rt1} /></span>{convert(`自动句读`, this.locale)}</Radio.Button>

                </Radio.Group>

                <ButtonGroup style={{ float: "right" }}>
                  <Tooltip title="缩小">
                    <Button onClick={() => { this.decline() }}
                      style={{
                        width: "30px", fontSize: "10px", background: "none",
                        color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)"
                      }}
                      icon={<FontSizeOutlined />}
                      className="wyd_btn decline mycolor">
                    </Button>

                  </Tooltip>
                  <Tooltip title="放大">
                    <Button className="wyd_btn increase mycolor"
                      icon={<FontSizeOutlined />}
                      style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}
                      onClick={() => { this.increase() }}>
                      {/* <span >-</span> */}
                      {/* <span style={{marginRight:"5px"}}>+</span> */}
                    </Button>
                  </Tooltip>
                  <Tooltip title="复制">
                    <Button className="wyd_btn mycolor"
                      style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}

                      icon={<CopyOutlined />} onClick={() => { this.copyone() }}>
                      {/* <img src={copy}></img> */}
                      {/* <span ></span> */}
                    </Button>
                  </Tooltip>
                  <Tooltip title="清空">
                    <Button className="wyd_btn mycolor"
                      style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)" }}
                      icon={<DeleteOutlined />} onClick={() => { this.delet() }}>
                      {/* <img src={delet2} ></img> */}
                      {/* <span></span> */}
                    </Button>
                  </Tooltip>
                  <Popover placement="bottomRight" content={content} title="分词符添加方法：" >
                    <Button className="wyd_btn mycolor" icon={<FormOutlined />}
                      style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}
                      /*onClick={() => { this.edit() }} */>
                      {/* <img src={edit} ></img>
                                            <span>编辑</span> */}
                    </Button>
                  </Popover>
                </ButtonGroup>
              </Space>

              <div className='Acon_Rco' style={{
                "minHeight": this.state.deskHeight - 300, "overflow": "auto",
                padding: 0, background: "none", border: "1px solid #d9d9d9"
              }}>
                {this._deal_textcontent.length === 0 &&
                  <div className='ziwen'
                    style={{ padding: "4px 11px", color: "#b2b2b2" }}>
                    {convert("处理后文本", this.locale)}
                  </div>}
                {

                  // this.state.deal_textcontent.map((item, index) => <Row key={index}><div dangerouslySetInnerHTML={{ __html: item }}></div></Row>)
                  // this.state.deal_textcontent.map((item, index) => <Row key={index}><div dangerouslySetInnerHTML={{__html:item.replace(reg, "<span style='color:rgba(114,164,190,1)'>ο</span>")}}></div></Row>)
                  <div className="content"
                    id="edit"
                    onDoubleClick={() => { this.insertHtmlAtCaret("<span style='color:red;font-size:" + (this.state.count - 4) + "px'>〇</span>") }}

                    style={{
                      color: "red", lineHeight: "1.8;", fontSize: 18,
                      padding: "4px 11px",
                      display: this.state.show2 == true ? "block" : "none"
                    }}
                    contenteditable={this.state.edit == true ? "true" : "false"} >
                    {/* {this.sl__deal_textcontent.map((item)=>{
                      return "<span>"+(item == " " ? "<span>〇</span>" : item)+"</span>"
                     })} */}
                    {this.state.showContent.map((item,index) =>
                      <span contenteditable='false' style={{ color: "rgba(0, 0, 0, 0.65)", }}
                      dangerouslySetInnerHTML={{ __html: highLights(item)}}></span> 
                      //dangerouslySetInnerHTML={{ __html: item.replace(reg, `<span style="color:#279EF3;">${"秋"}</span>`) }}></span>

                    )
                    
                    //<span dangerouslySetInnerHTML={{ __html: this._deal_textcontent }}>  </span>

                      /*this._deal_textcontent
                      this.state.showstate == 1 ?
                        this.sl__deal_textcontent.map((item, index) =>
                          <p>
                            {
                              item.map((i) =>
                                i.length == 0 ? <br /> :
                                  i.map((n) =>
                                    <span contenteditable='false' style={{ color: "rgba(0, 0, 0, 0.65)", }} //borderBottom:"2px solid #0081EF"
                                      //将句号换成〇，reg是上面定义的一个正则表达式
                                      //dangerouslySetInnerHTML={{ __html: n.replace(reg, "<span style='font-size:" + (this.state.count + 40) + "px'>〇</span>") }}></span>
                                      //dangerouslySetInnerHTML={{ __html: n }}></span>         
                                      dangerouslySetInnerHTML={{ __html: n.replace(reg, "<span style='border-bottom-style:solid;border-bottom-color:black;border-bottom-width:2px'>" +n+"</span>") }}></span>    //solid,dashed,dotted          
                                  )

                              )
                            }
                          </p>
                          //  <span  contenteditable='false' style={{color:"rgba(0, 0, 0, 0.65)"}} 
                          //  dangerouslySetInnerHTML={{__html:item.replace(reg,
                          //  "<span>〇</span>")}}></span>
                        )
                        :
                        this.sl__deal_textcontent.map((item, index) =>
                          <span contenteditable='false' style={{ color: "rgba(0, 0, 0, 0.65)" }}
                            dangerouslySetInnerHTML={{ __html: item }}></span>
                        )
                        */
                    }
                  </div>
                }

              </div>
              {/* <div style={{textAlign: 'left',paddingTop:'8px'}}>
                <p style={{ fontSize: "12px", color: "black" }}>提示：仅支持UTF-8格式文本，游客：建议≤500字/次，12次/天；注册用户：建议≤1000字/次，20次/天；为了更好的体验，建议字体：<a style={{ color: "#096dd9" }} href="http://fonts.jp/hanazono/" target="blank">花园明朝体</a>
                   <span className="closethis" style={{
                    fontSize: "12px",
                    textAlign: "right", lineHeight: "32px"
                  }}>
                    <span className="_closethis" style={{ float: "right" }} onClick={() => { this.closethis() }}>关闭</span>
                  </span> 
                  <span style={{ float: "right", marginRight: "20px" }}>当前字数：{this.sl__deal_textcontent.length}/{!localStorage.getItem("token") ? 500 : 1000}</span>
                </p>
              </div>*/}
            </div>
          </div>
        </div>
        <Modal
          className='uioio12'
          width={400}
          visible={this.state.visible2}
          onOk={this.handleOkM2}
          onCancel={this.handleCancelM2}
          footer={null}
          closable={false}
        >
          <img src={loading}></img>
          {/* <Progress strokeColor="#3AA4CE" type="circle" percent={this.state.progress} 
          format={percent => `${percent / 5} 秒`} /> */}
          {/* <span>句读处理中，预计20s请耐心等待～</span> */}
          {/* <Button>暂停</Button> */}
        </Modal>

        <Modal
          className='saveModal'
          title={<span><span style={{ color: 'red' }}>* </span>保存</span>}
          visible={this.state.visible1}
          destroyOnClose={true}
          onOk={() => {
            this.setState({
              visible1: false
            }, () => {
              this.resave()
            })
          }}
          onCancel={() => {
            this.setState({
              visible1: false
            })
          }}
          okText="提交"
          // cancelText="取消"
          destroyOnClose={true}
          footer={[<Button key="submit" type="primary" onClick={this.handleOk} style={{ background: '#096dd9', marginRight: '200px' }}> 提交</Button>]}
        >
          <Input placeholder={convert('请输入文件名')} defaultValue={(!this.props.duData || !this.props.duData._deal_textcontent)?(this.props.filedata?this.props.filedata.filename:""):""}
            onChange={e => {
              this.setState({
                fildName: e.target.value
              })
            }}></Input>
        </Modal>
      </Content>

    )
  }
}

function mapStateToProps(state) {
  return {
    dealTextContent: state.dealTextContent,
    duData: state.duData,
    filedata: state.filedata,
  }
}
export default withRouter(connect(mapStateToProps)(SegmentationDisposed));