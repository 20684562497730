import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Form, Input, Button, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request';
import * as lacs from '../actions/loginActions';
import * as cont from '../config/constant'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import reqwest from 'reqwest'
const { Content } = Layout;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

class Login extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
    this.state = {
      password: "",
      name: "",
      isModalVisible: false
    }
  }
  handleSubmit = (values) => {
    let _this = this

    // request(cont.login, {
    //   body: values
    // }, _this.props).then(data => {
    //   if (data.code === 0) {
    //     if (!!data.item) {
    //       let obj = data.item
    //       _this.props.dispatch(lacs.loginSuccess(obj));
    //     }
    //     console.log(_this.props.goToUrl)
    //     _this.props.history.push(_this.props.goToUrl ? _this.props.goToUrl : '/')
    //     message.success({ content: convert('登录成功', this.locale), key: 'login', duration: 2 })
    //   } else if (data.code === 1) {
    //     message.error({ content: data.errmsg, key: 'login', duration: 2 })
    //   }
    // })
    reqwest({
      url: '/wyd/auth/login',
      method: 'post',
      type: 'json',
      data: {
        client_id: "wyd",
        password: values.password,
        username: values.username,
        code: values.code
      },
      success: function (data) {
        if (data.code === 200) {
          // this.location.reload()
          localStorage.setItem("token", data.data.token)
          if(!data.data.shows){
            localStorage.setItem("shows", '')
          }else{
            localStorage.setItem("shows", data.data.shows)
          }
          localStorage.setItem("name", data.data.name)
          localStorage.setItem("number", 1)
          localStorage.setItem("id", data.data.id)

          // 判断邮箱是否验证过
          if (data.data.email_verified == false) {
            _this.setState({
              isModalVisible: true,
            })
            Modal.warning({
              title: '提示',
              icon: <ExclamationCircleOutlined />,
              content: '您的邮箱未进行验证，请点击 “确定” 前往验证',
              okText: '确定',
              width: '500px',
              visible: _this.state.isModalVisible,
              onOk: () => {
                _this.setState({
                  isModalVisible: false,
                })
                window.location.href = "/#/VerifyEmail"
              }
              // onCancel: () => {
              //   this.setState({
              //     isModalVisible: false
              //   })
              // },
              // cancelText: "取消",

            });

          } else {
            message.loading({
              content: convert('正在登录', this.locale), key: 'login'
            })
            window.location.href = "/#/AutomaticDu"
          }
          // window.location.href = "/#/AutomaticDu"
          // phone_verified 还未用 
        }
      },
      error: function (err) {
        _this.handleVerify();
        let mes = JSON.parse(err.response)
        message.error(mes.message)
        // window.location.reload()
        // window.location.href ="/cbdb_cooperation#/AutomaticDu"

      }
    })
  }

  handleVerify = () => {
    document.getElementById("img").src = "/wyd/captcha.jpg?" + Math.random();
  }
  componentWillMount() {
    let number = localStorage.getItem("number")
    if (number == 1) {
      localStorage.setItem("number", 2)
      window.location.reload()
    }
  }
  render() {
    return (
      <Content className='bg-e7ecef'>
        <div className='content bg-fff login-box '>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.handleSubmit}
          >
            <span className='login-title'>{convert('用户登录', this.locale)}</span>
            <Form.Item
              label={convert('账号', this.locale)}
              name="username"
              rules={[{ message: convert('请输入您的用户名!', this.locale), required: true }]}
            >
              <Input
                // onChange={handlename}
                // value={this.state.name}
                placeholder={convert('请输入您的用户名', this.locale)} />
            </Form.Item>
            <Form.Item
              label={convert('密码', this.locale)}
              name="password"
              rules={[{ message: convert('请输入您的密码!', this.locale), required: true }]}
            >
              <Input.Password value={this.state.password}
                // onChange={handlepass}
                // value={this.state.password}
                placeholder={convert('请输入您的密码', this.locale)} />
            </Form.Item>
            <Form.Item
              className="verificationCode"
              label={convert('验证码', this.locale)}
              name="code"
              rules={[{ required: true, message: convert('请输入验证码', this.locale) },]}>
              <Input placeholder={convert('请输入验证码', this.locale)} style={{ width: 'auto' }}
                addonAfter={
                  <Button style={{ marginLeft: 20, padding: 0 }} >
                    <img style={{ width: "100%", height: "100%", marginRight: 10 }} id='img' src='/wyd/captcha.jpg' onClick={this.handleVerify} alt='验证码' />
                    <span style={{ fontSize: 12, color: "#787876" }} onClick={this.handleVerify}>点击图片刷新验证码</span>
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ textAlign: 'right', color: '#4e81ae' }} className='mb-10'>
              <Link to='/forgetpwd' onClick={(e) => {
                sessionStorage.setItem('login','/login');
                // e.preventDefault()
                // message.error({ content: convert('功能暂不可用', this.locale), duration: 2 })
              }}><span>忘记密码？/ 找回密码</span></Link>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button className='pri-btn' type="primary" htmlType="submit" style={{ width: '100%', background: "#096dd9", marginBottom: '30px' }}>{
                convert('登录', this.locale)
              }</Button>
              <Link to='/register'><Button className='def-btn' htmlType="submit" style={{ width: '100%' }}>{convert('注册', this.locale)}</Button></Link>
            </Form.Item>
          </Form>
        </div>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    goToUrl: state.login.goToUrl
  }
}
export default withRouter(connect(mapStateToProps)(Login));