import Immutable from 'immutable';

let initState = { uid: '', identify: '', doing: '', content: [], jd_deal_content: {}, mm_deal_content: {} }
export function DealTextContentReducer(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {
        case 'set_deal_content': {
            fromjs = fromjs.set('content', Immutable.fromJS(action.payload.content))
            fromjs = fromjs.set('uid', Immutable.fromJS(action.payload.uid))
            fromjs = fromjs.set('identify', Immutable.fromJS(action.payload.identify))
            if (action.payload.jd_deal_content) {
                fromjs = fromjs.set('jd_deal_content', Immutable.fromJS(action.payload.jd_deal_content))
            } else {
                fromjs = fromjs.set('jd_deal_content', {})
            }
            if (action.payload.mm_deal_content) {
                fromjs = fromjs.set('mm_deal_content', Immutable.fromJS(action.payload.mm_deal_content))
            } else {
                fromjs = fromjs.set('mm_deal_content', {})
            }


            return fromjs.toJS();
        }
        case 'init_deal_content': {
            return initState;
        } 
        case 'todoHandleAutomaticDuFlag': {
            return action.data.flag;
        }
        default:
            return state
            // break;
    }
    // return state;
}