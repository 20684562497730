import React from 'react';

//图标
export const Tub1 = () => (<svg
    width="30px" height="40px">
    <image x="0px" y="0px" width="22px" height="20px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAQAAAAjdQW7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkBhwLICqW5OwmAAABlElEQVQoz33SMUjUYRjH8c979787TLOCcAtCSI9EWhwChaChgqL4z9ZSIBEItQTt0dZktbZINDRYS0PQUAQNRQRRROJWkd6iZ4Wn/3sbvM7/ndlve5/3y+95nh9PSF/bpWnRGQ3/1pg7eq2bD2n0yl4j7lvdAR5y0nODekIaTTjirv9rxKRLIY1WVZSM+ip0ACU9aDprxoo+tZDeNuWbOZkNSQ5d984cqJoy7ZMHIeWLly6KMvWc9x5PnGu/amZdTQQFZf0y02Zz8NtcOiVBRa5xsKqeG2PFhMdoeO+WiI4pix3rRf2GMeSgm5ulwo5h9XqmqurRVmkLDn53wM12h7aS3OdpuxVlggIGfG6ZbIMzS867kHNes9w92SZc9tO4oqjXCw/dEBSsgMaWdyKKmlhoJdow5hqiohKOWkS0QWJExT6HzIso+GjYdK73LzWHJQYMhnRNGT8c8x1Bn2I+AZxwTxkfEmXXDbrsTSusrAuNKsquOG48Qc1+mRnLXSf6Fx41aUldCGlsXcKB1vbbdcpTsPAH4rBxO92Y6T8AAAAASUVORK5CYII=" />
</svg>)
//图标
export const Tub2 = () => (<svg
    width="30px" height="40px">
    <image x="0px" y="0px" width="23px" height="17px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAARCAQAAACcev82AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfkBhwLLgdNuJ3dAAABXUlEQVQoz4XSP2sUURSG8d+d2dn8WcTCwiZWRrazioV2YiEpBBeCQSuFYGEh2ApKioAWgkWK/Qz2YiHBxiJVOrFLAoEYFJmIZhMk7lyLmR3GFc1pDu+5z73nPZcTejDntY7M/+PYoOWJqOusNduGfxy3FIpapWZdDb1YyWvenfD6vDcJHrqNxBU7vsrlcns2ZF7Yr/S+y2VDtnxCdMo5676Bi7oym94LIDpwpsTbJqoSSz6Clx6Y0tdvmOmWeDOmq5wpHHjurnK24KbB3/joJyISuz5UlWAwMtOMUOegY9Xqv80EvPJDonDe0MBji7WZew6beCq3oS1DatNnxxJZbSYZfWQZU96aG7O2YqWhFpr4kUv69RoEuRseuWVYTXLfUYmXmxG1nBbr60NM15Ug9avEJ7VRWHdhzMyy5Ya6Q+h9kTo0Y83uGJyIdbeo5bp26M17atJPHekJG/nds99YKF/7BdPnhAAAAABJRU5ErkJggg==" />
</svg>)
export const SearchSvg = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="28px" height="42px" viewBox="0 0 42 31" enableBackground="new 0 0 42 42" >  <image id="image0" width="42" height="42" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAQAAABvygHQAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBhMDFR3aGMpxAAADR0lEQVRIx62Xz09cVRTHPwzTmSqwKU1gFC0kLmpXWEaRptExmBCrNv5g
mkbsohtlI6gJTWXTdeNS/4YuoAsTDbGp47ILgzHpoi5qAknbtCTFDUMaqObj4r2B4c27AzPx+zb3
nXPf99177s33nNMhTdDJCwzSRy895IBtNlhnjVXu8W/4s44gaYEiJ1hjlTXW2WAbyNFDL30M0scd
lnnYCmk/47zET9zhSeCXz3CC9/iLCo8OQpplnFGW+J2mkQE6GOEMv/EL/zQnPcI5VqgkpwWRZZwh
Fvh7j9X653nnHJMWnzEvOVBvqXcOOO8rLVMiDjtfT7vrOOKcw21RRrRzHkmSZp1uY+N7gzBtNhrX
DmoCuHHAwwlhhyMT38tRKinTcpzjGitsscGfXKNMrgnpr4zSt3v6UxZTNvShd03irh81CUHRT2ox
LXjFjoS706uqVvzCIfP2+LIzVlS9ameAtMMrFiLSs440uCPKrxvsX8W0obWOeFYw62UPJ1yTqp5K
/eyUqpMB0sNeNovHvJhw5LynzgZXM6veNxfwXvQYliwlzFNqpemdrKifBnwlSxn6G8TrA2Cx6Y1c
iGel4RH9GXpZT5hfBX5uSnoDKAZ86/Rm6GIzYe6HNOmtw0OgEPBt0pUhz1bCHCWOdrFFPtPiOiIU
4lkBZNgin7DdBt5qSjoBLAd8ebYybNKVMP8AlJuSluNZaehiE897PPXyzwRv6Yx633zAe9zzaZcf
y6oB0R5TtRz8ZclShhWGGrawyLfALb5s8MxyC4A36Axsf5DVdEHBTr+LpW/aIXPmHHI6lr4frapL
dqesM+83ZiPpO5m6kY9TRXpSHPGB+sfexCziyUj6sODngfjknPK6K2677YrXndrRphe9rT5oUOLP
fK6WTS+kyPR+T49LatV36mxFL4hx4rvJu2RpDRu8z/c8S3XHkuUMN4GdvD/hRMtrRXyzbrzDUTMc
+l+KiUPRuCYoT1ngNMMthmAXw5xmgafxW92/Bpxvs5oKFmgR7aU2gvB6spRMFr29lNsoehf3pqS0
8vxtXmMpqJf1KB6sPI/QzzjdLO/TSBSpHrSRqKG+5XlMNc5c3Rxtt+WpodacHaU7bs6qPN6/OfsP
9MsuU5vmO8MAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDYtMThUMTk6MjE6MjkrMDg6MDDayGrN
AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA2LTE4VDE5OjIxOjI5KzA4OjAwq5XScQAAACB0RVh0
c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVtYjo6RG9jdW1l
bnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAA0Mme1bjMAAAAWdEVY
dFRodW1iOjpJbWFnZTo6V2lkdGgANDKfGq6+AAAAGXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2Uv
cG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTI0NzkyODlVan7qAAAAEnRFWHRUaHVtYjo6
U2l6ZQAxODYwQkLEMd1QAAAAQnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vdG1wL2ltYWdlbGMvaW1n
dmlldzJfN18xNTkyNDcwODc0NjA2Mjk0M185N19bMF3yBKmbAAAAAElFTkSuQmCC" ></image>
    </svg>

);
export const Cbian = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" enable-background="new 0 0 18 18">  <image id="image0" width="18" height="18" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASBAMAAACk4JNkAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAD1BMVEXr7O7h4uT///+kpKSZ
mZmdhDNzAAAAAWJLR0QCZgt8ZAAAAAd0SU1FB+QHHwAdKUaFnJ0AAABASURBVAjXY2AAAgEgZgIx
GA1gLGEHKIvRBMYSdoGyGE1gLGEXKAsoBGUBhTBYCFmEDiRTECYj2YZwAZKrIC4FANSpCsVC6cbs
AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTMwVDE2OjI5OjQxKzA4OjAwayI3PAAAACV0RVh0
ZGF0ZTptb2RpZnkAMjAyMC0wNy0zMFQxNjoyOTo0MSswODowMBp/j4AAAAAgdEVYdHNvZnR3YXJl
AGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdl
cwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMTj6F3NoAAAAFnRFWHRUaHVtYjo6
SW1hZ2U6OldpZHRoADE4Ariz5QAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4A
AAAXdEVYdFRodW1iOjpNVGltZQAxNTk2MDk3NzgxbUubZAAAABF0RVh0VGh1bWI6OlNpemUAMjA0
QkLDZAW4AAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcy
XzlfMTU5MjU1ODU0MDQ4NDYzMThfMjFfWzBdxntslQAAAABJRU5ErkJggg==" ></image>
    </svg>
)

export const Asisss = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 88 88" enableBackground="new 0 0 88 88">  <image id="image0" width="88" height="88" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAmVBMVEX////+/v/i6/LG1+Wq
    xNiOsMt0nsBplrpfkLZWibJNg63////h6vGxydyCqMZViLHm7vSkv9Vhkbf9/v+90eFynb9If6v7
    /P62zN5hkbf///+80OFcjbTg6fF0nsD8/f6du9JLgq3o7/Vvmr3p7/XF1+VUiLH///+nwddIf6v+
    /v+Wts/7/P6Gq8jg6fGVtc/7/P5If6v////OalsxAAAAMXRSTlMADzlgg6K91Of0/QhCea7gRIfI
    Gmi0/SF1yRFtx1CxH4btSbRJatoNgvcZkx2bQpMf3FoPrAAAAAFiS0dEAIgFHUgAAAAHdElNRQfk
    CA4RKSz61PWJAAACJklEQVRYw7WZ55aCMBCFx4piQazYAddedmff/+EWLGshkBC495fnOPMdTpJJ
    phBJVSiWypWqUaubZr1mVCvlUrEg95Ko0Wy1LY7IareajQzYjt3lWHXtjh611x8MOVHDQb+XGjty
    xqygsTNKhZ1MZyrYULPpRJ07X6hiQy3mitil66XhMnvuUoXrr9JhQ62+5Nz1Jj2XebOWYLe7vQ6X
    eb/bJnEPRz1sqOMhnns663OZz6fY783EDcgx37zNsA731RCv8y4rl3knPGea5+FVe8Gp87XO76c2
    fiSONeJNpNVndLv5cJndd+7ce/3z911R7wQD7+2umyxU/eQGi9f7earuJzeYPrmjWcRP+FvNYPZ8
    rZw0fnID58HtjZP9hEoAjx9vd5/zBXP/Dh7kDR7cuJ1h3uBh5wq2U+6NgoEdchvd/MHdMGO8CP0y
    BEioSwBuIcCtANxGgNtBvm4hwFaBiowAc5FKHCexh6JBicoYcJkqGHCFqhhwlQwM2KAaBlyjOgZc
    JxMDNnFg2FLANg923GABAgtp2CUEuzZhFz3saYI9prjnX5ywJO+Q1KCJTLHkSaFQiWAbm8bCEm9c
    qSArbtJu3n9xAyvHRAVkhgB5KSBhJS+uSIe1FXCNEFzrBtZswrXHcA092n5n5ca0IGFNU1ybF9eY
    xrXSCdb8J81xhS/nEv2ABiwEGwkRbohFsLFbKNCg8CrMaPOqxiVuGHvJMoy9SW98/AeliIxiv3sH
    +AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wOC0xNFQwOTo0MTo0NCswODowMDuV7OEAAAAldEVY
    dGRhdGU6bW9kaWZ5ADIwMjAtMDgtMTRUMDk6NDE6NDQrMDg6MDBKyFRdAAAAIHRFWHRzb2Z0d2Fy
    ZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFn
    ZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADg4K9XIIQAAABZ0RVh0VGh1bWI6
    OkltYWdlOjpXaWR0aAA4ONN6CKwAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZO
    AAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NzM2OTMwNJCd1McAAAASdEVYdFRodW1iOjpTaXplADIw
    OTZCQi47iNsAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmll
    dzJfOV8xNTkyNTU4NTQzNzEyNDczOV8xNl9bMF1EoBMiAAAAAElFTkSuQmCC" ></image>
    </svg>)



export const Right = () => (<svg
    width="0.282cm" height="0.494cm" fill="currentColor" >
    <path fillRule="evenodd"
        d="M6.475,5.611 L7.889,7.025 L1.525,13.389 L0.111,11.975 L6.475,5.611 Z" />
    <path fillRule="evenodd"
        d="M6.555,8.015 L8.015,6.555 L1.445,-0.015 L-0.015,1.445 L6.555,8.015 Z" />
</svg>)
export const Textannotation = () => (<svg
    width="0.847cm" height="0.847cm" fill="currentColor">
    <path fillRule="evenodd"
        d="M-0.000,6.000 L-0.000,4.000 L9.000,4.000 L9.000,6.000 L-0.000,6.000 Z" />
    <path fillRule="evenodd"
        d="M11.000,6.000 L11.000,4.000 L14.000,4.000 L14.000,6.000 L11.000,6.000 Z" />
    <path fillRule="evenodd"
        d="M16.000,6.000 L16.000,4.000 L19.000,4.000 L19.000,6.000 L16.000,6.000 Z" />
    <path fillRule="evenodd"
        d="M21.000,6.000 L21.000,4.000 L24.000,4.000 L24.000,6.000 L21.000,6.000 Z" />
    <path fillRule="evenodd"
        d="M11.000,24.000 L11.000,22.000 L14.000,22.000 L14.000,24.000 L11.000,24.000 Z" />
    <path fillRule="evenodd"
        d="M6.000,24.000 L6.000,22.000 L9.000,22.000 L9.000,24.000 L6.000,24.000 Z" />
    <path fillRule="evenodd"
        d="M3.000,24.000 L3.000,22.000 L6.000,22.000 L6.000,24.000 L3.000,24.000 Z" />
    <path fillRule="evenodd"
        d="M16.000,24.000 L16.000,22.000 L19.000,22.000 L19.000,24.000 L16.000,24.000 Z" />
    <path fillRule="evenodd"
        d="M21.000,24.000 L21.000,22.000 L24.000,22.000 L24.000,24.000 L21.000,24.000 Z" />
    <path fillRule="evenodd"
        d="M22.000,5.000 L24.000,5.000 L24.000,8.000 L22.000,8.000 L22.000,5.000 Z" />
    <path fillRule="evenodd"
        d="M22.000,10.000 L24.000,10.000 L24.000,13.000 L22.000,13.000 L22.000,10.000 Z" />
    <path fillRule="evenodd"
        d="M22.000,15.000 L24.000,15.000 L24.000,18.000 L22.000,18.000 L22.000,15.000 Z" />
    <path fillRule="evenodd"
        d="M22.000,20.000 L24.000,20.000 L24.000,23.000 L22.000,23.000 L22.000,20.000 Z" />
    <path fillRule="evenodd"
        d="M3.000,15.000 L5.000,15.000 L5.000,18.000 L3.000,18.000 L3.000,15.000 Z" />
    <path fillRule="evenodd"
        d="M3.000,20.000 L5.000,20.000 L5.000,22.000 L3.000,22.000 L3.000,20.000 Z" />
    <path fillRule="evenodd"
        d="M3.000,10.000 L5.000,10.000 L5.000,13.000 L3.000,13.000 L3.000,10.000 Z" />
    <path fillRule="evenodd"
        d="M3.000,0.000 L5.000,0.000 L5.000,10.000 L3.000,10.000 L3.000,0.000 Z" />
    <path fillRule="evenodd"
        d="M8.000,11.000 L8.000,9.000 L19.000,9.000 L19.000,11.000 L8.000,11.000 Z" />
    <path fillRule="evenodd"
        d="M8.000,15.000 L8.000,13.000 L13.000,13.000 L13.000,15.000 L8.000,15.000 Z" />
    <path fillRule="evenodd"
        d="M8.000,19.000 L8.000,17.000 L17.000,17.000 L17.000,19.000 L8.000,19.000 Z" />
</svg>)
export const Reading = () => (<svg
    width="0.635cm" height="0.952cm" fill="currentColor" className="linl">
    <path fillRule="evenodd"
        d="M17.253,14.096 C17.174,18.831 13.317,22.648 8.563,22.648 C3.759,22.648 0.628,18.861 0.447,13.872 L0.447,13.785 L0.465,13.785 C0.511,13.311 0.897,12.936 1.383,12.936 C1.869,12.936 2.255,13.311 2.301,13.785 L2.319,13.785 L2.319,13.926 C2.319,17.515 5.229,20.426 8.819,20.426 L8.926,20.426 C12.515,20.426 15.425,17.515 15.425,13.926 L15.425,13.785 L15.443,13.785 C15.490,13.311 15.876,12.936 16.362,12.936 C16.879,12.936 17.298,13.355 17.298,13.872 C17.298,13.952 17.271,14.022 17.253,14.096 Z" />
    <path fillRule="evenodd"
        d="M8.087,22.422 L9.809,22.298 L9.809,25.106 L8.087,25.093 L8.087,22.422 Z" />
    <path fillRule="evenodd"
        d="M7.000,25.106 L10.745,25.106 L10.745,26.979 L7.000,26.979 L7.000,25.106 Z" />
    <path fillRule="evenodd" id="woa" strokeWidth="2px" stroke="rgb(255, 255, 255)" fillOpacity="0"
        d="M8.197,3.721 L9.197,3.721 C11.393,3.721 13.000,5.804 13.000,8.000 L13.000,14.000 C13.000,16.196 11.393,18.348 9.197,18.348 L8.197,18.348 C6.001,18.348 4.000,17.196 4.000,15.000 L4.000,8.000 C4.000,5.804 6.001,3.721 8.197,3.721 Z" />
</svg>)
export const Contrast = () => (<svg
    width="0.847cm" height="0.529cm" fill='currentColor'>
    <text fontFamily="Apple Chancery" fontSize="28px" x="0px" y="15.028px">vs</text>
</svg>)
export const Buttoned = () => (<svg
    width="0.882cm" height="0.741cm">
    <path fillRule="evenodd" fill="rgb(60, 162, 243)"
        d="M1.000,-0.000 L24.000,-0.000 C24.552,-0.000 25.000,0.448 25.000,1.000 L25.000,2.000 C25.000,2.552 24.552,3.000 24.000,3.000 L1.000,3.000 C0.448,3.000 -0.000,2.552 -0.000,2.000 L-0.000,1.000 C-0.000,0.448 0.448,-0.000 1.000,-0.000 Z" />
    <path fillRule="evenodd" fill="rgb(60, 162, 243)"
        d="M1.000,9.000 L24.000,9.000 C24.552,9.000 25.000,9.448 25.000,10.000 L25.000,11.000 C25.000,11.552 24.552,12.000 24.000,12.000 L1.000,12.000 C0.448,12.000 -0.000,11.552 -0.000,11.000 L-0.000,10.000 C-0.000,9.448 0.448,9.000 1.000,9.000 Z" />
    <path fillRule="evenodd" fill="rgb(60, 162, 243)"
        d="M1.500,18.000 L23.500,18.000 C24.328,18.000 25.000,18.672 25.000,19.500 C25.000,20.328 24.328,21.000 23.500,21.000 L1.500,21.000 C0.672,21.000 -0.000,20.328 -0.000,19.500 C-0.000,18.672 0.672,18.000 1.500,18.000 Z" />
</svg>)
export const jib = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" >  <image id="image0" width="24" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEADyyuxG/yAAAB8klEQVQ4y52UP2hTURjFf0lejEPgSVFo/oBDsIJFUAjoVhARQZu0iDg5
    iAo62LUgFJyCY6cOBunQDrol2HQXB0UoiDVWAlmS8GzzhDZF/BtyHHJ976XNoL1nuN+F79xz7/3O
    d0NiYKTJc4UMScChToUyrYEM+UioKFeLmtKY4oprTNNalKuiEn6Wn55TWwXZYg9sFdRWbi9hRg1l
    9yX/RVYNzQQJOTV0XEeHIiaEUmr0VfpnbyurOQ0fXW3ohlBWbSVESFDE5SGHGCHKKBEAnjHPGwAO
    c4FJzgIFjnEXpeWaqy6oO0Thm9Z0zVzfVdoizwodYIJJ7rNDvzALPOEdAD8RLgAdVsijVU0JoXvq
    6quHnr4HVuvmtaa1apHhIwAxPjHv1fMxS1S9lWvmKhmLJI5ni9teSpzLnA8QXtADHJJWwCVblL34
    JK+pAbBDlTV6AITAwiFJDfhClCMeIcI4IwBEecBbbgGQwLGoc4oa8BKbMJtmry7rfDCEM6TMNuPU
    LSrkKQEtLnKHCVO4GKcZBWCb9zw3hBwVizKPsOkAJ/jBFmGjsM0mALvsGlWbq8yFaVFiFrjEU2J8
    pkmTJr9xTfSL60ZhlhIt33w3tRww9Cud8+KMNnzz+fZOKRIghAf6ITxo7wM00AFa9J8/gdD/fjN/
    AMdq0mL6qlHyAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTE2VDE2OjE1OjQ0KzA4OjAwPWln
    NwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0xNlQxNjoxNTo0NCswODowMEw034sAAAAgdEVY
    dHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3Vt
    ZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjTYjGyAAAAAFnRF
    WHRUaHVtYjo6SW1hZ2U6OldpZHRoADI0ICOsDQAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdl
    L3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0ODg3MzQ08DEyzwAAABF0RVh0VGh1bWI6
    OlNpemUANzQ4QkKN/OT7AAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxj
    L2ltZ3ZpZXcyXzlfMTU5Mjg3NzU4MjAwNzY1MjFfNTJfWzBduA05vwAAAABJRU5ErkJggg==" ></image>
    </svg>
)
export const zib = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">  <image id="image0" width="24" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEADinHtao8AAABs0lEQVQ4y52UO4tTURSFv6vBKhIERW8SEVRSKIwgabSYShR8JBPEwn8g
    jMZfIFgFppjOLkUQf8DgPIJOrVhHAmokKnGIkFgYpjT4WeRqHnMDOns1u1jrnLXPWecEMlVZitzg
    DGmgS5stnrMzxXCM0Kp9ay6ZM2nSnCVr9q0ajlljesGeFVMyg5QVexZmBWU75veQ/yBvx/KkoGDH
    jMFcQWDGzmiXQAhpcJ2z3OYRcZXmMYvkqXOBb4hVK+JD59dnEStWBbP2TYn33J5j6KLvo/H7ZhMU
    2WQAvGSVq7xlOGPoGA/YBmDAJkWsuxSt9CzWztBXLkSMkvXAD9yiBUBAipCDAKyzwmsAdvhBwCgQ
    OTZw16SId3064fud1/72p21EXdLdAxNeTxFfxzkZdQEk6JKmBWyxygs6/ALgBGVKABzmMhuRIKSb
    oM05WkCbO9zkKKM9ExzhJwDfWWEtEpynjcvWYs5+coYxai6PL24aX12MyW3f7Dgak7jiwEN7BFE0
    MLRn3qwNmzZt+kVdjYl4z3CUVijwhEssRAMP+cTHmaPN8Ib7rLPfB7SPJ/rPn0Dwv9/Mb02490AJ
    t2D8AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTE2VDE2OjE0OjQxKzA4OjAwgJMjrgAAACV0
    RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0xNlQxNjoxNDo0MSswODowMPHOmxIAAAAgdEVYdHNvZnR3
    YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQ
    YWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjTYjGyAAAAAFnRFWHRUaHVt
    Yjo6SW1hZ2U6OldpZHRoADI0ICOsDQAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+y
    Vk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0ODg3MjgxLSzjewAAABF0RVh0VGh1bWI6OlNpemUA
    NjkyQkJPYZBAAAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3Zp
    ZXcyXzlfMTU5MjU1ODU0OTM0MDc5NjZfNjdfWzBd/+7U+gAAAABJRU5ErkJggg==" ></image>
    </svg>
)
export const shib = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" >  <image id="image0" width="24" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEAEQU4N8hBAAABwUlEQVQ4y52Uz0sUYRjHP7POdsiprYPC7AoSKxJZlDIH8WB3hV09dhES
    PEiwEQVBEHSaQ0TQxYMD7qGLF8Ef2x/QoYsEnTSQFmSIEWYhWTepw9a3g+POro1hPs/leV++X57n
    fZ7v8xqiw/ooMkmeLBBQ5R1rfO1AKHZbnmoqa0qDsmRpUNMqqyZPdoyK4QWFcpURJzwjV6EKJwkl
    +XL+Ah+7I1+ldkJBvnKnwhHKyT/KclR7KEcTetkGeKuhNvCGkKNQtjAEHjWe8ZBXHLZ6cYkfNKM4
    xS+uAi49zBnq4xMD1JnhMaUWYZnXbEbxTZ5wDcjwhWGTIhXqwCoLPMLnNwAXGSMX5RpnGYA6FYom
    kywCcECBe/TTBUAXWdIA7PGCSpRrnTmTPNsApAl50yppnSU+ABDwrXW7RR41ZAmhaSVbU+81EvXL
    UsNQA5vvQIpekuwys2SYj94TmARk2QGGeZ4AF03S3IlONoFJlRvsAHV2EzOY3KI7ioeoGnqAw33A
    5Hoi4QKjPKUfgDIf48HdZYVk+4nFlePBIeTJ/afwbmtfCLny2sV3OiGvz7H4zibvVKe8z7FA51jR
    M38Cxv9+M38AC0Cksw7LbycAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDctMTZUMTY6MTc6MDUr
    MDg6MDAbobJEAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA3LTE2VDE2OjE3OjA1KzA4OjAwavwK
    +AAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVt
    Yjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAyNNiM
    bIAAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMjQgI6wNAAAAGXRFWHRUaHVtYjo6TWltZXR5
    cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTQ4ODc0MjXUI7NaAAAAEXRF
    WHRUaHVtYjo6U2l6ZQA2OTlCQkM0f6EAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1w
    L2ltYWdlbGMvaW1ndmlldzJfOV8xNTkyNTU3MDQwMTY4NDY5MF83NF9bMF3xsN7GAAAAAElFTkSu
    QmCC" ></image>
    </svg>
)
export const yued = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="20px" viewBox="0 0 24 20">  <image id="image0" width="24" height="20" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAUCAQAAAA9vDUIAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEAEwPjYg/2AAAAo0lEQVQoz92SMQrCQBBF/0o6NR5BET1FDmQhxHtY5SBeQkT0CJaKndjZ
    51lIdDMThK0EZ7r/32O22AAKSpheCvxLIe9MS1WSAJnd8GDu0hKoKbywAuDGrANf4C68ihNwZepx
    KzRFny1wYWLxthAXA3bAmXEbjwVTMGQP3E36FiwuRM4BqFnGaSNUDhdixJF1O/s8qXC4EJlNwt/+
    1i+TSSKBD09Y1BuXKmp3BwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xNlQxNjoxOTowMysw
    ODowMGa4t80AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTZUMTY6MTk6MDMrMDg6MDAX5Q9x
    AAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1i
    OjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADIw3+Go
    mQAAABZ0RVh0VGh1bWI6OkltYWdlOjpXaWR0aAAyNCAjrA0AAAAZdEVYdFRodW1iOjpNaW1ldHlw
    ZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NDg4NzU0M2rY294AAAARdEVY
    dFRodW1iOjpTaXplADMzM0JC6f6VYwAAAEZ0RVh0VGh1bWI6OlVSSQBmaWxlOi8vL2FwcC90bXAv
    aW1hZ2VsYy9pbWd2aWV3Ml85XzE1OTI4Nzc1ODI0MzE1MTg1Xzg1X1swXXyF8lsAAAAASUVORK5C
    YII=" ></image>
    </svg>
)
export const sandian = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="5px" height="21px" viewBox="0 0 5 21">  <image id="image0" width="5" height="21" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAVBAMAAAB8lzlNAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAElBMVEX///89o/M8o/Q9o/M8
    ovP////iFGwBAAAABHRSTlMAhfnztadGowAAAAFiS0dEAIgFHUgAAAAHdElNRQfkBxEWHSjJ9gT6
    AAAAFklEQVQI12MQchJgMHExYADRuACJagC1jARRxpXrDQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAy
    MC0wNy0xN1QxNDoyOTo0MCswODowMITM4XEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTdU
    MTQ6Mjk6NDArMDg6MDD1kVnNAAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9y
    Z7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJ
    bWFnZTo6SGVpZ2h0ADIxqOaYDwAAABV0RVh0VGh1bWI6OkltYWdlOjpXaWR0aAA13SdbxAAAABl0
    RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0
    OTY3MzgwL4XUHgAAABF0RVh0VGh1bWI6OlNpemUAMTM0QkKWcdCGAAAARnRFWHRUaHVtYjo6VVJJ
    AGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MjU1ODUzOTQ4MjMzMTFfMTNf
    WzBdMIGwyAAAAABJRU5ErkJggg==" ></image>
    </svg>
)
export const jinb = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" >  <image id="image0" width="24" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEACSmI9Dz7AAACFUlEQVQ4y52UTUsUUBSGH6dBoyZ0kdZMptKEtMoSXQhtBFsZfiBEXyCI
    tMiwoEjatGhh/oA2ITQ/IAgVM0ppkZFRUQQlGKUmOoWDiNknGU+LGWfyY5G9By73ct9zzzmc954s
    WYFC6qklSgSI85479DK9gmHGwnabMGaDpYYMWWqjMRN2G86wMvQ6Z+00V1ZZrp3OWrfaod0pK9aQ
    l63CKduT+yAAdVykihngIJ/4CEAee9N5L1LFCJP0JWsIO5t6Pdu7drlTxKtmkDBohbOGkyl12ynm
    +MRfKcKYIYs8ZZ7N7jPsaRE77RYsNGGu2OSoByyxxEpnbLHZYfGZJy1zPlV+wsIA9fSzANxnKy+Z
    YIKnZNPLWizQT32A2tTlIsUU8YibVLKbOdZDH7UBoowCEOQe4xyihREGyFnX4Q3RIBHiABwhn118
    A7bxmGPrOsSJBNOHYaK85QewhU0McngdhywIECcCwBwFVDPOIEcpTkVdjTBxHLAhJYAzDrvkb6c8
    J+7xhFjrDkOpPmCjA9hmTMQC5z3uQ2/Y4oIhL7mUtkkDIsZsyzSu2QfiLS+Iz20y3xprHPOaNZb/
    3bhpeugA8viZzvU720kwxBCfec0QLwDooIfpIHCFV9xOij21ArRyHojSxWUWqGY/rZRBlkl5X6ea
    JT5QyDxfKWeCzZSnI37hHSOcXZb3hj7Qf3/Rfx4CWRsdM38A9LDHEJVTlcMAAAAldEVYdGRhdGU6
    Y3JlYXRlADIwMjAtMDctMTZUMTY6MDk6NDErMDg6MDC043feAAAAJXRFWHRkYXRlOm1vZGlmeQAy
    MDIwLTA3LTE2VDE2OjA5OjQxKzA4OjAwxb7PYgAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFn
    ZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RF
    WHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAyNNiMbIAAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgA
    MjQgI6wNAAAAGXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6
    Ok1UaW1lADE1OTQ4ODY5ODGZxWv/AAAAEXRFWHRUaHVtYjo6U2l6ZQA4MDdCQouSo0AAAABGdEVY
    dFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTkyNTU3MTM2
    OTgzOTk5OF8xN19bMF1puxt4AAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const hen = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">  <image id="image0" width="20" height="20" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxEAMhDrQ1vLAAAAjUlEQVQoz92RsRHCMAxFn3weg0U8iDMBB9QZhJaQY4JoEC/CHBZVsJXK
LfxKepLuJH15GE5huiisuW6eR7hJS5/fsuh1anyxwKB+oTH2lwIJ3SPHiWFygxoKQCh17rGoGGMa
33HNh8lyfsPrVNOhsW6iXZ7qjEJNdqc0bDlC72l3aflvrxdnzv4sy55/AD1iLIkJoXiEAAAAJXRF
WHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTE2VDE2OjUwOjE2KzA4OjAwtObAvAAAACV0RVh0ZGF0ZTpt
b2RpZnkAMjAyMC0wNy0xNlQxNjo1MDoxNiswODowMMW7eAAAAAAgdEVYdHNvZnR3YXJlAGh0dHBz
Oi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7
LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjDf4aiZAAAAFnRFWHRUaHVtYjo6SW1hZ2U6
OldpZHRoADIwJ05oFAAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVY
dFRodW1iOjpNVGltZQAxNTk0ODg5NDE2htjGEAAAABF0RVh0VGh1bWI6OlNpemUAMjg1QkIHEkdg
AAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5
MjU1ODUzOTQ4MjMzMTFfOTRfWzBdbvLutQAAAABJRU5ErkJggg==" ></image>
    </svg>
)

export const shu = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">  <image id="image0" width="20" height="20" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxEAMxmLhNIuAAAAQklEQVQoz2N0/c/AwMDAwLCLkYHBDcrGBlhQlexixK7M7T8TA5GABdVC
3JYz/ifGOAYGBqKtpr5CxtFwpIrCoRCOAKH2H5hBmEURAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIw
LTA3LTE2VDE2OjUxOjI1KzA4OjAw5EO2/AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0xNlQx
Njo1MToyNSswODowMJUeDkAAAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3Jn
vM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6Oklt
YWdlOjpIZWlnaHQAMjDf4aiZAAAAFnRFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADIwJ05oFAAAABl0
RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0
ODg5NDg1zhMs4wAAABF0RVh0VGh1bWI6OlNpemUAMTU4QkK6AHY+AAAARnRFWHRUaHVtYjo6VVJJ
AGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MjU1ODU0OTU5NzE1ODRfMjRf
WzBdkMfYWQAAAABJRU5ErkJggg==" ></image>
    </svg>
)

export const newhen = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" >  <image id="image0" width="20" height="20" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxEBBC/V+420AAAAUUlEQVQoz2N0/c+ACsJ2rWZgcAtlWIUqzMRAJBiZClkYwtAEjkPIP2ji
jP+JNJFoqxldQ9Gt3vaEgcFL5o8luhvR4vRPGMNqBoY/lqNxTZJCACwXEcvtYmE3AAAAJXRFWHRk
YXRlOmNyZWF0ZQAyMDIwLTA3LTE2VDE3OjA0OjQ3KzA4OjAw/1sQ0QAAACV0RVh0ZGF0ZTptb2Rp
ZnkAMjAyMC0wNy0xNlQxNzowNDo0NyswODowMI4GqG0AAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8v
aW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAA
ABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjDf4aiZAAAAFnRFWHRUaHVtYjo6SW1hZ2U6Oldp
ZHRoADIwJ05oFAAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRo
dW1iOjpNVGltZQAxNTk0ODkwMjg3ZPhXRwAAABF0RVh0VGh1bWI6OlNpemUAMTk3QkL7io67AAAA
RnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MjU1
ODUzOTQ1MDc1MzNfOTZfWzBdbjFvCgAAAABJRU5ErkJggg==" ></image>
    </svg>

)

export const newshu = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" >  <image id="image0" width="20" height="20" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
    RQfkBxEBBgU8dibgAAAAMElEQVQoz2N0/c/AwMDAwLCLkYHBDcrGBpgYiATUV8j4n0iFA+nG0XCk
    jhuHQDgCAJVbC+6dd29dAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTE2VDE3OjA2OjA1KzA4
    OjAw6HvfPwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0xNlQxNzowNjowNSswODowMJkmZ4MA
    AAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6
    OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjDf4aiZ
    AAAAFnRFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADIwJ05oFAAAABl0RVh0VGh1bWI6Ok1pbWV0eXBl
    AGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0ODkwMzY1Fbdx0gAAABF0RVh0
    VGh1bWI6OlNpemUAMTMyQkKS/Kw0AAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9p
    bWFnZWxjL2ltZ3ZpZXcyXzlfMTU5Mjg3NzU4ODg2NDcwNzBfNjFfWzBdW44OmQAAAABJRU5ErkJg
    gg==" ></image>
    </svg>

)
export const dayin = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="25px" height="24px" viewBox="0 0 25 24">  <image id="image0" width="25" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAAAAAAq3gkaAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
  RQfkBxIALCeVCm71AAAAm0lEQVQoz2P4DwK9Hq4I4LMeLMYAIj65Ji1aDAPzgmMRMm9dN/9HgN4I
  ZJmtSDIThrUMtjD4s23RopmuiQsXwcDcYD8gefQ/wypX7OACwwSft/8xwXnXrQxg+7YuQgYnwG4C
  y7xHNSgaKhOGzdWvgDJTXPuBbkORAbqtxnUXw61IkAHIMv0ggYwPBMOgFslp2UgyPyNQ3FYGlgEA
  aT3U/XI0tmIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDctMTdUMTY6NDQ6MzkrMDg6MDBmhlkD
  AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA3LTE3VDE2OjQ0OjM5KzA4OjAwF9vhvwAAACB0RVh0
  c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVtYjo6RG9jdW1l
  bnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAyNNiMbIAAAAAWdEVY
  dFRodW1iOjpJbWFnZTo6V2lkdGgAMjVXJJybAAAAGXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2Uv
  cG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTQ5NzU0NzlD54fLAAAAEXRFWHRUaHVtYjo6
  U2l6ZQA1MjRCQttNESMAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMv
  aW1ndmlldzJfOV8xNTkzMzM4NTQyMDQ3NDE0NF81Ml9bMF0HVF/YAAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const prevs = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="35px" height="63px" viewBox="0 0 35 63">  <image id="image0" width="35" height="63" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA/BAMAAACY42yKAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEX///8pPUcmN0EmNUI7
O04nNkImNkIoN0Y7Tk4rQEomNUEpNkImNkIoOUT///8IHQq5AAAADnRSTlMAGWZlDXeAMw0YflF/
LTPyS7cAAAABYktHRACIBR1IAAAAB3RJTUUH5AcREyIrDuq9lwAAAEJJREFUOMtjYCAIhEzQRRjD
0tGFRNMiMBSlCmAoChxVNGwUcS7DUMSddpaBsCosZmGzcVTZsFQWgakMozTBUuaAAADC/DClznPV
ZwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xN1QxMTozNDo0MyswODowMJx7HI8AAAAldEVY
dGRhdGU6bW9kaWZ5ADIwMjAtMDctMTdUMTE6MzQ6NDMrMDg6MDDtJqQzAAAAIHRFWHRzb2Z0d2Fy
ZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFn
ZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADYzIoQ8JwAAABZ0RVh0VGh1bWI6
OkltYWdlOjpXaWR0aAAzNU4/rdoAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZO
AAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NDk1Njg4M0XFd/AAAAARdEVYdFRodW1iOjpTaXplADU1
NUJCR1hDrQAAAEZ0RVh0VGh1bWI6OlVSSQBmaWxlOi8vL2FwcC90bXAvaW1hZ2VsYy9pbWd2aWV3
Ml85XzE1OTI1NTcwNDAxNjg0NjkwXzIyX1swXS49utUAAAAASUVORK5CYII=" ></image>
    </svg>
)

export const nexts = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="35px" height="63px" viewBox="0 0 35 63">  <image id="image0" width="35" height="63" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA/BAMAAACY42yKAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEX///87O04mNUImN0Ep
PUcoN0YmNkInNkI7Tk4mNUErQEooOUQmNkIpNkL///+UiXi3AAAADnRSTlMADWVmGTOAdw1+GC1/
Ubgol+gAAAABYktHRACIBR1IAAAAB3RJTUUH5AcREyQLY9462QAAAEBJREFUOMtjYMAEjMoO6EKs
aSXoQhxl6RjK3DGVsYwqGyHKZqELcR/LJawKi1mYNo4qGrqKsJQTWEoTLGUOJgAAdWpDUZ1uRdQA
AAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDctMTdUMTE6MzY6MTErMDg6MDBH8dP/AAAAJXRFWHRk
YXRlOm1vZGlmeQAyMDIwLTA3LTE3VDExOjM2OjExKzA4OjAwNqxrQwAAACB0RVh0c29mdHdhcmUA
aHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2Vz
ADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAA2MyKEPCcAAAAWdEVYdFRodW1iOjpJ
bWFnZTo6V2lkdGgAMzVOP63aAAAAGXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2UvcG5nP7JWTgAA
ABd0RVh0VGh1bWI6Ok1UaW1lADE1OTQ5NTY5NzEtkWAkAAAAEXRFWHRUaHVtYjo6U2l6ZQA1NDhC
Qvc8t5sAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJf
OV8xNTkyNTU4NTQ5MzQwNzk2Nl8xMF9bMF1QyzhSAAAAAElFTkSuQmCC" ></image>
    </svg>

)

export const xiais = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABelBMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6tNg62a
uNDb5e74+vzv9PjE1uR6osKBp8XG1+Ts8fbx9fjR3umLrslKgKtikbfj6/L////F1uRTh7BbjLPS
3+rV4etXirJhkbbd5+98o8NPhK5jkrejvtTy9fno7vSWtc7w9Pjr8fafvNNYirKKrcnp7/Xl7fNV
iLFJgKvL2udOg61djrTW4uzI2OXb5u7H2OWJrMhXibFLgay3zd5lk7j5+/xwm72Vtc5xnL6txdmw
yNvu8/dql7r6+/1Qha7t8vdrmLtkk7iYt8+1y92Fqsfy9vlZi7LZ5O3O3ehejrXv8/dRha9olbn9
/f73+fvY4+3Y5O1UiLC3zN7i6vGpw9dcjbTf6PDg6fBWibHe6PBplrqrxNjc5u9Uh7BmlLnn7vRt
mbzO3OhNgq1fj7Xq8PXT4Ov1+PrD1eOgmoSqAAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3
f4D2AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+QHERM6IWwkzNAAAAIDSURBVDjLlVX5W9NAEN3m6JFe
lHZBQEk5OtCKlSJUAUHkvu+CIIegaJRyKIf3/06SJpvN2S/vh3xvZ142M7uTGYTMCDAsxwdD4SDP
sUwAeSEiRDGFqBBxlTIxbEOMcZTGE9gRibhDBEnsgqQtloYUdkWqwaxtxJ5oNO2L64DaO5KqJ06R
uOPJelo5S/1MEvW18glqd2FYmpoftbS2PVb5k3Yx29HZRXy120mTdXcOVPT04nyhRp/26c60mh3R
PstC8Xl/aeAFDA6V4eWr0vDIKLwe091Kjhl9MV6ENxMKeTspbzmlisamYUb3C3JNkjqbhfJcjc0v
wIBmXFyCZY1GA1R6U7BC+Cpha7BupMgS8wZsep8eizjCt2DbW8whnvAK7NgFu3mD8yhIeDu8s4sr
ewYPohDh+/D+wFMcQmFjcQhHE17iMBUGPv4AhRNKeCqK4sdPonhGwuApb2kUPn+hXpYkqeWrJH0j
CXL0R89zUO2jDaYwOOpSFBxcQPXSTczS1axg7gqKtTq7Vp/F74aPoQpJU1fhx42izVoPRi4kJFhs
wzk4O8G3ZZiZNzsEU/FryN9B4b4CP39Z7OoPbmuHv//Ixf+332KNWX9Y/QR7/v23VSDjvxX4azK+
2pe/xuiv5fpr5v7GhL8BpNxO2i5NO482NZaMeWhm3IemAm0chxzH8QN1A51An6vAOQAAACV0RVh0
ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xN1QxMTo1ODozMyswODowMGQ3P1sAAAAldEVYdGRhdGU6bW9k
aWZ5ADIwMjAtMDctMTdUMTE6NTg6MzMrMDg6MDAVaofnAAAAIHRFWHRzb2Z0d2FyZQBodHRwczov
L2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8A
AAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADQ0jtbLBgAAABZ0RVh0VGh1bWI6OkltYWdlOjpX
aWR0aAA0NHZ5C4sAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRU
aHVtYjo6TVRpbWUAMTU5NDk1ODMxM3iNVGsAAAASdEVYdFRodW1iOjpTaXplADE3OTJCQh2GjrkA
AABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTky
NTU4NTM5NTU4NzM5NF8xMF9bMF0E1HyPAAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const shuqians = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA/FBMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6ueu9L4
+vz////m7fNmlLm80OC1y93S3+qvx9pNg61kk7hbjLPt8vdolbns8fb9/f73+fvY4+1ejrW3zN7i
6vGpw9ddjrRJgKtTh7Dl7POnwdbK2ub+/v6DqMbd5++rxNiMrsrg6fD2+PuowtdhkLby9vnN3Oh5
ocH8/f7o7/RViLGZuND5+vxrmLuCqMa/0uHZ5O22zN3y9fmyydxcjbTu8/d0nr/Y5O3T4OpLgaxx
nL7w9Pi5zt9Shq9A9ZEOAAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3f4D2AAAAAWJLR0QA
iAUdSAAAAAd0SU1FB+QHERM7I5sxnL0AAAGWSURBVDjLnZVnQ8IwEIZD9wBKKbERUBTrqFqmIrj3
3v7//yIFbBKSltb3Uy552t5drncA0MoJoiQrqqbIkijkQJJ0w4SETEOPRYU8ZJQXuGihCLkqFjge
WDBGFuNLyYaxsks0W4aJKlPvhQtEvFu3F8F25HfBWsSOo/zLCc7ZkosoLVdxBmd3gZ933Vqd0Apa
xWfT23HwBqpR326gOjacSXTEKXnI2GGMlbSwMa5JMy1s5sjwWJiWAMQkeG2dtEQgJcFNypaAnB6W
gZIeVoAaB294nre55Xnb0Y4KtDh4x/d9d9f396Idje/GfsB3gxdgq93hB8hJXdBFvf7EPjikU8e5
lA4aHA2rkJHIue5+bwSPT9otBhbYQqoOT8fhnZ13A7aQgEHDrfbFZbi8Qh22ROni94Pu9c10Xevd
BkzxA6IdouYdGszWjfvhAwnn53/YR4SeIuP5BaERGd5cK3h9e//Ax59f3z+RUfxPk8nUvrI1xmwt
N1szzzYmsg2g8HYcFnX4o23iS4UempX4oRlqNo5V7jj+BfwFhxYlJxJnAAAAJXRFWHRkYXRlOmNy
ZWF0ZQAyMDIwLTA3LTE3VDExOjU5OjM1KzA4OjAw6CVhXwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAy
MC0wNy0xN1QxMTo1OTozNSswODowMJl42eMAAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2Vt
YWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0
VGh1bWI6OkltYWdlOjpIZWlnaHQANDSO1ssGAAAAFnRFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADQ0
dnkLiwAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpN
VGltZQAxNTk0OTU4Mzc1x7RW2AAAABJ0RVh0VGh1bWI6OlNpemUAMTQ1MUJCErb1iAAAAEZ0RVh0
VGh1bWI6OlVSSQBmaWxlOi8vL2FwcC90bXAvaW1hZ2VsYy9pbWd2aWV3Ml85XzE1OTI1NTg1NDM0
NDEzODQ0Xzc3X1swXZ4dq6EAAAAASUVORK5CYII=" ></image>
    </svg>
)

export const fangdas = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABQVBMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6tkk7ig
vdPU4evq8PX6+/3u8/fa5e6rxNhwm71cjbS90eH+/v7////t8vbP3em/0uLM2+fl7fNrl7t1nr/w
9PjB0+Jumrxjkrevx9qMr8r7/P12n8Di6vGSs81hkbZNgq1Shq/e6PD8/P1znb5ej7V9pMP09/rd
5+9JgKuRssyfvNOFqcehvdRumbzC1OP1+Prr8faQscyqw9hmlLitxtlUiLBplrqeu9K+0eGHq8hK
gKtZi7Ll7PPE1eP5+vxMgqyuxtqNr8qOsMtmlLnp7/VLgazJ2ebW4uyivtTE1uSPsctNg63T4Or8
/f5/pcRTh7Dg6fD4+vzy9flgkLaCqMYoSmDwAAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3
f4D2AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+QHERQAEvMSZToAAAIoSURBVDjLnZVpW9pAEMeXIwHC
JcJSWSHKiNFiqa22eKCgrVi8Kl7VWnvf/f4foEuWbmaTIE/6f7GZTX7PPnNsZghRFQpHopoei+ta
NBIOkfuUMJIUKWkkRqLhFPUoFfZF0xnqq0zax4MsHaGsx5eJHB2p3ITKTtJ7NamcS8cInZ3IjYNz
0u90dhzLo/yXEyVnxQdTJTZdrpjuDA5rgV7NzFZhrjZvLQDUFlVaVCfvvHhYry49sq3GY8aWFThv
R4fYJ/WnctNYgVWFHsRYcHyo15/ZxnN7ba6tb2DY4HfSuWezVXHuJmuJ59Z2G8HJEAqvWF0Shgkd
YezArhpiRNov4KULbrI9DEdIVNpTc9QFU6uL4SjRpL1fG/hpmuYr6PH1gO8O4QjBGtGlzeZ5HhhI
HVN6AkUE6yQm7WmLL61Op9OD13w97VNagTMEx0hc2uVzj88Xl30Ex5EbFWi44KuuhQPUUYAmvHHB
13CDYQ2ljtaYOPotnIo0l27fqalzikIX2UrTNo5tT/t30MIsLwq+zcuwtik3zTt4r7C83CHcsFbX
t3bE4VfXJSjDB8zyi0QM/GJjG5h1eNK76MJtq/0RPqFvhnr5udq7e11evUvrhsf2+cvXb84n+wf3
tMOj4tmwFt9//JQ/bsrzw7o1U/r1W4bnbQUuHez/EUbmf5pMoPYVrDEGa7nBmnmwMRFsAA2qk/ei
ef/RZvtSUIdmYfTQHGg4jmO+4/gv9keYjk/rSCYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDct
MTdUMTI6MDA6MTgrMDg6MDD7X+ykAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA3LTE3VDEyOjAw
OjE4KzA4OjAwigJUGAAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2d
AAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6
OkhlaWdodAA0NI7WywYAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANDR2eQuLAAAAGXRFWHRU
aHVtYjo6TWltZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTQ5NTg0
MTjqEJtmAAAAEnRFWHRUaHVtYjo6U2l6ZQAxODM2QkL3YlHQAAAARXRFWHRUaHVtYjo6VVJJAGZp
bGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MjU1ODU0MDA1OTM1MjZfMl9bMF3P
JDgrAAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const sxiaos = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABOFBMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6tkk7ig
vdPU4evq8PX6+/3u8/fa5e6rxNhwm71cjbS90eH+/v7////t8vbP3em/0uLM2+fl7fNrl7t1nr/w
9PjB0+Jumrxjkrevx9qMr8r7/P12n8Di6vGSs81hkbZShq/e6PD8/P1znb5ej7X09/rd5+9JgKuf
vNOFqcehvdRumbzC1OP1+Prr8fZmlLitxtlUiLBNgq1plrqeu9J9pMORssyHq8hKgKtZi7Ll7PPE
1eP5+vxMgqyuxtqNr8qOsMvp7/VLgazJ2ebW4uyivtSqw9jE1uSPsctNg63T4Or8/f5/pcRTh7Dg
6fD4+vzy9flgkLaCqMYiRG51AAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3f4D2AAAAAWJL
R0QAiAUdSAAAAAd0SU1FB+QHERQBB4fUsJAAAAIMSURBVDjLnVVpW9pAEF4SEiBcIgzKCigjphaL
Z/HEC69Ki1Wr1rZerbb9//+g4drMJkGe9P2QZ2b3zTxz7QxjMgKKGtT0UFjXgqoSYK8hYkSBIGpE
BlKVGLgQUzyp8QR4IhH38CAJA5B0+TKSgoFIjcjcUXgVo5JdGAJiO5IaRk4Jv+PJYVwryn5OpJxl
x8ZzfCJfKDoz2KsFOZqcKuF0ecZ8g1ieldnd6qTtg7eV0ty7jlSd53xBIqc70RHuYmVJKNVlXJHY
7Rgztg+VyntyWVtdW6dkw+pJu8+mSkuSqY3NrTpRowESXrY058jANu7IIapC3sU9B7nG96mqsqCQ
x6ddpTAbVAsyTcgH5bafxT4OLe0IjwlZY7qQ+QzACUeBDwCnmCVknYWEPGFan+bHPj61AAp4Rsgh
FhZy/rPL5/OLFtHCxI0CVh3cy4ZJVZ0EWMQvDvIVXlNVI6mDMpdN13I3X+XU2UWBWb5cI3etW2xK
P6tSNy/g6oZt9xa/yV4ptJEAVtY2t7vGL69ymMfvlGs1EjPowfoWcvPo9Md5A2+a9Tu8J3eG3PwW
6jv7Dat6F+a1FdvD49NP+6rzwF3j8Dh71qvFr+cX8XBjrgfrxGTu9x8RnnsUOHB48LcrJP5nyPga
X/4Go7+R62+Y+1sT/hZQuzppNzXtvdo6vmTkpZkZvDTb6K3jkOc6/gfIiZUfTf02UQAAACV0RVh0
ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xN1QxMjowMTowNyswODowMC5/9+0AAAAldEVYdGRhdGU6bW9k
aWZ5ADIwMjAtMDctMTdUMTI6MDE6MDcrMDg6MDBfIk9RAAAAIHRFWHRzb2Z0d2FyZQBodHRwczov
L2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8A
AAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADQ0jtbLBgAAABZ0RVh0VGh1bWI6OkltYWdlOjpX
aWR0aAA0NHZ5C4sAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRU
aHVtYjo6TVRpbWUAMTU5NDk1ODQ2NzXuEDAAAAASdEVYdFRodW1iOjpTaXplADE3NTdCQlGb8+oA
AABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTky
NTU4NTM5NDgyMzMxMV83OV9bMF2saEt0AAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const pinms = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAt1BMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6tcjbS/
0uHt8vb////y9fnK2uZrl7u/0uLs8fb6+/1KgayjvtT7/P1KgKvy8/Th5OWkq7A6SFPt7/DCxsqA
ipE0Q07P09a+w8ckNEBWY2z+/v75+fqgp6w5SFL6+/ussrdKWGGqw9fg6fBXibF3n8CwyNtNgq10
3cguAAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3f4D2AAAAAWJLR0QAiAUdSAAAAAd0SU1F
B+QHERUnHM+tkG8AAAGOSURBVDjLlZXpdoJADIVHVkVcEAeIClS70lW6au37P1cBdRYJ4txfDPlO
SCYhIURWR9MN07K7lmnoWoecU8/pU0F9p9eIai6tydVQdDCkqIYDJIIRbdCoFsvYo43yxjI7oWc1
kfzSFgm+e14b7LG4B6M2tsjyeCfDdra4wUMtLmEp3VfHZ+cgjEBUFAYc9qvsODuDU80Eusxxyk4h
zAPpy8EcQn5yip7kfRaBzBY0RPzQ74jpAdSykl5pRL8c1onRYFnESZrE0iuDmCh8tVwd7uOa201i
ofCywG5uS3jJ7RaxMXhR+r27L+HVgtlt0sXgGBgMMbN38TASeMiyx6csewZIhDDQBFN4ea30BpAK
CRq453Wev3/k+afo2RCLwsuNx6yL5eaNVN3G1/fJbWhiIyEtCvDDzEUjEYe7Pm1+gDVstkerIzW/
pH1v/G44Xf3gLj2j7QZ2+yf3gh92u/s7pqc8CtSGjNL4UhuMaiNXbZirrQm1BVRWx6+jPr7aqlim
8tKcNi/NUod1bKPr+B8WKoOhmPW+xwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xN1QxMzoz
OToyOCswODowMM2xfwkAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTdUMTM6Mzk6MjgrMDg6
MDC87Me1AAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVY
dFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0
ADQ0jtbLBgAAABZ0RVh0VGh1bWI6OkltYWdlOjpXaWR0aAA0NHZ5C4sAAAAZdEVYdFRodW1iOjpN
aW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NDk2NDM2OK1o3kwA
AAASdEVYdFRodW1iOjpTaXplADE0MzZCQjKSvNEAAABFdEVYdFRodW1iOjpVUkkAZmlsZTovLy9h
cHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTkyODc3NTg4MDc5MDA4Ml8xX1swXSJBZUgAAAAA
SUVORK5CYII=" ></image>
    </svg>
)
export const zitis = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="44px" height="44px" viewBox="0 0 44 44">  <image id="image0" width="44" height="44" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABcVBMVEX///////9JgK1IgKtJ
gKxIgKxJgKxIgKtJgKxLgK1JgKxJgKtIgKxLgK1IgKxJgKtJga1JgKtJgKxJgKtJgKxIf6tejrV6
osJlk7jF1uT////f6PBZi7P8/P3k7PJwm72eu9Jrl7v2+Pu4zd7l7fPT4Ov3+ftRha9XibGCqMas
xdmkv9WEqcZJgKt3oMCNr8pznb6RssxKgKu6z9/V4ev1+PrX4+xUh7C/0uJPhK7l7PPZ5O2Wtc6u
xtpLgaxNgq2UtM5ViLH5+/ygvNNql7qSs82wyNtMgqxKgazw9Pjd5++Zt9BynL5cjbT9/f6yydtf
j7VikbfN3Ojy9vng6fHo7vTz9vlOg63S3+r4+vz09/pxnL6LrsmZuNDB0+J4ocFUiLBShq/E1eO6
zt/8/f5ajLNNg63M2+fb5e7J2ea0ytxkk7iCp8X+/v5umbzp7/WBp8VgkLamwNbt8vbT4Orx9fjg
6fDv9Phtmbz6+/1jkrfSS7f4AAAAFXRSTlMAATiDpb/Z8/JEmefCIq3ubfyQw5o3f4D2AAAAAWJL
R0QAiAUdSAAAAAd0SU1FB+QHERUqEHO1ogkAAAHfSURBVDjLlZVnXxNBEIc3V1IujZAMZRDBAKFI
k470ZhSlI2pUICiGplQF/fTuHQk3S+6S7P/FZvbmyf1mdvZmGBPlUVRN9/r8Xl1TFQ8rpYARBKKg
EXBFlRAUKaQ4ouEIOCoSdoggCi6KFsVSFQNXxapEthpKqlp4L5QReXcgVg6OPcQdjpZjeZaFM4mU
Z/kJ5mtRCQtwX514ZXDcyk54VFNb50abOSaEJ/XY4AYb/E4K9+xJ41NscoGDnkfpNeOzZIt7iqqw
b22DllT7vd3R2fW8u4c4VaZRthf7oB9fmObAIKaGhnFk1PZqTKfwWHIc4OWEaU7i1DTMzGKX7dWZ
l7Bz82a8C7jI16VXab6+frNsu73MR+C3+I6vK6trhT+vb2xu2W4f8xN4e7jP1PudD3zT+fETcmVs
t5+G8RkL+gLwFXf31vezBxkaBknw2/dD6/fHThv0ZHKWfURgnRxdNnWct07w9BBPTOvsJ4E1UpQl
7M5bv/iBnScv2i+vrm8IrJJyH/1OF8w/t+m73F9M5f5NEVh5dJGosnfTwp5fJGZAhTKKLn8JWR94
qDI2JP/BSrUCuSYj1b7kGqNcy5Vr5nJjQm4AmdVxaJFx59FmxZIQh2bCfWiayo9jn+M4/g+aXpaW
XW+8/gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNy0xN1QxMzo0MjoxNiswODowMMLa7cMAAAAl
dEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTdUMTM6NDI6MTYrMDg6MDCzh1V/AAAAIHRFWHRzb2Z0
d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6
UGFnZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADQ0jtbLBgAAABZ0RVh0VGh1
bWI6OkltYWdlOjpXaWR0aAA0NHZ5C4sAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/
slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NDk2NDUzNjMqe7wAAAASdEVYdFRodW1iOjpTaXpl
ADE2MTFCQucUMb8AAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1n
dmlldzJfOV8xNTkyNTU4NTQzNzEyNDczOV81MF9bMF1QcaSUAAAAAElFTkSuQmCC" ></image>
    </svg>
)

export const tuwen = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">  <image id="image0" width="24" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxIAJx4p+z82AAACCElEQVQ4y41UTUhUURT+znXoDVkDMUEFLgLbCa4qIilCmu4kD0v6g4Ta
ZCYhZosoayXkJsiyNkLkwhaVq5Eh540gbSqljQwJFURBaBouxhgfzt89Ld6d8b2ZJ8x5i3fe+c53
73fv9zgkL6sRhFFDkML7ug6KrCKMNK14MIP3I0OLnlodHwCoL4Aw0tRgrbuxaBN/QdI6591BDvE9
2i0AWvG2bylpHgBELa3uCDiv9u3ZAW6hb8HB2NIm2HakcFynP5MTHkL2DZu0zCc2TrY3T9olgmrF
Qy0nCTchuo9NGrVuyE5+lW1FvKw6TstOJhYrJPnHVAop3zMk/sg4d8szvJd+GDOu673C3Tqds257
djAuZQe4hWLBwZjtWq6Bj2pxmQpJkzYeVEsKDRdGnWwjX6qVfYgeltdr8UEALGT/qZSaw6M2wwv+
68+sOk9xwiWJdvJjWsc0R4qdeAlc3FFm/KYPOltwnyEvukKvm+yP3/mW+Sl/V3XQeQdMjGPc71rt
xAsAiD7n4dwCiIBrDni6WR0sGTdlVRkXGksP0Zq4oJ7wWafCJutfQyVRTXi7Jse4R/zF/WJCrzvD
dzT1V4UPOp6hp9Bn3Yx8pkNsAO9mMetzrZthfaVpXDV3iaeAyG3twx5ZX/qkEa7PddE8wFzZyo0A
UDUEOEgK5DMEAtwIiF6qfcwAWNp27D81Br+Slc3WfwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0w
Ny0xN1QxNjozOTozMCswODowMC/bguMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDctMTdUMTY6
Mzk6MzArMDg6MDBehjpfAAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zP
HZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFn
ZTo6SGVpZ2h0ADI02IxsgAAAABZ0RVh0VGh1bWI6OkltYWdlOjpXaWR0aAAyNCAjrA0AAAAZdEVY
dFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTU5NDk3
NTE3MDzw/YQAAAARdEVYdFRodW1iOjpTaXplADg1N0JCvExTcgAAAEV0RVh0VGh1bWI6OlVSSQBm
aWxlOi8vL2FwcC90bXAvaW1hZ2VsYy9pbWd2aWV3Ml85XzE1OTMzMzg1NDIwNjUxMTQzXzFfWzBd
L+ftoAAAAABJRU5ErkJggg==" ></image>
    </svg>

)

export const yuanwen = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="25px" height="24px" viewBox="0 0 25 24">  <image id="image0" width="25" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAAAAAAq3gkaAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxIAKQvapfZTAAAA/ElEQVQoz2P4P8vTFQvwXsTw1yN64WIMsCDCj+GP65L/WMAcT6DM8v+/
ZoZHzPuDIjMPIjPDvbPNdSE2mZCe//+bY3DJtMRgN62rzQ2rab9m4XIBKjgR2fYXq8wK9yjXbiwy
P9tce35Ndp0BlPkNkflQmfQKSL3O8lj3//+/NtcomJ470W4eaV//Xw0LOgtS96faFSpzwNfvyHbX
yq1eyU8hhv7IBcv8m+cadx/oUFfX2q8orp5T61r2CcRrQ3IMWMbdddpfMO9XodsBFBmvHTDupwTv
K8gySAH2NCT4KY7QueKd8AkhgxLbB9wKf0FjGz2FJLtWQlMIzlQFANcdvQndWpO8AAAAJXRFWHRk
YXRlOmNyZWF0ZQAyMDIwLTA3LTE3VDE2OjQxOjExKzA4OjAw8WXbXQAAACV0RVh0ZGF0ZTptb2Rp
ZnkAMjAyMC0wNy0xN1QxNjo0MToxMSswODowMIA4Y+EAAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8v
aW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAA
ABd0RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQAMjTYjGyAAAAAFnRFWHRUaHVtYjo6SW1hZ2U6Oldp
ZHRoADI1VyScmwAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRo
dW1iOjpNVGltZQAxNTk0OTc1MjcxSbFzSwAAABF0RVh0VGh1bWI6OlNpemUAODIxQkIlFhd5AAAA
RnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MzMz
ODU0MjEyMjU1NDFfMjFfWzBdeD0t1QAAAABJRU5ErkJggg==" ></image>
    </svg>
)

export const wenben = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="21px" height="24px" viewBox="0 0 21 24">  <image id="image0" width="21" height="24" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAYCAAAAAAw4umUAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxIAKiUtXqhfAAAAq0lEQVQoz2P4G+eKBBLf/gcBhu+ucQsXQcE8V3eIMFB05X8Y+O46Nx0s
DBRdgyS65iNYGF30P1gYQxQsjCYKAWtQRH9DXYIqCjcHJrppGpC4Vv0dVbTNder/a/5hT1BF/7a6
tvqHPUQz4f/fSlfvB+jmArV7AA1BEwWaea8VIowkmg8082+rx21U0fePQGZfRTcXqy8oEl2JJvoV
LIqITQhoBIqixjwYeBwHAGEvhIN+7HC8AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA3LTE3VDE2
OjQyOjM3KzA4OjAwO6dSGQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNy0xN1QxNjo0MjozNysw
ODowMEr66qUAAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8dnQAAABh0
RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdlOjpIZWln
aHQAMjTYjGyAAAAAFnRFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADIxUElYggAAABl0RVh0VGh1bWI6
Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNTk0OTc1MzU3kybe
ywAAABF0RVh0VGh1bWI6OlNpemUANTQ1QkL/5CTIAAAARnRFWHRUaHVtYjo6VVJJAGZpbGU6Ly8v
YXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzlfMTU5MjU1ODU0MzQ0MTM4NDRfNDlfWzBdp7lnbgAA
AABJRU5ErkJggg==" ></image>
    </svg>
)

export const shezhi = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="27px" height="26px" viewBox="0 0 27 26">  <image id="image0" width="27" height="26" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAaCAAAAABj43gsAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfkBxIAKyfaS/gyAAABrElEQVQoz12S30sCQRDH75+bcy0NTUqisujBAkMqUAhFsqd+EWkkKUVF
koRaSD4UBWEJFRVFQfVQYD8l6vphiWWd2+5qd6f7sPfd+czOzswNh+WVctsHBcWZk6WgN/boWn4r
WSYSFfACesRHkMC34VheZncmHrQ25CTSrLEjAOubxKz1F8KIZSZL5Me4ZSqb1A5LTDeJy5ezU2K2
jnKUr/VIzKtmpvuN+cQTFWlYKrH96eoBonJjACpQB0WiLXXBM8o8oHF8Yix2obkHMTUKQ/SiVc1H
MJeBCeqJw7DNAodghz3pNGDuBA6ZzdxbzENs7WffGLxwV7yP3vtCoVKSw810z/VpMxwOQHsO4+9y
JjSgFZrnHsSVMVtoTH/NJatB1MwqcllguTjMxfr8cEBEgdVwXayBeMYpy6p87ELOI9dOOmqk7Aai
pSykntE2ojyNaTccJRKistXJ3aTay9671gNA1488G63k3PRe/A9fx6lNtCyx0Zrj83NRMUuGAPGJ
+7aIzWWtmLO27teCm9eDH6dNrgq2zqNGWMNTYIKq08r5vJ1x04asOhaf/01/s0g+P0CzAB4AAAAl
dEVYdGRhdGU6Y3JlYXRlADIwMjAtMDctMTdUMTY6NDM6MzkrMDg6MDCEWkJ6AAAAJXRFWHRkYXRl
Om1vZGlmeQAyMDIwLTA3LTE3VDE2OjQzOjM5KzA4OjAw9Qf6xgAAACB0RVh0c29mdHdhcmUAaHR0
cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn
/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAyNjaCDawAAAAWdEVYdFRodW1iOjpJbWFn
ZTo6V2lkdGgAMje5Kv23AAAAGXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0
RVh0VGh1bWI6Ok1UaW1lADE1OTQ5NzU0MTkVvSBNAAAAEnRFWHRUaHVtYjo6U2l6ZQAxMjY4QkKA
kpRJAAAAR3RFWHRUaHVtYjo6VVJJAGZpbGU6Ly8vYXBwL3RtcC9pbWFnZWxjL2ltZ3ZpZXcyXzEw
XzE1OTI1NTcwNDAxODM3NDk2XzM1X1swXd2yRLAAAAAASUVORK5CYII=" ></image>
    </svg>
)
//接口列表
//登录
export const getRoleMenu = '/role_menu/getList.htm'  //获取菜单列表
export const login = '/login/login.htm';//登录
export const logininfo = '/login/logininfo.htm';//获取登录用户信息
export const isInNetworkSegment = '/login/isInNetworkSegment.htm';//判断是否在设定的ip段内
export const logout = '/login/logout.htm';//退出
export const register = '/login/register.htm';//注册账号
export const changePassword = '/login/changePassword.htm';//修改登录用户的密码
export const checkUserName = '/login/checkUserName.htm';//校验账号
export const checkPassword = '/login/checkPassword.htm';//校验密码正确性,用于修改密码

export const loginBycode = '/pc/merchanr/loginForPhone.htm';//验证码登录
export const checkcode = '/pc/merchanr/checkCodeImg.htm';//验证图片验证码
export const checkPhoneCode = '/phoneCode/checkPhoneCode.htm';//验证手机验证码
export const checkEmailCode = '/wyd/auth/mailto';//验证邮箱验证码
export const getEmailCode = '/auth/verifyMail';//测试邮件验证码是否有效
export const checkPhone = '/login/checkPhone.htm';//验证手机号码是否与账号匹配
export const resetPassword = '/login/resetPassword.htm';//找回密码/重置密码
export const getPhoneCode = '/phoneCode/getPhoneCode.htm';//发送手机验证码
export const usernemu = '/pc/login/getMenu.htm';//登录用户的菜单


//角色管理
export const getRoleList = '/role/getList.htm';//角色列表

//机构
export const getOrganzationList = '/organzation/getList.htm';//机构


//banner
export const getBannerList = '/banner/getList.htm';//banner

//picture
export const getPicture = window.BASE_URL + '/picture/get.htm';//读取图片
export const uploadPicture = window.BASE_URL + '/ckeditorimagepasteuploadservlet.sv';//上传图片


//部门
export const getDepartmentList = '/department/getList.htm';//获取部门列表


//岗位
export const getPositionList = '/position/getList.htm';//获取岗位列表

//热词
export const getHotWordList = '/hot_word/getList.htm';//获取热词列表

//历史纪录
export const getHistoryList = '/history/getList.htm';//获取热词列表

//收藏列表
export const getCollectionList = '/collection/getList.htm';//获取热词列表
export const saveCollection = '/collection/save.htm';//获取热词列表

//收藏列表
// export const getSearchResult = '/search_person.htm';//查询
//查询右侧文献列表
export const getText = '/search_text.htm';//查询右侧文献列表

//朝代列表
export const getDynastiesList = '/dynasties/getList.htm'

//资料列表
export const getSearchList = '/search_.htm'


export const getAddrChildren = '/getAddrChildren.htm' //查询地址列表子节点

export const getAddrList = '/getAddrList.htm' //获取地址列表根节点, 地址列表异步展示,先查根节点,然后根据点击展开的节点查询子节点

export const getAddrTypeList = '/getAddrTypeList.htm' //获取地址类型列表

export const getEntry_typeList = '/getEntry_typeList.htm' //获取入仕类别列表

export const getOfficeChildren = '/getOffiChildren.htm' //查询官职列表子节点

export const getOfficeList = '/getOffiList.htm' //获取官职列表根节点, 官职列表异步展示,先查根节点,然后根据点击展开的节点查询子节点

export const getEntryList = '/getEntryList.htm' //获取入仕途径列表

export const getAssoc_typeList = '/getAssoc_typeList.htm' //获取社会关系类型

export const getAssocList = '/getAssocList.htm' //获取社会关系

export const getKinshipList = '/getKinshipList.htm' //获取社会关系

export const getChoronymList = '/getChoronymList.htm' //获取郡望列表

export const getText_typeList = '/getText_typeList.htm' //获取著述类别列表

export const getText_catList = '/getText_catList.htm' //获取著述类目列表

export const getText_roleList = '/getText_roleList.htm' //获取著述角色列表

export const getTextList = '/getTextList.htm' //获取著述列表

export const getArticleList = '/article/getList.htm' //获取著述列表

export const getArticle = '/article/get.htm';//文章

export const getLiteratureList = '/literature/getList.htm';//获取文献列表

export const getLiterature = '/literature/get.htm';//获取文献

export const searchLiteratureList = '/literature/search.htm';//搜索

export const getRanking = '/literature/getRanking.htm';//获取阅读排名

export const startTask = '/ocr/startTask.htm';//图文识别_创建任务

export const ocr_upload = '/ocr/upoad.htm';//图文识别_开始任务

export const pauseTask = '/ocr/pauseTask.htm';//图文识别_暂停任务

export const proceedTask = '/ocr/proceedTask.htm';//图文识别_继续任务

export const getTaskList = '/task/getList.htm';//图文识别_获取任务列表

export const deleteTask = '/task/delete.htm';//图文识别_删除任务

export const getTask = '/task/get.htm';//获取任务信息

export const getTaskLog = '/task_log/getList.htm';//获取任务日志

export const getOCR_Text = '/ocr_text/get.htm';//获取图文识别结果

export const getOCR_TextList = '/ocr_text/getList.htm';//获取图文识别结果



export const ipChina = '/ip/identify.htm' // 详情页 国内外ip
export const registerCheckPhone = '/login/registerCheckPhone.htm';//验证手机或邮箱

export const getTextId = '/cbdb/basicSearch/getTextId.htm';//根据文本名字跟类型获取ids---实体链接
export const getSearchResult = '/cbdb/tempVisual/search_person.htm';//查询 收藏列表
