import React, { Component } from 'react';
import { Layout } from 'antd';
import './never.css';
import convert from '../../utils/locale_zh_convert';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom'


const { Footer } = Layout;


class Footeds extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
  }

  render() {
    return (
      <Footer id='footer'>
        <div className='wenb' style={{color:"black"}}>
        {convert('国家古籍数字化工程专项经费资助项目     ', this.locale)}
        <a style={{color: "#096dd9"}} href="https://beian.miit.gov.cn/#/Integrated/index" target="blank">京ICP备13052946</a>
       </div>
      </Footer>
    );
  }
}
export default withRouter(Footeds);
