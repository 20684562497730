import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from './reduces/index'
import zh_TW from 'antd/es/locale/zh_TW'
import zh_CN from 'antd/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import Routes from './Routes'
dayjs.locale('zh-cn')


ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
