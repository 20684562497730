import React from 'react';
import { Layout, message, ConfigProvider } from 'antd';
import Headeds from './components/common/Headeds'
import Footeds from './components/common/Footeds'
import './App.css';
import './css/index.css';
import request from './utils/request'
import * as cont from './config/constant'
import * as lacs from './actions/loginActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import ewm from './images/ewm1.png'

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      locale: 'zh_tw'
    }
  }

  componentDidMount() {
    // this.getLogininfo()
  }

  getLogininfo = () => {
    let _this = this
    _this.isInNetworkSegment().then(data => {
      // if (data.length === 0) {
      request(cont.logininfo, {}, _this.props).then(data => {
        if (data.code == 0) {
          if (!!data.item) {
            let obj = data.item
            _this.setState({ login: obj }, () => {
              _this.props.dispatch(lacs.loginSuccess(obj));
            })
          } else {
            console.log(_this.props)

          }
        } else if (data.code == 1) {

        }
      })
      // }
    })
  }
  isInNetworkSegment = () => {
    let _this = this
    console.log(this.props)
    return new Promise((resolve, reject) => {
      request(cont.isInNetworkSegment, {}, _this.props).then(data => {
        if (data.code == 0) {
          if (!!data.item) {
            _this.props.dispatch(lacs.setNetworkSegment(data.item));
          } else {
            _this.props.dispatch(lacs.setNetworkSegment([]));
          }
        } else if (data.code == 1) {
          _this.props.dispatch(lacs.setNetworkSegment([]));
        }
        resolve(!!data.item ? data.item : [])
      })
    })
  }
  logout = () => {
    let _this = this
    request(cont.logout, {
      body: {}
    }, _this.props).then((data) => {
      if (data.code == 0) {
        _this.props.dispatch(lacs.loginSuccess(0));
        // this.props.history.push(window.BASE_URL)
      }
      if (data.code >= 1) {
        message.error(data.errmsg);
      }
    })
  }
  changeLocale = (value) => {
    console.log(value)
    let _this = this
    if (value == 1) {
      _this.setState({
        locale: 'zh_cn'
      })
      dayjs.locale('zh_cn')

    } else if (value == 2) {
      _this.setState({
        locale: 'zh_tw'
      })
      dayjs.locale('zh_tw')
    }
  }
  render() {
    return (
      <ConfigProvider locale={this.state.locale}>
        <div className="App" key={this.state.locale}>
          {/* <div className="tip">
            <h1>公告</h1>
          </div>
          <div className="mask"></div>
          <div className="tipinfo" >
            <div className="fir">
              <p>尊敬的“吾与点”用户：</p>
              <p style={{ textIndent: '2em' }}>您好！为更好的为您提供服务，服务器将进行迁移工作。</p>
              <p style={{ textIndent: '4em' }}>1.计划于2021年07月23日（周五）10：00开始，周期预计为5个工作日内完成。</p>
              <p style={{ textIndent: '4em' }}>2.具体进度及结束时间以平台实际情况为准（根据迁移调试进度可能提前或延后）。</p>
              <p style={{ textIndent: '4em' }}>3.届时，吾与点平台系统将暂时停止服务，由此带来的不便，敬请谅解！</p>
              <p style={{ textIndent: '2em' }}>敬请留意平台及群内公告。</p>
            </div>
            <div className="yx">
              <p>感谢您对我们工作的理解与支持，如您有任何疑问，请联系</p>
              <p>邮箱：<a href="#">gdhc@pku.edu.cn</a></p>
              <p>公众号：数字人文开放实验室 （关注公众号，加入用户群与我们互动！）</p>
            </div>
            <div className="ewm">
              <img src={ewm}></img>
            </div>
          </div> */}
          <Layout className="stop-pointer-events">
            <Headeds logout={this.logout} changeLocale={this.changeLocale} />
            {/* <div style={{ minHeight: 833, backgroundColor: "#FFFFFF" }} id='content33fdd3'> */}
            <div style={{ backgroundColor: "#FFFFFF" }} id='content33fdd3'>
              {this.props.children}
            </div>
            <Footeds />
          </Layout>
        </div>
      </ConfigProvider>

    );
  }
}

function mapStateToProps(state) {
  return {
    networksegment: state.login.networksegment
  }
}
export default withRouter(connect(mapStateToProps)(App));
