import Immutable from 'immutable';
let initState = {}
export function HandledDataReducer(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {
       
        case 'initNamedEntityDisposed': {
            return action.data.handledData;
        }
       
        default:
            return state
        
    }
}