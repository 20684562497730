import React, { useState, useEffect, useRef } from 'react'

import { Radio, Popover, Typography, Input } from 'antd'
const { Text } = Typography;

function Choices(props) {
    const radioRef = useRef()
    const inputRef = useRef()
    const divRef = useRef()
    const { choices, text, onSelect, placement, currKey, selectKeys, editable, style, modified, scaleSize, onClick, startEdit, status } = props
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(text && text);
    useEffect(() => {
        if (!!text) {
            setValue(text);
        }
    }, [text])
    useEffect(() => {

        editable && startEdit && setEditing(currKey && selectKeys && selectKeys.indexOf(currKey) > -1)
        if (editable && !startEdit) {
            setEditing(false)
        }
        if (currKey && selectKeys && selectKeys.indexOf(currKey) > -1 && divRef.current) {
            !editable && setSelectionText(divRef.current)
        }
    }, [startEdit, selectKeys])
    useEffect(() => {
        if (editable && editing) {
            setValue(text && text)
            inputRef.current.focus();
        } else if (editable && !editing) {
            inputRef.current && inputRef.current.blur();
        }
    }, [editing])
    useEffect(() => {
        startEdit && !editing && save()
    }, [editing])
    const toggleEdit = () => {
        editable && setEditing(!editing);
    };
    const save = () => {
        onSelect(value)
    }
    function setSelectionText(target) {
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(target);
            range.select();
        } else if (window.getSelection) {

            window.getSelection().removeAllRanges();

            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNode(target);
            selection.addRange(range);
        }
    }
    // 回车进入：onPressEnter方法，鼠标进入：onVisibleChange方法。
    return (<Popover placement={placement}
        overlayClassName='choice'
        overlayStyle={{ zoom: scaleSize ? scaleSize : 1 }}
        visible={editing}
        onVisibleChange={(visible) => {
            editable && setEditing(visible)
        }}

        content={
            <Radio.Group defaultValue={text} onChange={e => {
                setValue(!e.target.value ? '' : e.target.value)
                inputRef.current && inputRef.current.focus();
            }} ref={radioRef}>
                {
                    choices && Array.from(choices).map((item, index) =>
                        <Radio.Button key={index} value={item}>{item}</Radio.Button>
                    )
                }
            </Radio.Group>
        }
        trigger="click">
        <div ref={divRef}
            onClick={e => {
                !editable && setSelectionText(e.currentTarget)
                onClick && onClick()
            }} style={{ minHeight: 20, minWidth: 20 }}>

            <Input
                style={{ display: editable && editing ? 'block' : 'none', ...style, padding: 0, width: '50px', textAlign: 'center' }}
                ref={inputRef}
                onChange={e => {
                    setValue(!e.target.value ? '' : e.target.value)
                }}
                onPressEnter={e => {
                    setValue(!e.target.value ? '' : e.target.value)
                    editable && setEditing(false)
                }} value={value} />

            {status ? <Text style={{ display: editable && editing ? 'none' : 'block', color: '#FFD306', ...style, }}>{text}</Text> : <Text style={{ display: editable && editing ? 'none' : 'block', color: modified && 'red', ...style, }}>{text}</Text>}
        </div>
    </Popover>)
}

export default Choices