import React, { Component } from 'react';
import { Layout, Steps, Button, message } from 'antd';
import './Distinguish.css';
import convert from '../../utils/locale_zh_convert';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom'
import TaskList from '../ocr/tasklist/taskList'
import Detail from '../ocr/detail/detail'
const { Content } = Layout;
const { Step } = Steps;
const steps = [
  {
    title: '上传文件',
    content: (<TaskList />),
  },
  {
    title: '识别文件',
    content: (<Detail />),
  },
  {
    title: '导出结果',
    content: 'Last-content',
  },
];
class Distinguish extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
    };
    this.locale = dayjs.locale()
  }
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  render() {
    const { current } = this.state;
    return (
      <Content>
        <div className='content'>
          <div className='contop'>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>

          <div className="steps-content">{steps[current].content}</div>

          {/* <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => this.next()}>
                下一步
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                上一步
              </Button>
            )}
          </div> */}
        </div>
      </Content>
    );
  }
}
export default withRouter(Distinguish);
