import React, { useState, useEffect } from 'react'

import { Layout, Radio, Row, Modal, Col, Typography, Button, message, notification,Select  } from 'antd'
import { withRouter } from 'react-router'
import dayjs from 'dayjs'
import convert from '../../../utils/locale_zh_convert'
import './detail.less'
import { connect } from 'react-redux';
import Proofread from './proofread'
import Detection from './detection'
import { RollbackOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'rc-scroll-anim'
import request from '../../../utils/request'
import * as cont from './../../../config/constant'
import reqwest from 'reqwest'

// import * as lacs from '../../../actions/textDataAction';
import { set_deal_content } from './../../../actions/DealTextContentAction'

const { Title } = Typography;
const {Option} =Select

function Detail(props) {
    const [visible, setVisible] = useState(false)
    const locale = dayjs.locale()
    const [modifyList, setModifyList] = useState([]);
    const uid = props.match.params.uid
    const [title, setTitle] = useState(convert('目标检测', locale));
    const [files, setFiles] = useState([]);
    const [selectTab, setSelectTab] = useState(1);
    const [selectTabStatus, setSelectTabStatus] = useState(false);

    const onChange = (e) => {
        setSelectTabStatus(true);
        setSelectTab(e.target.value)
        if (e.target.value === 1) {
            setTitle(convert('目标检测', locale))
        } else if (e.target.value === 2) {
            setTitle(convert('图文校对', locale))
        }

    };
    const showModel = () => {
        setVisible(true)
    }
    const getFiles = () => {
        // request(cont.getOCR_TextList, {
        //     body: {
        //         // task_id: uid
        //         task_id: 43
        //     }
        // }, props).then(data => {
        //     if (data.code === 0) {
        //         console.log('data::::::::>>>',data);

        //         if (data.list && data.list.length > 0) {
        //             const cc = JSON.parse(data.list[0].ocr_content)
        //             if (cc.page.lines === null) {
        //                 // notification.error({ description: '该文件不能解析' })
        //                 showModel()
        //                 // props.history.push('/ocr/list#3')
        //             } else {
        //                 let fs = []
        //                 fs = data.list.filter(t => t.ocr_status === 'Success')
        //                 setFiles(fs)
        //             }

        //         } else {
        //             notification.error({ description: '未知错误' })
        //         }
        //     } else if (data.code === 1) {
        //         notification.error({ description: data.errmsg ? data.errmsg : '未知错误' })
        //     }
        // })
    }
    function setModifyLists(value) {

        setModifyList(value)
    }
    function handleChange(value) {
        console.log(`selected ${value}`);
        if (value == 1) {
            if (files.length > 0 && files[0].page && files[0].page.lines) {
                props.dispatch(set_deal_content(files[0].uid, files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join('')), files[0].identify))
                console.log(props.dealTextContent)
                saveVersion({ uid: files[0].uid, content: JSON.stringify(files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join(''))), identify: files[0].identify, action: 'OCR识别' })
            }
            props.history.push('/AutomaticDu#2')
              
        } else if( value == 2 ) {
            if (files.length > 0 && files[0].page && files[0].page.lines) {
                props.dispatch(set_deal_content(files[0].uid, files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join('')), files[0].identify))
                saveVersion({ uid: files[0].uid, content: JSON.stringify(files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join(''))), identify: files[0].identify, action: 'OCR识别' })
            }
                props.history.push('/NamedEntity#2')
        }
      }
    useEffect(() => {
        // getFiles();
        console.log(props.files)
        console.log(uid)
        let filesData = props.files.find(item => item.uid === uid);
        if (!filesData || !filesData.page.lines) {
            showModel();
        } else {
            setFiles([filesData]);
            if (filesData.modifyList) {
                setModifyList(filesData.modifyList)
            }
        }
    }, []);

    const ssssss = (files) => {
        files.modifyList = modifyList
        let fs = props.files
        let filesData = fs.find(item => item.uid === uid);
        fs.splice(fs.indexOf(filesData), 1, files)
        props.dispatch({
            type: 'set_files_data',
            payload: { files: fs }
        });
        setFiles([files]);
    }

    const saveVersion = (v) => {
        reqwest({
            url: '/text/saveVersion.htm',
            method: 'post',
            type: 'json',
            data: v,
            success: function (data) {
                if (data.code === 0) {
                    message.success('已保存成功')
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }, error: (err) => {
                message.error(err.status + ':' + err.statusText)
            }
        })
    }

    return (
        <Layout style={{ margin: '0 30px', background: "#FFF" }} className="component-5d3fs35s2s">
            <Row style={{ height: '60px' }}>
                <Col span={12} style={{ justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
                    <Title level={3} > {title}</Title>
                </Col>
                <Col span={12} style={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>

                    <Button size="large" type="link" style={{ marginRight: '2%' }} onClick={e => {
                        props.history.push('/ocr/list#3')
                    }}>{convert('返回')}<RollbackOutlined /></Button>
                    <Radio.Group className='iuily' defaultValue={selectTab} size="large" onChange={onChange}>
                        <Radio.Button value={1}>{convert('目标检测', locale)}</Radio.Button>
                        <Radio.Button value={2}>{convert('图文校对', locale)}</Radio.Button>
                    </Radio.Group>
                    {/* <Button size="large" style={{ marginLeft: '5%' }} onClick={e => {
                        if (files.length > 0 && files[0].page && files[0].page.lines) {
                            props.dispatch(set_deal_content(files[0].uid, files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join('')), files[0].identify))
                            saveVersion({ uid: files[0].uid, content: JSON.stringify(files[0].page.lines.map((i, indx) => i.words.map(t => t.text).join(''))), identify: files[0].identify, action: 'OCR识别' })
                        }
                        props.history.push('/AutomaticDu#2')
                    }}>{convert('句读')}</Button> */}

{/* //下拉菜单 */}
<Select defaultValue="运行" style={{ width: 120,color:'white',"margin-left": 20}} onChange={handleChange}>
      <Option value="1">自动句读</Option>
      <Option value="2">命名实体</Option>
    </Select>











                </Col>
            </Row>
            {
                selectTab === 1 ?
                    <Proofread files={files} modifyList={modifyList} ssssss={(value) => ssssss(value)} setModifyList={(value) => setModifyLists(value)} /> : <Detection files={files} modifyList={modifyList} ssssss={(value) => ssssss(value)} setModifyList={(value) => setModifyLists(value)} />
            }
            <Modal
                visible={visible}
                centered={true}
                destroyOnClose={true}
                className={"errmodel"}
                title={<div style={{ textAlign: 'center' }}>
                    <ExclamationCircleOutlined style={{ color: '#ff4242' }} />
                该文件不符合解析规则
            </div>
                }
                onOk={() => { setVisible(false); props.history.push('/ocr/list#3'); console.log("123"); }}
                onCancel={() => { setVisible(false); props.history.push('/ocr/list#3') }}
                okText={'提交'}
            >

                <p>你可以提交该错误文件</p>
                <p>&nbsp;&nbsp;提交到：<a>feedback@ecbdb.com</a></p>
                <p>&nbsp;&nbsp;或反馈到公司QQ客户支持群：<a>809694090</a></p>
            </Modal>
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        files: state.text.files
    }
}

export default withRouter(connect(mapStateToProps)(Detail));