import React, { Component } from 'react';
import { Layout, Form, Tabs, Space, Input, Button, Checkbox, Select, Radio, TreeSelect, notification, message, Upload, Empty, ConfigProvider, Result, Divider } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request'
import * as cont from '../config/constant'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal';
import user2 from '../images/user2.png'
const { TabPane } = Tabs;
class Personaldata extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabPosition: 'left',
    }
  }
  changeTabPosition = e => {
    this.setState({ tabPosition: e.target.value });
  };
  render() {
    const { tabPosition } = this.state;
    return (<>
      <div className="rement3">
        <Space style={{ marginBottom: 24 }}>

        </Space>
        <Tabs tabPosition={tabPosition}>
          <TabPane tab="基本设置" key="1">

          </TabPane>
          <TabPane tab="识别文档" key="2">

          </TabPane>
          <TabPane tab="整理文档" key="3">

          </TabPane>
        </Tabs>



      </div>
    </>
    )
  }
}
export default withRouter(Personaldata);