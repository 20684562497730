// PersonalCenter
import React, { Component } from 'react';
import { Layout, Button, Input, Upload, message, Radio, Form, Tabs, Cascader, Popover, DatePicker } from 'antd';
import { PlayCircleOutlined, DeleteOutlined, DownloadOutlined, FileDoneOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import './PersonalCenter.css';
import { connect } from 'react-redux'
import convert from "../../utils/locale_zh_convert"
import dayjs from 'dayjs'
const { Content } = Layout;

const { TabPane } = Tabs;
const { Search } = Input;

function onChange(date, dateString) {
  console.log(date, dateString);
}

function callback(key) {
  console.log(key);

}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const options = [
  {
    value: '北京',
    label: '北京',
    children: [
      {
        value: '通州区',
        label: '通州区',
      },
    ],
  },
];
function onChangeCas(value) {
  console.log(value);
}

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '标注时间',
    dataIndex: 'time',
  },
  {
    title: '操作',
    dataIndex: 'action',
  },
];

const data = [];
for (let i = 0; i < 10; i++) {
  data.push({
    key: i,
    name: `李太白文集`,
    time: `2020-08-04 14:30`,
    action: <div className='action'><PlayCircleOutlined /><FileDoneOutlined /><DownloadOutlined /><DeleteOutlined /></div>,
  });
}



class PersonalCenter extends Component {
  constructor(props) {
    super(props);
    this.locale = dayjs.locale()
    this.state = {
      loading: false,
      value: 1,
      selectedRowKeys: [], // Check here to configure the default column
      key: props.location.hash.slice(1, 2)
    };
  }


  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  handleChangeUP = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  //刷新key值
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if (nextProps.location.hash !== this.props.location.hash) {
      this.setState({
        key: nextProps.location.hash.slice(1, 2)
      })
      return false
    }
    return false
  }

  render() {
    const { login, networksegment } = this.props
    // console.log(login, networksegment)
    const { loading, imageUrl, selectedRowKeys } = this.state;
    const uploadButton = (
      <div>
        <div>{convert('上传新头像', this.locale)}</div>
      </div>
    );
    const onFinish = (values) => {
      console.log('Success:', values);
    };
    // const onReset = () => {
    //   form.resetFields();
    // };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Content className='bg-93ds95 Center123'>
        <div className='top'>
          <Tabs activeKey={this.state.key} onChange={(key) => {
            this.setState({
              key: key
            })
          }}>
            <TabPane tab={convert('基本设置', this.locale)} key="1">
              <div className='jiben'>
                <Form
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label={convert('头像：', this.locale)}
                    name={convert('photo', this.locale)}
                  >
                    <div className='qiguai'>
                      <div className='img'></div>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={this.handleChangeUP}
                      >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                      <span className='xiaozi'>{convert('（支持JPG、PNG、GIF格式，小于2MB）', this.locale)}</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={convert('真实姓名：', this.locale)}
                    name={convert('real_name', this.locale)}
                    initialValue={login.name}
                  >
                    <Input placeholder={convert('请填写', this.locale)} />
                  </Form.Item>
                  <Form.Item
                    label={convert('邮箱：', this.locale)}
                    name={convert('email', this.locale)}
                    initialValue={login.email}
                  >
                    <Input placeholder={convert('请填写', this.locale)} />
                  </Form.Item>
                  <Form.Item
                    label={convert('昵称：', this.locale)}
                    name={convert('Nickname', this.locale)}
                    initialValue={login.name}
                  >
                    <Input placeholder={convert('请填写', this.locale)} />
                  </Form.Item>
                  <Form.Item
                    label={convert('性别：', this.locale)}
                    name={convert('sex', this.locale)}
                    initialValue={login.sex}
                  >
                    <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
                      <Radio value={1}>{convert('女', this.locale)}</Radio>
                      <Radio value={2}>{convert('男', this.locale)}</Radio>
                      <Radio value={3}>{convert('保密', this.locale)}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={convert('联系方式：', this.locale)}
                    name={convert('phone', this.locale)}
                    initialValue={login.phone}
                  >
                    <Input placeholder={convert('请填写', this.locale)} />
                  </Form.Item>
                  <Form.Item
                    label={convert('地址：', this.locale)}
                    name={convert('address', this.locale)}
                  >
                    <Cascader style={{ textAlign: "left" }} options={options} onChange={onChangeCas} placeholder={convert('请选择', this.locale)} />
                    <Input className='tes' placeholder={convert('请填写详细地址：', this.locale)} />
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button className='cz'>{convert('重置', this.locale)}</Button>
                      <Button type="primary" htmlType="submit" className='tj'>{convert('提交', this.locale)}</Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane tab={convert('识别文档', this.locale)} key="2">
              <div className='sbwen'>
                <div className='Ctop Ctops'>
                  <div className='Right'>
                    <div>
                      <span>{convert("选择日期：", this.locale)}</span>
                      <DatePicker onChange={onChange} />
                    </div>
                    <Search
                      placeholder={convert("请输入...", this.locale)}
                      size="large"
                      onSearch={value => console.log(value)}
                    />
                  </div>
                </div>
                <div className='Bottm'>

                </div>
              </div>
            </TabPane>
            <TabPane tab={convert('整理文档', this.locale)} key="3">
              <div className='Ctop ss123'>
                <span>{convert("文档列表", this.locale)}</span>
                <div className='Right'>
                  <Button>{convert("全部(20)", this.locale)}</Button>
                  <Button>{convert("整理中(10)", this.locale)}</Button>
                  <Button>{convert("已完成(10)", this.locale)}</Button>
                  <div>
                    <span>{convert("选择日期：", this.locale)}</span>
                    <DatePicker onChange={onChange} />
                  </div>
                  <Search
                    placeholder={convert("请输入...", this.locale)}
                    size="large"
                    onSearch={value => console.log(value)}
                  />
                </div>
              </div>
              <div className='wendang'>
                <div className='Centers'>
                  <div className='Headers'>
                    <span>{convert("名称", this.locale)}</span>
                    <span>{convert("作者", this.locale)}</span>
                    <span>{convert("提交人", this.locale)}</span>
                    <span>{convert("提交时间", this.locale)}</span>
                    <span>{convert("状态", this.locale)}</span>
                    <span>{convert("操作", this.locale)}</span>
                  </div>
                  <div className='BodysList'>
                    <div className='Item'>
                      <span>
                        <div></div>
                        {convert("李太白文集", this.locale)}
                      </span>
                      <span>{convert("[東漢] 鄭玄", this.locale)}</span>
                      <span>{convert("李华华", this.locale)}</span>
                      <span>{convert("07-16 17:18", this.locale)}</span>
                      <span style={{ color: '#DD2222' }}>{convert("整理中", this.locale)}</span>
                      <span style={{ color: "#3DA6CF" }}>{convert("查看", this.locale)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab={convert('消息通知', this.locale)} key="4">
              <div className='news'>
                <Popover overlayClassName='xiangq' placement="bottomLeft" title={<div className='tetitle'>
                  <p>{convert('所有消息按时间倒序显示标题标题标题标题标题标题标题标题标题标题标题标题', this.locale)}</p>
                  <span>2020-08-04 14:30</span>
                </div>} content={<div className='tecontent'>
                  增加了命名实体，系统自动标注、人名、地名、职官等信息，标注更加全面。
                </div>} trigger="click">
                  <div className='items'>
                    <div>
                      <div style={{ backgroundColor: "#60C37E" }}>{convert('协同', this.locale)}</div>
                      <p>{convert('所有消息按时间倒序显示标题标题标题标题标题标题标题标题标题标题标题标题', this.locale)}</p>
                    </div>
                    <span>2020-08-04 14:30</span>
                  </div>
                </Popover>

                <div className='items'>
                  <div>
                    <div style={{ backgroundColor: "#4BA6F0" }}>{convert('系统', this.locale)}</div>
                    <p>{convert('所有消息按时间倒序显示标题标题标题标题标题标题标题标题标题标题标题标题', this.locale)}</p>
                  </div>
                  <span>2020-08-04 14:30</span>
                </div>
                <div className='items'>
                  <div>
                    <div style={{ backgroundColor: "#4BA6F0" }}>{convert('系统', this.locale)}</div>
                    <p>{convert('所有消息按时间倒序显示标题标题标题标题标题标题标题标题标题标题标题标题', this.locale)}</p>
                  </div>
                  <span>2020-08-04 14:30</span>
                </div>
                <div className='items'>
                  <div>
                    <div style={{ backgroundColor: "#4BA6F0" }}>{convert('系统', this.locale)}</div>
                    <p>{convert('所有消息按时间倒序显示标题标题标题标题标题标题标题标题标题标题标题标题', this.locale)}</p>
                  </div>
                  <span>2020-08-04 14:30</span>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Content >
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state.login.login,
    networksegment: state.login.networksegment
  }
}
export default withRouter(connect(mapStateToProps)(PersonalCenter));