import dayjs from 'dayjs'
import convert from './../utils/locale_zh_convert'
import Mock, { mock } from 'mockjs'


const statisticsList = []

const tagList = [
    {
        id: 1,
        tag_name: '人名',
        tag_key: 'peop',
        style_index: 0,
        visible: true,
        color: '#f7e298',
        keywordStyles: [
            {
                backgroundColor: '#f7e298',
                color: '#252729',
                border: '1px solid #f7e298',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#f7e298',
            //     border: '1px solid #f7e298',
            //     fontSize: 20,
            // },
            {
                backgroundColor: '#FFFFFF',
                color: '#252729',
                border: '1px solid #FFFFFF',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#f7e298',
            //     fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ],
        styles: [
            {
                backgroundColor: '#f7e298',
                color: '#686868',
                border: '1px solid #f7e298',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#f7e298',
            //     border: '1px solid #f7e298',
            //     // fontSize: 20,
            // },
            {
                backgroundColor: '#cccccc',
                color: '#686868',
                border: '1px solid #cccccc',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#f7e298',
            //     // fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ]
    },
    {
        id: 4,
        tag_name: '时间',
        tag_key: 'date',
        color: '#8ecffd',
        style_index: 0,
        visible: true,
        keywordStyles: [
            {
                backgroundColor: '#8ecffd',
                color: '#252729',
                border: '1px solid #8ecffd',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#8ecffd',
            //     border: '1px solid #8ecffd',
            //     fontSize: 20,
            // },
            {
                backgroundColor: '#FFFFFF',
                color: '#252729',
                border: '1px solid #FFFFFF',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#8ecffd',
            //     fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ],
        styles: [
            {
                backgroundColor: '#8ecffd',
                color: '#686868',
                border: '1px solid #8ecffd',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#8ecffd',
            //     border: '1px solid #8ecffd',
            //     // fontSize: 20,
            // },
            {
                backgroundColor: '#cccccc',
                color: '#686868',
                border: '1px solid #cccccc',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#8ecffd',
            //     // fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ]
    },
    {
        id: 2,
        tag_name: '地名',
        tag_key: 'tpn',
        color: '#c3eca3',
        style_index: 0,
        visible: true,
        keywordStyles: [
            {
                backgroundColor: '#c3eca3',
                color: '#252729',
                border: '1px solid #c3eca3',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#c3eca3',
            //     border: '1px solid #c3eca3',
            //     fontSize: 20,
            // },
            {
                backgroundColor: '#FFFFFF',
                color: '#252729',
                border: '1px solid #FFFFFF',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#c3eca3',
            //     fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ],
        styles: [
            {
                backgroundColor: '#c3eca3',
                color: '#686868',
                border: '1px solid #c3eca3',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#c3eca3',
            //     border: '1px solid #c3eca3',
            //     // fontSize: 20,
            // },
            {
                backgroundColor: '#cccccc',
                color: '#686868',
                border: '1px solid #cccccc',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#c3eca3',
            //     // fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ]
    },
    {
        id: 3,
        tag_name: '职官',
        tag_key: 'offi',
        color: '#caa4f0',
        style_index: 0,
        visible: true,
        keywordStyles: [
            {
                backgroundColor: '#caa4f0',
                color: '#252729',
                border: '1px solid #caa4f0',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#caa4f0',
            //     border: '1px solid #caa4f0',
            //     fontSize: 20,
            // },
            {
                backgroundColor: '#FFFFFF',
                color: '#252729',
                border: '1px solid #FFFFFF',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#caa4f0',
            //     fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ],
        styles: [
            {
                backgroundColor: '#caa4f0',
                color: '#686868',
                border: '1px solid #caa4f0',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#caa4f0',
            //     border: '1px solid #caa4f0',
            //     // fontSize: 20,
            // },
            {
                backgroundColor: '#cccccc',
                color: '#686868',
                border: '1px solid #cccccc',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#caa4f0',
            //     // fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ]
    },
    {
        id: 5,
        tag_name: '书名',
        tag_key: 'liter',
        color: '#e199b7',
        style_index: 0,
        visible: true,
        keywordStyles: [
            {
                backgroundColor: '#e199b7',
                color: '#252729',
                border: '1px solid #e199b7',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#e199b7',
            //     border: '1px solid #e199b7',
            //     fontSize: 20,
            // },
            {
                backgroundColor: '#FFFFFF',
                color: '#252729',
                border: '1px solid #FFFFFF',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#e199b7',
            //     fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ],
        styles: [
            {
                backgroundColor: '#e199b7',
                color: '#686868',
                border: '1px solid #e199b7',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#e199b7',
            //     border: '1px solid #e199b7',
            //     // fontSize: 20,
            // },
            {
                backgroundColor: '#cccccc',
                color: '#686868',
                border: '1px solid #cccccc',
                // fontSize: 20,
            },
            // {
            //     backgroundColor: '#FFFFFF',
            //     color: '#c27ba0',
            //     // fontSize: 20,
            //     border: '1px solid #fafafa',
            // },
        ]
    },
]


export const getTagList = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    return {
        code: 0,
        hint: null,
        errmsg: '',
        item: null,
        list: tagList
    };


}
export const deleteTag = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    if (!param || !param.id) {
        return {
            code: 1,
            hint: null,
            errmsg: '缺少必要参数',
            item: null,
        };
    }
    let tag = tagList.find(item => item.id == param.id)
    if (!tag) {
        return {
            code: 1,
            hint: null,
            errmsg: '该标注类型不存在',
            item: null,
        };
    }
    tagList.splice(tagList.indexOf(tag), 1)
    return {
        code: 0,
        hint: null,
        errmsg: '删除成功',
        item: null,
    };
}
export const setTagVisible = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    if (!param || !param.id) {
        return {
            code: 1,
            hint: null,
            errmsg: '缺少必要参数',
            item: null,
        };
    }
    let tag = tagList.find(item => item.id == param.id)
    if (!tag) {
        return {
            code: 1,
            hint: null,
            errmsg: '该标注类型不存在',
            item: null,
        };
    }
    let t = { ...tag }
    t.visible = param.visible === 'true'
    tagList.splice(tagList.indexOf(tag), 1, t)
    return {
        code: 0,
        hint: null,
        errmsg: '设置成功',
        item: null,
    };
}
export const addTag = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    let obj = null
    if (!param) {
        obj = {
            id: '@integer(0,1000)',
            tag_name: '标签',
            tag_key: '@string',
            color: '@color',
            style_index: 0,
            visible: true,
            styles: [
                {
                    backgroundColor: '@color',
                    color: '#FFFFFF',
                    border: '1px solid @color',
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: '@color',
                    border: '1px solid @color',
                },
                {
                    backgroundColor: '#cccccc',
                    color: '#252729',
                    border: '1px solid #cccccc',
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: '@color',
                    // fontSize: 20,
                },
            ],
            keywordStyles: [
                {
                    backgroundColor: '@color',
                    color: '#FFFFFF',
                    border: '1px solid @color',
                    fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: '@color',
                    border: '1px solid @color',
                    fontSize: 20,
                },
                {
                    backgroundColor: '#cccccc',
                    color: '#252729',
                    border: '1px solid #cccccc',
                    fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: '@color',
                    fontSize: 20,
                },
            ]
        }
    } else {

        obj = {
            id: '@integer(0,1000)',
            tag_name: param.tag_name ? param.tag_name : '标签',
            tag_key: param.tag_key ? param.tag_key : '@string',
            color: param.color ? ('#' + param.color) : '@color',
            style_index: 0,
            visible: true,
            styles: [
                {
                    backgroundColor: param.color ? ('#' + param.color) : '@color',
                    color: '#FFFFFF',
                    border: '1px solid ' + (param.color ? ('#' + param.color) : '@color'),
                    // fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: param.color ? ('#' + param.color) : '@color',
                    border: '1px solid ' + (param.color ? ('#' + param.color) : '@color'),
                    // fontSize: 20,
                },
                {
                    backgroundColor: '#cccccc',
                    color: '#252729',
                    border: '1px solid #cccccc',
                    // fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: param.color ? ('#' + param.color) : '@color',
                    // fontSize: 20,
                },
            ],
            keywordStyles: [
                {
                    backgroundColor: param.color ? ('#' + param.color) : '@color',
                    color: '#FFFFFF',
                    border: '1px solid ' + (param.color ? ('#' + param.color) : '@color'),
                    fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: param.color ? ('#' + param.color) : '@color',
                    border: '1px solid ' + (param.color ? ('#' + param.color) : '@color'),
                    fontSize: 20,
                },
                {
                    backgroundColor: '#cccccc',
                    color: '#252729',
                    border: '1px solid #cccccc',
                    fontSize: 20,
                },
                {
                    backgroundColor: '#FFFFFF',
                    color: param.color ? ('#' + param.color) : '@color',
                    fontSize: 20,
                },
            ]
        }
    }
    if (obj) {
        if (tagList.filter(item => item.tag_key === obj.tag_key).length > 0) {
            return {
                code: 1,
                hint: null,
                errmsg: '标签标识已存在',
            };
        }
        tagList.push(Mock.mock(obj))
        return {
            code: 0,
            hint: null,
            errmsg: '',
            item: obj
        };
    } else {
        return {
            code: 1,
            hint: null,
            errmsg: '保存失败',
        };
    }
}
export const getTagByKey = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }

    let obj = null
    if (!param || !param.tag_key) {
        return {
            code: 0,
            hint: null,
            errmsg: '参数不正确',
        };
    } else {
        let tags = tagList.filter(item => item.tag_key === param.tag_key)
        if (tags.length === 0) {
            return {
                code: 0,
                hint: null,
                errmsg: '标签不存在',
            };
        }
        return tags[0];
    }

}

//添加标记记录
export function addTagTextLog(config) {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }

    if (!param || !param.tag_id || !param.keyword) {
        return {
            code: 1,
            errmsg: '未传入标记记录',
            item: null,
            hint: null
        }
    }
    let obj = {
        id: '@integer(0,1000)',
        tag_id: param.tag_id,
        keyword: JSON.parse(param.keyword),
        time: '@datetime'
    }

    statisticsList.push(Mock.mock(obj))
    return {
        code: 0,
        errmsg: null,
        item: null,
        hint: '添加记录成功'
    }
}

export function getKeywordStatisticsList(config) {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    let ss = statisticsList.map(item => {
        let tag_ = tagList.find(tag => tag.id == item.tag_id)
        if (tag_) {
            item = {
                ...item,
                color: tag_.color,
                tag_key: tag_.tag_key,
                tag_name: tag_.tag_name,
                styles: tag_.styles,
                keywordStyles: tag_.keywordStyles
            }
        } else {
            item = { ...item, tag_ }
        }
        return item
    })

    let data = ss.reduce((t, item, index, arr) => {
        if (!t.find(i => i.keyword == item.keyword)) {
            item.tag_times = arr.filter(it => it.keyword == item.keyword).length
            t.push(item)
        }
        return t
    }, [])

    return {
        code: 0,
        list: data
    }
}
export function getTagStatisticsList(config) {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }

    let data = tagList.map((item, index) => {
        let statistics_ = statisticsList.filter(statistics => statistics.tag_id == item.id)
        item.tag_times_total = statistics_.length  //总计
        let s = statistics_.reduce((ss, item) => {  //去重
            if (!ss.find(i => i.keyword == item.keyword)) {
                ss.push(item)
            }
            return ss
        }, [])
        item.tag_times = s.length
        item.key = index
        return item
    })

    // let data = statisticsList.map(item => {
    //     let tag_ = tagList.find(tag => tag.id == item.tag_id)
    //     if (tag_) {
    //         item = {
    //             ...item,
    //             tag_key: tag_.tag_key,
    //             tag_name: tag_.tag_name,
    //             styles: tag_.styles,
    //             keywordStyles: tag_.keywordStyles
    //         }
    //     } else {
    //         item = { ...item, tag_ }
    //     }
    //     return item
    // })


    return {
        code: 0,
        list: data
    }
}

function getParams(body) {
    if (!body) {
        return null
    } else {

        body = decodeURI(body)
        body = body.replace(/%3A/g, ':').replace(/%2C/g, ',')
        let ps = body.split('&');
        let param = ps.reduce((pr, item) => {
            if (item) {
                let p = item.split('=')
                if (p[0].indexOf('[') > -1) {
                    let f = p[0].substring(0, p[0].indexOf('['))
                    if (pr[f]) {
                        if (p[1]) {
                            pr[f] = [...pr[f], p[1]]
                        }
                    } else {
                        if (p[1]) {
                            pr[f] = [p[1]]
                        }
                    }

                } else {
                    pr[p[0]] = p[1] ? p[1] : null;
                }
            }
            return pr
        }, {})
        return param
    }

}

export const getTagById = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    if (!param || !param.id) {
        return {
            item: null,
            hint: null,
            errmsg: '无效的id',
            code: 1,
        }
    } else {
        let item = tagList.find(t => t.id === parseInt(param.id))
        if (item) {
            return {
                item: item,
                hint: null,
                errmsg: null,
                code: 0,
            }
        } else {
            return {
                item: null,
                hint: null,
                errmsg: '无效的id',
                code: 1,
            }
        }
    }
}
export const saveVersion = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    if (!param || !param.uid || !param['content'] || !param.identify || !param.action) {
        return {
            item: null,
            hint: null,
            errmsg: '参数不完整',
            code: 1,
        }
    } else {
        let textVersions = []
        if (localStorage.getItem("textVersions")) {
            textVersions = JSON.parse(localStorage.getItem("textVersions"))
        }
        let v = Mock.mock(
            {
                id: '@integer(1,1000)',
                uid: param.uid,
                content: JSON.parse(param['content']),
                identify: param.identify,
                action: param.action,
                jd_deal_content: param['jd_deal_content'] && JSON.parse(param['jd_deal_content']),//句读处理后的文本
                mm_deal_content: param['mm_deal_content'] && JSON.parse(param['mm_deal_content']),//专名识别处理后的文本
                time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }
        )
        textVersions.push(v)
        // if (textVersions.length > 3) {
        //     textVersions.slice(textVersions.length - 3)
        // }
        localStorage.setItem("textVersions", JSON.stringify(textVersions))

        return {
            code: 0,
            hint: '保存成功',
            item: v
        }
    }
}
export const getVersionList = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }
    if (!param || !param.identify) {
        return {
            code: 0,
            hint: null,
            list: [],
            errmsg: null
        }
    }
    let textVersions = []
    if (localStorage.getItem("textVersions")) {
        let tvs = JSON.parse(localStorage.getItem("textVersions"))
        textVersions = tvs.filter(item => item.identify == param.identify)
    }

    return {
        code: 0,
        hint: null,
        list: textVersions
    }
}
export const getLastVersion = (config) => {
    let param = null
    if (config.body) {
        param = getParams(config.body)
    }

    let textVersions = []
    if (localStorage.getItem("textVersions")) {
        textVersions = JSON.parse(localStorage.getItem("textVersions"))
    }
    if (param.action_order) {
        //流程排序,  OCR识别->自动句读->专名识别
        textVersions = textVersions.filter(item => {
            if (param.action_order == 1 || param.action_order == 2) {
                return true
            } else if (param.action_order == 3) {
                if (item.action === '自动句读' || item.action === '专名识别') {
                    return true
                }
            }
        })
    }

    if (textVersions.length > 0) {
        return {
            code: 0,
            hint: null,
            item: textVersions[textVersions.length - 1]
        }
    } else {
        return {
            code: 0,
            hint: null,
            item: null
        }
    }

}
