export function set_deal_content(uid, content, identify, jd_deal_content, mm_deal_content) {
    return {
        type: 'set_deal_content',
        payload: {
            uid: uid,
            content: content,
            identify: identify,
            jd_deal_content: jd_deal_content,
            mm_deal_content: mm_deal_content,
        }
    }
}
export function init_deal_content() {
    return {
        type: 'init_deal_content',
    }
}