import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Spin, message, Dropdown, Menu, Divider } from 'antd';
import ToolBar from './toolbar'
import { fabric } from 'fabric'
import { useKeyPress } from 'ahooks'
import { EuroCircleTwoTone } from '@ant-design/icons';
import convert from '../../../utils/locale_zh_convert';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

// img	规定要使用的图像、画布或视频。
// sx	可选。开始剪切的 x 坐标位置。
// sy	可选。开始剪切的 y 坐标位置。
// swidth	可选。被剪切图像的宽度。
// sheight	可选。被剪切图像的高度。
// x	在画布上放置图像的 x 坐标位置。
// y	在画布上放置图像的 y 坐标位置。
// width	可选。要使用的图像的宽度。（伸展或缩小图像）
// height	可选。要使用的图像的高度。（伸展或缩小图像）
// onload   目标图片是否加载完成
//positionData  图片坐标划分列表
//onSelect  选中某个坐标事件
//selectPositionKey  选中的坐标key值
//showFooter 是否展示末尾工具栏
//current 当前页
//onPageChange 页码变更事件
//pageSize 每页展示条数
//total 总条数
function CanvasImg(props) {
    const { img, sx = 0, sy = 0, swidth, sheight, x = 0, y = 0, width, height,
        onload, positionData = [], onSelect, selectPositionKeys = [], deletePositionKeys = [], showFooter, current, onPageChange, pageSize, total, initScaleSize = 1, locale, dragable, adjustImg, distinguish, onClick, spin, selectLines, style } = props
    const canvasRef = useRef();
    const onSelect1 = useCallback(onSelect, [])
    const [selectPositions, setSelectPositions] = useState([])
    // const [deletePositions, setDeletePositions] = useState([])
    const [scaleSize, setScaleSize] = useState(initScaleSize)
    const [canvasOnLoad, setCanvasOnLoad] = useState(false)
    const [modifyPostions, setModifyPostions] = useState([])
    const multiSelect = useRef(false)
    const currSelectPositionRef = useRef({})
    const selectRectsRef = useRef([])
    const fabricCanvasRef = useRef()//当前画布
    const positionsRef = useRef({})
    //canvas \初始化
    function canvasInit(callback) {
        if (!canvasRef.current) {
            return;
        }

        setCanvasOnLoad(true)


        if (onload) {
            let sw = swidth ? swidth : img.width
            let sh = sheight ? sheight : img.height
            let w = width ? width : sw;
            let h = height ? height : sh;

            if (dragable) {
                if (fabricCanvasRef.current) {
                    fabricCanvasRef.current.dispose()
                }
                let canvas = fabricCanvasRef.current = new fabric.Canvas(canvasRef.current)

                canvas.setHeight(h * scaleSize)
                canvas.setWidth(w * scaleSize)
                canvas.setZoom(scaleSize)
                canvas.setBackgroundImage(new fabric.Image(img))
                // canvas.clipTo = (ctx) => {
                //     ctx.rect(sx, sy, sw, sh)
                // }
                // canvas.uniScaleTransform = true
                canvas.hoverCursor = null
                canvas.set({ preserveObjectStacking: true })



                canvas.on("mouse:out", (options) => {

                })
                canvas.on("selection:cleared", (options) => {
                    if (!options.selected) {

                        //清除
                        if ((!!positionsRef.current.rect1) && (!!positionsRef.current.rect1)) {
                            canvas.remove(positionsRef.current.rect1);
                            canvas.remove(positionsRef.current.rect2);
                        }
                        // let v = new fabric.ActiveSelection([...options.deselected], {
                        //     canvas: canvas,
                        //     hasRotatingPoint: false,
                        //     cornerColor: 'blue',
                        //     cornerSize: 6,
                        //     transparentCorners: false,
                        // });
                        // canvas.discardActiveObject();
                        // canvas.setActiveObject(v, 'object:selected');

                        canvas.requestRenderAll();
                    }

                })
                canvas.on("selection:created", function (n) {
                    var i = n.target;
                    return new Promise(resolve => {
                        onRowSelect(i.left, i.top)
                        resolve()
                    })
                });
                canvas.on("selection:updated", function (n) {
                    setMultiSelect(n)
                    var i = n.target;
                    return new Promise(resolve => {
                        onRowSelect(i.left, i.top)
                        resolve()
                    })
                });
                canvas.on("object:scaling", function (n) {
                    var t = n.target, u = t.width * t.scaleX, f = t.height * t.scaleY, i = t.left, r = t.top,
                        e = t.canvas.getWidth(), o = t.canvas.getHeight();
                    if (i < 0) {
                        u += i
                        i = 0
                    }
                    if (r < 0) {
                        f += r
                        r = 0
                    }
                    i + u > e && (u = e - i);
                    r + f > o && (f = o - r);
                    t.set({ width: u, height: f, scaleX: 1, scaleY: 1, left: i, top: r });
                    t.setCoords();
                    t.canvas.requestRenderAll()
                });
                canvas.on("object:moving", function (n) {
                    var t = n.target, i = t.left, r = t.top, e = t.width, o = t.height, u = t.canvas.getWidth() - e,
                        f = t.canvas.getHeight() - o;
                    i < 0 && t.set("left", 0);
                    r < 0 && t.set("top", 0);
                    i > u && t.set("left", u);
                    r > f && t.set("top", f);
                    t.setCoords();
                    t.canvas.requestRenderAll()
                });
                canvas.on("object:modified", function () {
                    var n = canvas.getActiveObject(), o = n._objects, u, f, e, t;
                    if (o) {
                        u = n.left + n.width / 2
                        f = n.top + n.height / 2
                        o.forEach(function (n) {
                            var i = {
                                left: u + n.left,
                                top: f + n.top,
                                right: u + n.left + n.width - 1,
                                bottom: f + n.top + n.height - 1,
                                width: n.width,
                                height: n.height,
                                isChanged: !0
                            }
                            t = n.data;
                        })
                        setModifyPostions([...modifyPostions, ...o])
                    } else {
                        e = {
                            left: n.left,
                            top: n.top,
                            right: n.left + n.width - 1,
                            bottom: n.top + n.height - 1,
                            width: n.width,
                            height: n.height,
                            isChanged: !0
                        }
                        t = n.data
                        setModifyPostions([...modifyPostions, [e]])
                    }

                });

                let rects = []
                let linePositionData = {}
                positionData.forEach((item, index) => {
                    linePositionData[item.col] = item.line
                    const rect = new fabric.Rect({
                        key: item.key,
                        left: item.x,
                        top: item.y,
                        width: item.width,
                        height: item.height,
                        strokeWidth: 2,
                        fill: "",
                        hasRotatingPoint: false,
                        borderColor: "blue",
                        cornerColor: "blue",
                        cornerSize: 6,
                        // opacity: 0.7,
                        transparentCorners: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        // scaleX: scaleSize,
                        // scaleY: scaleSize,
                        dirty: true,
                        data: { type: 'word', data: item.data }
                    })
                    switch (item.wordFlag) {
                        case 'LeftSmall':
                            rect.set({ stroke: '#ffb85a' })
                            break;
                        case 'RightSmall':
                            rect.set({ stroke: '#6f7dd0' })
                            break;
                        default:
                            rect.set({ stroke: distinguish ? 'red' : '' })
                            break;
                    }
                    positionsRef.current[item.key] = rect
                    rects.push(rect)
                })
                for (var key in linePositionData) {
                    let line = linePositionData[key]
                    const rect = new fabric.Rect({
                        key: key,
                        left: line.position[0],
                        top: line.position[1],
                        width: (line.position[2] - line.position[0]),
                        height: (line.position[3] - line.position[1]),
                        //绿色外边框
                        stroke: distinguish ? '#00cc00' : '',
                        borderColor: "blue",
                        cornerColor: "blue",
                        strokeWidth: 3,
                        fill: "",
                        // opacity: 0,
                        selectable: false,
                        dirty: true,
                        data: { type: 'line', data: line },
                        // scaleX: scaleSize,
                        // scaleY: scaleSize,
                    })
                    rects.push(rect)
                }
                rects.reverse().forEach(item => {
                    canvas.add(item)
                })
                canvas.requestRenderAll()
                document.getElementsByClassName("upper-canvas")[0].addEventListener('contextmenu', (event) => {
                    if (canvas.getActiveObjects().length > 0) {
                        return false;
                    } else {
                        for (var key in positionsRef.current) {
                            var rect = positionsRef.current[key];
                            //判断该对象是否在鼠标点击处
                            if (rect && canvas.containsPoint(event, rect)) {
                                //选中该对象
                                canvas.setActiveObject(rect);
                                canvas.requestRenderAll()
                            }
                        }
                        //阻止系统右键菜单
                        event.preventDefault();
                        return false;
                    }

                });
            } else {
                const canvas = canvasRef.current;
                canvas.width = w * scaleSize
                canvas.height = h * scaleSize
                canvas.onclick = (e) => {
                    const c = canvasRef.current
                    var x = (e.pageX - c.getBoundingClientRect().x - document.documentElement.scrollLeft) / scaleSize
                    var y = (e.pageY - c.getBoundingClientRect().y - document.documentElement.scrollTop) / scaleSize
                    var ps = positionData.find(t => x - t.x <= t.width && x - t.x > 0 && y - t.y <= t.height && y - t.y > 0)
                    if (ps) {
                        let sps = multiSelect ? [...selectPositions, ps] : [ps]
                        onSelect1(sps.map(item => item.key), ps, positionData)
                    }
                }
                var ctx = canvas.getContext("2d");
                // 清除画布
                ctx.clearRect(x, y, w, h);

                ctx.scale(scaleSize, scaleSize);
                // 绘制图片
                ctx.drawImage(img, sx, sy,
                    sw, sh,
                    x, y, w, h);
            }
            callback && callback()
        }

    }

    useEffect(() => {
        canvasInit()
    }, [onload, current, scaleSize, distinguish])

    useEffect(() => {
        if (selectPositionKeys.length > 0) {
            onRectSelect(selectPositionKeys)
        }

    }, [selectPositionKeys])
    useEffect(() => {
        if (deletePositionKeys.length > 0) {
            deletePositionKeys.forEach(item => {
                onRectDelete(item)
            })
        }
    }, [deletePositionKeys])

    useEffect(() => {
        if (!!selectLines && selectLines.length > 0) {
            let canvas = fabricCanvasRef.current;
            if (!canvas) {
                return;
            }

            if ((!!positionsRef.current.rect1) && (!!positionsRef.current.rect2)) {
                canvas.remove(positionsRef.current.rect1);
                canvas.remove(positionsRef.current.rect2);
            }
            if (selectLines[0] === 0) {
                canvas.remove(positionsRef.current.rect1);
                canvas.remove(positionsRef.current.rect2);
                return
            }
            let width = canvas.width;
            let height = canvas.height;

            let index = selectLines[0] + 1;
            const list = [];

            positionData.map(item => {
                if (item.col === index) {
                    list.push(item);
                }
            });
            if (list.length > 0) {
                const padding = list[0].line.position;//x-y-width-height
                // console.log(padding, scaleSize)
                const rect1 = new fabric.Rect({
                    left: 0,
                    top: 0,
                    width: padding[0],
                    height: height / scaleSize,
                    fill: "#5B5B5B",
                    // backgroundColor: '#5B5B5B',
                    opacity: 0.7,
                    // hasControls: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    // scaleX: scaleSize,
                    // scaleY: scaleSize,
                    selectable: false
                });
                positionsRef.current['rect1'] = rect1;
                canvas.add(rect1);

                const rect2 = new fabric.Rect({
                    left: padding[2],
                    top: 0,
                    width: (width - padding[2] - 3) / scaleSize,
                    height: height / scaleSize,
                    fill: "#5B5B5B",
                    // backgroundColor: '#5B5B5B',
                    opacity: 0.7,
                    // hasControls: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    // scaleX: scaleSize,
                    // scaleY: scaleSize,
                    selectable: false
                });
                positionsRef.current['rect2'] = rect2;
                canvas.add(rect2);
            }

        }
    }, [selectLines])

    useEffect(() => {
        if (selectPositions.length > 0 && currSelectPositionRef.current) {
            onSelect1(selectPositions.map(item => item.key), currSelectPositionRef.current, positionData)
        }
    }, [selectPositions])

    useEffect(() => {
        setScaleSize(initScaleSize)
    }, [initScaleSize])

    //图片切分调整
    const adjust_img = () => {
        let canvas = fabricCanvasRef.current && fabricCanvasRef.current
        canvas && fabric.Image.fromURL(canvas.toDataURL(), function (n) {
            let params = new FormData()
            modifyPostions.forEach((item, index) => {
                let img = n.toCanvasElement(item)
                img.toBlob(function (f) {
                    params.append("image_" + index, f, "image_" + index)
                    index === modifyPostions.length - 1 && adjustImg && adjustImg(params)
                })
            })

        })
    }

    const onRectSelect = (selectKeys) => {
        if (dragable) {
            let canvas = fabricCanvasRef.current && fabricCanvasRef.current
            if (!canvas) {
                return
            }
            let rects = selectKeys.map(key => positionsRef.current[key] && positionsRef.current[key])
            if (canvas && rects.length > 0) {
                rects.forEach(rect => {
                    console.log(rect)
                    if (rect) {
                        canvas.setActiveObject(rect, 'object:selected');
                        canvas.requestRenderAll()
                    }
                })
            }
        } else {
            canvasInit(() => {
                var canvas = canvasRef.current
                if (canvas) {
                    var ctx = canvas.getContext("2d");
                    ctx.globalAlpha = 0.3
                    ctx.fillStyle = "#17b9a6";
                    ctx.beginPath();
                    ctx.lineWidth = "1";
                    ctx.strokeStyle = "red";
                    selectKeys.forEach(selectkey => {
                        let selectPosition = positionData.find(item => item.key === selectkey)
                        if (selectPosition) {
                            ctx.fillRect(selectPosition.x, selectPosition.y, selectPosition.width, selectPosition.height);
                            if (dragable) {
                                ctx.strokeRect(selectPosition.x, selectPosition.y, selectPosition.width, selectPosition.height)
                            }

                        }
                    })
                }
            })

        }

    }
    const onRowSelect = (x, y) => {
        var ps = positionData.find(t => x - t.x <= (t.width - 10) && x - t.x >= 0 && y - t.y <= (t.height - 10) && y - t.y >= 0)
        if (ps && !selectPositions.find(t => x - t.x <= t.width && x - t.x > 0 && y - t.y <= t.height && y - t.y > 0)) {
            let sps = multiSelect.current === true ? [...selectPositions, ps] : [ps]
            currSelectPositionRef.current = ps
            setSelectPositions(sps)
        } else {
            currSelectPositionRef.current = {}
            let sps = multiSelect.current === true ? [...selectPositions] : []
            setSelectPositions(sps)
        }
    }

    const onRectDelete = (rect_key) => {
        let canvas = fabricCanvasRef.current
        if (!canvas) {
            return
        }
        let selRects = [...canvas.getActiveObjects()]
        canvas.discardActiveObject();
        selRects.map(item => {
            canvas.remove(item)
        })
        canvas.requestRenderAll();
    }


    const onScale = (value) => {
        setScaleSize(value)
    }
    const onResetScale = (value) => {
        setScaleSize(value)
        setSelectPositions([])
    }

    //拆分
    const split = (type) => {//type: h | v
        let canvas = fabricCanvasRef.current
        if (!canvas) {
            return
        }
        let activeObjects = canvas.getActiveObjects()
        if (activeObjects.length !== 1) {
            return
        }
        let activeRect = activeObjects[0]

        if (type === 'h') {
            activeRect.set({ width: activeRect.width / 2 });
            activeRect.set({ stroke: '#ffb85a' })
        } else if (type === 'v') {
            activeRect.set({ height: activeRect.height / 2 });
        }
        activeRect.setCoords()
        canvas.discardActiveObject();
        const rect = new fabric.Rect({
            key: activeRect.key + '_1',
            left: type === 'h' ? (activeRect.left + activeRect.width - 2) : activeRect.left,
            top: type === 'v' ? (activeRect.top + activeRect.height - 2) : activeRect.top,
            width: type === 'h' ? (activeRect.width - 2) : activeRect.width,
            height: type === 'v' ? (activeRect.height - 2) : activeRect.height,
            strokeWidth: 2,
            fill: null,
            hasRotatingPoint: false,
            cornerColor: 'blue',
            cornerSize: 6,
            // opacity: 0.7,
            transparentCorners: false,
            lockMovementX: true,
            lockMovementY: true,
            stroke: type === 'h' ? '#6f7dd0' : activeRect.stroke,
            scaleX: scaleSize,
            scaleY: scaleSize,
        })
        canvas.add(rect)

        positionsRef.current[rect.key] = rect
        let v = new fabric.ActiveSelection([rect, activeRect], {
            canvas: canvas,
            hasRotatingPoint: false,
            cornerColor: 'blue',
            cornerSize: 6,
            transparentCorners: false,
        });
        canvas.setActiveObject(v, 'object:selected');
        canvas.requestRenderAll();
        let modRect = {
            left: activeRect.left,
            top: activeRect.top,
            right: activeRect.left + activeRect.width - 1,
            bottom: activeRect.top + activeRect.height - 1,
            width: activeRect.width,
            height: activeRect.height,
            isChanged: true
        }
        let modRect1 = {
            left: rect.left,
            top: rect.top,
            right: rect.left + rect.width - 1,
            bottom: rect.top + rect.height - 1,
            width: rect.width,
            height: rect.height,
            isChanged: true
        }
        setModifyPostions([...modifyPostions, [modRect, modRect1]])
    }

    //设置文字类型
    const setWordFlag = (wordFlag) => {//type: LeftSmall | RightSmall | common
        let canvas = fabricCanvasRef.current
        if (!canvas) {
            return
        }
        let activeRect = canvas.getActiveObject()
        switch (wordFlag) {
            case 'LeftSmall':
                activeRect.set({ stroke: '#ffb85a' })
                break;
            case 'RightSmall':
                activeRect.set({ stroke: '#6f7dd0' })
                break;
            default:
                activeRect.set({ stroke: 'red' })
                break;
        }
        canvas.requestRenderAll();
        let modRect = {
            left: activeRect.left,
            top: activeRect.top,
            right: activeRect.left + activeRect.width - 1,
            bottom: activeRect.top + activeRect.height - 1,
            width: activeRect.width,
            height: activeRect.height,
            isChanged: true
        }
        setModifyPostions([...modifyPostions, [modRect]])
    }

    //合并
    const combine = () => {
        let canvas = fabricCanvasRef.current
        if (!canvas) {
            return
        }
        let selRects = [...canvas.getActiveObjects()]
        let key = selRects[0].key
        if (selRects.length > 1) {
            let left = selRects.reduce((minleft, item) => {
                minleft = minleft < item.aCoords.tl.x ? minleft : item.aCoords.tl.x
                return minleft
            }, selRects[0].aCoords.tl.x)

            let top = selRects.reduce((mintop, item) => {
                mintop = mintop < item.aCoords.tl.y ? mintop : item.aCoords.tl.y
                return mintop
            }, selRects[0].aCoords.tl.y)
            let right = selRects.reduce((maxright, item) => {
                let right = item.aCoords.br.x
                maxright = maxright > right ? maxright : right
                return maxright
            }, selRects[0].aCoords.br.x)
            let bottom = selRects.reduce((maxbottom, item) => {
                let bottom = item.aCoords.br.y
                maxbottom = maxbottom > bottom ? maxbottom : bottom
                return maxbottom
            }, selRects[0].aCoords.br.y)
            let scaleX = selRects[0].scaleX
            let scaleY = selRects[0].scaleY

            const rect = new fabric.Rect({
                key: key,
                left: left,
                top: top,
                width: right - left,
                height: bottom - top,
                strokeWidth: 2,
                fill: null,
                hasRotatingPoint: false,
                cornerColor: 'blue',
                cornerSize: 6,
                // opacity: 0.7,
                transparentCorners: false,
                lockMovementX: true,
                lockMovementY: true,
                stroke: 'red',
                scaleX: scaleX,
                scaleY: scaleY
            })
            canvas.discardActiveObject();
            selRects.map(item => {
                canvas.remove(item)
                positionsRef.current[item.key] = rect
            })
            canvas.add(rect);
            canvas.setActiveObject(rect);
            canvas.requestRenderAll();
            let modRect = {
                left: rect.left,
                top: rect.top,
                right: rect.left + rect.width - 1,
                bottom: rect.top + rect.height - 1,
                width: rect.width,
                height: rect.height,
                isChanged: true
            }
            setModifyPostions([...modifyPostions, [modRect]])
        }

    }
    const setMultiSelect = (n) => {
        let canvas = fabricCanvasRef.current
        if (multiSelect.current === true) {
            let v = new fabric.ActiveSelection([...n.deselected, ...n.selected], {
                canvas: canvas,
                hasRotatingPoint: false,
                cornerColor: 'blue',
                cornerSize: 6,
                transparentCorners: false,
            });
            canvas.discardActiveObject();
            canvas.setActiveObject(v, 'object:selected');
            canvas.requestRenderAll();
        } else {
            selectRectsRef.current = []
        }
    }

    const arrowMove = event => {
        if (!dragable) {
            return
        }
        let clickWord = currSelectPositionRef.current
        if (clickWord.key) {

            let lineIndex = parseInt(clickWord.key.split('_')[0])
            let wordIndex = parseInt(clickWord.key.split('_')[1])
            // console.log(lineIndex + '_' + wordIndex+'---------------old');
            if (event.key === 'ArrowDown') {
                wordIndex = wordIndex + 1
            }

            if (event.key === 'ArrowUp') {
                wordIndex = wordIndex - 1
            }
            if (event.key === 'ArrowLeft') {
                lineIndex = lineIndex + 1
            }
            if (event.key === 'ArrowRight') {
                lineIndex = lineIndex - 1
            }
            let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
            // if(word){
            //     console.log(word.data.text+lineIndex+'-'+wordIndex);
            // }
            if (!word) {
                // console.log("词不存在");
                if (event.key === 'ArrowDown') {
                    wordIndex = 0
                    lineIndex = lineIndex + 1
                    let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
                    if (!word) {
                        lineIndex = 0
                    }
                }

                if (event.key === 'ArrowUp') {
                    wordIndex = 0
                    lineIndex = lineIndex - 1
                    let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
                    if (words && words.length > 0) {
                        word = words.pop()
                    } else {
                        word = positionData.pop()
                    }
                    lineIndex = word.key.split('_')[0]
                    wordIndex = word.key.split('_')[1]
                }
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
                    if (words && words.length > 0) {
                        let word = words.pop()
                        wordIndex = word.key.split('_')[1]
                    } else {
                        let word_wei = event.key === 'ArrowRight' ? positionData.pop() : positionData[0]
                        lineIndex = word_wei.key.split('_')[0]
                        let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
                        if (!word) {
                            word = word_wei
                        }
                        wordIndex = word.key.split('_')[1]
                    }

                }

            }

            let selWord = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
            // console.log(lineIndex + '_' + wordIndex+'------------new');
            onSelect1([lineIndex + '_' + wordIndex], selWord, positionData)
            onRectSelect([lineIndex + '_' + wordIndex])
        }

    }


    const menu = <Menu >
        <Menu.Item key="1" onClick={() => {
            onRectDelete(currSelectPositionRef.current.key)
        }}>{convert('删除(Backspace)', locale)}</Menu.Item>
        <Menu.Item key="2" onClick={() => {
            split('h')
        }}>{convert('左右拆分(S)', locale)}</Menu.Item>
        <Menu.Item key="3" onClick={() => {
            split('v')
        }}>{convert('上下拆分(W)', locale)}</Menu.Item>
        <Menu.Item key="4" onClick={() => {
            setWordFlag('Big')
        }}>{convert('大字(F)', locale)}</Menu.Item>
        <Menu.Item key="5" onClick={() => {
            setWordFlag('LeftSmall')
        }}>{convert('左小字(E)', locale)}</Menu.Item>
        <Menu.Item key="6" onClick={() => {
            setWordFlag('RightSmall')
        }}>{convert('右小字(R)', locale)}</Menu.Item>
        <Menu.Item key="7" onClick={() => {
            combine()
        }}>{convert('合并(M)', locale)}</Menu.Item>
        <Menu.Item key="8" hidden={modifyPostions.length === 0} onClick={() => {
            adjust_img()
        }}>{convert('重新识别(Enter)', locale)}</Menu.Item>
    </Menu>

    useKeyPress(
        KeyboardEvent => {
            var agent = navigator.userAgent.toLowerCase();
            var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
            if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {

            }
            if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {

            }
            if (isMac) {
                return KeyboardEvent.key === 'Command'
            } else {
                return KeyboardEvent.key === 'Control'
            }
        },
        event => {
            if (event.type === 'keyup') {
                multiSelect.current = false
            } else {
                multiSelect.current = true
            }
        },
        {
            events: ['keydown', 'keyup'],
            target: document.getElementById("keyboard_area")
        }
    )

    useKeyPress(
        ['s', 'w', 'f', 'e', 'r', 'm', 'delete', 'enter'],
        event => {
            if (!dragable) {
                return
            }
            switch (event.key) {
                case 's':
                    split('h')
                    break;
                case 'w':
                    split('v')
                    break;
                case 'f':
                    setWordFlag('Big')
                    break;
                case 'e':
                    setWordFlag('LeftSmall')
                    break;
                case 'r':
                    setWordFlag('RightSmall')
                    break;
                case 'm':
                    combine()
                    break;
                case 'Backspace':
                    onRectDelete(currSelectPositionRef.current.key)
                    break;
                case 'Enter':
                    adjust_img()
                    break;
                default:
                    break;
            }
        },
        {
            events: ['keyup'],
            target: document.getElementById("keyboard_area")
        }
    )
    useKeyPress(
        ['up', 'down', 'left', 'right'],
        arrowMove,
        {
            events: ['keyup'],
            target: document.getElementById("keyboard_area")
        }
    )
    return (
        img ? img.src && <Dropdown overlay={menu} disabled={!dragable} trigger={['contextMenu']}>

            <div onClick={onClick && onClick} style={{ ...style }} id="keyboard_area" tabIndex={!dragable ? -1 : 1}>
                <canvas ref={canvasRef} ></canvas>
                {/* {
                    showFooter &&
                    <ToolBar
                        total={total}
                        pageSize={pageSize}
                        onPageChange={(page) => {
                            onPageChange(page)
                        }}
                        initScaleSize={initScaleSize}
                        onScale={onScale}
                        onResetScale={onResetScale}
                        current={current}
                        locale={locale}
                    />
                } */}

            </div ></Dropdown> : <Spin></Spin>
    )
}

export default CanvasImg