export function loginSuccess(login, goToUrl) {
    return {
        type: 'get_login_success',
        payload: {
            login: login,
            goToUrl: goToUrl//登录成功后,将要跳转的页面
        }
    }
}
export function setNetworkSegment(networksegment) {
    return {
        type: 'set_networkSegment',
        payload: {
            networksegment: networksegment,
        }
    }
}