import React from 'react'
import { Layout, Button, Result } from 'antd';
import { Link, withRouter } from 'react-router-dom'
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
const { Content } = Layout;

class RegisterResult extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            result_code: this.props.match.params.result_code
        }
        this.locale = dayjs.locale()
    }
    render() {
        const { result_code } = this.state
        return (
            <Content className='bg-e7ecef'>
                <div className='content bg-fff login-box '>
                    <Result
                        status={result_code === '0' ? 'success' : 'warning'}
                        title={convert(result_code === '0' ? "注册成功!" : "注册失败!", this.locale)}
                        extra={[
                            <Link to='/login'><Button type="primary" key="console">
                                {convert('登录', this.locale)}
                            </Button></Link>,
                            <Link to='/register'><Button key="buy" > {convert('重新注册', this.locale)}</Button></Link>,
                        ]}
                    />
                </div>
            </Content>
        )
    }
}

export default withRouter(RegisterResult)