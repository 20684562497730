
let initState = {}
export function HandledDataToNameEntityReducer(state = initState, action) {

    switch (action.type) {
        case 'initNamedEntity': {
            return action.data.handledDataToNameEntity;
        }
        default:
            return state

    }
}