import React, { Component } from 'react';
import { Layout, Popover, Button, Upload, Modal, Input, Tabs, Switch, Checkbox, Spin, Radio, Slider, Menu, Dropdown, message, Row, Progress, Divider, Table, Col, Popconfirm, Space, Empty, Select } from 'antd';
import './NamedEntity.css';
import Icon from '@ant-design/icons';
import convert from '../../utils/locale_zh_convert';
import { UploadOutlined, ArrowRightOutlined, PlusOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, DeleteOutlined, CaretRightOutlined, ExportOutlined, FileTextOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';
import { withRouter } from 'react-router-dom'
import reqwest from 'reqwest'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import './../../data/mock_data'
import DemoWordCloud from './DemoWordCloud'
import { connect } from 'react-redux'
import { set_deal_content } from './../../actions/DealTextContentAction'
import loading from "../../images/loading.svg"
import doText from '../../utils/doText'
import $ from 'jquery'

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const Back = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" width="11px" height="14px" viewBox="0 0 11 14" enableBackground="new 0 0 11 14">  <image id="image0" width="11" height="14" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAMAAADOvxanAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAe1BMVEXy9/zH2eesxtva5vFU
iLCPsc3o8Phikrhvm77u9Pt3oMJShq/F1+bT4e3i6/RIf6tZjLOeu9Tw9fvj7PXV4u6rxdtViLFw
nL7g6vTO3uvn7/edu9RgkLbL2+lNgq2zyt/q8fnE1+a1zOCMr8tKgKtWibF4ocK+0uL///8Rc2Lj
AAAAAWJLR0QovbC1sgAAAAd0SU1FB+QJAQIIF7wjLYEAAABaSURBVAjXRcxHFoAgDEXR2CtSFOwV
y/53qOIhvsm/J4MAgOOCzfMDyzCKE+s0ywkpPlMT4+Jx+VZJVQv80CiOBsl+V7RAt7RD9wNynGaz
y7rpXX83Qo/zevYGKZAEjhKcqJsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDgtMzFUMTg6MDg6
MjMrMDg6MDCabfaCAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA4LTMxVDE4OjA4OjIzKzA4OjAw
6zBOPgAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRU
aHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAx
NPOhP0MAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMTF7ZAtBAAAAGXRFWHRUaHVtYjo6TWlt
ZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTg4Njg1MDMuR9qZAAAA
EXRFWHRUaHVtYjo6U2l6ZQAzODZCQjg00IkAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAv
dG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTk4ODYyMjc0Njc0MTE3M18xOF9bMF3NzS3qAAAAAElF
TkSuQmCC" ></image>
  </svg>
)
const Backs = props => <Icon component={Back} {...props} />;
// const plainOptions1 = ['棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生',];
function callback(key) {
  console.log(key);
}
function onChangeSwitch(checked) {
  console.log(`switch to ${checked}`);
}
class NamedEntity extends Component {
  constructor(props) {
    super(props)
    let content = ''
    let editable = true
    let jd_deal_content_ner = ''
    if (props.dealTextContent && props.dealTextContent.jd_deal_content
      && props.dealTextContent.jd_deal_content.deal_content) {
      content = props.dealTextContent.jd_deal_content.deal_content.join('')
      jd_deal_content_ner = props.dealTextContent.jd_deal_content.deal_content_ner.join('')
      editable = false
    } else if (props.dealTextContent && props.dealTextContent.mm_deal_content && props.dealTextContent.mm_deal_content.content) {
      content = props.dealTextContent.mm_deal_content.content.join('')
      editable = false
    } else if (props.dealTextContent && props.dealTextContent.content) {
      content = props.dealTextContent.content.join('')
      editable = false
    }
    console.log(props)
    this.state = {
      color: '#000000',
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      visible7: false,
      visible8: false,
      visible9: false,
      isModalVisible: false,
      value: 1,
      fileList: [],
      editable: editable,
      textcontent: content, //处理前文本
      deal_textcontent: [],//处理后文本
      pretextcontent: [],
      progress: 0,
      complete: true,
      action: '4',
      text_type: 'a',//实体模式
      search_type: "machine_learning",
      tagList: [],
      tag_name: '',
      tag_key: 'peop',
      statisticsList: [],//标记统计,总览列表
      keywordStatisticsList: [],//标记统计,图表
      task_uid: this.props.dealTextContent ? this.props.dealTextContent.uid : '',
      task_identify: this.props.dealTextContent ? this.props.dealTextContent.identify : '',
      versionList: [],
      keyword: '',
      currStyle: undefined,
      searchResult: [],
      selectedRowKeys: [],
      addTagtype: '2',
      selectText: '',
      deal_textcontent2: [],
      threshold: "20",
      all_text_rade: [],
      keyword_arr: {},
      fildId: '',
      deskHeight: document.body.clientHeight,
      rate: !localStorage.getItem("token") ? 12 : 20,
      rateNumMask: 20,
      rateVisile: this.props.handledData ? this.props.handledData.rateNumMask : false,
    };
    this._jd_deal_content_ner = jd_deal_content_ner
    this.locale = dayjs.locale()
    this._textcontent = ''////处理前文本缓存
    this._deal_textcontent = this.props.dealTextContent && this.props.dealTextContent.mm_deal_content && this.props.dealTextContent.mm_deal_content.deal_content_code ? this.props.dealTextContent.mm_deal_content.deal_content_code.join('') : ''////处理后文本缓存
    this._ner_index = null////处理后标注缓存
    this._tagList = []////处理后标注缓存
    this.handledtextcontent = this.props.handledDataToNameEntity.deal_textcontent;
    this.fildID = this.props.handledDataToNameEntity.fildID;
    this.columns1 = [
      {
        key: 'tag_name',
        title: convert('类别', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles && row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_times',
        title: convert('去重', this.locale),
        dataIndex: 'tag_times',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      },
      {
        key: 'tag_times_total',
        title: convert('总计', this.locale),
        dataIndex: 'tag_times_total',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      }
    ]
    this.columns2 = [
      {
        key: 'keyword',
        title: convert('实体', this.locale),
        dataIndex: 'keyword',
        sorter: (a, b) => {
          let acol = a.keyword
          let bcol = b.keyword
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_times',
        title: convert('频次', this.locale),
        dataIndex: 'tag_times',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      },
      {
        key: 'tag_name',
        title: convert('类型', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      }
    ]
    this.columns3 = [
      {
        key: 'tag_name',
        title: convert('类别', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_key',
        title: convert('类别标识', this.locale),
        dataIndex: 'tag_key',
        sorter: (a, b) => {
          let acol = a.tag_key
          let bcol = b.tag_key
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'options',
        title: convert('操作', this.locale),
        dataIndex: 'options',
        render: (text, row, index) => {
          return <Space>
            <Popconfirm
              title={convert("确定删除该类别?", this.locale)}
              onConfirm={() => { this.deleteTag(row) }}
              okText={convert("确定", this.locale)}
              cancelText={convert("取消", this.locale)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <span style={{ color: 'red' }}><Space><DeleteOutlined />{convert('删除', this.locale)}</Space></span>
            </Popconfirm>
            <Divider type='vertical' />
            <Popconfirm
              title={convert("确定" + (row.visible ? '隐藏' : '显示') + "该类别?", this.locale)}
              onConfirm={() => { this.setTagVisible(row.id, !row.visible) }}
              okText={convert("确定", this.locale)}
              cancelText={convert("取消", this.locale)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              {
                row.visible ? <span style={{ color: '#121c80' }}><Space><EyeOutlined />{convert('显示', this.locale)}</Space></span>
                  : <span style={{ color: '#121c80' }}><Space><EyeInvisibleOutlined />{convert('隐藏', this.locale)}</Space></span>
              }
            </Popconfirm>
          </Space>
        }
      },
    ]
  }

  onChangeRa = e => {
    e.stopPropagation();
    this.setState({
      addTagtype: e.target.value,
    });
  };
  onChange1 = e => {
    console.log(e.target.value)
    this.setState({
      tag_key: e.target.value
    })
    // console.log(this.state.value1)
  };

  tagtext = (callback) => {
    let _this = this
    if (!_this.state.textcontent) {
      message.error('待处理文本不能为空,请上传/粘贴文本')
      _this.setState({
        action: '1'
      })
      return
    }
    let uid = _this.state.task_uid
    let identify = _this.state.task_identify

    _this.setState({
      visible5: true,
      complete: false,
      progress: 0,
    }, () => {
      _this.progressRun()
      // let sentence = _this._jd_deal_content_ner ? _this._jd_deal_content_ner : _this.state.textcontent
      let sentence = _this.state.textcontent
      sentence = sentence.replace(/ο/g, "〇")
      //sentence = sentence.replace(/\r\n/g,'◉') //...
      //sentence = sentence.replace(/◉{2,}/g,'◉') //...
      sentence = sentence.replace(/\s+/g, '')
      sentence = sentence.replace(/〔/g,'[')
      sentence = sentence.replace(/〕/g,']')
      console.log(sentence)

      var reqXhr = new XMLHttpRequest();
      var form = new FormData();
      form.append("sentence", sentence)
      reqXhr.open('POST', '/wyd/predict/getRel', false);
      reqXhr.responseType = 'json';
      reqXhr.setRequestHeader("WYD-TOKEN", localStorage.getItem("token") == null ? "" : localStorage.getItem("token"))

      reqXhr.onload = function () {
        let data = JSON.parse(this.response);
        console.log('ner_index is',data.data.index)
        if (data.code == 200) {
          let fildID = data.data.id;
          let content = data.data.content
          let ner_index = data.data.index;
          let source = data.data.source;
          _this._deal_textcontent = content
          _this._textcontent = _this.state.textcontent
          _this._ner_index = ner_index;
          let dataSou = data;

          // 得到响应头，剩余次数
          var rate = reqXhr.getResponseHeader('rate-1d');
          let rateNum = false;
          if (rate) {
            rateNum = true;
            let arrRate = rate.split("/");
            console.log(arrRate[0]);
            let rateNumMask = arrRate[1] - arrRate[0]
            let tempToken = localStorage.getItem("token");
            let tempID = localStorage.getItem("id");
            if (tempToken) {
              let rateobj = localStorage.getItem(tempID);
              if (!rateobj) {
                rateobj = {};
              } else {
                rateobj = JSON.parse(rateobj);
              }
              rateobj['rateNumMask'] = rateNumMask;
              rateobj = JSON.stringify(rateobj);
              localStorage.setItem(tempID, rateobj);

            } else {
              // _this.setState({rateVisile:false})
              localStorage.setItem("rateNumMask", rateNumMask);
            }
          }
          _this.doTextMask(source,rateNum,fildID,dataSou)
          _this.props.history.push('/RelationExtDisposed')
        } else if (data.code == 500) {
          message.error(data.message);
        }
        callback && callback()
        _this.setState({
          visible5: false,
          complete: true,
        })
      }
      reqXhr.send(form);

    })

  }

  changeAction = (action) => {

    let _this = this
    if (action === '1' || action === '3' || action === '5') {
      _this.setState({
        visible2: true
      })
      return;
    }
    if (action === '6' || action === '7') {
      return;
    }
    if (!_this.state.textcontent) {
      message.error('待处理文本不能为空,请上传文本')
      _this.setState({
        action: '1'
      })
      return
    }
    // 找到含〇的个数
    // var pattern = /[〇]/g,
    var str = _this.state.textcontent;
    var thisCount = 0;
    str.replace(/[〇，。？！]/g, function (m, i) {
      //m为找到的〇元素、i为索引
      thisCount++;
    });
    // var strs = null;
    // strs=str.split('〇');
    // thisCount=strs.length-1;
    let checkFlag = true;
    if (_this.state.textcontent.length > 20 && thisCount * 100 / _this.state.textcontent.length < 0.06 * 100) {
      checkFlag = false;
    }

    if (!checkFlag) {
      _this.setState({ isModalVisible: true });
      _this.confirm();
    } else {
      _this.setState({
        action: action,
        text_type: 'a',
      }, () => {
        if (action === '2' || action === '4')
          this.tagtext();

      })

    }
  }
  confirm = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请您上传/粘贴带标点的文本或先执行“自动标点/句读”',
      okText: '确认',
      width: '500px',
      onOk: () => this.handleAutomatic(this.state.textcontent),
      onCancel: () => {
        this.setState({
          isModalVisible: false
        })
      },
      cancelText: "取消",
    });
  }
  handleAutomatic = (textcontent) => {
    // 到句读中进行标点处理
    let todoHandleDataObj = {};
    todoHandleDataObj.content = textcontent;
    todoHandleDataObj.flag = 1;
    this.props.dispatch({ type: "todoHandleAutomaticDu", data: { todoHandleData: todoHandleDataObj } });
    this.props.history.push('/BiaoDian')
  }
  progressRun = () => {
    let _this = this
    if (!_this.state.complete) {
      if (_this.state.progress < 100) {
        _this.setState({ progress: _this.state.progress + 5 })
      }
      setTimeout(() => {
        _this.progressRun()
      }, 1000)
    }
  }



  handleColorChange = (colorCode) => {
    this.setState({ color: colorCode.hex })
  }
  componentDidMount() {

    let gson = {
      "categories": {
        "person": "人物",
        "event": "事件",
        "location": "地点"
      },
      "data": {
        "nodes": [{
            "label": "共读西厢",
            "value": 2,
            "id": 3779,
            "categories": [
              "event"
            ],
            "info": "宝玉到沁芳桥边桃花底下看《西厢记》，正准备将落花送进池中，黛玉说她早已准备了一个花冢，正来葬花。黛玉发现《西厢记》，宝玉借书中词句，向黛玉表白。黛玉觉得冒犯了自己尊严，引起口角，宝玉赔礼讨饶，黛玉也借《西厢记》词句，嘲笑了宝玉。于是两人收拾落花，葬到花冢里去。"
          },
          {
            "label": "林如海捐馆扬州城",
            "value": 4,
            "id": 3780,
            "categories": [
              "event"
            ],
            "info": "林如海考中探花后，迁为兰台寺大夫，钦点为扬州巡盐御史。后身染重病于九月初三日巳时而亡。"
          },
          {
            "label": "海棠诗社",
            "value": 8,
            "id": 3781,
            "categories": [
              "event"
            ],
            "info": "初秋季节，贾探春提议邀集大观园中有文采的人所组的诗社。诗社成立目的 旨在“宴集诗人於风庭月榭；醉飞吟盏於帘杏溪桃，作诗吟辞以显大观园众姊妹之文采不让桃李须眉。”诗社成员 有林黛玉、薛宝钗、史湘云、贾迎春、贾探春、贾惜春、贾宝玉及李纨。稻香老农(李纨)为社长，菱洲(迎春)、藕榭（惜春）为副社长，一人出题，一人监场。"
          },
          {
            "label": "紫鹃试玉",
            "value": 2,
            "id": 3782,
            "categories": [
              "event"
            ],
            "info": "紫鹃想试试宝玉对黛玉的情意，就单独骗他说自己要和黛玉回苏州，宝玉本来不信，但在紫鹃一番骗小孩的语言下，痴情迷糊的宝玉还是相信了。就这一骗弄得宝玉是发了疯一样，指着墙上画中的小船说那是来接林妹妹的船……后来弄得大家都慌了，最后在紫鹃诉说下才好起来。真是个可爱迷糊又痴情的人。"
          },
          {
            "label": "魇魔姊弟",
            "value": 6,
            "id": 3783,
            "categories": [
              "event"
            ],
            "info": "贾环用油灯烫了宝玉后，王夫人骂了赵姨娘没好好管教。赵姨娘受气后，正巧马道婆来和她闲聊。赵姨娘在闲聊过程中暴露出了对凤姐和宝玉的不满，马道婆于是献计用魇魔法暗里算计。赵姨娘就出钱央求马道婆实施了这一毒招。话分两头，宝玉挨烫了之后，林妹妹来看他，凤姐等也来凑趣。凤姐姐开玩笑要林妹妹做贾家的媳妇。后来众人要走，宝玉要林妹妹站住说句话，正想说体己话，林妹妹羞得要走，就在此时马道婆作法生效了，宝玉闹头痛，跟着凤姐也发了狂。二人请医延药，眼见不济事，来了癞头和尚和跛足道人，用宝玉佩戴的宝玉救了二人。"
          },
          {
            "label": "羞笼红麝串",
            "value": 2,
            "id": 3784,
            "categories": [
              "event"
            ],
            "info": "然后是元春从宫中赏出很多东西，每个人都有，但是宝玉和宝钗的是一样的，黛玉的和三姐妹的是一样的，宝玉很是纳闷，看见宝钗便要看那红麝串，宝钗生的珠圆玉润，串子不容易退下来，宝玉看宝钗的臂膀产生的种种幻想，被宝钗看出，于是宝钗害羞的将串子抛给宝玉，这场景恰巧被黛玉看到。"
          },
          {
            "label": "麒麟伏双星",
            "value": 3,
            "id": 3785,
            "categories": [
              "event"
            ],
            "info": "可能暗示某两人白头偕老，或者是某两个人老了以后双双遇到，结合到一起。其中女方应该是史湘云（她也有一个金麒麟），但男方是谁，有点说不准，可能是卫若兰，可能是贾宝玉，也有可能是别的什么人。"
          },
          {
            "label": "纳鸳鸯",
            "value": 6,
            "id": 3786,
            "categories": [
              "event"
            ],
            "info": "鸳鸯是贾母的贴身丫环，除了负责贾母的日常起居饮食之外，还掌握着贾母钱箱的钥匙，对贾母的钱财了如指掌。可以说谁娶了鸳鸯，谁就等于掌握了贾母的私房钱，可惜，知子莫若母，贾赦的计谋被贾母识穿，最终未能得逞。"
          },
          {
            "label": "撵晴雯",
            "value": 4,
            "id": 3787,
            "categories": [
              "event"
            ],
            "info": "晴雯长得风流灵巧，眉眼儿有点像林黛玉，口齿伶俐，针线活尤好，曾为宝玉病补雀金裘。 她本是赖妈妈家的丫鬟，因得贾母喜爱，固把她赐给宝玉。因娇生惯养，养成得理不饶人的性格。一次王夫人进园子看见她打骂小丫鬟，觉得她不合世家教养，再加上王善保家的等小人进谗，王夫人在她病得“四五日水米不曾沾牙”的情况下，从炕上拉下来，硬给撵了出去（放出去）。宝玉偷偷前去探望，晴雯深为感动。当夜，晴雯悲惨地死去。听小丫头说晴雯死后做了芙蓉花神，宝玉便作了篇长长的《芙蓉女儿诔》寄托哀思。"
          },
          {
            "label": "偷娶尤二姐",
            "value": 4,
            "id": 3788,
            "categories": [
              "event"
            ],
            "info": "尤二姐模样标致，温柔和顺。贾珍馋涎妻妹的美貌，对尤二姐无微不至，当他玩腻后，就把她让给了贾琏。贾琏因惧怕王熙凤的淫威，只得偷偷娶尤二姐为二房，并把她安置在荣国府外，但不久被王熙凤发现，在她的借刀杀人计谋下，尤二姐备受折磨，当胎儿被庸医打下后，她绝望地吞金自尽。"
          },
          {
            "label": "软语救贾琏",
            "value": 3,
            "id": 3789,
            "categories": [
              "event"
            ],
            "info": "巧姐儿长水痘，贾府供奉天花娘娘，贾琏与多浑虫之妻相好，可是12天后巧姐儿病好了，贾琏搬回去住，凤姐命令平儿仔细搜查贾琏的行李，平儿搜到一缕青丝，却对凤姐说什么都没有。"
          },
          {
            "label": "大闹学堂",
            "value": 7,
            "id": 3790,
            "categories": [
              "event"
            ],
            "info": "宝玉准备入塾，袭人为他收拾妥当，叮嘱宝玉功课不必过于操劳。宝玉向家中长辈辞行之后，又独去向黛玉作辞，不辞宝钗。上学期间，宝玉与秦钟因平日就玩的很好且宝玉认为他们都是一类人就形影不离，引发不少风言风语。秦钟又因与“香怜”交好而引发贾家远亲金荣（贾璜之妻的侄儿）争风吃醋，适逢代儒外出，其孙儿贾瑞因私心处理不公，引起纠纷进而引发学堂内的一场大混战，最后以金荣被迫磕头道歉而告一段落。"
          },
          {
            "label": "拐卖巧姐",
            "value": 2,
            "id": 3791,
            "categories": [
              "event"
            ],
            "info": " 王仁要送巧姐，正为此烦恼，臭味相投，找贾芹喝酒，席间大骂贾府无情无义，贾琏不是东西，妹妹不争气，给王家抹黑，让自己蒙羞。贾芹见时机成熟，说动王仁，这样的动乱时机，谁会顾及一个小女孩的生死，莫若途中乘机买了巧姐，谎称说路上病死了，也就完事了。这样既不会祸害王家，也不会得罪王子腾，也给了贾府一个交代，贾琏和王熙凤是万难出来了，根本不会再有人过问此事。王仁仗着酒气，权衡利弊，一咬牙一跺脚一横心，同意了。这就是王仁和贾芹，一个狠舅，一个奸兄的由来，一个是枉为人，一个假情假意，他们决计要卖了巧姐，报仇雪恨，顺便发笔横财。王熙凤到血崩之症流血而死之时恐怕也没有想到，她的亲人会如此绝情，会如此来算计她的女儿。人性的复杂和丑恶，由此可见。"
          },
          {
            "label": "乱判葫芦案",
            "value": 5,
            "id": 3792,
            "categories": [
              "event"
            ],
            "info": "贾宝玉的表兄薛蟠在应天府与一个小户人家的公子冯渊同时看上了一个被拐的丫头香菱，拐子（就是人口贩子）又一奴卖二主将香菱同时卖于二人，被发觉后二人皆不要退钱只要香菱，薛蟠家大势大，在与冯渊争执时纵奴行凶将其打死，薛蟠本定进京，打死冯渊之后没事人一样就进京探亲了，冯家告上官府。偏生应天府尹是受贾府推介的贾雨村，贾雨村在深知内情的昔日葫芦庙庙祝今日应天府门子的指导下，将这一起人命官司草草了结。"
          },
          {
            "label": "毒设相思局",
            "value": 5,
            "id": 3793,
            "categories": [
              "event"
            ],
            "info": "贾敬的寿辰宁府排家宴，贾瑞在园子里碰见熙凤就起色心。此后几次到荣府都没找到熙凤。贾瑞见到凤姐后，凤姐要他晚上在西穿堂相见。贾瑞按约定晚上钻入穿堂，腊月天寒，白冻一晚而归。代儒惩罚贾瑞跪在院内读文章，打了三四十大板，不许吃饭。第二次又在凤姐房后小过道里那座空房子，被贾蓉、贾蔷捉弄。 贾瑞不听跛道人之言，正照风月宝鉴，一命呜呼。"
          },
          {
            "label": "情赠茜香罗",
            "value": 2,
            "id": 3794,
            "categories": [
              "event"
            ],
            "info": "主要讲的就是宝玉初次遇见蒋玉菡，两人相互觉得彼此投缘，于是宝玉将自己的扇子坠送给蒋玉菡，蒋玉菡将北静王送的大红汗巾子送给宝玉，作为交换，宝玉将自己的松花色汗巾子结下送给他。"
          },
          {
            "label": "勇救薛蟠",
            "value": 2,
            "id": 3795,
            "categories": [
              "event"
            ],
            "info": "时过境迁，二十回后柳湘莲再度登场，这大概是五年后了。这一次，他性格里的更多光彩得到展现。刚才陈述的是他“惩强”的一面，接下来该介绍他“扶弱”的一面了。遭薛蟠调戏时，他已经“又气又恨”了，但看到薛蟠财物被劫，性命堪忧的时候，他又奋不顾身，挺身斗贼。这样的义举，即使放在今天的法制社会里，也是值得表彰褒奖的英雄之事。他还宽容了薛蟠的过错，与之结拜了生死弟兄，又体现出这位江湖侠客的宽广胸襟。"
          },
          {
            "label": "倪二轻财尚义",
            "value": 2,
            "id": 3796,
            "categories": [
              "event"
            ],
            "info": "贾芸想要去他的舅舅卜世仁哪里佘一些麝香、冰片之类的东西，拿去贿赂凤姐，让凤姐给他一个差事，但是卜世人没有同意。贾芸回家的路上遇见了醉金刚倪二刚刚收完利息，贾芸不小心撞上了倪二，倪二本想大骂，后来看见是贾芸就没有骂人，还跟贾芸闲聊了几句，闲聊之中贾芸把事情跟倪二说了，没想到倪二很慷慨的就借给了贾芸钱，倪二本事的放高利贷的，但是这回去没有收利息，借据还是贾芸硬要给的，所以曹公称之为“义侠”。"
          },
          {
            "label": "神游太虚幻境",
            "value": 3,
            "id": 3797,
            "categories": [
              "event"
            ],
            "info": "贾宝玉观赏会芳园的梅花后，在侄媳秦可卿的卧房里睡午觉，做了个神游太虚幻境的美梦。梦中，他遇到了袅娜翩跹的警仙幻姑。仙姑邀请宝玉到她居住的太虚幻境一游。到了那里，忽见有一座牌坊，上书“太虚幻境”四个大字。进入二层门内，只见两边配殿内有“痴情司”， “结怨司”， “朝啼司”， “暮哭司”，“春感司”，“秋悲司”等诸司。宝玉在“薄命司”里看了金陵十二钗正册、副册、又副册。入室，闻一缕幽香，品“千红一窟”茶，饮“万艳同悲”酒，聆听《红楼梦》仙曲十二支。仙姑又将其妹秦可卿许配给宝玉，并授以云雨之事。宝玉与可卿柔情缱绻，软语温存，难解难分。次日，二人携手游玩，至迷津处，被雷声惊醒。"
          },
          {
            "label": "借剑杀人",
            "value": 3,
            "id": 3798,
            "categories": [
              "event"
            ],
            "info": "王熙凤她布置的将尤二姐骗入大观圆，令丫头虐待，向老太太告恶状，在尤二姐怀孕后请医生做手脚使其小产，最后让秋桐担罪名，逼得尤二姐吞金自杀。“弄小巧借剑杀人”中的“剑”指的就是丫头秋桐，杀的就是尤二姐。"
          },
          {
            "label": "平儿失镯",
            "value": 4,
            "id": 3799,
            "categories": [
              "event"
            ],
            "info": "平儿的镯子在一次和大家吃烤鹿肉的时候为了方便而被平儿取了下来放在一旁，这虾须镯本是一对，没想到取下的那一只突然消失了，只剩下一只了。平儿很喜欢这镯子，也一直找，没想到最后查出来是怡红院中的宝玉的丫鬟坠儿盗取了。平儿为了不让宝玉生气难堪，将镯子拿了回来之后骗大家说，这镯子藏在雪里了，雪融了才发现，并不是被人偷了。一个镯子倒也体现了平儿温和大方的性格。后来晴雯将坠儿撵了出去。"
          },
          {
            "label": "平儿行权",
            "value": 3,
            "id": 3800,
            "categories": [
              "event"
            ],
            "info": "柳嫂子是厨房总管，她的女儿柳五儿想进怡红院当差，因为生病暂时在厨房帮忙。后来被冤枉偷了玫瑰露，那个时候凤姐生病， 很多事情都由平儿来处理，平儿知道柳五儿是冤枉的，所以做主放了柳五儿。"
          },
          {
            "label": "司棋被捉",
            "value": 3,
            "id": 3801,
            "categories": [
              "event"
            ],
            "info": "潘又安与司棋在大观园幽会被鸳鸯撞见，畏罪远遁。他发财回家，意欲迎娶司棋，无奈司母不从，司棋撞墙身亡，他也殉情自尽。"
          },
          {
            "label": "巧结梅花络",
            "value": 3,
            "id": 3802,
            "categories": [
              "event"
            ],
            "info": "莺儿是薛宝钗的丫头，手特别巧。每一种样式名称、编法、色彩搭配，清清楚楚，了然于心。因此宝玉请莺儿打一些络子，装点扇子，香坠儿，汗巾子。"
          },
          {
            "label": "亲尝莲叶羹",
            "value": 2,
            "id": 3803,
            "categories": [
              "event"
            ],
            "info": "宝玉挨打后想吃莲叶羹。王熙凤就着人做了。后来王夫人打发玉钏给宝玉送汤。因为宝玉算是间接害死了玉钏的姐姐。玉钏就不大搭理宝玉。宝玉喝了一口莲叶羹之后，故意逗玉钏，说这汤不好喝。玉钏说这还不好吃那什么好吃，宝玉说，那你尝尝。玉钏就尝了一尝。"
          },
          {
            "label": "宝玉挨打",
            "value": 5,
            "id": 3804,
            "categories": [
              "event"
            ],
            "info": "宝玉挨打发生在第三十三回，地点是贾政书房，时间是夏季午休后。它的根本原因是他交结贾家敌对势力忠顺亲王的戏子，犯了政治大忌；导火索是贾环在政老爹面前诬告宝玉“强奸（母婢）未遂，（金钏）赌气投井”。贾政闻而暴怒，回书房关门将宝玉一顿毒打。总共打了大约五十板子，贾母赶来已迟，严斥贾政后，命人将奄奄一息的宝玉从书房带回自己屋里。贾政三番落泪，事后也颇悔下手太重，后脚跟进贾母屋里欲看宝玉，又被贾母斥出屋里。它暗衔“清虚观打醮”、“冯紫英寿宴”两节，近接上一回的“吃胭脂金钏语成谶”，是全书的情节高潮之一。寿宴妓女云儿（告密者）、书房来客贾雨村（参与告密）都不是闲笔。"
          },
          {
            "label": "大闹厨房",
            "value": 3,
            "id": 3805,
            "categories": [
              "event"
            ],
            "info": "司棋让小丫头莲花儿到厨房要碗鸡蛋，没有要到，还惹了一句“头层主子、二层主子”的话，便率众丫头打砸小厨房搞得柳嫂子吃个哑巴亏。司棋此举虽然无理、霸道，但是好爽！"
          },
          {
            "label": "香菱学诗",
            "value": 3,
            "id": 3806,
            "categories": [
              "event"
            ],
            "info": "香菱学诗，是曹雪芹著作《红楼梦》中的经典故事，被选入语文课本。脂砚斋对此有精辟的分析：“细想香菱之为人也，根基不让迎探，容貌不让凤秦，端雅不让纨钗，风流不让湘黛，贤惠不让袭平，所惜者幼年罹祸，命运乖蹇，致为侧室。且曾读书，不能与林湘辈并驰于海棠之社耳。然此一人岂可不入园哉。故欲令入园，终无可入之隙，筹画再四，欲令入园必呆兄远行后方可。”"
          },
          {
            "label": "凤姐托孤",
            "value": 2,
            "id": 3807,
            "categories": [
              "event"
            ],
            "info": "贾府被抄后，贾母去世，刘姥姥三进贾府，没想到贾府已败落不堪，内忧外患，凤姐见姥姥，往事难回首。凤姐病重，临终含泪把女儿巧姐托付给刘姥姥，并从手腕上褪下一只金镯子交给她，村妇刘姥姥却拒收了，但她爽快地答应了日后照顾巧姐。"
          },
          {
            "label": "旺儿妇霸成亲",
            "value": 3,
            "id": 3808,
            "categories": [
              "event"
            ],
            "info": "来旺家的儿子，看上了王夫人屋里的彩霞。来旺家的依仗凤姐的权势，强迫彩霞的父母结亲。"
          },
          {
            "label": "弄权铁槛寺",
            "value": 2,
            "id": 3809,
            "categories": [
              "event"
            ],
            "info": "凤姐到了水月庵安顿下后，一位老尼乘机，求凤姐办一件事，说长安城里有一户财主姓张，张家小姐有一次进庙烧香，被李衙内看上，李公子便要定要娶这位张小姐，可小姐已经受了前任长安守备公子的聘礼。那张财主便想将女儿嫁与李衙内，要退了守备家的婚约，守备家当然不同意，便与之打起了官司。现在便请凤姐，由贾府出面让守备家主动推婚。凤姐便要求送与她三千两百银，便出手帮这个忙，把退婚这件事给做个了解。"
          },
          {
            "label": "智能偷情",
            "value": 3,
            "id": 3810,
            "categories": [
              "event"
            ],
            "info": "智能儿，水月庵的小尼姑。净虚的徒弟，自幼在贾府走动，无人不识，也常和宝玉、秦钟玩笑。长大后渐知风情，看上秦钟人物风流，秦钟也爱她妍媚。两人情投意合，在馒头庵时幽会数次，秦钟返回家后，她从水月庵私逃出来找秦钟，不意被秦钟父亲秦业知觉，将她逐出，后不知去向。"
          },
          {
            "label": "勾引薛蝌",
            "value": 3,
            "id": 3811,
            "categories": [
              "event"
            ],
            "info": "夏金桂大概是看到薛蟠此次出事没命回来，就对老公的兄弟薛蝌动了念头，她的丫头宝蟾虽说给薛蟠做了房里人，但见薛蟠出事，她们主仆二人各怀心事，都把希望放到了薛蝌的身上。先是宝蟾去试探薛蝌，再是主仆二人联手勾引薛蝌。"
          },
          {
            "label": "贾政借钱",
            "value": 2,
            "id": 3812,
            "categories": [
              "event"
            ],
            "info": "贾政扶了贾母灵柩一路南行，因盘缠不够，不得已，写书一封，“差人到赖尚荣任上借银五百”。几天后那家人回来，将赖尚荣的禀启呈上，书内告了多少苦处，备上白银五十两。贾政看了大怒，即命家人：“立刻送还！将原书发回，叫他不必费心。”"
          },
          {
            "label": "探春远嫁",
            "value": 2,
            "id": 3813,
            "categories": [
              "event"
            ],
            "info": "探春判词：后面又画着两人放风筝，一片大海，一只大船，船中有一女子，掩面泣涕。也有四句云：才自精明志自高，生于末世运偏消。清明涕送江边望，千里东风一梦遥。"
          },
          {
            "label": "刘姥姥一进荣国府",
            "value": 4,
            "id": 3814,
            "categories": [
              "event"
            ],
            "info": "刘姥姥家因年关将近，却手头拮据，想起先祖做官时曾在京中认荣国府做了亲戚，便到荣国府讨些过年的钱。见到了当家的二奶奶王熙凤，凤姐将丫头们准备做冬衣的钱给了姥姥，为此刘姥姥十分感激她，并为将来救出凤姐的女儿巧姐埋下了伏线。"
          },
          {
            "label": "黛玉葬花",
            "value": 2,
            "id": 3815,
            "categories": [
              "event"
            ],
            "info": "林黛玉最怜惜花，觉得花落以后埋在土里最干净，说明她对美有独特的见解。她写了葬花词，以花比喻自己，在《红楼梦》中是最美丽的诗歌之一。贾宝玉和林黛玉在葬花的时候有一段对话，成为《红楼梦》中一场情人之间解除误会的绝唱。"
          },
          {
            "label": "宝钗扑蝶",
            "value": 3,
            "id": 3816,
            "categories": [
              "event"
            ],
            "info": "薛宝钗在滴翠亭附近赏春扑蝶的场景，表现了宝钗活泼、旷达的性格。此事之后发生的“滴翠亭事件”，则体现了宝钗的善良机智。此回情节作者通过对人物的刻画及行动的描绘，生动、形象地再现了人物活动的场面，并且为读者展现了一副不可多得的仕女图。"
          },
          {
            "label": "金钏投井",
            "value": 3,
            "id": 3817,
            "categories": [
              "event"
            ],
            "info": "贾宝玉调戏金钏戏说：跟太太讨了她去一起。金钏反调笑宝玉让他去赵姨娘处捉贾环和丫头的奸被假寐的王夫人听到，遂怒起打了金钏一掌说她教坏少爷了。宝玉没敢为金钏辩解吓跑了，金钏被逐出府羞愤自尽！"
          },
          {
            "label": "大观园试才",
            "value": 5,
            "id": 3818,
            "categories": [
              "event"
            ],
            "info": "贾宝玉和姐姐贾元春之间的感情深厚，贾政想让元妃高兴，就让贾宝玉来题大观园里的匾额和对联，让元妃看到爱弟的进步，这回主要讲的就是贾政带着贾宝玉在大观园里考验儿子的才学，为各处题匾,作对联的故事。"
          },
          {
            "label": "秦可卿淫丧天香楼",
            "value": 3,
            "id": 3819,
            "categories": [
              "event"
            ],
            "info": "秦可卿嫁给贾蓉不久，就与公公贾珍有了首尾，且二人常在天香楼约会，二人自为众人不知，殊不知宁府下人早看接触端倪，只是慑于贾珍族长的身份，皆不敢声张。但有三个人一直被蒙在鼓里，即秦可卿的两个丫鬟瑞珠和宝珠，以及她的婆婆尤氏，直到有一天晚上，公媳再次私会于天香楼上，但恰好被前来找少奶奶的两个丫鬟撞见了贾珍和秦可卿的“好事”，贾珍匆忙间更衣逃走，秦可卿也慌忙更衣下楼，一不小心遗下了自己常戴的一根簪子在贾珍处。第二天，这根簪子被尤氏从贾珍换下的衣服中获得，因此产生了怀疑，遂唤秦可卿的两个丫鬟来问，两个丫鬟在尤氏的威逼利诱下，大哭着说出真相，此事被秦可卿贾珍得知，秦氏觉得自己再无脸面苟活，于是在一个晚上自缢于天香楼。"
          },
          {
            "label": "迎春误嫁中山狼",
            "value": 3,
            "id": 3820,
            "categories": [
              "event"
            ],
            "info": "贾迎春过门后极尽妇道，对孙恩爱有加，但不久发现孙绍祖一味好色，好赌酗酒……好不好，打一顿撵在下房里睡去……”，孙视迎春为买来的奴仆，动辄非打即骂。迎春乃名门闺秀，性本娇弱，那里经得起如此蹂躏折磨，不久便香销玉殒，一命归西。"
          },
          {
            "label": "金玉良缘",
            "value": 2,
            "id": 3821,
            "categories": [
              "event"
            ],
            "info": "宝玉身上有一块玉，宝钗有一块金锁，合起来就叫金玉良缘，薛家与贾家两大家族的结合，门当户对的意思。"
          },
          {
            "label": "王熙凤协理宁国府",
            "value": 3,
            "id": 3822,
            "categories": [
              "event"
            ],
            "info": "秦可卿死，宁国府贾珍的妻子尤氏装病不理事，贾珍只好请王熙凤来主持丧事。王熙凤为了显示自己才干，定时点卯，分派任务，处理的井井有条，并且出发了故意搞乱给凤姐下马威的一个。众人听从安排，比较成功的办理了秦可卿的丧事，展示了王熙凤的才干本领。"
          },
          {
            "label": "元妃省亲",
            "value": 1,
            "id": 3823,
            "categories": [
              "event"
            ],
            "info": "元妃省亲时先看到大观园，暗叹过于奢华。后来与贾母等人相遇，都哭了一场。接着见了林黛玉、薛宝钗和薛姨妈，又见了贾宝玉，然后看了匾额，她着各人选一首题诗，薛、林二人得到赞誉。宝玉独占四首，薛宝钗看见他用了“绿玉春犹卷”这一句，提醒他元妃不喜欢“绿玉”一词，叫宝玉改成了“绿蜡”。宝玉作了三首，正在冥思苦想，黛玉见他只抄“杏帘在望”一首，就帮他作了一首，让宝玉抄。其实黛玉本想今夜大展奇才压倒众人的，但元妃只命一人作一首诗，她只好作罢。结果元妃看了，喜之不尽，说最后一首为上。把山庄名改成了“稻香村”。之后再听了几出戏，也就散了。"
          },
          {
            "label": "甄士隐梦幻识通灵",
            "value": 3,
            "id": 3824,
            "categories": [
              "event"
            ],
            "info": "是贾宝玉和林黛玉的来历。宝玉是石头下凡。这块石头因“无材补天”被女娲抛弃在青埂峰下，又四处游荡，到警幻仙子处做了神瑛侍者，遇见一株绛珠仙草，日日为她灌溉甘露，后来又被一僧一道携了投胎下凡做人，他就是贾宝玉。那株绛珠仙草也跟了石头下凡，她就是林黛玉。"
          },
          {
            "label": "晴雯撕扇",
            "value": 3,
            "id": 3825,
            "categories": [
              "event"
            ],
            "info": "端午佳节间，宝玉因金钏儿之事，心情很糟糕。恰巧晴雯给宝玉换衣时失手把他扇子跌折，便训斥了她几句，晴雯的自尊心受到伤害，还击了一通，不仅把宝玉“气得浑身乱颤”，而且连来劝架的袭人也落了个灰头土脸。最后，宝玉一定要回了太太去，至袭人一干丫鬟跪下求情才罢。而宝玉赴宴回来，仍和晴雯有说有笑。听说晴雯喜欢听撕扇子的声音，就任凭她将一大堆名扇痛痛快快撕尽了。最后晴雯将宝玉手中的扇子撕了，又把麝月的扇子也撕了。"
          },
          {
            "label": "凤姐泼醋",
            "value": 4,
            "id": 3826,
            "categories": [
              "event"
            ],
            "info": "凤姐自觉酒沉，下席回房。小丫头见面扭头就跑，凤姐疑心严审，发觉贾琏与鲍二家的偷情。先打平儿，凤姐踢门而进，撕打叫骂。尤氏等人至，贾琏拔剑欲杀凤姐，凤姐哭往贾母处。邢夫人管治喝退贾琏，贾母笑语解围。李纨拉平儿入园，宝玉让平儿怡红院理妆，为平儿黛玉伤感。邢夫人早起带贾琏过贾母处，贾琏下跪作揖陪罪。鲍二家的上吊自尽，凤姐吓阻，贾琏拿钱发送打点。"
          },
          {
            "label": "探春理家",
            "value": 4,
            "id": 3827,
            "categories": [
              "event"
            ],
            "info": "王熙凤病倒，王夫人怕无人看管诺大个贾府，叫探春、李纨以及薛宝钗代理府中一切事物，比凤姐在时还厉害。赶上赵姨娘兄弟死了，探春公私分明，不为亲人多给银子，引得赵姨娘大怒。之后裁度众人月例，把园子包给众仆妇，引得众人交口称赞，探春的理家理念得到了具体实施。"
          },
          {
            "label": "湘云醉眠芍药裀",
            "value": 1,
            "id": 3828,
            "categories": [
              "event"
            ],
            "info": "在宝玉等人生日宴席上，史湘云喝醉酒，便在园中山后一块石凳上睡着了。她头枕着一包芍药花瓣，芍药花飞了她一身，手中扇子落在地下，也半被花埋，身边蜂围蝶绕。虽然香梦沉酣，她口中还唧唧嘟嘟说着酒令。众人上前推醒她后，湘云不由心中后悔。"
          },
          {
            "label": "尤三姐殉情",
            "value": 2,
            "id": 3829,
            "categories": [
              "event"
            ],
            "info": "柳湘莲与尤三姐相爱后，赠与三姐一双鸳鸯剑为定情物，然后远出官职。不想一别竟是五年。五年中柳湘莲有期盼、有思念，但也听到了许多贾府的传言之讯。在假期中，柳湘莲本想娶妻成亲，但回到京城却从朋友那里听到了贾府的很多乱伦的风月之事。此行却变成了落实贾府男女们肮脏行为的调查，和悔婚约决心的下定。当他来到贾府门前，正值宝玉在门外，寒暄之后，宝玉提到了尤三姐的痴情苦等。柳湘莲急忙摆手摇头并说：“你们东府里除了那两个石头狮子干净，只怕连猫儿狗儿都不干净，我不做这剩王八。”躲在门内的三姐听得真切 ，盼君、迎君的热切激情，如同被冰水陡然泼灌。在回屋还剑之时，将剑鞘递与柳湘莲；顺势自己抽出一柄剑，当着柳湘莲的面愤情自尽。以自己的鲜血和生命证明了自身的清白和对爱情的忠贞。"
          },
          {
            "label": "抄检大观园",
            "value": 8,
            "id": 3830,
            "categories": [
              "event"
            ],
            "info": "园内发现香囊，王夫人令凤姐和王善保家一起抄检。在怡红院，晴雯愤怒倒出所有东西，并无私弊之物。在探春处，探春不但顶撞凤姐，还打了王善保耳光。在迎春处，搜到司棋私通证据。在惜春处，搜到入画哥哥的靴袜。最后，晴雯、司棋、入画被逐出府。"
          },
          {
            "label": "黛玉焚稿",
            "value": 3,
            "id": 3831,
            "categories": [
              "event"
            ],
            "info": "林黛玉得知贾宝玉和薛宝钗定婚的消息后，一病不起，临死前，挣扎着在卧榻边，狠命撕那宝玉送的旧帕和写有诗文的绢子，又叫雪雁点灯笼上火盆，黛玉将绢子撂在火上，雪雁也顾不得烧手，从火里抓起来撂在地下乱踩，却已烧得所余无几了，之后黛玉便含泪而逝。"
          },
          {
            "label": "黛玉之死",
            "value": 1,
            "id": 3832,
            "categories": [
              "event"
            ],
            "info": "黛玉听说宝玉结婚的消息后，病情日重。她自料万无生理，就将作为自己和宝玉爱情见证的旧帕与诗稿付之一炬。贾府上下忙于娶亲，无暇顾及黛玉，还把她的丫环叫走使唤。黛玉在直声叫道“宝玉！宝玉！你好——”后便去世了。而此时，远处隐隐约约传来了娶亲的音乐之声，潇湘馆外竹梢风动,月影移墙,好不凄凉冷淡!"
          },
          {
            "label": "晴雯补裘",
            "value": 2,
            "id": 3833,
            "categories": [
              "event"
            ],
            "info": "袭人因母病回家，晴雯夜里受寒伤风，身上烧得烫人。宝玉为舅舅庆寿，贾母给了宝玉一件俄罗斯的孔雀毛做的氅衣，宝玉穿时不小心烧了个洞，麝月忙悄悄拿出来叫人织补，谁知道东西太名贵，没有裁缝敢揽活。睛雯心灵手巧，重病中连夜补好。"
          },
          {
            "label": "元宵丢英莲",
            "value": 2,
            "id": 3834,
            "categories": [
              "event"
            ],
            "info": "香菱原名甄英莲，她出身在一二等富贵风流之地姑苏，母亲封氏性情贤淑，深明礼义，父亲甄士隐严正清白，禀性恬淡，为本地望族。年已半百的夫妻俩，膝下无儿，只有一女，乳名英莲。英莲“生得粉妆玉琢，乖觉可喜”，全家极其疼爱。应该说英莲生活在这样的家庭是幸福美好的。不幸的是，在她五岁那年的元霄佳节，士隐命家人抱去看灯，至半夜时家人霍启因小解，将英莲放在一家人家门槛上，待他回来，英莲不见踪影。全家人到处寻找，皆无音讯，英莲早被拐子拐去，另走他乡。"
          },
          {
            "label": "冷子兴演说荣国府",
            "value": 2,
            "id": 3835,
            "categories": [
              "event"
            ],
            "info": "大致介绍了宁荣二府的主要家庭成员的情况，二是点明了贾府现时萧条的光景和面临的危机，透露出这样一个钟鸣鼎食之家，如今的儿孙竟一代不如一代了。这些为我们理解小说是写一个处于“末世”的封建家族的衰亡提供了启示。"
          },
          {
            "label": "木石前盟",
            "value": 2,
            "id": 3836,
            "categories": [
              "event"
            ],
            "info": "《红楼梦》中贾宝玉与林黛玉的讹缘，木指黛玉前世绛珠仙草，石指宝玉前世顽石。神瑛侍者以甘露之水灌溉绛珠草，使其得换人形，修成个女体，名唤绛珠仙子。为报灌溉之德，绛珠仙子情愿随神瑛侍者下凡历劫，将自己一世的眼泪还他。可是一个与此事毫不相干的顽石插足，错领绛珠之泪，以致于绛珠泪尽而逝。故事由两个神话开头，渲染出了佛道的因果关系，以及人生如梦的主旨。可是宝玉口中的“木石前盟”竟然只是一厢情愿的讹缘，起到了当头棒喝的效果，乃是作者苦口婆心的劝诫：放下情迷，原本人生如梦。"
          },
          {
            "label": "贤袭人娇嗔箴宝玉",
            "value": 3,
            "id": 3837,
            "categories": [
              "event"
            ],
            "info": "史湘云在贾府玩，夜里和林黛玉睡在一起。贾宝玉一早起床就往林黛玉房间跑，要史湘云给他梳头。袭人跟贾宝玉说：一个男孩子老往女孩子房间跑，这样很不好。薛宝钗觉得袭人有见识。贾宝玉被袭人和薛宝钗说了一番，很不高兴，成天都没有理睬袭人，袭人想只有慢慢劝他。"
          },
          {
            "label": "潇湘馆",
            "value": 3,
            "id": 3838,
            "categories": [
              "location"
            ],
            "info": "黛玉的居所。黛玉作诗的笔名就潇湘妃子，这是曹雪芹对黛玉这个人物的赞美。"
          },
          {
            "label": "蘅芜苑",
            "value": 3,
            "id": 3839,
            "categories": [
              "location"
            ],
            "info": "薛宝钗的住所，匾额题为“蘅芷清芬”。宝钗亦因此得诗号“蘅芜君”。蘅芜苑离正殿不远，在沁芳池边，是一所外则“无味”，内藏“清雅”的处所。作者通过欲扬先抑的手法，暗喻了宝钗外表藏愚守拙，内在天然可爱，并且亲近佛道的思想性格。"
          },
          {
            "label": "暖香坞",
            "value": 2,
            "id": 3840,
            "categories": [
              "location"
            ],
            "info": "惜春的住所。暖香坞位于大观园的西半区，北面是李纨住的稻香村，南边是薛宝钗的蘅芜苑。"
          },
          {
            "label": "缀锦楼",
            "value": 3,
            "id": 3841,
            "categories": [
              "location"
            ],
            "info": "贾迎春的住所。大观园东部有一个庭院，西侧临水，东部靠山，院内西部建筑是紫菱洲，北房正厅，即缀锦楼。"
          },
          {
            "label": "稻香村",
            "value": 3,
            "id": 3842,
            "categories": [
              "location"
            ],
            "info": "李纨的住所。转过山怀中，隐隐露出一带黄泥墙，墙上皆用稻茎掩护。有几百枝杏花，如喷火蒸霞一般。里面数楹茅屋，外面却是桑榆槿柘，各色树稚新条，随其曲折，编就两溜青篱。篱外山坡之下，有一土井，旁有桔槔辘轳之属；下面分畦列亩，佳蔬菜花，一望无际。"
          },
          {
            "label": "凤藻宫",
            "value": 2,
            "id": 3843,
            "categories": [
              "location"
            ],
            "info": "元春省亲的住所。"
          },
          {
            "label": "秋爽斋",
            "value": 3,
            "id": 3844,
            "categories": [
              "location"
            ],
            "info": "贾探春在大观园的住所。题有“桐剪秋风”匾额。“斋”，书房也。斋较堂，惟气藏而收敛，盖藏修秘处之地，故式不宜敞显，充满了浓郁的书卷气息。"
          },
          {
            "label": "怡红院",
            "value": 4,
            "id": 3845,
            "categories": [
              "location"
            ],
            "info": "贾宝玉的住所。试才题对额时，宝玉题为“红香绿玉”，取意蕉棠两植。元宵省亲时，元春不喜“香玉”二字（因暗指黛玉），改为“怡红快绿”，赐名“怡红院”，宝玉因号“怡红公子”。"
          },
          {
            "label": "栊翠庵",
            "value": 2,
            "id": 3846,
            "categories": [
              "location"
            ],
            "info": "妙玉在大观园中的修行处，是一所园林中点景的尼姑庵。后来惜春出家，便住在此处。对于此建筑布局，书中并未展开描述，只是点到山门、东禅堂、耳房这三处，其中给读者留下深刻印象的是栊翠庵中的红梅。"
          },
          {
            "label": "梨香院",
            "value": 2,
            "id": 3847,
            "categories": [
              "location"
            ],
            "info": "宝钗初入贾府时，便住在梨香院。在贾府建成大观园以后，“薛姨妈另迁于东北上一所幽静房舍居住，将梨香院早已腾挪出来，另行修理了，就令教习在此教演女戏”（第18回），梨香院又成为了龄官等十二女伶的驻地。再后来，大观园内的女子戏班被遣散，梨香院还临时做过尤二姐的停灵之所（第69回）。"
          },
          {
            "label": "荣国府",
            "value": 16,
            "id": 3848,
            "categories": [
              "location"
            ],
            "info": "荣国公府邸。荣国公贾源是弟弟，死后他的儿子贾代善袭了他的爵位，贾代善的夫人就是贾母，史太君。贾代善生了两个儿子一个女儿，分别是：贾赦、贾政和贾敏。贾代善死后大儿子贾赦（妻：邢夫人）袭了爵位。"
          },
          {
            "label": "宁国府",
            "value": 7,
            "id": 3849,
            "categories": [
              "location"
            ],
            "info": "宁国公府邸。宁国公贾演是哥哥，死后他的儿子贾代化袭了他的爵位。贾代化死后他的儿子贾敬袭了爵位，贾敬有一儿一女，儿子是贾珍，女儿是惜春。贾敬死后他的儿子贾珍（妻：尤氏）袭了爵位。"
          },
          {
            "label": "苏州",
            "value": 9,
            "id": 3850,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里地名，即姑苏城。林黛玉祖籍。"
          },
          {
            "label": "大观园",
            "value": 9,
            "id": 3851,
            "categories": [
              "location"
            ],
            "info": "贾府为元妃省亲所建别墅园林。"
          },
          {
            "label": "玄真观",
            "value": 2,
            "id": 3852,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里，贾敬修行、炼丹的地方。"
          },
          {
            "label": "京郊",
            "value": 7,
            "id": 3853,
            "categories": [
              "location"
            ],
            "info": "地处京城之外，刘姥姥家所处位置。"
          },
          {
            "label": "扬州",
            "value": 2,
            "id": 3854,
            "categories": [
              "location"
            ],
            "info": "林黛玉出生的地方，也是林如海捐馆之所。"
          },
          {
            "label": "金陵",
            "value": 31,
            "id": 3855,
            "categories": [
              "location"
            ],
            "info": "金陵即今天的南京。贾母祖籍。"
          },
          {
            "label": "京城",
            "value": 11,
            "id": 3856,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里写的京城是指长安。"
          },
          {
            "label": "胡州",
            "value": 1,
            "id": 3857,
            "categories": [
              "location"
            ],
            "info": "作者虚拟的地名，脂评：“胡邹也。”"
          },
          {
            "label": "葫芦庙",
            "value": 2,
            "id": 3858,
            "categories": [
              "location"
            ],
            "info": "应天府的门子曾经是葫芦庙里的小沙僧。贾雨村失意时住过一段时间。在苏州城内。"
          },
          {
            "label": "南海",
            "value": 2,
            "id": 3859,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里贾探春远嫁的地方。"
          },
          {
            "label": "太虚幻境",
            "value": 1,
            "id": 3860,
            "categories": [
              "location"
            ],
            "info": "太虚幻境，《红楼梦》中的女儿仙境，警幻仙子司主。它位于离恨天之上、灌愁海之中的放春山遣香洞，以梦境的形式向甄士隐、贾宝玉二位有缘人显现。"
          },
          {
            "label": "大同府",
            "value": 1,
            "id": 3861,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》中，贾迎春之夫孙绍祖是山西大同府人。"
          },
          {
            "label": "阊门",
            "value": 3,
            "id": 3862,
            "categories": [
              "location"
            ],
            "info": "苏州繁华之地，《红楼梦》写道：最是红尘中一二等富贵风流之地。"
          },
          {
            "label": "贾宝玉",
            "value": 52,
            "image": "./images/photo/贾宝玉.jpg",
            "id": 3863,
            "categories": [
              "person"
            ],
            "info": "荣国府衔玉而诞的公子，贾政与王夫人之次子，阖府捧为掌上明珠，对他寄予厚望，他却走上了叛逆之路，痛恨八股文，批判程朱理学，给那些读书做官的人起名“国贼禄蠹”。他不喜欢“正经书”，却偏爱《牡丹亭》《西厢记》之类的“杂书”。他终日与家里的女孩们厮混，爱她们美丽纯洁，伤悼她们的薄命悲剧。"
          },
          {
            "label": "贾代善",
            "value": 8,
            "id": 3864,
            "categories": [
              "person"
            ],
            "info": "荣国公贾源是宁国公贾演的弟弟，贾源死后他的儿子贾代善袭了他的爵位。贾代善的夫人就是贾母（又称史太君）。 贾代善生了两个儿子多个女儿，其中有姓名的分别是：贾赦、贾政和贾敏。 贾代善死后大儿子贾赦（妻：邢夫人）袭了爵位。"
          },
          {
            "label": "贾代化",
            "value": 5,
            "id": 3865,
            "categories": [
              "person"
            ],
            "info": "贾演长子，贾敷、贾敬之父，京营节度使世袭一等神威将军。"
          },
          {
            "label": "贾敷",
            "value": 4,
            "id": 3866,
            "categories": [
              "person"
            ],
            "info": "贾代化长子，贾敬之兄。八九岁夭折。"
          },
          {
            "label": "贾敬",
            "value": 6,
            "image": "./images/photo/贾敬.jpg",
            "id": 3867,
            "categories": [
              "person"
            ],
            "info": "宁国公贾演的孙子，京营节度使世袭一等神威将军贾代化的次子，贾珍之父。是乙卯科进士，却一味好道，在都外玄真观修炼，烧丹炼汞，别的事一概不管，放纵家人胡作非为。后因吃秘制的丹砂烧胀而死。死后追赐为五品之职。"
          },
          {
            "label": "贾赦",
            "value": 15,
            "image": "./images/photo/贾赦.jpg",
            "id": 3868,
            "categories": [
              "person"
            ],
            "info": "字恩侯，荣国公之孙，贾代善、贾母之长子，邢夫人的丈夫，贾琏、迎春的父亲，是个无耻之徒，他承袭了一等将军之职爵位。生性好色，妾室一片，平日依官作势，行为不检。他不满贾母偏向弟弟贾政，与贾母、贾政貌合神离。这个无耻的封建阶级的末代子孙，终于遭到查抄家产，革去世职，远离都城，充军边地的应得下场。"
          },
          {
            "label": "贾政",
            "value": 24,
            "image": "./images/photo/贾政.jpg",
            "id": 3869,
            "categories": [
              "person"
            ],
            "info": "字存周，贾母和贾代善所生的次子，贾宝玉的父亲，林黛玉的舅舅，薛宝钗的姨父。他是除贾母外荣国府的最高掌权者，但同贾母一样不常管理府中大小俗务，每日只看书著棋，同一众清客闲聊，是名副其实的甩手掌柜，他并不喜好繁华奢侈的生活，在游览大观园时亦有过归农隐逸之意。他自幼好读书，但并不是天生的方正呆板，出仕前，他“也是个诗酒放诞之人”，但“一切为的是光宗耀祖”，因此重视读书上进，归于正途。为人端方正直，谦恭厚道，人品端方，风声清肃。礼贤下士，济弱扶危，大有祖风，惟失之于迂腐。他一心孝顺贾母，亦想严厉管教子女；他想作好官，可是不谙世情，只解打躬作揖，终日臣坐，形同泥塑，遭人蒙骗，弄得声名狼藉。"
          },
          {
            "label": "贾蓉",
            "value": 11,
            "image": "./images/photo/贾蓉.jpg",
            "id": 3870,
            "categories": [
              "person"
            ],
            "info": "贾珍之子，是一个十六七岁的少年，面目清秀，身材俊俏，轻裘宝带，美服华冠的男子。他原为监生，妻子秦可卿死后，为了在丧礼上风光些，父亲贾珍花了一千两百两银子给他捐了个五品龙禁尉。贾蓉长得是面目清秀，细挑身材，但生活上却和他父亲贾珍一样荒淫无耻。"
          },
          {
            "label": "贾蔷",
            "value": 7,
            "image": "./images/photo/贾蔷.jpg",
            "id": 3871,
            "categories": [
              "person"
            ],
            "info": "宁国府的正派玄孙。他父母早亡，从小跟贾珍过活，比贾蓉生得还风流俊俏。虽然每日应名去上学，亦只不过虚掩眼目而已，仍旧是斗鸡走狗，赏花阅柳。他上有贾珍溺爱，下有贾蓉匡助，越发自大起来。后成了贾府小戏班梨香院的总管，与小旦龄官相好。"
          },
          {
            "label": "贾兰",
            "value": 4,
            "image": "./images/photo/贾兰.jpg",
            "id": 3872,
            "categories": [
              "person"
            ],
            "info": "贾珠与李纨之子。关于贾兰的人物结局，高鹗整理的后40回中的描述是：贾兰成年后考中第一百三十七名举人。"
          },
          {
            "label": "贾珍",
            "value": 19,
            "image": "./images/photo/贾珍.jpg",
            "id": 3873,
            "categories": [
              "person"
            ],
            "info": "贾敬之子，贾演曾孙。世袭三品爵威烈将军。生活穷奢极欲，虽有一妻二妾，但仍和儿媳秦可卿、妻妹尤二姐关系暧昧。秦可卿死后，是他流泪向王夫人请求让王熙凤料理丧事，让她“爱怎么办就怎么样办”，铺排浪费，为丧礼风光，特意花一千二百两银子为儿子贾蓉捐了个五品龙禁尉，而儿媳葬礼的奢华也证明了他们之间的不正当关系。后因作恶多端，被人参奏革去世职，发配海疆。"
          },
          {
            "label": "贾琏",
            "value": 25,
            "image": "./images/photo/贾琏.jpg",
            "id": 3874,
            "categories": [
              "person"
            ],
            "info": "贾赦的长子，刑夫人无子女,异母妹妹迎春,妻子王熙凤,女儿巧姐。他捐了个同知的官位，不喜欢读书谋求官位。住在荣国府，和妻子王熙凤帮着料理荣府家务，在贾府一众男子中算是第一得力干练之人。他最大的缺点是好色，但并不像贾珍、薛蟠等那样强人所难。女儿巧姐出天花，按迷信要夫妻分房，他一离开王熙凤就找“多姑娘” 鬼混。王熙凤去过生日宴会，他就把鲍二媳妇勾搭上手，见了尤二姐，又贪图其美色，骗娶为二房。父亲贾赦却夸他能干，又把自己的丫环秋桐赏给他。从他不赞同贾雨村为了几把扇子把石呆子害得坑家败业、真情对待尤二姐之死，以及劝阻王熙凤将彩霞配给旺儿品德不好的儿子等事中，可以看出他富有同情心、做事有底线，不以自己世家公子的身份压榨别人。他同王熙凤的关系由一开始的和美转为同床异梦，可以看成是王熙凤的悲剧，也是他的悲剧。"
          },
          {
            "label": "贾琮",
            "value": 4,
            "id": 3875,
            "categories": [
              "person"
            ],
            "info": "贾赦之子。"
          },
          {
            "label": "贾珠",
            "value": 12,
            "id": 3876,
            "categories": [
              "person"
            ],
            "info": "贾政嫡长子，与元春、宝玉均为王夫人所生，娶妻李纨，育子贾兰，在《红楼梦》中是作为已故人物。"
          },
          {
            "label": "贾环",
            "value": 10,
            "image": "./images/photo/贾环.jpg",
            "id": 3877,
            "categories": [
              "person"
            ],
            "info": "同贾宝玉为同父异母的弟弟，其母为贾政之妾赵姨娘。贾环颇为顽劣，赶围棋玩耍时，与丫头莺儿撒泼耍赖。贾环十分忌恨其兄贾宝玉，多次陷害宝玉。故意拨翻烛台 ，烫伤宝玉。金钏跳井事件后，诬陷宝玉，使得宝玉遭受贾政毒打。贾环因常常受到不公平对待而生不平、妒嫉之心；贾环虽行为无赖，然而也算尚学，小有才华。"
          },
          {
            "label": "贾璜",
            "value": 8,
            "id": 3878,
            "categories": [
              "person"
            ],
            "info": "贾家“玉”字辈嫡派，璜大奶奶金氏的老公，金荣的姑父。远不如宁荣二府的富势，夫妻俩只守着些小的产业。第十回中道：‘时常到宁荣二府里去请请安，又会奉承凤姐儿并尤氏，所以凤姐儿尤氏也 时常资助资助他，方能如此度日。请安，奉承，尤显得当时宁荣二府溜须拍马之风盛行。"
          },
          {
            "label": "贾演",
            "value": 5,
            "id": 3879,
            "categories": [
              "person"
            ],
            "info": "荣国公贾源的胞兄，被朝廷封为宁国公，底下有四子（书中仅提及长子贾代化），死后由长子贾代化继承其爵位，贾演与贾源为贾府家业的开创者。"
          },
          {
            "label": "贾源",
            "value": 4,
            "id": 3880,
            "categories": [
              "person"
            ],
            "info": "宁国公贾演的胞弟，被朝廷封为荣国公。他是宁国公贾演的胞弟，贾政、贾赦的祖父，贾宝玉的曾祖父。贾源被朝廷封为荣国公，死后由长子贾代善继承其爵位。"
          },
          {
            "label": "秦钟",
            "value": 10,
            "image": "./images/photo/秦钟.jpg",
            "id": 3881,
            "categories": [
              "person"
            ],
            "info": "表字鲸卿。秦业五十三岁时得的儿子，秦可卿的弟弟。他生得眉清目秀，粉面朱唇，身材俊俏，举止风流，怯怯羞羞的有些女儿之态。秦钟本性怯弱，且带病未痊，受了笞杖，今见老父气死，又添了许多病症，不几日也死了。"
          },
          {
            "label": "王子腾",
            "value": 4,
            "id": 3882,
            "categories": [
              "person"
            ],
            "info": "都太尉统制县伯王公之后裔，王夫人、薛姨妈、王子胜之兄。初任京营节度使，后擢九省统制，奉旨查边，旋升九省都检点。鲍二家的自缢后，阻其娘家亲戚申诉，并左右都察院审理张华一案的，都是王熙凤倚王子腾之势所为。而他又因贾雨村私断金陵命案便累上保本、引荐雨村进京。"
          },
          {
            "label": "王公之子",
            "value": 7,
            "id": 3883,
            "categories": [
              "person"
            ],
            "info": "王熙凤之祖父。"
          },
          {
            "label": "王仁",
            "value": 4,
            "id": 3884,
            "categories": [
              "person"
            ],
            "info": "王仁是王熙凤的哥哥，贾巧姐的舅舅，王仁在这里其实就是“忘仁”的意思，说明他是个忘却仁义的小人，后来贾家败落，王仁居然将自己的外甥女贾巧姐卖到了烟花柳巷之地，几乎害了她一生，幸亏由刘姥姥救出。"
          },
          {
            "label": "薛蟠",
            "value": 17,
            "image": "./images/photo/薛蟠.jpg",
            "id": 3885,
            "categories": [
              "person"
            ],
            "info": "字文龙（一作“文起”），外号“呆霸王”，薛姨妈之子，薛宝钗之兄。因幼年丧父，寡母又纵容溺爱，终日唯有斗鸡走马，游山玩水。薛蟠任性弄气，诨号呆霸王。不过，薛蟠也很重义气。"
          },
          {
            "label": "薛蝌",
            "value": 5,
            "image": "./images/photo/薛蝌.jpg",
            "id": 3886,
            "categories": [
              "person"
            ],
            "info": "皇商之子，薛姨妈的侄儿，薛宝琴的胞兄，薛宝钗的堂弟。"
          },
          {
            "label": "史鼐",
            "value": 2,
            "id": 3887,
            "categories": [
              "person"
            ],
            "info": "世袭保龄侯、尚书令史公之次孙(也就是史公的嫡长子的次子)，忠靖侯史鼎之兄，史湘云之叔，贾母之侄。"
          },
          {
            "label": "史鼎",
            "value": 2,
            "id": 3888,
            "categories": [
              "person"
            ],
            "info": "史湘云之叔，贾母之侄。"
          },
          {
            "label": "史侯之子",
            "value": 6,
            "id": 3889,
            "categories": [
              "person"
            ],
            "info": "贾母的哥哥。"
          },
          {
            "label": "柳湘莲",
            "value": 8,
            "image": "./images/photo/柳湘莲.jpg",
            "id": 3890,
            "categories": [
              "person"
            ],
            "info": "又称冷面二郎，原系世家子弟。他父母早丧，读书不成。性情豪爽，酷好耍枪舞剑，赌博吃酒，以至眠花宿柳，吹笛弹筝，无所不为。他生得又美，是一个业余的戏剧演员，最喜串戏，擅演生旦风月戏文，在书中和宝玉最合得来。有一次在赖大家赴宴，薛蟠酒后向他调情，被他骗至北门（今德胜门）外苇子坑打了个半死，事后，远走他乡。 后又在路上救了薛蟠，与薛蟠结为兄弟。足见其无邪之心。行事草率，不负责任。"
          },
          {
            "label": "贾雨村",
            "value": 9,
            "image": "./images/photo/贾雨村.jpg",
            "id": 3891,
            "categories": [
              "person"
            ],
            "info": "贾雨村是一个提纲挈领式的人物，以“假语村言”提醒读者，统率全文。 贾雨村，名化，字时飞，别号雨村，故为“假语村言”。原系湖州人氏，生于仕宦人家。但到他时，祖宗根基已尽，人口衰丧，只剩下他一人。他想进京求取功名，无奈囊内空空，只得暂寄姑苏城里葫芦庙中安身，每日卖文作字为生。后因甄士隐相助，他才有钱上路，考中进士，做了知府。不久因贪酷徇私被革职，受聘至林如海家任林黛玉启蒙教师。在贾政的极力帮助下，他又官复原职，但为官不正，乱判了一起“葫芦案”（为“糊涂案”），后来这一案件被世人所知，因为“因嫌纱帽小，致使锁枷杠”。"
          },
          {
            "label": "贾瑞",
            "value": 4,
            "image": "./images/photo/贾瑞.jpg",
            "id": 3892,
            "categories": [
              "person"
            ],
            "info": "贾府义学塾贾代儒的长孙。贪图便宜又好色，欺自己的嫂子，死于王熙凤设的“相思局”中。"
          },
          {
            "label": "蒋玉菡",
            "value": 6,
            "id": 3893,
            "categories": [
              "person"
            ],
            "info": "忠顺王府戏班的名角，擅唱小旦，小名琪官（一作棋官）。贾宝玉曾以玉玦扇坠和袭人所给松花汗巾相赠，蒋玉菡回赠以北静王所赐茜香国女国王贡奉的大红汗巾。贾府彻底败落后，蒋玉菡娶宝玉房中大丫头袭人为妻。在贾府被抄家后，蒋玉菡夫妇资助了贫穷的贾宝玉夫妇。蒋玉菡妩媚温柔，随机应答，很有礼貌。"
          },
          {
            "label": "贾芸",
            "value": 7,
            "image": "./images/photo/贾芸.jpg",
            "id": 3894,
            "categories": [
              "person"
            ],
            "info": "生有一张容长脸儿，长挑身材，甚是斯文清秀。父亲早逝，因贾宝玉一句玩笑话“像我儿子”，他便伶俐地说：“如若宝叔不嫌侄儿蠢笨，认作儿子，就是我的造化了。”贾芸，贾府族人，西廊下五嫂子的儿子。为了到荣国府谋事做，对凤姐百般奉承，又夸她能干又送她香料，得了一个管花草的职位。而为了攀入贾府中心，他不惜对小他几岁的宝玉认父亲，并赠了两盆白海棠。而他也是一个重情重义的人，贾府败落后帮助过身陷困境的宝玉和凤姐，并喜欢上了丫头小红，捡到她的手帕后一番接触，两人渐渐陷入爱河，上演了一出和宝黛之恋同样纯美的爱情故事。"
          },
          {
            "label": "倪二",
            "value": 2,
            "image": "./images/photo/倪二.jpg",
            "id": 3895,
            "categories": [
              "person"
            ],
            "info": "贾芸的街坊，绰号醉金刚，虽是个泼皮无赖，“却因人而使，颇颇的有义侠之名”，故作者特设回目《醉金刚轻财尚义侠》。"
          },
          {
            "label": "林如海",
            "value": 5,
            "image": "./images/photo/林如海.jpg",
            "id": 3896,
            "categories": [
              "person"
            ],
            "info": "姑苏林家的子孙，娶妻贾敏（已先于林如海病故），育有一子，三岁时没了，仅有一女林黛玉。"
          },
          {
            "label": "焦大",
            "value": 2,
            "image": "./images/photo/焦大.jpg",
            "id": 3897,
            "categories": [
              "person"
            ],
            "info": "从小跟宁国公贾演出过三四回兵，曾从死人堆里把奄奄一息的主子背出来。没有饭吃，他饿著肚子去偷东西给主子吃，没有水喝，他自己喝马尿，把得来的半碗水给主子喝。由于以往的功劳情分，宁府的主子们对他另眼相看，不大难为他。他对宁国府后代糜烂的生活深恶痛绝，也只有他在喝醉酒后敢大骂他们：“每日偷狗戏鸡，爬灰的爬灰，养小叔子的养小叔子”，吓得众小厮魂飞魄丧，把他捆起来，用土和马粪满满填了他一嘴。"
          },
          {
            "label": "秦业",
            "value": 2,
            "id": 3898,
            "categories": [
              "person"
            ],
            "info": "秦钟生父，秦可卿养父。他的名字有一种隐意，是“情孽”.任工部营缮郎，夫人早亡，因素与贾府有些瓜葛，故将养女许配给了贾蓉。五旬之上方得了秦钟，因此对儿子期望极高。纵使宦囊羞涩，仍东拼西凑的恭恭敬敬封了二十四两贽见礼，亲自带了秦钟，来代儒家拜见了。这一点倒是和现代人很相像的。正是：早知日后闲争气，岂肯今朝错读书。当他有发现水月庵智能私找秦钟，气得旧病发作而亡。"
          },
          {
            "label": "王公",
            "value": 2,
            "id": 3899,
            "categories": [
              "person"
            ],
            "info": "王夫人之祖父。"
          },
          {
            "label": "贾代儒之子",
            "value": 3,
            "id": 3900,
            "categories": [
              "person"
            ],
            "info": "贾代儒之子。"
          },
          {
            "label": "薛公之孙",
            "value": 1,
            "id": 3901,
            "categories": [
              "person"
            ],
            "info": "薛公之孙。"
          },
          {
            "label": "史侯",
            "value": 3,
            "id": 3902,
            "categories": [
              "person"
            ],
            "info": "红楼梦中史侯是史湘云祖父的父亲，有两个孩子，分别是史太君和史湘云祖父。女孩史太君嫁入贾代善门下，也就是我们说的贾母。而儿子有三个孩子，史鼐和史鼎也就是保龄侯和忠靖侯。还有一个就是史湘云的爸爸或妈妈。这个我也不知道，有谁知道请补充。史湘云父母只有史湘云一个孩子，史湘云嫁给了卫若兰。"
          },
          {
            "label": "甄士隐",
            "value": 8,
            "image": "./images/photo/甄士隐.jpg",
            "id": 3903,
            "categories": [
              "person"
            ],
            "info": "姓甄，名费，谐音“废”，字士隐。“甄士隐”取意为“真事隐”。书中有“因曾历过一番梦幻之后，故将真事隐去。”一句。正如贾雨村是“假语村言”一样。甄士隐是和贾雨村两相对照而写的，作者在开卷第一段里就明确表示他撰拟这两个名字的寓意。写甄士隐是为了写一个经历了骨肉分离、家遭火灾、下半世坎坷而终于醒悟出世的人物形象。他可能是作者自身的影子，同时也是提系着全书主题的一个线索。"
          },
          {
            "label": "邢忠",
            "value": 2,
            "image": "./images/photo/邢忠.jpg",
            "id": 3904,
            "categories": [
              "person"
            ],
            "info": "邢岫烟父，邢夫人兄，薛蝌岳父。他因家中艰难，故携家上京投靠贾府。"
          },
          {
            "label": "板儿",
            "value": 7,
            "image": "./images/photo/板儿.jpg",
            "id": 3905,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的外孙。在小说第六回“刘姥姥一进荣国府”中，多处生动地描写了板儿未经世面的村野孩童形象。及至成年，娶贾巧姐为妻。"
          },
          {
            "label": "孙绍祖",
            "value": 5,
            "image": "./images/photo/孙绍祖.jpg",
            "id": 3906,
            "categories": [
              "person"
            ],
            "info": "迎春丈夫孙绍祖在家境困难时曾经拜倒在贾府门下，乞求帮助。后来，孙绍祖在京袭了官职，又“在兵部候缺题升”，贾家衰败后，孙绍祖向他逼债，任意践踏迎春。迎春判词有“迎春遭遇中山狼”一幅，画中的孙绍祖，高壮如异兽，十分可怕。“中山狼”之形态毕出也。在这桩弱女悍夫的不幸婚姻的室内细节，文字没有写到之处，似乎都可从画中窥出。"
          },
          {
            "label": "李贵",
            "value": 2,
            "image": "./images/photo/李贵.jpg",
            "id": 3907,
            "categories": [
              "person"
            ],
            "info": "李嬷嬷子，宝玉之奶兄、贴身跟班。虽不识书，学舌什么“呦呦鹿鸣，荷叶浮萍”，但颇明事理，众顽童大闹学堂时亏他力劝，并指明各人的不是，方才平息了事端。宝玉探秦钟时，他亦如长兄般慰藉。他在贾府中亦是体面的男仆，有小厮服侍。"
          },
          {
            "label": "赵天梁",
            "value": 1,
            "id": 3908,
            "categories": [
              "person"
            ],
            "info": "贾琏的奶哥哥。"
          },
          {
            "label": "赵天栋",
            "value": 1,
            "id": 3909,
            "categories": [
              "person"
            ],
            "info": "贾琏的奶哥哥。"
          },
          {
            "label": "贾代儒",
            "value": 14,
            "image": "./images/photo/贾代儒.jpg",
            "id": 3910,
            "categories": [
              "person"
            ],
            "info": "贾代儒是贾府中“代”字辈的长辈，其人生有三大不幸：早年丧父，中年丧子，晚年丧孙。宝玉两次进塾就读，八股文也是跟他学的。他儿子、儿媳均早亡，故担负起对孙子的教养责任。他对贾瑞管教甚严，一旦贾瑞在外擅自过夜，他必定重罚。"
          },
          {
            "label": "赖大",
            "value": 4,
            "image": "./images/photo/赖大.jpg",
            "id": 3911,
            "categories": [
              "person"
            ],
            "info": "赖嬷嬷之子，赖尚荣之父。是“熬了两三辈子，好容易挣出”来的“家生子儿”，因为赖嬷嬷“年高服侍过”贾府的老主子，又得到贾母的“赏脸”，他才做了荣府大总管。"
          },
          {
            "label": "赵国基",
            "value": 1,
            "id": 3912,
            "categories": [
              "person"
            ],
            "info": "赵姨娘弟弟，荣府男仆。曾随侍贾环上学。赵国基死时，正值探春理家，为了发送赏银的多寡，赵姨娘与探春曾翻脸大闹。"
          },
          {
            "label": "吴贵",
            "value": 3,
            "id": 3913,
            "categories": [
              "person"
            ],
            "info": "晴雯的姑舅哥哥，老实胆小，人都叫他贵儿。"
          },
          {
            "label": "花自芳",
            "value": 1,
            "image": "./images/photo/花自芳.jpg",
            "id": 3914,
            "categories": [
              "person"
            ],
            "info": "怡红院大丫环花袭人之兄。 因生活困顿，与父母一道将袭人卖与贾府。父亲死后，情况渐好，遂又和母亲商议，要赎出袭人。宝玉私访花家，自芳惶恐相见，殷勤款待，曲尽奴才之意。一二〇回写到其写到其妻及亲戚们做媒，将袭人嫁与蒋玉菡。"
          },
          {
            "label": "王成之父",
            "value": 1,
            "id": 3915,
            "categories": [
              "person"
            ],
            "info": "王成之父昔年与凤姐之祖王夫人之父认识。因贪王家的势利，便连了宗认作侄儿。"
          },
          {
            "label": "王成",
            "value": 3,
            "id": 3916,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的亲家，王狗儿之父。因家业萧条，合家搬往城外乡下居住，在刘姥姥初进大观园前不久病故。"
          },
          {
            "label": "狗儿",
            "value": 6,
            "id": 3917,
            "categories": [
              "person"
            ],
            "info": "王成之子，刘姥姥女婿。祖上曾作小京官，周瑞昔年为争买田地事，曾得他一臂之力。后家道中落，便务农为业。他在穷愁困顿中听到刘姥姥去贾府走动的建议，赶紧怂恿刘姥姥启身前往。"
          },
          {
            "label": "周瑞",
            "value": 4,
            "image": "./images/photo/周瑞.jpg",
            "id": 3918,
            "categories": [
              "person"
            ],
            "info": "冷子兴岳丈。荣府的男管家。"
          },
          {
            "label": "林之孝",
            "value": 3,
            "image": "./images/photo/林之孝.jpg",
            "id": 3919,
            "categories": [
              "person"
            ],
            "info": "荣国府管家，负责管理银库账房的，为人处世十分低调。"
          },
          {
            "label": "鲍二",
            "value": 2,
            "id": 3920,
            "categories": [
              "person"
            ],
            "info": "贾府男仆。"
          },
          {
            "label": "金文翔",
            "value": 3,
            "id": 3921,
            "categories": [
              "person"
            ],
            "info": "鸳鸯的哥哥。他是贾母房里的买办。"
          },
          {
            "label": "香怜",
            "value": 2,
            "id": 3922,
            "categories": [
              "person"
            ],
            "info": "贾家义学中的小学生。"
          },
          {
            "label": "贾菌",
            "value": 5,
            "id": 3923,
            "categories": [
              "person"
            ],
            "info": "荣府近派的重孙，娄氏之子。少孤，在家塾中读书时与贾兰最好，年纪虽小，志气却大，极是淘气不怕人的，在顽童闹学时，他不肯吃亏，十分顽皮。"
          },
          {
            "label": "贾蓝",
            "value": 4,
            "id": 3924,
            "categories": [
              "person"
            ],
            "info": "贾蓝在《红楼梦》中只是一个符号式的人物，是个“牛心左性”的孩童而已。"
          },
          {
            "label": "金荣",
            "value": 3,
            "id": 3925,
            "categories": [
              "person"
            ],
            "info": "贾璜妻子的娘家侄子。"
          },
          {
            "label": "茗烟",
            "value": 5,
            "image": "./images/photo/茗烟.jpg",
            "id": 3926,
            "categories": [
              "person"
            ],
            "info": "贾宝玉最得力的书僮和小厮就叫焙茗。 一般认为焙茗即茗烟。但早期版本的《红楼梦》中并未交代改名事宜。 程本及后来的通行本在二十四回中有交代： 只见茗烟在那里掏小雀儿呢。贾芸在他身后，把脚一跺，道：“茗烟小猴儿又淘气了！”茗烟回头，见是贾芸，便笑道：“何苦二爷唬我们这么一跳。”因又笑说：“我不叫‘茗烟’了，我们宝二爷嫌‘烟’字不好，改了叫‘焙茗’了。二爷明儿只叫我焙茗罢。”贾芸点头笑着同进书房，便坐下问：“宝二爷下来了没有？”"
          },
          {
            "label": "潘又安",
            "value": 2,
            "image": "./images/photo/潘又安.jpg",
            "id": 3927,
            "categories": [
              "person"
            ],
            "info": "贾府小厮，司棋青梅竹马的姑舅表弟。他性格懦弱，胆小怕事，跟司棋在大观园幽会被鸳鸯撞见，畏罪远遁。他发财回家，意欲迎娶司棋，无奈司母不从，司棋撞墙身亡，他也殉情自尽。潘又安在《红楼梦》中正面出场仅仅两次。第一次是在《红楼梦》第七十一回，潘又安与女友司棋在花光柳影，诗意绵绵的大观园中约会。不巧被鸳鸯撞见，潘又安迅速藏在树后。司棋见到鸳鸯以后，便叫潘又安出来，潘又安只得从树后爬出来，如捣蒜似的向贾母大丫头鸳鸯姐姐磕头。这就是他的出场，只有一系列动作，没有外貌的刻画，没有声音的流露。"
          },
          {
            "label": "贾芹",
            "value": 2,
            "image": "./images/photo/贾芹.jpg",
            "id": 3929,
            "categories": [
              "person"
            ],
            "info": "贾府小和尚小道士的总管。他在家庙里，为王称霸，夜夜聚赌。有人匿名写了“西贝草斤年纪轻，水月庵里管尼僧。一个男人多少女，窝娼聚赌是陶情。不肖子弟来办事，荣国府内好声名。”的帖儿贴在荣府大门口。贾政见后，革去了他的总管一职。"
          },
          {
            "label": "玉爱",
            "value": 1,
            "id": 3930,
            "categories": [
              "person"
            ],
            "info": "贾家义学中的小学生。未考真姓名，只因生得妩媚风流，满学中才给他起了这个外号。"
          },
          {
            "label": "来升",
            "value": 3,
            "id": 3931,
            "categories": [
              "person"
            ],
            "info": "首见第十回《金寡妇贪利权受辱 张太医论病细穷源》。"
          },
          {
            "label": "俞禄",
            "value": 3,
            "id": 3932,
            "categories": [
              "person"
            ],
            "info": "俞禄是曹雪芹小说《红楼梦》中的人物，一个小管家。"
          },
          {
            "label": "杏奴",
            "value": 1,
            "id": 3933,
            "categories": [
              "person"
            ],
            "info": "柳湘莲的小厮。"
          },
          {
            "label": "赖尚荣",
            "value": 3,
            "image": "./images/photo/赖尚荣.jpg",
            "id": 3934,
            "categories": [
              "person"
            ],
            "info": "赖嬷嬷的孙子。赖家本是贾家的家生奴仆，但赖尚荣一落娘胎，就被主子放了出来，成为自由人。"
          },
          {
            "label": "金彩",
            "value": 2,
            "id": 3935,
            "categories": [
              "person"
            ],
            "info": "鸳鸯、金文翔之父，出生于1841年。（见《红楼梦》第四十六回）贾府老仆，与老妻两口在南京看房子。"
          },
          {
            "label": "邢德全",
            "value": 1,
            "id": 3936,
            "categories": [
              "person"
            ],
            "info": "邢夫人胞弟，邢岫烟之叔。"
          },
          {
            "label": "隆儿",
            "value": 1,
            "id": 3937,
            "categories": [
              "person"
            ],
            "info": "贾琏的小厮。"
          },
          {
            "label": "兴儿",
            "value": 3,
            "id": 3938,
            "categories": [
              "person"
            ],
            "info": "贾琏的心腹小厮，他曾对尤二姐细说荣府成员。后王熙凤发觉贾琏偷娶一事，就拿他来盘问，要他自己打自己嘴巴。兴儿只得自己左右开弓，打了十几个嘴巴，并把贾琏偷娶尤二姐的经过一一告知。"
          },
          {
            "label": "王善保",
            "value": 2,
            "id": 3939,
            "categories": [
              "person"
            ],
            "info": "十二家人之一。 邢夫人的仆人。"
          },
          {
            "label": "冷子兴",
            "value": 5,
            "image": "./images/photo/冷子兴.jpg",
            "id": 3940,
            "categories": [
              "person"
            ],
            "info": "冷子兴是周瑞的女婿，都城中的古董商，和贾雨村是好朋友。他对贾雨村介绍荣国府，使贾家众人在他口中整体亮相。"
          },
          {
            "label": "卜世仁",
            "value": 1,
            "image": "./images/photo/卜世仁.jpg",
            "id": 3941,
            "categories": [
              "person"
            ],
            "info": "贾芸母舅，香料铺主人。"
          },
          {
            "label": "冯紫英",
            "value": 1,
            "image": "./images/photo/冯紫英.jpg",
            "id": 3942,
            "categories": [
              "person"
            ],
            "info": "冯紫英是神武将军冯唐之子，与贾府中贾宝玉、薛蟠等人都有来往。冯紫英也是令所有红学家都热衷的“神秘人物”。就在第26回《蜂腰桥设言传心事 潇湘馆春困发幽情》中，由于薛蟠庆生，宝玉等人都前来道贺，冯紫英也在其中。"
          },
          {
            "label": "凤姐之父",
            "value": 4,
            "id": 3943,
            "categories": [
              "person"
            ],
            "info": "凤姐之父。"
          },
          {
            "label": "史湘云之父",
            "value": 2,
            "id": 3944,
            "categories": [
              "person"
            ],
            "info": "史侯之子。"
          },
          {
            "label": "卫若兰",
            "value": 2,
            "image": "./images/photo/卫若兰.jpg",
            "id": 3945,
            "categories": [
              "person"
            ],
            "info": "在《红楼梦》中只出现过很少的几次，仅在秦氏出殡时出现了一次，书中点明他的身份是“王孙公子”。他之所以成为引人注目的人物，是由于脂批的特意提及。"
          },
          {
            "label": "梅翰林之子",
            "value": 2,
            "id": 3946,
            "categories": [
              "person"
            ],
            "info": "薛宝琴的丈夫。"
          },
          {
            "label": "吴兴登",
            "value": 2,
            "id": 3947,
            "categories": [
              "person"
            ],
            "info": "贾府家仆。"
          },
          {
            "label": "旺儿",
            "value": 5,
            "image": "./images/photo/旺儿.jpg",
            "id": 3948,
            "categories": [
              "person"
            ],
            "info": "旺儿，又叫来旺儿，是王熙凤的小厮。"
          },
          {
            "label": "封肃",
            "value": 2,
            "id": 3949,
            "categories": [
              "person"
            ],
            "info": "封肃是甄士隐的岳父，殷实的务农人家。"
          },
          {
            "label": "李纨之哥",
            "value": 2,
            "id": 3950,
            "categories": [
              "person"
            ],
            "info": "李纨之哥。"
          },
          {
            "label": "小沙弥",
            "value": 3,
            "id": 3951,
            "categories": [
              "person"
            ],
            "info": "贾雨村的门子，见风使舵，善于专营。"
          },
          {
            "label": "皇上",
            "value": 1,
            "id": 3952,
            "categories": [
              "person"
            ],
            "info": "皇上只是一个影射,没有具体的称谓。他只是起到猜谜时的隐意作用。"
          },
          {
            "label": "薛姨父",
            "value": 2,
            "id": 3953,
            "categories": [
              "person"
            ],
            "info": "薛蟠之父。"
          },
          {
            "label": "北静王",
            "value": 3,
            "image": "./images/photo/北静王.jpg",
            "id": 3954,
            "categories": [
              "person"
            ],
            "info": "北静王，名水溶，年未弱冠，形容秀美，性情谦和。因祖上与贾府有世交之谊，故从未以异姓相见，更不以王位自居。"
          },
          {
            "label": "应天府",
            "value": 2,
            "id": 3955,
            "categories": [
              "location"
            ],
            "info": "应天府是今天河南的商丘。而金陵是指南京，明时称应天。应天和应天府不是同一个地方。曹雪芹本来就是故意混淆了红楼的时间和地点。"
          },
          {
            "label": "喜儿",
            "value": 1,
            "id": 3956,
            "categories": [
              "person"
            ],
            "info": "贾珍的跟班。"
          },
          {
            "label": "寿儿",
            "value": 1,
            "id": 3957,
            "categories": [
              "person"
            ],
            "info": "贾宝玉的一个小厮。"
          },
          {
            "label": "钱槐",
            "value": 1,
            "id": 3958,
            "categories": [
              "person"
            ],
            "info": "荣府男仆，被派跟贾环上学。他是赵姨娘内侄，父母在库上管帐。他看上柳五儿标致，就倚势求亲，怎耐五儿执意不从，他“心中又气又愧，发恨定要弄取成配，方了此愿”。"
          },
          {
            "label": "乌进孝",
            "value": 1,
            "image": "./images/photo/乌进孝.jpg",
            "id": 3959,
            "categories": [
              "person"
            ],
            "info": "宁府黑山村庄头。书中写他岁末赶了一个多月路程进京交租，向贾珍诉苦说：“年成实在不好。”贾珍看了乌进孝送上了的租单，不满地说：“真真是又教别过年了。”又议论到荣府这几年添了许多花销的事，又不能添些银钞产业，倒赔了许多，“不和你们要，找谁去！”现在过的日子真是“黄杨木作磬槌子——外头体面里头苦”。"
          },
          {
            "label": "南安郡王",
            "value": 3,
            "id": 3960,
            "categories": [
              "person"
            ],
            "info": "南安郡王是一位王爷。《红楼梦》里后来写贾府为秦可卿大办丧事，来了四家王爷参与祭奠，他们是东平郡王、南安郡王、西宁郡王和北静郡王。"
          },
          {
            "label": "冯渊",
            "value": 2,
            "id": 3961,
            "categories": [
              "person"
            ],
            "info": "冯渊，谐音“逢冤”，意指他逢受冤屈。金陵省一个小乡绅之子。自幼父母双亡，又无兄弟，只他一个人守着些薄产度日。"
          },
          {
            "label": "李守中",
            "value": 2,
            "id": 3962,
            "categories": [
              "person"
            ],
            "info": "李纨之父，曾为国子祭酒。"
          },
          {
            "label": "单聘仁",
            "value": 1,
            "id": 3963,
            "categories": [
              "person"
            ],
            "info": "单聘仁为贾政门下清客相公，对宝玉阿谀奉承。脂批以此名寓“善与骗人之意”。为迎元妃归省，他曾随贾蔷下姑苏聘请教习、采买女孩子、置办乐器行头等事。"
          },
          {
            "label": "程日兴",
            "value": 1,
            "id": 3964,
            "categories": [
              "person"
            ],
            "info": "程日兴，贾政门下清客相公，亦在古董行做事，宝玉说画美人是他的绝技。"
          },
          {
            "label": "詹光",
            "value": 1,
            "id": 3965,
            "categories": [
              "person"
            ],
            "info": "詹光字子亮，为贾政门下清客相公。善绘工细楼台，兴建大观园时，他也参加规划设计；为大观园题写对联匾额时，他也参加讨论。平时则陪贾政下棋聊天，见到宝玉百般奉承，极尽丑态。贾府失势，人也就辞去了。脂砚斋说作者给清客们起名多有调侃之意，詹光“盖沾光之意”（甲戌本）也。"
          },
          {
            "label": "昭儿",
            "value": 2,
            "id": 3967,
            "categories": [
              "person"
            ],
            "info": "贾琏心腹小厮。曾随贾琏送黛玉回扬州探林如海，如海殁，他即赶回京城报丧取衣。"
          },
          {
            "label": "癞头和尚",
            "value": 3,
            "image": "./images/photo/癞头和尚.jpg",
            "id": 3968,
            "categories": [
              "person"
            ],
            "info": "癞头和尚和跛足道人的戏份不多但地位颇重。作为外表“癞头跣脚”的神异人物，癞头和尚和跛足道人虽然其貌不扬，但却是小说中实在在的“神仙”，更是被曹雪芹寓以了既能令人富贵、又能一朝回贫的深刻含义。"
          },
          {
            "label": "跛足道人",
            "value": 4,
            "image": "./images/photo/跛足道人.jpg",
            "id": 3969,
            "categories": [
              "person"
            ],
            "info": "跛足道人，他看到了一个欲望无穷、沽名钓誉、巧取豪夺的泥浊世界，“世人都晓神仙好，惟有功名忘不了”，“世人都说神仙好，只有金银忘不了”，他所唱的《好了歌》，正是荒诞歌。甄士隐为《好了歌》作了注解。他的解，是对泥浊世界和荒诞人生的“解构”。原来，这个金玉其外的人群舞台，演的不过是一幕幕荒诞戏剧：“乱哄哄你方唱罢我登场，反认他乡是故乡。甚荒唐，到头来都是为他人作嫁衣裳。”（跛足道人别称应为渺渺真人，而空空道人则为书中另一形象。）"
          },
          {
            "label": "霍启",
            "value": 3,
            "id": 3970,
            "categories": [
              "person"
            ],
            "info": "甄士隐的家奴。谐音“祸起”。因为弄丢了甄士隐年方三岁的爱女甄英莲，便逃往他乡去了。"
          },
          {
            "label": "旺儿之子",
            "value": 5,
            "id": 3971,
            "categories": [
              "person"
            ],
            "info": "旺儿之子。"
          },
          {
            "label": "神瑛侍者",
            "value": 2,
            "id": 3972,
            "categories": [
              "person"
            ],
            "info": "神瑛侍者为贾宝玉的前身，他对三生石畔的绛珠仙草曾有灌溉之恩。贾宝玉身上的通灵宝玉是由顽石变化而成的。后在”绛珠“”泪尽夭折“后，经历了与宝钗”恩爱夫妻不到冬“、贾府的”忽喇喇似大厦倾“、女儿们的：“三春去后诸芳尽”等等人间不适后，心灰意冷地“悬崖散手”，与“绛珠”“证了前缘”。"
          },
          {
            "label": "林黛玉",
            "value": 21,
            "image": "./images/photo/林黛玉.jpg",
            "id": 4037,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之冠（与宝钗并列）。林如海与贾敏之女，宝玉的姑表妹，寄居荣国府 。她生性孤傲，多愁善感，才思敏捷。她与宝玉真心相爱，是宝玉反抗封建礼教的同盟，是自由恋爱的坚定追求者。"
          },
          {
            "label": "薛宝钗",
            "value": 17,
            "image": "./images/photo/薛宝钗.jpg",
            "id": 4038,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之冠（与黛玉并列），来自四大家族之薛家，薛姨妈之女。她大方典雅，举止雍容。她对官场黑暗深恶痛绝，但仍规谏宝玉读书做官。有一个金锁，与贾宝玉的通灵宝玉被外人称为金玉良缘。"
          },
          {
            "label": "贾巧姐",
            "value": 7,
            "image": "./images/photo/贾巧姐.jpg",
            "id": 4039,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十，贾琏与王熙凤的女儿。因生在七月初七，刘姥姥给她取名为“巧姐”。在贾府败落后，王仁和贾环要把她卖与藩王作使女。在紧急关头，幸亏刘姥姥相救，把她带去乡下。"
          },
          {
            "label": "贾惜春",
            "value": 8,
            "image": "./images/photo/贾惜春.jpg",
            "id": 4040,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之八，宁国府贾珍的妹妹，贾府四小姐，爱好绘画。因父亲贾敬一味好道炼丹，母亲又早逝，她一直在荣国府贾母身边长大。由于没有父母疼爱，养成了孤僻冷漠的性格，抄检大观园时，她狠心撵走丫环入画。四大家族的没落，三个姐姐的结局，使她产生了弃世的念头，看破红尘出家为尼。"
          },
          {
            "label": "王熙凤",
            "value": 25,
            "image": "./images/photo/王熙凤.jpg",
            "id": 4041,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之九，来自四大家族之王家，王夫人的内侄女，贾琏之妻。她精明强干，深得贾母和王夫人的信任，成为荣国府的管家奶奶，她为人处事圆滑周到，图财害命的事也干过不少，在前80回里她支持宝黛爱情。"
          },
          {
            "label": "贾迎春",
            "value": 10,
            "image": "./images/photo/贾迎春.jpg",
            "id": 4042,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之七，是贾赦与妾所生，贾府二小姐。她老实无能，懦弱怕事，有“二木头”的诨名。她不但作诗猜谜不如姐妹们，在处世为人上，也只知退让，任人欺侮。贾赦欠了孙家五千两银子还不出，就把她嫁给孙家，原著前80回未交代结局，根据金陵十二钗判词，最后被丈夫孙绍祖虐待致死。"
          },
          {
            "label": "李纨",
            "value": 12,
            "image": "./images/photo/李纨.jpg",
            "id": 4043,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十一，贾珠遗孀，生子贾兰。李纨虽处于膏粱锦绣之中，竟如“槁木死灰”一般，一概不闻不问，只知道抚养亲子，闲时陪侍小姑等女红、诵读而已  。她是个恪守封建礼法的节妇的典型。"
          },
          {
            "label": "贾元春",
            "value": 9,
            "image": "./images/photo/贾元春.jpg",
            "id": 4044,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之三，贾政与王夫人之长女，贾府大小姐。因贤孝才德，选入宫作女史。秦可卿出殡不久，元春晋封贵妃  。贾府为了迎接她省亲，建造了大观园。她给家族带来了“烈火烹油，鲜花著锦之盛”，但自己幽闭深宫，不能尽天伦之乐。"
          },
          {
            "label": "贾探春",
            "value": 14,
            "image": "./images/photo/贾探春.jpg",
            "id": 4045,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之四，贾政与赵姨娘所生，贾府三小姐。她精明能干，个性刚烈，有“玫瑰花”之诨名。抄检大观园时，她当众扇了王善保家的一巴掌。她对贾府的危局颇有感触，用兴利除弊的改革来挽救。改革虽成功，但无济大事。"
          },
          {
            "label": "邢夫人",
            "value": 12,
            "image": "./images/photo/邢夫人.jpg",
            "id": 4046,
            "categories": [
              "person"
            ],
            "info": "贾赦之妻子，无儿无女，地位尴尬，比不上妯娌王夫人。她禀性愚犟，只知奉承贾赦，家中小老婆一堆 ，一应大小事务，俱由贾赦摆布。出入银钱，一经她手，便克扣异常，婪取财货。儿女奴仆，一人不靠，一言不听，故甚不得人心。"
          },
          {
            "label": "赵姨娘",
            "value": 11,
            "image": "./images/photo/赵姨娘.jpg",
            "id": 4047,
            "categories": [
              "person"
            ],
            "info": "赵姨娘是荣国府二老爷贾政之妾，环三爷贾环和三姑娘贾探春之生母。也许，赵姨娘年轻时候是漂亮的，看探春的容貌大概可以略知一二。于是，赵姨娘成了贾政的妾，这是她幸运的一步，却也是不幸的开始。"
          },
          {
            "label": "贾敏",
            "value": 10,
            "id": 4048,
            "categories": [
              "person"
            ],
            "info": "贾敏既是贾代善与史太君之女，也是林黛玉之母。风华绝代，才情不凡，在《红楼梦》这本名著中作为已故人物出场。贾母最疼爱的小女儿，贾敏在未嫁之时受到贾母的呵护令王夫人刻骨铭心。嫁与巡盐御史林如海为妻。先育有一子，早夭，后生下林黛玉。早逝。"
          },
          {
            "label": "贾母",
            "value": 14,
            "image": "./images/photo/贾母.jpg",
            "id": 4049,
            "categories": [
              "person"
            ],
            "info": "来自四大家族之史家，贾府老太太，宝玉祖母 。在贾家从重孙媳妇做起，一直到有了重孙媳妇。她凭着自己的精明能干，才坐稳了贾家大家长的位置。"
          },
          {
            "label": "妙玉",
            "value": 3,
            "image": "./images/photo/妙玉.jpg",
            "id": 4050,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之六，苏州人氏。祖上是读书仕宦人家，因自幼多病，买了许多替身皆不中用，只得入了空门，带发修行。父母亡故后，她随师父进京。师父圆寂后，王夫人赏识她的佛学修为，请她入住大观园栊翠庵，原著前80回未交代结局。"
          },
          {
            "label": "王夫人",
            "value": 21,
            "image": "./images/photo/王夫人.jpg",
            "id": 4051,
            "categories": [
              "person"
            ],
            "info": "王夫人是故事主角贾宝玉和十二钗之一的皇妃贾元春之母，贾政之妻，是荣国府掌权管事的家长之一。她性格沉闷守旧，贾母形容她老实，又多病多痛。"
          },
          {
            "label": "史湘云",
            "value": 12,
            "image": "./images/photo/史湘云.jpg",
            "id": 4052,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之五，来自四大家族之史家，是贾母的侄孙女。自幼父母双亡，在家一点儿也作不得主，不时还要三更半夜做针线活儿。她心直口快，开朗豪爽，心怀坦荡，从未把儿女私情略萦心上。"
          },
          {
            "label": "秦可卿",
            "value": 13,
            "image": "./images/photo/秦可卿.jpg",
            "id": 4053,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十二，宁国府贾蓉之妻。她是营缮司郎中秦邦业从养生堂抱养的女儿，小名可儿，大名兼美。她长得袅娜纤巧，性格风流，行事又温柔和平，深得贾母等人的欢心  。但公公贾珍与她关系暧昧，致使其年轻早夭。"
          },
          {
            "label": "晴雯 ",
            "value": 9,
            "id": 4054,
            "categories": [
              "person"
            ],
            "info": "中国古典小说《红楼梦》中人物，金陵十二钗又副册之首，贾宝玉房里的四个大丫鬟之一，虽是丫鬟，但在宝玉房里过得千金小姐的生活。红学中普遍评价她有林黛玉之风。她长得风流灵巧，眉眼儿有点像林黛玉，口齿伶俐，针线活尤好，曾为宝玉病补雀金裘。 她本是赖妈妈家的丫鬟，因得贾母喜爱，固把她赐给宝玉。因娇生惯养，养成得理不饶人的性格。一次王夫人进园子看见她打骂小丫鬟，觉得她不合世家教养，再加上王善保家的等小人进谗，王夫人在她病得“四五日水米不曾沾牙”的情况下，从炕上拉下来，硬给撵了出去（放出去）。宝玉偷偷前去探望，晴雯深为感动。当夜，晴雯悲惨地死去。"
          },
          {
            "label": "袭人",
            "value": 8,
            "image": "./images/photo/袭人.jpg",
            "id": 4055,
            "categories": [
              "person"
            ],
            "info": "袭人原名珍珠，花姓，金陵十二钗又副册第二位，宝玉房里四个大丫鬟之首。从小因家贫被父母卖入贾府为婢，原是跟着贾母，起先服侍史湘云几年，贾母见喜袭人心地纯良，恪尽职守，便命她服侍宝玉。她对人和气，处事稳重，工作认真，得到大观园中上下人等的夸奖，是出了名的贤女。"
          },
          {
            "label": "平儿",
            "value": 7,
            "image": "./images/photo/平儿.jpg",
            "id": 4056,
            "categories": [
              "person"
            ],
            "info": "王熙凤的陪嫁丫头，贾琏的通房大丫头。她是个极聪明、极清俊的女孩儿。虽是凤姐的心腹，要帮着凤姐料理事务，但她为人很好，心地善良，平儿从不弄权仗势欺人，本能地同情那些和她地位相仿或更低的奴隶们，常背着王熙凤做些好事。"
          },
          {
            "label": "鸳鸯",
            "value": 5,
            "image": "./images/photo/鸳鸯.jpg",
            "id": 4057,
            "categories": [
              "person"
            ],
            "info": "随父姓金，是贾母的大丫头，甚受信任，因为这个缘故，她在贾府的丫头中有很高的地位。贾母平日倚之若左右手。贾母玩牌，她坐在旁边出主意；贾母摆宴，她入座充当令官。她自重自爱，从不以此自傲，仗势欺人，因此深得上下各色人等的好感和尊重。"
          },
          {
            "label": "紫鹃",
            "value": 6,
            "image": "./images/photo/紫鹃.jpg",
            "id": 4058,
            "categories": [
              "person"
            ],
            "info": "紫鹃原名鹦哥，是贾母房里的二等小丫头。贾母见林黛玉来时只带了两个人，恐不中使，便把鹦哥给了黛玉，改名为紫鹃。她聪明灵慧，和黛玉关系很好，情同姐妹，一时一刻不离开，是黛玉的“闺中贵友”，一心一意为黛玉着想，见黛玉是个孤儿，又和宝玉相爱，就劝黛玉趁老太太还硬朗的时候作定大事要紧。为此，她想出林家要接黛玉回苏州的话来试宝玉，使宝玉痴病大发。黛玉死后，随黛玉灵柩回苏州，为黛玉守灵。"
          },
          {
            "label": "莺儿",
            "value": 3,
            "image": "./images/photo/莺儿.jpg",
            "id": 4059,
            "categories": [
              "person"
            ],
            "info": "莺儿，本名黄金莺，薛宝钗的丫头。因薛宝钗嫌金莺拗口，改叫莺儿。她甚是乖巧，薛宝钗在观看通灵宝玉，念著玉上所镌之文“莫失莫忘，仙寿恒昌”时，她马上想到这和小姐项圈上的两句话是一对儿。她手特巧，擅长打络子、编花篮等，还颇懂色彩的搭配。薛宝钗嫁给宝玉后，她就成了薛宝钗的陪房丫头。"
          },
          {
            "label": "玉钏",
            "value": 3,
            "image": "./images/photo/玉钏.jpg",
            "id": 4060,
            "categories": [
              "person"
            ],
            "info": "姓白，名玉钏，金钏的妹妹，王夫人的丫头。金钏投井后，王夫人便把金钏的月钱给了玉钏。金钏周年，玉钏独坐廊下垂泪，可见底层人民的骨肉之情。玉钏应当是一个沉默的小姑娘，一直隐在姐姐身后。"
          },
          {
            "label": "金钏",
            "value": 4,
            "image": "./images/photo/金钏.jpg",
            "id": 4061,
            "categories": [
              "person"
            ],
            "info": "王夫人房中的丫鬟。本姓白。有一个妹妹，叫做“玉钏”，同是王夫人房中丫头。后跳井自杀。金钏给人以不谙世事，天真烂漫的印象。投井自杀的结局也就更点明其人刚烈、其质清洁。"
          },
          {
            "label": "彩云",
            "value": 2,
            "image": "./images/photo/彩云.jpg",
            "id": 4062,
            "categories": [
              "person"
            ],
            "info": "彩云是一个与贾环玩得很要好的丫头。贾环曾为她从芳官处讨些蔷薇硝来，彩云打开一看才知是茉莉粉，赵姨娘非要找芳官算帐，彩云死劝不住，只得躲入房内。后来，她禁不住赵姨娘的再三央求，从王夫人柜子里偷了些东西给贾环，被玉钏儿发觉。平儿为了不使探春难堪，要宝玉应下拿东西的名儿，彩云回来告诉赵姨娘。贾环起了疑心，认为她和宝玉好，并把彩云私赠之物都拿出来，照著她的脸摔了过去。彩云急得赌咒起誓，百般解说。贾环还是不信，气得彩云哭了个泪乾肠断，把东西全扔到河里了。后来她染了无医之症。"
          },
          {
            "label": "司棋",
            "value": 7,
            "image": "./images/photo/司棋.jpg",
            "id": 4063,
            "categories": [
              "person"
            ],
            "info": "贾迎春的丫头。脾气刚烈，雷厉风行。排挤柳家的未果，后与潘又安私通，被撵出大观园。最终与潘又安双双殉情而死。“品貌风流”，“高大丰壮”，做事干脆利落，有“侠女”之风。与其主子迎春“二木头”的性格形成了很大的反差。"
          },
          {
            "label": "芳官",
            "value": 2,
            "image": "./images/photo/芳官.jpg",
            "id": 4064,
            "categories": [
              "person"
            ],
            "info": "贾府买来的戏班成员，原姓花，姑苏人氏，正旦。戏班解散后成了贾宝玉的丫环，后来由于旁人诬陷，王夫人把她撵了出去，让她干娘在府外给她找个女婿，芳官不甘心再被干娘买卖，便跟水月庵的智通出家去了。芳官的男子气概在“洗头事件”和“蔷薇硝事件”中展现得淋漓尽致。芳官在群芳夜宴中唱《赏花时》意蕴深厚，为后来的故事埋下诸多伏笔。芳官很聪明伶俐，善解人意，脾气倔强。"
          },
          {
            "label": "麝月",
            "value": 4,
            "image": "./images/photo/麝月.jpg",
            "id": 4065,
            "categories": [
              "person"
            ],
            "info": "麝月是主人公贾宝玉身边一等丫鬟。怡红院里的四大丫鬟之一，她在书中的出场并不多，但是一个不可缺少的角色，因为她是唯一一个见证了贾府的走向衰败的全程、目睹了女儿的薄命的命运。在高鹗续书的后40回，是宝玉宝钗落魄后依然还在身边服侍的重要人物，故为：”开到荼蘼花事了“，但是即便是这样宝玉依然离开二人，出家为僧。麝月忠厚老实，心地善良。在怡红院几个大丫头中，她受袭人“陶冶教育”影响最深。"
          },
          {
            "label": "香菱",
            "value": 9,
            "image": "./images/photo/香菱.jpg",
            "id": 4066,
            "categories": [
              "person"
            ],
            "info": "本名甄英莲，原籍姑苏，甄士隐独女，眉心有米粒大小的一点胭脂记。金陵十二钗副册女儿，贾府通称为香菱。四岁那年元宵，在看社火花灯时因家奴霍启看护不当而被拐子拐走。养大后原是卖给金陵公子冯渊，中途却被薛蟠抢回去做小妾，宝钗给她起名叫香菱。后四十回夏金桂下毒害她，结果却阴差阳错毒死了自己。薛蟠出狱后，香菱扶正，因难产而死，甄士隐亲自接她归入太虚幻境。"
          },
          {
            "label": "抱琴",
            "value": 1,
            "id": 4067,
            "categories": [
              "person"
            ],
            "info": "抱琴是贾门大姐大贾元春的丫头，她是随元春进宫的唯一有名字的侍女。贾元春比另三个小姐的年纪要大出一块，可以说抱琴应该是四个丫头中年纪最大的。"
          },
          {
            "label": "宝蟾",
            "value": 4,
            "image": "./images/photo/宝蟾.jpg",
            "id": 4068,
            "categories": [
              "person"
            ],
            "info": "宝蟾是夏金桂的陪房丫头。长有三分姿色，举止轻浮。她与夏金桂这一对主仆是曹雪芹最后推出的一组人物，两个可悲可怜可恨的女人。宝蟾的名字与夏金桂颇有关合，桂蟾相合则是月亮的别称，蟾宫有桂，桂宫有蟾，形影相随。宝蟾虽是夏金桂陪房，却与主子各怀鬼胎，时而分庭抗礼，时而又狼狈为奸，以害人始害己终。"
          },
          {
            "label": "雪燕",
            "value": 2,
            "id": 4069,
            "categories": [
              "person"
            ],
            "info": "雪雁是中国古典小说《红楼梦》中的人物，雪雁是林黛玉从苏州家里带来的小丫头，为林黛玉的贴身丫头之一。 雪雁年龄虽小，却是个聪慧之人。虽无害人之心，却有防人之意。"
          },
          {
            "label": "春纤",
            "value": 1,
            "id": 4070,
            "categories": [
              "person"
            ],
            "info": "春纤，首见原著第二十九回，林黛玉丫环。书中出现过两次：一是随黛玉到清虚观打醮；一是晴雯受贾宝玉之托给黛玉送手帕时，见到她正往栏杠上晾手帕。"
          },
          {
            "label": "文杏",
            "value": 1,
            "id": 4071,
            "categories": [
              "person"
            ],
            "info": "薛宝钗丫环。薛姨妈曾说：“文杏又小，道三不着两。”"
          },
          {
            "label": "碧月",
            "value": 3,
            "id": 4072,
            "categories": [
              "person"
            ],
            "info": "李纨的丫头。一次她到怡红院找手帕，恰逢宝玉同麝月、晴雯、芳官笑闹不休。她很羡慕，宝玉问她：你们为什么不顽呢？碧月就说：“我们奶奶不顽，就把两个姨娘和琴姑娘也‘宾’住了。”"
          },
          {
            "label": "琥珀",
            "value": 1,
            "id": 4073,
            "categories": [
              "person"
            ],
            "info": "贾母身边的大丫鬟，照顾贾母起居生活，也负责传话、取物等各色杂事。"
          },
          {
            "label": "翠墨",
            "value": 1,
            "id": 4074,
            "categories": [
              "person"
            ],
            "info": "贾探春的贴身丫鬟。翠墨和探春的另一个丫鬟侍书（待书）的名字拼在一起有“书墨”二字，以示贾探春之才。"
          },
          {
            "label": "薛姨妈",
            "value": 10,
            "image": "./images/photo/薛姨妈.jpg",
            "id": 4075,
            "categories": [
              "person"
            ],
            "info": "薛蟠和薛宝钗之母，王夫人之妹。丧夫后携带一双儿女寄居贾府的梨香院，谐音“离乡怨”，后因元妃省亲，贾府采买的小戏子们入住院中学戏，薛姨妈等又迁住于园中东北上一处幽静住所。她以“慈”著称，由于举止言行得体，贾母常愿与她对坐聊天。她十分溺爱自己的子女，尤其怜惜薛蟠独根孤种，未免溺爱纵容，遂至老大无成，任薛蟠在外为所欲为。"
          },
          {
            "label": "夏金桂",
            "value": 7,
            "image": "./images/photo/夏金桂.jpg",
            "id": 4076,
            "categories": [
              "person"
            ],
            "info": "薛蟠的妻子。她家和薛家一样，同在户部挂名行商，也就是皇商，也是数一数二的大门户。家中只有一个寡母带着夏金桂，与薛蟠一样，被寡母纵容教养长大，她爱自己尊若菩萨，窥他人秽如粪土，外具花柳之姿，内秉风雷之性，嫁到薛家，使得薛家上下吃尽了苦头。夏金桂是个泼辣、善妒、狠毒的形象，同时也是“薄命司”中的一员，也是个值得我们同情和嗟伤的女子。"
          },
          {
            "label": "薛宝琴",
            "value": 6,
            "image": "./images/photo/薛宝琴.jpg",
            "id": 4077,
            "categories": [
              "person"
            ],
            "info": "皇商之女，小时跟父亲跑过不少地方。她是薛姨妈的侄女，薛蝌的胞妹，薛蟠，薛宝钗的堂妹。她长得十分美貌，贾母甚是喜爱，夸她比画上的还好看，曾欲把她说给贾宝玉为妻。王夫人也认她为干女儿。她自幼读书识字，本性聪敏，在大观园里曾作《怀古绝句十首》。后嫁都中梅翰林之子。她是一位近乎完美的人。她的美艳与纯真和邢岫烟的内敛与清高、李纹、李绮的超脱与淡然截然不同，十分耀眼。主要成就为作怀古诗10首。本性聪敏，才华横溢，性格开朗热情、活泼爱动。"
          },
          {
            "label": "邢岫烟",
            "value": 6,
            "image": "./images/photo/邢岫烟.jpg",
            "id": 4078,
            "categories": [
              "person"
            ],
            "info": "邢岫烟是邢忠夫妇的女儿，邢夫人的侄女。她家道贫寒，一家人前来投奔邢夫人，就在大观园迎春的住处紫菱洲住下。邢夫人对邢岫烟并不真心疼爱，只不过为了脸面之情。邢夫人甚至要求邢岫烟把每月二两银子的月钱省下一两来给她自己的父母，使得邢岫烟只得典当衣服来维持她在大观园的开支。邢岫烟生得端雅稳重，知书达礼，被薛姨妈看中，央求贾母作媒说与薛蝌，后嫁给薛蝌。"
          },
          {
            "label": "尤氏",
            "value": 9,
            "image": "./images/photo/尤氏.jpg",
            "id": 4079,
            "categories": [
              "person"
            ],
            "info": "别名大奶奶、珍大奶奶、珍哥媳妇，是曹雪芹小说《红楼梦》中的人物，贾珍的继室。虽为宁国府当家奶奶，但并无实权，素日只是顺从贾珍。她没什么才干，也没口齿。"
          },
          {
            "label": "尤二姐",
            "value": 7,
            "image": "./images/photo/尤二姐.jpg",
            "id": 4080,
            "categories": [
              "person"
            ],
            "info": "贾琏的二房，是贾珍夫人尤氏的继母带来的女儿。尤二姐模样标致，温柔和顺。贾珍馋涎妻妹的美貌，对尤二姐无微不至，当他玩腻后，就把她让给了贾琏。贾琏因惧怕王熙凤的淫威，只得偷偷娶尤二姐为二房，并把她安置在荣国府外，但不久被王熙凤发现，在她的借刀杀人计谋下，尤二姐备受折磨，当胎儿被庸医打下后，她绝望地吞金自尽。"
          },
          {
            "label": "尤三姐",
            "value": 5,
            "image": "./images/photo/尤三姐.jpg",
            "id": 4081,
            "categories": [
              "person"
            ],
            "info": "尤氏继母带来的女儿，尤二姐的妹妹，亦称作尤小妹。尤三姐模样儿风流标致，她又偏爱打扮得出色，自有一种万人不及的风情体态。贾珍、贾琏、贾蓉等好色之徒，对她颇为馋涎。但尤三姐不愿像姐姐那样遭人玩弄，她用泼辣作为武器，捍卫自己的清白。她看中柳湘莲后，就一心一意等他。但因柳湘莲认为宁国府多好色之徒（贾珍贾蓉之流），怀疑尤三姐也是个不干净之人，要索回定礼，刚烈的尤三姐在奉还定礼时自杀。尤三姐个性泼辣刚烈，不像姐姐尤二姐般任人摆布。"
          },
          {
            "label": "刘姥姥",
            "value": 4,
            "image": "./images/photo/刘姥姥.jpg",
            "id": 4082,
            "categories": [
              "person"
            ],
            "info": "刘姥姥是一位来自乡下贫农家庭的谙于世故的老婆婆，凤姐女儿巧姐的命运与她密切相关，巧姐判词和《留余庆》曲中均提及刘姥姥。这个老婆婆深受广大读者喜爱。她幽默风趣，精明强干，机智过人，她敢做敢为，有计有谋有办法，毫不顾虑拯救巧姐。"
          },
          {
            "label": "刘氏",
            "value": 5,
            "id": 4083,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的女儿。"
          },
          {
            "label": "同喜",
            "value": 1,
            "id": 4084,
            "categories": [
              "person"
            ],
            "info": "薛姨妈房中丫头。"
          },
          {
            "label": "小红",
            "value": 7,
            "image": "./images/photo/小红.jpg",
            "id": 4085,
            "categories": [
              "person"
            ],
            "info": "中国古典小说《红楼梦》中的人物，贾府的丫鬟，原名林红玉，因名字中含有“林”字，冲撞了林黛玉，名字中的“玉”字，又冲撞了林黛玉和贾宝玉。因而改名为小红（也称红儿）。小红原本在怡红院当差，烧茶炉子喂喂鸟雀，因有几分姿色，每每想到贾宝玉跟前现弄现弄，只因袭人等伶牙利爪下不去手。好容易得到一次倒茶的机会，出门就被秋纹、碧痕等撞见，说她也不照照镜子，配端茶倒水的不配，排挤的小红心灰意冷。后转投凤姐，在凤姐处得到重用。她俏丽恬静，口齿清楚，说话简短，又很齐全，不受封建礼教束缚，敢于追逐自己的幸福，自己的爱情，和贾芸通过手帕私定终身。"
          },
          {
            "label": "鲍二家的",
            "value": 4,
            "id": 4086,
            "categories": [
              "person"
            ],
            "info": "鲍二家的是贾府男仆鲍二的老婆，书中人称鲍二家的。鲍二家的是个悲剧人物，给了贾府的家仆鲍二，却与主子贾琏眉目传情，趁着凤姐生日，收了贾琏送来的“两块银子，两根簪子，两匹缎子”，跑去贾琏与王熙凤的屋里厮混偷情，被王熙凤当场捉奸。"
          },
          {
            "label": "瑞珠",
            "value": 3,
            "image": "./images/photo/瑞珠.jpg",
            "id": 4087,
            "categories": [
              "person"
            ],
            "info": "秦可卿的丫环。秦可卿生前与公公贾珍关系暧昧，服侍秦可卿的瑞珠应该有所觉察，因此受到贾珍的威胁。秦可卿死后，瑞珠触柱而亡。"
          },
          {
            "label": "藕官",
            "value": 3,
            "image": "./images/photo/藕官.jpg",
            "id": 4088,
            "categories": [
              "person"
            ],
            "info": "藕官是贾家买来的十二个唱戏的优伶之一，小戏子。为人义气，当她的朋友芳官被赵姨娘侮辱时，藕官冒着随时被驱逐的危险和伙伴们一起将平时不可一世的姨娘打了个痛快。戏班解散后，她成了林黛玉的丫环。后来因不肯聘嫁他人而出家为尼，长伴青灯古佛。"
          },
          {
            "label": "蕊官",
            "value": 1,
            "image": "./images/photo/蕊官.jpg",
            "id": 4089,
            "categories": [
              "person"
            ],
            "info": "蕊官是梨香院女伶，后遁入空门。"
          },
          {
            "label": "入画",
            "value": 2,
            "image": "./images/photo/入画.jpg",
            "id": 4090,
            "categories": [
              "person"
            ],
            "info": "贾惜春的丫环。因她父母在南方，她和哥哥只好跟随叔叔过日子，可叔叔婶婶只知道喝酒赌钱，她哥哥只好把做小厮所得的赏赐托人交给她保管，抄检大观园时被发现。贾惜春非要把她撵走，入画跪地哀求，百般苦告，尤氏只得叫人将入画带到宁府。"
          },
          {
            "label": "彩屏",
            "value": 1,
            "id": 4091,
            "categories": [
              "person"
            ],
            "info": "贾惜春的一名丫鬟。"
          },
          {
            "label": "彩儿",
            "value": 1,
            "id": 4092,
            "categories": [
              "person"
            ],
            "info": "服侍贾惜春的小小丫环。"
          },
          {
            "label": "丰儿",
            "value": 1,
            "id": 4093,
            "categories": [
              "person"
            ],
            "info": "王熙凤的小丫环。在王熙凤房中位置仅次于平儿。"
          },
          {
            "label": "绣橘",
            "value": 1,
            "image": "./images/photo/绣橘.jpg",
            "id": 4094,
            "categories": [
              "person"
            ],
            "info": "贾迎春的丫头，她伶牙俐齿、好胜争强。是一个一心护主的二等小丫鬟，为了迎春，她跟王住儿媳妇为攒珠累丝金凤快言快语地吵架，吵不出结果时又气又急，竟至于哭了。对懦小姐迎春的“只是脸软怕人恼”，众人是无奈兼觉得好笑，绣橘却是满心的着急忧虑和奋力维护。绣橘第二次出场，是司棋被撵走时，她在大观园中哭着赶来给司棋送绢包，说是姑娘给的，做个念想吧。最后，迎春被贾赦许嫁孙绍祖之时，绣橘是四个陪嫁丫鬟之一。绣橘性格泼辣，有义护义。"
          },
          {
            "label": "侍书",
            "value": 2,
            "image": "./images/photo/侍书.jpg",
            "id": 4095,
            "categories": [
              "person"
            ],
            "info": "侍书是中国古典名著《红楼梦》中的虚拟人物，她是贾探春的丫鬟，心眼灵活，口齿伶俐。最后结局是跟随探春远嫁。她的名字有很大争议，一作“侍书”。"
          },
          {
            "label": "小鹊",
            "value": 2,
            "id": 4096,
            "categories": [
              "person"
            ],
            "info": "小鹊这个名字具有讽刺意味，喜鹊是报喜的，而她却是报忧的。小鹊是个机灵、有心机的丫头。"
          },
          {
            "label": "翠缕",
            "value": 1,
            "image": "./images/photo/翠缕.jpg",
            "id": 4097,
            "categories": [
              "person"
            ],
            "info": "史湘云的丫环。她是一位天真的丫鬟，性格如湘云一般，豪爽直言。"
          },
          {
            "label": "榛儿",
            "value": 1,
            "id": 4098,
            "categories": [
              "person"
            ],
            "info": "香菱的丫鬟。"
          },
          {
            "label": "小螺",
            "value": 1,
            "id": 4099,
            "categories": [
              "person"
            ],
            "info": "薛宝琴的小丫环（首见第五十回），即宝琴立雪时，在宝琴身后抱一瓶红梅者。"
          },
          {
            "label": "篆儿",
            "value": 1,
            "id": 4100,
            "categories": [
              "person"
            ],
            "info": "邢岫烟丫环，岫烟曾命她悄悄地将当票递与莺儿，交给薛宝钗。"
          },
          {
            "label": "茄官",
            "value": 1,
            "id": 4101,
            "categories": [
              "person"
            ],
            "info": "贾府梨香院优伶，饰老旦。"
          },
          {
            "label": "善姐",
            "value": 2,
            "image": "./images/photo/善姐.jpg",
            "id": 4102,
            "categories": [
              "person"
            ],
            "info": "本为王熙凤身边的丫头，后被凤姐特意送给尤二姐使唤。“善姐”二字实为反讽：名为“善”，但其人不善。 善姐伶牙俐齿，时时对尤二姐冷嘲热讽，又“不服使唤”，故意在饮食起居上作践二姐。善姐之“不善”，实为凤姐逼死尤二姐的阴险步骤之一，故《脂》批说：“写使女欺压二姐，正写凤姐欺压二姐。”"
          },
          {
            "label": "素云",
            "value": 2,
            "id": 4103,
            "categories": [
              "person"
            ],
            "info": "素云是李纨的丫环。"
          },
          {
            "label": "小吉祥儿",
            "value": 1,
            "id": 4104,
            "categories": [
              "person"
            ],
            "info": "赵姨娘跟前的一个小丫头。"
          },
          {
            "label": "葵官",
            "value": 1,
            "id": 4105,
            "categories": [
              "person"
            ],
            "info": "贾府买来的十二个唱戏的女孩子之一。后来成为湘云的丫鬟。"
          },
          {
            "label": "宝珠",
            "value": 2,
            "image": "./images/photo/宝珠.jpg",
            "id": 4106,
            "categories": [
              "person"
            ],
            "info": "秦可卿的丫环。秦可卿死后，宝珠见秦氏没有孩子，愿为义女，请任摔丧驾灵之任，后在铁槛寺陪伴秦可卿之灵，执意不肯回宁国府。"
          },
          {
            "label": "同贵",
            "value": 1,
            "id": 4107,
            "categories": [
              "person"
            ],
            "info": "同贵是曹雪芹小说《红楼梦》中的人物，出场身份是薛姨妈的丫头"
          },
          {
            "label": "炒豆儿",
            "value": 1,
            "id": 4108,
            "categories": [
              "person"
            ],
            "info": "尤氏身边的小丫头。在李纨房中，炒豆儿为尤氏端水只弯腰捧着，被李纨斥责后才跪下。事情发生在大观园抄检之后，显示出最讲等级规矩的贾府实际上已经纪律松散，衰败的现象逐步加剧，仆妇佣人也不那么服帖了。"
          },
          {
            "label": "银蝶",
            "value": 1,
            "id": 4109,
            "categories": [
              "person"
            ],
            "info": "尤氏的丫头。"
          },
          {
            "label": "小舍儿",
            "value": 2,
            "id": 4110,
            "categories": [
              "person"
            ],
            "info": "小舍儿是金桂从小儿在家使唤的。"
          },
          {
            "label": "艾官",
            "value": 1,
            "id": 4111,
            "categories": [
              "person"
            ],
            "info": "贾府梨香院女伶，饰老外。戏班解散后，她做了探春的丫头。夏婆子调唆赵姨娘辱打芳官，恰被她听见，便悄悄地回了探春，探春却料定她与芳官等“皆饰一党，本皆淘气异常，便只答应，也不肯据此为实”。众女伶被逐出大观园后，她被干娘领出自行聘嫁。"
          },
          {
            "label": "文官",
            "value": 1,
            "id": 4112,
            "categories": [
              "person"
            ],
            "info": "贾府中十二个唱戏的女子之一，饰小生。在“十二官”中是个领头的，她由于口齿伶俐，甚得贾母欢心，薛姨妈等人也赞为“好个灵透孩子”，戏班解散后，贾母便留下她自使。此后没再出场。众女伶被王夫人逐出大观园时她因在贾母身边而没被牵连进去。"
          },
          {
            "label": "彩霞",
            "value": 5,
            "id": 4113,
            "categories": [
              "person"
            ],
            "info": "彩霞是王夫人的大丫头，有时却又是赵姨娘身边的二等丫头。她非常能干，不仅要提醒王夫人大小家事，就连贾政在家出外去的一应大小家事，也得由她记着。她和贾环还合得来，不时规劝他安分些，何苦讨人嫌，但贾环并不领情。赵姨娘素日和彩霞好，巴不得彩霞能嫁给贾环，自己有个臂膀。赵姨娘求贾政说情，又要贾环去讨，贾环却认为不过是个丫头，丢开手便走了。后由王熙凤和贾琏作主，配给容貌丑陋、酗酒赌博的旺儿之子。"
          },
          {
            "label": "娇红",
            "value": 1,
            "id": 4114,
            "categories": [
              "person"
            ],
            "info": "邢夫人的丫头。"
          },
          {
            "label": "秋桐",
            "value": 3,
            "image": "./images/photo/秋桐.jpg",
            "id": 4115,
            "categories": [
              "person"
            ],
            "info": "原为贾赦的丫环。贾琏偷娶尤二姐后，出门为父亲贾赦办事，事情办的很出色，贾赦十分欢喜，夸他能干，把秋桐赏他为妾。秋桐自以为揣摩到了凤姐的心思，加上自己是贾赦所赐，无人敢冒犯她。王熙凤曾借她用“借剑杀人”之计，把尤二姐迫害而死。"
          },
          {
            "label": "小吉祥",
            "value": 1,
            "id": 4116,
            "categories": [
              "person"
            ],
            "info": "赵姨娘的丫头。"
          },
          {
            "label": "娇杏",
            "value": 3,
            "image": "./images/photo/娇杏.jpg",
            "id": 4117,
            "categories": [
              "person"
            ],
            "info": "娇杏是《红楼梦》中甄家的丫环，金陵十二钗副册。 “ 生得仪容不俗，眉目清秀，虽无十分姿色，却亦有动人之处。”"
          },
          {
            "label": "菂官",
            "value": 1,
            "id": 4118,
            "categories": [
              "person"
            ],
            "info": "作药官，《红楼梦》人物，系贾府买来的十二个唱戏的女孩之一，饰小旦。她在戏中与小生藕官常扮夫妻，二人相好异常，同性相恋。但不久她夭亡了，致使藕官伤心欲绝，哭得死去活来。"
          },
          {
            "label": "旺儿媳妇",
            "value": 5,
            "id": 4119,
            "categories": [
              "person"
            ],
            "info": "荣府女仆，王熙凤的陪房。她仗着与丈夫一起专替凤姐出力办事，放账收债。故无所忌惮，她倚势为“酗酒赌博”，“容貌丑陋”的儿子强娶彩霞，逼得彩霞之母纵不愿意也只好忍气吞声同意了。她是奴仆中的一“霸”。"
          },
          {
            "label": "王嬷嬷",
            "value": 2,
            "image": "./images/photo/王嬷嬷.jpg",
            "id": 4120,
            "categories": [
              "person"
            ],
            "info": "王嬷嬷是《红楼梦》中林黛玉的奶娘，贾老太君说王嬷嬷已经很老，料黛玉使唤不如意，于是又给黛玉安排了紫鹃这个丫鬟。"
          },
          {
            "label": "李嬷嬷",
            "value": 3,
            "image": "./images/photo/李嬷嬷.jpg",
            "id": 4121,
            "categories": [
              "person"
            ],
            "info": "贾宝玉的乳母。她是一个年老爱唠叨的人。儿子李贵是跟宝玉上学的仆人。"
          },
          {
            "label": "多姑娘",
            "value": 4,
            "image": "./images/photo/多姑娘.jpg",
            "id": 4122,
            "categories": [
              "person"
            ],
            "info": "多姑娘出场就是跟贾琏的一次偷情。多姑娘也是晴雯的表嫂，在晴雯临死病重、宝玉前去探望之时，她“便坐在炕沿上，却紧紧的将宝玉搂入怀中。”但后来，得知宝玉是来为情探望晴雯，便说“如今我反后悔错怪了你们。既然如此，你但放心。以后你只管来，我也不罗唣你”。也看出来了她的善以及对真爱的一丝羡慕，因为在作者的笔下，“多姑娘儿”代表了女性对男权社会的一种反抗，寄托了作者对女性解放的希望。对多姑娘的描写与对贾宝玉的描写遥相呼应，突出了作者的写作技巧。"
          },
          {
            "label": "赖大家的",
            "value": 3,
            "id": 4123,
            "categories": [
              "person"
            ],
            "info": "赖大家的是贾府管家赖大的妻子，人们称她为赖大家的。"
          },
          {
            "label": "吴兴登家的",
            "value": 4,
            "id": 4124,
            "categories": [
              "person"
            ],
            "info": "王夫人房里的仆人。"
          },
          {
            "label": "王善保家的",
            "value": 6,
            "image": "./images/photo/王善保家的.jpg",
            "id": 4125,
            "categories": [
              "person"
            ],
            "info": "王善保家的，是中国著名古典小说《红楼梦》中的人物，她是荣国府大房太太邢夫人的陪房婆子，也是邢夫人的心腹。"
          },
          {
            "label": "柳嫂子",
            "value": 3,
            "id": 4126,
            "categories": [
              "person"
            ],
            "info": "大观园内厨房主管，柳五儿之母。她曾经在十二个小戏子暗指的梨香院里当厨子，因此与小戏子芳官儿交好。她想通过芳官把女儿送进贾宝玉所住的怡红院，故对怡红院中人格外照顾。她巴结晴雯芳官儿，因此怠慢得罪了贾迎春的丫头司棋，被司棋领着缀锦楼的丫头们将大厨房一通乱砸，柳家的怕了，等司棋回去。她忙蒸了司棋要的蛋羹，却被司棋泼于地下。后因小丫头莲花儿等，她被牵连到“玫瑰露”事件。秦显家的乘机买通林之孝家的，企图撵柳家母女出去，并接管厨房。次日，“玫瑰露”事件被平儿按下，柳嫂母女冤情大白，才又回到内厨房管事。"
          },
          {
            "label": "李纹",
            "value": 3,
            "id": 4127,
            "categories": [
              "person"
            ],
            "info": "李婶娘之女，李纨堂妹。雪芹先生写其有超脱、淡然之美。其性格如梅——美丽高冷却又不失坚韧，与其柔弱寡断的堂姐胞妹——李纨和李绮有着鲜明的对比。后四十回结局猜想：也许并非世人所续“随便嫁了个人”，而可能是与薛宝琴、邢岫烟扮演不同的“悲剧红颜”。一些专家猜测，李纹很有可能是与妙玉一同被拐走。"
          },
          {
            "label": "李绮",
            "value": 3,
            "id": 4128,
            "categories": [
              "person"
            ],
            "info": "李纹的妹妹，李纨的堂妹，李纨寡婶之女。金陵十二钗副册之一，书中李绮被称赞是个水葱儿般水灵的姑娘。"
          },
          {
            "label": "李婶娘",
            "value": 4,
            "id": 4129,
            "categories": [
              "person"
            ],
            "info": "李纹、李绮之母。"
          },
          {
            "label": "青儿",
            "value": 3,
            "id": 4130,
            "categories": [
              "person"
            ],
            "info": "青儿--王狗儿之女（首见《红楼梦》第六回）。贾府被抄后，刘姥姥带她进府探望，恰逢王仁等要将巧姐送给外藩王爷，巧姐就扮作青儿模样避走乡下。"
          },
          {
            "label": "周瑞家的",
            "value": 6,
            "image": "./images/photo/周瑞家的.jpg",
            "id": 4131,
            "categories": [
              "person"
            ],
            "info": "周瑞家的，是王夫人的陪房，冷子兴的岳母。常在大观园及王夫人、琏二奶奶处做事露面。处事较为圆滑、见风使舵，是贾宝玉口中典型的“鱼眼睛”形象。 在荣国府里，周瑞家的管太太奶奶们出行的事。她的丈夫周瑞管宁国府地租庄子银钱的出入。"
          },
          {
            "label": "林之孝家的",
            "value": 4,
            "image": "./images/photo/林之孝家的.jpg",
            "id": 4132,
            "categories": [
              "person"
            ],
            "info": "林之孝家的是林红玉（小红）之母，也是荣国府大管家之一，为人行事低调，与其夫被喻为“天聋地哑”，地处管家高位却如此行事低调，实属难得，但也是媚上欺下，时不时也会利用自己的权利谋些好处。"
          },
          {
            "label": "柳五儿",
            "value": 2,
            "image": "./images/photo/柳五儿.jpg",
            "id": 4133,
            "categories": [
              "person"
            ],
            "info": "柳嫂子之女，十六岁，她虽是厨役之女，书中形容她生得人物与平、袭、鸳、紫相类，说她相貌与平儿、鸳鸯、袭人、紫鹃等人一样的漂亮。因他排行第五，便叫他五儿。五儿和宝玉的丫鬟芳官是好朋友，芳官把宝玉喝剩的玫瑰露给了她，因母亲不慎得罪了司棋等人，被冠以偷窃的贼名。幸亏平儿相助，她们母女的冤情得以洗清，柳五儿在曹雪芹前八十回因此事已经病死，后续书却把她写活了，并安排成为贾宝玉的丫环。"
          },
          {
            "label": "秦显家的",
            "value": 1,
            "image": "./images/photo/秦显家的.jpg",
            "id": 4134,
            "categories": [
              "person"
            ],
            "info": "在《红楼梦》中是个极次要的人物，她在第六十一回《投鼠忌器宝玉瞒赃 判冤决狱平儿行权》末尾方被提及，到第六十二回《憨湘云醉眠芍药茵 呆香菱情解石榴裙》开篇一现，曹公写她只有寥寥三百来字，但却构成一个令人难忘的艺术形象。"
          },
          {
            "label": "莲花儿",
            "value": 2,
            "id": 4135,
            "categories": [
              "person"
            ],
            "info": "迎春房里的小丫头。司棋让她吩咐厨房炖鸡蛋，柳嫂不从，她就同柳嫂口角，后又赌气添了一大篇话，惹得司棋火起，带领丫头们大闹厨房。"
          },
          {
            "label": "金氏",
            "value": 2,
            "id": 4136,
            "categories": [
              "person"
            ],
            "info": "贾璜之妻。"
          },
          {
            "label": "五嫂子",
            "value": 2,
            "id": 4138,
            "categories": [
              "person"
            ],
            "info": "贾芸之母。"
          },
          {
            "label": "来升媳妇",
            "value": 2,
            "id": 4139,
            "categories": [
              "person"
            ],
            "info": "宁府都总管赖二之妻。在秦氏丧仪中听凤姐令，每日揽总查看，不敢疏忽。"
          },
          {
            "label": "龄官",
            "value": 2,
            "image": "./images/photo/龄官.jpg",
            "id": 4140,
            "categories": [
              "person"
            ],
            "info": "龄官是清代小说《红楼梦》中的女性人物，贾家买来的十二个唱戏的女孩之一。戏活极好，长相、气质很像林黛玉，也有着林黛玉的敏感与清高，相当于林黛玉的一个射影，素与贾蔷相好。与贾蔷的爱情也给了宝玉不一样的启发。"
          },
          {
            "label": "封氏",
            "value": 5,
            "id": 4141,
            "categories": [
              "person"
            ],
            "info": "封氏是曹雪芹小说《红楼梦》中的人物，甄士隐的夫人。"
          },
          {
            "label": "赖嬷嬷",
            "value": 2,
            "image": "./images/photo/赖嬷嬷.jpg",
            "id": 4142,
            "categories": [
              "person"
            ],
            "info": "贾府管事之一，赖大的母亲，孙子赖尚荣在贾府的帮助做了知县，该人物在贾府里属于年高而有体面的嬷嬷。"
          },
          {
            "label": "周姨娘",
            "value": 1,
            "id": 4143,
            "categories": [
              "person"
            ],
            "info": "周姨娘为贾政妾，常与赵姨娘一起出现于奉侍贾母的场面上。"
          },
          {
            "label": "翠云",
            "value": 1,
            "id": 4144,
            "categories": [
              "person"
            ],
            "info": "贾赦之妾。"
          },
          {
            "label": "嫣红",
            "value": 1,
            "id": 4145,
            "categories": [
              "person"
            ],
            "info": "出自《红楼梦》第四十七回《呆霸王调情遭苦打 冷郎君惧祸走他乡》。邢夫人将方才的话只略说了几句，贾赦无法，又含愧，自此便告病，且不敢见贾母，只打发邢夫人及贾琏每日过去请安。只得又各处遣人购求寻觅，终究费了八百两银子买了一个十七岁的女孩子来，名唤嫣红，收在屋内。不在话下。"
          },
          {
            "label": "坠儿",
            "value": 3,
            "image": "./images/photo/坠儿.jpg",
            "id": 4146,
            "categories": [
              "person"
            ],
            "info": "宝玉的小丫头，因偷了平儿的一个镯子，被晴雯得知后，气得晴雯蛾眉倒蹙，凤眼圆睁，把她撵了出去。值得注意的是，曹雪芹有意把小红和坠儿设计成一对密友，这在那个时代，那种社会环境里，特别是在赫赫森严的贵族府邸里，她们的作为，她们的话语，才是真正具有叛逆性的，是晴雯等望尘莫及的，放射出真正的人性光辉。"
          },
          {
            "label": "智能儿",
            "value": 2,
            "id": 4147,
            "categories": [
              "person"
            ],
            "info": "秦钟之相好。"
          },
          {
            "label": "净虚",
            "value": 1,
            "id": 4148,
            "categories": [
              "person"
            ],
            "info": "馒头庵的姑子净虚，小尼姑智能儿的师父。馒头庵就是水月庵，因他庙里做的馒头好，就起了这个浑号，离铁槛寺不远。"
          },
          {
            "label": "万儿",
            "value": 3,
            "id": 4149,
            "categories": [
              "person"
            ],
            "info": "贾珍、尤氏的丫头。"
          },
          {
            "label": "司棋母",
            "value": 2,
            "image": "./images/photo/司棋母.jpg",
            "id": 4150,
            "categories": [
              "person"
            ],
            "info": "-迎春丫环司棋的母亲，阻挠女儿和自己的侄儿自己结合，以致潘又安、司棋双双徇情自尽。"
          },
          {
            "label": "白大娘",
            "value": 2,
            "id": 4151,
            "categories": [
              "person"
            ],
            "info": "金钏、玉钏之母"
          },
          {
            "label": "尤老娘",
            "value": 1,
            "image": "./images/photo/尤老娘.jpg",
            "id": 4152,
            "categories": [
              "person"
            ],
            "info": "尤二姐、尤三姐的亲娘，也是宁国府尤氏的继母，两个女儿也与尤氏没有血缘关系。她的两个女儿都生的青春貌美，她想用国色天香的尤二姐来嫁给贾琏，提高她在贾府的地位，可是后来尤二姐被凤姐害死了，尤三姐也因想柳湘莲订婚不成，自刎了。从母亲的角度来说，她是一个失败的母亲，失去了两个女儿。"
          },
          {
            "label": "周奶奶",
            "value": 1,
            "id": 4153,
            "categories": [
              "person"
            ],
            "info": "史湘云之奶妈。"
          },
          {
            "label": "豆官",
            "value": 1,
            "id": 4154,
            "categories": [
              "person"
            ],
            "info": "贾家买来唱戏的女优伶。饰小花脸。身量年纪皆极小，又极鬼灵，故曰豆官。戏班解散后，随侍薛宝琴。从此，园中人或唤她“阿豆”，或唤作“炒豆子”，而薛宝琴则将她打扮成琴童摸样，唤作“豆童”。"
          },
          {
            "label": "文花",
            "value": 1,
            "id": 4155,
            "categories": [
              "person"
            ],
            "info": "贾珍的小妾，很会唱曲。书中称她“喉清嗓嫩，真令人魂醉魄飞。”"
          },
          {
            "label": "赵嬷嬷",
            "value": 3,
            "image": "./images/photo/赵嬷嬷.jpg",
            "id": 4156,
            "categories": [
              "person"
            ],
            "info": "贾琏的乳母。曾求贾琏夫妇给她的两个儿子找份差事干。"
          },
          {
            "label": "娄氏",
            "value": 1,
            "id": 4157,
            "categories": [
              "person"
            ],
            "info": "贾菌之母。"
          },
          {
            "label": "老叶妈",
            "value": 1,
            "id": 4158,
            "categories": [
              "person"
            ],
            "info": "怡红院老妈子，宝玉小厮茗烟之母，与莺儿妈相善，莺儿还认她作干娘。"
          },
          {
            "label": "周氏",
            "value": 1,
            "id": 4160,
            "categories": [
              "person"
            ],
            "info": "贾芹之母。"
          },
          {
            "label": "佩凤",
            "value": 1,
            "id": 4161,
            "categories": [
              "person"
            ],
            "info": "贾珍之妾，年青姣憨之女子。"
          },
          {
            "label": "代儒夫人",
            "value": 2,
            "image": "./images/photo/代儒夫人.jpg",
            "id": 4162,
            "categories": [
              "person"
            ],
            "info": "贾瑞之祖母。"
          },
          {
            "label": "春燕",
            "value": 2,
            "image": "./images/photo/春燕.jpg",
            "id": 4163,
            "categories": [
              "person"
            ],
            "info": "何婆子之女，贾宝玉房内的二等丫鬟，贾府内世仆的女孩，聪明伶俐乐于助人。"
          },
          {
            "label": "何婆子",
            "value": 1,
            "image": "./images/photo/何婆子.jpg",
            "id": 4164,
            "categories": [
              "person"
            ],
            "info": "秦钟的丫头春燕之母。"
          },
          {
            "label": "碧痕",
            "value": 1,
            "image": "./images/photo/碧痕.jpg",
            "id": 4165,
            "categories": [
              "person"
            ],
            "info": "碧痕是贾宝玉房里的一个丫鬟。曾经在红楼梦中第20，24，26，31，77回出现过，碧痕的长相在书中从未出现过，有两次描写她的牙尖嘴利，心气很高。"
          },
          {
            "label": "笑儿",
            "value": 1,
            "id": 4166,
            "categories": [
              "person"
            ],
            "info": "史湘云的丫头。"
          },
          {
            "label": "警幻仙姑",
            "value": 2,
            "id": 4167,
            "categories": [
              "person"
            ],
            "info": "太虚幻境司主，相当于西方的维纳斯、丘比特。她首次提出“意淫”的概念。《红楼梦》第五回，她受宁荣二公所托，让宝玉阅览金陵十二钗命运簿册，聆听《红楼梦曲》十四支，又将妹妹可卿许配于宝玉，引导宝玉“留意于孔孟之间，委身于经济之道”。警幻之名，即设计情欲声色梦幻警醒宝玉入于仕途经济正路。无奈宝玉竟未悟，梦醒后依然不改意淫志行，直到后四十回第二梦才了悟。根据《红楼梦》中的脂批探佚，警幻仙子应该在最后一回中展示警幻情榜。"
          },
          {
            "label": "马道婆",
            "value": 4,
            "image": "./images/photo/马道婆.jpg",
            "id": 4168,
            "categories": [
              "person"
            ],
            "info": "贾宝玉寄名的干娘，是个搞封建迷信，邪魔歪道的混帐东西，经常出入贾府各房各屋。"
          },
          {
            "label": "南安太妃",
            "value": 5,
            "image": "./images/photo/南安太妃.jpg",
            "id": 4169,
            "categories": [
              "person"
            ],
            "info": "南安太妃是中国古典小说《红楼梦》中的人物。南安太妃在小说中出场不多，但是决定了贾府三小姐探春的命运。贾母过生日的那一次，她前来赴宴应酬，见到了宝钗、黛玉、湘云和探春，对这几个女孩子她非常喜欢。"
          },
          {
            "label": "夏婆子",
            "value": 1,
            "id": 4170,
            "categories": [
              "person"
            ],
            "info": "夏婆子是红楼梦中的一个人物，她是藕官的干娘，小蝉的外婆，平日在大观园当差。"
          },
          {
            "label": "费婆子",
            "value": 1,
            "id": 4171,
            "categories": [
              "person"
            ],
            "info": "邢夫人的陪房。"
          },
          {
            "label": "周瑞之女",
            "value": 4,
            "id": 4172,
            "categories": [
              "person"
            ],
            "info": "周瑞之女。"
          },
          {
            "label": "绛珠仙子",
            "value": 2,
            "id": 4173,
            "categories": [
              "person"
            ],
            "info": "传说中的美女仙，还传说是贾宝玉和林黛玉的第二世情缘，是绛珠仙子在“五衷内对神瑛侍者郁结着缠绵不尽之意”，一心回报神瑛侍者的灌溉之恩。"
          }
        ],
        "edges": [{
            "id": 3776,
            "label": "位于",
            "from": 3838,
            "to": 3851
          },
          {
            "id": 3777,
            "label": "位于",
            "from": 3839,
            "to": 3851
          },
          {
            "id": 3778,
            "label": "位于",
            "from": 3840,
            "to": 3851
          },
          {
            "id": 3779,
            "label": "位于",
            "from": 3841,
            "to": 3851
          },
          {
            "id": 3780,
            "label": "位于",
            "from": 3842,
            "to": 3851
          },
          {
            "id": 3781,
            "label": "位于",
            "from": 3843,
            "to": 3856
          },
          {
            "id": 3782,
            "label": "位于",
            "from": 3844,
            "to": 3851
          },
          {
            "id": 3783,
            "label": "位于",
            "from": 3845,
            "to": 3851
          },
          {
            "id": 3784,
            "label": "位于",
            "from": 3846,
            "to": 3851
          },
          {
            "id": 3785,
            "label": "位于",
            "from": 3847,
            "to": 3848
          },
          {
            "id": 3786,
            "label": "位于",
            "from": 3851,
            "to": 3856
          },
          {
            "id": 3787,
            "label": "位于",
            "from": 3852,
            "to": 3853
          },
          {
            "id": 3788,
            "label": "位于",
            "from": 3858,
            "to": 3850
          },
          {
            "id": 3789,
            "label": "位于",
            "from": 3862,
            "to": 3850
          },
          {
            "id": 3122,
            "label": "母亲",
            "from": 3863,
            "to": 4051
          },
          {
            "id": 3090,
            "label": "父亲",
            "from": 3863,
            "to": 3869
          },
          {
            "id": 3142,
            "label": "妻子",
            "from": 3863,
            "to": 4038
          },
          {
            "id": 3251,
            "label": "姐姐",
            "from": 3863,
            "to": 4044
          },
          {
            "id": 3262,
            "label": "老师",
            "from": 3863,
            "to": 3910
          },
          {
            "id": 3271,
            "label": "奶妈",
            "from": 3863,
            "to": 4121
          },
          {
            "id": 3278,
            "label": "交好",
            "from": 3863,
            "to": 3881
          },
          {
            "id": 3289,
            "label": "交好",
            "from": 3863,
            "to": 3890
          },
          {
            "id": 3292,
            "label": "交好",
            "from": 3863,
            "to": 3893
          },
          {
            "id": 3294,
            "label": "交好",
            "from": 3863,
            "to": 3954
          },
          {
            "id": 3295,
            "label": "同宗",
            "from": 3863,
            "to": 3878
          },
          {
            "id": 3312,
            "label": "跟班",
            "from": 3863,
            "to": 3907
          },
          {
            "id": 3316,
            "label": "跟班",
            "from": 3863,
            "to": 3926
          },
          {
            "id": 3320,
            "label": "仆人",
            "from": 3863,
            "to": 4055
          },
          {
            "id": 3328,
            "label": "仆人",
            "from": 3863,
            "to": 4054
          },
          {
            "id": 3335,
            "label": "仆人",
            "from": 3863,
            "to": 4065
          },
          {
            "id": 3337,
            "label": "仆人",
            "from": 3863,
            "to": 4064
          },
          {
            "id": 3338,
            "label": "仆人",
            "from": 3863,
            "to": 4163
          },
          {
            "id": 3339,
            "label": "仆人",
            "from": 3863,
            "to": 4165
          },
          {
            "id": 3340,
            "label": "参与",
            "from": 3863,
            "to": 3779
          },
          {
            "id": 3382,
            "label": "参与",
            "from": 3863,
            "to": 3825
          },
          {
            "id": 3396,
            "label": "参与",
            "from": 3863,
            "to": 3781
          },
          {
            "id": 3401,
            "label": "参与",
            "from": 3863,
            "to": 3790
          },
          {
            "id": 3402,
            "label": "参与",
            "from": 3863,
            "to": 3782
          },
          {
            "id": 3403,
            "label": "参与",
            "from": 3863,
            "to": 3783
          },
          {
            "id": 3404,
            "label": "参与",
            "from": 3863,
            "to": 3784
          },
          {
            "id": 3405,
            "label": "参与",
            "from": 3863,
            "to": 3794
          },
          {
            "id": 3406,
            "label": "参与",
            "from": 3863,
            "to": 3797
          },
          {
            "id": 3407,
            "label": "参与",
            "from": 3863,
            "to": 3799
          },
          {
            "id": 3408,
            "label": "参与",
            "from": 3863,
            "to": 3802
          },
          {
            "id": 3409,
            "label": "参与",
            "from": 3863,
            "to": 3803
          },
          {
            "id": 3410,
            "label": "参与",
            "from": 3863,
            "to": 3804
          },
          {
            "id": 3411,
            "label": "参与",
            "from": 3863,
            "to": 3810
          },
          {
            "id": 3412,
            "label": "参与",
            "from": 3863,
            "to": 3815
          },
          {
            "id": 3413,
            "label": "参与",
            "from": 3863,
            "to": 3817
          },
          {
            "id": 3414,
            "label": "参与",
            "from": 3863,
            "to": 3818
          },
          {
            "id": 3415,
            "label": "参与",
            "from": 3863,
            "to": 3833
          },
          {
            "id": 3416,
            "label": "参与",
            "from": 3863,
            "to": 3821
          },
          {
            "id": 3417,
            "label": "参与",
            "from": 3863,
            "to": 3837
          },
          {
            "id": 3418,
            "label": "参与",
            "from": 3863,
            "to": 3785
          },
          {
            "id": 3255,
            "label": "哥哥",
            "from": 3863,
            "to": 3876
          },
          {
            "id": 3187,
            "label": "居住地",
            "from": 3863,
            "to": 3845
          },
          {
            "id": 3222,
            "label": "原籍",
            "from": 3863,
            "to": 3855
          },
          {
            "id": 3261,
            "label": "干娘",
            "from": 3863,
            "to": 4168
          },
          {
            "id": 3250,
            "label": "前世",
            "from": 3863,
            "to": 3972
          },
          {
            "id": 3223,
            "label": "原籍",
            "from": 3864,
            "to": 3855
          },
          {
            "id": 3188,
            "label": "居住地",
            "from": 3864,
            "to": 3848
          },
          {
            "id": 3143,
            "label": "妻子",
            "from": 3864,
            "to": 4049
          },
          {
            "id": 3091,
            "label": "父亲",
            "from": 3864,
            "to": 3880
          },
          {
            "id": 3224,
            "label": "原籍",
            "from": 3865,
            "to": 3855
          },
          {
            "id": 3189,
            "label": "居住地",
            "from": 3865,
            "to": 3849
          },
          {
            "id": 3092,
            "label": "父亲",
            "from": 3865,
            "to": 3879
          },
          {
            "id": 3225,
            "label": "原籍",
            "from": 3866,
            "to": 3855
          },
          {
            "id": 3190,
            "label": "居住地",
            "from": 3866,
            "to": 3849
          },
          {
            "id": 3093,
            "label": "父亲",
            "from": 3866,
            "to": 3865
          },
          {
            "id": 3256,
            "label": "哥哥",
            "from": 3867,
            "to": 3866
          },
          {
            "id": 3226,
            "label": "原籍",
            "from": 3867,
            "to": 3855
          },
          {
            "id": 3191,
            "label": "居住地",
            "from": 3867,
            "to": 3852
          },
          {
            "id": 3094,
            "label": "父亲",
            "from": 3867,
            "to": 3865
          },
          {
            "id": 3383,
            "label": "参与",
            "from": 3868,
            "to": 3820
          },
          {
            "id": 3341,
            "label": "参与",
            "from": 3868,
            "to": 3786
          },
          {
            "id": 3227,
            "label": "原籍",
            "from": 3868,
            "to": 3855
          },
          {
            "id": 3192,
            "label": "居住地",
            "from": 3868,
            "to": 3848
          },
          {
            "id": 3182,
            "label": "姬妾",
            "from": 3868,
            "to": 4144
          },
          {
            "id": 3176,
            "label": "姬妾",
            "from": 3868,
            "to": 4145
          },
          {
            "id": 3144,
            "label": "妻子",
            "from": 3868,
            "to": 4046
          },
          {
            "id": 3123,
            "label": "母亲",
            "from": 3868,
            "to": 4049
          },
          {
            "id": 3095,
            "label": "父亲",
            "from": 3868,
            "to": 3864
          },
          {
            "id": 3397,
            "label": "参与",
            "from": 3869,
            "to": 3818
          },
          {
            "id": 3384,
            "label": "参与",
            "from": 3869,
            "to": 3812
          },
          {
            "id": 3342,
            "label": "参与",
            "from": 3869,
            "to": 3804
          },
          {
            "id": 3329,
            "label": "仆人",
            "from": 3869,
            "to": 4116
          },
          {
            "id": 3321,
            "label": "仆人",
            "from": 3869,
            "to": 4096
          },
          {
            "id": 3272,
            "label": "奶妈",
            "from": 3869,
            "to": 4142
          },
          {
            "id": 3257,
            "label": "哥哥",
            "from": 3869,
            "to": 3868
          },
          {
            "id": 3228,
            "label": "原籍",
            "from": 3869,
            "to": 3855
          },
          {
            "id": 3193,
            "label": "居住地",
            "from": 3869,
            "to": 3848
          },
          {
            "id": 3183,
            "label": "姬妾",
            "from": 3869,
            "to": 4143
          },
          {
            "id": 3177,
            "label": "姬妾",
            "from": 3869,
            "to": 4047
          },
          {
            "id": 3145,
            "label": "妻子",
            "from": 3869,
            "to": 4051
          },
          {
            "id": 3124,
            "label": "母亲",
            "from": 3869,
            "to": 4049
          },
          {
            "id": 3096,
            "label": "父亲",
            "from": 3869,
            "to": 3864
          },
          {
            "id": 3385,
            "label": "参与",
            "from": 3870,
            "to": 3793
          },
          {
            "id": 3343,
            "label": "参与",
            "from": 3870,
            "to": 3788
          },
          {
            "id": 3279,
            "label": "交好",
            "from": 3870,
            "to": 3874
          },
          {
            "id": 3194,
            "label": "居住地",
            "from": 3870,
            "to": 3849
          },
          {
            "id": 3146,
            "label": "妻子",
            "from": 3870,
            "to": 4053
          },
          {
            "id": 3097,
            "label": "父亲",
            "from": 3870,
            "to": 3873
          },
          {
            "id": 3344,
            "label": "参与",
            "from": 3871,
            "to": 3793
          },
          {
            "id": 3296,
            "label": "同宗",
            "from": 3871,
            "to": 3870
          },
          {
            "id": 3280,
            "label": "交好",
            "from": 3871,
            "to": 3881
          },
          {
            "id": 3274,
            "label": "私通",
            "from": 3871,
            "to": 4140
          },
          {
            "id": 3263,
            "label": "老师",
            "from": 3872,
            "to": 3910
          },
          {
            "id": 3195,
            "label": "居住地",
            "from": 3872,
            "to": 3848
          },
          {
            "id": 3125,
            "label": "母亲",
            "from": 3872,
            "to": 4043
          },
          {
            "id": 3098,
            "label": "父亲",
            "from": 3872,
            "to": 3876
          },
          {
            "id": 3398,
            "label": "参与",
            "from": 3873,
            "to": 3780
          },
          {
            "id": 3386,
            "label": "参与",
            "from": 3873,
            "to": 3822
          },
          {
            "id": 3345,
            "label": "参与",
            "from": 3873,
            "to": 3819
          },
          {
            "id": 3336,
            "label": "仆人",
            "from": 3873,
            "to": 3959
          },
          {
            "id": 3330,
            "label": "仆人",
            "from": 3873,
            "to": 3932
          },
          {
            "id": 3322,
            "label": "仆人",
            "from": 3873,
            "to": 3931
          },
          {
            "id": 3317,
            "label": "跟班",
            "from": 3873,
            "to": 3957
          },
          {
            "id": 3313,
            "label": "跟班",
            "from": 3873,
            "to": 3956
          },
          {
            "id": 3297,
            "label": "同宗",
            "from": 3873,
            "to": 3878
          },
          {
            "id": 3275,
            "label": "私通",
            "from": 3873,
            "to": 4053
          },
          {
            "id": 3229,
            "label": "原籍",
            "from": 3873,
            "to": 3855
          },
          {
            "id": 3196,
            "label": "居住地",
            "from": 3873,
            "to": 3849
          },
          {
            "id": 3178,
            "label": "姬妾",
            "from": 3873,
            "to": 4161
          },
          {
            "id": 3147,
            "label": "妻子",
            "from": 3873,
            "to": 4079
          },
          {
            "id": 3099,
            "label": "父亲",
            "from": 3873,
            "to": 3867
          },
          {
            "id": 3399,
            "label": "参与",
            "from": 3874,
            "to": 3789
          },
          {
            "id": 3387,
            "label": "参与",
            "from": 3874,
            "to": 3826
          },
          {
            "id": 3346,
            "label": "参与",
            "from": 3874,
            "to": 3788
          },
          {
            "id": 3331,
            "label": "仆人",
            "from": 3874,
            "to": 3967
          },
          {
            "id": 3323,
            "label": "仆人",
            "from": 3874,
            "to": 4102
          },
          {
            "id": 3318,
            "label": "跟班",
            "from": 3874,
            "to": 3937
          },
          {
            "id": 3314,
            "label": "跟班",
            "from": 3874,
            "to": 3938
          },
          {
            "id": 3298,
            "label": "同宗",
            "from": 3874,
            "to": 3878
          },
          {
            "id": 3276,
            "label": "私通",
            "from": 3874,
            "to": 4122
          },
          {
            "id": 3273,
            "label": "奶妈",
            "from": 3874,
            "to": 4156
          },
          {
            "id": 3230,
            "label": "原籍",
            "from": 3874,
            "to": 3855
          },
          {
            "id": 3197,
            "label": "居住地",
            "from": 3874,
            "to": 3848
          },
          {
            "id": 3185,
            "label": "姬妾",
            "from": 3874,
            "to": 4115
          },
          {
            "id": 3184,
            "label": "姬妾",
            "from": 3874,
            "to": 4080
          },
          {
            "id": 3179,
            "label": "姬妾",
            "from": 3874,
            "to": 4056
          },
          {
            "id": 3148,
            "label": "妻子",
            "from": 3874,
            "to": 4041
          },
          {
            "id": 3100,
            "label": "父亲",
            "from": 3874,
            "to": 3868
          },
          {
            "id": 3299,
            "label": "同宗",
            "from": 3875,
            "to": 3878
          },
          {
            "id": 3198,
            "label": "居住地",
            "from": 3875,
            "to": 3848
          },
          {
            "id": 3126,
            "label": "母亲",
            "from": 3875,
            "to": 4046
          },
          {
            "id": 3101,
            "label": "父亲",
            "from": 3875,
            "to": 3868
          },
          {
            "id": 3332,
            "label": "仆人",
            "from": 3876,
            "to": 4072
          },
          {
            "id": 3324,
            "label": "仆人",
            "from": 3876,
            "to": 4103
          },
          {
            "id": 3300,
            "label": "同宗",
            "from": 3876,
            "to": 3878
          },
          {
            "id": 3231,
            "label": "原籍",
            "from": 3876,
            "to": 3855
          },
          {
            "id": 3199,
            "label": "居住地",
            "from": 3876,
            "to": 3848
          },
          {
            "id": 3149,
            "label": "妻子",
            "from": 3876,
            "to": 4043
          },
          {
            "id": 3127,
            "label": "母亲",
            "from": 3876,
            "to": 4051
          },
          {
            "id": 3102,
            "label": "父亲",
            "from": 3876,
            "to": 3869
          },
          {
            "id": 3347,
            "label": "参与",
            "from": 3877,
            "to": 3804
          },
          {
            "id": 3315,
            "label": "跟班",
            "from": 3877,
            "to": 3958
          },
          {
            "id": 3301,
            "label": "同宗",
            "from": 3877,
            "to": 3878
          },
          {
            "id": 3258,
            "label": "哥哥",
            "from": 3877,
            "to": 3874
          },
          {
            "id": 3252,
            "label": "姐姐",
            "from": 3877,
            "to": 4045
          },
          {
            "id": 3200,
            "label": "居住地",
            "from": 3877,
            "to": 3848
          },
          {
            "id": 3128,
            "label": "母亲",
            "from": 3877,
            "to": 4047
          },
          {
            "id": 3103,
            "label": "父亲",
            "from": 3877,
            "to": 3869
          },
          {
            "id": 3150,
            "label": "妻子",
            "from": 3878,
            "to": 4136
          },
          {
            "id": 3325,
            "label": "仆人",
            "from": 3879,
            "to": 3897
          },
          {
            "id": 3232,
            "label": "原籍",
            "from": 3879,
            "to": 3855
          },
          {
            "id": 3201,
            "label": "居住地",
            "from": 3879,
            "to": 3849
          },
          {
            "id": 3259,
            "label": "哥哥",
            "from": 3880,
            "to": 3879
          },
          {
            "id": 3233,
            "label": "原籍",
            "from": 3880,
            "to": 3855
          },
          {
            "id": 3202,
            "label": "居住地",
            "from": 3880,
            "to": 3848
          },
          {
            "id": 3388,
            "label": "参与",
            "from": 3881,
            "to": 3790
          },
          {
            "id": 3348,
            "label": "参与",
            "from": 3881,
            "to": 3810
          },
          {
            "id": 3290,
            "label": "交好",
            "from": 3881,
            "to": 3922
          },
          {
            "id": 3264,
            "label": "老师",
            "from": 3881,
            "to": 3910
          },
          {
            "id": 3253,
            "label": "姐姐",
            "from": 3881,
            "to": 4053
          },
          {
            "id": 3104,
            "label": "父亲",
            "from": 3881,
            "to": 3898
          },
          {
            "id": 3234,
            "label": "原籍",
            "from": 3882,
            "to": 3855
          },
          {
            "id": 3105,
            "label": "父亲",
            "from": 3882,
            "to": 3883
          },
          {
            "id": 3235,
            "label": "原籍",
            "from": 3883,
            "to": 3855
          },
          {
            "id": 3106,
            "label": "父亲",
            "from": 3883,
            "to": 3899
          },
          {
            "id": 3349,
            "label": "参与",
            "from": 3884,
            "to": 3791
          },
          {
            "id": 3236,
            "label": "原籍",
            "from": 3884,
            "to": 3855
          },
          {
            "id": 3107,
            "label": "父亲",
            "from": 3884,
            "to": 3943
          },
          {
            "id": 3389,
            "label": "参与",
            "from": 3885,
            "to": 3795
          },
          {
            "id": 3350,
            "label": "参与",
            "from": 3885,
            "to": 3792
          },
          {
            "id": 3293,
            "label": "交好",
            "from": 3885,
            "to": 3890
          },
          {
            "id": 3291,
            "label": "交好",
            "from": 3885,
            "to": 3942
          },
          {
            "id": 3281,
            "label": "交好",
            "from": 3885,
            "to": 3925
          },
          {
            "id": 3277,
            "label": "私通",
            "from": 3885,
            "to": 4068
          },
          {
            "id": 3265,
            "label": "老师",
            "from": 3885,
            "to": 3910
          },
          {
            "id": 3237,
            "label": "原籍",
            "from": 3885,
            "to": 3855
          },
          {
            "id": 3180,
            "label": "姬妾",
            "from": 3885,
            "to": 4066
          },
          {
            "id": 3151,
            "label": "妻子",
            "from": 3885,
            "to": 4076
          },
          {
            "id": 3129,
            "label": "母亲",
            "from": 3885,
            "to": 4075
          },
          {
            "id": 3108,
            "label": "父亲",
            "from": 3885,
            "to": 3953
          },
          {
            "id": 3351,
            "label": "参与",
            "from": 3886,
            "to": 3811
          },
          {
            "id": 3260,
            "label": "哥哥",
            "from": 3886,
            "to": 3885
          },
          {
            "id": 3152,
            "label": "妻子",
            "from": 3886,
            "to": 4078
          },
          {
            "id": 3238,
            "label": "原籍",
            "from": 3887,
            "to": 3855
          },
          {
            "id": 3109,
            "label": "父亲",
            "from": 3887,
            "to": 3889
          },
          {
            "id": 3239,
            "label": "原籍",
            "from": 3888,
            "to": 3855
          },
          {
            "id": 3110,
            "label": "父亲",
            "from": 3888,
            "to": 3889
          },
          {
            "id": 3240,
            "label": "原籍",
            "from": 3889,
            "to": 3855
          },
          {
            "id": 3111,
            "label": "父亲",
            "from": 3889,
            "to": 3902
          },
          {
            "id": 3390,
            "label": "参与",
            "from": 3890,
            "to": 3795
          },
          {
            "id": 3352,
            "label": "参与",
            "from": 3890,
            "to": 3829
          },
          {
            "id": 3319,
            "label": "跟班",
            "from": 3890,
            "to": 3933
          },
          {
            "id": 3282,
            "label": "交好",
            "from": 3890,
            "to": 3881
          },
          {
            "id": 3391,
            "label": "参与",
            "from": 3891,
            "to": 3835
          },
          {
            "id": 3353,
            "label": "参与",
            "from": 3891,
            "to": 3792
          },
          {
            "id": 3309,
            "label": "连宗",
            "from": 3891,
            "to": 3869
          },
          {
            "id": 3283,
            "label": "交好",
            "from": 3891,
            "to": 3940
          },
          {
            "id": 3241,
            "label": "原籍",
            "from": 3891,
            "to": 3857
          },
          {
            "id": 3203,
            "label": "居住地",
            "from": 3891,
            "to": 3955
          },
          {
            "id": 3181,
            "label": "姬妾",
            "from": 3891,
            "to": 4117
          },
          {
            "id": 3392,
            "label": "参与",
            "from": 3892,
            "to": 3790
          },
          {
            "id": 3354,
            "label": "参与",
            "from": 3892,
            "to": 3793
          },
          {
            "id": 3204,
            "label": "居住地",
            "from": 3892,
            "to": 3856
          },
          {
            "id": 3112,
            "label": "父亲",
            "from": 3892,
            "to": 3900
          },
          {
            "id": 3393,
            "label": "参与",
            "from": 3893,
            "to": 3804
          },
          {
            "id": 3355,
            "label": "参与",
            "from": 3893,
            "to": 3794
          },
          {
            "id": 3284,
            "label": "交好",
            "from": 3893,
            "to": 3954
          },
          {
            "id": 3153,
            "label": "妻子",
            "from": 3893,
            "to": 4055
          },
          {
            "id": 3356,
            "label": "参与",
            "from": 3894,
            "to": 3796
          },
          {
            "id": 3302,
            "label": "同宗",
            "from": 3894,
            "to": 3863
          },
          {
            "id": 3285,
            "label": "交好",
            "from": 3894,
            "to": 3871
          },
          {
            "id": 3205,
            "label": "居住地",
            "from": 3894,
            "to": 3853
          },
          {
            "id": 3154,
            "label": "妻子",
            "from": 3894,
            "to": 4085
          },
          {
            "id": 3130,
            "label": "母亲",
            "from": 3894,
            "to": 4138
          },
          {
            "id": 3357,
            "label": "参与",
            "from": 3895,
            "to": 3796
          },
          {
            "id": 3206,
            "label": "居住地",
            "from": 3895,
            "to": 3856
          },
          {
            "id": 3358,
            "label": "参与",
            "from": 3896,
            "to": 3780
          },
          {
            "id": 3207,
            "label": "居住地",
            "from": 3896,
            "to": 3850
          },
          {
            "id": 3155,
            "label": "妻子",
            "from": 3896,
            "to": 4048
          },
          {
            "id": 3208,
            "label": "居住地",
            "from": 3897,
            "to": 3849
          },
          {
            "id": 3242,
            "label": "原籍",
            "from": 3899,
            "to": 3855
          },
          {
            "id": 3243,
            "label": "原籍",
            "from": 3900,
            "to": 3853
          },
          {
            "id": 3113,
            "label": "父亲",
            "from": 3900,
            "to": 3910
          },
          {
            "id": 3244,
            "label": "原籍",
            "from": 3902,
            "to": 3855
          },
          {
            "id": 3359,
            "label": "参与",
            "from": 3903,
            "to": 3824
          },
          {
            "id": 3333,
            "label": "仆人",
            "from": 3903,
            "to": 3970
          },
          {
            "id": 3326,
            "label": "仆人",
            "from": 3903,
            "to": 4117
          },
          {
            "id": 3245,
            "label": "原籍",
            "from": 3903,
            "to": 3850
          },
          {
            "id": 3209,
            "label": "居住地",
            "from": 3903,
            "to": 3862
          },
          {
            "id": 3156,
            "label": "妻子",
            "from": 3903,
            "to": 4141
          },
          {
            "id": 3360,
            "label": "参与",
            "from": 3905,
            "to": 3814
          },
          {
            "id": 3246,
            "label": "原籍",
            "from": 3905,
            "to": 3853
          },
          {
            "id": 3157,
            "label": "妻子",
            "from": 3905,
            "to": 4039
          },
          {
            "id": 3131,
            "label": "母亲",
            "from": 3905,
            "to": 4083
          },
          {
            "id": 3114,
            "label": "父亲",
            "from": 3905,
            "to": 3917
          },
          {
            "id": 3361,
            "label": "参与",
            "from": 3906,
            "to": 3820
          },
          {
            "id": 3247,
            "label": "原籍",
            "from": 3906,
            "to": 3861
          },
          {
            "id": 3210,
            "label": "居住地",
            "from": 3906,
            "to": 3856
          },
          {
            "id": 3158,
            "label": "妻子",
            "from": 3906,
            "to": 4042
          },
          {
            "id": 3132,
            "label": "母亲",
            "from": 3907,
            "to": 4121
          },
          {
            "id": 3133,
            "label": "母亲",
            "from": 3908,
            "to": 4156
          },
          {
            "id": 3134,
            "label": "母亲",
            "from": 3909,
            "to": 4156
          },
          {
            "id": 3303,
            "label": "同宗",
            "from": 3910,
            "to": 3869
          },
          {
            "id": 3248,
            "label": "原籍",
            "from": 3910,
            "to": 3853
          },
          {
            "id": 3159,
            "label": "妻子",
            "from": 3910,
            "to": 4162
          },
          {
            "id": 3160,
            "label": "妻子",
            "from": 3911,
            "to": 4123
          },
          {
            "id": 3135,
            "label": "母亲",
            "from": 3911,
            "to": 4142
          },
          {
            "id": 3161,
            "label": "妻子",
            "from": 3913,
            "to": 4122
          },
          {
            "id": 3310,
            "label": "连宗",
            "from": 3916,
            "to": 3883
          },
          {
            "id": 3115,
            "label": "父亲",
            "from": 3916,
            "to": 3915
          },
          {
            "id": 3162,
            "label": "妻子",
            "from": 3917,
            "to": 4083
          },
          {
            "id": 3116,
            "label": "父亲",
            "from": 3917,
            "to": 3916
          },
          {
            "id": 3311,
            "label": "有恩",
            "from": 3918,
            "to": 3917
          },
          {
            "id": 3163,
            "label": "妻子",
            "from": 3918,
            "to": 4131
          },
          {
            "id": 3164,
            "label": "妻子",
            "from": 3919,
            "to": 4132
          },
          {
            "id": 3165,
            "label": "妻子",
            "from": 3920,
            "to": 4086
          },
          {
            "id": 3362,
            "label": "参与",
            "from": 3921,
            "to": 3786
          },
          {
            "id": 3117,
            "label": "父亲",
            "from": 3921,
            "to": 3935
          },
          {
            "id": 3266,
            "label": "老师",
            "from": 3922,
            "to": 3910
          },
          {
            "id": 3363,
            "label": "参与",
            "from": 3923,
            "to": 3790
          },
          {
            "id": 3304,
            "label": "同宗",
            "from": 3923,
            "to": 3870
          },
          {
            "id": 3286,
            "label": "交好",
            "from": 3923,
            "to": 3924
          },
          {
            "id": 3267,
            "label": "老师",
            "from": 3923,
            "to": 3910
          },
          {
            "id": 3136,
            "label": "母亲",
            "from": 3923,
            "to": 4157
          },
          {
            "id": 3364,
            "label": "参与",
            "from": 3924,
            "to": 3790
          },
          {
            "id": 3305,
            "label": "同宗",
            "from": 3924,
            "to": 3870
          },
          {
            "id": 3268,
            "label": "老师",
            "from": 3924,
            "to": 3910
          },
          {
            "id": 3365,
            "label": "参与",
            "from": 3925,
            "to": 3790
          },
          {
            "id": 3269,
            "label": "老师",
            "from": 3925,
            "to": 3910
          },
          {
            "id": 3366,
            "label": "参与",
            "from": 3926,
            "to": 3790
          },
          {
            "id": 3270,
            "label": "老师",
            "from": 3926,
            "to": 3910
          },
          {
            "id": 3137,
            "label": "母亲",
            "from": 3926,
            "to": 4158
          },
          {
            "id": 3367,
            "label": "参与",
            "from": 3927,
            "to": 3801
          },
          {
            "id": 3307,
            "label": "同宗",
            "from": 3929,
            "to": 3874
          },
          {
            "id": 3139,
            "label": "母亲",
            "from": 3929,
            "to": 4160
          },
          {
            "id": 3308,
            "label": "同宗",
            "from": 3930,
            "to": 3863
          },
          {
            "id": 3166,
            "label": "妻子",
            "from": 3931,
            "to": 4139
          },
          {
            "id": 3334,
            "label": "仆人",
            "from": 3932,
            "to": 3938
          },
          {
            "id": 3327,
            "label": "仆人",
            "from": 3932,
            "to": 4149
          },
          {
            "id": 3368,
            "label": "参与",
            "from": 3934,
            "to": 3812
          },
          {
            "id": 3287,
            "label": "交好",
            "from": 3934,
            "to": 3890
          },
          {
            "id": 3118,
            "label": "父亲",
            "from": 3934,
            "to": 3911
          },
          {
            "id": 3254,
            "label": "姐姐",
            "from": 3936,
            "to": 4046
          },
          {
            "id": 3167,
            "label": "妻子",
            "from": 3939,
            "to": 4125
          },
          {
            "id": 3369,
            "label": "参与",
            "from": 3940,
            "to": 3835
          },
          {
            "id": 3211,
            "label": "居住地",
            "from": 3940,
            "to": 3856
          },
          {
            "id": 3168,
            "label": "妻子",
            "from": 3940,
            "to": 4172
          },
          {
            "id": 3119,
            "label": "父亲",
            "from": 3943,
            "to": 3883
          },
          {
            "id": 3120,
            "label": "父亲",
            "from": 3944,
            "to": 3889
          },
          {
            "id": 3169,
            "label": "妻子",
            "from": 3945,
            "to": 4052
          },
          {
            "id": 3170,
            "label": "妻子",
            "from": 3946,
            "to": 4077
          },
          {
            "id": 3171,
            "label": "妻子",
            "from": 3947,
            "to": 4124
          },
          {
            "id": 3370,
            "label": "参与",
            "from": 3948,
            "to": 3788
          },
          {
            "id": 3172,
            "label": "妻子",
            "from": 3948,
            "to": 4119
          },
          {
            "id": 3212,
            "label": "居住地",
            "from": 3949,
            "to": 3850
          },
          {
            "id": 3173,
            "label": "妻子",
            "from": 3950,
            "to": 4129
          },
          {
            "id": 3371,
            "label": "参与",
            "from": 3951,
            "to": 3792
          },
          {
            "id": 3249,
            "label": "原籍",
            "from": 3951,
            "to": 3850
          },
          {
            "id": 3213,
            "label": "居住地",
            "from": 3951,
            "to": 3858
          },
          {
            "id": 3140,
            "label": "母亲",
            "from": 3953,
            "to": 4075
          },
          {
            "id": 3214,
            "label": "居住地",
            "from": 3954,
            "to": 3856
          },
          {
            "id": 3790,
            "label": "位于",
            "from": 3955,
            "to": 3855
          },
          {
            "id": 3215,
            "label": "居住地",
            "from": 3960,
            "to": 3859
          },
          {
            "id": 3174,
            "label": "妻子",
            "from": 3960,
            "to": 4169
          },
          {
            "id": 3372,
            "label": "参与",
            "from": 3961,
            "to": 3792
          },
          {
            "id": 3216,
            "label": "居住地",
            "from": 3961,
            "to": 3855
          },
          {
            "id": 3217,
            "label": "居住地",
            "from": 3962,
            "to": 3855
          },
          {
            "id": 3373,
            "label": "参与",
            "from": 3963,
            "to": 3818
          },
          {
            "id": 3374,
            "label": "参与",
            "from": 3964,
            "to": 3818
          },
          {
            "id": 3375,
            "label": "参与",
            "from": 3965,
            "to": 3818
          },
          {
            "id": 3376,
            "label": "参与",
            "from": 3967,
            "to": 3780
          },
          {
            "id": 3394,
            "label": "参与",
            "from": 3968,
            "to": 3783
          },
          {
            "id": 3377,
            "label": "参与",
            "from": 3968,
            "to": 3824
          },
          {
            "id": 3288,
            "label": "交好",
            "from": 3968,
            "to": 3969
          },
          {
            "id": 3400,
            "label": "参与",
            "from": 3969,
            "to": 3783
          },
          {
            "id": 3395,
            "label": "参与",
            "from": 3969,
            "to": 3793
          },
          {
            "id": 3378,
            "label": "参与",
            "from": 3969,
            "to": 3824
          },
          {
            "id": 3379,
            "label": "参与",
            "from": 3970,
            "to": 3834
          },
          {
            "id": 3218,
            "label": "居住地",
            "from": 3970,
            "to": 3862
          },
          {
            "id": 3380,
            "label": "参与",
            "from": 3971,
            "to": 3808
          },
          {
            "id": 3175,
            "label": "妻子",
            "from": 3971,
            "to": 4113
          },
          {
            "id": 3141,
            "label": "母亲",
            "from": 3971,
            "to": 4119
          },
          {
            "id": 3121,
            "label": "父亲",
            "from": 3971,
            "to": 3948
          },
          {
            "id": 3381,
            "label": "参与",
            "from": 3972,
            "to": 3836
          },
          {
            "id": 3770,
            "label": "参与",
            "from": 4037,
            "to": 3831
          },
          {
            "id": 3767,
            "label": "参与",
            "from": 4037,
            "to": 3780
          },
          {
            "id": 3764,
            "label": "参与",
            "from": 4037,
            "to": 3815
          },
          {
            "id": 3758,
            "label": "参与",
            "from": 4037,
            "to": 3806
          },
          {
            "id": 3747,
            "label": "参与",
            "from": 4037,
            "to": 3781
          },
          {
            "id": 3723,
            "label": "参与",
            "from": 4037,
            "to": 3832
          },
          {
            "id": 3668,
            "label": "参与",
            "from": 4037,
            "to": 3779
          },
          {
            "id": 3658,
            "label": "仆人",
            "from": 4037,
            "to": 4088
          },
          {
            "id": 3645,
            "label": "仆人",
            "from": 4037,
            "to": 4070
          },
          {
            "id": 3628,
            "label": "仆人",
            "from": 4037,
            "to": 4069
          },
          {
            "id": 3605,
            "label": "仆人",
            "from": 4037,
            "to": 4058
          },
          {
            "id": 3590,
            "label": "喜欢",
            "from": 4037,
            "to": 3863
          },
          {
            "id": 3588,
            "label": "奶妈",
            "from": 4037,
            "to": 4120
          },
          {
            "id": 3587,
            "label": "老师",
            "from": 4037,
            "to": 3891
          },
          {
            "id": 3576,
            "label": "知己",
            "from": 4037,
            "to": 4058
          },
          {
            "id": 3542,
            "label": "前世",
            "from": 4037,
            "to": 4173
          },
          {
            "id": 3528,
            "label": "原籍",
            "from": 4037,
            "to": 3854
          },
          {
            "id": 3499,
            "label": "居住地",
            "from": 4037,
            "to": 3838
          },
          {
            "id": 3441,
            "label": "母亲",
            "from": 4037,
            "to": 4048
          },
          {
            "id": 3419,
            "label": "父亲",
            "from": 4037,
            "to": 3896
          },
          {
            "id": 3771,
            "label": "参与",
            "from": 4038,
            "to": 3837
          },
          {
            "id": 3768,
            "label": "参与",
            "from": 4038,
            "to": 3821
          },
          {
            "id": 3765,
            "label": "参与",
            "from": 4038,
            "to": 3816
          },
          {
            "id": 3759,
            "label": "参与",
            "from": 4038,
            "to": 3802
          },
          {
            "id": 3748,
            "label": "参与",
            "from": 4038,
            "to": 3781
          },
          {
            "id": 3724,
            "label": "参与",
            "from": 4038,
            "to": 3806
          },
          {
            "id": 3669,
            "label": "参与",
            "from": 4038,
            "to": 3784
          },
          {
            "id": 3646,
            "label": "仆人",
            "from": 4038,
            "to": 4089
          },
          {
            "id": 3629,
            "label": "仆人",
            "from": 4038,
            "to": 4071
          },
          {
            "id": 3606,
            "label": "仆人",
            "from": 4038,
            "to": 4059
          },
          {
            "id": 3591,
            "label": "喜欢",
            "from": 4038,
            "to": 3863
          },
          {
            "id": 3551,
            "label": "哥哥",
            "from": 4038,
            "to": 3885
          },
          {
            "id": 3529,
            "label": "原籍",
            "from": 4038,
            "to": 3855
          },
          {
            "id": 3500,
            "label": "居住地",
            "from": 4038,
            "to": 3839
          },
          {
            "id": 3442,
            "label": "母亲",
            "from": 4038,
            "to": 4075
          },
          {
            "id": 3420,
            "label": "父亲",
            "from": 4038,
            "to": 3901
          },
          {
            "id": 3670,
            "label": "参与",
            "from": 4039,
            "to": 3791
          },
          {
            "id": 3530,
            "label": "原籍",
            "from": 4039,
            "to": 3856
          },
          {
            "id": 3501,
            "label": "居住地",
            "from": 4039,
            "to": 3848
          },
          {
            "id": 3461,
            "label": "丈夫",
            "from": 4039,
            "to": 3905
          },
          {
            "id": 3443,
            "label": "母亲",
            "from": 4039,
            "to": 4041
          },
          {
            "id": 3421,
            "label": "父亲",
            "from": 4039,
            "to": 3874
          },
          {
            "id": 3671,
            "label": "参与",
            "from": 4040,
            "to": 3781
          },
          {
            "id": 3647,
            "label": "仆人",
            "from": 4040,
            "to": 4092
          },
          {
            "id": 3630,
            "label": "仆人",
            "from": 4040,
            "to": 4091
          },
          {
            "id": 3607,
            "label": "仆人",
            "from": 4040,
            "to": 4090
          },
          {
            "id": 3552,
            "label": "哥哥",
            "from": 4040,
            "to": 3873
          },
          {
            "id": 3531,
            "label": "原籍",
            "from": 4040,
            "to": 3856
          },
          {
            "id": 3502,
            "label": "居住地",
            "from": 4040,
            "to": 3840
          },
          {
            "id": 3422,
            "label": "父亲",
            "from": 4040,
            "to": 3867
          },
          {
            "id": 3775,
            "label": "参与",
            "from": 4041,
            "to": 3830
          },
          {
            "id": 3774,
            "label": "参与",
            "from": 4041,
            "to": 3786
          },
          {
            "id": 3773,
            "label": "参与",
            "from": 4041,
            "to": 3826
          },
          {
            "id": 3772,
            "label": "参与",
            "from": 4041,
            "to": 3822
          },
          {
            "id": 3769,
            "label": "参与",
            "from": 4041,
            "to": 3814
          },
          {
            "id": 3766,
            "label": "参与",
            "from": 4041,
            "to": 3809
          },
          {
            "id": 3760,
            "label": "参与",
            "from": 4041,
            "to": 3807
          },
          {
            "id": 3749,
            "label": "参与",
            "from": 4041,
            "to": 3783
          },
          {
            "id": 3725,
            "label": "参与",
            "from": 4041,
            "to": 3798
          },
          {
            "id": 3672,
            "label": "参与",
            "from": 4041,
            "to": 3793
          },
          {
            "id": 3665,
            "label": "陪房",
            "from": 4041,
            "to": 4119
          },
          {
            "id": 3663,
            "label": "仆人",
            "from": 4041,
            "to": 3948
          },
          {
            "id": 3659,
            "label": "仆人",
            "from": 4041,
            "to": 3938
          },
          {
            "id": 3648,
            "label": "仆人",
            "from": 4041,
            "to": 4093
          },
          {
            "id": 3631,
            "label": "仆人",
            "from": 4041,
            "to": 4085
          },
          {
            "id": 3608,
            "label": "仆人",
            "from": 4041,
            "to": 4056
          },
          {
            "id": 3579,
            "label": "私通",
            "from": 4041,
            "to": 3870
          },
          {
            "id": 3553,
            "label": "哥哥",
            "from": 4041,
            "to": 3884
          },
          {
            "id": 3532,
            "label": "原籍",
            "from": 4041,
            "to": 3855
          },
          {
            "id": 3503,
            "label": "居住地",
            "from": 4041,
            "to": 3848
          },
          {
            "id": 3462,
            "label": "丈夫",
            "from": 4041,
            "to": 3874
          },
          {
            "id": 3423,
            "label": "父亲",
            "from": 4041,
            "to": 3943
          },
          {
            "id": 3726,
            "label": "参与",
            "from": 4042,
            "to": 3820
          },
          {
            "id": 3673,
            "label": "参与",
            "from": 4042,
            "to": 3781
          },
          {
            "id": 3649,
            "label": "仆人",
            "from": 4042,
            "to": 4135
          },
          {
            "id": 3632,
            "label": "仆人",
            "from": 4042,
            "to": 4094
          },
          {
            "id": 3609,
            "label": "仆人",
            "from": 4042,
            "to": 4063
          },
          {
            "id": 3554,
            "label": "哥哥",
            "from": 4042,
            "to": 3874
          },
          {
            "id": 3504,
            "label": "居住地",
            "from": 4042,
            "to": 3841
          },
          {
            "id": 3463,
            "label": "丈夫",
            "from": 4042,
            "to": 3906
          },
          {
            "id": 3424,
            "label": "父亲",
            "from": 4042,
            "to": 3868
          },
          {
            "id": 3727,
            "label": "参与",
            "from": 4043,
            "to": 3827
          },
          {
            "id": 3674,
            "label": "参与",
            "from": 4043,
            "to": 3781
          },
          {
            "id": 3650,
            "label": "仆人",
            "from": 4043,
            "to": 4110
          },
          {
            "id": 3633,
            "label": "仆人",
            "from": 4043,
            "to": 4103
          },
          {
            "id": 3610,
            "label": "仆人",
            "from": 4043,
            "to": 4072
          },
          {
            "id": 3505,
            "label": "居住地",
            "from": 4043,
            "to": 3842
          },
          {
            "id": 3464,
            "label": "丈夫",
            "from": 4043,
            "to": 3876
          },
          {
            "id": 3425,
            "label": "父亲",
            "from": 4043,
            "to": 3962
          },
          {
            "id": 3675,
            "label": "参与",
            "from": 4044,
            "to": 3823
          },
          {
            "id": 3611,
            "label": "仆人",
            "from": 4044,
            "to": 4067
          },
          {
            "id": 3555,
            "label": "哥哥",
            "from": 4044,
            "to": 3876
          },
          {
            "id": 3506,
            "label": "居住地",
            "from": 4044,
            "to": 3843
          },
          {
            "id": 3465,
            "label": "丈夫",
            "from": 4044,
            "to": 3952
          },
          {
            "id": 3444,
            "label": "母亲",
            "from": 4044,
            "to": 4051
          },
          {
            "id": 3426,
            "label": "父亲",
            "from": 4044,
            "to": 3869
          },
          {
            "id": 3761,
            "label": "参与",
            "from": 4045,
            "to": 3830
          },
          {
            "id": 3750,
            "label": "参与",
            "from": 4045,
            "to": 3827
          },
          {
            "id": 3728,
            "label": "参与",
            "from": 4045,
            "to": 3813
          },
          {
            "id": 3676,
            "label": "参与",
            "from": 4045,
            "to": 3781
          },
          {
            "id": 3651,
            "label": "仆人",
            "from": 4045,
            "to": 4111
          },
          {
            "id": 3634,
            "label": "仆人",
            "from": 4045,
            "to": 4074
          },
          {
            "id": 3612,
            "label": "仆人",
            "from": 4045,
            "to": 4095
          },
          {
            "id": 3572,
            "label": "干娘",
            "from": 4045,
            "to": 4169
          },
          {
            "id": 3556,
            "label": "哥哥",
            "from": 4045,
            "to": 3863
          },
          {
            "id": 3543,
            "label": "姐姐",
            "from": 4045,
            "to": 4044
          },
          {
            "id": 3507,
            "label": "居住地",
            "from": 4045,
            "to": 3844
          },
          {
            "id": 3445,
            "label": "母亲",
            "from": 4045,
            "to": 4047
          },
          {
            "id": 3427,
            "label": "父亲",
            "from": 4045,
            "to": 3869
          },
          {
            "id": 3677,
            "label": "参与",
            "from": 4046,
            "to": 3786
          },
          {
            "id": 3666,
            "label": "陪房",
            "from": 4046,
            "to": 4125
          },
          {
            "id": 3652,
            "label": "仆人",
            "from": 4046,
            "to": 4171
          },
          {
            "id": 3635,
            "label": "仆人",
            "from": 4046,
            "to": 4114
          },
          {
            "id": 3613,
            "label": "仆人",
            "from": 4046,
            "to": 4115
          },
          {
            "id": 3557,
            "label": "哥哥",
            "from": 4046,
            "to": 3904
          },
          {
            "id": 3533,
            "label": "原籍",
            "from": 4046,
            "to": 3855
          },
          {
            "id": 3508,
            "label": "居住地",
            "from": 4046,
            "to": 3848
          },
          {
            "id": 3466,
            "label": "丈夫",
            "from": 4046,
            "to": 3868
          },
          {
            "id": 3729,
            "label": "参与",
            "from": 4047,
            "to": 3827
          },
          {
            "id": 3678,
            "label": "参与",
            "from": 4047,
            "to": 3783
          },
          {
            "id": 3636,
            "label": "仆人",
            "from": 4047,
            "to": 4104
          },
          {
            "id": 3614,
            "label": "仆人",
            "from": 4047,
            "to": 4096
          },
          {
            "id": 3558,
            "label": "哥哥",
            "from": 4047,
            "to": 3912
          },
          {
            "id": 3509,
            "label": "居住地",
            "from": 4047,
            "to": 3848
          },
          {
            "id": 3467,
            "label": "丈夫",
            "from": 4047,
            "to": 3869
          },
          {
            "id": 3615,
            "label": "仆人",
            "from": 4048,
            "to": 4120
          },
          {
            "id": 3570,
            "label": "哥哥",
            "from": 4048,
            "to": 3868
          },
          {
            "id": 3559,
            "label": "哥哥",
            "from": 4048,
            "to": 3869
          },
          {
            "id": 3534,
            "label": "原籍",
            "from": 4048,
            "to": 3855
          },
          {
            "id": 3510,
            "label": "居住地",
            "from": 4048,
            "to": 3850
          },
          {
            "id": 3468,
            "label": "丈夫",
            "from": 4048,
            "to": 3896
          },
          {
            "id": 3446,
            "label": "母亲",
            "from": 4048,
            "to": 4049
          },
          {
            "id": 3428,
            "label": "父亲",
            "from": 4048,
            "to": 3864
          },
          {
            "id": 3679,
            "label": "参与",
            "from": 4049,
            "to": 3786
          },
          {
            "id": 3660,
            "label": "仆人",
            "from": 4049,
            "to": 4058
          },
          {
            "id": 3653,
            "label": "仆人",
            "from": 4049,
            "to": 4112
          },
          {
            "id": 3637,
            "label": "仆人",
            "from": 4049,
            "to": 4073
          },
          {
            "id": 3616,
            "label": "仆人",
            "from": 4049,
            "to": 4057
          },
          {
            "id": 3560,
            "label": "哥哥",
            "from": 4049,
            "to": 3889
          },
          {
            "id": 3535,
            "label": "原籍",
            "from": 4049,
            "to": 3855
          },
          {
            "id": 3511,
            "label": "居住地",
            "from": 4049,
            "to": 3848
          },
          {
            "id": 3469,
            "label": "丈夫",
            "from": 4049,
            "to": 3864
          },
          {
            "id": 3429,
            "label": "父亲",
            "from": 4049,
            "to": 3902
          },
          {
            "id": 3592,
            "label": "喜欢",
            "from": 4050,
            "to": 3863
          },
          {
            "id": 3577,
            "label": "知己",
            "from": 4050,
            "to": 4078
          },
          {
            "id": 3512,
            "label": "居住地",
            "from": 4050,
            "to": 3846
          },
          {
            "id": 3751,
            "label": "参与",
            "from": 4051,
            "to": 3830
          },
          {
            "id": 3730,
            "label": "参与",
            "from": 4051,
            "to": 3817
          },
          {
            "id": 3680,
            "label": "参与",
            "from": 4051,
            "to": 3787
          },
          {
            "id": 3667,
            "label": "陪房",
            "from": 4051,
            "to": 4131
          },
          {
            "id": 3664,
            "label": "仆人",
            "from": 4051,
            "to": 4124
          },
          {
            "id": 3661,
            "label": "仆人",
            "from": 4051,
            "to": 4113
          },
          {
            "id": 3654,
            "label": "仆人",
            "from": 4051,
            "to": 4062
          },
          {
            "id": 3638,
            "label": "仆人",
            "from": 4051,
            "to": 4061
          },
          {
            "id": 3617,
            "label": "仆人",
            "from": 4051,
            "to": 4060
          },
          {
            "id": 3571,
            "label": "哥哥",
            "from": 4051,
            "to": 3943
          },
          {
            "id": 3561,
            "label": "哥哥",
            "from": 4051,
            "to": 3882
          },
          {
            "id": 3536,
            "label": "原籍",
            "from": 4051,
            "to": 3855
          },
          {
            "id": 3513,
            "label": "居住地",
            "from": 4051,
            "to": 3848
          },
          {
            "id": 3470,
            "label": "丈夫",
            "from": 4051,
            "to": 3869
          },
          {
            "id": 3430,
            "label": "父亲",
            "from": 4051,
            "to": 3883
          },
          {
            "id": 3752,
            "label": "参与",
            "from": 4052,
            "to": 3828
          },
          {
            "id": 3731,
            "label": "参与",
            "from": 4052,
            "to": 3785
          },
          {
            "id": 3681,
            "label": "参与",
            "from": 4052,
            "to": 3781
          },
          {
            "id": 3655,
            "label": "仆人",
            "from": 4052,
            "to": 4166
          },
          {
            "id": 3639,
            "label": "仆人",
            "from": 4052,
            "to": 4105
          },
          {
            "id": 3618,
            "label": "仆人",
            "from": 4052,
            "to": 4097
          },
          {
            "id": 3589,
            "label": "奶妈",
            "from": 4052,
            "to": 4153
          },
          {
            "id": 3578,
            "label": "知己",
            "from": 4052,
            "to": 4037
          },
          {
            "id": 3537,
            "label": "原籍",
            "from": 4052,
            "to": 3854
          },
          {
            "id": 3471,
            "label": "丈夫",
            "from": 4052,
            "to": 3945
          },
          {
            "id": 3431,
            "label": "父亲",
            "from": 4052,
            "to": 3944
          },
          {
            "id": 3753,
            "label": "参与",
            "from": 4053,
            "to": 3822
          },
          {
            "id": 3732,
            "label": "参与",
            "from": 4053,
            "to": 3819
          },
          {
            "id": 3682,
            "label": "参与",
            "from": 4053,
            "to": 3797
          },
          {
            "id": 3640,
            "label": "仆人",
            "from": 4053,
            "to": 4106
          },
          {
            "id": 3619,
            "label": "仆人",
            "from": 4053,
            "to": 4087
          },
          {
            "id": 3593,
            "label": "喜欢",
            "from": 4053,
            "to": 3863
          },
          {
            "id": 3580,
            "label": "私通",
            "from": 4053,
            "to": 3871
          },
          {
            "id": 3514,
            "label": "居住地",
            "from": 4053,
            "to": 3849
          },
          {
            "id": 3472,
            "label": "丈夫",
            "from": 4053,
            "to": 3870
          },
          {
            "id": 3432,
            "label": "父亲",
            "from": 4053,
            "to": 3898
          },
          {
            "id": 3762,
            "label": "参与",
            "from": 4054,
            "to": 3833
          },
          {
            "id": 3754,
            "label": "参与",
            "from": 4054,
            "to": 3830
          },
          {
            "id": 3733,
            "label": "参与",
            "from": 4054,
            "to": 3825
          },
          {
            "id": 3683,
            "label": "参与",
            "from": 4054,
            "to": 3787
          },
          {
            "id": 3599,
            "label": "交好",
            "from": 4054,
            "to": 4065
          },
          {
            "id": 3562,
            "label": "哥哥",
            "from": 4054,
            "to": 3913
          },
          {
            "id": 3515,
            "label": "居住地",
            "from": 4054,
            "to": 3845
          },
          {
            "id": 3734,
            "label": "参与",
            "from": 4055,
            "to": 3785
          },
          {
            "id": 3684,
            "label": "参与",
            "from": 4055,
            "to": 3837
          },
          {
            "id": 3600,
            "label": "交好",
            "from": 4055,
            "to": 4056
          },
          {
            "id": 3563,
            "label": "哥哥",
            "from": 4055,
            "to": 3914
          },
          {
            "id": 3516,
            "label": "居住地",
            "from": 4055,
            "to": 3845
          },
          {
            "id": 3473,
            "label": "丈夫",
            "from": 4055,
            "to": 3893
          },
          {
            "id": 3763,
            "label": "参与",
            "from": 4056,
            "to": 3826
          },
          {
            "id": 3755,
            "label": "参与",
            "from": 4056,
            "to": 3789
          },
          {
            "id": 3735,
            "label": "参与",
            "from": 4056,
            "to": 3800
          },
          {
            "id": 3685,
            "label": "参与",
            "from": 4056,
            "to": 3799
          },
          {
            "id": 3736,
            "label": "参与",
            "from": 4057,
            "to": 3801
          },
          {
            "id": 3686,
            "label": "参与",
            "from": 4057,
            "to": 3786
          },
          {
            "id": 3564,
            "label": "哥哥",
            "from": 4057,
            "to": 3921
          },
          {
            "id": 3433,
            "label": "父亲",
            "from": 4057,
            "to": 3935
          },
          {
            "id": 3737,
            "label": "参与",
            "from": 4058,
            "to": 3831
          },
          {
            "id": 3687,
            "label": "参与",
            "from": 4058,
            "to": 3782
          },
          {
            "id": 3517,
            "label": "居住地",
            "from": 4058,
            "to": 3838
          },
          {
            "id": 3688,
            "label": "参与",
            "from": 4059,
            "to": 3802
          },
          {
            "id": 3518,
            "label": "居住地",
            "from": 4059,
            "to": 3839
          },
          {
            "id": 3689,
            "label": "参与",
            "from": 4060,
            "to": 3803
          },
          {
            "id": 3447,
            "label": "母亲",
            "from": 4060,
            "to": 4151
          },
          {
            "id": 3738,
            "label": "参与",
            "from": 4061,
            "to": 3817
          },
          {
            "id": 3690,
            "label": "参与",
            "from": 4061,
            "to": 3804
          },
          {
            "id": 3448,
            "label": "母亲",
            "from": 4061,
            "to": 4151
          },
          {
            "id": 3594,
            "label": "喜欢",
            "from": 4062,
            "to": 3877
          },
          {
            "id": 3756,
            "label": "参与",
            "from": 4063,
            "to": 3830
          },
          {
            "id": 3739,
            "label": "参与",
            "from": 4063,
            "to": 3801
          },
          {
            "id": 3691,
            "label": "参与",
            "from": 4063,
            "to": 3805
          },
          {
            "id": 3581,
            "label": "私通",
            "from": 4063,
            "to": 3927
          },
          {
            "id": 3519,
            "label": "居住地",
            "from": 4063,
            "to": 3841
          },
          {
            "id": 3449,
            "label": "母亲",
            "from": 4063,
            "to": 4150
          },
          {
            "id": 3740,
            "label": "参与",
            "from": 4065,
            "to": 3799
          },
          {
            "id": 3692,
            "label": "参与",
            "from": 4065,
            "to": 3825
          },
          {
            "id": 3757,
            "label": "参与",
            "from": 4066,
            "to": 3834
          },
          {
            "id": 3741,
            "label": "参与",
            "from": 4066,
            "to": 3806
          },
          {
            "id": 3693,
            "label": "参与",
            "from": 4066,
            "to": 3792
          },
          {
            "id": 3620,
            "label": "仆人",
            "from": 4066,
            "to": 4098
          },
          {
            "id": 3538,
            "label": "原籍",
            "from": 4066,
            "to": 3850
          },
          {
            "id": 3474,
            "label": "丈夫",
            "from": 4066,
            "to": 3885
          },
          {
            "id": 3450,
            "label": "母亲",
            "from": 4066,
            "to": 4141
          },
          {
            "id": 3434,
            "label": "父亲",
            "from": 4066,
            "to": 3903
          },
          {
            "id": 3694,
            "label": "参与",
            "from": 4068,
            "to": 3811
          },
          {
            "id": 3582,
            "label": "私通",
            "from": 4068,
            "to": 3885
          },
          {
            "id": 3695,
            "label": "参与",
            "from": 4069,
            "to": 3831
          },
          {
            "id": 3520,
            "label": "居住地",
            "from": 4072,
            "to": 3842
          },
          {
            "id": 3641,
            "label": "仆人",
            "from": 4075,
            "to": 4107
          },
          {
            "id": 3621,
            "label": "仆人",
            "from": 4075,
            "to": 4084
          },
          {
            "id": 3565,
            "label": "哥哥",
            "from": 4075,
            "to": 3882
          },
          {
            "id": 3544,
            "label": "姐姐",
            "from": 4075,
            "to": 4051
          },
          {
            "id": 3539,
            "label": "原籍",
            "from": 4075,
            "to": 3856
          },
          {
            "id": 3521,
            "label": "居住地",
            "from": 4075,
            "to": 3847
          },
          {
            "id": 3435,
            "label": "父亲",
            "from": 4075,
            "to": 3883
          },
          {
            "id": 3696,
            "label": "参与",
            "from": 4076,
            "to": 3811
          },
          {
            "id": 3656,
            "label": "仆人",
            "from": 4076,
            "to": 4110
          },
          {
            "id": 3642,
            "label": "仆人",
            "from": 4076,
            "to": 4108
          },
          {
            "id": 3622,
            "label": "仆人",
            "from": 4076,
            "to": 4068
          },
          {
            "id": 3540,
            "label": "原籍",
            "from": 4076,
            "to": 3856
          },
          {
            "id": 3475,
            "label": "丈夫",
            "from": 4076,
            "to": 3885
          },
          {
            "id": 3643,
            "label": "仆人",
            "from": 4077,
            "to": 4154
          },
          {
            "id": 3623,
            "label": "仆人",
            "from": 4077,
            "to": 4099
          },
          {
            "id": 3573,
            "label": "干娘",
            "from": 4077,
            "to": 4051
          },
          {
            "id": 3566,
            "label": "哥哥",
            "from": 4077,
            "to": 3886
          },
          {
            "id": 3476,
            "label": "丈夫",
            "from": 4077,
            "to": 3946
          },
          {
            "id": 3624,
            "label": "仆人",
            "from": 4078,
            "to": 4100
          },
          {
            "id": 3541,
            "label": "原籍",
            "from": 4078,
            "to": 3855
          },
          {
            "id": 3477,
            "label": "丈夫",
            "from": 4078,
            "to": 3886
          },
          {
            "id": 3436,
            "label": "父亲",
            "from": 4078,
            "to": 3904
          },
          {
            "id": 3662,
            "label": "仆人",
            "from": 4079,
            "to": 4149
          },
          {
            "id": 3657,
            "label": "仆人",
            "from": 4079,
            "to": 4155
          },
          {
            "id": 3644,
            "label": "仆人",
            "from": 4079,
            "to": 4109
          },
          {
            "id": 3625,
            "label": "仆人",
            "from": 4079,
            "to": 4101
          },
          {
            "id": 3478,
            "label": "丈夫",
            "from": 4079,
            "to": 3873
          },
          {
            "id": 3451,
            "label": "母亲",
            "from": 4079,
            "to": 4152
          },
          {
            "id": 3742,
            "label": "参与",
            "from": 4080,
            "to": 3798
          },
          {
            "id": 3697,
            "label": "参与",
            "from": 4080,
            "to": 3788
          },
          {
            "id": 3626,
            "label": "仆人",
            "from": 4080,
            "to": 4102
          },
          {
            "id": 3545,
            "label": "姐姐",
            "from": 4080,
            "to": 4079
          },
          {
            "id": 3479,
            "label": "丈夫",
            "from": 4080,
            "to": 3874
          },
          {
            "id": 3698,
            "label": "参与",
            "from": 4081,
            "to": 3829
          },
          {
            "id": 3595,
            "label": "喜欢",
            "from": 4081,
            "to": 3890
          },
          {
            "id": 3583,
            "label": "私通",
            "from": 4081,
            "to": 3873
          },
          {
            "id": 3549,
            "label": "姐姐",
            "from": 4081,
            "to": 4079
          },
          {
            "id": 3546,
            "label": "姐姐",
            "from": 4081,
            "to": 4080
          },
          {
            "id": 3743,
            "label": "参与",
            "from": 4082,
            "to": 3814
          },
          {
            "id": 3699,
            "label": "参与",
            "from": 4082,
            "to": 3807
          },
          {
            "id": 3522,
            "label": "居住地",
            "from": 4082,
            "to": 3853
          },
          {
            "id": 3480,
            "label": "丈夫",
            "from": 4083,
            "to": 3917
          },
          {
            "id": 3452,
            "label": "母亲",
            "from": 4083,
            "to": 4082
          },
          {
            "id": 3700,
            "label": "参与",
            "from": 4085,
            "to": 3816
          },
          {
            "id": 3601,
            "label": "交好",
            "from": 4085,
            "to": 4146
          },
          {
            "id": 3481,
            "label": "丈夫",
            "from": 4085,
            "to": 3894
          },
          {
            "id": 3453,
            "label": "母亲",
            "from": 4085,
            "to": 4132
          },
          {
            "id": 3437,
            "label": "父亲",
            "from": 4085,
            "to": 3919
          },
          {
            "id": 3701,
            "label": "参与",
            "from": 4086,
            "to": 3826
          },
          {
            "id": 3584,
            "label": "私通",
            "from": 4086,
            "to": 3874
          },
          {
            "id": 3482,
            "label": "丈夫",
            "from": 4086,
            "to": 3920
          },
          {
            "id": 3702,
            "label": "参与",
            "from": 4087,
            "to": 3819
          },
          {
            "id": 3602,
            "label": "交好",
            "from": 4087,
            "to": 4106
          },
          {
            "id": 3596,
            "label": "喜欢",
            "from": 4088,
            "to": 4118
          },
          {
            "id": 3574,
            "label": "干娘",
            "from": 4088,
            "to": 4170
          },
          {
            "id": 3703,
            "label": "参与",
            "from": 4090,
            "to": 3830
          },
          {
            "id": 3523,
            "label": "居住地",
            "from": 4095,
            "to": 3844
          },
          {
            "id": 3704,
            "label": "参与",
            "from": 4113,
            "to": 3808
          },
          {
            "id": 3597,
            "label": "喜欢",
            "from": 4113,
            "to": 3877
          },
          {
            "id": 3483,
            "label": "丈夫",
            "from": 4113,
            "to": 3971
          },
          {
            "id": 3705,
            "label": "参与",
            "from": 4115,
            "to": 3798
          },
          {
            "id": 3484,
            "label": "丈夫",
            "from": 4117,
            "to": 3891
          },
          {
            "id": 3706,
            "label": "参与",
            "from": 4119,
            "to": 3808
          },
          {
            "id": 3485,
            "label": "丈夫",
            "from": 4119,
            "to": 3948
          },
          {
            "id": 3707,
            "label": "参与",
            "from": 4121,
            "to": 3787
          },
          {
            "id": 3708,
            "label": "参与",
            "from": 4122,
            "to": 3789
          },
          {
            "id": 3486,
            "label": "丈夫",
            "from": 4122,
            "to": 3913
          },
          {
            "id": 3627,
            "label": "仆人",
            "from": 4123,
            "to": 4054
          },
          {
            "id": 3487,
            "label": "丈夫",
            "from": 4123,
            "to": 3911
          },
          {
            "id": 3709,
            "label": "参与",
            "from": 4124,
            "to": 3827
          },
          {
            "id": 3488,
            "label": "丈夫",
            "from": 4124,
            "to": 3947
          },
          {
            "id": 3744,
            "label": "参与",
            "from": 4125,
            "to": 3830
          },
          {
            "id": 3710,
            "label": "参与",
            "from": 4125,
            "to": 3787
          },
          {
            "id": 3489,
            "label": "丈夫",
            "from": 4125,
            "to": 3939
          },
          {
            "id": 3711,
            "label": "参与",
            "from": 4126,
            "to": 3805
          },
          {
            "id": 3603,
            "label": "交好",
            "from": 4126,
            "to": 4064
          },
          {
            "id": 3547,
            "label": "姐姐",
            "from": 4127,
            "to": 4043
          },
          {
            "id": 3454,
            "label": "母亲",
            "from": 4127,
            "to": 4129
          },
          {
            "id": 3550,
            "label": "姐姐",
            "from": 4128,
            "to": 4127
          },
          {
            "id": 3548,
            "label": "姐姐",
            "from": 4128,
            "to": 4043
          },
          {
            "id": 3455,
            "label": "母亲",
            "from": 4128,
            "to": 4129
          },
          {
            "id": 3490,
            "label": "丈夫",
            "from": 4129,
            "to": 3950
          },
          {
            "id": 3567,
            "label": "哥哥",
            "from": 4130,
            "to": 3905
          },
          {
            "id": 3456,
            "label": "母亲",
            "from": 4130,
            "to": 4083
          },
          {
            "id": 3438,
            "label": "父亲",
            "from": 4130,
            "to": 3917
          },
          {
            "id": 3745,
            "label": "参与",
            "from": 4131,
            "to": 3830
          },
          {
            "id": 3712,
            "label": "参与",
            "from": 4131,
            "to": 3814
          },
          {
            "id": 3491,
            "label": "丈夫",
            "from": 4131,
            "to": 3918
          },
          {
            "id": 3575,
            "label": "干娘",
            "from": 4132,
            "to": 4041
          },
          {
            "id": 3492,
            "label": "丈夫",
            "from": 4132,
            "to": 3919
          },
          {
            "id": 3713,
            "label": "参与",
            "from": 4133,
            "to": 3800
          },
          {
            "id": 3457,
            "label": "母亲",
            "from": 4133,
            "to": 4126
          },
          {
            "id": 3714,
            "label": "参与",
            "from": 4134,
            "to": 3800
          },
          {
            "id": 3715,
            "label": "参与",
            "from": 4135,
            "to": 3805
          },
          {
            "id": 3493,
            "label": "丈夫",
            "from": 4136,
            "to": 3878
          },
          {
            "id": 3569,
            "label": "哥哥",
            "from": 4138,
            "to": 3941
          },
          {
            "id": 3494,
            "label": "丈夫",
            "from": 4139,
            "to": 3931
          },
          {
            "id": 3598,
            "label": "喜欢",
            "from": 4140,
            "to": 3871
          },
          {
            "id": 3524,
            "label": "居住地",
            "from": 4141,
            "to": 3850
          },
          {
            "id": 3495,
            "label": "丈夫",
            "from": 4141,
            "to": 3903
          },
          {
            "id": 3439,
            "label": "父亲",
            "from": 4141,
            "to": 3949
          },
          {
            "id": 3746,
            "label": "参与",
            "from": 4146,
            "to": 3816
          },
          {
            "id": 3716,
            "label": "参与",
            "from": 4146,
            "to": 3799
          },
          {
            "id": 3717,
            "label": "参与",
            "from": 4147,
            "to": 3810
          },
          {
            "id": 3585,
            "label": "私通",
            "from": 4147,
            "to": 3881
          },
          {
            "id": 3718,
            "label": "参与",
            "from": 4148,
            "to": 3809
          },
          {
            "id": 3586,
            "label": "私通",
            "from": 4149,
            "to": 3926
          },
          {
            "id": 3458,
            "label": "母亲",
            "from": 4150,
            "to": 4125
          },
          {
            "id": 3496,
            "label": "丈夫",
            "from": 4162,
            "to": 3910
          },
          {
            "id": 3459,
            "label": "母亲",
            "from": 4163,
            "to": 4164
          },
          {
            "id": 3719,
            "label": "参与",
            "from": 4167,
            "to": 3797
          },
          {
            "id": 3525,
            "label": "居住地",
            "from": 4167,
            "to": 3860
          },
          {
            "id": 3720,
            "label": "参与",
            "from": 4168,
            "to": 3783
          },
          {
            "id": 3604,
            "label": "交好",
            "from": 4168,
            "to": 4047
          },
          {
            "id": 3526,
            "label": "居住地",
            "from": 4168,
            "to": 3853
          },
          {
            "id": 3721,
            "label": "参与",
            "from": 4169,
            "to": 3813
          },
          {
            "id": 3527,
            "label": "居住地",
            "from": 4169,
            "to": 3859
          },
          {
            "id": 3497,
            "label": "丈夫",
            "from": 4169,
            "to": 3960
          },
          {
            "id": 3498,
            "label": "丈夫",
            "from": 4172,
            "to": 3940
          },
          {
            "id": 3460,
            "label": "母亲",
            "from": 4172,
            "to": 4131
          },
          {
            "id": 3440,
            "label": "父亲",
            "from": 4172,
            "to": 3918
          },
          {
            "id": 3722,
            "label": "参与",
            "from": 4173,
            "to": 3836
          }
        ]
      }
    }
    let gson2 = {
      "categories": {
        "person": "人物",
        "event": "事件",
        "location": "地点"
      },
      "data": {
        "nodes": [{
            "label": "共读西厢",
            "value": 2,
            "id": 3779,
            "categories": [
              "event"
            ],
            "info": "宝玉到沁芳桥边桃花底下看《西厢记》，正准备将落花送进池中，黛玉说她早已准备了一个花冢，正来葬花。黛玉发现《西厢记》，宝玉借书中词句，向黛玉表白。黛玉觉得冒犯了自己尊严，引起口角，宝玉赔礼讨饶，黛玉也借《西厢记》词句，嘲笑了宝玉。于是两人收拾落花，葬到花冢里去。"
          },
          {
            "label": "林如海捐馆扬州城",
            "value": 4,
            "id": 3780,
            "categories": [
              "event"
            ],
            "info": "林如海考中探花后，迁为兰台寺大夫，钦点为扬州巡盐御史。后身染重病于九月初三日巳时而亡。"
          },
          {
            "label": "海棠诗社",
            "value": 8,
            "id": 3781,
            "categories": [
              "event"
            ],
            "info": "初秋季节，贾探春提议邀集大观园中有文采的人所组的诗社。诗社成立目的 旨在“宴集诗人於风庭月榭；醉飞吟盏於帘杏溪桃，作诗吟辞以显大观园众姊妹之文采不让桃李须眉。”诗社成员 有林黛玉、薛宝钗、史湘云、贾迎春、贾探春、贾惜春、贾宝玉及李纨。稻香老农(李纨)为社长，菱洲(迎春)、藕榭（惜春）为副社长，一人出题，一人监场。"
          },
          {
            "label": "紫鹃试玉",
            "value": 2,
            "id": 3782,
            "categories": [
              "event"
            ],
            "info": "紫鹃想试试宝玉对黛玉的情意，就单独骗他说自己要和黛玉回苏州，宝玉本来不信，但在紫鹃一番骗小孩的语言下，痴情迷糊的宝玉还是相信了。就这一骗弄得宝玉是发了疯一样，指着墙上画中的小船说那是来接林妹妹的船……后来弄得大家都慌了，最后在紫鹃诉说下才好起来。真是个可爱迷糊又痴情的人。"
          },
          {
            "label": "魇魔姊弟",
            "value": 6,
            "id": 3783,
            "categories": [
              "event"
            ],
            "info": "贾环用油灯烫了宝玉后，王夫人骂了赵姨娘没好好管教。赵姨娘受气后，正巧马道婆来和她闲聊。赵姨娘在闲聊过程中暴露出了对凤姐和宝玉的不满，马道婆于是献计用魇魔法暗里算计。赵姨娘就出钱央求马道婆实施了这一毒招。话分两头，宝玉挨烫了之后，林妹妹来看他，凤姐等也来凑趣。凤姐姐开玩笑要林妹妹做贾家的媳妇。后来众人要走，宝玉要林妹妹站住说句话，正想说体己话，林妹妹羞得要走，就在此时马道婆作法生效了，宝玉闹头痛，跟着凤姐也发了狂。二人请医延药，眼见不济事，来了癞头和尚和跛足道人，用宝玉佩戴的宝玉救了二人。"
          },
          {
            "label": "羞笼红麝串",
            "value": 2,
            "id": 3784,
            "categories": [
              "event"
            ],
            "info": "然后是元春从宫中赏出很多东西，每个人都有，但是宝玉和宝钗的是一样的，黛玉的和三姐妹的是一样的，宝玉很是纳闷，看见宝钗便要看那红麝串，宝钗生的珠圆玉润，串子不容易退下来，宝玉看宝钗的臂膀产生的种种幻想，被宝钗看出，于是宝钗害羞的将串子抛给宝玉，这场景恰巧被黛玉看到。"
          },
          {
            "label": "麒麟伏双星",
            "value": 3,
            "id": 3785,
            "categories": [
              "event"
            ],
            "info": "可能暗示某两人白头偕老，或者是某两个人老了以后双双遇到，结合到一起。其中女方应该是史湘云（她也有一个金麒麟），但男方是谁，有点说不准，可能是卫若兰，可能是贾宝玉，也有可能是别的什么人。"
          },
          {
            "label": "纳鸳鸯",
            "value": 6,
            "id": 3786,
            "categories": [
              "event"
            ],
            "info": "鸳鸯是贾母的贴身丫环，除了负责贾母的日常起居饮食之外，还掌握着贾母钱箱的钥匙，对贾母的钱财了如指掌。可以说谁娶了鸳鸯，谁就等于掌握了贾母的私房钱，可惜，知子莫若母，贾赦的计谋被贾母识穿，最终未能得逞。"
          },
          {
            "label": "撵晴雯",
            "value": 4,
            "id": 3787,
            "categories": [
              "event"
            ],
            "info": "晴雯长得风流灵巧，眉眼儿有点像林黛玉，口齿伶俐，针线活尤好，曾为宝玉病补雀金裘。 她本是赖妈妈家的丫鬟，因得贾母喜爱，固把她赐给宝玉。因娇生惯养，养成得理不饶人的性格。一次王夫人进园子看见她打骂小丫鬟，觉得她不合世家教养，再加上王善保家的等小人进谗，王夫人在她病得“四五日水米不曾沾牙”的情况下，从炕上拉下来，硬给撵了出去（放出去）。宝玉偷偷前去探望，晴雯深为感动。当夜，晴雯悲惨地死去。听小丫头说晴雯死后做了芙蓉花神，宝玉便作了篇长长的《芙蓉女儿诔》寄托哀思。"
          },
          {
            "label": "偷娶尤二姐",
            "value": 4,
            "id": 3788,
            "categories": [
              "event"
            ],
            "info": "尤二姐模样标致，温柔和顺。贾珍馋涎妻妹的美貌，对尤二姐无微不至，当他玩腻后，就把她让给了贾琏。贾琏因惧怕王熙凤的淫威，只得偷偷娶尤二姐为二房，并把她安置在荣国府外，但不久被王熙凤发现，在她的借刀杀人计谋下，尤二姐备受折磨，当胎儿被庸医打下后，她绝望地吞金自尽。"
          },
          {
            "label": "软语救贾琏",
            "value": 3,
            "id": 3789,
            "categories": [
              "event"
            ],
            "info": "巧姐儿长水痘，贾府供奉天花娘娘，贾琏与多浑虫之妻相好，可是12天后巧姐儿病好了，贾琏搬回去住，凤姐命令平儿仔细搜查贾琏的行李，平儿搜到一缕青丝，却对凤姐说什么都没有。"
          },
          {
            "label": "大闹学堂",
            "value": 7,
            "id": 3790,
            "categories": [
              "event"
            ],
            "info": "宝玉准备入塾，袭人为他收拾妥当，叮嘱宝玉功课不必过于操劳。宝玉向家中长辈辞行之后，又独去向黛玉作辞，不辞宝钗。上学期间，宝玉与秦钟因平日就玩的很好且宝玉认为他们都是一类人就形影不离，引发不少风言风语。秦钟又因与“香怜”交好而引发贾家远亲金荣（贾璜之妻的侄儿）争风吃醋，适逢代儒外出，其孙儿贾瑞因私心处理不公，引起纠纷进而引发学堂内的一场大混战，最后以金荣被迫磕头道歉而告一段落。"
          },
          {
            "label": "拐卖巧姐",
            "value": 2,
            "id": 3791,
            "categories": [
              "event"
            ],
            "info": " 王仁要送巧姐，正为此烦恼，臭味相投，找贾芹喝酒，席间大骂贾府无情无义，贾琏不是东西，妹妹不争气，给王家抹黑，让自己蒙羞。贾芹见时机成熟，说动王仁，这样的动乱时机，谁会顾及一个小女孩的生死，莫若途中乘机买了巧姐，谎称说路上病死了，也就完事了。这样既不会祸害王家，也不会得罪王子腾，也给了贾府一个交代，贾琏和王熙凤是万难出来了，根本不会再有人过问此事。王仁仗着酒气，权衡利弊，一咬牙一跺脚一横心，同意了。这就是王仁和贾芹，一个狠舅，一个奸兄的由来，一个是枉为人，一个假情假意，他们决计要卖了巧姐，报仇雪恨，顺便发笔横财。王熙凤到血崩之症流血而死之时恐怕也没有想到，她的亲人会如此绝情，会如此来算计她的女儿。人性的复杂和丑恶，由此可见。"
          },
          {
            "label": "乱判葫芦案",
            "value": 5,
            "id": 3792,
            "categories": [
              "event"
            ],
            "info": "贾宝玉的表兄薛蟠在应天府与一个小户人家的公子冯渊同时看上了一个被拐的丫头香菱，拐子（就是人口贩子）又一奴卖二主将香菱同时卖于二人，被发觉后二人皆不要退钱只要香菱，薛蟠家大势大，在与冯渊争执时纵奴行凶将其打死，薛蟠本定进京，打死冯渊之后没事人一样就进京探亲了，冯家告上官府。偏生应天府尹是受贾府推介的贾雨村，贾雨村在深知内情的昔日葫芦庙庙祝今日应天府门子的指导下，将这一起人命官司草草了结。"
          },
          {
            "label": "毒设相思局",
            "value": 5,
            "id": 3793,
            "categories": [
              "event"
            ],
            "info": "贾敬的寿辰宁府排家宴，贾瑞在园子里碰见熙凤就起色心。此后几次到荣府都没找到熙凤。贾瑞见到凤姐后，凤姐要他晚上在西穿堂相见。贾瑞按约定晚上钻入穿堂，腊月天寒，白冻一晚而归。代儒惩罚贾瑞跪在院内读文章，打了三四十大板，不许吃饭。第二次又在凤姐房后小过道里那座空房子，被贾蓉、贾蔷捉弄。 贾瑞不听跛道人之言，正照风月宝鉴，一命呜呼。"
          },
          {
            "label": "情赠茜香罗",
            "value": 2,
            "id": 3794,
            "categories": [
              "event"
            ],
            "info": "主要讲的就是宝玉初次遇见蒋玉菡，两人相互觉得彼此投缘，于是宝玉将自己的扇子坠送给蒋玉菡，蒋玉菡将北静王送的大红汗巾子送给宝玉，作为交换，宝玉将自己的松花色汗巾子结下送给他。"
          },
          {
            "label": "勇救薛蟠",
            "value": 2,
            "id": 3795,
            "categories": [
              "event"
            ],
            "info": "时过境迁，二十回后柳湘莲再度登场，这大概是五年后了。这一次，他性格里的更多光彩得到展现。刚才陈述的是他“惩强”的一面，接下来该介绍他“扶弱”的一面了。遭薛蟠调戏时，他已经“又气又恨”了，但看到薛蟠财物被劫，性命堪忧的时候，他又奋不顾身，挺身斗贼。这样的义举，即使放在今天的法制社会里，也是值得表彰褒奖的英雄之事。他还宽容了薛蟠的过错，与之结拜了生死弟兄，又体现出这位江湖侠客的宽广胸襟。"
          },
          {
            "label": "倪二轻财尚义",
            "value": 2,
            "id": 3796,
            "categories": [
              "event"
            ],
            "info": "贾芸想要去他的舅舅卜世仁哪里佘一些麝香、冰片之类的东西，拿去贿赂凤姐，让凤姐给他一个差事，但是卜世人没有同意。贾芸回家的路上遇见了醉金刚倪二刚刚收完利息，贾芸不小心撞上了倪二，倪二本想大骂，后来看见是贾芸就没有骂人，还跟贾芸闲聊了几句，闲聊之中贾芸把事情跟倪二说了，没想到倪二很慷慨的就借给了贾芸钱，倪二本事的放高利贷的，但是这回去没有收利息，借据还是贾芸硬要给的，所以曹公称之为“义侠”。"
          },
          {
            "label": "神游太虚幻境",
            "value": 3,
            "id": 3797,
            "categories": [
              "event"
            ],
            "info": "贾宝玉观赏会芳园的梅花后，在侄媳秦可卿的卧房里睡午觉，做了个神游太虚幻境的美梦。梦中，他遇到了袅娜翩跹的警仙幻姑。仙姑邀请宝玉到她居住的太虚幻境一游。到了那里，忽见有一座牌坊，上书“太虚幻境”四个大字。进入二层门内，只见两边配殿内有“痴情司”， “结怨司”， “朝啼司”， “暮哭司”，“春感司”，“秋悲司”等诸司。宝玉在“薄命司”里看了金陵十二钗正册、副册、又副册。入室，闻一缕幽香，品“千红一窟”茶，饮“万艳同悲”酒，聆听《红楼梦》仙曲十二支。仙姑又将其妹秦可卿许配给宝玉，并授以云雨之事。宝玉与可卿柔情缱绻，软语温存，难解难分。次日，二人携手游玩，至迷津处，被雷声惊醒。"
          },
          {
            "label": "借剑杀人",
            "value": 3,
            "id": 3798,
            "categories": [
              "event"
            ],
            "info": "王熙凤她布置的将尤二姐骗入大观圆，令丫头虐待，向老太太告恶状，在尤二姐怀孕后请医生做手脚使其小产，最后让秋桐担罪名，逼得尤二姐吞金自杀。“弄小巧借剑杀人”中的“剑”指的就是丫头秋桐，杀的就是尤二姐。"
          },
          {
            "label": "平儿失镯",
            "value": 4,
            "id": 3799,
            "categories": [
              "event"
            ],
            "info": "平儿的镯子在一次和大家吃烤鹿肉的时候为了方便而被平儿取了下来放在一旁，这虾须镯本是一对，没想到取下的那一只突然消失了，只剩下一只了。平儿很喜欢这镯子，也一直找，没想到最后查出来是怡红院中的宝玉的丫鬟坠儿盗取了。平儿为了不让宝玉生气难堪，将镯子拿了回来之后骗大家说，这镯子藏在雪里了，雪融了才发现，并不是被人偷了。一个镯子倒也体现了平儿温和大方的性格。后来晴雯将坠儿撵了出去。"
          },
          {
            "label": "平儿行权",
            "value": 3,
            "id": 3800,
            "categories": [
              "event"
            ],
            "info": "柳嫂子是厨房总管，她的女儿柳五儿想进怡红院当差，因为生病暂时在厨房帮忙。后来被冤枉偷了玫瑰露，那个时候凤姐生病， 很多事情都由平儿来处理，平儿知道柳五儿是冤枉的，所以做主放了柳五儿。"
          },
          {
            "label": "司棋被捉",
            "value": 3,
            "id": 3801,
            "categories": [
              "event"
            ],
            "info": "潘又安与司棋在大观园幽会被鸳鸯撞见，畏罪远遁。他发财回家，意欲迎娶司棋，无奈司母不从，司棋撞墙身亡，他也殉情自尽。"
          },
          {
            "label": "巧结梅花络",
            "value": 3,
            "id": 3802,
            "categories": [
              "event"
            ],
            "info": "莺儿是薛宝钗的丫头，手特别巧。每一种样式名称、编法、色彩搭配，清清楚楚，了然于心。因此宝玉请莺儿打一些络子，装点扇子，香坠儿，汗巾子。"
          },
          {
            "label": "亲尝莲叶羹",
            "value": 2,
            "id": 3803,
            "categories": [
              "event"
            ],
            "info": "宝玉挨打后想吃莲叶羹。王熙凤就着人做了。后来王夫人打发玉钏给宝玉送汤。因为宝玉算是间接害死了玉钏的姐姐。玉钏就不大搭理宝玉。宝玉喝了一口莲叶羹之后，故意逗玉钏，说这汤不好喝。玉钏说这还不好吃那什么好吃，宝玉说，那你尝尝。玉钏就尝了一尝。"
          },
          {
            "label": "宝玉挨打",
            "value": 5,
            "id": 3804,
            "categories": [
              "event"
            ],
            "info": "宝玉挨打发生在第三十三回，地点是贾政书房，时间是夏季午休后。它的根本原因是他交结贾家敌对势力忠顺亲王的戏子，犯了政治大忌；导火索是贾环在政老爹面前诬告宝玉“强奸（母婢）未遂，（金钏）赌气投井”。贾政闻而暴怒，回书房关门将宝玉一顿毒打。总共打了大约五十板子，贾母赶来已迟，严斥贾政后，命人将奄奄一息的宝玉从书房带回自己屋里。贾政三番落泪，事后也颇悔下手太重，后脚跟进贾母屋里欲看宝玉，又被贾母斥出屋里。它暗衔“清虚观打醮”、“冯紫英寿宴”两节，近接上一回的“吃胭脂金钏语成谶”，是全书的情节高潮之一。寿宴妓女云儿（告密者）、书房来客贾雨村（参与告密）都不是闲笔。"
          },
          {
            "label": "大闹厨房",
            "value": 3,
            "id": 3805,
            "categories": [
              "event"
            ],
            "info": "司棋让小丫头莲花儿到厨房要碗鸡蛋，没有要到，还惹了一句“头层主子、二层主子”的话，便率众丫头打砸小厨房搞得柳嫂子吃个哑巴亏。司棋此举虽然无理、霸道，但是好爽！"
          },
          {
            "label": "香菱学诗",
            "value": 3,
            "id": 3806,
            "categories": [
              "event"
            ],
            "info": "香菱学诗，是曹雪芹著作《红楼梦》中的经典故事，被选入语文课本。脂砚斋对此有精辟的分析：“细想香菱之为人也，根基不让迎探，容貌不让凤秦，端雅不让纨钗，风流不让湘黛，贤惠不让袭平，所惜者幼年罹祸，命运乖蹇，致为侧室。且曾读书，不能与林湘辈并驰于海棠之社耳。然此一人岂可不入园哉。故欲令入园，终无可入之隙，筹画再四，欲令入园必呆兄远行后方可。”"
          },
          {
            "label": "凤姐托孤",
            "value": 2,
            "id": 3807,
            "categories": [
              "event"
            ],
            "info": "贾府被抄后，贾母去世，刘姥姥三进贾府，没想到贾府已败落不堪，内忧外患，凤姐见姥姥，往事难回首。凤姐病重，临终含泪把女儿巧姐托付给刘姥姥，并从手腕上褪下一只金镯子交给她，村妇刘姥姥却拒收了，但她爽快地答应了日后照顾巧姐。"
          },
          {
            "label": "旺儿妇霸成亲",
            "value": 3,
            "id": 3808,
            "categories": [
              "event"
            ],
            "info": "来旺家的儿子，看上了王夫人屋里的彩霞。来旺家的依仗凤姐的权势，强迫彩霞的父母结亲。"
          },
          {
            "label": "弄权铁槛寺",
            "value": 2,
            "id": 3809,
            "categories": [
              "event"
            ],
            "info": "凤姐到了水月庵安顿下后，一位老尼乘机，求凤姐办一件事，说长安城里有一户财主姓张，张家小姐有一次进庙烧香，被李衙内看上，李公子便要定要娶这位张小姐，可小姐已经受了前任长安守备公子的聘礼。那张财主便想将女儿嫁与李衙内，要退了守备家的婚约，守备家当然不同意，便与之打起了官司。现在便请凤姐，由贾府出面让守备家主动推婚。凤姐便要求送与她三千两百银，便出手帮这个忙，把退婚这件事给做个了解。"
          },
          {
            "label": "智能偷情",
            "value": 3,
            "id": 3810,
            "categories": [
              "event"
            ],
            "info": "智能儿，水月庵的小尼姑。净虚的徒弟，自幼在贾府走动，无人不识，也常和宝玉、秦钟玩笑。长大后渐知风情，看上秦钟人物风流，秦钟也爱她妍媚。两人情投意合，在馒头庵时幽会数次，秦钟返回家后，她从水月庵私逃出来找秦钟，不意被秦钟父亲秦业知觉，将她逐出，后不知去向。"
          },
          {
            "label": "勾引薛蝌",
            "value": 3,
            "id": 3811,
            "categories": [
              "event"
            ],
            "info": "夏金桂大概是看到薛蟠此次出事没命回来，就对老公的兄弟薛蝌动了念头，她的丫头宝蟾虽说给薛蟠做了房里人，但见薛蟠出事，她们主仆二人各怀心事，都把希望放到了薛蝌的身上。先是宝蟾去试探薛蝌，再是主仆二人联手勾引薛蝌。"
          },
          {
            "label": "贾政借钱",
            "value": 2,
            "id": 3812,
            "categories": [
              "event"
            ],
            "info": "贾政扶了贾母灵柩一路南行，因盘缠不够，不得已，写书一封，“差人到赖尚荣任上借银五百”。几天后那家人回来，将赖尚荣的禀启呈上，书内告了多少苦处，备上白银五十两。贾政看了大怒，即命家人：“立刻送还！将原书发回，叫他不必费心。”"
          },
          {
            "label": "探春远嫁",
            "value": 2,
            "id": 3813,
            "categories": [
              "event"
            ],
            "info": "探春判词：后面又画着两人放风筝，一片大海，一只大船，船中有一女子，掩面泣涕。也有四句云：才自精明志自高，生于末世运偏消。清明涕送江边望，千里东风一梦遥。"
          },
          {
            "label": "刘姥姥一进荣国府",
            "value": 4,
            "id": 3814,
            "categories": [
              "event"
            ],
            "info": "刘姥姥家因年关将近，却手头拮据，想起先祖做官时曾在京中认荣国府做了亲戚，便到荣国府讨些过年的钱。见到了当家的二奶奶王熙凤，凤姐将丫头们准备做冬衣的钱给了姥姥，为此刘姥姥十分感激她，并为将来救出凤姐的女儿巧姐埋下了伏线。"
          },
          {
            "label": "黛玉葬花",
            "value": 2,
            "id": 3815,
            "categories": [
              "event"
            ],
            "info": "林黛玉最怜惜花，觉得花落以后埋在土里最干净，说明她对美有独特的见解。她写了葬花词，以花比喻自己，在《红楼梦》中是最美丽的诗歌之一。贾宝玉和林黛玉在葬花的时候有一段对话，成为《红楼梦》中一场情人之间解除误会的绝唱。"
          },
          {
            "label": "宝钗扑蝶",
            "value": 3,
            "id": 3816,
            "categories": [
              "event"
            ],
            "info": "薛宝钗在滴翠亭附近赏春扑蝶的场景，表现了宝钗活泼、旷达的性格。此事之后发生的“滴翠亭事件”，则体现了宝钗的善良机智。此回情节作者通过对人物的刻画及行动的描绘，生动、形象地再现了人物活动的场面，并且为读者展现了一副不可多得的仕女图。"
          },
          {
            "label": "金钏投井",
            "value": 3,
            "id": 3817,
            "categories": [
              "event"
            ],
            "info": "贾宝玉调戏金钏戏说：跟太太讨了她去一起。金钏反调笑宝玉让他去赵姨娘处捉贾环和丫头的奸被假寐的王夫人听到，遂怒起打了金钏一掌说她教坏少爷了。宝玉没敢为金钏辩解吓跑了，金钏被逐出府羞愤自尽！"
          },
          {
            "label": "大观园试才",
            "value": 5,
            "id": 3818,
            "categories": [
              "event"
            ],
            "info": "贾宝玉和姐姐贾元春之间的感情深厚，贾政想让元妃高兴，就让贾宝玉来题大观园里的匾额和对联，让元妃看到爱弟的进步，这回主要讲的就是贾政带着贾宝玉在大观园里考验儿子的才学，为各处题匾,作对联的故事。"
          },
          {
            "label": "秦可卿淫丧天香楼",
            "value": 3,
            "id": 3819,
            "categories": [
              "event"
            ],
            "info": "秦可卿嫁给贾蓉不久，就与公公贾珍有了首尾，且二人常在天香楼约会，二人自为众人不知，殊不知宁府下人早看接触端倪，只是慑于贾珍族长的身份，皆不敢声张。但有三个人一直被蒙在鼓里，即秦可卿的两个丫鬟瑞珠和宝珠，以及她的婆婆尤氏，直到有一天晚上，公媳再次私会于天香楼上，但恰好被前来找少奶奶的两个丫鬟撞见了贾珍和秦可卿的“好事”，贾珍匆忙间更衣逃走，秦可卿也慌忙更衣下楼，一不小心遗下了自己常戴的一根簪子在贾珍处。第二天，这根簪子被尤氏从贾珍换下的衣服中获得，因此产生了怀疑，遂唤秦可卿的两个丫鬟来问，两个丫鬟在尤氏的威逼利诱下，大哭着说出真相，此事被秦可卿贾珍得知，秦氏觉得自己再无脸面苟活，于是在一个晚上自缢于天香楼。"
          },
          {
            "label": "迎春误嫁中山狼",
            "value": 3,
            "id": 3820,
            "categories": [
              "event"
            ],
            "info": "贾迎春过门后极尽妇道，对孙恩爱有加，但不久发现孙绍祖一味好色，好赌酗酒……好不好，打一顿撵在下房里睡去……”，孙视迎春为买来的奴仆，动辄非打即骂。迎春乃名门闺秀，性本娇弱，那里经得起如此蹂躏折磨，不久便香销玉殒，一命归西。"
          },
          {
            "label": "金玉良缘",
            "value": 2,
            "id": 3821,
            "categories": [
              "event"
            ],
            "info": "宝玉身上有一块玉，宝钗有一块金锁，合起来就叫金玉良缘，薛家与贾家两大家族的结合，门当户对的意思。"
          },
          {
            "label": "王熙凤协理宁国府",
            "value": 3,
            "id": 3822,
            "categories": [
              "event"
            ],
            "info": "秦可卿死，宁国府贾珍的妻子尤氏装病不理事，贾珍只好请王熙凤来主持丧事。王熙凤为了显示自己才干，定时点卯，分派任务，处理的井井有条，并且出发了故意搞乱给凤姐下马威的一个。众人听从安排，比较成功的办理了秦可卿的丧事，展示了王熙凤的才干本领。"
          },
          {
            "label": "元妃省亲",
            "value": 1,
            "id": 3823,
            "categories": [
              "event"
            ],
            "info": "元妃省亲时先看到大观园，暗叹过于奢华。后来与贾母等人相遇，都哭了一场。接着见了林黛玉、薛宝钗和薛姨妈，又见了贾宝玉，然后看了匾额，她着各人选一首题诗，薛、林二人得到赞誉。宝玉独占四首，薛宝钗看见他用了“绿玉春犹卷”这一句，提醒他元妃不喜欢“绿玉”一词，叫宝玉改成了“绿蜡”。宝玉作了三首，正在冥思苦想，黛玉见他只抄“杏帘在望”一首，就帮他作了一首，让宝玉抄。其实黛玉本想今夜大展奇才压倒众人的，但元妃只命一人作一首诗，她只好作罢。结果元妃看了，喜之不尽，说最后一首为上。把山庄名改成了“稻香村”。之后再听了几出戏，也就散了。"
          },
          {
            "label": "甄士隐梦幻识通灵",
            "value": 3,
            "id": 3824,
            "categories": [
              "event"
            ],
            "info": "是贾宝玉和林黛玉的来历。宝玉是石头下凡。这块石头因“无材补天”被女娲抛弃在青埂峰下，又四处游荡，到警幻仙子处做了神瑛侍者，遇见一株绛珠仙草，日日为她灌溉甘露，后来又被一僧一道携了投胎下凡做人，他就是贾宝玉。那株绛珠仙草也跟了石头下凡，她就是林黛玉。"
          },
          {
            "label": "晴雯撕扇",
            "value": 3,
            "id": 3825,
            "categories": [
              "event"
            ],
            "info": "端午佳节间，宝玉因金钏儿之事，心情很糟糕。恰巧晴雯给宝玉换衣时失手把他扇子跌折，便训斥了她几句，晴雯的自尊心受到伤害，还击了一通，不仅把宝玉“气得浑身乱颤”，而且连来劝架的袭人也落了个灰头土脸。最后，宝玉一定要回了太太去，至袭人一干丫鬟跪下求情才罢。而宝玉赴宴回来，仍和晴雯有说有笑。听说晴雯喜欢听撕扇子的声音，就任凭她将一大堆名扇痛痛快快撕尽了。最后晴雯将宝玉手中的扇子撕了，又把麝月的扇子也撕了。"
          },
          {
            "label": "凤姐泼醋",
            "value": 4,
            "id": 3826,
            "categories": [
              "event"
            ],
            "info": "凤姐自觉酒沉，下席回房。小丫头见面扭头就跑，凤姐疑心严审，发觉贾琏与鲍二家的偷情。先打平儿，凤姐踢门而进，撕打叫骂。尤氏等人至，贾琏拔剑欲杀凤姐，凤姐哭往贾母处。邢夫人管治喝退贾琏，贾母笑语解围。李纨拉平儿入园，宝玉让平儿怡红院理妆，为平儿黛玉伤感。邢夫人早起带贾琏过贾母处，贾琏下跪作揖陪罪。鲍二家的上吊自尽，凤姐吓阻，贾琏拿钱发送打点。"
          },
          {
            "label": "探春理家",
            "value": 4,
            "id": 3827,
            "categories": [
              "event"
            ],
            "info": "王熙凤病倒，王夫人怕无人看管诺大个贾府，叫探春、李纨以及薛宝钗代理府中一切事物，比凤姐在时还厉害。赶上赵姨娘兄弟死了，探春公私分明，不为亲人多给银子，引得赵姨娘大怒。之后裁度众人月例，把园子包给众仆妇，引得众人交口称赞，探春的理家理念得到了具体实施。"
          },
          {
            "label": "湘云醉眠芍药裀",
            "value": 1,
            "id": 3828,
            "categories": [
              "event"
            ],
            "info": "在宝玉等人生日宴席上，史湘云喝醉酒，便在园中山后一块石凳上睡着了。她头枕着一包芍药花瓣，芍药花飞了她一身，手中扇子落在地下，也半被花埋，身边蜂围蝶绕。虽然香梦沉酣，她口中还唧唧嘟嘟说着酒令。众人上前推醒她后，湘云不由心中后悔。"
          },
          {
            "label": "尤三姐殉情",
            "value": 2,
            "id": 3829,
            "categories": [
              "event"
            ],
            "info": "柳湘莲与尤三姐相爱后，赠与三姐一双鸳鸯剑为定情物，然后远出官职。不想一别竟是五年。五年中柳湘莲有期盼、有思念，但也听到了许多贾府的传言之讯。在假期中，柳湘莲本想娶妻成亲，但回到京城却从朋友那里听到了贾府的很多乱伦的风月之事。此行却变成了落实贾府男女们肮脏行为的调查，和悔婚约决心的下定。当他来到贾府门前，正值宝玉在门外，寒暄之后，宝玉提到了尤三姐的痴情苦等。柳湘莲急忙摆手摇头并说：“你们东府里除了那两个石头狮子干净，只怕连猫儿狗儿都不干净，我不做这剩王八。”躲在门内的三姐听得真切 ，盼君、迎君的热切激情，如同被冰水陡然泼灌。在回屋还剑之时，将剑鞘递与柳湘莲；顺势自己抽出一柄剑，当着柳湘莲的面愤情自尽。以自己的鲜血和生命证明了自身的清白和对爱情的忠贞。"
          },
          {
            "label": "抄检大观园",
            "value": 8,
            "id": 3830,
            "categories": [
              "event"
            ],
            "info": "园内发现香囊，王夫人令凤姐和王善保家一起抄检。在怡红院，晴雯愤怒倒出所有东西，并无私弊之物。在探春处，探春不但顶撞凤姐，还打了王善保耳光。在迎春处，搜到司棋私通证据。在惜春处，搜到入画哥哥的靴袜。最后，晴雯、司棋、入画被逐出府。"
          },
          {
            "label": "黛玉焚稿",
            "value": 3,
            "id": 3831,
            "categories": [
              "event"
            ],
            "info": "林黛玉得知贾宝玉和薛宝钗定婚的消息后，一病不起，临死前，挣扎着在卧榻边，狠命撕那宝玉送的旧帕和写有诗文的绢子，又叫雪雁点灯笼上火盆，黛玉将绢子撂在火上，雪雁也顾不得烧手，从火里抓起来撂在地下乱踩，却已烧得所余无几了，之后黛玉便含泪而逝。"
          },
          {
            "label": "黛玉之死",
            "value": 1,
            "id": 3832,
            "categories": [
              "event"
            ],
            "info": "黛玉听说宝玉结婚的消息后，病情日重。她自料万无生理，就将作为自己和宝玉爱情见证的旧帕与诗稿付之一炬。贾府上下忙于娶亲，无暇顾及黛玉，还把她的丫环叫走使唤。黛玉在直声叫道“宝玉！宝玉！你好——”后便去世了。而此时，远处隐隐约约传来了娶亲的音乐之声，潇湘馆外竹梢风动,月影移墙,好不凄凉冷淡!"
          },
          {
            "label": "晴雯补裘",
            "value": 2,
            "id": 3833,
            "categories": [
              "event"
            ],
            "info": "袭人因母病回家，晴雯夜里受寒伤风，身上烧得烫人。宝玉为舅舅庆寿，贾母给了宝玉一件俄罗斯的孔雀毛做的氅衣，宝玉穿时不小心烧了个洞，麝月忙悄悄拿出来叫人织补，谁知道东西太名贵，没有裁缝敢揽活。睛雯心灵手巧，重病中连夜补好。"
          },
          {
            "label": "元宵丢英莲",
            "value": 2,
            "id": 3834,
            "categories": [
              "event"
            ],
            "info": "香菱原名甄英莲，她出身在一二等富贵风流之地姑苏，母亲封氏性情贤淑，深明礼义，父亲甄士隐严正清白，禀性恬淡，为本地望族。年已半百的夫妻俩，膝下无儿，只有一女，乳名英莲。英莲“生得粉妆玉琢，乖觉可喜”，全家极其疼爱。应该说英莲生活在这样的家庭是幸福美好的。不幸的是，在她五岁那年的元霄佳节，士隐命家人抱去看灯，至半夜时家人霍启因小解，将英莲放在一家人家门槛上，待他回来，英莲不见踪影。全家人到处寻找，皆无音讯，英莲早被拐子拐去，另走他乡。"
          },
          {
            "label": "冷子兴演说荣国府",
            "value": 2,
            "id": 3835,
            "categories": [
              "event"
            ],
            "info": "大致介绍了宁荣二府的主要家庭成员的情况，二是点明了贾府现时萧条的光景和面临的危机，透露出这样一个钟鸣鼎食之家，如今的儿孙竟一代不如一代了。这些为我们理解小说是写一个处于“末世”的封建家族的衰亡提供了启示。"
          },
          {
            "label": "木石前盟",
            "value": 2,
            "id": 3836,
            "categories": [
              "event"
            ],
            "info": "《红楼梦》中贾宝玉与林黛玉的讹缘，木指黛玉前世绛珠仙草，石指宝玉前世顽石。神瑛侍者以甘露之水灌溉绛珠草，使其得换人形，修成个女体，名唤绛珠仙子。为报灌溉之德，绛珠仙子情愿随神瑛侍者下凡历劫，将自己一世的眼泪还他。可是一个与此事毫不相干的顽石插足，错领绛珠之泪，以致于绛珠泪尽而逝。故事由两个神话开头，渲染出了佛道的因果关系，以及人生如梦的主旨。可是宝玉口中的“木石前盟”竟然只是一厢情愿的讹缘，起到了当头棒喝的效果，乃是作者苦口婆心的劝诫：放下情迷，原本人生如梦。"
          },
          {
            "label": "贤袭人娇嗔箴宝玉",
            "value": 3,
            "id": 3837,
            "categories": [
              "event"
            ],
            "info": "史湘云在贾府玩，夜里和林黛玉睡在一起。贾宝玉一早起床就往林黛玉房间跑，要史湘云给他梳头。袭人跟贾宝玉说：一个男孩子老往女孩子房间跑，这样很不好。薛宝钗觉得袭人有见识。贾宝玉被袭人和薛宝钗说了一番，很不高兴，成天都没有理睬袭人，袭人想只有慢慢劝他。"
          },
          {
            "label": "潇湘馆",
            "value": 3,
            "id": 3838,
            "categories": [
              "location"
            ],
            "info": "黛玉的居所。黛玉作诗的笔名就潇湘妃子，这是曹雪芹对黛玉这个人物的赞美。"
          },
          {
            "label": "蘅芜苑",
            "value": 3,
            "id": 3839,
            "categories": [
              "location"
            ],
            "info": "薛宝钗的住所，匾额题为“蘅芷清芬”。宝钗亦因此得诗号“蘅芜君”。蘅芜苑离正殿不远，在沁芳池边，是一所外则“无味”，内藏“清雅”的处所。作者通过欲扬先抑的手法，暗喻了宝钗外表藏愚守拙，内在天然可爱，并且亲近佛道的思想性格。"
          },
          {
            "label": "暖香坞",
            "value": 2,
            "id": 3840,
            "categories": [
              "location"
            ],
            "info": "惜春的住所。暖香坞位于大观园的西半区，北面是李纨住的稻香村，南边是薛宝钗的蘅芜苑。"
          },
          {
            "label": "缀锦楼",
            "value": 3,
            "id": 3841,
            "categories": [
              "location"
            ],
            "info": "贾迎春的住所。大观园东部有一个庭院，西侧临水，东部靠山，院内西部建筑是紫菱洲，北房正厅，即缀锦楼。"
          },
          {
            "label": "稻香村",
            "value": 3,
            "id": 3842,
            "categories": [
              "location"
            ],
            "info": "李纨的住所。转过山怀中，隐隐露出一带黄泥墙，墙上皆用稻茎掩护。有几百枝杏花，如喷火蒸霞一般。里面数楹茅屋，外面却是桑榆槿柘，各色树稚新条，随其曲折，编就两溜青篱。篱外山坡之下，有一土井，旁有桔槔辘轳之属；下面分畦列亩，佳蔬菜花，一望无际。"
          },
          {
            "label": "凤藻宫",
            "value": 2,
            "id": 3843,
            "categories": [
              "location"
            ],
            "info": "元春省亲的住所。"
          },
          {
            "label": "秋爽斋",
            "value": 3,
            "id": 3844,
            "categories": [
              "location"
            ],
            "info": "贾探春在大观园的住所。题有“桐剪秋风”匾额。“斋”，书房也。斋较堂，惟气藏而收敛，盖藏修秘处之地，故式不宜敞显，充满了浓郁的书卷气息。"
          },
          {
            "label": "怡红院",
            "value": 4,
            "id": 3845,
            "categories": [
              "location"
            ],
            "info": "贾宝玉的住所。试才题对额时，宝玉题为“红香绿玉”，取意蕉棠两植。元宵省亲时，元春不喜“香玉”二字（因暗指黛玉），改为“怡红快绿”，赐名“怡红院”，宝玉因号“怡红公子”。"
          },
          {
            "label": "栊翠庵",
            "value": 2,
            "id": 3846,
            "categories": [
              "location"
            ],
            "info": "妙玉在大观园中的修行处，是一所园林中点景的尼姑庵。后来惜春出家，便住在此处。对于此建筑布局，书中并未展开描述，只是点到山门、东禅堂、耳房这三处，其中给读者留下深刻印象的是栊翠庵中的红梅。"
          },
          {
            "label": "梨香院",
            "value": 2,
            "id": 3847,
            "categories": [
              "location"
            ],
            "info": "宝钗初入贾府时，便住在梨香院。在贾府建成大观园以后，“薛姨妈另迁于东北上一所幽静房舍居住，将梨香院早已腾挪出来，另行修理了，就令教习在此教演女戏”（第18回），梨香院又成为了龄官等十二女伶的驻地。再后来，大观园内的女子戏班被遣散，梨香院还临时做过尤二姐的停灵之所（第69回）。"
          },
          {
            "label": "荣国府",
            "value": 16,
            "id": 3848,
            "categories": [
              "location"
            ],
            "info": "荣国公府邸。荣国公贾源是弟弟，死后他的儿子贾代善袭了他的爵位，贾代善的夫人就是贾母，史太君。贾代善生了两个儿子一个女儿，分别是：贾赦、贾政和贾敏。贾代善死后大儿子贾赦（妻：邢夫人）袭了爵位。"
          },
          {
            "label": "宁国府",
            "value": 7,
            "id": 3849,
            "categories": [
              "location"
            ],
            "info": "宁国公府邸。宁国公贾演是哥哥，死后他的儿子贾代化袭了他的爵位。贾代化死后他的儿子贾敬袭了爵位，贾敬有一儿一女，儿子是贾珍，女儿是惜春。贾敬死后他的儿子贾珍（妻：尤氏）袭了爵位。"
          },
          {
            "label": "苏州",
            "value": 9,
            "id": 3850,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里地名，即姑苏城。林黛玉祖籍。"
          },
          {
            "label": "大观园",
            "value": 9,
            "id": 3851,
            "categories": [
              "location"
            ],
            "info": "贾府为元妃省亲所建别墅园林。"
          },
          {
            "label": "玄真观",
            "value": 2,
            "id": 3852,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里，贾敬修行、炼丹的地方。"
          },
          {
            "label": "京郊",
            "value": 7,
            "id": 3853,
            "categories": [
              "location"
            ],
            "info": "地处京城之外，刘姥姥家所处位置。"
          },
          {
            "label": "扬州",
            "value": 2,
            "id": 3854,
            "categories": [
              "location"
            ],
            "info": "林黛玉出生的地方，也是林如海捐馆之所。"
          },
          {
            "label": "金陵",
            "value": 31,
            "id": 3855,
            "categories": [
              "location"
            ],
            "info": "金陵即今天的南京。贾母祖籍。"
          },
          {
            "label": "京城",
            "value": 11,
            "id": 3856,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里写的京城是指长安。"
          },
          {
            "label": "胡州",
            "value": 1,
            "id": 3857,
            "categories": [
              "location"
            ],
            "info": "作者虚拟的地名，脂评：“胡邹也。”"
          },
          {
            "label": "葫芦庙",
            "value": 2,
            "id": 3858,
            "categories": [
              "location"
            ],
            "info": "应天府的门子曾经是葫芦庙里的小沙僧。贾雨村失意时住过一段时间。在苏州城内。"
          },
          {
            "label": "南海",
            "value": 2,
            "id": 3859,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》里贾探春远嫁的地方。"
          },
          {
            "label": "太虚幻境",
            "value": 1,
            "id": 3860,
            "categories": [
              "location"
            ],
            "info": "太虚幻境，《红楼梦》中的女儿仙境，警幻仙子司主。它位于离恨天之上、灌愁海之中的放春山遣香洞，以梦境的形式向甄士隐、贾宝玉二位有缘人显现。"
          },
          {
            "label": "大同府",
            "value": 1,
            "id": 3861,
            "categories": [
              "location"
            ],
            "info": "《红楼梦》中，贾迎春之夫孙绍祖是山西大同府人。"
          },
          {
            "label": "阊门",
            "value": 3,
            "id": 3862,
            "categories": [
              "location"
            ],
            "info": "苏州繁华之地，《红楼梦》写道：最是红尘中一二等富贵风流之地。"
          },
          {
            "label": "贾宝玉",
            "value": 52,
            "image": "./images/photo/贾宝玉.jpg",
            "id": 3863,
            "categories": [
              "person"
            ],
            "info": "荣国府衔玉而诞的公子，贾政与王夫人之次子，阖府捧为掌上明珠，对他寄予厚望，他却走上了叛逆之路，痛恨八股文，批判程朱理学，给那些读书做官的人起名“国贼禄蠹”。他不喜欢“正经书”，却偏爱《牡丹亭》《西厢记》之类的“杂书”。他终日与家里的女孩们厮混，爱她们美丽纯洁，伤悼她们的薄命悲剧。"
          },
          {
            "label": "贾代善",
            "value": 8,
            "id": 3864,
            "categories": [
              "person"
            ],
            "info": "荣国公贾源是宁国公贾演的弟弟，贾源死后他的儿子贾代善袭了他的爵位。贾代善的夫人就是贾母（又称史太君）。 贾代善生了两个儿子多个女儿，其中有姓名的分别是：贾赦、贾政和贾敏。 贾代善死后大儿子贾赦（妻：邢夫人）袭了爵位。"
          },
          {
            "label": "贾代化",
            "value": 5,
            "id": 3865,
            "categories": [
              "person"
            ],
            "info": "贾演长子，贾敷、贾敬之父，京营节度使世袭一等神威将军。"
          },
          {
            "label": "贾敷",
            "value": 4,
            "id": 3866,
            "categories": [
              "person"
            ],
            "info": "贾代化长子，贾敬之兄。八九岁夭折。"
          },
          {
            "label": "贾敬",
            "value": 6,
            "image": "./images/photo/贾敬.jpg",
            "id": 3867,
            "categories": [
              "person"
            ],
            "info": "宁国公贾演的孙子，京营节度使世袭一等神威将军贾代化的次子，贾珍之父。是乙卯科进士，却一味好道，在都外玄真观修炼，烧丹炼汞，别的事一概不管，放纵家人胡作非为。后因吃秘制的丹砂烧胀而死。死后追赐为五品之职。"
          },
          {
            "label": "贾赦",
            "value": 15,
            "image": "./images/photo/贾赦.jpg",
            "id": 3868,
            "categories": [
              "person"
            ],
            "info": "字恩侯，荣国公之孙，贾代善、贾母之长子，邢夫人的丈夫，贾琏、迎春的父亲，是个无耻之徒，他承袭了一等将军之职爵位。生性好色，妾室一片，平日依官作势，行为不检。他不满贾母偏向弟弟贾政，与贾母、贾政貌合神离。这个无耻的封建阶级的末代子孙，终于遭到查抄家产，革去世职，远离都城，充军边地的应得下场。"
          },
          {
            "label": "贾政",
            "value": 24,
            "image": "./images/photo/贾政.jpg",
            "id": 3869,
            "categories": [
              "person"
            ],
            "info": "字存周，贾母和贾代善所生的次子，贾宝玉的父亲，林黛玉的舅舅，薛宝钗的姨父。他是除贾母外荣国府的最高掌权者，但同贾母一样不常管理府中大小俗务，每日只看书著棋，同一众清客闲聊，是名副其实的甩手掌柜，他并不喜好繁华奢侈的生活，在游览大观园时亦有过归农隐逸之意。他自幼好读书，但并不是天生的方正呆板，出仕前，他“也是个诗酒放诞之人”，但“一切为的是光宗耀祖”，因此重视读书上进，归于正途。为人端方正直，谦恭厚道，人品端方，风声清肃。礼贤下士，济弱扶危，大有祖风，惟失之于迂腐。他一心孝顺贾母，亦想严厉管教子女；他想作好官，可是不谙世情，只解打躬作揖，终日臣坐，形同泥塑，遭人蒙骗，弄得声名狼藉。"
          },
          {
            "label": "贾蓉",
            "value": 11,
            "image": "./images/photo/贾蓉.jpg",
            "id": 3870,
            "categories": [
              "person"
            ],
            "info": "贾珍之子，是一个十六七岁的少年，面目清秀，身材俊俏，轻裘宝带，美服华冠的男子。他原为监生，妻子秦可卿死后，为了在丧礼上风光些，父亲贾珍花了一千两百两银子给他捐了个五品龙禁尉。贾蓉长得是面目清秀，细挑身材，但生活上却和他父亲贾珍一样荒淫无耻。"
          },
          {
            "label": "贾蔷",
            "value": 7,
            "image": "./images/photo/贾蔷.jpg",
            "id": 3871,
            "categories": [
              "person"
            ],
            "info": "宁国府的正派玄孙。他父母早亡，从小跟贾珍过活，比贾蓉生得还风流俊俏。虽然每日应名去上学，亦只不过虚掩眼目而已，仍旧是斗鸡走狗，赏花阅柳。他上有贾珍溺爱，下有贾蓉匡助，越发自大起来。后成了贾府小戏班梨香院的总管，与小旦龄官相好。"
          },
          {
            "label": "贾兰",
            "value": 4,
            "image": "./images/photo/贾兰.jpg",
            "id": 3872,
            "categories": [
              "person"
            ],
            "info": "贾珠与李纨之子。关于贾兰的人物结局，高鹗整理的后40回中的描述是：贾兰成年后考中第一百三十七名举人。"
          },
          {
            "label": "贾珍",
            "value": 19,
            "image": "./images/photo/贾珍.jpg",
            "id": 3873,
            "categories": [
              "person"
            ],
            "info": "贾敬之子，贾演曾孙。世袭三品爵威烈将军。生活穷奢极欲，虽有一妻二妾，但仍和儿媳秦可卿、妻妹尤二姐关系暧昧。秦可卿死后，是他流泪向王夫人请求让王熙凤料理丧事，让她“爱怎么办就怎么样办”，铺排浪费，为丧礼风光，特意花一千二百两银子为儿子贾蓉捐了个五品龙禁尉，而儿媳葬礼的奢华也证明了他们之间的不正当关系。后因作恶多端，被人参奏革去世职，发配海疆。"
          },
          {
            "label": "贾琏",
            "value": 25,
            "image": "./images/photo/贾琏.jpg",
            "id": 3874,
            "categories": [
              "person"
            ],
            "info": "贾赦的长子，刑夫人无子女,异母妹妹迎春,妻子王熙凤,女儿巧姐。他捐了个同知的官位，不喜欢读书谋求官位。住在荣国府，和妻子王熙凤帮着料理荣府家务，在贾府一众男子中算是第一得力干练之人。他最大的缺点是好色，但并不像贾珍、薛蟠等那样强人所难。女儿巧姐出天花，按迷信要夫妻分房，他一离开王熙凤就找“多姑娘” 鬼混。王熙凤去过生日宴会，他就把鲍二媳妇勾搭上手，见了尤二姐，又贪图其美色，骗娶为二房。父亲贾赦却夸他能干，又把自己的丫环秋桐赏给他。从他不赞同贾雨村为了几把扇子把石呆子害得坑家败业、真情对待尤二姐之死，以及劝阻王熙凤将彩霞配给旺儿品德不好的儿子等事中，可以看出他富有同情心、做事有底线，不以自己世家公子的身份压榨别人。他同王熙凤的关系由一开始的和美转为同床异梦，可以看成是王熙凤的悲剧，也是他的悲剧。"
          },
          {
            "label": "贾琮",
            "value": 4,
            "id": 3875,
            "categories": [
              "person"
            ],
            "info": "贾赦之子。"
          },
          {
            "label": "贾珠",
            "value": 12,
            "id": 3876,
            "categories": [
              "person"
            ],
            "info": "贾政嫡长子，与元春、宝玉均为王夫人所生，娶妻李纨，育子贾兰，在《红楼梦》中是作为已故人物。"
          },
          {
            "label": "贾环",
            "value": 10,
            "image": "./images/photo/贾环.jpg",
            "id": 3877,
            "categories": [
              "person"
            ],
            "info": "同贾宝玉为同父异母的弟弟，其母为贾政之妾赵姨娘。贾环颇为顽劣，赶围棋玩耍时，与丫头莺儿撒泼耍赖。贾环十分忌恨其兄贾宝玉，多次陷害宝玉。故意拨翻烛台 ，烫伤宝玉。金钏跳井事件后，诬陷宝玉，使得宝玉遭受贾政毒打。贾环因常常受到不公平对待而生不平、妒嫉之心；贾环虽行为无赖，然而也算尚学，小有才华。"
          },
          {
            "label": "贾璜",
            "value": 8,
            "id": 3878,
            "categories": [
              "person"
            ],
            "info": "贾家“玉”字辈嫡派，璜大奶奶金氏的老公，金荣的姑父。远不如宁荣二府的富势，夫妻俩只守着些小的产业。第十回中道：‘时常到宁荣二府里去请请安，又会奉承凤姐儿并尤氏，所以凤姐儿尤氏也 时常资助资助他，方能如此度日。请安，奉承，尤显得当时宁荣二府溜须拍马之风盛行。"
          },
          {
            "label": "贾演",
            "value": 5,
            "id": 3879,
            "categories": [
              "person"
            ],
            "info": "荣国公贾源的胞兄，被朝廷封为宁国公，底下有四子（书中仅提及长子贾代化），死后由长子贾代化继承其爵位，贾演与贾源为贾府家业的开创者。"
          },
          {
            "label": "贾源",
            "value": 4,
            "id": 3880,
            "categories": [
              "person"
            ],
            "info": "宁国公贾演的胞弟，被朝廷封为荣国公。他是宁国公贾演的胞弟，贾政、贾赦的祖父，贾宝玉的曾祖父。贾源被朝廷封为荣国公，死后由长子贾代善继承其爵位。"
          },
          {
            "label": "秦钟",
            "value": 10,
            "image": "./images/photo/秦钟.jpg",
            "id": 3881,
            "categories": [
              "person"
            ],
            "info": "表字鲸卿。秦业五十三岁时得的儿子，秦可卿的弟弟。他生得眉清目秀，粉面朱唇，身材俊俏，举止风流，怯怯羞羞的有些女儿之态。秦钟本性怯弱，且带病未痊，受了笞杖，今见老父气死，又添了许多病症，不几日也死了。"
          },
          {
            "label": "王子腾",
            "value": 4,
            "id": 3882,
            "categories": [
              "person"
            ],
            "info": "都太尉统制县伯王公之后裔，王夫人、薛姨妈、王子胜之兄。初任京营节度使，后擢九省统制，奉旨查边，旋升九省都检点。鲍二家的自缢后，阻其娘家亲戚申诉，并左右都察院审理张华一案的，都是王熙凤倚王子腾之势所为。而他又因贾雨村私断金陵命案便累上保本、引荐雨村进京。"
          },
          {
            "label": "王公之子",
            "value": 7,
            "id": 3883,
            "categories": [
              "person"
            ],
            "info": "王熙凤之祖父。"
          },
          {
            "label": "王仁",
            "value": 4,
            "id": 3884,
            "categories": [
              "person"
            ],
            "info": "王仁是王熙凤的哥哥，贾巧姐的舅舅，王仁在这里其实就是“忘仁”的意思，说明他是个忘却仁义的小人，后来贾家败落，王仁居然将自己的外甥女贾巧姐卖到了烟花柳巷之地，几乎害了她一生，幸亏由刘姥姥救出。"
          },
          {
            "label": "薛蟠",
            "value": 17,
            "image": "./images/photo/薛蟠.jpg",
            "id": 3885,
            "categories": [
              "person"
            ],
            "info": "字文龙（一作“文起”），外号“呆霸王”，薛姨妈之子，薛宝钗之兄。因幼年丧父，寡母又纵容溺爱，终日唯有斗鸡走马，游山玩水。薛蟠任性弄气，诨号呆霸王。不过，薛蟠也很重义气。"
          },
          {
            "label": "薛蝌",
            "value": 5,
            "image": "./images/photo/薛蝌.jpg",
            "id": 3886,
            "categories": [
              "person"
            ],
            "info": "皇商之子，薛姨妈的侄儿，薛宝琴的胞兄，薛宝钗的堂弟。"
          },
          {
            "label": "史鼐",
            "value": 2,
            "id": 3887,
            "categories": [
              "person"
            ],
            "info": "世袭保龄侯、尚书令史公之次孙(也就是史公的嫡长子的次子)，忠靖侯史鼎之兄，史湘云之叔，贾母之侄。"
          },
          {
            "label": "史鼎",
            "value": 2,
            "id": 3888,
            "categories": [
              "person"
            ],
            "info": "史湘云之叔，贾母之侄。"
          },
          {
            "label": "史侯之子",
            "value": 6,
            "id": 3889,
            "categories": [
              "person"
            ],
            "info": "贾母的哥哥。"
          },
          {
            "label": "柳湘莲",
            "value": 8,
            "image": "./images/photo/柳湘莲.jpg",
            "id": 3890,
            "categories": [
              "person"
            ],
            "info": "又称冷面二郎，原系世家子弟。他父母早丧，读书不成。性情豪爽，酷好耍枪舞剑，赌博吃酒，以至眠花宿柳，吹笛弹筝，无所不为。他生得又美，是一个业余的戏剧演员，最喜串戏，擅演生旦风月戏文，在书中和宝玉最合得来。有一次在赖大家赴宴，薛蟠酒后向他调情，被他骗至北门（今德胜门）外苇子坑打了个半死，事后，远走他乡。 后又在路上救了薛蟠，与薛蟠结为兄弟。足见其无邪之心。行事草率，不负责任。"
          },
          {
            "label": "贾雨村",
            "value": 9,
            "image": "./images/photo/贾雨村.jpg",
            "id": 3891,
            "categories": [
              "person"
            ],
            "info": "贾雨村是一个提纲挈领式的人物，以“假语村言”提醒读者，统率全文。 贾雨村，名化，字时飞，别号雨村，故为“假语村言”。原系湖州人氏，生于仕宦人家。但到他时，祖宗根基已尽，人口衰丧，只剩下他一人。他想进京求取功名，无奈囊内空空，只得暂寄姑苏城里葫芦庙中安身，每日卖文作字为生。后因甄士隐相助，他才有钱上路，考中进士，做了知府。不久因贪酷徇私被革职，受聘至林如海家任林黛玉启蒙教师。在贾政的极力帮助下，他又官复原职，但为官不正，乱判了一起“葫芦案”（为“糊涂案”），后来这一案件被世人所知，因为“因嫌纱帽小，致使锁枷杠”。"
          },
          {
            "label": "贾瑞",
            "value": 4,
            "image": "./images/photo/贾瑞.jpg",
            "id": 3892,
            "categories": [
              "person"
            ],
            "info": "贾府义学塾贾代儒的长孙。贪图便宜又好色，欺自己的嫂子，死于王熙凤设的“相思局”中。"
          },
          {
            "label": "蒋玉菡",
            "value": 6,
            "id": 3893,
            "categories": [
              "person"
            ],
            "info": "忠顺王府戏班的名角，擅唱小旦，小名琪官（一作棋官）。贾宝玉曾以玉玦扇坠和袭人所给松花汗巾相赠，蒋玉菡回赠以北静王所赐茜香国女国王贡奉的大红汗巾。贾府彻底败落后，蒋玉菡娶宝玉房中大丫头袭人为妻。在贾府被抄家后，蒋玉菡夫妇资助了贫穷的贾宝玉夫妇。蒋玉菡妩媚温柔，随机应答，很有礼貌。"
          },
          {
            "label": "贾芸",
            "value": 7,
            "image": "./images/photo/贾芸.jpg",
            "id": 3894,
            "categories": [
              "person"
            ],
            "info": "生有一张容长脸儿，长挑身材，甚是斯文清秀。父亲早逝，因贾宝玉一句玩笑话“像我儿子”，他便伶俐地说：“如若宝叔不嫌侄儿蠢笨，认作儿子，就是我的造化了。”贾芸，贾府族人，西廊下五嫂子的儿子。为了到荣国府谋事做，对凤姐百般奉承，又夸她能干又送她香料，得了一个管花草的职位。而为了攀入贾府中心，他不惜对小他几岁的宝玉认父亲，并赠了两盆白海棠。而他也是一个重情重义的人，贾府败落后帮助过身陷困境的宝玉和凤姐，并喜欢上了丫头小红，捡到她的手帕后一番接触，两人渐渐陷入爱河，上演了一出和宝黛之恋同样纯美的爱情故事。"
          },
          {
            "label": "倪二",
            "value": 2,
            "image": "./images/photo/倪二.jpg",
            "id": 3895,
            "categories": [
              "person"
            ],
            "info": "贾芸的街坊，绰号醉金刚，虽是个泼皮无赖，“却因人而使，颇颇的有义侠之名”，故作者特设回目《醉金刚轻财尚义侠》。"
          },
          {
            "label": "林如海",
            "value": 5,
            "image": "./images/photo/林如海.jpg",
            "id": 3896,
            "categories": [
              "person"
            ],
            "info": "姑苏林家的子孙，娶妻贾敏（已先于林如海病故），育有一子，三岁时没了，仅有一女林黛玉。"
          },
          {
            "label": "焦大",
            "value": 2,
            "image": "./images/photo/焦大.jpg",
            "id": 3897,
            "categories": [
              "person"
            ],
            "info": "从小跟宁国公贾演出过三四回兵，曾从死人堆里把奄奄一息的主子背出来。没有饭吃，他饿著肚子去偷东西给主子吃，没有水喝，他自己喝马尿，把得来的半碗水给主子喝。由于以往的功劳情分，宁府的主子们对他另眼相看，不大难为他。他对宁国府后代糜烂的生活深恶痛绝，也只有他在喝醉酒后敢大骂他们：“每日偷狗戏鸡，爬灰的爬灰，养小叔子的养小叔子”，吓得众小厮魂飞魄丧，把他捆起来，用土和马粪满满填了他一嘴。"
          },
          {
            "label": "秦业",
            "value": 2,
            "id": 3898,
            "categories": [
              "person"
            ],
            "info": "秦钟生父，秦可卿养父。他的名字有一种隐意，是“情孽”.任工部营缮郎，夫人早亡，因素与贾府有些瓜葛，故将养女许配给了贾蓉。五旬之上方得了秦钟，因此对儿子期望极高。纵使宦囊羞涩，仍东拼西凑的恭恭敬敬封了二十四两贽见礼，亲自带了秦钟，来代儒家拜见了。这一点倒是和现代人很相像的。正是：早知日后闲争气，岂肯今朝错读书。当他有发现水月庵智能私找秦钟，气得旧病发作而亡。"
          },
          {
            "label": "王公",
            "value": 2,
            "id": 3899,
            "categories": [
              "person"
            ],
            "info": "王夫人之祖父。"
          },
          {
            "label": "贾代儒之子",
            "value": 3,
            "id": 3900,
            "categories": [
              "person"
            ],
            "info": "贾代儒之子。"
          },
          {
            "label": "薛公之孙",
            "value": 1,
            "id": 3901,
            "categories": [
              "person"
            ],
            "info": "薛公之孙。"
          },
          {
            "label": "史侯",
            "value": 3,
            "id": 3902,
            "categories": [
              "person"
            ],
            "info": "红楼梦中史侯是史湘云祖父的父亲，有两个孩子，分别是史太君和史湘云祖父。女孩史太君嫁入贾代善门下，也就是我们说的贾母。而儿子有三个孩子，史鼐和史鼎也就是保龄侯和忠靖侯。还有一个就是史湘云的爸爸或妈妈。这个我也不知道，有谁知道请补充。史湘云父母只有史湘云一个孩子，史湘云嫁给了卫若兰。"
          },
          {
            "label": "甄士隐",
            "value": 8,
            "image": "./images/photo/甄士隐.jpg",
            "id": 3903,
            "categories": [
              "person"
            ],
            "info": "姓甄，名费，谐音“废”，字士隐。“甄士隐”取意为“真事隐”。书中有“因曾历过一番梦幻之后，故将真事隐去。”一句。正如贾雨村是“假语村言”一样。甄士隐是和贾雨村两相对照而写的，作者在开卷第一段里就明确表示他撰拟这两个名字的寓意。写甄士隐是为了写一个经历了骨肉分离、家遭火灾、下半世坎坷而终于醒悟出世的人物形象。他可能是作者自身的影子，同时也是提系着全书主题的一个线索。"
          },
          {
            "label": "邢忠",
            "value": 2,
            "image": "./images/photo/邢忠.jpg",
            "id": 3904,
            "categories": [
              "person"
            ],
            "info": "邢岫烟父，邢夫人兄，薛蝌岳父。他因家中艰难，故携家上京投靠贾府。"
          },
          {
            "label": "板儿",
            "value": 7,
            "image": "./images/photo/板儿.jpg",
            "id": 3905,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的外孙。在小说第六回“刘姥姥一进荣国府”中，多处生动地描写了板儿未经世面的村野孩童形象。及至成年，娶贾巧姐为妻。"
          },
          {
            "label": "孙绍祖",
            "value": 5,
            "image": "./images/photo/孙绍祖.jpg",
            "id": 3906,
            "categories": [
              "person"
            ],
            "info": "迎春丈夫孙绍祖在家境困难时曾经拜倒在贾府门下，乞求帮助。后来，孙绍祖在京袭了官职，又“在兵部候缺题升”，贾家衰败后，孙绍祖向他逼债，任意践踏迎春。迎春判词有“迎春遭遇中山狼”一幅，画中的孙绍祖，高壮如异兽，十分可怕。“中山狼”之形态毕出也。在这桩弱女悍夫的不幸婚姻的室内细节，文字没有写到之处，似乎都可从画中窥出。"
          },
          {
            "label": "李贵",
            "value": 2,
            "image": "./images/photo/李贵.jpg",
            "id": 3907,
            "categories": [
              "person"
            ],
            "info": "李嬷嬷子，宝玉之奶兄、贴身跟班。虽不识书，学舌什么“呦呦鹿鸣，荷叶浮萍”，但颇明事理，众顽童大闹学堂时亏他力劝，并指明各人的不是，方才平息了事端。宝玉探秦钟时，他亦如长兄般慰藉。他在贾府中亦是体面的男仆，有小厮服侍。"
          },
          {
            "label": "赵天梁",
            "value": 1,
            "id": 3908,
            "categories": [
              "person"
            ],
            "info": "贾琏的奶哥哥。"
          },
          {
            "label": "赵天栋",
            "value": 1,
            "id": 3909,
            "categories": [
              "person"
            ],
            "info": "贾琏的奶哥哥。"
          },
          {
            "label": "贾代儒",
            "value": 14,
            "image": "./images/photo/贾代儒.jpg",
            "id": 3910,
            "categories": [
              "person"
            ],
            "info": "贾代儒是贾府中“代”字辈的长辈，其人生有三大不幸：早年丧父，中年丧子，晚年丧孙。宝玉两次进塾就读，八股文也是跟他学的。他儿子、儿媳均早亡，故担负起对孙子的教养责任。他对贾瑞管教甚严，一旦贾瑞在外擅自过夜，他必定重罚。"
          },
          {
            "label": "赖大",
            "value": 4,
            "image": "./images/photo/赖大.jpg",
            "id": 3911,
            "categories": [
              "person"
            ],
            "info": "赖嬷嬷之子，赖尚荣之父。是“熬了两三辈子，好容易挣出”来的“家生子儿”，因为赖嬷嬷“年高服侍过”贾府的老主子，又得到贾母的“赏脸”，他才做了荣府大总管。"
          },
          {
            "label": "赵国基",
            "value": 1,
            "id": 3912,
            "categories": [
              "person"
            ],
            "info": "赵姨娘弟弟，荣府男仆。曾随侍贾环上学。赵国基死时，正值探春理家，为了发送赏银的多寡，赵姨娘与探春曾翻脸大闹。"
          },
          {
            "label": "吴贵",
            "value": 3,
            "id": 3913,
            "categories": [
              "person"
            ],
            "info": "晴雯的姑舅哥哥，老实胆小，人都叫他贵儿。"
          },
          {
            "label": "花自芳",
            "value": 1,
            "image": "./images/photo/花自芳.jpg",
            "id": 3914,
            "categories": [
              "person"
            ],
            "info": "怡红院大丫环花袭人之兄。 因生活困顿，与父母一道将袭人卖与贾府。父亲死后，情况渐好，遂又和母亲商议，要赎出袭人。宝玉私访花家，自芳惶恐相见，殷勤款待，曲尽奴才之意。一二〇回写到其写到其妻及亲戚们做媒，将袭人嫁与蒋玉菡。"
          },
          {
            "label": "王成之父",
            "value": 1,
            "id": 3915,
            "categories": [
              "person"
            ],
            "info": "王成之父昔年与凤姐之祖王夫人之父认识。因贪王家的势利，便连了宗认作侄儿。"
          },
          {
            "label": "王成",
            "value": 3,
            "id": 3916,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的亲家，王狗儿之父。因家业萧条，合家搬往城外乡下居住，在刘姥姥初进大观园前不久病故。"
          },
          {
            "label": "狗儿",
            "value": 6,
            "id": 3917,
            "categories": [
              "person"
            ],
            "info": "王成之子，刘姥姥女婿。祖上曾作小京官，周瑞昔年为争买田地事，曾得他一臂之力。后家道中落，便务农为业。他在穷愁困顿中听到刘姥姥去贾府走动的建议，赶紧怂恿刘姥姥启身前往。"
          },
          {
            "label": "周瑞",
            "value": 4,
            "image": "./images/photo/周瑞.jpg",
            "id": 3918,
            "categories": [
              "person"
            ],
            "info": "冷子兴岳丈。荣府的男管家。"
          },
          {
            "label": "林之孝",
            "value": 3,
            "image": "./images/photo/林之孝.jpg",
            "id": 3919,
            "categories": [
              "person"
            ],
            "info": "荣国府管家，负责管理银库账房的，为人处世十分低调。"
          },
          {
            "label": "鲍二",
            "value": 2,
            "id": 3920,
            "categories": [
              "person"
            ],
            "info": "贾府男仆。"
          },
          {
            "label": "金文翔",
            "value": 3,
            "id": 3921,
            "categories": [
              "person"
            ],
            "info": "鸳鸯的哥哥。他是贾母房里的买办。"
          },
          {
            "label": "香怜",
            "value": 2,
            "id": 3922,
            "categories": [
              "person"
            ],
            "info": "贾家义学中的小学生。"
          },
          {
            "label": "贾菌",
            "value": 5,
            "id": 3923,
            "categories": [
              "person"
            ],
            "info": "荣府近派的重孙，娄氏之子。少孤，在家塾中读书时与贾兰最好，年纪虽小，志气却大，极是淘气不怕人的，在顽童闹学时，他不肯吃亏，十分顽皮。"
          },
          {
            "label": "贾蓝",
            "value": 4,
            "id": 3924,
            "categories": [
              "person"
            ],
            "info": "贾蓝在《红楼梦》中只是一个符号式的人物，是个“牛心左性”的孩童而已。"
          },
          {
            "label": "金荣",
            "value": 3,
            "id": 3925,
            "categories": [
              "person"
            ],
            "info": "贾璜妻子的娘家侄子。"
          },
          {
            "label": "茗烟",
            "value": 5,
            "image": "./images/photo/茗烟.jpg",
            "id": 3926,
            "categories": [
              "person"
            ],
            "info": "贾宝玉最得力的书僮和小厮就叫焙茗。 一般认为焙茗即茗烟。但早期版本的《红楼梦》中并未交代改名事宜。 程本及后来的通行本在二十四回中有交代： 只见茗烟在那里掏小雀儿呢。贾芸在他身后，把脚一跺，道：“茗烟小猴儿又淘气了！”茗烟回头，见是贾芸，便笑道：“何苦二爷唬我们这么一跳。”因又笑说：“我不叫‘茗烟’了，我们宝二爷嫌‘烟’字不好，改了叫‘焙茗’了。二爷明儿只叫我焙茗罢。”贾芸点头笑着同进书房，便坐下问：“宝二爷下来了没有？”"
          },
          {
            "label": "潘又安",
            "value": 2,
            "image": "./images/photo/潘又安.jpg",
            "id": 3927,
            "categories": [
              "person"
            ],
            "info": "贾府小厮，司棋青梅竹马的姑舅表弟。他性格懦弱，胆小怕事，跟司棋在大观园幽会被鸳鸯撞见，畏罪远遁。他发财回家，意欲迎娶司棋，无奈司母不从，司棋撞墙身亡，他也殉情自尽。潘又安在《红楼梦》中正面出场仅仅两次。第一次是在《红楼梦》第七十一回，潘又安与女友司棋在花光柳影，诗意绵绵的大观园中约会。不巧被鸳鸯撞见，潘又安迅速藏在树后。司棋见到鸳鸯以后，便叫潘又安出来，潘又安只得从树后爬出来，如捣蒜似的向贾母大丫头鸳鸯姐姐磕头。这就是他的出场，只有一系列动作，没有外貌的刻画，没有声音的流露。"
          },
          {
            "label": "贾芹",
            "value": 2,
            "image": "./images/photo/贾芹.jpg",
            "id": 3929,
            "categories": [
              "person"
            ],
            "info": "贾府小和尚小道士的总管。他在家庙里，为王称霸，夜夜聚赌。有人匿名写了“西贝草斤年纪轻，水月庵里管尼僧。一个男人多少女，窝娼聚赌是陶情。不肖子弟来办事，荣国府内好声名。”的帖儿贴在荣府大门口。贾政见后，革去了他的总管一职。"
          },
          {
            "label": "玉爱",
            "value": 1,
            "id": 3930,
            "categories": [
              "person"
            ],
            "info": "贾家义学中的小学生。未考真姓名，只因生得妩媚风流，满学中才给他起了这个外号。"
          },
          {
            "label": "来升",
            "value": 3,
            "id": 3931,
            "categories": [
              "person"
            ],
            "info": "首见第十回《金寡妇贪利权受辱 张太医论病细穷源》。"
          },
          {
            "label": "俞禄",
            "value": 3,
            "id": 3932,
            "categories": [
              "person"
            ],
            "info": "俞禄是曹雪芹小说《红楼梦》中的人物，一个小管家。"
          },
          {
            "label": "杏奴",
            "value": 1,
            "id": 3933,
            "categories": [
              "person"
            ],
            "info": "柳湘莲的小厮。"
          },
          {
            "label": "赖尚荣",
            "value": 3,
            "image": "./images/photo/赖尚荣.jpg",
            "id": 3934,
            "categories": [
              "person"
            ],
            "info": "赖嬷嬷的孙子。赖家本是贾家的家生奴仆，但赖尚荣一落娘胎，就被主子放了出来，成为自由人。"
          },
          {
            "label": "金彩",
            "value": 2,
            "id": 3935,
            "categories": [
              "person"
            ],
            "info": "鸳鸯、金文翔之父，出生于1841年。（见《红楼梦》第四十六回）贾府老仆，与老妻两口在南京看房子。"
          },
          {
            "label": "邢德全",
            "value": 1,
            "id": 3936,
            "categories": [
              "person"
            ],
            "info": "邢夫人胞弟，邢岫烟之叔。"
          },
          {
            "label": "隆儿",
            "value": 1,
            "id": 3937,
            "categories": [
              "person"
            ],
            "info": "贾琏的小厮。"
          },
          {
            "label": "兴儿",
            "value": 3,
            "id": 3938,
            "categories": [
              "person"
            ],
            "info": "贾琏的心腹小厮，他曾对尤二姐细说荣府成员。后王熙凤发觉贾琏偷娶一事，就拿他来盘问，要他自己打自己嘴巴。兴儿只得自己左右开弓，打了十几个嘴巴，并把贾琏偷娶尤二姐的经过一一告知。"
          },
          {
            "label": "王善保",
            "value": 2,
            "id": 3939,
            "categories": [
              "person"
            ],
            "info": "十二家人之一。 邢夫人的仆人。"
          },
          {
            "label": "冷子兴",
            "value": 5,
            "image": "./images/photo/冷子兴.jpg",
            "id": 3940,
            "categories": [
              "person"
            ],
            "info": "冷子兴是周瑞的女婿，都城中的古董商，和贾雨村是好朋友。他对贾雨村介绍荣国府，使贾家众人在他口中整体亮相。"
          },
          {
            "label": "卜世仁",
            "value": 1,
            "image": "./images/photo/卜世仁.jpg",
            "id": 3941,
            "categories": [
              "person"
            ],
            "info": "贾芸母舅，香料铺主人。"
          },
          {
            "label": "冯紫英",
            "value": 1,
            "image": "./images/photo/冯紫英.jpg",
            "id": 3942,
            "categories": [
              "person"
            ],
            "info": "冯紫英是神武将军冯唐之子，与贾府中贾宝玉、薛蟠等人都有来往。冯紫英也是令所有红学家都热衷的“神秘人物”。就在第26回《蜂腰桥设言传心事 潇湘馆春困发幽情》中，由于薛蟠庆生，宝玉等人都前来道贺，冯紫英也在其中。"
          },
          {
            "label": "凤姐之父",
            "value": 4,
            "id": 3943,
            "categories": [
              "person"
            ],
            "info": "凤姐之父。"
          },
          {
            "label": "史湘云之父",
            "value": 2,
            "id": 3944,
            "categories": [
              "person"
            ],
            "info": "史侯之子。"
          },
          {
            "label": "卫若兰",
            "value": 2,
            "image": "./images/photo/卫若兰.jpg",
            "id": 3945,
            "categories": [
              "person"
            ],
            "info": "在《红楼梦》中只出现过很少的几次，仅在秦氏出殡时出现了一次，书中点明他的身份是“王孙公子”。他之所以成为引人注目的人物，是由于脂批的特意提及。"
          },
          {
            "label": "梅翰林之子",
            "value": 2,
            "id": 3946,
            "categories": [
              "person"
            ],
            "info": "薛宝琴的丈夫。"
          },
          {
            "label": "吴兴登",
            "value": 2,
            "id": 3947,
            "categories": [
              "person"
            ],
            "info": "贾府家仆。"
          },
          {
            "label": "旺儿",
            "value": 5,
            "image": "./images/photo/旺儿.jpg",
            "id": 3948,
            "categories": [
              "person"
            ],
            "info": "旺儿，又叫来旺儿，是王熙凤的小厮。"
          },
          {
            "label": "封肃",
            "value": 2,
            "id": 3949,
            "categories": [
              "person"
            ],
            "info": "封肃是甄士隐的岳父，殷实的务农人家。"
          },
          {
            "label": "李纨之哥",
            "value": 2,
            "id": 3950,
            "categories": [
              "person"
            ],
            "info": "李纨之哥。"
          },
          {
            "label": "小沙弥",
            "value": 3,
            "id": 3951,
            "categories": [
              "person"
            ],
            "info": "贾雨村的门子，见风使舵，善于专营。"
          },
          {
            "label": "皇上",
            "value": 1,
            "id": 3952,
            "categories": [
              "person"
            ],
            "info": "皇上只是一个影射,没有具体的称谓。他只是起到猜谜时的隐意作用。"
          },
          {
            "label": "薛姨父",
            "value": 2,
            "id": 3953,
            "categories": [
              "person"
            ],
            "info": "薛蟠之父。"
          },
          {
            "label": "北静王",
            "value": 3,
            "image": "./images/photo/北静王.jpg",
            "id": 3954,
            "categories": [
              "person"
            ],
            "info": "北静王，名水溶，年未弱冠，形容秀美，性情谦和。因祖上与贾府有世交之谊，故从未以异姓相见，更不以王位自居。"
          },
          {
            "label": "应天府",
            "value": 2,
            "id": 3955,
            "categories": [
              "location"
            ],
            "info": "应天府是今天河南的商丘。而金陵是指南京，明时称应天。应天和应天府不是同一个地方。曹雪芹本来就是故意混淆了红楼的时间和地点。"
          },
          {
            "label": "喜儿",
            "value": 1,
            "id": 3956,
            "categories": [
              "person"
            ],
            "info": "贾珍的跟班。"
          },
          {
            "label": "寿儿",
            "value": 1,
            "id": 3957,
            "categories": [
              "person"
            ],
            "info": "贾宝玉的一个小厮。"
          },
          {
            "label": "钱槐",
            "value": 1,
            "id": 3958,
            "categories": [
              "person"
            ],
            "info": "荣府男仆，被派跟贾环上学。他是赵姨娘内侄，父母在库上管帐。他看上柳五儿标致，就倚势求亲，怎耐五儿执意不从，他“心中又气又愧，发恨定要弄取成配，方了此愿”。"
          },
          {
            "label": "乌进孝",
            "value": 1,
            "image": "./images/photo/乌进孝.jpg",
            "id": 3959,
            "categories": [
              "person"
            ],
            "info": "宁府黑山村庄头。书中写他岁末赶了一个多月路程进京交租，向贾珍诉苦说：“年成实在不好。”贾珍看了乌进孝送上了的租单，不满地说：“真真是又教别过年了。”又议论到荣府这几年添了许多花销的事，又不能添些银钞产业，倒赔了许多，“不和你们要，找谁去！”现在过的日子真是“黄杨木作磬槌子——外头体面里头苦”。"
          },
          {
            "label": "南安郡王",
            "value": 3,
            "id": 3960,
            "categories": [
              "person"
            ],
            "info": "南安郡王是一位王爷。《红楼梦》里后来写贾府为秦可卿大办丧事，来了四家王爷参与祭奠，他们是东平郡王、南安郡王、西宁郡王和北静郡王。"
          },
          {
            "label": "冯渊",
            "value": 2,
            "id": 3961,
            "categories": [
              "person"
            ],
            "info": "冯渊，谐音“逢冤”，意指他逢受冤屈。金陵省一个小乡绅之子。自幼父母双亡，又无兄弟，只他一个人守着些薄产度日。"
          },
          {
            "label": "李守中",
            "value": 2,
            "id": 3962,
            "categories": [
              "person"
            ],
            "info": "李纨之父，曾为国子祭酒。"
          },
          {
            "label": "单聘仁",
            "value": 1,
            "id": 3963,
            "categories": [
              "person"
            ],
            "info": "单聘仁为贾政门下清客相公，对宝玉阿谀奉承。脂批以此名寓“善与骗人之意”。为迎元妃归省，他曾随贾蔷下姑苏聘请教习、采买女孩子、置办乐器行头等事。"
          },
          {
            "label": "程日兴",
            "value": 1,
            "id": 3964,
            "categories": [
              "person"
            ],
            "info": "程日兴，贾政门下清客相公，亦在古董行做事，宝玉说画美人是他的绝技。"
          },
          {
            "label": "詹光",
            "value": 1,
            "id": 3965,
            "categories": [
              "person"
            ],
            "info": "詹光字子亮，为贾政门下清客相公。善绘工细楼台，兴建大观园时，他也参加规划设计；为大观园题写对联匾额时，他也参加讨论。平时则陪贾政下棋聊天，见到宝玉百般奉承，极尽丑态。贾府失势，人也就辞去了。脂砚斋说作者给清客们起名多有调侃之意，詹光“盖沾光之意”（甲戌本）也。"
          },
          {
            "label": "昭儿",
            "value": 2,
            "id": 3967,
            "categories": [
              "person"
            ],
            "info": "贾琏心腹小厮。曾随贾琏送黛玉回扬州探林如海，如海殁，他即赶回京城报丧取衣。"
          },
          {
            "label": "癞头和尚",
            "value": 3,
            "image": "./images/photo/癞头和尚.jpg",
            "id": 3968,
            "categories": [
              "person"
            ],
            "info": "癞头和尚和跛足道人的戏份不多但地位颇重。作为外表“癞头跣脚”的神异人物，癞头和尚和跛足道人虽然其貌不扬，但却是小说中实在在的“神仙”，更是被曹雪芹寓以了既能令人富贵、又能一朝回贫的深刻含义。"
          },
          {
            "label": "跛足道人",
            "value": 4,
            "image": "./images/photo/跛足道人.jpg",
            "id": 3969,
            "categories": [
              "person"
            ],
            "info": "跛足道人，他看到了一个欲望无穷、沽名钓誉、巧取豪夺的泥浊世界，“世人都晓神仙好，惟有功名忘不了”，“世人都说神仙好，只有金银忘不了”，他所唱的《好了歌》，正是荒诞歌。甄士隐为《好了歌》作了注解。他的解，是对泥浊世界和荒诞人生的“解构”。原来，这个金玉其外的人群舞台，演的不过是一幕幕荒诞戏剧：“乱哄哄你方唱罢我登场，反认他乡是故乡。甚荒唐，到头来都是为他人作嫁衣裳。”（跛足道人别称应为渺渺真人，而空空道人则为书中另一形象。）"
          },
          {
            "label": "霍启",
            "value": 3,
            "id": 3970,
            "categories": [
              "person"
            ],
            "info": "甄士隐的家奴。谐音“祸起”。因为弄丢了甄士隐年方三岁的爱女甄英莲，便逃往他乡去了。"
          },
          {
            "label": "旺儿之子",
            "value": 5,
            "id": 3971,
            "categories": [
              "person"
            ],
            "info": "旺儿之子。"
          },
          {
            "label": "神瑛侍者",
            "value": 2,
            "id": 3972,
            "categories": [
              "person"
            ],
            "info": "神瑛侍者为贾宝玉的前身，他对三生石畔的绛珠仙草曾有灌溉之恩。贾宝玉身上的通灵宝玉是由顽石变化而成的。后在”绛珠“”泪尽夭折“后，经历了与宝钗”恩爱夫妻不到冬“、贾府的”忽喇喇似大厦倾“、女儿们的：“三春去后诸芳尽”等等人间不适后，心灰意冷地“悬崖散手”，与“绛珠”“证了前缘”。"
          },
          {
            "label": "林黛玉",
            "value": 21,
            "image": "./images/photo/林黛玉.jpg",
            "id": 4037,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之冠（与宝钗并列）。林如海与贾敏之女，宝玉的姑表妹，寄居荣国府 。她生性孤傲，多愁善感，才思敏捷。她与宝玉真心相爱，是宝玉反抗封建礼教的同盟，是自由恋爱的坚定追求者。"
          },
          {
            "label": "薛宝钗",
            "value": 17,
            "image": "./images/photo/薛宝钗.jpg",
            "id": 4038,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之冠（与黛玉并列），来自四大家族之薛家，薛姨妈之女。她大方典雅，举止雍容。她对官场黑暗深恶痛绝，但仍规谏宝玉读书做官。有一个金锁，与贾宝玉的通灵宝玉被外人称为金玉良缘。"
          },
          {
            "label": "贾巧姐",
            "value": 7,
            "image": "./images/photo/贾巧姐.jpg",
            "id": 4039,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十，贾琏与王熙凤的女儿。因生在七月初七，刘姥姥给她取名为“巧姐”。在贾府败落后，王仁和贾环要把她卖与藩王作使女。在紧急关头，幸亏刘姥姥相救，把她带去乡下。"
          },
          {
            "label": "贾惜春",
            "value": 8,
            "image": "./images/photo/贾惜春.jpg",
            "id": 4040,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之八，宁国府贾珍的妹妹，贾府四小姐，爱好绘画。因父亲贾敬一味好道炼丹，母亲又早逝，她一直在荣国府贾母身边长大。由于没有父母疼爱，养成了孤僻冷漠的性格，抄检大观园时，她狠心撵走丫环入画。四大家族的没落，三个姐姐的结局，使她产生了弃世的念头，看破红尘出家为尼。"
          },
          {
            "label": "王熙凤",
            "value": 25,
            "image": "./images/photo/王熙凤.jpg",
            "id": 4041,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之九，来自四大家族之王家，王夫人的内侄女，贾琏之妻。她精明强干，深得贾母和王夫人的信任，成为荣国府的管家奶奶，她为人处事圆滑周到，图财害命的事也干过不少，在前80回里她支持宝黛爱情。"
          },
          {
            "label": "贾迎春",
            "value": 10,
            "image": "./images/photo/贾迎春.jpg",
            "id": 4042,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之七，是贾赦与妾所生，贾府二小姐。她老实无能，懦弱怕事，有“二木头”的诨名。她不但作诗猜谜不如姐妹们，在处世为人上，也只知退让，任人欺侮。贾赦欠了孙家五千两银子还不出，就把她嫁给孙家，原著前80回未交代结局，根据金陵十二钗判词，最后被丈夫孙绍祖虐待致死。"
          },
          {
            "label": "李纨",
            "value": 12,
            "image": "./images/photo/李纨.jpg",
            "id": 4043,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十一，贾珠遗孀，生子贾兰。李纨虽处于膏粱锦绣之中，竟如“槁木死灰”一般，一概不闻不问，只知道抚养亲子，闲时陪侍小姑等女红、诵读而已  。她是个恪守封建礼法的节妇的典型。"
          },
          {
            "label": "贾元春",
            "value": 9,
            "image": "./images/photo/贾元春.jpg",
            "id": 4044,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之三，贾政与王夫人之长女，贾府大小姐。因贤孝才德，选入宫作女史。秦可卿出殡不久，元春晋封贵妃  。贾府为了迎接她省亲，建造了大观园。她给家族带来了“烈火烹油，鲜花著锦之盛”，但自己幽闭深宫，不能尽天伦之乐。"
          },
          {
            "label": "贾探春",
            "value": 14,
            "image": "./images/photo/贾探春.jpg",
            "id": 4045,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之四，贾政与赵姨娘所生，贾府三小姐。她精明能干，个性刚烈，有“玫瑰花”之诨名。抄检大观园时，她当众扇了王善保家的一巴掌。她对贾府的危局颇有感触，用兴利除弊的改革来挽救。改革虽成功，但无济大事。"
          },
          {
            "label": "邢夫人",
            "value": 12,
            "image": "./images/photo/邢夫人.jpg",
            "id": 4046,
            "categories": [
              "person"
            ],
            "info": "贾赦之妻子，无儿无女，地位尴尬，比不上妯娌王夫人。她禀性愚犟，只知奉承贾赦，家中小老婆一堆 ，一应大小事务，俱由贾赦摆布。出入银钱，一经她手，便克扣异常，婪取财货。儿女奴仆，一人不靠，一言不听，故甚不得人心。"
          },
          {
            "label": "赵姨娘",
            "value": 11,
            "image": "./images/photo/赵姨娘.jpg",
            "id": 4047,
            "categories": [
              "person"
            ],
            "info": "赵姨娘是荣国府二老爷贾政之妾，环三爷贾环和三姑娘贾探春之生母。也许，赵姨娘年轻时候是漂亮的，看探春的容貌大概可以略知一二。于是，赵姨娘成了贾政的妾，这是她幸运的一步，却也是不幸的开始。"
          },
          {
            "label": "贾敏",
            "value": 10,
            "id": 4048,
            "categories": [
              "person"
            ],
            "info": "贾敏既是贾代善与史太君之女，也是林黛玉之母。风华绝代，才情不凡，在《红楼梦》这本名著中作为已故人物出场。贾母最疼爱的小女儿，贾敏在未嫁之时受到贾母的呵护令王夫人刻骨铭心。嫁与巡盐御史林如海为妻。先育有一子，早夭，后生下林黛玉。早逝。"
          },
          {
            "label": "贾母",
            "value": 14,
            "image": "./images/photo/贾母.jpg",
            "id": 4049,
            "categories": [
              "person"
            ],
            "info": "来自四大家族之史家，贾府老太太，宝玉祖母 。在贾家从重孙媳妇做起，一直到有了重孙媳妇。她凭着自己的精明能干，才坐稳了贾家大家长的位置。"
          },
          {
            "label": "妙玉",
            "value": 3,
            "image": "./images/photo/妙玉.jpg",
            "id": 4050,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之六，苏州人氏。祖上是读书仕宦人家，因自幼多病，买了许多替身皆不中用，只得入了空门，带发修行。父母亡故后，她随师父进京。师父圆寂后，王夫人赏识她的佛学修为，请她入住大观园栊翠庵，原著前80回未交代结局。"
          },
          {
            "label": "王夫人",
            "value": 21,
            "image": "./images/photo/王夫人.jpg",
            "id": 4051,
            "categories": [
              "person"
            ],
            "info": "王夫人是故事主角贾宝玉和十二钗之一的皇妃贾元春之母，贾政之妻，是荣国府掌权管事的家长之一。她性格沉闷守旧，贾母形容她老实，又多病多痛。"
          },
          {
            "label": "史湘云",
            "value": 12,
            "image": "./images/photo/史湘云.jpg",
            "id": 4052,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之五，来自四大家族之史家，是贾母的侄孙女。自幼父母双亡，在家一点儿也作不得主，不时还要三更半夜做针线活儿。她心直口快，开朗豪爽，心怀坦荡，从未把儿女私情略萦心上。"
          },
          {
            "label": "秦可卿",
            "value": 13,
            "image": "./images/photo/秦可卿.jpg",
            "id": 4053,
            "categories": [
              "person"
            ],
            "info": "金陵十二钗之十二，宁国府贾蓉之妻。她是营缮司郎中秦邦业从养生堂抱养的女儿，小名可儿，大名兼美。她长得袅娜纤巧，性格风流，行事又温柔和平，深得贾母等人的欢心  。但公公贾珍与她关系暧昧，致使其年轻早夭。"
          },
          {
            "label": "晴雯 ",
            "value": 9,
            "id": 4054,
            "categories": [
              "person"
            ],
            "info": "中国古典小说《红楼梦》中人物，金陵十二钗又副册之首，贾宝玉房里的四个大丫鬟之一，虽是丫鬟，但在宝玉房里过得千金小姐的生活。红学中普遍评价她有林黛玉之风。她长得风流灵巧，眉眼儿有点像林黛玉，口齿伶俐，针线活尤好，曾为宝玉病补雀金裘。 她本是赖妈妈家的丫鬟，因得贾母喜爱，固把她赐给宝玉。因娇生惯养，养成得理不饶人的性格。一次王夫人进园子看见她打骂小丫鬟，觉得她不合世家教养，再加上王善保家的等小人进谗，王夫人在她病得“四五日水米不曾沾牙”的情况下，从炕上拉下来，硬给撵了出去（放出去）。宝玉偷偷前去探望，晴雯深为感动。当夜，晴雯悲惨地死去。"
          },
          {
            "label": "袭人",
            "value": 8,
            "image": "./images/photo/袭人.jpg",
            "id": 4055,
            "categories": [
              "person"
            ],
            "info": "袭人原名珍珠，花姓，金陵十二钗又副册第二位，宝玉房里四个大丫鬟之首。从小因家贫被父母卖入贾府为婢，原是跟着贾母，起先服侍史湘云几年，贾母见喜袭人心地纯良，恪尽职守，便命她服侍宝玉。她对人和气，处事稳重，工作认真，得到大观园中上下人等的夸奖，是出了名的贤女。"
          },
          {
            "label": "平儿",
            "value": 7,
            "image": "./images/photo/平儿.jpg",
            "id": 4056,
            "categories": [
              "person"
            ],
            "info": "王熙凤的陪嫁丫头，贾琏的通房大丫头。她是个极聪明、极清俊的女孩儿。虽是凤姐的心腹，要帮着凤姐料理事务，但她为人很好，心地善良，平儿从不弄权仗势欺人，本能地同情那些和她地位相仿或更低的奴隶们，常背着王熙凤做些好事。"
          },
          {
            "label": "鸳鸯",
            "value": 5,
            "image": "./images/photo/鸳鸯.jpg",
            "id": 4057,
            "categories": [
              "person"
            ],
            "info": "随父姓金，是贾母的大丫头，甚受信任，因为这个缘故，她在贾府的丫头中有很高的地位。贾母平日倚之若左右手。贾母玩牌，她坐在旁边出主意；贾母摆宴，她入座充当令官。她自重自爱，从不以此自傲，仗势欺人，因此深得上下各色人等的好感和尊重。"
          },
          {
            "label": "紫鹃",
            "value": 6,
            "image": "./images/photo/紫鹃.jpg",
            "id": 4058,
            "categories": [
              "person"
            ],
            "info": "紫鹃原名鹦哥，是贾母房里的二等小丫头。贾母见林黛玉来时只带了两个人，恐不中使，便把鹦哥给了黛玉，改名为紫鹃。她聪明灵慧，和黛玉关系很好，情同姐妹，一时一刻不离开，是黛玉的“闺中贵友”，一心一意为黛玉着想，见黛玉是个孤儿，又和宝玉相爱，就劝黛玉趁老太太还硬朗的时候作定大事要紧。为此，她想出林家要接黛玉回苏州的话来试宝玉，使宝玉痴病大发。黛玉死后，随黛玉灵柩回苏州，为黛玉守灵。"
          },
          {
            "label": "莺儿",
            "value": 3,
            "image": "./images/photo/莺儿.jpg",
            "id": 4059,
            "categories": [
              "person"
            ],
            "info": "莺儿，本名黄金莺，薛宝钗的丫头。因薛宝钗嫌金莺拗口，改叫莺儿。她甚是乖巧，薛宝钗在观看通灵宝玉，念著玉上所镌之文“莫失莫忘，仙寿恒昌”时，她马上想到这和小姐项圈上的两句话是一对儿。她手特巧，擅长打络子、编花篮等，还颇懂色彩的搭配。薛宝钗嫁给宝玉后，她就成了薛宝钗的陪房丫头。"
          },
          {
            "label": "玉钏",
            "value": 3,
            "image": "./images/photo/玉钏.jpg",
            "id": 4060,
            "categories": [
              "person"
            ],
            "info": "姓白，名玉钏，金钏的妹妹，王夫人的丫头。金钏投井后，王夫人便把金钏的月钱给了玉钏。金钏周年，玉钏独坐廊下垂泪，可见底层人民的骨肉之情。玉钏应当是一个沉默的小姑娘，一直隐在姐姐身后。"
          },
          {
            "label": "金钏",
            "value": 4,
            "image": "./images/photo/金钏.jpg",
            "id": 4061,
            "categories": [
              "person"
            ],
            "info": "王夫人房中的丫鬟。本姓白。有一个妹妹，叫做“玉钏”，同是王夫人房中丫头。后跳井自杀。金钏给人以不谙世事，天真烂漫的印象。投井自杀的结局也就更点明其人刚烈、其质清洁。"
          },
          {
            "label": "彩云",
            "value": 2,
            "image": "./images/photo/彩云.jpg",
            "id": 4062,
            "categories": [
              "person"
            ],
            "info": "彩云是一个与贾环玩得很要好的丫头。贾环曾为她从芳官处讨些蔷薇硝来，彩云打开一看才知是茉莉粉，赵姨娘非要找芳官算帐，彩云死劝不住，只得躲入房内。后来，她禁不住赵姨娘的再三央求，从王夫人柜子里偷了些东西给贾环，被玉钏儿发觉。平儿为了不使探春难堪，要宝玉应下拿东西的名儿，彩云回来告诉赵姨娘。贾环起了疑心，认为她和宝玉好，并把彩云私赠之物都拿出来，照著她的脸摔了过去。彩云急得赌咒起誓，百般解说。贾环还是不信，气得彩云哭了个泪乾肠断，把东西全扔到河里了。后来她染了无医之症。"
          },
          {
            "label": "司棋",
            "value": 7,
            "image": "./images/photo/司棋.jpg",
            "id": 4063,
            "categories": [
              "person"
            ],
            "info": "贾迎春的丫头。脾气刚烈，雷厉风行。排挤柳家的未果，后与潘又安私通，被撵出大观园。最终与潘又安双双殉情而死。“品貌风流”，“高大丰壮”，做事干脆利落，有“侠女”之风。与其主子迎春“二木头”的性格形成了很大的反差。"
          },
          {
            "label": "芳官",
            "value": 2,
            "image": "./images/photo/芳官.jpg",
            "id": 4064,
            "categories": [
              "person"
            ],
            "info": "贾府买来的戏班成员，原姓花，姑苏人氏，正旦。戏班解散后成了贾宝玉的丫环，后来由于旁人诬陷，王夫人把她撵了出去，让她干娘在府外给她找个女婿，芳官不甘心再被干娘买卖，便跟水月庵的智通出家去了。芳官的男子气概在“洗头事件”和“蔷薇硝事件”中展现得淋漓尽致。芳官在群芳夜宴中唱《赏花时》意蕴深厚，为后来的故事埋下诸多伏笔。芳官很聪明伶俐，善解人意，脾气倔强。"
          },
          {
            "label": "麝月",
            "value": 4,
            "image": "./images/photo/麝月.jpg",
            "id": 4065,
            "categories": [
              "person"
            ],
            "info": "麝月是主人公贾宝玉身边一等丫鬟。怡红院里的四大丫鬟之一，她在书中的出场并不多，但是一个不可缺少的角色，因为她是唯一一个见证了贾府的走向衰败的全程、目睹了女儿的薄命的命运。在高鹗续书的后40回，是宝玉宝钗落魄后依然还在身边服侍的重要人物，故为：”开到荼蘼花事了“，但是即便是这样宝玉依然离开二人，出家为僧。麝月忠厚老实，心地善良。在怡红院几个大丫头中，她受袭人“陶冶教育”影响最深。"
          },
          {
            "label": "香菱",
            "value": 9,
            "image": "./images/photo/香菱.jpg",
            "id": 4066,
            "categories": [
              "person"
            ],
            "info": "本名甄英莲，原籍姑苏，甄士隐独女，眉心有米粒大小的一点胭脂记。金陵十二钗副册女儿，贾府通称为香菱。四岁那年元宵，在看社火花灯时因家奴霍启看护不当而被拐子拐走。养大后原是卖给金陵公子冯渊，中途却被薛蟠抢回去做小妾，宝钗给她起名叫香菱。后四十回夏金桂下毒害她，结果却阴差阳错毒死了自己。薛蟠出狱后，香菱扶正，因难产而死，甄士隐亲自接她归入太虚幻境。"
          },
          {
            "label": "抱琴",
            "value": 1,
            "id": 4067,
            "categories": [
              "person"
            ],
            "info": "抱琴是贾门大姐大贾元春的丫头，她是随元春进宫的唯一有名字的侍女。贾元春比另三个小姐的年纪要大出一块，可以说抱琴应该是四个丫头中年纪最大的。"
          },
          {
            "label": "宝蟾",
            "value": 4,
            "image": "./images/photo/宝蟾.jpg",
            "id": 4068,
            "categories": [
              "person"
            ],
            "info": "宝蟾是夏金桂的陪房丫头。长有三分姿色，举止轻浮。她与夏金桂这一对主仆是曹雪芹最后推出的一组人物，两个可悲可怜可恨的女人。宝蟾的名字与夏金桂颇有关合，桂蟾相合则是月亮的别称，蟾宫有桂，桂宫有蟾，形影相随。宝蟾虽是夏金桂陪房，却与主子各怀鬼胎，时而分庭抗礼，时而又狼狈为奸，以害人始害己终。"
          },
          {
            "label": "雪燕",
            "value": 2,
            "id": 4069,
            "categories": [
              "person"
            ],
            "info": "雪雁是中国古典小说《红楼梦》中的人物，雪雁是林黛玉从苏州家里带来的小丫头，为林黛玉的贴身丫头之一。 雪雁年龄虽小，却是个聪慧之人。虽无害人之心，却有防人之意。"
          },
          {
            "label": "春纤",
            "value": 1,
            "id": 4070,
            "categories": [
              "person"
            ],
            "info": "春纤，首见原著第二十九回，林黛玉丫环。书中出现过两次：一是随黛玉到清虚观打醮；一是晴雯受贾宝玉之托给黛玉送手帕时，见到她正往栏杠上晾手帕。"
          },
          {
            "label": "文杏",
            "value": 1,
            "id": 4071,
            "categories": [
              "person"
            ],
            "info": "薛宝钗丫环。薛姨妈曾说：“文杏又小，道三不着两。”"
          },
          {
            "label": "碧月",
            "value": 3,
            "id": 4072,
            "categories": [
              "person"
            ],
            "info": "李纨的丫头。一次她到怡红院找手帕，恰逢宝玉同麝月、晴雯、芳官笑闹不休。她很羡慕，宝玉问她：你们为什么不顽呢？碧月就说：“我们奶奶不顽，就把两个姨娘和琴姑娘也‘宾’住了。”"
          },
          {
            "label": "琥珀",
            "value": 1,
            "id": 4073,
            "categories": [
              "person"
            ],
            "info": "贾母身边的大丫鬟，照顾贾母起居生活，也负责传话、取物等各色杂事。"
          },
          {
            "label": "翠墨",
            "value": 1,
            "id": 4074,
            "categories": [
              "person"
            ],
            "info": "贾探春的贴身丫鬟。翠墨和探春的另一个丫鬟侍书（待书）的名字拼在一起有“书墨”二字，以示贾探春之才。"
          },
          {
            "label": "薛姨妈",
            "value": 10,
            "image": "./images/photo/薛姨妈.jpg",
            "id": 4075,
            "categories": [
              "person"
            ],
            "info": "薛蟠和薛宝钗之母，王夫人之妹。丧夫后携带一双儿女寄居贾府的梨香院，谐音“离乡怨”，后因元妃省亲，贾府采买的小戏子们入住院中学戏，薛姨妈等又迁住于园中东北上一处幽静住所。她以“慈”著称，由于举止言行得体，贾母常愿与她对坐聊天。她十分溺爱自己的子女，尤其怜惜薛蟠独根孤种，未免溺爱纵容，遂至老大无成，任薛蟠在外为所欲为。"
          },
          {
            "label": "夏金桂",
            "value": 7,
            "image": "./images/photo/夏金桂.jpg",
            "id": 4076,
            "categories": [
              "person"
            ],
            "info": "薛蟠的妻子。她家和薛家一样，同在户部挂名行商，也就是皇商，也是数一数二的大门户。家中只有一个寡母带着夏金桂，与薛蟠一样，被寡母纵容教养长大，她爱自己尊若菩萨，窥他人秽如粪土，外具花柳之姿，内秉风雷之性，嫁到薛家，使得薛家上下吃尽了苦头。夏金桂是个泼辣、善妒、狠毒的形象，同时也是“薄命司”中的一员，也是个值得我们同情和嗟伤的女子。"
          },
          {
            "label": "薛宝琴",
            "value": 6,
            "image": "./images/photo/薛宝琴.jpg",
            "id": 4077,
            "categories": [
              "person"
            ],
            "info": "皇商之女，小时跟父亲跑过不少地方。她是薛姨妈的侄女，薛蝌的胞妹，薛蟠，薛宝钗的堂妹。她长得十分美貌，贾母甚是喜爱，夸她比画上的还好看，曾欲把她说给贾宝玉为妻。王夫人也认她为干女儿。她自幼读书识字，本性聪敏，在大观园里曾作《怀古绝句十首》。后嫁都中梅翰林之子。她是一位近乎完美的人。她的美艳与纯真和邢岫烟的内敛与清高、李纹、李绮的超脱与淡然截然不同，十分耀眼。主要成就为作怀古诗10首。本性聪敏，才华横溢，性格开朗热情、活泼爱动。"
          },
          {
            "label": "邢岫烟",
            "value": 6,
            "image": "./images/photo/邢岫烟.jpg",
            "id": 4078,
            "categories": [
              "person"
            ],
            "info": "邢岫烟是邢忠夫妇的女儿，邢夫人的侄女。她家道贫寒，一家人前来投奔邢夫人，就在大观园迎春的住处紫菱洲住下。邢夫人对邢岫烟并不真心疼爱，只不过为了脸面之情。邢夫人甚至要求邢岫烟把每月二两银子的月钱省下一两来给她自己的父母，使得邢岫烟只得典当衣服来维持她在大观园的开支。邢岫烟生得端雅稳重，知书达礼，被薛姨妈看中，央求贾母作媒说与薛蝌，后嫁给薛蝌。"
          },
          {
            "label": "尤氏",
            "value": 9,
            "image": "./images/photo/尤氏.jpg",
            "id": 4079,
            "categories": [
              "person"
            ],
            "info": "别名大奶奶、珍大奶奶、珍哥媳妇，是曹雪芹小说《红楼梦》中的人物，贾珍的继室。虽为宁国府当家奶奶，但并无实权，素日只是顺从贾珍。她没什么才干，也没口齿。"
          },
          {
            "label": "尤二姐",
            "value": 7,
            "image": "./images/photo/尤二姐.jpg",
            "id": 4080,
            "categories": [
              "person"
            ],
            "info": "贾琏的二房，是贾珍夫人尤氏的继母带来的女儿。尤二姐模样标致，温柔和顺。贾珍馋涎妻妹的美貌，对尤二姐无微不至，当他玩腻后，就把她让给了贾琏。贾琏因惧怕王熙凤的淫威，只得偷偷娶尤二姐为二房，并把她安置在荣国府外，但不久被王熙凤发现，在她的借刀杀人计谋下，尤二姐备受折磨，当胎儿被庸医打下后，她绝望地吞金自尽。"
          },
          {
            "label": "尤三姐",
            "value": 5,
            "image": "./images/photo/尤三姐.jpg",
            "id": 4081,
            "categories": [
              "person"
            ],
            "info": "尤氏继母带来的女儿，尤二姐的妹妹，亦称作尤小妹。尤三姐模样儿风流标致，她又偏爱打扮得出色，自有一种万人不及的风情体态。贾珍、贾琏、贾蓉等好色之徒，对她颇为馋涎。但尤三姐不愿像姐姐那样遭人玩弄，她用泼辣作为武器，捍卫自己的清白。她看中柳湘莲后，就一心一意等他。但因柳湘莲认为宁国府多好色之徒（贾珍贾蓉之流），怀疑尤三姐也是个不干净之人，要索回定礼，刚烈的尤三姐在奉还定礼时自杀。尤三姐个性泼辣刚烈，不像姐姐尤二姐般任人摆布。"
          },
          {
            "label": "刘姥姥",
            "value": 4,
            "image": "./images/photo/刘姥姥.jpg",
            "id": 4082,
            "categories": [
              "person"
            ],
            "info": "刘姥姥是一位来自乡下贫农家庭的谙于世故的老婆婆，凤姐女儿巧姐的命运与她密切相关，巧姐判词和《留余庆》曲中均提及刘姥姥。这个老婆婆深受广大读者喜爱。她幽默风趣，精明强干，机智过人，她敢做敢为，有计有谋有办法，毫不顾虑拯救巧姐。"
          },
          {
            "label": "刘氏",
            "value": 5,
            "id": 4083,
            "categories": [
              "person"
            ],
            "info": "刘姥姥的女儿。"
          },
          {
            "label": "同喜",
            "value": 1,
            "id": 4084,
            "categories": [
              "person"
            ],
            "info": "薛姨妈房中丫头。"
          },
          {
            "label": "小红",
            "value": 7,
            "image": "./images/photo/小红.jpg",
            "id": 4085,
            "categories": [
              "person"
            ],
            "info": "中国古典小说《红楼梦》中的人物，贾府的丫鬟，原名林红玉，因名字中含有“林”字，冲撞了林黛玉，名字中的“玉”字，又冲撞了林黛玉和贾宝玉。因而改名为小红（也称红儿）。小红原本在怡红院当差，烧茶炉子喂喂鸟雀，因有几分姿色，每每想到贾宝玉跟前现弄现弄，只因袭人等伶牙利爪下不去手。好容易得到一次倒茶的机会，出门就被秋纹、碧痕等撞见，说她也不照照镜子，配端茶倒水的不配，排挤的小红心灰意冷。后转投凤姐，在凤姐处得到重用。她俏丽恬静，口齿清楚，说话简短，又很齐全，不受封建礼教束缚，敢于追逐自己的幸福，自己的爱情，和贾芸通过手帕私定终身。"
          },
          {
            "label": "鲍二家的",
            "value": 4,
            "id": 4086,
            "categories": [
              "person"
            ],
            "info": "鲍二家的是贾府男仆鲍二的老婆，书中人称鲍二家的。鲍二家的是个悲剧人物，给了贾府的家仆鲍二，却与主子贾琏眉目传情，趁着凤姐生日，收了贾琏送来的“两块银子，两根簪子，两匹缎子”，跑去贾琏与王熙凤的屋里厮混偷情，被王熙凤当场捉奸。"
          },
          {
            "label": "瑞珠",
            "value": 3,
            "image": "./images/photo/瑞珠.jpg",
            "id": 4087,
            "categories": [
              "person"
            ],
            "info": "秦可卿的丫环。秦可卿生前与公公贾珍关系暧昧，服侍秦可卿的瑞珠应该有所觉察，因此受到贾珍的威胁。秦可卿死后，瑞珠触柱而亡。"
          },
          {
            "label": "藕官",
            "value": 3,
            "image": "./images/photo/藕官.jpg",
            "id": 4088,
            "categories": [
              "person"
            ],
            "info": "藕官是贾家买来的十二个唱戏的优伶之一，小戏子。为人义气，当她的朋友芳官被赵姨娘侮辱时，藕官冒着随时被驱逐的危险和伙伴们一起将平时不可一世的姨娘打了个痛快。戏班解散后，她成了林黛玉的丫环。后来因不肯聘嫁他人而出家为尼，长伴青灯古佛。"
          },
          {
            "label": "蕊官",
            "value": 1,
            "image": "./images/photo/蕊官.jpg",
            "id": 4089,
            "categories": [
              "person"
            ],
            "info": "蕊官是梨香院女伶，后遁入空门。"
          },
          {
            "label": "入画",
            "value": 2,
            "image": "./images/photo/入画.jpg",
            "id": 4090,
            "categories": [
              "person"
            ],
            "info": "贾惜春的丫环。因她父母在南方，她和哥哥只好跟随叔叔过日子，可叔叔婶婶只知道喝酒赌钱，她哥哥只好把做小厮所得的赏赐托人交给她保管，抄检大观园时被发现。贾惜春非要把她撵走，入画跪地哀求，百般苦告，尤氏只得叫人将入画带到宁府。"
          },
          {
            "label": "彩屏",
            "value": 1,
            "id": 4091,
            "categories": [
              "person"
            ],
            "info": "贾惜春的一名丫鬟。"
          },
          {
            "label": "彩儿",
            "value": 1,
            "id": 4092,
            "categories": [
              "person"
            ],
            "info": "服侍贾惜春的小小丫环。"
          },
          {
            "label": "丰儿",
            "value": 1,
            "id": 4093,
            "categories": [
              "person"
            ],
            "info": "王熙凤的小丫环。在王熙凤房中位置仅次于平儿。"
          },
          {
            "label": "绣橘",
            "value": 1,
            "image": "./images/photo/绣橘.jpg",
            "id": 4094,
            "categories": [
              "person"
            ],
            "info": "贾迎春的丫头，她伶牙俐齿、好胜争强。是一个一心护主的二等小丫鬟，为了迎春，她跟王住儿媳妇为攒珠累丝金凤快言快语地吵架，吵不出结果时又气又急，竟至于哭了。对懦小姐迎春的“只是脸软怕人恼”，众人是无奈兼觉得好笑，绣橘却是满心的着急忧虑和奋力维护。绣橘第二次出场，是司棋被撵走时，她在大观园中哭着赶来给司棋送绢包，说是姑娘给的，做个念想吧。最后，迎春被贾赦许嫁孙绍祖之时，绣橘是四个陪嫁丫鬟之一。绣橘性格泼辣，有义护义。"
          },
          {
            "label": "侍书",
            "value": 2,
            "image": "./images/photo/侍书.jpg",
            "id": 4095,
            "categories": [
              "person"
            ],
            "info": "侍书是中国古典名著《红楼梦》中的虚拟人物，她是贾探春的丫鬟，心眼灵活，口齿伶俐。最后结局是跟随探春远嫁。她的名字有很大争议，一作“侍书”。"
          },
          {
            "label": "小鹊",
            "value": 2,
            "id": 4096,
            "categories": [
              "person"
            ],
            "info": "小鹊这个名字具有讽刺意味，喜鹊是报喜的，而她却是报忧的。小鹊是个机灵、有心机的丫头。"
          },
          {
            "label": "翠缕",
            "value": 1,
            "image": "./images/photo/翠缕.jpg",
            "id": 4097,
            "categories": [
              "person"
            ],
            "info": "史湘云的丫环。她是一位天真的丫鬟，性格如湘云一般，豪爽直言。"
          },
          {
            "label": "榛儿",
            "value": 1,
            "id": 4098,
            "categories": [
              "person"
            ],
            "info": "香菱的丫鬟。"
          },
          {
            "label": "小螺",
            "value": 1,
            "id": 4099,
            "categories": [
              "person"
            ],
            "info": "薛宝琴的小丫环（首见第五十回），即宝琴立雪时，在宝琴身后抱一瓶红梅者。"
          },
          {
            "label": "篆儿",
            "value": 1,
            "id": 4100,
            "categories": [
              "person"
            ],
            "info": "邢岫烟丫环，岫烟曾命她悄悄地将当票递与莺儿，交给薛宝钗。"
          },
          {
            "label": "茄官",
            "value": 1,
            "id": 4101,
            "categories": [
              "person"
            ],
            "info": "贾府梨香院优伶，饰老旦。"
          },
          {
            "label": "善姐",
            "value": 2,
            "image": "./images/photo/善姐.jpg",
            "id": 4102,
            "categories": [
              "person"
            ],
            "info": "本为王熙凤身边的丫头，后被凤姐特意送给尤二姐使唤。“善姐”二字实为反讽：名为“善”，但其人不善。 善姐伶牙俐齿，时时对尤二姐冷嘲热讽，又“不服使唤”，故意在饮食起居上作践二姐。善姐之“不善”，实为凤姐逼死尤二姐的阴险步骤之一，故《脂》批说：“写使女欺压二姐，正写凤姐欺压二姐。”"
          },
          {
            "label": "素云",
            "value": 2,
            "id": 4103,
            "categories": [
              "person"
            ],
            "info": "素云是李纨的丫环。"
          },
          {
            "label": "小吉祥儿",
            "value": 1,
            "id": 4104,
            "categories": [
              "person"
            ],
            "info": "赵姨娘跟前的一个小丫头。"
          },
          {
            "label": "葵官",
            "value": 1,
            "id": 4105,
            "categories": [
              "person"
            ],
            "info": "贾府买来的十二个唱戏的女孩子之一。后来成为湘云的丫鬟。"
          },
          {
            "label": "宝珠",
            "value": 2,
            "image": "./images/photo/宝珠.jpg",
            "id": 4106,
            "categories": [
              "person"
            ],
            "info": "秦可卿的丫环。秦可卿死后，宝珠见秦氏没有孩子，愿为义女，请任摔丧驾灵之任，后在铁槛寺陪伴秦可卿之灵，执意不肯回宁国府。"
          },
          {
            "label": "同贵",
            "value": 1,
            "id": 4107,
            "categories": [
              "person"
            ],
            "info": "同贵是曹雪芹小说《红楼梦》中的人物，出场身份是薛姨妈的丫头"
          },
          {
            "label": "炒豆儿",
            "value": 1,
            "id": 4108,
            "categories": [
              "person"
            ],
            "info": "尤氏身边的小丫头。在李纨房中，炒豆儿为尤氏端水只弯腰捧着，被李纨斥责后才跪下。事情发生在大观园抄检之后，显示出最讲等级规矩的贾府实际上已经纪律松散，衰败的现象逐步加剧，仆妇佣人也不那么服帖了。"
          },
          {
            "label": "银蝶",
            "value": 1,
            "id": 4109,
            "categories": [
              "person"
            ],
            "info": "尤氏的丫头。"
          },
          {
            "label": "小舍儿",
            "value": 2,
            "id": 4110,
            "categories": [
              "person"
            ],
            "info": "小舍儿是金桂从小儿在家使唤的。"
          },
          {
            "label": "艾官",
            "value": 1,
            "id": 4111,
            "categories": [
              "person"
            ],
            "info": "贾府梨香院女伶，饰老外。戏班解散后，她做了探春的丫头。夏婆子调唆赵姨娘辱打芳官，恰被她听见，便悄悄地回了探春，探春却料定她与芳官等“皆饰一党，本皆淘气异常，便只答应，也不肯据此为实”。众女伶被逐出大观园后，她被干娘领出自行聘嫁。"
          },
          {
            "label": "文官",
            "value": 1,
            "id": 4112,
            "categories": [
              "person"
            ],
            "info": "贾府中十二个唱戏的女子之一，饰小生。在“十二官”中是个领头的，她由于口齿伶俐，甚得贾母欢心，薛姨妈等人也赞为“好个灵透孩子”，戏班解散后，贾母便留下她自使。此后没再出场。众女伶被王夫人逐出大观园时她因在贾母身边而没被牵连进去。"
          },
          {
            "label": "彩霞",
            "value": 5,
            "id": 4113,
            "categories": [
              "person"
            ],
            "info": "彩霞是王夫人的大丫头，有时却又是赵姨娘身边的二等丫头。她非常能干，不仅要提醒王夫人大小家事，就连贾政在家出外去的一应大小家事，也得由她记着。她和贾环还合得来，不时规劝他安分些，何苦讨人嫌，但贾环并不领情。赵姨娘素日和彩霞好，巴不得彩霞能嫁给贾环，自己有个臂膀。赵姨娘求贾政说情，又要贾环去讨，贾环却认为不过是个丫头，丢开手便走了。后由王熙凤和贾琏作主，配给容貌丑陋、酗酒赌博的旺儿之子。"
          },
          {
            "label": "娇红",
            "value": 1,
            "id": 4114,
            "categories": [
              "person"
            ],
            "info": "邢夫人的丫头。"
          },
          {
            "label": "秋桐",
            "value": 3,
            "image": "./images/photo/秋桐.jpg",
            "id": 4115,
            "categories": [
              "person"
            ],
            "info": "原为贾赦的丫环。贾琏偷娶尤二姐后，出门为父亲贾赦办事，事情办的很出色，贾赦十分欢喜，夸他能干，把秋桐赏他为妾。秋桐自以为揣摩到了凤姐的心思，加上自己是贾赦所赐，无人敢冒犯她。王熙凤曾借她用“借剑杀人”之计，把尤二姐迫害而死。"
          },
          {
            "label": "小吉祥",
            "value": 1,
            "id": 4116,
            "categories": [
              "person"
            ],
            "info": "赵姨娘的丫头。"
          },
          {
            "label": "娇杏",
            "value": 3,
            "image": "./images/photo/娇杏.jpg",
            "id": 4117,
            "categories": [
              "person"
            ],
            "info": "娇杏是《红楼梦》中甄家的丫环，金陵十二钗副册。 “ 生得仪容不俗，眉目清秀，虽无十分姿色，却亦有动人之处。”"
          },
          {
            "label": "菂官",
            "value": 1,
            "id": 4118,
            "categories": [
              "person"
            ],
            "info": "作药官，《红楼梦》人物，系贾府买来的十二个唱戏的女孩之一，饰小旦。她在戏中与小生藕官常扮夫妻，二人相好异常，同性相恋。但不久她夭亡了，致使藕官伤心欲绝，哭得死去活来。"
          },
          {
            "label": "旺儿媳妇",
            "value": 5,
            "id": 4119,
            "categories": [
              "person"
            ],
            "info": "荣府女仆，王熙凤的陪房。她仗着与丈夫一起专替凤姐出力办事，放账收债。故无所忌惮，她倚势为“酗酒赌博”，“容貌丑陋”的儿子强娶彩霞，逼得彩霞之母纵不愿意也只好忍气吞声同意了。她是奴仆中的一“霸”。"
          },
          {
            "label": "王嬷嬷",
            "value": 2,
            "image": "./images/photo/王嬷嬷.jpg",
            "id": 4120,
            "categories": [
              "person"
            ],
            "info": "王嬷嬷是《红楼梦》中林黛玉的奶娘，贾老太君说王嬷嬷已经很老，料黛玉使唤不如意，于是又给黛玉安排了紫鹃这个丫鬟。"
          },
          {
            "label": "李嬷嬷",
            "value": 3,
            "image": "./images/photo/李嬷嬷.jpg",
            "id": 4121,
            "categories": [
              "person"
            ],
            "info": "贾宝玉的乳母。她是一个年老爱唠叨的人。儿子李贵是跟宝玉上学的仆人。"
          },
          {
            "label": "多姑娘",
            "value": 4,
            "image": "./images/photo/多姑娘.jpg",
            "id": 4122,
            "categories": [
              "person"
            ],
            "info": "多姑娘出场就是跟贾琏的一次偷情。多姑娘也是晴雯的表嫂，在晴雯临死病重、宝玉前去探望之时，她“便坐在炕沿上，却紧紧的将宝玉搂入怀中。”但后来，得知宝玉是来为情探望晴雯，便说“如今我反后悔错怪了你们。既然如此，你但放心。以后你只管来，我也不罗唣你”。也看出来了她的善以及对真爱的一丝羡慕，因为在作者的笔下，“多姑娘儿”代表了女性对男权社会的一种反抗，寄托了作者对女性解放的希望。对多姑娘的描写与对贾宝玉的描写遥相呼应，突出了作者的写作技巧。"
          },
          {
            "label": "赖大家的",
            "value": 3,
            "id": 4123,
            "categories": [
              "person"
            ],
            "info": "赖大家的是贾府管家赖大的妻子，人们称她为赖大家的。"
          },
          {
            "label": "吴兴登家的",
            "value": 4,
            "id": 4124,
            "categories": [
              "person"
            ],
            "info": "王夫人房里的仆人。"
          },
          {
            "label": "王善保家的",
            "value": 6,
            "image": "./images/photo/王善保家的.jpg",
            "id": 4125,
            "categories": [
              "person"
            ],
            "info": "王善保家的，是中国著名古典小说《红楼梦》中的人物，她是荣国府大房太太邢夫人的陪房婆子，也是邢夫人的心腹。"
          },
          {
            "label": "柳嫂子",
            "value": 3,
            "id": 4126,
            "categories": [
              "person"
            ],
            "info": "大观园内厨房主管，柳五儿之母。她曾经在十二个小戏子暗指的梨香院里当厨子，因此与小戏子芳官儿交好。她想通过芳官把女儿送进贾宝玉所住的怡红院，故对怡红院中人格外照顾。她巴结晴雯芳官儿，因此怠慢得罪了贾迎春的丫头司棋，被司棋领着缀锦楼的丫头们将大厨房一通乱砸，柳家的怕了，等司棋回去。她忙蒸了司棋要的蛋羹，却被司棋泼于地下。后因小丫头莲花儿等，她被牵连到“玫瑰露”事件。秦显家的乘机买通林之孝家的，企图撵柳家母女出去，并接管厨房。次日，“玫瑰露”事件被平儿按下，柳嫂母女冤情大白，才又回到内厨房管事。"
          },
          {
            "label": "李纹",
            "value": 3,
            "id": 4127,
            "categories": [
              "person"
            ],
            "info": "李婶娘之女，李纨堂妹。雪芹先生写其有超脱、淡然之美。其性格如梅——美丽高冷却又不失坚韧，与其柔弱寡断的堂姐胞妹——李纨和李绮有着鲜明的对比。后四十回结局猜想：也许并非世人所续“随便嫁了个人”，而可能是与薛宝琴、邢岫烟扮演不同的“悲剧红颜”。一些专家猜测，李纹很有可能是与妙玉一同被拐走。"
          },
          {
            "label": "李绮",
            "value": 3,
            "id": 4128,
            "categories": [
              "person"
            ],
            "info": "李纹的妹妹，李纨的堂妹，李纨寡婶之女。金陵十二钗副册之一，书中李绮被称赞是个水葱儿般水灵的姑娘。"
          },
          {
            "label": "李婶娘",
            "value": 4,
            "id": 4129,
            "categories": [
              "person"
            ],
            "info": "李纹、李绮之母。"
          },
          {
            "label": "青儿",
            "value": 3,
            "id": 4130,
            "categories": [
              "person"
            ],
            "info": "青儿--王狗儿之女（首见《红楼梦》第六回）。贾府被抄后，刘姥姥带她进府探望，恰逢王仁等要将巧姐送给外藩王爷，巧姐就扮作青儿模样避走乡下。"
          },
          {
            "label": "周瑞家的",
            "value": 6,
            "image": "./images/photo/周瑞家的.jpg",
            "id": 4131,
            "categories": [
              "person"
            ],
            "info": "周瑞家的，是王夫人的陪房，冷子兴的岳母。常在大观园及王夫人、琏二奶奶处做事露面。处事较为圆滑、见风使舵，是贾宝玉口中典型的“鱼眼睛”形象。 在荣国府里，周瑞家的管太太奶奶们出行的事。她的丈夫周瑞管宁国府地租庄子银钱的出入。"
          },
          {
            "label": "林之孝家的",
            "value": 4,
            "image": "./images/photo/林之孝家的.jpg",
            "id": 4132,
            "categories": [
              "person"
            ],
            "info": "林之孝家的是林红玉（小红）之母，也是荣国府大管家之一，为人行事低调，与其夫被喻为“天聋地哑”，地处管家高位却如此行事低调，实属难得，但也是媚上欺下，时不时也会利用自己的权利谋些好处。"
          },
          {
            "label": "柳五儿",
            "value": 2,
            "image": "./images/photo/柳五儿.jpg",
            "id": 4133,
            "categories": [
              "person"
            ],
            "info": "柳嫂子之女，十六岁，她虽是厨役之女，书中形容她生得人物与平、袭、鸳、紫相类，说她相貌与平儿、鸳鸯、袭人、紫鹃等人一样的漂亮。因他排行第五，便叫他五儿。五儿和宝玉的丫鬟芳官是好朋友，芳官把宝玉喝剩的玫瑰露给了她，因母亲不慎得罪了司棋等人，被冠以偷窃的贼名。幸亏平儿相助，她们母女的冤情得以洗清，柳五儿在曹雪芹前八十回因此事已经病死，后续书却把她写活了，并安排成为贾宝玉的丫环。"
          },
          {
            "label": "秦显家的",
            "value": 1,
            "image": "./images/photo/秦显家的.jpg",
            "id": 4134,
            "categories": [
              "person"
            ],
            "info": "在《红楼梦》中是个极次要的人物，她在第六十一回《投鼠忌器宝玉瞒赃 判冤决狱平儿行权》末尾方被提及，到第六十二回《憨湘云醉眠芍药茵 呆香菱情解石榴裙》开篇一现，曹公写她只有寥寥三百来字，但却构成一个令人难忘的艺术形象。"
          },
          {
            "label": "莲花儿",
            "value": 2,
            "id": 4135,
            "categories": [
              "person"
            ],
            "info": "迎春房里的小丫头。司棋让她吩咐厨房炖鸡蛋，柳嫂不从，她就同柳嫂口角，后又赌气添了一大篇话，惹得司棋火起，带领丫头们大闹厨房。"
          },
          {
            "label": "金氏",
            "value": 2,
            "id": 4136,
            "categories": [
              "person"
            ],
            "info": "贾璜之妻。"
          },
          {
            "label": "五嫂子",
            "value": 2,
            "id": 4138,
            "categories": [
              "person"
            ],
            "info": "贾芸之母。"
          },
          {
            "label": "来升媳妇",
            "value": 2,
            "id": 4139,
            "categories": [
              "person"
            ],
            "info": "宁府都总管赖二之妻。在秦氏丧仪中听凤姐令，每日揽总查看，不敢疏忽。"
          },
          {
            "label": "龄官",
            "value": 2,
            "image": "./images/photo/龄官.jpg",
            "id": 4140,
            "categories": [
              "person"
            ],
            "info": "龄官是清代小说《红楼梦》中的女性人物，贾家买来的十二个唱戏的女孩之一。戏活极好，长相、气质很像林黛玉，也有着林黛玉的敏感与清高，相当于林黛玉的一个射影，素与贾蔷相好。与贾蔷的爱情也给了宝玉不一样的启发。"
          },
          {
            "label": "封氏",
            "value": 5,
            "id": 4141,
            "categories": [
              "person"
            ],
            "info": "封氏是曹雪芹小说《红楼梦》中的人物，甄士隐的夫人。"
          },
          {
            "label": "赖嬷嬷",
            "value": 2,
            "image": "./images/photo/赖嬷嬷.jpg",
            "id": 4142,
            "categories": [
              "person"
            ],
            "info": "贾府管事之一，赖大的母亲，孙子赖尚荣在贾府的帮助做了知县，该人物在贾府里属于年高而有体面的嬷嬷。"
          },
          {
            "label": "周姨娘",
            "value": 1,
            "id": 4143,
            "categories": [
              "person"
            ],
            "info": "周姨娘为贾政妾，常与赵姨娘一起出现于奉侍贾母的场面上。"
          },
          {
            "label": "翠云",
            "value": 1,
            "id": 4144,
            "categories": [
              "person"
            ],
            "info": "贾赦之妾。"
          },
          {
            "label": "嫣红",
            "value": 1,
            "id": 4145,
            "categories": [
              "person"
            ],
            "info": "出自《红楼梦》第四十七回《呆霸王调情遭苦打 冷郎君惧祸走他乡》。邢夫人将方才的话只略说了几句，贾赦无法，又含愧，自此便告病，且不敢见贾母，只打发邢夫人及贾琏每日过去请安。只得又各处遣人购求寻觅，终究费了八百两银子买了一个十七岁的女孩子来，名唤嫣红，收在屋内。不在话下。"
          },
          {
            "label": "坠儿",
            "value": 3,
            "image": "./images/photo/坠儿.jpg",
            "id": 4146,
            "categories": [
              "person"
            ],
            "info": "宝玉的小丫头，因偷了平儿的一个镯子，被晴雯得知后，气得晴雯蛾眉倒蹙，凤眼圆睁，把她撵了出去。值得注意的是，曹雪芹有意把小红和坠儿设计成一对密友，这在那个时代，那种社会环境里，特别是在赫赫森严的贵族府邸里，她们的作为，她们的话语，才是真正具有叛逆性的，是晴雯等望尘莫及的，放射出真正的人性光辉。"
          },
          {
            "label": "智能儿",
            "value": 2,
            "id": 4147,
            "categories": [
              "person"
            ],
            "info": "秦钟之相好。"
          },
          {
            "label": "净虚",
            "value": 1,
            "id": 4148,
            "categories": [
              "person"
            ],
            "info": "馒头庵的姑子净虚，小尼姑智能儿的师父。馒头庵就是水月庵，因他庙里做的馒头好，就起了这个浑号，离铁槛寺不远。"
          },
          {
            "label": "万儿",
            "value": 3,
            "id": 4149,
            "categories": [
              "person"
            ],
            "info": "贾珍、尤氏的丫头。"
          },
          {
            "label": "司棋母",
            "value": 2,
            "image": "./images/photo/司棋母.jpg",
            "id": 4150,
            "categories": [
              "person"
            ],
            "info": "-迎春丫环司棋的母亲，阻挠女儿和自己的侄儿自己结合，以致潘又安、司棋双双徇情自尽。"
          },
          {
            "label": "白大娘",
            "value": 2,
            "id": 4151,
            "categories": [
              "person"
            ],
            "info": "金钏、玉钏之母"
          },
          {
            "label": "尤老娘",
            "value": 1,
            "image": "./images/photo/尤老娘.jpg",
            "id": 4152,
            "categories": [
              "person"
            ],
            "info": "尤二姐、尤三姐的亲娘，也是宁国府尤氏的继母，两个女儿也与尤氏没有血缘关系。她的两个女儿都生的青春貌美，她想用国色天香的尤二姐来嫁给贾琏，提高她在贾府的地位，可是后来尤二姐被凤姐害死了，尤三姐也因想柳湘莲订婚不成，自刎了。从母亲的角度来说，她是一个失败的母亲，失去了两个女儿。"
          },
          {
            "label": "周奶奶",
            "value": 1,
            "id": 4153,
            "categories": [
              "person"
            ],
            "info": "史湘云之奶妈。"
          },
          {
            "label": "豆官",
            "value": 1,
            "id": 4154,
            "categories": [
              "person"
            ],
            "info": "贾家买来唱戏的女优伶。饰小花脸。身量年纪皆极小，又极鬼灵，故曰豆官。戏班解散后，随侍薛宝琴。从此，园中人或唤她“阿豆”，或唤作“炒豆子”，而薛宝琴则将她打扮成琴童摸样，唤作“豆童”。"
          },
          {
            "label": "文花",
            "value": 1,
            "id": 4155,
            "categories": [
              "person"
            ],
            "info": "贾珍的小妾，很会唱曲。书中称她“喉清嗓嫩，真令人魂醉魄飞。”"
          },
          {
            "label": "赵嬷嬷",
            "value": 3,
            "image": "./images/photo/赵嬷嬷.jpg",
            "id": 4156,
            "categories": [
              "person"
            ],
            "info": "贾琏的乳母。曾求贾琏夫妇给她的两个儿子找份差事干。"
          },
          {
            "label": "娄氏",
            "value": 1,
            "id": 4157,
            "categories": [
              "person"
            ],
            "info": "贾菌之母。"
          },
          {
            "label": "老叶妈",
            "value": 1,
            "id": 4158,
            "categories": [
              "person"
            ],
            "info": "怡红院老妈子，宝玉小厮茗烟之母，与莺儿妈相善，莺儿还认她作干娘。"
          },
          {
            "label": "周氏",
            "value": 1,
            "id": 4160,
            "categories": [
              "person"
            ],
            "info": "贾芹之母。"
          },
          {
            "label": "佩凤",
            "value": 1,
            "id": 4161,
            "categories": [
              "person"
            ],
            "info": "贾珍之妾，年青姣憨之女子。"
          },
          {
            "label": "代儒夫人",
            "value": 2,
            "image": "./images/photo/代儒夫人.jpg",
            "id": 4162,
            "categories": [
              "person"
            ],
            "info": "贾瑞之祖母。"
          },
          {
            "label": "春燕",
            "value": 2,
            "image": "./images/photo/春燕.jpg",
            "id": 4163,
            "categories": [
              "person"
            ],
            "info": "何婆子之女，贾宝玉房内的二等丫鬟，贾府内世仆的女孩，聪明伶俐乐于助人。"
          },
          {
            "label": "何婆子",
            "value": 1,
            "image": "./images/photo/何婆子.jpg",
            "id": 4164,
            "categories": [
              "person"
            ],
            "info": "秦钟的丫头春燕之母。"
          },
          {
            "label": "碧痕",
            "value": 1,
            "image": "./images/photo/碧痕.jpg",
            "id": 4165,
            "categories": [
              "person"
            ],
            "info": "碧痕是贾宝玉房里的一个丫鬟。曾经在红楼梦中第20，24，26，31，77回出现过，碧痕的长相在书中从未出现过，有两次描写她的牙尖嘴利，心气很高。"
          },
          {
            "label": "笑儿",
            "value": 1,
            "id": 4166,
            "categories": [
              "person"
            ],
            "info": "史湘云的丫头。"
          },
          {
            "label": "警幻仙姑",
            "value": 2,
            "id": 4167,
            "categories": [
              "person"
            ],
            "info": "太虚幻境司主，相当于西方的维纳斯、丘比特。她首次提出“意淫”的概念。《红楼梦》第五回，她受宁荣二公所托，让宝玉阅览金陵十二钗命运簿册，聆听《红楼梦曲》十四支，又将妹妹可卿许配于宝玉，引导宝玉“留意于孔孟之间，委身于经济之道”。警幻之名，即设计情欲声色梦幻警醒宝玉入于仕途经济正路。无奈宝玉竟未悟，梦醒后依然不改意淫志行，直到后四十回第二梦才了悟。根据《红楼梦》中的脂批探佚，警幻仙子应该在最后一回中展示警幻情榜。"
          },
          {
            "label": "马道婆",
            "value": 4,
            "image": "./images/photo/马道婆.jpg",
            "id": 4168,
            "categories": [
              "person"
            ],
            "info": "贾宝玉寄名的干娘，是个搞封建迷信，邪魔歪道的混帐东西，经常出入贾府各房各屋。"
          },
          {
            "label": "南安太妃",
            "value": 5,
            "image": "./images/photo/南安太妃.jpg",
            "id": 4169,
            "categories": [
              "person"
            ],
            "info": "南安太妃是中国古典小说《红楼梦》中的人物。南安太妃在小说中出场不多，但是决定了贾府三小姐探春的命运。贾母过生日的那一次，她前来赴宴应酬，见到了宝钗、黛玉、湘云和探春，对这几个女孩子她非常喜欢。"
          },
          {
            "label": "夏婆子",
            "value": 1,
            "id": 4170,
            "categories": [
              "person"
            ],
            "info": "夏婆子是红楼梦中的一个人物，她是藕官的干娘，小蝉的外婆，平日在大观园当差。"
          },
          {
            "label": "费婆子",
            "value": 1,
            "id": 4171,
            "categories": [
              "person"
            ],
            "info": "邢夫人的陪房。"
          },
          {
            "label": "周瑞之女",
            "value": 4,
            "id": 4172,
            "categories": [
              "person"
            ],
            "info": "周瑞之女。"
          },
          {
            "label": "绛珠仙子",
            "value": 2,
            "id": 4173,
            "categories": [
              "person"
            ],
            "info": "传说中的美女仙，还传说是贾宝玉和林黛玉的第二世情缘，是绛珠仙子在“五衷内对神瑛侍者郁结着缠绵不尽之意”，一心回报神瑛侍者的灌溉之恩。"
          }
        ],
        "edges": [{
            "id": 3776,
            "label": "位于",
            "from": 3838,
            "to": 3851
          },
          {
            "id": 3777,
            "label": "位于",
            "from": 3839,
            "to": 3851
          },
          {
            "id": 3778,
            "label": "位于",
            "from": 3840,
            "to": 3851
          },
          {
            "id": 3779,
            "label": "位于",
            "from": 3841,
            "to": 3851
          },
          {
            "id": 3780,
            "label": "位于",
            "from": 3842,
            "to": 3851
          },
          {
            "id": 3781,
            "label": "位于",
            "from": 3843,
            "to": 3856
          },
          {
            "id": 3782,
            "label": "位于",
            "from": 3844,
            "to": 3851
          },
          {
            "id": 3783,
            "label": "位于",
            "from": 3845,
            "to": 3851
          },
          {
            "id": 3784,
            "label": "位于",
            "from": 3846,
            "to": 3851
          },
          {
            "id": 3785,
            "label": "位于",
            "from": 3847,
            "to": 3848
          },
          {
            "id": 3786,
            "label": "位于",
            "from": 3851,
            "to": 3856
          },
          {
            "id": 3787,
            "label": "位于",
            "from": 3852,
            "to": 3853
          },
          {
            "id": 3788,
            "label": "位于",
            "from": 3858,
            "to": 3850
          },
          {
            "id": 3789,
            "label": "位于",
            "from": 3862,
            "to": 3850
          },
          {
            "id": 3122,
            "label": "母亲",
            "from": 3863,
            "to": 4051
          },
          {
            "id": 3090,
            "label": "父亲",
            "from": 3863,
            "to": 3869
          },
          {
            "id": 3142,
            "label": "妻子",
            "from": 3863,
            "to": 4038
          },
          {
            "id": 3251,
            "label": "姐姐",
            "from": 3863,
            "to": 4044
          },
          {
            "id": 3262,
            "label": "老师",
            "from": 3863,
            "to": 3910
          },
          {
            "id": 3271,
            "label": "奶妈",
            "from": 3863,
            "to": 4121
          },
          {
            "id": 3278,
            "label": "交好",
            "from": 3863,
            "to": 3881
          },
          {
            "id": 3289,
            "label": "交好",
            "from": 3863,
            "to": 3890
          },
          {
            "id": 3292,
            "label": "交好",
            "from": 3863,
            "to": 3893
          },
          {
            "id": 3294,
            "label": "交好",
            "from": 3863,
            "to": 3954
          },
          {
            "id": 3295,
            "label": "同宗",
            "from": 3863,
            "to": 3878
          },
          {
            "id": 3312,
            "label": "跟班",
            "from": 3863,
            "to": 3907
          },
          {
            "id": 3316,
            "label": "跟班",
            "from": 3863,
            "to": 3926
          },
          {
            "id": 3320,
            "label": "仆人",
            "from": 3863,
            "to": 4055
          },
          {
            "id": 3143,
            "label": "妻子",
            "from": 3864,
            "to": 4049
          },
          {
            "id": 3091,
            "label": "父亲",
            "from": 3864,
            "to": 3880
          },
          {
            "id": 3224,
            "label": "原籍",
            "from": 3865,
            "to": 3855
          },
          {
            "id": 3189,
            "label": "居住地",
            "from": 3865,
            "to": 3849
          },
          {
            "id": 3092,
            "label": "父亲",
            "from": 3865,
            "to": 3879
          },
          {
            "id": 3225,
            "label": "原籍",
            "from": 3866,
            "to": 3855
          },
          {
            "id": 3190,
            "label": "居住地",
            "from": 3866,
            "to": 3849
          },
          {
            "id": 3093,
            "label": "父亲",
            "from": 3866,
            "to": 3865
          },
          {
            "id": 3256,
            "label": "哥哥",
            "from": 3867,
            "to": 3866
          },
          {
            "id": 3226,
            "label": "原籍",
            "from": 3867,
            "to": 3855
          },
          {
            "id": 3191,
            "label": "居住地",
            "from": 3867,
            "to": 3852
          },
          {
            "id": 3094,
            "label": "父亲",
            "from": 3867,
            "to": 3865
          },
          {
            "id": 3227,
            "label": "原籍",
            "from": 3868,
            "to": 3855
          },
          {
            "id": 3192,
            "label": "居住地",
            "from": 3868,
            "to": 3848
          },
          {
            "id": 3182,
            "label": "姬妾",
            "from": 3868,
            "to": 4144
          },
          {
            "id": 3176,
            "label": "姬妾",
            "from": 3868,
            "to": 4145
          },
          {
            "id": 3144,
            "label": "妻子",
            "from": 3868,
            "to": 4046
          },
          {
            "id": 3123,
            "label": "母亲",
            "from": 3868,
            "to": 4049
          },
          {
            "id": 3095,
            "label": "父亲",
            "from": 3868,
            "to": 3864
          },
          {
            "id": 3397,
            "label": "参与",
            "from": 3869,
            "to": 3818
          },
          {
            "id": 3384,
            "label": "参与",
            "from": 3869,
            "to": 3812
          },
          {
            "id": 3342,
            "label": "参与",
            "from": 3869,
            "to": 3804
          },
          {
            "id": 3329,
            "label": "仆人",
            "from": 3869,
            "to": 4116
          },
          {
            "id": 3321,
            "label": "仆人",
            "from": 3869,
            "to": 4096
          },
          {
            "id": 3272,
            "label": "奶妈",
            "from": 3869,
            "to": 4142
          },
          {
            "id": 3257,
            "label": "哥哥",
            "from": 3869,
            "to": 3868
          },
          {
            "id": 3228,
            "label": "原籍",
            "from": 3869,
            "to": 3855
          },
          {
            "id": 3193,
            "label": "居住地",
            "from": 3869,
            "to": 3848
          },
          {
            "id": 3183,
            "label": "姬妾",
            "from": 3869,
            "to": 4143
          },
          {
            "id": 3177,
            "label": "姬妾",
            "from": 3869,
            "to": 4047
          },
          {
            "id": 3145,
            "label": "妻子",
            "from": 3869,
            "to": 4051
          },
          {
            "id": 3124,
            "label": "母亲",
            "from": 3869,
            "to": 4049
          },
          {
            "id": 3096,
            "label": "父亲",
            "from": 3869,
            "to": 3864
          },
          {
            "id": 3385,
            "label": "参与",
            "from": 3870,
            "to": 3793
          },
          {
            "id": 3343,
            "label": "参与",
            "from": 3870,
            "to": 3788
          },
          {
            "id": 3279,
            "label": "交好",
            "from": 3870,
            "to": 3874
          },
          {
            "id": 3194,
            "label": "居住地",
            "from": 3870,
            "to": 3849
          },
          {
            "id": 3146,
            "label": "妻子",
            "from": 3870,
            "to": 4053
          },
          {
            "id": 3097,
            "label": "父亲",
            "from": 3870,
            "to": 3873
          },
          {
            "id": 3344,
            "label": "参与",
            "from": 3871,
            "to": 3793
          },
          {
            "id": 3296,
            "label": "同宗",
            "from": 3871,
            "to": 3870
          },
          {
            "id": 3280,
            "label": "交好",
            "from": 3871,
            "to": 3881
          },
          {
            "id": 3274,
            "label": "私通",
            "from": 3871,
            "to": 4140
          },
          {
            "id": 3313,
            "label": "跟班",
            "from": 3873,
            "to": 3956
          },
          {
            "id": 3297,
            "label": "同宗",
            "from": 3873,
            "to": 3878
          },
          {
            "id": 3275,
            "label": "私通",
            "from": 3873,
            "to": 4053
          },
          {
            "id": 3229,
            "label": "原籍",
            "from": 3873,
            "to": 3855
          },
          {
            "id": 3196,
            "label": "居住地",
            "from": 3873,
            "to": 3849
          },
          {
            "id": 3178,
            "label": "姬妾",
            "from": 3873,
            "to": 4161
          },
          {
            "id": 3147,
            "label": "妻子",
            "from": 3873,
            "to": 4079
          },
          {
            "id": 3099,
            "label": "父亲",
            "from": 3873,
            "to": 3867
          },
          {
            "id": 3399,
            "label": "参与",
            "from": 3874,
            "to": 3789
          },
          {
            "id": 3387,
            "label": "参与",
            "from": 3874,
            "to": 3826
          },
          {
            "id": 3346,
            "label": "参与",
            "from": 3874,
            "to": 3788
          },
          {
            "id": 3331,
            "label": "仆人",
            "from": 3874,
            "to": 3967
          },
          {
            "id": 3323,
            "label": "仆人",
            "from": 3874,
            "to": 4102
          },
          {
            "id": 3318,
            "label": "跟班",
            "from": 3874,
            "to": 3937
          },
          {
            "id": 3314,
            "label": "跟班",
            "from": 3874,
            "to": 3938
          },
          {
            "id": 3298,
            "label": "同宗",
            "from": 3874,
            "to": 3878
          },
          {
            "id": 3276,
            "label": "私通",
            "from": 3874,
            "to": 4122
          },
          {
            "id": 3273,
            "label": "奶妈",
            "from": 3874,
            "to": 4156
          },
          {
            "id": 3230,
            "label": "原籍",
            "from": 3874,
            "to": 3855
          },
          {
            "id": 3197,
            "label": "居住地",
            "from": 3874,
            "to": 3848
          },
          {
            "id": 3185,
            "label": "姬妾",
            "from": 3874,
            "to": 4115
          },
          {
            "id": 3184,
            "label": "姬妾",
            "from": 3874,
            "to": 4080
          },
          {
            "id": 3179,
            "label": "姬妾",
            "from": 3874,
            "to": 4056
          },
          {
            "id": 3148,
            "label": "妻子",
            "from": 3874,
            "to": 4041
          },
          {
            "id": 3100,
            "label": "父亲",
            "from": 3874,
            "to": 3868
          },
          {
            "id": 3299,
            "label": "同宗",
            "from": 3875,
            "to": 3878
          },
          {
            "id": 3198,
            "label": "居住地",
            "from": 3875,
            "to": 3848
          },
          {
            "id": 3126,
            "label": "母亲",
            "from": 3875,
            "to": 4046
          },
          {
            "id": 3101,
            "label": "父亲",
            "from": 3875,
            "to": 3868
          },
          {
            "id": 3332,
            "label": "仆人",
            "from": 3876,
            "to": 4072
          },
          {
            "id": 3324,
            "label": "仆人",
            "from": 3876,
            "to": 4103
          },
          {
            "id": 3300,
            "label": "同宗",
            "from": 3876,
            "to": 3878
          },
          {
            "id": 3231,
            "label": "原籍",
            "from": 3876,
            "to": 3855
          },
          {
            "id": 3199,
            "label": "居住地",
            "from": 3876,
            "to": 3848
          },
          {
            "id": 3149,
            "label": "妻子",
            "from": 3876,
            "to": 4043
          },
          {
            "id": 3127,
            "label": "母亲",
            "from": 3876,
            "to": 4051
          },
          {
            "id": 3102,
            "label": "父亲",
            "from": 3876,
            "to": 3869
          },
          {
            "id": 3347,
            "label": "参与",
            "from": 3877,
            "to": 3804
          },
          {
            "id": 3315,
            "label": "跟班",
            "from": 3877,
            "to": 3958
          },
          {
            "id": 3301,
            "label": "同宗",
            "from": 3877,
            "to": 3878
          },
          {
            "id": 3258,
            "label": "哥哥",
            "from": 3877,
            "to": 3874
          },
          {
            "id": 3252,
            "label": "姐姐",
            "from": 3877,
            "to": 4045
          },
          {
            "id": 3200,
            "label": "居住地",
            "from": 3877,
            "to": 3848
          },
          {
            "id": 3128,
            "label": "母亲",
            "from": 3877,
            "to": 4047
          },
          {
            "id": 3103,
            "label": "父亲",
            "from": 3877,
            "to": 3869
          },
          {
            "id": 3150,
            "label": "妻子",
            "from": 3878,
            "to": 4136
          },
          {
            "id": 3325,
            "label": "仆人",
            "from": 3879,
            "to": 3897
          },
          {
            "id": 3232,
            "label": "原籍",
            "from": 3879,
            "to": 3855
          },
          {
            "id": 3201,
            "label": "居住地",
            "from": 3879,
            "to": 3849
          },
          {
            "id": 3259,
            "label": "哥哥",
            "from": 3880,
            "to": 3879
          },
          {
            "id": 3233,
            "label": "原籍",
            "from": 3880,
            "to": 3855
          },
          {
            "id": 3202,
            "label": "居住地",
            "from": 3880,
            "to": 3848
          },
          {
            "id": 3388,
            "label": "参与",
            "from": 3881,
            "to": 3790
          },
          {
            "id": 3348,
            "label": "参与",
            "from": 3881,
            "to": 3810
          },
          {
            "id": 3290,
            "label": "交好",
            "from": 3881,
            "to": 3922
          },
          {
            "id": 3264,
            "label": "老师",
            "from": 3881,
            "to": 3910
          },
          {
            "id": 3253,
            "label": "姐姐",
            "from": 3881,
            "to": 4053
          },
          {
            "id": 3104,
            "label": "父亲",
            "from": 3881,
            "to": 3898
          },
          {
            "id": 3234,
            "label": "原籍",
            "from": 3882,
            "to": 3855
          },
          {
            "id": 3105,
            "label": "父亲",
            "from": 3882,
            "to": 3883
          },
          {
            "id": 3235,
            "label": "原籍",
            "from": 3883,
            "to": 3855
          },
          {
            "id": 3106,
            "label": "父亲",
            "from": 3883,
            "to": 3899
          },
          {
            "id": 3349,
            "label": "参与",
            "from": 3884,
            "to": 3791
          },
          {
            "id": 3236,
            "label": "原籍",
            "from": 3884,
            "to": 3855
          },
          {
            "id": 3107,
            "label": "父亲",
            "from": 3884,
            "to": 3943
          },
          {
            "id": 3389,
            "label": "参与",
            "from": 3885,
            "to": 3795
          },
          {
            "id": 3350,
            "label": "参与",
            "from": 3885,
            "to": 3792
          },
          {
            "id": 3293,
            "label": "交好",
            "from": 3885,
            "to": 3890
          },
          {
            "id": 3291,
            "label": "交好",
            "from": 3885,
            "to": 3942
          },
          {
            "id": 3391,
            "label": "参与",
            "from": 3891,
            "to": 3835
          },
          {
            "id": 3353,
            "label": "参与",
            "from": 3891,
            "to": 3792
          },
          {
            "id": 3309,
            "label": "连宗",
            "from": 3891,
            "to": 3869
          },
          {
            "id": 3283,
            "label": "交好",
            "from": 3891,
            "to": 3940
          },
          {
            "id": 3241,
            "label": "原籍",
            "from": 3891,
            "to": 3857
          },
          {
            "id": 3203,
            "label": "居住地",
            "from": 3891,
            "to": 3955
          },
          {
            "id": 3181,
            "label": "姬妾",
            "from": 3891,
            "to": 4117
          },
          {
            "id": 3392,
            "label": "参与",
            "from": 3892,
            "to": 3790
          },
          {
            "id": 3354,
            "label": "参与",
            "from": 3892,
            "to": 3793
          },
          {
            "id": 3204,
            "label": "居住地",
            "from": 3892,
            "to": 3856
          },
          {
            "id": 3112,
            "label": "父亲",
            "from": 3892,
            "to": 3900
          },
          {
            "id": 3393,
            "label": "参与",
            "from": 3893,
            "to": 3804
          },
          {
            "id": 3355,
            "label": "参与",
            "from": 3893,
            "to": 3794
          },
          {
            "id": 3284,
            "label": "交好",
            "from": 3893,
            "to": 3954
          },
          {
            "id": 3153,
            "label": "妻子",
            "from": 3893,
            "to": 4055
          },
          {
            "id": 3356,
            "label": "参与",
            "from": 3894,
            "to": 3796
          },
          {
            "id": 3302,
            "label": "同宗",
            "from": 3894,
            "to": 3863
          },
          {
            "id": 3285,
            "label": "交好",
            "from": 3894,
            "to": 3871
          },
          {
            "id": 3205,
            "label": "居住地",
            "from": 3894,
            "to": 3853
          },
          {
            "id": 3154,
            "label": "妻子",
            "from": 3894,
            "to": 4085
          },
          {
            "id": 3130,
            "label": "母亲",
            "from": 3894,
            "to": 4138
          },
          {
            "id": 3357,
            "label": "参与",
            "from": 3895,
            "to": 3796
          },
          {
            "id": 3206,
            "label": "居住地",
            "from": 3895,
            "to": 3856
          },
          {
            "id": 3358,
            "label": "参与",
            "from": 3896,
            "to": 3780
          },
          {
            "id": 3207,
            "label": "居住地",
            "from": 3896,
            "to": 3850
          },
          {
            "id": 3155,
            "label": "妻子",
            "from": 3896,
            "to": 4048
          },
          {
            "id": 3208,
            "label": "居住地",
            "from": 3897,
            "to": 3849
          },
          {
            "id": 3242,
            "label": "原籍",
            "from": 3899,
            "to": 3855
          },
          {
            "id": 3243,
            "label": "原籍",
            "from": 3900,
            "to": 3853
          },
          {
            "id": 3113,
            "label": "父亲",
            "from": 3900,
            "to": 3910
          },
          {
            "id": 3244,
            "label": "原籍",
            "from": 3902,
            "to": 3855
          },
          {
            "id": 3359,
            "label": "参与",
            "from": 3903,
            "to": 3824
          },
          {
            "id": 3333,
            "label": "仆人",
            "from": 3903,
            "to": 3970
          },
          {
            "id": 3326,
            "label": "仆人",
            "from": 3903,
            "to": 4117
          },
          {
            "id": 3245,
            "label": "原籍",
            "from": 3903,
            "to": 3850
          },
          {
            "id": 3209,
            "label": "居住地",
            "from": 3903,
            "to": 3862
          },
          {
            "id": 3156,
            "label": "妻子",
            "from": 3903,
            "to": 4141
          },
          {
            "id": 3360,
            "label": "参与",
            "from": 3905,
            "to": 3814
          },
          {
            "id": 3246,
            "label": "原籍",
            "from": 3905,
            "to": 3853
          },
          {
            "id": 3157,
            "label": "妻子",
            "from": 3905,
            "to": 4039
          },
          {
            "id": 3131,
            "label": "母亲",
            "from": 3905,
            "to": 4083
          },
          {
            "id": 3114,
            "label": "父亲",
            "from": 3905,
            "to": 3917
          },
          {
            "id": 3361,
            "label": "参与",
            "from": 3906,
            "to": 3820
          },
          {
            "id": 3247,
            "label": "原籍",
            "from": 3906,
            "to": 3861
          },
          {
            "id": 3210,
            "label": "居住地",
            "from": 3906,
            "to": 3856
          },
          {
            "id": 3158,
            "label": "妻子",
            "from": 3906,
            "to": 4042
          },
          {
            "id": 3132,
            "label": "母亲",
            "from": 3907,
            "to": 4121
          },
          {
            "id": 3133,
            "label": "母亲",
            "from": 3908,
            "to": 4156
          },
          {
            "id": 3134,
            "label": "母亲",
            "from": 3909,
            "to": 4156
          },
          {
            "id": 3303,
            "label": "同宗",
            "from": 3910,
            "to": 3869
          },
          {
            "id": 3248,
            "label": "原籍",
            "from": 3910,
            "to": 3853
          },
          {
            "id": 3159,
            "label": "妻子",
            "from": 3910,
            "to": 4162
          },
          {
            "id": 3160,
            "label": "妻子",
            "from": 3911,
            "to": 4123
          },
          {
            "id": 3135,
            "label": "母亲",
            "from": 3911,
            "to": 4142
          },
          {
            "id": 3161,
            "label": "妻子",
            "from": 3913,
            "to": 4122
          },
          {
            "id": 3310,
            "label": "连宗",
            "from": 3916,
            "to": 3883
          },
          {
            "id": 3115,
            "label": "父亲",
            "from": 3916,
            "to": 3915
          },
          {
            "id": 3162,
            "label": "妻子",
            "from": 3917,
            "to": 4083
          },
          {
            "id": 3116,
            "label": "父亲",
            "from": 3917,
            "to": 3916
          },
          {
            "id": 3311,
            "label": "有恩",
            "from": 3918,
            "to": 3917
          },
          {
            "id": 3163,
            "label": "妻子",
            "from": 3918,
            "to": 4131
          },
          {
            "id": 3164,
            "label": "妻子",
            "from": 3919,
            "to": 4132
          },
          {
            "id": 3165,
            "label": "妻子",
            "from": 3920,
            "to": 4086
          },
          {
            "id": 3362,
            "label": "参与",
            "from": 3921,
            "to": 3786
          },
          {
            "id": 3117,
            "label": "父亲",
            "from": 3921,
            "to": 3935
          },
          {
            "id": 3266,
            "label": "老师",
            "from": 3922,
            "to": 3910
          },
          {
            "id": 3363,
            "label": "参与",
            "from": 3923,
            "to": 3790
          },
          {
            "id": 3304,
            "label": "同宗",
            "from": 3923,
            "to": 3870
          },
          {
            "id": 3286,
            "label": "交好",
            "from": 3923,
            "to": 3924
          },
          {
            "id": 3267,
            "label": "老师",
            "from": 3923,
            "to": 3910
          },
          {
            "id": 3136,
            "label": "母亲",
            "from": 3923,
            "to": 4157
          },
          {
            "id": 3364,
            "label": "参与",
            "from": 3924,
            "to": 3790
          },
          {
            "id": 3305,
            "label": "同宗",
            "from": 3924,
            "to": 3870
          },
          {
            "id": 3268,
            "label": "老师",
            "from": 3924,
            "to": 3910
          },
          {
            "id": 3365,
            "label": "参与",
            "from": 3925,
            "to": 3790
          },
          {
            "id": 3269,
            "label": "老师",
            "from": 3925,
            "to": 3910
          },
          {
            "id": 3366,
            "label": "参与",
            "from": 3926,
            "to": 3790
          },
          {
            "id": 3270,
            "label": "老师",
            "from": 3926,
            "to": 3910
          },
          {
            "id": 3137,
            "label": "母亲",
            "from": 3926,
            "to": 4158
          },
          {
            "id": 3367,
            "label": "参与",
            "from": 3927,
            "to": 3801
          },
          {
            "id": 3307,
            "label": "同宗",
            "from": 3929,
            "to": 3874
          },
          {
            "id": 3139,
            "label": "母亲",
            "from": 3929,
            "to": 4160
          },
          {
            "id": 3308,
            "label": "同宗",
            "from": 3930,
            "to": 3863
          },
          {
            "id": 3166,
            "label": "妻子",
            "from": 3931,
            "to": 4139
          },
          {
            "id": 3334,
            "label": "仆人",
            "from": 3932,
            "to": 3938
          },
          {
            "id": 3327,
            "label": "仆人",
            "from": 3932,
            "to": 4149
          },
          {
            "id": 3368,
            "label": "参与",
            "from": 3934,
            "to": 3812
          },
          {
            "id": 3287,
            "label": "交好",
            "from": 3934,
            "to": 3890
          },
          {
            "id": 3118,
            "label": "父亲",
            "from": 3934,
            "to": 3911
          },
          {
            "id": 3254,
            "label": "姐姐",
            "from": 3936,
            "to": 4046
          },
          {
            "id": 3167,
            "label": "妻子",
            "from": 3939,
            "to": 4125
          },
          {
            "id": 3369,
            "label": "参与",
            "from": 3940,
            "to": 3835
          },
          {
            "id": 3211,
            "label": "居住地",
            "from": 3940,
            "to": 3856
          },
          {
            "id": 3168,
            "label": "妻子",
            "from": 3940,
            "to": 4172
          },
          {
            "id": 3119,
            "label": "父亲",
            "from": 3943,
            "to": 3883
          },
          {
            "id": 3120,
            "label": "父亲",
            "from": 3944,
            "to": 3889
          },
          {
            "id": 3169,
            "label": "妻子",
            "from": 3945,
            "to": 4052
          },
          {
            "id": 3170,
            "label": "妻子",
            "from": 3946,
            "to": 4077
          },
          {
            "id": 3171,
            "label": "妻子",
            "from": 3947,
            "to": 4124
          },
          {
            "id": 3370,
            "label": "参与",
            "from": 3948,
            "to": 3788
          },
          {
            "id": 3172,
            "label": "妻子",
            "from": 3948,
            "to": 4119
          },
          {
            "id": 3212,
            "label": "居住地",
            "from": 3949,
            "to": 3850
          },
          {
            "id": 3173,
            "label": "妻子",
            "from": 3950,
            "to": 4129
          },
          {
            "id": 3371,
            "label": "参与",
            "from": 3951,
            "to": 3792
          },
          {
            "id": 3249,
            "label": "原籍",
            "from": 3951,
            "to": 3850
          },
          {
            "id": 3213,
            "label": "居住地",
            "from": 3951,
            "to": 3858
          },
          {
            "id": 3140,
            "label": "母亲",
            "from": 3953,
            "to": 4075
          },
          {
            "id": 3214,
            "label": "居住地",
            "from": 3954,
            "to": 3856
          },
          {
            "id": 3790,
            "label": "位于",
            "from": 3955,
            "to": 3855
          },
          {
            "id": 3215,
            "label": "居住地",
            "from": 3960,
            "to": 3859
          },
          {
            "id": 3174,
            "label": "妻子",
            "from": 3960,
            "to": 4169
          },
          {
            "id": 3372,
            "label": "参与",
            "from": 3961,
            "to": 3792
          },
          {
            "id": 3216,
            "label": "居住地",
            "from": 3961,
            "to": 3855
          },
          {
            "id": 3217,
            "label": "居住地",
            "from": 3962,
            "to": 3855
          },
          {
            "id": 3373,
            "label": "参与",
            "from": 3963,
            "to": 3818
          },
          {
            "id": 3374,
            "label": "参与",
            "from": 3964,
            "to": 3818
          },
          {
            "id": 3375,
            "label": "参与",
            "from": 3965,
            "to": 3818
          },
          {
            "id": 3376,
            "label": "参与",
            "from": 3967,
            "to": 3780
          },
          {
            "id": 3394,
            "label": "参与",
            "from": 3968,
            "to": 3783
          },
          {
            "id": 3377,
            "label": "参与",
            "from": 3968,
            "to": 3824
          },
          {
            "id": 3288,
            "label": "交好",
            "from": 3968,
            "to": 3969
          },
          {
            "id": 3400,
            "label": "参与",
            "from": 3969,
            "to": 3783
          },
          {
            "id": 3395,
            "label": "参与",
            "from": 3969,
            "to": 3793
          },
          {
            "id": 3378,
            "label": "参与",
            "from": 3969,
            "to": 3824
          },
          {
            "id": 3379,
            "label": "参与",
            "from": 3970,
            "to": 3834
          },
          {
            "id": 3218,
            "label": "居住地",
            "from": 3970,
            "to": 3862
          },
          {
            "id": 3380,
            "label": "参与",
            "from": 3971,
            "to": 3808
          },
          {
            "id": 3175,
            "label": "妻子",
            "from": 3971,
            "to": 4113
          },
          {
            "id": 3141,
            "label": "母亲",
            "from": 3971,
            "to": 4119
          },
          {
            "id": 3121,
            "label": "父亲",
            "from": 3971,
            "to": 3948
          },
          {
            "id": 3381,
            "label": "参与",
            "from": 3972,
            "to": 3836
          },
          {
            "id": 3770,
            "label": "参与",
            "from": 4037,
            "to": 3831
          },
          {
            "id": 3767,
            "label": "参与",
            "from": 4037,
            "to": 3780
          },
          {
            "id": 3764,
            "label": "参与",
            "from": 4037,
            "to": 3815
          },
          {
            "id": 3758,
            "label": "参与",
            "from": 4037,
            "to": 3806
          },
          {
            "id": 3747,
            "label": "参与",
            "from": 4037,
            "to": 3781
          },
          {
            "id": 3723,
            "label": "参与",
            "from": 4037,
            "to": 3832
          },
          {
            "id": 3668,
            "label": "参与",
            "from": 4037,
            "to": 3779
          },
          {
            "id": 3658,
            "label": "仆人",
            "from": 4037,
            "to": 4088
          },
          {
            "id": 3645,
            "label": "仆人",
            "from": 4037,
            "to": 4070
          },
          {
            "id": 3628,
            "label": "仆人",
            "from": 4037,
            "to": 4069
          },
          {
            "id": 3605,
            "label": "仆人",
            "from": 4037,
            "to": 4058
          },
          {
            "id": 3590,
            "label": "喜欢",
            "from": 4037,
            "to": 3863
          },
          {
            "id": 3588,
            "label": "奶妈",
            "from": 4037,
            "to": 4120
          },
          {
            "id": 3587,
            "label": "老师",
            "from": 4037,
            "to": 3891
          },
          {
            "id": 3576,
            "label": "知己",
            "from": 4037,
            "to": 4058
          },
          {
            "id": 3542,
            "label": "前世",
            "from": 4037,
            "to": 4173
          },
          {
            "id": 3528,
            "label": "原籍",
            "from": 4037,
            "to": 3854
          },
          {
            "id": 3499,
            "label": "居住地",
            "from": 4037,
            "to": 3838
          },
          {
            "id": 3441,
            "label": "母亲",
            "from": 4037,
            "to": 4048
          },
          {
            "id": 3419,
            "label": "父亲",
            "from": 4037,
            "to": 3896
          },
          {
            "id": 3771,
            "label": "参与",
            "from": 4038,
            "to": 3837
          },
          {
            "id": 3768,
            "label": "参与",
            "from": 4038,
            "to": 3821
          },
          {
            "id": 3765,
            "label": "参与",
            "from": 4038,
            "to": 3816
          },
          {
            "id": 3759,
            "label": "参与",
            "from": 4038,
            "to": 3802
          },
          {
            "id": 3748,
            "label": "参与",
            "from": 4038,
            "to": 3781
          },
          {
            "id": 3724,
            "label": "参与",
            "from": 4038,
            "to": 3806
          },
          {
            "id": 3669,
            "label": "参与",
            "from": 4038,
            "to": 3784
          },
          {
            "id": 3646,
            "label": "仆人",
            "from": 4038,
            "to": 4089
          },
          {
            "id": 3629,
            "label": "仆人",
            "from": 4038,
            "to": 4071
          },
          {
            "id": 3606,
            "label": "仆人",
            "from": 4038,
            "to": 4059
          },
          {
            "id": 3591,
            "label": "喜欢",
            "from": 4038,
            "to": 3863
          },
          {
            "id": 3551,
            "label": "哥哥",
            "from": 4038,
            "to": 3885
          },
          {
            "id": 3529,
            "label": "原籍",
            "from": 4038,
            "to": 3855
          },
          {
            "id": 3500,
            "label": "居住地",
            "from": 4038,
            "to": 3839
          },
          {
            "id": 3442,
            "label": "母亲",
            "from": 4038,
            "to": 4075
          },
          {
            "id": 3420,
            "label": "父亲",
            "from": 4038,
            "to": 3901
          },
          {
            "id": 3670,
            "label": "参与",
            "from": 4039,
            "to": 3791
          },
          {
            "id": 3530,
            "label": "原籍",
            "from": 4039,
            "to": 3856
          },
          {
            "id": 3501,
            "label": "居住地",
            "from": 4039,
            "to": 3848
          },
          {
            "id": 3461,
            "label": "丈夫",
            "from": 4039,
            "to": 3905
          },
          {
            "id": 3443,
            "label": "母亲",
            "from": 4039,
            "to": 4041
          },
          {
            "id": 3421,
            "label": "父亲",
            "from": 4039,
            "to": 3874
          },
          {
            "id": 3671,
            "label": "参与",
            "from": 4040,
            "to": 3781
          },
          {
            "id": 3647,
            "label": "仆人",
            "from": 4040,
            "to": 4092
          },
          {
            "id": 3630,
            "label": "仆人",
            "from": 4040,
            "to": 4091
          },
          {
            "id": 3607,
            "label": "仆人",
            "from": 4040,
            "to": 4090
          },
          {
            "id": 3552,
            "label": "哥哥",
            "from": 4040,
            "to": 3873
          },
          {
            "id": 3531,
            "label": "原籍",
            "from": 4040,
            "to": 3856
          },
          {
            "id": 3502,
            "label": "居住地",
            "from": 4040,
            "to": 3840
          },
          {
            "id": 3422,
            "label": "父亲",
            "from": 4040,
            "to": 3867
          },
          {
            "id": 3775,
            "label": "参与",
            "from": 4041,
            "to": 3830
          },
          {
            "id": 3774,
            "label": "参与",
            "from": 4041,
            "to": 3786
          },
          {
            "id": 3773,
            "label": "参与",
            "from": 4041,
            "to": 3826
          },
          {
            "id": 3772,
            "label": "参与",
            "from": 4041,
            "to": 3822
          },
          {
            "id": 3769,
            "label": "参与",
            "from": 4041,
            "to": 3814
          },
          {
            "id": 3766,
            "label": "参与",
            "from": 4041,
            "to": 3809
          },
          {
            "id": 3760,
            "label": "参与",
            "from": 4041,
            "to": 3807
          },
          {
            "id": 3749,
            "label": "参与",
            "from": 4041,
            "to": 3783
          },
          {
            "id": 3725,
            "label": "参与",
            "from": 4041,
            "to": 3798
          },
          {
            "id": 3672,
            "label": "参与",
            "from": 4041,
            "to": 3793
          },
          {
            "id": 3665,
            "label": "陪房",
            "from": 4041,
            "to": 4119
          },
          {
            "id": 3663,
            "label": "仆人",
            "from": 4041,
            "to": 3948
          },
          {
            "id": 3659,
            "label": "仆人",
            "from": 4041,
            "to": 3938
          },
          {
            "id": 3648,
            "label": "仆人",
            "from": 4041,
            "to": 4093
          },
          {
            "id": 3631,
            "label": "仆人",
            "from": 4041,
            "to": 4085
          },
          {
            "id": 3608,
            "label": "仆人",
            "from": 4041,
            "to": 4056
          },
          {
            "id": 3579,
            "label": "私通",
            "from": 4041,
            "to": 3870
          },
          {
            "id": 3553,
            "label": "哥哥",
            "from": 4041,
            "to": 3884
          },
          {
            "id": 3532,
            "label": "原籍",
            "from": 4041,
            "to": 3855
          },
          {
            "id": 3503,
            "label": "居住地",
            "from": 4041,
            "to": 3848
          },
          {
            "id": 3462,
            "label": "丈夫",
            "from": 4041,
            "to": 3874
          },
          {
            "id": 3423,
            "label": "父亲",
            "from": 4041,
            "to": 3943
          },
          {
            "id": 3726,
            "label": "参与",
            "from": 4042,
            "to": 3820
          },
          {
            "id": 3673,
            "label": "参与",
            "from": 4042,
            "to": 3781
          },
          {
            "id": 3649,
            "label": "仆人",
            "from": 4042,
            "to": 4135
          },
          {
            "id": 3632,
            "label": "仆人",
            "from": 4042,
            "to": 4094
          },
          {
            "id": 3609,
            "label": "仆人",
            "from": 4042,
            "to": 4063
          },
          {
            "id": 3554,
            "label": "哥哥",
            "from": 4042,
            "to": 3874
          },
          {
            "id": 3504,
            "label": "居住地",
            "from": 4042,
            "to": 3841
          },
          {
            "id": 3463,
            "label": "丈夫",
            "from": 4042,
            "to": 3906
          },
          {
            "id": 3424,
            "label": "父亲",
            "from": 4042,
            "to": 3868
          },
          {
            "id": 3727,
            "label": "参与",
            "from": 4043,
            "to": 3827
          },
          {
            "id": 3674,
            "label": "参与",
            "from": 4043,
            "to": 3781
          },
          {
            "id": 3650,
            "label": "仆人",
            "from": 4043,
            "to": 4110
          },
          {
            "id": 3633,
            "label": "仆人",
            "from": 4043,
            "to": 4103
          },
          {
            "id": 3460,
            "label": "母亲",
            "from": 4172,
            "to": 4131
          },
          {
            "id": 3440,
            "label": "父亲",
            "from": 4172,
            "to": 3918
          },
          {
            "id": 3722,
            "label": "参与",
            "from": 4173,
            "to": 3836
          }
        ]
      }
    }
    
    let gson3 = {'categories': {'person': '人物', 'event': '事件', 'location': '地点'},
    'data': {'nodes': [{'label': '庞德',
       'value': 1,
       'id': 0,
       'categories': ['人物'],
       'info': '庞德-人物'},
      {'label': '庞德公',
       'value': 1,
       'id': 1,
       'categories': ['人物'],
       'info': '庞德公-人物'},
      {'label': '孟获', 'value': 2, 'id': 2, 'categories': ['人物'], 'info': '孟获-人物'},
      {'label': '周泰', 'value': 1, 'id': 3, 'categories': ['人物'], 'info': '周泰-人物'},
      {'label': '马超', 'value': 3, 'id': 4, 'categories': ['人物'], 'info': '马超-人物'},
      {'label': '凌统', 'value': 1, 'id': 5, 'categories': ['人物'], 'info': '凌统-人物'},
      {'label': '袁谭', 'value': 2, 'id': 6, 'categories': ['人物'], 'info': '袁谭-人物'},
      {'label': '黄祖', 'value': 1, 'id': 7, 'categories': ['人物'], 'info': '黄祖-人物'},
      {'label': '徐晃', 'value': 1, 'id': 8, 'categories': ['人物'], 'info': '徐晃-人物'},
      {'label': '许褚', 'value': 1, 'id': 9, 'categories': ['人物'], 'info': '许褚-人物'},
      {'label': '庞统',
       'value': 2,
       'id': 10,
       'categories': ['人物'],
       'info': '庞统-人物'},
      {'label': '文聘',
       'value': 1,
       'id': 11,
       'categories': ['人物'],
       'info': '文聘-人物'},
      {'label': '曹仁',
       'value': 1,
       'id': 12,
       'categories': ['人物'],
       'info': '曹仁-人物'},
      {'label': '马谡',
       'value': 2,
       'id': 13,
       'categories': ['人物'],
       'info': '马谡-人物'},
      {'label': '陆逊',
       'value': 2,
       'id': 14,
       'categories': ['人物'],
       'info': '陆逊-人物'},
      {'label': '刘崎',
       'value': 1,
       'id': 15,
       'categories': ['人物'],
       'info': '刘崎-人物'},
      {'label': '法正',
       'value': 1,
       'id': 16,
       'categories': ['人物'],
       'info': '法正-人物'},
      {'label': '刘协',
       'value': 2,
       'id': 17,
       'categories': ['人物'],
       'info': '刘协-人物'},
      {'label': '夏侯淳',
       'value': 1,
       'id': 18,
       'categories': ['人物'],
       'info': '夏侯淳-人物'},
      {'label': '孙权',
       'value': 20,
       'id': 19,
       'categories': ['人物'],
       'info': '孙权-人物'},
      {'label': '袁术',
       'value': 1,
       'id': 20,
       'categories': ['人物'],
       'info': '袁术-人物'},
      {'label': '黄承彦',
       'value': 1,
       'id': 21,
       'categories': ['人物'],
       'info': '黄承彦-人物'},
      {'label': '刘备',
       'value': 17,
       'id': 22,
       'categories': ['人物'],
       'info': '刘备-人物'},
      {'label': '诸葛瑾',
       'value': 2,
       'id': 23,
       'categories': ['人物'],
       'info': '诸葛瑾-人物'},
      {'label': '吴国太',
       'value': 3,
       'id': 24,
       'categories': ['人物'],
       'info': '吴国太-人物'},
      {'label': '李渊',
       'value': 3,
       'id': 25,
       'categories': ['人物'],
       'info': '李渊-人物'},
      {'label': '袁熙',
       'value': 2,
       'id': 26,
       'categories': ['人物'],
       'info': '袁熙-人物'},
      {'label': '太史慈',
       'value': 1,
       'id': 27,
       'categories': ['人物'],
       'info': '太史慈-人物'},
      {'label': '李建成',
       'value': 1,
       'id': 28,
       'categories': ['人物'],
       'info': '李建成-人物'},
      {'label': '司马懿',
       'value': 3,
       'id': 29,
       'categories': ['人物'],
       'info': '司马懿-人物'},
      {'label': '陈宫',
       'value': 1,
       'id': 30,
       'categories': ['人物'],
       'info': '陈宫-人物'},
      {'label': '魏延',
       'value': 1,
       'id': 31,
       'categories': ['人物'],
       'info': '魏延-人物'},
      {'label': '张苞',
       'value': 1,
       'id': 32,
       'categories': ['人物'],
       'info': '张苞-人物'},
      {'label': '郭嘉',
       'value': 1,
       'id': 33,
       'categories': ['人物'],
       'info': '郭嘉-人物'},
      {'label': '梁龙',
       'value': 1,
       'id': 34,
       'categories': ['人物'],
       'info': '梁龙-人物'},
      {'label': '曹昂',
       'value': 1,
       'id': 35,
       'categories': ['人物'],
       'info': '曹昂-人物'},
      {'label': '蔡瑁',
       'value': 1,
       'id': 36,
       'categories': ['人物'],
       'info': '蔡瑁-人物'},
      {'label': '马良',
       'value': 2,
       'id': 37,
       'categories': ['人物'],
       'info': '马良-人物'},
      {'label': '黄月英',
       'value': 2,
       'id': 38,
       'categories': ['人物'],
       'info': '黄月英-人物'},
      {'label': '张角',
       'value': 1,
       'id': 39,
       'categories': ['人物'],
       'info': '张角-人物'},
      {'label': '公孙越',
       'value': 2,
       'id': 40,
       'categories': ['人物'],
       'info': '公孙越-人物'},
      {'label': '交趾',
       'value': 2,
       'id': 41,
       'categories': ['地点'],
       'info': '交趾-地点'},
      {'label': '李世民',
       'value': 3,
       'id': 42,
       'categories': ['人物'],
       'info': '李世民-人物'},
      {'label': '韩当',
       'value': 1,
       'id': 43,
       'categories': ['人物'],
       'info': '韩当-人物'},
      {'label': '夏侯渊',
       'value': 2,
       'id': 44,
       'categories': ['人物'],
       'info': '夏侯渊-人物'},
      {'label': '刘启',
       'value': 1,
       'id': 45,
       'categories': ['人物'],
       'info': '刘启-人物'},
      {'label': '丁奉',
       'value': 1,
       'id': 46,
       'categories': ['人物'],
       'info': '丁奉-人物'},
      {'label': '徐盛',
       'value': 1,
       'id': 47,
       'categories': ['人物'],
       'info': '徐盛-人物'},
      {'label': '兰陵',
       'value': 1,
       'id': 48,
       'categories': ['地点'],
       'info': '兰陵-地点'},
      {'label': '朱晧 ',
       'value': 1,
       'id': 49,
       'categories': ['人物'],
       'info': '朱晧 -人物'},
      {'label': '吕布',
       'value': 3,
       'id': 50,
       'categories': ['人物'],
       'info': '吕布-人物'},
      {'label': '周瑜',
       'value': 2,
       'id': 51,
       'categories': ['人物'],
       'info': '周瑜-人物'},
      {'label': '袁尚',
       'value': 1,
       'id': 52,
       'categories': ['人物'],
       'info': '袁尚-人物'},
      {'label': '黄盖',
       'value': 1,
       'id': 53,
       'categories': ['人物'],
       'info': '黄盖-人物'},
      {'label': '刘胜',
       'value': 1,
       'id': 54,
       'categories': ['人物'],
       'info': '刘胜-人物'},
      {'label': '诸葛亮',
       'value': 6,
       'id': 55,
       'categories': ['人物'],
       'info': '诸葛亮-人物'},
      {'label': '甘宁',
       'value': 1,
       'id': 56,
       'categories': ['人物'],
       'info': '甘宁-人物'},
      {'label': '定襄',
       'value': 1,
       'id': 57,
       'categories': ['地点'],
       'info': '定襄-地点'},
      {'label': '刘表',
       'value': 2,
       'id': 58,
       'categories': ['人物'],
       'info': '刘表-人物'},
      {'label': '于禁',
       'value': 1,
       'id': 59,
       'categories': ['人物'],
       'info': '于禁-人物'},
      {'label': '鲁肃',
       'value': 1,
       'id': 60,
       'categories': ['人物'],
       'info': '鲁肃-人物'},
      {'label': '曹植',
       'value': 1,
       'id': 61,
       'categories': ['人物'],
       'info': '曹植-人物'},
      {'label': '曹纯',
       'value': 1,
       'id': 62,
       'categories': ['人物'],
       'info': '曹纯-人物'},
      {'label': '贾诩',
       'value': 1,
       'id': 63,
       'categories': ['人物'],
       'info': '贾诩-人物'},
      {'label': '祝融',
       'value': 1,
       'id': 64,
       'categories': ['人物'],
       'info': '祝融-人物'},
      {'label': '吴王',
       'value': 1,
       'id': 65,
       'categories': ['官职'],
       'info': '吴王-官职'},
      {'label': '关羽',
       'value': 1,
       'id': 66,
       'categories': ['人物'],
       'info': '关羽-人物'},
      {'label': '吕蒙',
       'value': 1,
       'id': 67,
       'categories': ['人物'],
       'info': '吕蒙-人物'},
      {'label': '蒯越',
       'value': 1,
       'id': 68,
       'categories': ['人物'],
       'info': '蒯越-人物'},
      {'label': '曹爽',
       'value': 1,
       'id': 69,
       'categories': ['人物'],
       'info': '曹爽-人物'},
      {'label': '公孙瓒',
       'value': 2,
       'id': 70,
       'categories': ['人物'],
       'info': '公孙瓒-人物'},
      {'label': '糜竺',
       'value': 2,
       'id': 71,
       'categories': ['人物'],
       'info': '糜竺-人物'},
      {'label': '沙摩柯',
       'value': 1,
       'id': 72,
       'categories': ['人物'],
       'info': '沙摩柯-人物'},
      {'label': '夏侯楙',
       'value': 2,
       'id': 73,
       'categories': ['人物'],
       'info': '夏侯楙-人物'},
      {'label': '尚书令',
       'value': 1,
       'id': 74,
       'categories': ['官职'],
       'info': '尚书令-官职'},
      {'label': '太尉',
       'value': 1,
       'id': 75,
       'categories': ['官职'],
       'info': '太尉-官职'},
      {'label': '曹操',
       'value': 26,
       'id': 76,
       'categories': ['人物'],
       'info': '曹操-人物'},
      {'label': '徐庶',
       'value': 2,
       'id': 77,
       'categories': ['人物'],
       'info': '徐庶-人物'},
      {'label': '曹嵩',
       'value': 1,
       'id': 78,
       'categories': ['人物'],
       'info': '曹嵩-人物'},
      {'label': '朱儁',
       'value': 4,
       'id': 79,
       'categories': ['人物'],
       'info': '朱儁-人物'},
      {'label': '张绣',
       'value': 1,
       'id': 80,
       'categories': ['人物'],
       'info': '张绣-人物'},
      {'label': '邓艾',
       'value': 1,
       'id': 81,
       'categories': ['人物'],
       'info': '邓艾-人物'},
      {'label': '钟会',
       'value': 1,
       'id': 82,
       'categories': ['人物'],
       'info': '钟会-人物'},
      {'label': '沛国',
       'value': 1,
       'id': 83,
       'categories': ['地点'],
       'info': '沛国-地点'},
      {'label': '关兴',
       'value': 1,
       'id': 84,
       'categories': ['人物'],
       'info': '关兴-人物'},
      {'label': '蒋琬',
       'value': 1,
       'id': 85,
       'categories': ['人物'],
       'info': '蒋琬-人物'},
      {'label': '荀攸',
       'value': 2,
       'id': 86,
       'categories': ['人物'],
       'info': '荀攸-人物'},
      {'label': '小乔',
       'value': 2,
       'id': 87,
       'categories': ['人物'],
       'info': '小乔-人物'},
      {'label': '典韦',
       'value': 1,
       'id': 88,
       'categories': ['人物'],
       'info': '典韦-人物'},
      {'label': '程普',
       'value': 1,
       'id': 89,
       'categories': ['人物'],
       'info': '程普-人物'},
      {'label': '孙尚香',
       'value': 1,
       'id': 90,
       'categories': ['人物'],
       'info': '孙尚香-人物'},
      {'label': '张飞',
       'value': 1,
       'id': 91,
       'categories': ['人物'],
       'info': '张飞-人物'},
      {'label': '张梁',
       'value': 1,
       'id': 92,
       'categories': ['人物'],
       'info': '张梁-人物'},
      {'label': '周绍范',
       'value': 1,
       'id': 93,
       'categories': ['人物'],
       'info': '周绍范-人物'},
      {'label': '高顺',
       'value': 1,
       'id': 94,
       'categories': ['人物'],
       'info': '高顺-人物'},
      {'label': '皇帝',
       'value': 2,
       'id': 95,
       'categories': ['官职'],
       'info': '皇帝-官职'},
      {'label': '孙策',
       'value': 3,
       'id': 96,
       'categories': ['人物'],
       'info': '孙策-人物'},
      {'label': '孙坚',
       'value': 3,
       'id': 97,
       'categories': ['人物'],
       'info': '孙坚-人物'},
      {'label': '姜维',
       'value': 1,
       'id': 98,
       'categories': ['人物'],
       'info': '姜维-人物'},
      {'label': '荀彧',
       'value': 2,
       'id': 99,
       'categories': ['人物'],
       'info': '荀彧-人物'},
      {'label': '黄忠',
       'value': 1,
       'id': 100,
       'categories': ['人物'],
       'info': '黄忠-人物'},
      {'label': '曹真',
       'value': 1,
       'id': 101,
       'categories': ['人物'],
       'info': '曹真-人物'},
      {'label': '袁绍',
       'value': 2,
       'id': 102,
       'categories': ['人物'],
       'info': '袁绍-人物'},
      {'label': '王允',
       'value': 1,
       'id': 103,
       'categories': ['人物'],
       'info': '王允-人物'},
      {'label': '司马昭',
       'value': 3,
       'id': 104,
       'categories': ['人物'],
       'info': '司马昭-人物'},
      {'label': '孙韶',
       'value': 1,
       'id': 105,
       'categories': ['人物'],
       'info': '孙韶-人物'},
      {'label': '成紀',
       'value': 1,
       'id': 106,
       'categories': ['地点'],
       'info': '成紀-地点'},
      {'label': '蒋钦',
       'value': 1,
       'id': 107,
       'categories': ['人物'],
       'info': '蒋钦-人物'},
      {'label': '会稽',
       'value': 1,
       'id': 108,
       'categories': ['地点'],
       'info': '会稽-地点'},
      {'label': '司马炎',
       'value': 1,
       'id': 109,
       'categories': ['人物'],
       'info': '司马炎-人物'},
      {'label': '乐进',
       'value': 1,
       'id': 110,
       'categories': ['人物'],
       'info': '乐进-人物'},
      {'label': '曹丕',
       'value': 1,
       'id': 111,
       'categories': ['人物'],
       'info': '曹丕-人物'},
      {'label': '董卓',
       'value': 1,
       'id': 112,
       'categories': ['人物'],
       'info': '董卓-人物'},
      {'label': '张昭',
       'value': 1,
       'id': 113,
       'categories': ['人物'],
       'info': '张昭-人物'},
      {'label': '马腾',
       'value': 2,
       'id': 114,
       'categories': ['人物'],
       'info': '马腾-人物'},
      {'label': '司马师',
       'value': 2,
       'id': 115,
       'categories': ['人物'],
       'info': '司马师-人物'},
      {'label': '周法尚',
       'value': 4,
       'id': 116,
       'categories': ['人物'],
       'info': '周法尚-人物'},
      {'label': '大乔',
       'value': 2,
       'id': 117,
       'categories': ['人物'],
       'info': '大乔-人物'},
      {'label': '诸葛瞻',
       'value': 4,
       'id': 118,
       'categories': ['人物'],
       'info': '诸葛瞻-人物'},
      {'label': '赵云',
       'value': 1,
       'id': 119,
       'categories': ['人物'],
       'info': '赵云-人物'},
      {'label': '糜芳',
       'value': 2,
       'id': 120,
       'categories': ['人物'],
       'info': '糜芳-人物'},
      {'label': '张辽',
       'value': 2,
       'id': 121,
       'categories': ['人物'],
       'info': '张辽-人物'},
      {'label': '刘禅',
       'value': 2,
       'id': 122,
       'categories': ['人物'],
       'info': '刘禅-人物'},
      {'label': '清河公主',
       'value': 2,
       'id': 123,
       'categories': ['人物'],
       'info': '清河公主-人物'},
      {'label': '刘宽',
       'value': 3,
       'id': 124,
       'categories': ['人物'],
       'info': '刘宽-人物'},
      {'label': '左武卫将军',
       'value': 1,
       'id': 125,
       'categories': ['官职'],
       'info': '左武卫将军-官职'},
      {'label': '程昱',
       'value': 1,
       'id': 126,
       'categories': ['人物'],
       'info': '程昱-人物'},
      {'label': '丞相',
       'value': 1,
       'id': 127,
       'categories': ['官职'],
       'info': '丞相-官职'},
      {'label': '周绍基',
       'value': 1,
       'id': 128,
       'categories': ['人物'],
       'info': '周绍基-人物'},
      {'label': '张宝',
       'value': 2,
       'id': 129,
       'categories': ['人物'],
       'info': '张宝-人物'}],
     'edges': [{'id': 0, 'label': '儿子', 'from': 32, 'to': 91},
      {'id': 1, 'label': '儿子', 'from': 84, 'to': 66},
      {'id': 2, 'label': '女婿', 'from': 118, 'to': 122},
      {'id': 3, 'label': '儿子', 'from': 118, 'to': 55},
      {'id': 4, 'label': '上级', 'from': 22, 'to': 55},
      {'id': 5, 'label': '妻子', 'from': 38, 'to': 55},
      {'id': 6, 'label': '父亲', 'from': 21, 'to': 38},
      {'id': 7, 'label': '哥哥', 'from': 23, 'to': 55},
      {'id': 8, 'label': '弟弟', 'from': 40, 'to': 70},
      {'id': 9, 'label': '上级', 'from': 22, 'to': 4},
      {'id': 10, 'label': '父亲', 'from': 114, 'to': 4},
      {'id': 11, 'label': '弟弟', 'from': 40, 'to': 70},
      {'id': 12, 'label': '父亲', 'from': 114, 'to': 4},
      {'id': 13, 'label': '上级', 'from': 22, 'to': 77},
      {'id': 14, 'label': '上级', 'from': 76, 'to': 77},
      {'id': 15, 'label': '儿子', 'from': 54, 'to': 45},
      {'id': 16, 'label': '女婿', 'from': 118, 'to': 122},
      {'id': 17, 'label': '上级', 'from': 22, 'to': 98},
      {'id': 18, 'label': '儿子', 'from': 118, 'to': 55},
      {'id': 19, 'label': '上级', 'from': 22, 'to': 55},
      {'id': 20, 'label': '上级', 'from': 19, 'to': 23},
      {'id': 21, 'label': '哥哥', 'from': 71, 'to': 120},
      {'id': 22, 'label': '上级', 'from': 22, 'to': 120},
      {'id': 23, 'label': '上级', 'from': 22, 'to': 71},
      {'id': 24, 'label': '上级', 'from': 22, 'to': 31},
      {'id': 25, 'label': '上级', 'from': 22, 'to': 119},
      {'id': 26, 'label': '上级', 'from': 22, 'to': 100},
      {'id': 27, 'label': '上级', 'from': 22, 'to': 10},
      {'id': 28, 'label': '上级', 'from': 22, 'to': 16},
      {'id': 29, 'label': '上级', 'from': 22, 'to': 85},
      {'id': 30, 'label': '上级', 'from': 22, 'to': 37},
      {'id': 31, 'label': '上级', 'from': 22, 'to': 2},
      {'id': 32, 'label': '上级', 'from': 22, 'to': 72},
      {'id': 33, 'label': '叔叔', 'from': 1, 'to': 10},
      {'id': 34, 'label': '弟弟', 'from': 13, 'to': 37},
      {'id': 35, 'label': '妻子', 'from': 64, 'to': 2},
      {'id': 36, 'label': '儿子', 'from': 105, 'to': 96},
      {'id': 37, 'label': '下级', 'from': 112, 'to': 17},
      {'id': 38, 'label': '下级', 'from': 103, 'to': 17},
      {'id': 39, 'label': '上级', 'from': 50, 'to': 94},
      {'id': 40, 'label': '上级', 'from': 50, 'to': 30},
      {'id': 41, 'label': '上级', 'from': 50, 'to': 121},
      {'id': 42, 'label': '上级', 'from': 76, 'to': 121},
      {'id': 43, 'label': '上级', 'from': 76, 'to': 68},
      {'id': 44, 'label': '上级', 'from': 58, 'to': 7},
      {'id': 45, 'label': '上级', 'from': 58, 'to': 11},
      {'id': 46, 'label': '上级', 'from': 76, 'to': 36},
      {'id': 47, 'label': '弟弟', 'from': 129, 'to': 39},
      {'id': 48, 'label': '弟弟', 'from': 92, 'to': 129},
      {'id': 49, 'label': '弟弟', 'from': 20, 'to': 102},
      {'id': 50, 'label': '儿子', 'from': 6, 'to': 102},
      {'id': 51, 'label': '弟弟', 'from': 26, 'to': 6},
      {'id': 52, 'label': '弟弟', 'from': 52, 'to': 26},
      {'id': 53, 'label': '女儿', 'from': 90, 'to': 24},
      {'id': 54, 'label': '妻子', 'from': 24, 'to': 97},
      {'id': 55, 'label': '儿子', 'from': 96, 'to': 97},
      {'id': 56, 'label': '弟弟', 'from': 19, 'to': 96},
      {'id': 57, 'label': '妹妹', 'from': 87, 'to': 117},
      {'id': 58, 'label': '丈夫', 'from': 51, 'to': 87},
      {'id': 59, 'label': '上级', 'from': 19, 'to': 51},
      {'id': 60, 'label': '上级', 'from': 19, 'to': 14},
      {'id': 61, 'label': '岳母', 'from': 117, 'to': 14},
      {'id': 62, 'label': '上级', 'from': 19, 'to': 46},
      {'id': 63, 'label': '上级', 'from': 19, 'to': 47},
      {'id': 64, 'label': '上级', 'from': 19, 'to': 60},
      {'id': 65, 'label': '上级', 'from': 19, 'to': 113},
      {'id': 66, 'label': '上级', 'from': 19, 'to': 107},
      {'id': 67, 'label': '上级', 'from': 19, 'to': 27},
      {'id': 68, 'label': '上级', 'from': 19, 'to': 3},
      {'id': 69, 'label': '上级', 'from': 19, 'to': 5},
      {'id': 70, 'label': '上级', 'from': 19, 'to': 67},
      {'id': 71, 'label': '上级', 'from': 19, 'to': 56},
      {'id': 72, 'label': '上级', 'from': 19, 'to': 53},
      {'id': 73, 'label': '上级', 'from': 19, 'to': 43},
      {'id': 74, 'label': '上级', 'from': 19, 'to': 89},
      {'id': 75, 'label': '父亲', 'from': 78, 'to': 76},
      {'id': 76, 'label': '上级', 'from': 76, 'to': 80},
      {'id': 77, 'label': '女儿', 'from': 123, 'to': 76},
      {'id': 78, 'label': '丈夫', 'from': 73, 'to': 123},
      {'id': 79, 'label': '父亲', 'from': 44, 'to': 73},
      {'id': 80, 'label': '上级', 'from': 76, 'to': 18},
      {'id': 81, 'label': '上级', 'from': 76, 'to': 44},
      {'id': 82, 'label': '儿子', 'from': 69, 'to': 101},
      {'id': 83, 'label': '上级', 'from': 76, 'to': 33},
      {'id': 84, 'label': '上级', 'from': 76, 'to': 8},
      {'id': 85, 'label': '上级', 'from': 76, 'to': 110},
      {'id': 86, 'label': '上级', 'from': 76, 'to': 9},
      {'id': 87, 'label': '上级', 'from': 76, 'to': 88},
      {'id': 88, 'label': '上级', 'from': 76, 'to': 99},
      {'id': 89, 'label': '上级', 'from': 76, 'to': 86},
      {'id': 90, 'label': '叔叔', 'from': 99, 'to': 86},
      {'id': 91, 'label': '上级', 'from': 76, 'to': 63},
      {'id': 92, 'label': '上级', 'from': 76, 'to': 29},
      {'id': 93, 'label': '上级', 'from': 76, 'to': 126},
      {'id': 94, 'label': '上级', 'from': 76, 'to': 59},
      {'id': 95, 'label': '上级', 'from': 76, 'to': 81},
      {'id': 96, 'label': '上级', 'from': 76, 'to': 82},
      {'id': 97, 'label': '上级', 'from': 76, 'to': 0},
      {'id': 98, 'label': '儿子', 'from': 115, 'to': 29},
      {'id': 99, 'label': '弟弟', 'from': 104, 'to': 115},
      {'id': 100, 'label': '儿子', 'from': 104, 'to': 29},
      {'id': 101, 'label': '儿子', 'from': 109, 'to': 104},
      {'id': 102, 'label': '弟弟', 'from': 62, 'to': 12},
      {'id': 103, 'label': '儿子', 'from': 35, 'to': 76},
      {'id': 104, 'label': '弟弟', 'from': 61, 'to': 111},
      {'id': 105, 'label': '下级', 'from': 13, 'to': 22},
      {'id': 106, 'label': '父亲', 'from': 97, 'to': 19},
      {'id': 107, 'label': '母亲', 'from': 24, 'to': 19},
      {'id': 108, 'label': '父亲', 'from': 42, 'to': 25},
      {'id': 109, 'label': '担任', 'from': 42, 'to': 95},
      {'id': 110, 'label': '籍贯', 'from': 42, 'to': 106},
      {'id': 111, 'label': '担任', 'from': 76, 'to': 127},
      {'id': 112, 'label': '籍贯', 'from': 76, 'to': 83},
      {'id': 113, 'label': '担任', 'from': 19, 'to': 65},
      {'id': 114, 'label': '儿子', 'from': 79, 'to': 49},
      {'id': 115, 'label': '籍贯', 'from': 79, 'to': 108},
      {'id': 116, 'label': '任职', 'from': 79, 'to': 41},
      {'id': 117, 'label': '任职', 'from': 79, 'to': 48},
      {'id': 118, 'label': '籍贯', 'from': 34, 'to': 41},
      {'id': 119, 'label': '担任', 'from': 124, 'to': 75},
      {'id': 120, 'label': '父亲', 'from': 124, 'to': 15},
      {'id': 121, 'label': '担任', 'from': 124, 'to': 74},
      {'id': 122, 'label': '担任', 'from': 25, 'to': 95},
      {'id': 123, 'label': '儿子', 'from': 25, 'to': 28},
      {'id': 124, 'label': '任职', 'from': 116, 'to': 57},
      {'id': 125, 'label': '担任', 'from': 116, 'to': 125},
      {'id': 126, 'label': '儿子', 'from': 116, 'to': 128},
      {'id': 127, 'label': '儿子', 'from': 116, 'to': 93}]}}
    /* eslint-disable */
    igraph.i18n.setLanguage("chs");
    var app = new igraph.GraphNavigator(document.getElementById('graphArea'), 'LIGHT');
    //app.connectService(igraph.LocalGraph.fromGson(this.props.handledData._ner_index[0]['data']));
    app.connectService(igraph.LocalGraph.fromGson(gson3));

    if (this.props.handledDataToNameEntity.deal_textcontent) {
      if (this.props.handledDataToNameEntity.deal_textcontent.indexOf('</span>') != -1) {
        this.setState({
          textcontent: ''
        })
      } else {

        this.setState({
          textcontent: this.props.handledDataToNameEntity.deal_textcontent
        }, () => {

          if (this.props.handledDataToNameEntity.executeNamedEntity == 1) {
            //如果是自动句读页面过来的
            var obj = this.props.handledDataToNameEntity;
            //执行专名查询  已执行
            obj.executeNamedEntity = 0;
            //console.log('execute NER');
            this.props.dispatch({ type: "initNamedEntity", data: { handledDataToNameEntity: obj } });
            if (this.state.textcontent) {
              this.changeAction('4')
            }

          }
        });
      }
    } else {
      this.setState({
        textcontent: ''
      })
    }



    let tempToken = localStorage.getItem("token");
    let tempID = localStorage.getItem("id");
    if (tempToken) {
      let rateobj = localStorage.getItem(tempID);
      if (rateobj) {
        rateobj = JSON.parse(rateobj);
        let tempRateNum = rateobj['rateNumMask'];
        if (tempRateNum != undefined) {
          this.setState({
            rateNumMask: tempRateNum
          })
        }
      }
    } else {
      let tempNum = localStorage.getItem("rateNumMask");
      if (tempNum) {
        this.setState({
          rate: tempNum
        })
      }

    }


  }
  getTagList = (callback) => {
    let _this = this
    reqwest({
      url: '/tag/getList.htm',
      method: 'post',
      type: 'json',
      data: {},
      success: function (data) {
        if (data.code === 0) {
          _this.setState({
            tagList: data.list
          }, () => {
            _this._tagList = data.list
            callback && callback()
          })
        }
      }
    })
  }

  doTextMask = (source,rateNum,fildID,dataSou) => {
    let _this = this;
    if (_this.state.text_type == 'a') {
      _this.setState({
        visible5: true,
        complete: false,
        progress: 0
      })

      let tempContent = doText(source, this._ner_index)

      console.log('tempContent is:',tempContent);
      console.log('source is:',source);
      console.log('ner_index is:',this._ner_index);

      _this.setState({
        deal_textcontent: tempContent
      })
      var obj = {}
      obj.deal_textcontent = tempContent;
      obj.fildID = fildID;
      obj._ner_index = this._ner_index;
      obj.source = source;
      obj.rateNumMask = rateNum;
      obj.dataSou = dataSou;
      _this.props.dispatch({ type: "initNamedEntityDisposed", data: { handledData: obj } });
    }
  }

  search = (keyword) => {
    let _this = this

    if (!keyword) {
      return [];
    }
    let _textcontent = _this._textcontent
    let c = Array.from(_this._textcontent)
    let ks = _textcontent.split(keyword);

    let ps = []
    ks.reduce((t, item, index, arr) => {
      let st = t + Array.from(item).length
      let ed = st + Array.from(keyword).length
      t = ed
      ps.push([st, ed - 1])
      return t
    }, 0)

    // let c = Array.from(_this._textcontent)
    // // let c = _this._textcontent
    // let ws = Array.from(keyword)
    // let ps = this._indexOfText(ws[0], c)
    let searchResult = []
    for (let i = 0; i < ps.length; i++) {
      let r = ''
      let p = ps[i]
      let start = 0;
      let end = -1;
      if (ps.length > (i + 1)) {
        let pa = ps[i + 1];
        if (pa[0] - p[1] > 5) {
          end = p[1] + 5
        } else {
          end = pa[0]
        }
      } else {
        if (c.length - 1 - p[1] > 5) {
          end = p[1] + 5
        }
      }
      if (i > 0) {
        let pb = ps[i - 1]
        if (p[0] - pb[1] > 5) {
          start = p[0] - 5
        } else {
          start = pb[1]
        }
      } else {
        if (p[0] - 0 > 5) {
          start = p[0] - 5
        } else {
          start = 0
        }
      }
      if (end > -1) {
        r = c.slice(start, end)
      } else {
        // r = c.slice(start)
      }
      if (r) {

        let row = _this._loop(keyword, r.join(''), _this.state.currStyle)
        searchResult.push({
          key: i,
          content: row,
          position: p
        })
      }
    }
    return searchResult
  }


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  //将搜索到的文字描红处理
  _loop(value = '', str, customStyle) {
    // console.log(value)
    // console.log(str)
    let data = [];

    let lines = str.split('\n')
    if (!value) {
      return lines
    }
    let v = value

    lines.forEach(line => {
      let ar = [];
      let title = line.replace(new RegExp(value, "g"), '<*>' + value + '<*>')
      if (title === line) {
        title = title.replace(new RegExp(convert(value, this.locale), "g"), '<*>' + convert(value, this.locale) + '<*>');
        if (title !== line) {
          v = convert(value, this.locale)
        }
      }

      let nameArray = title.split('<*>');

      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i] === v) {
          ar.push(<span key={i} style={customStyle}>{v}</span>);
        } else {
          ar.push(nameArray[i]);
        }
      }
      data.push(ar)
    })
    return data
  }

  ban = () => {
    if (this.state.task_uid) {
    } else {
      if (!localStorage.getItem("token")) {
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴内容长度超过限制，目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      } else if (localStorage.getItem("token")) {
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴内容长度超过限制，目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      }
    }
  }

  
  loadExample = (value) => {
    if (value == '1'){
      let initLoad1 = '太仓令其除之！齐太仓令淳于意有罪，太仓令，齐王国官也。师古曰：非也，姓孙。以中大夫令免为车骑将军，屯飞狐。夫士卒尽家人子，师古曰：家人子，谓庶人家之子也。今民入粟受爵至五大夫以上，师古曰：五大夫，第九爵。是时，太中大夫邓通方爱幸，赏赐累巨万；帝尝燕飮通家，其宠幸无比。乃拜周亚夫为中尉。于是贵新垣平至上大夫，周官有上大夫。是日，令唐持节赦魏尚，复以为云中守，而拜唐为车骑都尉。初，高祖贤文王 芮，制诏御史：“长沙王忠，其定著令。初，高祖贤文王 芮，制诏御史：“长沙王忠，其定著令。”帝于是从谊计，徙淮阳王武为梁王，北界泰山，西至高阳，得大县四十余城。贡父曰：“长沙王忠，其定著令，”定著令者，谓于令著长沙王车服土地之类也。又分齐为六国；丙寅，立齐悼惠王子在者六人：杨虚侯 将闾为齐王，安都侯 志为济北王，武成侯 贤为菑川王，白石侯雄渠为胶东王，平昌侯 卬为胶西王，扐侯 辟光为济南王。'
      this.setState({
        textcontent: initLoad1,
      })
    } else if (value == '2'){
      let initLoad2 = '寧宗沖聖嗣孝皇帝諱懿璘質班明宗第二子也母曰八不沙皇后乃蠻真氏初武宗有子二人長明宗次文宗延祐中明宗封周王出居朔漠泰定之際正統遂偏天曆元年文宗入紹大統內難既平即遣使奉皇帝璽綬北迎明宗明宗崩文宗復即皇帝位明宗有子二人長妥懽帖木耳次即帝也天曆二年二月乙巳封帝為鄜王至順三年八月己酉文宗崩于上都皇后導揚末命申固讓初志傳位於明宗之子時妥懽帖木耳出居靜江帝以文宗眷愛之篤留京師太師太平王右丞相燕鐵木兒請立帝以繼大統於是遣使徵諸王會京師中書百司政務咸啟中宮取進止甲寅中書省臣奉中宮旨預備大朝會賞賜金銀幣帛等物乙卯燕鐵木兒奉中宮旨賜駙馬也不干子歡忒哈赤太尉孛蘭奚句容郡王荅鄰荅里僉事小薛阿麻剌台之子禿帖木兒公主本荅里諸王丑漢妃公主台忽都魯諸王卯澤妃公主完者台及公主本荅里徹里帖木兒等金銀幣鈔有差是月渾源雲內二州隕霜殺禾冀寧路之陽曲河曲二縣及荊門州皆旱江水又溢高郵府之寶應興化二縣德安府之雲夢應城二縣大雨水'
      this.setState({
        textcontent:initLoad2,
      })
    } else if (value == '3'){
      let initLoad3 = '秋七月癸酉太祖自將救洪都癸未次湖口先伏兵涇江口及南湖觜遏友諒歸路檄信州兵守武陽渡友諒聞太祖至解圍逆戰於鄱陽湖友諒兵號六十萬聯巨舟為陣樓櫓高十餘丈綿亘數十里旌旂戈盾望之如山丁亥遇於康郎山太祖分軍十一隊以禦之戊子合戰徐達擊其前鋒俞通海以火礟焚其舟數十殺傷畧相當友諒驍將張定邊直犯太祖舟舟膠於沙不得退危甚常遇春從旁射中定邊通海復來援舟驟進水湧太祖舟乃得脫己丑友諒悉巨艦出戰諸將舟小仰攻不利有怖色太祖親麾之不前斬退縮者十餘人人皆殊死戰會日晡大風起東北乃命敢死士操七舟實火藥蘆葦中縱火焚友諒舟風烈火熾烟燄漲天湖水盡赤友諒兵大亂諸將鼓噪乘之斬首二千餘級焚溺死者無算友諒氣奪辛卯復戰友諒復大敗於是斂舟自守不敢更戰壬辰太祖移軍扼左蠡友諒亦退保渚磯相持三日其左右二金吾將軍皆降友諒勢益蹙忿甚盡殺所獲將士而太祖則悉還所俘傷者傅以善藥且祭其親戚諸將陣亡者八月壬戌友諒食盡趨南湖觜為南湖軍所遏遂突湖口太祖邀之順流搏戰及於涇江涇江軍復遮擊之友諒中流矢死'
      this.setState({
        textcontent:initLoad3,
      })
    } else if (value == '4') {
      let initLoad4 = '初鄭武公娶于申曰武姜生莊公及共叔段莊公寤生驚姜氏故名曰寤生遂惡之愛共叔段欲立之亟請於武公公弗許及莊公即位為之請制公曰制巖邑也虢叔死焉佗邑唯命請京使居之謂之京城大叔祭仲曰都城過百雉國之害也先王之制大都不過參國之一中五之一小九之一今京不度非制也君將不堪公曰姜氏欲之焉辟害對曰姜氏何厭之有不如早為之所無使滋蔓蔓難圖也蔓草猶不可除況君之寵弟乎公曰多行不義必自斃子姑待之既而大叔命西鄙北鄙貳於己公子呂曰國不堪貳君將若之何欲與大叔臣請事之若弗與則請除之無生民心公曰無庸將自及大叔又收貳以為己邑至于廩延子封曰可矣厚將得眾公曰不義不暱厚將崩大叔完聚繕甲兵具卒乘將襲鄭夫人將啟之公聞其期曰可矣命子封帥車二百乘以伐京京叛大叔段段入于鄢公伐諸鄢五月辛丑大叔出奔共'
      this.setState({
        textcontent:initLoad4,
      })
    } else if (value == '5') {
      let initLoad5 = '四年春齊侯以諸侯之師侵蔡蔡潰遂伐楚楚子使與師言曰君處北海寡人處南海唯是風馬牛不相及也不虞君之涉吾地也何故管仲對曰昔召康公命我先君大公曰五侯九伯女實征之以夾輔周室賜我先君履東至于海西至于河南至于穆陵北至于無棣爾貢包茅不入王祭不共無以縮酒寡人是徵昭王南征而不復寡人是問對曰貢之不入寡君之罪也敢不共給昭王之不復君其問諸水濱師進次于陘夏楚子使屈完如師師退次于召陵齊侯陳諸侯之師與屈完乘而觀之齊侯曰豈不穀是為先君之好是繼與不穀同好如何對曰君惠徼福於敝邑之社稷辱收寡君寡君之願也齊侯曰以此眾戰誰能禦之以此攻城何城不克對曰君若以德綏諸侯誰敢不服君若以力楚國方城以為城漢水以為池雖眾無所用之屈完及諸侯盟'
      this.setState({
        textcontent:initLoad5,
      })
    } else if (value == '6') {
      let initLoad6 = '沛公旦日從百餘騎來見項王至鴻門謝曰臣與將軍戮力而攻秦將軍戰河北臣戰河南然不自意能先入關破秦得復見將軍於此今者有小人之言令將軍與臣有卻項王曰此沛公左司馬曹無傷言之不然籍何以至此項王即日因留沛公與飲項王項伯東嚮坐亞父南嚮坐亞父者范增也沛公北嚮坐張良西嚮侍范增數目項王舉所佩玉玦以示之者三項王默然不應范增起出召項莊謂曰君王為人不忍若入前為壽壽畢請以劍舞因擊沛公於坐殺之不者若屬皆且為所虜莊則入為壽壽畢曰君王與沛公飲軍中無以為樂請以劍舞項王曰諾項莊拔劍起舞項伯亦拔劍起舞常以身翼蔽沛公莊不得擊於是張良至軍門見樊噲樊噲曰今日之事何如良曰甚急今者項莊拔劍舞其意常在沛公也噲曰此迫矣臣請入與之同命噲即帶劍擁盾入軍門交戟之衛士欲止不內樊噲側其盾以撞衛士仆地噲遂入披帷西嚮立瞋目視項王頭髮上指目眥盡裂項王按劍而跽曰客何為者張良曰沛公之參乘樊噲者也項王曰壯士賜之卮酒則與斗卮酒'
      this.setState({
        textcontent:initLoad6,
      })
    } else if (value == '7') {
      let initLoad7 = '陳勝者陽城人也字涉吳廣者陽夏人也字叔陳涉少時嘗與人傭耕輟耕之壟上悵恨久之曰苟富貴無相忘庸者笑而應曰若為庸耕何富貴也陳涉太息曰嗟乎燕雀安知鴻鵠之志哉二世元年七月發閭左適戍漁陽九百人屯大澤鄉陳勝吳廣皆次當行為屯長會天大雨道不通度已失期失期法皆斬陳勝吳廣乃謀曰今亡亦死舉大計亦死等死死國可乎陳勝曰天下苦秦久矣吾聞二世少子也不當立當立者乃公子扶蘇扶蘇以數諫故上使外將兵今或聞無罪二世殺之百姓多聞其賢未知其死也項燕為楚將數有功愛士卒楚人憐之或以為死或以為亡今誠以吾眾詐自稱公子扶蘇項燕為天下唱宜多應者吳廣以為然乃行卜卜者知其指意曰足下事皆成有功然足下卜之鬼乎陳勝吳廣喜念鬼曰此教我先威眾耳乃丹書帛曰陳勝王置人所罾魚腹中卒買魚烹食得魚腹中書固以怪之矣又閒令吳廣之次所旁叢祠中夜篝火狐鳴呼曰大楚興陳勝王卒皆夜驚恐旦日卒中往往語皆指目陳勝'
      this.setState({
        textcontent:initLoad7,
      })
    } else if (value == '8') {
      let initLoad8 = '是時秦兵旣盛都下震恐謝玄入問計於謝安安夷然答曰已別有旨旣而寂然玄不敢復言乃令張玄重請安遂命駕出遊山墅親朋畢集與玄圍棋賭墅安棋常劣於玄是日玄懼便為敵手而又不勝安遂游陟至夜乃還桓沖深以根本為憂遣精銳三千入衞京師謝安固卻之曰朝廷處分已定兵甲無闕西藩宜留以為防沖對佐吏歎曰謝安石有廟堂之量不閑將略今大敵垂至方遊談不暇遣諸不經事少年拒之衆又寡弱天下事已可知吾其左衽矣以琅邪王道子錄尚書六條事冬十月秦陽平公融等攻壽陽癸酉克之執平虜將軍徐元喜等融以其參軍河南郭褒為淮南太守慕容垂拔鄖城胡彬聞壽陽陷退保硤石融進攻之秦衞將軍梁成等帥衆五萬屯于洛澗柵淮以遏東兵謝石謝玄等去洛澗二十五里而軍憚成不敢進胡彬糧盡潛遣使告石等曰今賊盛糧盡恐不復見大軍秦人獲之送於陽平公融融馳使白秦王堅曰賊少易擒但恐逃去宜速赴之堅乃留大軍於項城引輕騎八千兼道就融於壽陽'
      this.setState({
        textcontent:initLoad8,
      })
    } else if (value == '9') {
      let initLoad9 = '曹操自江陵將順江東下諸葛亮謂劉備曰事急矣請奉命求救於孫將軍遂與魯肅俱詣孫權亮見權於柴桑說權曰海內大亂將軍起兵江東劉豫州收衆漢南與曹操共爭天下今操芟夷大難略已平矣遂破荊州威震四海英雄無用武之地故豫州遁逃至此願將軍量力而處之若能以吳越之衆與中國抗衡不如早與之絕若不能何不按兵束甲北面而事之今將軍外託服從之名而內懷猶豫之計事急而不斷禍至無日矣權曰苟如君言劉豫州何不遂事之乎亮曰田橫齊之壯士耳猶守義不辱況劉豫州王室之胄英才蓋世衆士慕仰若水之歸海若事之不濟此乃天也安能復為之下乎權勃然曰吾不能舉全吳之地十萬之衆受制於人吾計決矣非劉豫州莫可以當曹操者然豫州新敗之後安能抗此難乎亮曰豫州軍雖敗於長坂今戰士還者及關羽水軍精甲萬人劉琦合江夏戰士亦不下萬人曹操之衆遠來疲敝聞追豫州輕騎一日一夜行三百餘里此所謂強弩之末勢不能穿魯縞者也'
      this.setState({
        textcontent:initLoad9,
      })
    } else if (value == '10') {
      let initLoad9 = ''
      this.setState({
        textcontent:initLoad9,
      })
    }
  }


  render() {
    const { fileList } = this.state
    const props = {
      multiple: false,//不允许多文件上传
      onRemove: (file) => {
        let fs = fileList.filter(item => item.uid !== file.uid)
        this.setState({
          fileList: fs
        })
      },
      // fileList: fileList,
      accept: '.txt',
      beforeUpload: (file, files) => {
        let _this = this
        _this.setState({
          fileList: [file]
        })
        return new Promise((rs, re) => {
          let reader = new FileReader();
          reader.readAsText(file)
          reader.onload = (result) => {
            let content = result.currentTarget.result
            if (content) {
              if (content.length > 800) {
                message.warn('上传文件内容长度超过限制，目前只支持最大800字，已自动截取。')
                content = content.substring(0, 800)
              }
              _this.setState({ textcontent: content, task_uid: file.uid, task_identify: file.name + '_' + file.lastModified, editable: true }, () => {
                // _this.tagtext()
                _this._jd_deal_content_ner = ''
              })
            }
          }
        })
      }
    };
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        {
          key: 'odd',
          text: '选择偶数行',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: '选择奇数行',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: '反选',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              let y = this.state.selectedRowKeys.filter(it => it == key)
              if (y.length === 0) {
                return true
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    var reg = new RegExp("ο", "g")
    return (
      <div id='graphArea' style={{height:'735px'}}></div>
    );
  }
}
function mapStateToProps(state) {
  return {
    dealTextContent: state.dealTextContent,
    handledDataToNameEntity: state.handledDataToNameEntity,
    handledData: state.handledData
  }
}
export default withRouter(connect(mapStateToProps)(NamedEntity));
