import React, { Component } from 'react';
import { Layout, Popover, Button, Modal, Input, Select } from 'antd';
import './ViewDetails.css';
import convert from '../../utils/locale_zh_convert';
import { ArrowLeftOutlined, ArrowRightOutlined, FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { withRouter, Link } from 'react-router-dom'
const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
class ViewDetails extends Component {
  state = { visible1: false, visible2: false };
  showModal1 = () => {
    this.setState({
      visible1: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };
  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };

  showModal2 = () => {
    this.setState({
      visible2: true,
    });
  };
  handleOk2 = e => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  handleCancel2 = e => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
  }
  render() {
    return (
      <Content>
        <div className='content'>
          <div className='Top_Vi'>
            <div className='Top_Vit'>
              <div>
                <span>{convert("李太白文集", this.locale)}</span>
                <span>{convert("提交人：唐雪梅", this.locale)}</span>
                <span>{convert("提交时间：2020-08-10", this.locale)}</span>
              </div>
              <div>
                <Link to='/Collation'><Button><ArrowLeftOutlined />{convert("返回", this.locale)}</Button></Link>
                <Button>{convert("待审核", this.locale)}</Button>
              </div>
            </div>
            <div className='Top_Vib'>
              <Button>{convert(`断点 `, this.locale)}</Button>
              <Button>{convert(`标点 `, this.locale)}</Button>
              <Button>{convert(`数据标注`, this.locale)}</Button>
              <Button>{convert(`词性标注`, this.locale)}</Button>
              <Button>{convert(`实体辨识`, this.locale)}</Button>
              <Button>{convert(`指代消解`, this.locale)}</Button>
            </div>
          </div>
          <div className='Body_Vi'>
            <p>{convert(`今將執行安樂死，卻突然爆出自己20年前遭緯來體育台封殺，他不懂自己哪裡得罪到電視台 `, this.locale)}</p>
            <p>{convert(`美國參議院針對今天總統布什所提名的勞工部長趙小蘭展開認可聽證會 `, this.locale)}</p>
          </div>
          <div className='footer_Vi'>
            <Input.Group compact >
              <Select defaultValue="0">
                <Option value="0">{convert(`专家评估难度打分`, this.locale)}</Option>
                <Option value="1">1级</Option>
                <Option value="2">2级</Option>
                <Option value="3">3级</Option>
                <Option value="4">4级</Option>
                <Option value="5">5级</Option>
                <Option value="6">6级</Option>
              </Select>
            </Input.Group>
            <Button>{convert(`保存 `, this.locale)}</Button>
            <Button onClick={this.showModal1}>{convert(`审核 `, this.locale)}</Button>
            <Button onClick={this.showModal2}>{convert(`驳回 `, this.locale)}</Button>
            <Modal
              className='gnlq1'
              width={400}
              visible={this.state.visible1}
              onOk={this.handleOk1}
              onCancel={this.handleCancel1}
              footer={null}
            >
              <span>{convert(`审核成功！ `, this.locale)}</span>
            </Modal>
            <Modal
              className='gnlq2'
              width={815}
              title={convert(`驳回原因`, this.locale)}
              visible={this.state.visible2}
              onOk={this.handleOk2}
              onCancel={this.handleCancel2}
              footer={null}
            >
              <div>
                <TextArea rows={4} placeholder="请输入500字以内" />
              </div>
              <div className='aqzs789'>
                <Button>{convert(`取消 `, this.locale)}</Button>
                <Button>{convert(`提交 `, this.locale)}</Button>
              </div>
            </Modal>
          </div>
        </div>
      </Content>
    );
  }
}
export default withRouter(ViewDetails);
