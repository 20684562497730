import React, { useState } from 'react'
import { PlusOutlined, MinusOutlined, LeftOutlined, RightOutlined, UndoOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { message } from 'antd';
import convert from '../../../utils/locale_zh_convert';

function ToolBar(props) {
    const { total, pageSize, onPageChange, initScaleSize, onScale, onResetScale, current, locale } = props
    const [scaleSize, setScaleSize] = useState(initScaleSize)
    return (
        <div className="viewer-toolbar">
            {/* <div className="page-box" style={{fontSize:'28px',display:'inline'}}>
                {current}/{total}
            </div> */}
            <ul>
                <li role="button" onClick={() => {
                    let pages = Math.ceil(total / pageSize)
                    if (pages === NaN || pages === 0) {
                        return
                    } else {
                        onPageChange(1)
                    }
                }}><StepBackwardOutlined style={{ color: '#FFF' }} /></li>
                <li role="button" onClick={() => {
                    let pages = Math.ceil(total / pageSize)
                    if (pages === NaN || pages === 0) {
                        return
                    } else if (current - 1 <= 0) {
                        message.warning(convert('已是第一页', locale))
                        return
                    } else {
                        onPageChange(current - 1)
                    }
                }}><LeftOutlined style={{ color: '#FFF' }} /></li>
                <li role="button"
                    onClick={() => {
                        let pages = Math.ceil(total / pageSize)
                        if (pages === NaN || pages === 0) {
                            return
                        } else if (pages < current + 1) {
                            message.warning(convert('已是最后一页', locale))
                            return
                        } else {
                            onPageChange(current + 1)
                        }
                    }}
                ><RightOutlined style={{ color: '#FFF' }} /></li>
                <li role="button" onClick={() => {
                    let pages = Math.ceil(total / pageSize)
                    if (pages === NaN || pages === 0) {
                        return
                    } else {
                        onPageChange(pages)
                    }
                }}><StepForwardOutlined style={{ color: '#FFF' }} /></li>
                <li role="button" onClick={() => {
                    setScaleSize(initScaleSize)
                    onResetScale(initScaleSize)
                }}><UndoOutlined style={{ color: '#FFF' }} /></li>
                <li role="button" onClick={() => {
                    if(scaleSize>1.29){
                        return
                    }else{
                        setScaleSize(scaleSize + 0.07)
                        onScale(scaleSize + 0.07)
                    }
                    
                }}> <PlusOutlined style={{ color: '#FFF' }} /></li>
                <li role="button" onClick={() => {
                    setScaleSize(scaleSize - 0.07)
                    onScale(scaleSize - 0.07)
                }}><MinusOutlined style={{ color: '#FFF' }} /></li>
            </ul>
        </div>
    )
}
export default ToolBar