import Immutable from 'immutable';

let initState = { isLogging: false, files: [] };

export function TextData(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {
        case 'set_files_data': {
            fromjs = fromjs.set('files', Immutable.fromJS(action.payload.files))
            return fromjs.toJS();
        }
        default:
            break;
    }
    return state;
}