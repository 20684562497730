import Immutable from 'immutable';

let initState = {}
export function DuDataReducer(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {
       
        case 'initAutomaticDuDisposed': {
            return action.data.duData;
        } 
        
        default:
            return state
        
    }
}