import React, { Component } from 'react';
import { Layout, Popover, Button, Upload, Modal, Input, Tabs, Tooltip, Checkbox, Empty, Spin, Menu, Dropdown, message, Divider, Popconfirm, Space, Radio, notification } from 'antd';
import './NamedEntityDisposed.css';
import Icon from '@ant-design/icons';
import convert from '../../utils/locale_zh_convert';
import {
    EyeInvisibleOutlined, EyeOutlined, DeleteOutlined, FontSizeOutlined, CopyOutlined, CloseOutlined, EditOutlined, FormOutlined, PlusSquareOutlined,
    DownloadOutlined, SaveOutlined, ExportOutlined, FileTextOutlined, QuestionCircleOutlined, LinkOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';
import { withRouter } from 'react-router-dom'
import reqwest from 'reqwest'
import request from '../../utils/request'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import htmlDocx from 'html-docx-js/dist/html-docx';
import * as cont from '../../config/constant'
import './../../data/mock_data'
import { connect } from 'react-redux'
import goback from "../../images/back.png"
import modify from "../../images/modify.png"
import $ from 'jquery'
import { set_deal_content } from './../../actions/DealTextContentAction'
import doText, { deleteTagsIndex, updateTagIndex, addTagIndex, saveIndex } from '../../utils/doText';

const openNotificationWithIcon = (title, msg) => {
    notification['error']({
        message: title,
        description:
            msg,
    });
};
const { Content } = Layout;
const ButtonGroup = Button.Group;
const content = (
    <div>
        <p>开启“编辑”模式后，在所要添加句读符的位置，“双击”鼠标即添加成功。</p>
    </div>
)
const Back = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="11px" height="14px" viewBox="0 0 11 14" enableBackground="new 0 0 11 14">  <image id="image0" width="11" height="14" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAMAAADOvxanAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAe1BMVEXy9/zH2eesxtva5vFU
  iLCPsc3o8Phikrhvm77u9Pt3oMJShq/F1+bT4e3i6/RIf6tZjLOeu9Tw9fvj7PXV4u6rxdtViLFw
  nL7g6vTO3uvn7/edu9RgkLbL2+lNgq2zyt/q8fnE1+a1zOCMr8tKgKtWibF4ocK+0uL///8Rc2Lj
  AAAAAWJLR0QovbC1sgAAAAd0SU1FB+QJAQIIF7wjLYEAAABaSURBVAjXRcxHFoAgDEXR2CtSFOwV
  y/53qOIhvsm/J4MAgOOCzfMDyzCKE+s0ywkpPlMT4+Jx+VZJVQv80CiOBsl+V7RAt7RD9wNynGaz
  y7rpXX83Qo/zevYGKZAEjhKcqJsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDgtMzFUMTg6MDg6
  MjMrMDg6MDCabfaCAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA4LTMxVDE4OjA4OjIzKzA4OjAw
  6zBOPgAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRU
  aHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAx
  NPOhP0MAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMTF7ZAtBAAAAGXRFWHRUaHVtYjo6TWlt
  ZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTg4Njg1MDMuR9qZAAAA
  EXRFWHRUaHVtYjo6U2l6ZQAzODZCQjg00IkAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAv
  dG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTk4ODYyMjc0Njc0MTE3M18xOF9bMF3NzS3qAAAAAElF
  TkSuQmCC" ></image>
    </svg>
)
const Backs = props => <Icon component={Back} {...props} />;
// const plainOptions1 = ['棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生',];
function callback(key) {
    console.log(key);
}
function onChangeSwitch(checked) {
    console.log(`switch to ${checked}`);
}

class NamedEntityDisposed extends Component {
    constructor(props) {
        super(props)
        let content = ''
        let editable = true
        let jd_deal_content_ner = ''
        if (props.dealTextContent && props.dealTextContent.jd_deal_content
            && props.dealTextContent.jd_deal_content.deal_content) {
            content = props.dealTextContent.jd_deal_content.deal_content.join('')
            jd_deal_content_ner = props.dealTextContent.jd_deal_content.deal_content_ner.join('')
            editable = false
        } else if (props.dealTextContent && props.dealTextContent.mm_deal_content && props.dealTextContent.mm_deal_content.content) {
            content = props.dealTextContent.mm_deal_content.content.join('')
            editable = false
        } else if (props.dealTextContent && props.dealTextContent.content) {
            content = props.dealTextContent.content.join('')
            editable = false
        }
        console.log(props)
        this.state = {
            count: 18,
            color: '#000000',
            visible1: false,
            visible2: false,
            value: 1,
            editable: editable,
            textcontent: content, //处理前文本
            // deal_textcontent: [],//处理后文本

            pretextcontent: [],
            action: '4',
            search_type: "machine_learning",
            tagList: [],
            tag_name: '',
            tag_key: 'peop',
            statisticsList: [],//标记统计,总览列表
            keywordStatisticsList: [],//标记统计,图表
            task_uid: this.props.dealTextContent ? this.props.dealTextContent.uid : '',
            task_identify: this.props.dealTextContent ? this.props.dealTextContent.identify : '',
            versionList: [],
            keyword: '',
            currStyle: undefined,
            searchResult: [],
            selectedRowKeys: [],
            addTagtype: '2',
            selectText: '',
            deal_textcontent2: [],
            threshold: "20",
            all_text_rade: [],
            keyword_arr: {},
            showMakusMenu: false,
            clickTag: '',
            deskHeight: document.body.clientHeight,
            visible3: false,//保存
            fildName: '',
            selectValue: '',//选择标签的值
            selectColor: '',//
            selectIdValue: '', //选择的ID的值
            tag: '',
            showEditTags: false, //修改标签
            showAddTags: false,//添加标签
            linkModalVisible: false,//实体链接
            selectwords: "", //鼠标选中的文字
            startIndex: 0,   //鼠标选中的文字开始下标
            _ner_index: this.props.handledData._ner_index,//处理后标注坐标
            clickType: '',//鼠标点击标签的类型
            clickIndex: '',//鼠标点击标签的下标
            textIds: [], //文本搜索到的ID
            collectState: false,
            result: {},
            serached: true,
            person_id: undefined,
            keyword1: '',
            search_show: false,
            dataID: 0,
            ifDisabled: true,//删 改 实体
            isDisabled: true,//加
            ifDisabledLink: true,//实体链接
            modifyShow: 'none' //事提链接修改图标
        };
        this.deal_textcontent = (this.props.handledData && !this.props.handledData.content) ? this.props.handledData.deal_textcontent : "";
        this.fildID = this.props.handledData.fildID;  //文件ID  ，保存要用
        this.dataSou = this.props.handledData.dataSou; //data
        this.source = this.props.handledData.source;  // 标记的原文本
        this._jd_deal_content_ner = jd_deal_content_ner
        this.locale = dayjs.locale()
        this._textcontent = ''////处理前文本缓存
        // this._deal_textcontent = this.props.dealTextContent && this.props.dealTextContent.mm_deal_content && this.props.dealTextContent.mm_deal_content.deal_content_code ? this.props.dealTextContent.mm_deal_content.deal_content_code.join('') : ''////处理后文本缓存
        // this._ner_index = this.props.handledData._ner_index;//处理后标注坐标
        this._tagList = []////处理后标注缓存
        this._textIds = []
        this.columns1 = [
            {
                key: 'tag_name',
                title: convert('类别', this.locale),
                dataIndex: 'tag_name',
                sorter: (a, b) => {
                    let acol = a.tag_name
                    let bcol = b.tag_name
                    if (!acol) {
                        acol = ''
                    }
                    if (!bcol) {
                        bcol = ''
                    }
                    return acol.localeCompare(bcol)
                },
                render: (text, row, index) => {
                    return <span style={row.styles && row.styles[3]}>{text}</span>
                }
            },
            {
                key: 'tag_times',
                title: convert('去重', this.locale),
                dataIndex: 'tag_times',
                sorter: (a, b) => {
                    let acol = a.tag_times
                    let bcol = b.tag_times
                    if (!acol) {
                        acol = 0
                    }
                    if (!bcol) {
                        bcol = 0
                    }
                    return acol - bcol
                },
            },
            {
                key: 'tag_times_total',
                title: convert('总计', this.locale),
                dataIndex: 'tag_times_total',
                sorter: (a, b) => {
                    let acol = a.tag_times
                    let bcol = b.tag_times
                    if (!acol) {
                        acol = 0
                    }
                    if (!bcol) {
                        bcol = 0
                    }
                    return acol - bcol
                },
            }
        ]
        this.columns2 = [
            {
                key: 'keyword',
                title: convert('实体', this.locale),
                dataIndex: 'keyword',
                sorter: (a, b) => {
                    let acol = a.keyword
                    let bcol = b.keyword
                    if (!acol) {
                        acol = ''
                    }
                    if (!bcol) {
                        bcol = ''
                    }
                    return acol.localeCompare(bcol)
                },
                render: (text, row, index) => {
                    return <span style={row.styles[3]}>{text}</span>
                }
            },
            {
                key: 'tag_times',
                title: convert('频次', this.locale),
                dataIndex: 'tag_times',
                sorter: (a, b) => {
                    let acol = a.tag_times
                    let bcol = b.tag_times
                    if (!acol) {
                        acol = 0
                    }
                    if (!bcol) {
                        bcol = 0
                    }
                    return acol - bcol
                },
            },
            {
                key: 'tag_name',
                title: convert('类型', this.locale),
                dataIndex: 'tag_name',
                sorter: (a, b) => {
                    let acol = a.tag_name
                    let bcol = b.tag_name
                    if (!acol) {
                        acol = ''
                    }
                    if (!bcol) {
                        bcol = ''
                    }
                    return acol.localeCompare(bcol)
                },
                render: (text, row, index) => {
                    return <span style={row.styles[3]}>{text}</span>
                }
            }
        ]
        this.columns3 = [
            {
                key: 'tag_name',
                title: convert('类别', this.locale),
                dataIndex: 'tag_name',
                sorter: (a, b) => {
                    let acol = a.tag_name
                    let bcol = b.tag_name
                    if (!acol) {
                        acol = ''
                    }
                    if (!bcol) {
                        bcol = ''
                    }
                    return acol.localeCompare(bcol)
                },
                render: (text, row, index) => {
                    return <span style={row.styles[3]}>{text}</span>
                }
            },
            {
                key: 'tag_key',
                title: convert('类别标识', this.locale),
                dataIndex: 'tag_key',
                sorter: (a, b) => {
                    let acol = a.tag_key
                    let bcol = b.tag_key
                    if (!acol) {
                        acol = ''
                    }
                    if (!bcol) {
                        bcol = ''
                    }
                    return acol.localeCompare(bcol)
                },
                render: (text, row, index) => {
                    return <span style={row.styles[3]}>{text}</span>
                }
            },
            {
                key: 'options',
                title: convert('操作', this.locale),
                dataIndex: 'options',
                render: (text, row, index) => {
                    return <Space>
                        <Popconfirm
                            title={convert("确定删除该类别?", this.locale)}
                            onConfirm={() => { this.deleteTag(row) }}
                            okText={convert("确定", this.locale)}
                            cancelText={convert("取消", this.locale)}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                            <span style={{ color: 'red' }}><Space><DeleteOutlined />{convert('删除', this.locale)}</Space></span>
                        </Popconfirm>
                        <Divider type='vertical' />
                        <Popconfirm
                            title={convert("确定" + (row.visible ? '隐藏' : '显示') + "该类别?", this.locale)}
                            onConfirm={() => { this.setTagVisible(row.id, !row.visible) }}
                            okText={convert("确定", this.locale)}
                            cancelText={convert("取消", this.locale)}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                            {
                                row.visible ? <span style={{ color: '#121c80' }}><Space><EyeOutlined />{convert('显示', this.locale)}</Space></span>
                                    : <span style={{ color: '#121c80' }}><Space><EyeInvisibleOutlined />{convert('隐藏', this.locale)}</Space></span>
                            }
                        </Popconfirm>
                    </Space>
                }
            },
        ]
    }
    // 保存
    resave = () => {
        if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
        // let content = document.getElementsByClassName("content")[0].innerText
        let content = document.getElementById("edit").innerHTML
        console.log(typeof content);
        console.log(content);
        let fildName = this.state.fildName;

        reqwest({
            url: '/wyd//result/' + this.fildID,
            method: 'put',
            type: 'json',
            headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
            data: { body: content, fileTitle: fildName },
            success: function (data) {
                if (data.code == 200) {
                    message.success("保存成功！")
                } else {
                    message.error("保存失败！")
                }
            }, error: (err) => {
                // message.error(err.status + ':' + err.statusText)
                message.error("保存失败！")
            }
        })
    }
    handleOk = () => {
        this.setState({
            visible3: false
        }, () => {
            this.resave()
        })
    }
    // 导出
    download = (type) => {
        console.log("this.deal_textcontent is",this.deal_textcontent);
        if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
        let content = this.deal_textcontent;
        if (content.length > 0) {
            if (type === "html") {
                // let c = content.join('\n')
                // let aaa = document.getElementsByClassName("content")[0]
                let aaa = document.getElementById("edit")

                let c = aaa.innerHTML;
                let cssStr = '<style type="text/css">' +
                    '.tpn{' +
                    ' color: #000;' +
                    'background-color: #c3eca3;' +
                    ' }' +
                    '.liter{' +
                    'color:  #000;' +
                    'background-color: #e199b7;' +
                    '}' +
                    '.peop{' +
                    'color: #000;' +
                    'background-color: #f7e298;' +
                    '}' +
                    '.offi{' +
                    'color: #000;' +
                    'background-color: #caa4f0;' +
                    '}' +
                    '.date{' +
                    ' color: #000;' +
                    'background-color: #8ecffd;' +
                    '}' +
                    '</style>';

                c = cssStr + c;
                let blob = new Blob([c], {
                    type: "application/msword;charset=utf-8",
                    endings: 'native'
                });
                saveAs(blob, '处理后文本.' + type, { autoBom: true })

            } else if (type === "xml") {
                // let c = content.join('\n')
                // let aaa = document.getElementsByClassName("content")[0]
                let aaa = document.getElementById("edit");
                let c = aaa.innerHTML
                let blob = new Blob([c], {
                    type: "application/msword;charset=utf-8",
                    endings: 'native'
                });
                saveAs(blob, '处理后文本.' + type, { autoBom: true })
            } else if (type === "word") {
                let aaa = document.getElementById("edit");
                let textcontent = aaa.innerHTML;
                textcontent = this.matchText(textcontent);
                let pagecontent = `<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body style='line-height: 1.8em;letter-spacing:3px'>${textcontent}</body></html>`
                const converted = htmlDocx.asBlob(pagecontent);
                // 用 FielSaver.js里的保存方法 进行输出
                saveAs(converted, '处理后文本.doc');
                // let aaa = document.getElementById("edit");
                // let c = aaa.innerHTML
                // let blob = new Blob([c], {
                //     type: "application/msword;charset=utf-8",
                //     endings: 'native'
                // });
                // saveAs(blob, '处理后文本.' + type, { autoBom: true })
            } else if (type === 'pdf') {
                // this.exportPDF1()
                this.exportPdf()
            }
        } else {
            message.error({ top: 300, content: '无内容!' })
        }
    }
    //html匹配 通过正则把行内式添加上
    matchText = (textcontent) => {
        if (!textcontent) {
            return message.error("文本不能为空！")
        }
        textcontent = textcontent.replace(/class="allclass peop"/g, 'class="allclass peop" style="color: Black;background-color: #f7e298;"');
        textcontent = textcontent.replace(/class="allclass tpn"/g, 'class="allclass tpn" style="color: Black;background-color: #c3eca3;"');
        textcontent = textcontent.replace(/class="allclass date"/g, 'class="allclass date" style="color: Black;background-color: #8ecffd;"');
        textcontent = textcontent.replace(/class="allclass offi"/g, 'class="allclass offi" style="color: Black;background-color: #caa4f0;"');
        textcontent = textcontent.replace(/class="allclass liter"/g, 'class="allclass liter" style="color: Black;background-color: #e199b7;"');
        return textcontent;
    }
    exportPDF1 = () => {
        // let shareContent = document.body,//需要截图的包裹的（原生的）DOM 对象
        let shareContent = document.getElementById('edit'),//需要截图的包裹的（原生的）DOM 对象
            width = shareContent.clientWidth, //获取dom 宽度
            height = shareContent.clientHeight, //获取dom 高度
            canvas = document.createElement("canvas"), //创建一个canvas节点
            scale = 1; //定义任意放大倍数 支持小数
        canvas.width = width * scale; //定义canvas 宽度 * 缩放
        canvas.height = height * scale; //定义canvas高度 *缩放
        canvas.style.width = shareContent.clientWidth * scale + "px";
        canvas.style.height = shareContent.clientHeight * scale + "px";
        canvas.getContext("2d").scale(scale, scale); //获取context,设置scale
        let opts = {
            scale: scale, // 添加的scale 参数
            canvas: canvas, //自定义 canvas
            logging: false, //日志开关，便于查看html2canvas的内部执行流程
            width: width, //dom 原始宽度
            height: height,
            useCORS: true, // 【重要】开启跨域配置
        };
        html2canvas(shareContent, opts).then(() => {
            let contentWidth = canvas.width;
            let contentHeight = canvas.height;
            //一页pdf显示html页面生成的canvas高度;
            let pageHeight = (contentWidth / 592.28) * 841.89;
            //未生成pdf的html页面高度
            let leftHeight = contentHeight;
            //页面偏移
            let position = 0;
            //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            let imgWidth = 595.28;
            let imgHeight = (592.28 / contentWidth) * contentHeight;
            let pageData = canvas.toDataURL("image/jpeg", 1.0);
            // let PDF = new JsPDF("", "pt", "a4");  // a4纸纵向
            // let PDF = new JsPDF('landscape');  // 横向页面
            let arrDPI = this.js_getDPI(); //获取显示器DPI
            let dpiX = 96;
            let dpiY = 96;
            if (arrDPI.length > 0) {
                dpiX = arrDPI[0];
                dpiY = arrDPI[1];
            }
            //l:横向， p：纵向；单位： in:英寸，mm毫米；画布大小：a3,a4,leter,[]（当内容为数组时，为自定义大小）
            let PDF = new jsPDF("l", "in", [(contentWidth + 10) / dpiX, (contentHeight + 10) / dpiY]);  // 自定义页面大小
            if (leftHeight <= pageHeight) {
                PDF.addImage(pageData, "JPEG", 5 / dpiX, 5 / dpiY);
            } else {
                while (leftHeight > 0) {
                    PDF.addImage(pageData, "JPEG", 0, position);
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    if (leftHeight > 0) {
                        PDF.addPage();
                    }
                }
            }
            PDF.save("处理后文本.pdf"); // 这里是导出的文件名
        })
            .catch(() => {

            })
    }
    //获取显示器DPI
    js_getDPI = () => {
        var arrDPI = new Array();
        if (window.screen.deviceXDPI != undefined) {
            arrDPI[0] = window.screen.deviceXDPI;
            arrDPI[1] = window.screen.deviceYDPI;
        }
        else {
            var tmpNode = document.createElement("DIV");
            tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
            document.body.appendChild(tmpNode);
            arrDPI[0] = parseInt(tmpNode.offsetWidth);
            arrDPI[1] = parseInt(tmpNode.offsetHeight);
            tmpNode.parentNode.removeChild(tmpNode);
        }
        return arrDPI;
    }
    // 导出PDF 
    exportPdf = () => {
        // 要导出的dom节点，注意如果使用class控制样式，一定css规则
        // const element = document.getElementsByClassName('content')[0];
        const element = document.getElementById("edit");
        // element.style.background = "#FFFFFF";
        //方法一：
        // html2canvas(element).then((canvas) => {
        //     var contentWidth = canvas.width;
        //     var contentHeight = canvas.height;
        //     //一页pdf显示html页面生成的canvas高度;
        //     var pageHeight = contentWidth / 592.28 * 841.89;
        //     //未生成pdf的html页面高度
        //     var leftHeight = contentHeight;
        //     //页面偏移
        //     var position = 0;
        //     //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        //     var imgWidth = 595.28;
        //     var imgHeight = 592.28 / contentWidth * contentHeight;
        //     var pageData = canvas.toDataURL('image/jpeg', 1.0);
        //     var pdf = new jsPDF('', 'pt', 'a4');
        //     //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //     //当内容未超过pdf一页显示的范围，无需分页
        //     if (leftHeight < pageHeight) {
        //         pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        //     } else {
        //         while (leftHeight > 0) {
        //             pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        //             leftHeight -= pageHeight;
        //             position -= 841.89;
        //             //避免添加空白页
        //             if (leftHeight > 0) {
        //                 pdf.addPage();
        //             }
        //         }
        //     }
        //     pdf.save("处理后文本.pdf");
        // })
        //方法2：
        html2canvas(element, {
            useCORS: true,// 是否允许网页中img元素跨域，这个设置需要img元素支持及服务器支持
            allowTaint: true,// 支持跨域
            scale: 2// 这个影响生成图片的清晰度
        }).then(function (canvas) {
            var pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
            var ctx = canvas.getContext("2d"),
                a4w = 190,
                a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
                imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
                renderedHeight = 0;
            while (renderedHeight < canvas.height) {
                var page = document.createElement("canvas");
                page.width = canvas.width;
                page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
                //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
                page
                    .getContext("2d")
                    .putImageData(
                        ctx.getImageData(
                            0,
                            renderedHeight,
                            canvas.width,
                            Math.min(imgHeight, canvas.height - renderedHeight)
                        ),
                        0,
                        0
                    );
                pdf.setFontSize(20);
                pdf.addImage(
                    page.toDataURL("image/jpeg", 1.0),
                    "JPEG",
                    10,
                    10,
                    a4w,
                    Math.min(a4h, (a4w * page.height) / page.width)
                ); //添加图像到页面，保留10mm边距

                renderedHeight += imgHeight;
                if (renderedHeight < canvas.height) {
                    pdf.addPage();
                } //如果后面还有内容，添加一个空页
            }
            pdf.save("处理后文本.pdf");
        });
    };
    //   放大
    increase = () => {
        let count = this.state.count + 2;

        if (count > 48) {
            count = 48;
        }
        // let ziwen = document.getElementsByClassName("content")[0]
        let ziwen = document.getElementById("edit")
        ziwen.style.fontSize = count + "px"
        this.setState({ count });
        
    };
    //   缩小
    decline = () => {
        let count = this.state.count - 2;
        if (count < 12) {
            count = 12;
        }
        // let ziwen = document.getElementsByClassName("content")[0]
        let ziwen = document.getElementById("edit")
        ziwen.style.fontSize = count + "px"
        this.setState({ count });
    };
    //   编辑
    edit = () => {
        if (!localStorage.getItem("token")) { message.warning("暂不支持此功能！"); return }
        if (this.deal_textcontent.length == 0) {
            message.warning("无内容！")
            return
        }
        this.setState({ edit: !this.state.edit })
        if (this.state.edit == true) {
            message.success({ top: 300, content: '编辑功能已关闭！' })
        } else {
            message.success({ top: 300, content: '编辑功能已开启！' })
        }
    }
    //   清空
    delet = () => {
        //  let doc = document.getElementsByClassName("content")[0]
        //  doc.innerHTML =""
        // let _this = this
        // this.state.deal_textcontent =[]
        if (this.deal_textcontent.length == 0) {
            message.warning("无内容！")
            return
        }
        this.deal_textcontent = '';
    }
    //   复制
    copyone = () => {
        if (this.deal_textcontent.length == 0) {
            message.warning("无内容！")
            return
        }
        // var Url2 = document.getElementsByClassName("content")[0].innerText
        var Url2 = document.getElementById("edit").innerText
        var oInput = document.createElement('input');
        oInput.value = Url2;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        message.success({ top: 300, content: '复制成功' })
    }

    formatter1(value) {
        return `${value}%`;
    }
    formatter(value) {

        let _this = this
        let all_text_rade = this.state.all_text_rade
        this.setState({
            threshold: value,
        });
        let array
        let textend = []
        textend = _this.state.textcontent.split("ο")
        let cc = ""
        let arr = this.state.keyword_arr
        for (let i = 0; i < textend.length; i++) {
            for (let c = 0; c < arr.PER.length; c++) {
                if (textend[i].includes(arr.PER[c])) {
                    textend[i] = textend[i].replace(arr.PER[c], _this.instance("PER", arr.PER[c]))
                } else {
                }
            }
            for (let c = 0; c < arr.LOC.length; c++) {
                if (textend[i].includes(arr.LOC[c])) {
                    textend[i] = textend[i].replace(arr.LOC[c], _this.instance("LOC", arr.LOC[c]))
                }
            }
            for (let c = 0; c < arr.OFI.length; c++) {
                if (textend[i].includes(arr.OFI[c])) {
                    textend[i] = textend[i].replace(arr.OFI[c], _this.instance("OFI", arr.OFI[c]))
                }

            }
            cc += `<span style='color:${_this.state.all_text_rade[i] * 100 > value ? "red" : "#ccc"}
      '> ${textend[i]}</span><span style=color:rgba(114,164,190,1)>ο</span>`
        }
        console.log(cc)
        _this.deal_textcontent = cc;

        // let list = [...this.state.pretextcontent] //用深copy
        // list.map((item) => {
        //   if (item instanceof Array) {
        //     let t = item.join('')
        //     let arrs = t.split("ο")
        //     arrs.forEach(it => {
        //       _arr.push({
        //         type: 'changable',
        //         content: it,
        //       })
        //     })

        //   } else if (item instanceof Object) {
        //     _arr.push({
        //       type: 'NOchangable',
        //       sysbo: item
        //     })
        //   }
        //   console.log(item)
        // })

        // array = [..._arr]
        // console.log(array)
        // let reg = new RegExp("ο", "g")
        // let reg1 = new RegExp(",", "g")
        // console.log(array)
        // let index =0
        // for (let i = 0; i < array.length; i++) {

        //   let d = array[i]
        //   if (d.type == 'NOchangable') {
        //      index += 1
        //     textend.push(d.sysbo)
        //     continue;
        //   } else if (d.content == "") {
        //     index += 1
        //   }
        //   let nexttype
        //   if (i < array.length - 1) {
        //     if (array[i + 1].type == "NOchangable") {
        //        nexttype = "no"
        //     }
        //   }
        //   // console.log
        //   d.sysbo = <><span style={{
        //     'color':_this.state.all_text_rade[i-index] * 100 >value ? "red" : "#ccc"
        //   }} > {d.content.replace(reg1, "")}</span>
        //    { nexttype == 'no' ? "" :  <span>ο</span>}
        // </>
        // textend += "<span style='color:" + (data.al_score[i] * 100 > _this.state.threshold ? "red" : "#ccc") + "'>" 
        //   + array[i].replace(reg1,"")+"</span>ο"
        //     textend.push(d.sysbo)
        //   }
        //   console.log(textend)
        //    _this.deal_textcontent = textend;


        return
    }

    //点击标签颜色变化
    switchStyle(tag) {
        let t = { ...tag }
        let _this = this
        let tags = [..._this.state.tagList]
        if (t.style_index === t.styles.length - 1) {
            t.style_index = 0
        } else {
            t.style_index = t.style_index + 1
        }
        tags.splice(tags.indexOf(tag), 1, t)
        _this.setState({
            tagList: tags
        }, () => {
            if (_this.state.text_type == "a") {
                _this._tagList = tags
                // _this.doText()
            } else {
                // tpn 代表地点 liter 代表书名 peop 代表人名 offi 代表官职 date 代表时间
                let tpn = document.getElementsByClassName("tpn")
                let liter = document.getElementsByClassName("liter")
                let peop = document.getElementsByClassName("peop")
                let offi = document.getElementsByClassName("offi")
                let date = document.getElementsByClassName("date")
                let num = tag.style_index + 1 >= 2 ? 0 : tag.style_index + 1
                let tpnstyle
                let literstyle
                let peopstyle
                let offistyle
                let datestyle
                for (let c = 0; c < tags.length; c++) {
                    if (tags[c].tag_key == "tpn") {
                        tpnstyle = tags[c].keywordStyles[num]
                    }
                }
                for (let c = 0; c < tags.length; c++) {
                    if (tags[c].tag_key == "liter") {
                        literstyle = tags[c].keywordStyles[num]
                    }
                }
                for (let c = 0; c < tags.length; c++) {
                    if (tags[c].tag_key == "peop") {
                        peopstyle = tags[c].keywordStyles[num]
                    }
                }
                for (let c = 0; c < tags.length; c++) {
                    if (tags[c].tag_key == "offi") {
                        offistyle = tags[c].keywordStyles[num]
                    }
                }
                for (let c = 0; c < tags.length; c++) {
                    if (tags[c].tag_key == "date") {
                        datestyle = tags[c].keywordStyles[num]
                    }
                }
                if (tag.tag_key == "tpn") {
                    for (let i = 0; i < tpn.length; i++) {
                        tpn[i].style.background = tpnstyle.backgroundColor.replace(/"/, "")
                        tpn[i].style.border = tpnstyle.border.replace(/"/, "")
                        tpn[i].style.color = tpnstyle.color.replace(/"/, "")
                        tpn[i].style.fontSize = tpnstyle.fontSize + "px"
                    }
                } else if (tag.tag_key == "liter") {
                    for (let i = 0; i < liter.length; i++) {
                        liter[i].style.background = literstyle.backgroundColor.replace(/"/, "")
                        liter[i].style.border = literstyle.border.replace(/"/, "")
                        liter[i].style.color = literstyle.color.replace(/"/, "")
                        liter[i].style.fontSize = literstyle.fontSize + "px"
                    }
                } else if (tag.tag_key == "peop") {
                    for (let i = 0; i < peop.length; i++) {
                        peop[i].style.background = peopstyle.backgroundColor.replace(/"/, "")
                        peop[i].style.border = peopstyle.border.replace(/"/, "")
                        peop[i].style.color = peopstyle.color.replace(/"/, "")
                        peop[i].style.fontSize = peopstyle.fontSize + "px"
                    }
                }
                else if (tag.tag_key == "offi") {
                    for (let i = 0; i < offi.length; i++) {
                        offi[i].style.background = offistyle.backgroundColor.replace(/"/, "")
                        offi[i].style.border = offistyle.border.replace(/"/, "")
                        offi[i].style.color = offistyle.color.replace(/"/, "")
                        offi[i].style.fontSize = offistyle.fontSize + "px"
                    }
                }
                else if (tag.tag_key == "date") {
                    for (let i = 0; i < date.length; i++) {
                        date[i].style.background = datestyle.backgroundColor.replace(/"/, "")
                        date[i].style.border = datestyle.border.replace(/"/, "")
                        date[i].style.color = datestyle.color.replace(/"/, "")
                        date[i].style.fontSize = datestyle.fontSize + "px"
                    }
                }
            }

        })
    }

    search = (keyword) => {
        let _this = this

        if (!keyword) {
            return [];
        }
        let _textcontent = _this._textcontent
        let c = Array.from(_this._textcontent)
        let ks = _textcontent.split(keyword);

        let ps = []
        ks.reduce((t, item, index, arr) => {
            let st = t + Array.from(item).length
            let ed = st + Array.from(keyword).length
            t = ed
            ps.push([st, ed - 1])
            return t
        }, 0)

        // let c = Array.from(_this._textcontent)
        // // let c = _this._textcontent
        // let ws = Array.from(keyword)
        // let ps = this._indexOfText(ws[0], c)
        let searchResult = []
        for (let i = 0; i < ps.length; i++) {
            let r = ''
            let p = ps[i]
            let start = 0;
            let end = -1;
            if (ps.length > (i + 1)) {
                let pa = ps[i + 1];
                if (pa[0] - p[1] > 5) {
                    end = p[1] + 5
                } else {
                    end = pa[0]
                }
            } else {
                if (c.length - 1 - p[1] > 5) {
                    end = p[1] + 5
                }
            }
            if (i > 0) {
                let pb = ps[i - 1]
                if (p[0] - pb[1] > 5) {
                    start = p[0] - 5
                } else {
                    start = pb[1]
                }
            } else {
                if (p[0] - 0 > 5) {
                    start = p[0] - 5
                } else {
                    start = 0
                }
            }
            if (end > -1) {
                r = c.slice(start, end)
            } else {
                // r = c.slice(start)
            }
            if (r) {

                let row = _this._loop(keyword, r.join(''), _this.state.currStyle)
                searchResult.push({
                    key: i,
                    content: row,
                    position: p
                })
            }
        }
        return searchResult
    }

    //获取标签列表信息
    getTagList = (callback) => {
        let _this = this
        reqwest({
            url: '/tag/getList.htm',
            method: 'post',
            type: 'json',
            data: {},
            success: function (data) {
                if (data.code === 0) {
                    _this.setState({
                        tagList: data.list
                    }, () => {
                        _this._tagList = data.list
                        callback && callback()
                    })
                }
            }
        })
    }
    deleteTag = (record) => {
        let _this = this
        reqwest({
            url: '/tag/deleteTag.htm',
            method: 'post',
            type: 'json',
            data: {
                id: record.id
            },
            success: function (data) {
                if (data.code === 0) {
                    message.success('删除成功')
                    _this.getTagList(() => {
                        // _this.doText()
                    })
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }
        })
    }
    // 删除标记
    deleteTagIndex = () => {
        let _this = this
        reqwest({
            url: '',
            method: 'post',
            type: 'json',
            data: {
                // id: record.id
            },
            success: function (data) {
                if (data.code === 0) {
                    message.success('删除成功')
                    _this.getTagList(() => {
                        // _this.doText()
                    })
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }
        })
    }
    setTagVisible = (id, visible) => {
        let _this = this
        reqwest({
            url: '/tag/setTagVisible.htm',
            method: 'post',
            type: 'json',
            data: {
                id: id,
                visible: visible
            },
            success: function (data) {
                if (data.code === 0) {
                    message.success('设置成功')
                    _this.getTagList(
                        () => {
                            // _this.doText()
                        }
                    )
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }
        })
    }
    addTag = (callback) => {
        let _this = this
        let tag_key = _this.state.tag_key
        reqwest({
            url: '/tag/addTag.htm',
            method: 'post',
            type: 'json',
            data: {
                tag_name: _this.state.tag_name,
                tag_key: tag_key,
                color: _this.state.color.substr(1)
            },
            success: function (data) {
                if (data.code === 0) {
                    _this.getTagList(() => {
                        callback && callback(data.item.tag_key)
                    })
                    _this.setState({
                        visible6: false,
                        tag_key: '',
                        tag_name: '',
                        color: '#000000'
                    })
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }
        })
    }
    onChangeRa = e => {
        e.stopPropagation();
        this.setState({
            addTagtype: e.target.value,
        });
    };
    onChange1 = e => {
        console.log(e.target.value)
        this.setState({
            tag_key: e.target.value
        })
        // console.log(this.state.value1)
    };

    saveVersion = (uid, content, identify, action, jd_deal_content, mm_deal_content) => {
        reqwest({
            url: '/text/saveVersion.htm',
            method: 'post',
            type: 'json',
            data: {
                uid: uid,
                content: content && JSON.stringify(content),
                identify: identify,
                action: action,
                jd_deal_content: jd_deal_content && JSON.stringify(jd_deal_content),
                mm_deal_content: mm_deal_content && JSON.stringify(mm_deal_content),
            },
            success: function (data) {
                if (data.code === 0) {
                    // message.success('已保存成功')
                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }, error: (err) => {
                // message.error(err.status + ':' + err.statusText)
                let mes = JSON.parse(err.response)
                message.error(mes.message)
            }
        })
    }
    getVersionList = (identify) => {
        let _this = this;
        reqwest({
            url: '/text/getVersionList.htm',
            method: 'post',
            type: 'json',
            data: { identify: identify },
            success: function (data) {
                if (data.code === 0) {
                    _this.setState({
                        versionList: data.list
                    })
                } else if (data.code === 1) {
                    _this.setState({
                        versionList: []
                    })
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }, error: (err) => {
                _this.setState({
                    versionList: []
                })
                // message.error(err.status + ':' + err.statusText)
                let mes = JSON.parse(err.response)
                message.error(mes.message)
            }
        })
    }

    getLastVersion = (action_order) => {
        let _this = this;
        reqwest({
            url: '/text/getLastVersion.htm',
            method: 'post',
            type: 'json',
            data: { action_order: action_order },
            success: function (data) {
                if (data.code === 0) {
                    if (data.item && !_this.state.textcontent) {
                        Modal.confirm({
                            onOk: close => {
                                let content = ''
                                let editable = true
                                let jd_deal_content_ner = ''
                                if (data.item.jd_deal_content && data.item.jd_deal_content.deal_content) {
                                    content = data.item.jd_deal_content.deal_content.join('')
                                    jd_deal_content_ner = data.item.jd_deal_content.deal_content_ner.join('')
                                    editable = false
                                } else if (data.item.mm_deal_content && data.item.mm_deal_content.content) {
                                    content = data.item.mm_deal_content.content.join('')
                                    editable = false
                                } else if (data.item && data.item.content) {
                                    content = data.item.content.join('')
                                    editable = false
                                }
                                _this.setState({
                                    task_uid: data.item.uid,
                                    textcontent: content,
                                    task_identify: data.item.identify,
                                    editable: editable
                                }, () => {

                                    let mm_deal_content = data.item.mm_deal_content
                                    if (mm_deal_content && Object.keys(mm_deal_content).length > 0) {
                                        let content = mm_deal_content.content
                                        let ner_index = mm_deal_content.ner_index
                                        _this._deal_textcontent = mm_deal_content.deal_content_code
                                        _this._textcontent = _this.state.textcontent
                                        _this._ner_index = ner_index
                                        _this._jd_deal_content_ner = jd_deal_content_ner
                                        // _this.doText()
                                        let origin_content = data.item.content.length > 0 ? data.item.content : [_this.state.textcontent]
                                        _this.props.dispatch(set_deal_content(data.item.uid, origin_content,
                                            data.item.identify, data.item.jd_deal_content, mm_deal_content))
                                    } else {
                                        _this._textcontent = data.item.content.join('')
                                    }

                                })

                                close()
                            },
                            onCancel: close => {
                                _this.setState({
                                    editable: true
                                })
                                close()
                            },
                            content: `检测到存在已经经过${data.item.action}处理的历史文本数据,是否载入?`,
                            okText: '确定',
                            cancelText: '取消',
                        })
                    }

                } else if (data.code === 1) {
                    message.error(data.errmsg ? data.errmsg : '未知错误')
                }
            }, error: (err) => {
                _this.setState({
                    versionList: []
                })
                // message.error(err.status + ':' + err.statusText)
                let mes = JSON.parse(err.response)
                message.error(mes.message)
            }
        })

    }
    componentDidUpdate() { // 组件更新后触发
        this.showMenu();
    }
    componentWillMount() {
        // 用于从我的文件点击修改，将文本数据带过来渲染进行操作；
        //console.log('start will');
        if (this.props.filedata && this.props.filedata.fileContent && this.props.filedata.fileContent.length > 0) {
            this.fildID = this.props.filedata.fildID;
            this.deal_textcontent = this.props.filedata.fileContent;
            this.dataSou = this.props.filedata.dataSou; //data
            this._ner_index = this.props.filedata.dataSou.data.index;//处理后标注坐标
            this.source = this.props.filedata.source;  // 标记的原文本
            this.setState({
                _ner_index: this._ner_index,
                // fildName: this.props.filedata.filename
            })
        }
        if (this.props.handledData && this.props.handledData.deal_textcontent && this.props.handledData.deal_textcontent.length > 0) {
            this.deal_textcontent = (this.props.handledData && !this.props.handledData.content) ? this.props.handledData.deal_textcontent : "";
            this.fildID = this.props.handledData.fildID;  //文件ID  ，保存要用
            this._ner_index = this.props.handledData._ner_index;//处理后标注坐标
            this.source = this.props.handledData.source;  // 标记的原文本
            this.dataSou = this.props.handledData.dataSou; //data
            this.setState({
                _ner_index: this._ner_index
            })
        }
    }
    componentDidMount() {
        //console.log('start did');
        // this.props.dispatch({ type: "currentPage", data: { currentPage: "NamedEntityDisposed" } });

        this.getTagList()
        this.getLastVersion(3);

        //添加点击事件
        this.showMenu();
        window.addEventListener('keyup', (e) => {
            this.handleKeyPress(e);
        });
    }
    showMenu = () => {
        let _this = this;
        let tempTags = ["tpn", "liter", "peop", "offi", "date"];
        for (let y = 0; y < tempTags.length; y++) {
            let dom = document.getElementsByClassName(tempTags[y]);
            if (dom && dom.length > 0) {
                for (let x = 0; x < dom.length; x++) {
                    let tempDom = dom[x];
                    if (tempDom) {
                        let tempIndex = tempDom.dataset.index;
                        let tempType = tempTags[y];
                        let str = dom[x].innerText;
                        var a = document.getElementsByClassName('popMenu')[0];
                        // var a = document.getElementsByClassName('ant-popover-arrow')[0];
                        a.onclick = function (e) {
                            // e.stopPropagation()
                        }
                        var lef = a.getBoundingClientRect().left;
                        var top = a.getBoundingClientRect().top;
                        // console.log(lef, top);
                        // console.log(a.style.left, a.style.top);
                        dom[x].onclick = function (e) {
                            var rr = dom[x].getBoundingClientRect().left;
                            var pr = dom[x].getBoundingClientRect().top;
                            let num = str.length * 3;
                            console.log(str, num, pr, rr);;
                            a.style.left = rr + num + 'px';
                            a.style.top = pr - 80 + 'px';
                            a.style.position = 'absolute';
                            //阻止事件冒泡到父元素
                            e.stopPropagation();
                            // 标签弹出框显示
                            _this.setState({
                                showMakusMenu: true,
                                clickIndex: tempIndex,
                                clickType: tempType,
                                clickTag: str,
                                ifDisabled: false,
                                // ifDisabledLink: false,
                                isDisabled: true,
                            });
                            _this.getTextId();
                        }
                    }
                }
            }
        }
    }

    instance(name, item) {

        let _this = this
        if (item) {
            return "<span class='allclass " + name + "'>" + convert(item, _this.locale) + "</span>"
        } else {
            return "<span></span>"
        }
    }

    deleteTextTag = () => {
        let sks = this.state.selectedRowKeys
        if (sks.length === 0) {
            message.error('请至少选择一项')
            return;
        }
        let _ner_index = this._ner_index
        let ns = sks.reduce((total, item) => {
            let a = this.state.searchResult.find(t => t.key === item)
            if (a) {
                total.push(a.position)
            }
            return total
        }, [])
        if (_ner_index) {
            for (var key in _ner_index) {
                let ps = _ner_index[key]
                let nps = ps.filter(ite => {
                    let ar = ns.filter(it => it[0] == ite[0] && it[1] == ite[1])
                    if (ar.length > 0) {
                        return false
                    } else {
                        return true
                    }
                })
                _ner_index[key] = nps
            }
            this.setState({
                visible4: false
            }, () => {
                // this.doText()
            })
        } else {
            this.setState({
                visible4: false
            })
            message.error('该文本无任何标记')
        }
    }
    addTextTag = (tag_key, ns) => {// ns 选中的位置
        let _ner_index = this._ner_index
        if (_ner_index) {

            for (var key in _ner_index) { //清除重合位置的标记
                let ps = _ner_index[key]//原标记位置
                let nps = ps.filter(ite => {  //过滤出 与 选中位置 不重合的 位置
                    let ar = ns.filter(it =>
                        (it[0] >= ite[0] && it[0] <= ite[1]) ||
                        (it[1] >= ite[0] && it[1] <= ite[1]) ||
                        (ite[0] >= it[0] && ite[0] <= it[1]) || (ite[1] >= it[0] && ite[1] <= it[1])
                    )
                    if (ar.length > 0) {
                        return false
                    } else {
                        return true
                    }
                })
                _ner_index[key] = [...nps]
            }

            if (_ner_index[tag_key]) {
                let ps = _ner_index[tag_key]//原标记位置
                let nps = ps.filter(ite => {  //过滤出 与 选中位置 不重合的 位置
                    let ar = ns.filter(it =>
                        (it[0] >= ite[0] && it[0] <= ite[1]) ||
                        (it[1] >= ite[0] && it[1] <= ite[1]) ||
                        (ite[0] >= it[0] && ite[0] <= it[1]) || (ite[1] >= it[0] && ite[1] <= it[1])
                    )
                    if (ar.length > 0) {
                        return false
                    } else {
                        return true
                    }
                })
                _ner_index[tag_key] = [...nps, ...ns]
            } else {
                _ner_index[tag_key] = ns
            }
        } else {
            _ner_index = {}
            _ner_index[tag_key] = ns
        }
        this.setState({
            visible4: false
        }, () => {
            this._ner_index = _ner_index
            // this.doText()
        })
    }
    _indexOfText = (keyword, str) => {
        let ps = []
        if (!keyword) {
            return ps
        }
        var index = str.indexOf(keyword); // 字符出现的位置
        while (index !== -1) {
            let st = index
            let end = index + keyword.length - 1
            ps.push([st, end])
            index = str.indexOf(keyword, index + keyword.length + 1); // 从字符串出现的位置的下一位置开始继续查找
        }
        return ps
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    //跳转到前页面
    gotoPrePage = () => {
        var obj = {}
        obj.deal_textcontent = "";
        this.props.dispatch({ type: "initNamedEntity", data: { handledDataToNameEntity: obj } });
        this.props.history.push('/NamedEntity');
    }

    //将搜索到的文字描红处理
    _loop(value = '', str, customStyle) {
        // console.log(value)
        // console.log(str)
        let data = [];

        let lines = str.split('\n')
        if (!value) {
            return lines
        }
        let v = value

        lines.forEach(line => {
            let ar = [];
            let title = line.replace(new RegExp(value, "g"), '<*>' + value + '<*>')
            if (title === line) {
                title = title.replace(new RegExp(convert(value, this.locale), "g"), '<*>' + convert(value, this.locale) + '<*>');
                if (title !== line) {
                    v = convert(value, this.locale)
                }
            }

            let nameArray = title.split('<*>');

            for (let i = 0; i < nameArray.length; i++) {
                if (nameArray[i] === v) {
                    ar.push(<span key={i} style={customStyle}>{v}</span>);
                } else {
                    ar.push(nameArray[i]);
                }
            }
            data.push(ar)
        })
        return data
    }
    //获取详细信息：根据名字ID
    getResult(id) {
        let type = this.state.clickType;
        if (type != "peop") {
            this.setState({ result: '' });
            return;
        }
        let _this = this
        let person_id = id;

        if (person_id === _this.state.person_id) {
            return
        }
        reqwest({
            url: cont.getSearchResult,
            method: 'post',
            type: 'json',
            headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
            data: { id: person_id },//按人名ID搜索
            success: function (data) {
                if (data.code == 0) {
                    _this.setState({
                        result: data.item,
                        person_id: person_id,
                        keyword1: data.item.c_name_chn,
                    })

                    if (data.item) {
                        // 出处
                        for (let i = 0; i < data.item.source_data.length; i++) {
                            const element = data.item.source_data[i];
                            var $trTemp = $("<tr></tr>");
                            $trTemp.append("<td'><nobr>" + element.c_title_chn + "</nobr>" + "</td>");
                            $("#outer").append($trTemp);
                        }
                        //别名
                        for (let i = 0; i < data.item.altname_data.length; i++) {
                            const element = data.item.altname_data[i];
                            var b = '、'
                            if (i == data.item.altname_data.length - 1) {
                                b = '。'
                            }
                            var $trAltName = $("<tr></tr>");
                            $trAltName.append("<td><nobr>" + element.c_name_type_desc_chn + ':</nobr>' + element.c_alt_name_chn + b + "</td>");
                            $("#altname").append($trAltName);
                        }
                        //地理資訊:
                        for (let i = 0; i < data.item.address_data.length; i++) {
                            const element = data.item.address_data[i];
                            var $trTempAddr = $("<tr></tr>");
                            $trTempAddr.append("<td><nobr>" + element.c_addr_desc_chn + ":</nobr>" + element.c_name_chn + "</td>");
                            $("#address").append($trTempAddr);
                        }
                        //入仕:
                        for (let i = 0; i < data.item.entry_data.length; i++) {
                            const element = data.item.entry_data[i];
                            var $trTempEntry = $("<tr></tr>");
                            $trTempEntry.append("<tr><td><nobr>入仕門:</nobr>" + element.c_entry_type_desc_chn + "</td></tr>" + "<tr><td><nobr>入仕別:</nobr>" + element.c_entry_desc_chn + "</td></tr>" + "<tr><td><nobr>出處:</nobr>" + element.c_title_chn + '頁' + element.c_pages + "</td></tr>");
                            $("#entry").append($trTempEntry);
                        }
                        //任官:
                        for (let i = 0; i < data.item.office_data.length; i++) {
                            const element = data.item.office_data[i];
                            var $trTempOffice = $("<table cellpadding='2' cellspacing='1' style='font-size: small'><tbody></tbody></table >");
                            $trTempOffice.append("<tr><td align='left' valign='top'><nobr>▪" + element.c_office_chn + ":</nobr>" + "</td><td align='left'>起始年:" + (element.c_firstyear ? element.c_firstyear : '未詳') + " 终止年:" + (element.c_lastyear ? element.c_lastyear : '未詳') + "<br /><font style='border: 1px solid black'>出處:</font>" + element.c_title_chn + "<br /><font style='border: 1px solid black'>註:</font>" + (element.c_notes ? element.c_notes : '暂无数据') + "<td/><tr />");
                            $("#office").append($trTempOffice);
                        }
                        //社會區分:
                        for (let i = 0; i < data.item.status_data.length; i++) {
                            const element = data.item.status_data[i];
                            var $trTempAddrStatus = $("<tr></tr>");
                            $trTempAddrStatus.append("<td>" + element.c_status_desc_chn + ", 出處:" + element.c_title_chn + "</td>");
                            $("#status").append($trTempAddrStatus);
                        }
                        //親屬關係:
                        for (let i = 0; i < data.item.kin_data.length; i++) {
                            const element = data.item.kin_data[i];
                            var $trTempKin = $("<tr></tr>");
                            $trTempKin.append("<td>" + element.c_kinrel_chn + " </td><td>" + element.kin_name + "</td><td><font style='border: 1px solid black'>出處:</font>" + element.c_title_chn + "</td>");
                            $("#kin").append($trTempKin);
                        }
                        //社會關係:
                        for (let i = 0; i < data.item.assoc_data.length; i++) {
                            const elements = data.item.assoc_data[i].children;
                            elements.map(item => {
                                item.childrenNodes.map(node => {
                                    var $trTempAssoc = $("<tr></tr>");
                                    $trTempAssoc.append("<td align='left' valign='top'>" + node.c_assoc_desc_chn + " </td><td><td>" + node.assoc_name + "</td><td>" + (node.c_notes ? node.c_notes : '') + "<br /><font style='border: 1px solid black'>出處:</font>" + node.c_title_chn + (node.c_pages ? "(頁" + node.c_pages + ")" : '') + "</td>");
                                    $("#assoc").append($trTempAssoc);
                                })
                            })
                        }
                        //入仕:
                        for (let i = 0; i < data.item.text_data.length; i++) {
                            const element = data.item.text_data[i];
                            var $trTempText = $("<tr></tr>");
                            $trTempText.append("<tr><td align='left' valign='top'><nobr>" + element.title_chn + ":</nobr></td><td>著作年代:" + element.c_text_year + ", 角色:" + element.c_role_desc_chn + "<br /><font style='border: 1px solid black'>出處:</font>" + element.c_title_chn + "</td></tr>");
                            $("#text").append($trTempText);
                        }
                    }
                } else if (data.code == 1) {
                    openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
                }
            },
            error: (err) => {
                let mes = JSON.parse(err.response)
                message.error(mes.message)
            },
        })
    }

    // 添加、修改标记，选择标记
    selectTagChange = (value, e) => {
        console.log(e)
        this.setState({
            selectColor: value.target.value.color,
            selectValue: value.target.value,
        })
        console.log(value);
    }
    selectIDChange = (value, e) => {
        console.log(e)
        this.setState({
            selectIdValue: value.target.value,
        })
        console.log(value);
        this.getResult(value.target.value);
    }
    resaveID = () => {

        this.setState({
            modifyShow: 'block'
        })
        let selectIdValue = this.state.selectIdValue;//得到用户保存选的id
        if (!selectIdValue) {
            message.error("请选择要保存的ID！");
            return;
        }
        let _this = this;

        let _ner_index = this.state._ner_index;
        let type = this.state.clickType;
        let index = this.state.clickIndex;//点击关键字词的索引
        index = index.replace(/_/i, ",");//得到"x,y"
        var strArr = index.split(",");//先将其分成字符串数组  
        var intArr = [];//保存转换后的整型字符串  
        intArr = strArr.map(function (data) {
            return +data;
        })

        let ner_index = saveIndex(_ner_index, type, intArr, selectIdValue);
        this.dataSou.data.index = ner_index;
        let data = JSON.stringify(this.dataSou);
        this.setState({ dataID: selectIdValue, dataIdindex: intArr });
        let content = document.getElementById("edit").innerHTML;
        // 接口body存的是update_sentence  content 存的是predicted_sentence
        reqwest({
            url: '/wyd//result/' + this.fildID,
            method: 'put',
            type: 'json',
            headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
            data: { body: content, content: data },
            success: function (data) {
                if (data.code == 200) {
                    message.success("保存成功！")
                    _this.setState({ linkModalVisible: false });
                } else {
                    message.error("保存失败！")
                }
            },
            error: (err) => {
                let mes = JSON.parse(err.response)
                message.error(mes.message)
                // message.error("保存失败！")
            },
        })
        this.setState({ linkModalVisible: false });

    }
    //快捷键监听 tpn 代表地点 liter 代表书名 peop 代表人名 offi 代表官职 date 代表时间
    handleKeyPress = (e) => {
        console.log(e);
        let tagkey = '';
        // key: "P", code: "KeyP",
        if (e.shiftKey && e.key) {
            //shift+p -- peop --- 人名
            if (e.shiftKey && e.key === 'P') {
                e.preventDefault();
                console.log("shift.p -----peop --- 人名");
                tagkey = 'peop';
                //shift+t --- date  -- 时间
            } else if (e.shiftKey && e.key === 'T') {
                e.preventDefault();
                console.log("shift.t  --- date  -- 时间");
                tagkey = 'date';
                //shift+l —- tpn --- 地名
            } else if (e.shiftKey && e.key === 'L') {
                e.preventDefault();
                console.log("shift.l  —- shift.l --- 地名");
                tagkey = 'tpn';
                //shift+o —--offi -- 官职
            } else if (e.shiftKey && e.key === 'O') {
                e.preventDefault();
                console.log("shift.o  —--offi -- 官职");
                tagkey = 'offi';
                // shift+b —- liter -- 书名
            } else if (e.shiftKey && e.key === 'B') {
                e.preventDefault();
                console.log("shift.b —- liter -- 书名");
                tagkey = 'liter';
            }
            let Ifkey = false;
            if (tagkey.length > 0) {
                if (this.state.showEditTags) {
                    console.log(this.state.showEditTags);
                    Ifkey = true;
                    this.updateTag(1, Ifkey, tagkey);
                }
                if (this.state.showAddTags) {
                    console.log(this.state.showAddTags);
                    Ifkey = true;
                    this.updateTag(2, Ifkey, tagkey);
                }
            }
        } else {
            return;
        }
    }

    handleVisibleChange = showEditTags => {
        this.setState({ showEditTags });
    };
    handleAddVisibleChange = showAddTags => {
        this.setState({ showAddTags });
    }
    handleVisible = showMakusMenu => {
        this.setState({ showMakusMenu });
    }
    handleLinkVisible = linkModalVisible => {
        this.setState({ linkModalVisible });
    }
    //删除标记modal  （未用）
    showDeleteConfirm = () => {
        Modal.confirm({
            title: '确定要删除该标记吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            // width: '500px',
            onOk: () => this.handleAutomatic(this.state.textcontent),
            onCancel: () => {
                this.setState({
                    isModalVisible: false
                })
            },
            cancelText: "取消",
        });
    }
    // 实体链接modal 
    linkModalVisible = () => {
        this.getTextId();
        this.setState({
            linkModalVisible: true,
            showMakusMenu: false
        });
    }
    //获取ID列表信息
    getTextId = (callback) => {
        let _this = this;
        let clickTag = this.state.clickTag;//点击关键字词文本
        let type = this.state.clickType;//点击关键字词的标记类型tpn/peop/liter...

        reqwest({
            url: cont.getTextId,
            method: 'post',
            type: 'json',
            async: true,
            data: { antistop: clickTag, type: type },
            success: function (data) {
                if (data.code === 0) {
                    let list = data.list;
                    if (list && list.length > 0) {
                        _this.setState({
                            selectIdValue: list[0],
                            ifDisabledLink: false
                        })
                    } else {
                        _this.setState({
                            ifDisabledLink: true
                        })
                    }
                    //dataID保存存的ID  dataIdindex保存的索引位置
                    let id = _this.state.dataID;
                    let dataIdindex = _this.state.dataIdindex;
                    let index = _this.state.clickIndex;//点击标签的索引
                    index = index.replace(/_/i, ",");//得到"x,y"
                    var strArr = index.split(",");//先将其分成字符串数组  
                    var intArr = [];//保存转换后的整型字符串  
                    intArr = strArr.map(function (data) {
                        return +data;
                    })
                    //判断保存的ID在不在获得ID列表里
                    let resavedId = list.filter(item => item == id);

                    if (resavedId.length > 0 && dataIdindex.toString() == intArr.toString()) {
                        _this.setState({ textIds: resavedId });
                        _this.getResult(id);
                    } else {
                        _this.setState({
                            textIds: list,
                            modifyShow: 'none'
                        }, () => {
                            _this._textIds = list
                            if (_this._textIds.length > 0) {
                                _this.getResult(_this._textIds[0]);
                            }
                            callback && callback()
                        });
                    }
                }
            }
        })
    }
    //修改ID列表信息
    updataTextId = (callback) => {
        let _this = this;
        let clickTag = this.state.clickTag;//点击关键字词文本
        let type = this.state.clickType;//点击关键字词的标记类型tpn/peop/liter...

        reqwest({
            url: cont.getTextId,
            method: 'post',
            type: 'json',
            async: true,
            data: { antistop: clickTag, type: type },
            success: function (data) {
                if (data.code === 0) {
                    let list = data.list;
                    if (list && list.length > 0) {
                        _this.setState({
                            selectIdValue: list[0],
                            ifDisabledLink: false
                        })
                    } else {
                        _this.setState({
                            ifDisabledLink: true
                        })
                    }

                    _this.setState({
                        textIds: list
                    }, () => {
                        _this._textIds = list
                        if (_this._textIds.length > 0) {
                            _this.getResult(_this._textIds[0]);
                        }
                        callback && callback()
                    });

                }
            }
        })
    }

    funcGetSelectText = () => {
        var txt = '';
        if (document.selection) {
            txt = document.selection.createRange().text;         //ie浏览器
        } else {
            txt = document.getSelection();   //其他浏览器
        }
        return txt.toString();
    }
    // 选中的文字设置新增弹框
    selected = () => {
        let _this = this;
        var txt = _this.funcGetSelectText();//获取选中的文字
        //获取选择文字的下标
        
        let startIndex = this.getSelectedIndex();
        //获取选中文字的坐标
        var range = window.getSelection().getRangeAt(0);
        var rect = range.getBoundingClientRect();
        var x = rect.left, y = rect.top;
        let num = txt.length * 2;
        var a = document.getElementsByClassName('popMenu')[0];
        a.onclick = function (e) {
            // e.stopPropagation()
        }

        console.log(a.style.left, a.style.top);
        a.style.left = x + num + 'px';
        a.style.top = y - 80 + 'px';
        a.style.position = 'absolute';
        if (txt) {
            _this.setState({
                showMakusMenu: true,
                selectwords: txt,
                startIndex: startIndex,
                ifDisabled: true,
                ifDisabledLink: true,
                isDisabled: false,
            });
        }
    }

    /**
     * 获取选中的文字的下标
     */
    getSelectedIndex = () => {
        let selection = window.getSelection();      //返回一个 Selection对象，表示用户选择的文本范围或光标的当前位置
        let startIndex = -1;
        if (selection) {
            // let baseNode = selection.baseNode;      //选中文字所在span的详细信息
            let baseNode = selection.anchorNode;      //选中文字所在span的详细信息
            let data = baseNode.data;               //选中文字所在span内的所有文字
            let anchorOffset = selection.anchorOffset;  //选中文字在span所有文字 内的偏移量，即在span内文字里的下标
            let focusOffset = selection.focusOffset;  //选中文字在span所有文字 内的偏移量，即在span内文字里的下标
            // 判断是从鼠标索引位置向前选中还是向后选中
            let offset = 0;
            if (anchorOffset > focusOffset) {
                offset = focusOffset;
            } else {
                offset = anchorOffset;
            }
            let previousElementSibling = baseNode.previousElementSibling;  //上一个节点信息 包括文本内容 span的属性信息 ClassName data_index信息 
            //开始下标  左边临界点
            let startIndexLeft = 0;
            if (previousElementSibling) {
                let outerHTML = previousElementSibling.outerHTML;  //"<span class="allclass liter" data-index="3_6">田間易學</span>"
                if (outerHTML) {
                    let dataIndex = outerHTML.match(/(data-index\=\"[0-9]{1,4}_[0-9]{1,4}\")/g);
                    if (dataIndex) {
                        dataIndex = dataIndex[0].match(/([0-9]{1,4}_[0-9]{1,4})/g);
                        if (dataIndex) {
                            let dataIndexArr = dataIndex[0].split("_");
                            startIndexLeft = dataIndexArr[1];
                            startIndex = offset + parseInt(startIndexLeft) + 1;
                        }
                    }
                }
            } else {
                startIndex = offset + startIndexLeft;
            }
        }
        return startIndex;
    }

    /**
     * 判断要添加的下标是否标记过
     * @param {*} _ner_index  标记的集合
     * @param {*} start  开始下标
     * @param {*} end    结束下标
     * @returns 返回 TRUE存在  FALSE不存在
     */
    IndexIsMask = (_ner_index, start, end) => {
        if (_ner_index && _ner_index.length > 0) {
            for (let i = 0; i < _ner_index.length; i++) {
                let _ner_index_inner = _ner_index[i];
                if (_ner_index_inner && _ner_index_inner.length > 0) {
                    for (let j = 0; j < _ner_index_inner.length; j++) {
                        let _ner_index = _ner_index_inner[j];
                        var objProps = Object.keys(_ner_index);
                        var objValues = _ner_index[objProps]
                        for (let k = 0; k < objValues.length; k++) {
                            let key = objValues[k]
                            if (key[0] == start || key[0] == end || key[1] == start || key[1] == end) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }
    /**
     * 判断两个数组是否相等,参数：父数组startIndex
     * fArray 父数组，cArray,子数组
     */
    arrayEqual = (fArray, fIndex, cArray) => {
        let checkFlag = true;
        if (fArray.length >= fIndex + cArray.length) {
            for (let i = 0; i < cArray.length; i++) {
                if (fArray[fIndex + i] != cArray[i]) {
                    checkFlag = false;
                    break;
                }
            }
        } else {
            checkFlag = false;
        }
        return checkFlag;
    }

    // 修改、添加、删除标记
    updateTag = (operateType, Ifkey, tagkey) => {
        let tag_key = '';
        // Ifkey是否是快捷键 是TRUE 否 FALSE
        if (Ifkey) {
            tag_key = tagkey;
        } else {
            if (operateType == 1 || operateType == 2) {
                if (!this.state.selectValue) {
                    message.warning('请选择标记方式');
                    return;
                }
            }
            let selectValue = this.state.selectValue;//得到用户选的标记信息
            tag_key = selectValue.tag_key; //得到用户选的标记tpn/peop/liter...
        }

        let clickTag = this.state.clickTag;//点击关键字词文本
        //let type = this.state.clickType;//点击关键字词的标记类型tpn/peop/liter...
        let index = this.state.clickIndex;//点击关键字词的索引
        index = index.replace(/_/i, ",");//得到"x,y"
        var strArr = index.split(",");//先将其分成字符串数组  
        var intArr = [];//保存转换后的整型字符串  
        // 方法一：
        intArr = strArr.map(function (data) {
            return +data;
        })
        // 方法二：
        // strArr.forEach(function (data, index, arr) {
        //     intArr.push(+data);
        // });

        // 删除标记
        if (operateType == 0) {
            let _ner_index = this.state._ner_index;
            let type = this.state.clickType;
            // 删除索引
            let ner_index = deleteTagsIndex(_ner_index, type, intArr);
            this.setState({ _ner_index: ner_index })
            // 调接口，将数据传给后台存储
            // let datajson = {}
            // datajson.index = this.state._ner_index;
            // let data = JSON.stringify(datajson);

            this.dataSou.data.index = ner_index;
            let data = JSON.stringify(this.dataSou);
            let temptext = doText(this.source, this.state._ner_index);
            // 接口
            reqwest({
                url: '/wyd//result/' + this.fildID,
                method: 'put',
                type: 'json',
                headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
                data: { body: temptext, content: data },
                success: function (data) {
                    if (data.code == 200) {
                        message.success("删除成功！")
                    } else {
                        message.error("删除失败！")
                    }
                },
                error: (err) => {
                    let mes = JSON.parse(err.response)
                    message.error(mes.message)
                    // message.error("删除失败！")
                },
            })
            // 渲染页面
            // let temptext = doText(this.source, this.state._ner_index);
            this.setState({ showMakusMenu: false });
            this.deal_textcontent = temptext;
        }
        // 修改标记
        if (operateType == 1) {
            let _ner_index = this.state._ner_index;
            let type = this.state.clickType;

            let ner_index = updateTagIndex(_ner_index, type, intArr, tag_key);
            this.setState({ _ner_index: ner_index });
            // 调接口，将数据传给后台存储
            // let datajson = {};
            // datajson.index = this.state._ner_index;
            // let data = JSON.stringify(datajson);

            this.dataSou.data.index = ner_index;
            let data = JSON.stringify(this.dataSou);
            let temptext = doText(this.source, this.state._ner_index);
            // 接口
            reqwest({
                url: '/wyd//result/' + this.fildID,
                method: 'put',
                type: 'json',
                headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
                data: { body: temptext, content: data },
                success: function (data) {
                    if (data.code == 200) {
                        message.success("保存成功！");
                    } else {
                        message.error("保存失败！");
                    }
                },
                error: (err) => {
                    let mes = JSON.parse(err.response);
                    message.error(mes.message);
                    // message.error("保存失败！");
                },
            })

            // 渲染页面   关闭弹框
            // let temptext = doText(this.source, this.state._ner_index);
            this.setState({ showEditTags: false, showMakusMenu: false });
            this.deal_textcontent = temptext;
        }
        // 添加标记
        if (operateType == 2) {
            let _ner_index = this.state._ner_index;
            // 全文添加 ---- 获取添加标签文本下标 （有一点问题，未完成：添加时没有遍历，且标记过的 未做判断）
            // var keywords = this.state.selectwords;
            // var tempText = Array.from(this.source);
            // var tempKeywords = Array.from(keywords);
            // var arrIndex = []
            // for (let j = 0; j < tempText.length; j++) {
            //     if (this.arrayEqual(tempText, j, tempKeywords)) {
            //         arrIndex.push(j);
            //         j = j + tempKeywords.length;
            //     }
            // }
            // var arr = new Array();//先声明一维
            // for (var i = 0; i < arrIndex.length; i++) {   //一维长度
            //     for (var j = 0; j < arrIndex.length; j++) {
            //         arr[j] = new Array(); //再声明二维
            //         let endIndex = arrIndex[j] + tempKeywords.length - 1;
            //         arr[j].push(arrIndex[j], endIndex);
            //     }
            // }
            // console.log(arr);
            let keywords = this.state.selectwords;
            let start = this.state.startIndex;
            let end = start + keywords.length - 1;

            //判断下标是否被标记过----如果标记过不能进行标记
            let flag = this.IndexIsMask(_ner_index, start, end);
            if (flag) {
                message.error("选中文字已被标记过不能再次添加！");
                return;
            }
            let index = new Array();
            index[0] = start;
            index[1] = end;
            // 添加索引
            let ner_index = addTagIndex(_ner_index, tag_key, index);
            this.setState({ _ner_index: ner_index });

            // 调接口，将数据传给后台存储
            // let datajson = {};
            // datajson.index = this.state._ner_index;
            // let data = JSON.stringify(datajson);

            this.dataSou.data.index = ner_index;
            let data = JSON.stringify(this.dataSou);
            let temptext = doText(this.source, this.state._ner_index);
            // 接口
            reqwest({
                url: '/wyd//result/' + this.fildID,
                method: 'put',
                type: 'json',
                headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
                data: { body: temptext, content: data },
                success: function (data) {
                    if (data.code == 200) {
                        message.success("保存成功！");
                    } else {
                        message.error("保存失败！");
                    }
                },
                error: (err) => {
                    let mes = JSON.parse(err.response);
                    message.error(mes.message);
                    // message.error("保存失败！");
                },
            })
            // 渲染页面 关闭弹框
            // let temptext = doText(this.source, this.state._ner_index);
            this.setState({ showAddTags: false, showMakusMenu: false });
            this.deal_textcontent = temptext;
        }
        this.showMenu();

    }

    getMousePos = (event) => {
        var e = event || window.event;
        var x = e.screenX;
        console.log(x);
        // return {'x':e.screenX,'y':screenY}
        var range = window.getSelection().getRangeAt(0);
        var rect = range.getBoundingClientRect();
        var x = rect.left, y = rect.top;
        return x;
    }


    render() {
        const { result, textIds, selectIdValue, ifDisabled, isDisabled, ifDisabledLink } = this.state;
        console.log(result);
        const dowloadMenu =
            <Menu className='woc123'>
                <Menu.Item onClick={() => {
                    this.download('word')
                }}>
                    <a target="_blank" rel="noopener noreferrer" >
                        word
                    </a>
                </Menu.Item>
                <Menu.Item onClick={() => {
                    this.download('pdf')
                }}>
                    <a target="_blank" rel="noopener noreferrer">
                        pdf
                    </a>
                </Menu.Item>
                <Menu.Item onClick={() => {
                    this.download('html')
                }}>
                    <a target="_blank" rel="noopener noreferrer" >
                        html
                    </a>
                </Menu.Item>
                <Menu.Item onClick={() => {
                    this.download('xml', 'export')
                }}>
                    <a target="_blank" rel="noopener noreferrer">
                        xml
                    </a>
                </Menu.Item>
            </Menu >
        var reg = new RegExp("ο", "g");
        const editTag = (
            <div className='addlabel'>
                <Radio.Group onChange={this.selectTagChange} >
                    {this.state.tagList.map(item => item.visible &&
                        <Radio className='radioSty' value={item} key={item.id} style={item.styles[item.style_index]}>{item.tag_name}</Radio>
                    )}
                </Radio.Group>
                <div style={{ paddingTop: '20px', paddingLeft: "140px" }}>
                    <Button onClick={() => { this.updateTag(1) }}>保存</Button>
                </div>
            </div>
        );
        const addTag = (
            <div className='addlabel'>
                <Radio.Group onChange={this.selectTagChange} >
                    {this.state.tagList.map(item => item.visible &&
                        <Radio className='radioSty' value={item} key={item.id} style={item.styles[item.style_index]}>{item.tag_name}</Radio>
                    )}
                </Radio.Group>
                <div style={{ paddingTop: '20px', paddingLeft: "140px" }}>
                    <Button onClick={() => { this.updateTag(2) }}>保存</Button>
                </div>
            </div>
        );
        const content = (
            <div className='popoverMenu'>
                <Popover
                    className='popsty'
                    content={editTag}
                    title={<Button style={{ color: '#096dd9', border: "none", fontFamily: "中粗" }}>修改标记</Button>}
                    trigger="click"
                    visible={this.state.showEditTags}
                    placement="bottom"
                    onVisibleChange={this.handleVisibleChange}
                >
                    <Button className='popbtn' size='small' disabled={ifDisabled} onClick={() => { this.setState({ showEditTags: true, }) }}><FormOutlined className='popicon' />修改</Button> |
                </Popover>
                <Popover
                    className='popsty'
                    content={addTag}
                    title={<Button style={{ color: '#096dd9', border: "none", fontFamily: "中粗" }}>添加标记</Button>}
                    trigger="click"
                    visible={this.state.showAddTags}
                    placement="bottom"
                    onVisibleChange={this.handleAddVisibleChange}
                >
                    <Button className='popbtn' size='small' disabled={isDisabled} onClick={() => { this.setState({ showAddTags: true }) }}><PlusSquareOutlined className='popicon' />添加</Button> |
                </Popover>
                <Popconfirm
                    className='popsty'
                    title={convert("确定删除该标记吗?", this.locale)}
                    onConfirm={() => { this.updateTag(0) }}
                    okText={convert("确定", this.locale)}
                    cancelText={convert("取消", this.locale)}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button className='popbtn' size='small' disabled={ifDisabled} type="dashed"><DeleteOutlined className='popicon' />删除</Button> |
                </Popconfirm>
                <Button className='popbtn' size='small' disabled={ifDisabledLink} onClick={() => { this.linkModalVisible() }}><LinkOutlined className='popicon' />实体链接</Button>
            </div>
        )
        return (
            <Spin spinning={this.state.visible2}>
                <Content>
                    <div className='content'>
                        <div className='Acon'>
                            <div className='Acon_R' id="doc">
                                <Space style={{ float: 'right', "width": "100%", "display": "inline-block", height: "32px", marginBottom: "10px" }} >
                                    <span style={{ float: "left" }}>

                                        <Dropdown className="r_btn" overlay={dowloadMenu} placement="bottomCenter">
                                            <Button className="wyd_btn" icon={<DownloadOutlined />}>
                                                {/* <span style={{marginRight:"5px"}}><img src={out}/></span> */} 导出</Button>
                                        </Dropdown>

                                        <Button className="resave wyd_btn"
                                            icon={<SaveOutlined />}
                                            onClick={() => {
                                                if (!localStorage.getItem("token")) {
                                                    message.warning("暂不支持此功能！"); return
                                                };
                                                this.setState({
                                                    visible3: true
                                                })
                                            }}>
                                            {convert('保存', this.locale)}</Button>

                                        {/* <Button className="back wyd_btn" icon={<ArrowLeftOutlined />} onClick={() => { this.props.history.push('/AutomaticDu') }}></Button> */}
                                        {/* <button className='goback' onClick={() => { this.props.history.push('/NamedEntity') }} title='重新上传'>
                                            <img src={goback} alt='重新上传' style={{ width: '100%', height: ' 100%', paddingBottom: '5px' }} />
                                        </button> */}
                                        <Tooltip title="重新上传">
                                            <button className='goback' onClick={() => { this.gotoPrePage() }} title='重新上传'>
                                                <img src={goback} alt='重新上传' style={{ width: '100%', height: ' 100%', paddingBottom: '5px' }} />
                                            </button>
                                        </Tooltip>
                                    </span>

                                    {/* <Radio.Group style={{
                                        "marginRight": "20px",
                                        "float": "left"
                                    }} buttonStyle='solid' onChange={e => {
                                        this.setState({
                                            search_type: e.target.value
                                        })
                                    }} value={this.state.search_type}>
                                        <Radio.Button style={{
                                            display: "none",
                                            "background": this.state.search_type == "fast"
                                                ? "rgba(113, 166, 197,1)" : "white",
                                            "color": this.state.search_type == "fast" ? "white" : "black",
                                            "border": "1px solid rgba(113, 166, 197,1)"
                                        }} value='fast' ><span style={{ marginRight: "5px" }}>
                                                <img src={rt2} /></span>{convert(`专名识别`, this.locale)}</Radio.Button>

                                    </Radio.Group> */}

                                    <ButtonGroup style={{ float: "right" }}>
                                        <Tooltip title="缩小">
                                            <Button onClick={() => { this.decline() }}
                                                style={{
                                                    width: "30px", fontSize: "10px", background: "none",
                                                    color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)"
                                                }}
                                                icon={<FontSizeOutlined />}
                                                className="wyd_btn decline mycolor">
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="放大">
                                            <Button className="wyd_btn increase mycolor"
                                                icon={<FontSizeOutlined />}
                                                style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}
                                                onClick={() => { this.increase() }}>
                                                {/* <span >-</span> */}
                                                {/* <span style={{marginRight:"5px"}}>+</span> */}
                                            </Button>
                                        </Tooltip>
                                        {/* <Tooltip title="复制">
                                            <Button className="wyd_btn mycolor"
                                                style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}

                                                icon={<CopyOutlined />} onClick={() => { this.copyone() }}>
                                                <img src={copy}></img>
                                                <span ></span>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="清空">
                                            <Button className="wyd_btn mycolor"
                                                style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)" }}
                                                icon={<DeleteOutlined />} onClick={() => { this.delet() }}>
                                                <img src={delet2} ></img>
                                                <span></span>
                                            </Button>
                                        </Tooltip> */}
                                        {/* <Popover placement="bottomRight" content={content} title="专名识别符添加方法：">
                                            <Button className="wyd_btn mycolor" icon={<FormOutlined />}
                                                style={{ width: "30px", background: "none", color: "#6f6f6f", border: "1px solid rgba(217, 217, 217,1)", }}
                                                onClick={() => { this.edit() }}>
                                                 <img src={edit} ></img>
                                                <span>编辑</span> 
                                            </Button>
                                        </Popover> */}
                                    </ButtonGroup>
                                </Space>
                                <div className='Acon_Rco' style={{ "minHeight": this.state.deskHeight - 300, "overflow": "auto", padding: 0, background: "none", border: "1px solid #d9d9d9" }}>
                                    {this.deal_textcontent.length === 0 && <div className='ziwen'>
                                        {convert("暂无数据", this.locale)}
                                    </div>}
                                    {
                                        <div className="content"
                                            id="edit"
                                            // onDoubleClick={() => { this.insertHtmlAtCaret("<span style='color:red;font-size:" + (this.state.count - 4) + "px'>〇</span>") }}
                                            style={{
                                                lineHeight: "1.8;",
                                                padding: "4px 20px",
                                                fontSize: 18,
                                            }}
                                            onMouseUp={this.selected}
                                            contenteditable={this.state.edit == true ? "true" : "false"} >
                                            {this.deal_textcontent instanceof Array == true ? this.deal_textcontent.map((item, index) => <span key={index}>{
                                                item instanceof Array == true ?
                                                    item.map((i, num) =>
                                                        <span dangerouslySetInnerHTML={{ __html: i.replace(reg, "<span style=color:rgba(114,164,190,1)>ο</span>") }}></span>
                                                        //<span dangerouslySetInnerHTML={{ __html: i }}></span>
                                                        // <span dangerouslySetInnerHTML={{ __html: i.replace(reg, "<span style=color:rgba(114,164,190,1)>ο</span>") }}></span>
                                                        // i == "ο" ? <span style='color':'rgba(114,164,190,1)'>ο</span> :
                                                    )
                                                    //  <div dangerouslySetInnerHTML={{ __html: item.replace(reg, "<span style={{'color':'rgba(114,164,190,1)'}}>ο</span>") }}></div>
                                                    // <span style={{ "color":"rgba(114,164,190,1)"}}>ο</span>
                                                    : item
                                            }</span>) :
                                                <span dangerouslySetInnerHTML={{ __html: this.deal_textcontent.replace(reg, "<span style=color:rgba(114,164,190,1)>ο</span>") }}></span>}
                                        </div>
                                    }
                                    <Popover
                                        className='popMenu'
                                        content={content}
                                        trigger="click"
                                        visible={this.state.showMakusMenu}
                                        // placement={this.popPosition}
                                        placement={'top'}
                                        arrowPointAtCenter={true}
                                        onVisibleChange={this.handleVisible}>
                                    </Popover>
                                </div>
                                {/* <div style={{ textAlign: 'left', paddingTop: '8px' }}>
                                    <p style={{ fontSize: "12px", color: "black" }}>提示：仅支持UTF-8格式文本，游客：建议≤500字/次，12次/天；注册用户：建议≤1000字/次，20次/天；为了更好的体验，建议字体：<a style={{ color: "#096dd9" }} href="http://fonts.jp/hanazono/" target="blank">花园明朝体</a>
                                            <span className="closethis" style={{
                                                fontSize: "12px",
                                                textAlign: "right", lineHeight: "32px"
                                            }}>
                                                <span className="_closethis" style={{ float: "right" }} onClick={() => { this.closethis() }}>关闭</span>
                                            </span> 
                                        <span style={{ float: "right", marginRight: "20px" }}>当前字数：{this.deal_textcontent.length}/{!localStorage.getItem("token") ? 500 : 1000}</span>
                                    </p>
                                </div>*/}

                            </div>
                        </div>
                        <div className='addlabel'>
                            {this.state.tagList.map(item => item.visible && <Button key={item.id} onClick={() => {
                                this.switchStyle(item)
                            }} style={item.styles[item.style_index]}>{item.tag_name}</Button>)}

                            <Popover
                                overlayClassName='qeerwaa'
                                placement="left"
                                visible={this.state.visible1}
                                title={<div>{convert('添加标签', this.locale)}</div>}
                                content={<div>
                                    <div style={{ display: 'flex' }}>
                                        <Input placeholder={convert('标签名…', this.locale)} value={this.state.tag_name} onChange={e => {
                                            this.setState({
                                                tag_name: e.target.value
                                            })
                                        }} />
                                        <Popover overlayClassName='sectcolors' placement="bottom" content={<SketchPicker color={this.state.color} onChange={(e) => {
                                            this.setState({
                                                color: e.hex
                                            })
                                        }} />} trigger="click">
                                            <div style={{ height: 32, width: 34, padding: "5px", border: "1px solid #D7D9DC", borderLeft: "0" }}>
                                                <div style={{ backgroundColor: this.state.color }} className='colorsect'>
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div>{convert('（请输入6个汉字以内的标签名并选择颜色）', this.locale)}</div>
                                    <div><Input value={this.state.tag_key} placeholder={convert('标签标识…', this.locale)} onChange={e => {
                                        this.setState({
                                            tag_key: e.target.value
                                        })
                                    }} /></div>
                                    <div style={{ textAlign: "right" }}>
                                        <Button onClick={() => {
                                            this.setState({
                                                visible1: false,
                                                tag_key: '',
                                                tag_name: '',
                                                color: '#000000'
                                            })
                                        }}>{convert('取消', this.locale)}</Button>
                                        <Button onClick={() => {

                                            let keyword = this.state.selectText
                                            let searchResult = this.search(keyword)
                                            let ns = searchResult.map(item => item.position)//选中的位置
                                            this.addTag((tag_key) => {
                                                this.addTextTag(tag_key, ns)
                                            })
                                        }}>{convert('确定', this.locale)}</Button>
                                    </div>
                                </div>
                                }
                                trigger="click" >
                                {/* <Button className='zenj' onClick={() => {
                                    this.setState({
                                        visible1: true,
                                        selectText: getSelection().toString()
                                    })
                                    }}><PlusOutlined /></Button> */}
                            </Popover>
                        </div>
                    </div>
                    {/* 保存Modal */}
                    <Modal
                        className='saveModal'
                        title={<span><span style={{ color: 'red' }}>* </span>保存</span>}
                        visible={this.state.visible3}
                        destroyOnClose={true}
                        onOk={() => {
                            this.setState({
                                visible3: false
                            }, () => {
                                this.resave()
                            })
                        }}
                        onCancel={() => {
                            this.setState({
                                visible3: false
                            })
                        }}
                        okText="提交"
                        // cancelText="取消"
                        footer={[<Button key="submit" type="primary" onClick={this.handleOk} style={{ background: '#096dd9', marginRight: '200px' }}> 提交</Button>]}
                    >
                        <Input placeholder={convert('请输入文件名')} defaultValue={!this.props.duData?(this.props.filedata?this.props.filedata.filename:""):""}
                            onChange={e => {
                                this.setState({
                                    fildName: e.target.value
                                })
                            }}></Input>
                    </Modal>
                    {/* 实体链接Modal */}
                    <Modal
                        title={<span className='linkTitle'>实体链接</span>}
                        visible={this.state.linkModalVisible}
                        onVisibleChange={this.handleLinkVisible}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={() => { this.setState({ linkModalVisible: false }) }}
                    >
                        <div className='locale-components' style={{ paddingLeft: "15px;" }}>

                            <div style={{ display: 'flex' }}>
                                <div className='leftClass'>
                                    <span style={{ paddingLeft: "16px;", lineHeight: "38px;" }}>  IDs:
                                        <Radio.Group onChange={this.selectIDChange} optionType="button" buttonStyle="solid" value={selectIdValue ? selectIdValue : textIds[0]} defaultValue={textIds[0]}>
                                            {textIds && textIds.length > 0 ? textIds.map(item =>
                                                <Radio.Button value={item} key={item} className='radioId' >{item}</Radio.Button>
                                            ) : null}
                                        </Radio.Group>
                                    </span>
                                </div>
                                <div className='rightClass'>
                                    <div style={{ display: this.state.modifyShow }} className="modify" onClick={() => { this.updataTextId() }}> <img src={modify}></img></div>
                                    {/* <EditOutlined className="modify" onClick={() => { this.updataTextId() }} /> */}
                                    <SaveOutlined className='saveIcon' onClick={() => { this.resaveID() }} />
                                </div>
                            </div>
                            <div>
                                <div className='linkdiv'>
                                    <div className="main">
                                        {result && textIds.length > 0 ?
                                            <>
                                                <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr><td>CBDB ID: {result.c_personid}</td></tr>
                                                        <tr><td>索引/中文/英文名稱: {result.c_name_chn}{result.c_name}</td></tr>
                                                        <tr><td>指數年(index year): {result.c_index_year}</td></tr>
                                                        <tr><td>生年: {result.by_c_dynasty_chn}{result.by_c_nianhao_chn}{result.c_by_nh_year}年({result.c_birthyear})</td></tr>
                                                        <tr><td>卒年: {result.dy_c_dynasty_chn}{result.dy_c_nianhao_chn}{result.c_dy_nh_year}年({result.c_deathyear})</td></tr>
                                                        <tr><td>享年: {result.c_death_age}</td></tr>
                                                        <tr><td>為女性: {result.c_female}</td></tr>
                                                        <tr><td>郡望: {result.c_choronym_chn}</td></tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <table width="100%" style={{ fontSize: "medium" }} cellSpacing="0" cellPadding="0"><tbody><tr>
                                                                    <td valign="top" align="left"><nobr><font>註</font>:&nbsp;</nobr></td>
                                                                    <td>{result.c_notes}</td>
                                                                </tr></tbody></table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>出處: </nobr></td>
                                                            <td><table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                <tbody id="outer">
                                                                </tbody></table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <table style={{ fontSize: "medium" }}>
                                                    <tr>
                                                        <td valign="top"><nobr>别名: </nobr></td>
                                                        <td>
                                                            <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                <tbody id="altname"></tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                                <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>地理資訊: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="address">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* 入仕 */}
                                                {/* <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>入仕: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="entry">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                {/* 任官 */}
                                                {/* <table style={{ fontSize: "medium" }} cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>任官: </nobr></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="office">
                                                                <table width="100%" cellPadding="2" cellSpacing="1" style={{ fontSize: "medium" }}>
                                                                    <tbody >
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                {/* 社會區分 */}
                                                {/* <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>社會區分: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="status">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                {/* 親屬關係 */}
                                                {/* <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>親屬關係: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="kin">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                {/* 社會關係 */}
                                                {/* <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>社會關係: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="assoc">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                {/* 著述 */}
                                                {/* <table style={{ fontSize: "medium" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"><nobr>著述: </nobr></td>
                                                            <td>
                                                                <table width="100%" cellPadding="0" cellSpacing="0" style={{ fontSize: "medium" }}>
                                                                    <tbody id="text">
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}
                                            </>
                                            : <div className='noText'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" /></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Content>
            </Spin>
        )
    }
}
function mapStateToProps(state) {
    return {
        dealTextContent: state.dealTextContent,
        handledData: state.handledData,
        filedata: state.filedata,
    }
}
export default withRouter(connect(mapStateToProps)(NamedEntityDisposed));