import React, { Component } from 'react';
import {
  Layout, Popover, Button, Upload, Modal,
  Progress, Input, message, Select
} from 'antd';
import './AutomaticDu.css';
import convert from '../../utils/locale_zh_convert';
import { UploadOutlined, DeleteOutlined, FileTextOutlined, CaretRightOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom'
import BraftEditor from 'braft-editor';
import reqwest from 'reqwest'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import 'braft-editor/dist/index.css';
import { set_deal_content } from './../../actions/DealTextContentAction'
import { connect } from 'react-redux';
import imgurl1 from "../../images/u110.svg"
import imgurl2 from "../../images/u31.svg"
import loading from "../../images/loading.svg"

const ButtonGroup = Button.Group;
const { Content } = Layout;

function handleChange(value) {
  let ziwen = document.getElementsByClassName("content")[0]
  ziwen.style.fontSize = value + "px"
}
function handleChange1(value) {
  console.log(value)
}
class AutomaticDu extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
    let content = ''
    // if (props.dealTextContent && props.dealTextContent.content) {
    //   content = props.dealTextContent.content.join('')
    // } else if (props.dealTextContent && props.dealTextContent.jd_deal_content && props.dealTextContent.jd_deal_content.content) {
    //   content = props.dealTextContent.jd_deal_content.content.join('')
    // }
    console.log(props)
    this.state = {
      count: 18,
      show: true,
      fildID: "",
      edit: false,
      imgurl: imgurl2,
      imgstate: true,
      strongstate: true,
      visible1: false,
      visible2: false,
      fileList: [],
      type: 1,//1,上传文件, 2:输入文本,
      textcontent: content, //处理前文本
      deal_textcontent: this.props.dealTextContent && this.props.dealTextContent.jd_deal_content && this.props.dealTextContent.jd_deal_content.deal_content ? this.props.dealTextContent.jd_deal_content.deal_content : [],//处理后文本
      progress: 0,
      complete: false,
      action: 'b',
      task_uid: this.props.dealTextContent ? this.props.dealTextContent.uid : '',
      task_identify: this.props.dealTextContent ? this.props.dealTextContent.identify : '',
      inputValue: 0,
      times: 10,
      max_num: 128,
      search_type: "fast",
      todoHandleData: this.props.todoHandleData,//从识别过来要进行标点的文本
      deskHeight: document.body.clientHeight,
      rate: !localStorage.getItem("token") ? 12 : 20,
      rateNum: 20,
      rateVisile: this.props.duData ? this.props.duData.rateNum : false,
      // rateNumber:!localStorage.getItem(localStorage.getItem("token"))
    };

    this._textcontent = ''////处理前文本缓存
    this._deal_textcontent = ''////处理后文本缓存
    this.sl__deal_textcontent = []
    this._biodian_index = null////处理后标点缓存
    this.lastEditRange = {}
  }

  showModalM1 = () => {
    this.setState({
      visible1: true,
    });
  };
  strong = () => {
    let ziwen = document.getElementsByClassName("content")[0]
    this.setState({
      strongstate: !this.state.strongstate
    })
    if (this.state.strongstate == true) {
      ziwen.style.fontWeight = "900"
    } else {
      ziwen.style.fontWeight = "400"
    }

  }
  handleOkM1 = e => {
    this.setState({
      visible1: false,
    });
  };

  handleCancelM1 = e => {
    this.setState({
      visible1: false,
    });
  };

  showModalM2 = () => {
    this.setState({
      visible2: true,
    });
  };

  handleOkM2 = e => {
    this.setState({
      visible2: false,
    });
  };

  handleCancelM2 = e => {
    this.setState({
      visible2: false,
    });
  };

  componentWillMount() {
    let number = localStorage.getItem("number")
    if (number == 1) {
      localStorage.setItem("number", 2)
      window.location.reload()
    }
  }

  jd = (textcontent, callback) => {
    this.setState({
      show2: true
    })

    let _this = this
    if (!textcontent) {
      message.error('待处理文本不能为空')
      return
    } else {
      // if(!textcontent.content || textcontent.content.length==0){
      if (textcontent.content && textcontent.content.length == 0 ||
        (textcontent.deal_textcontent || (textcontent.deal_textcontent && textcontent.deal_textcontent.length == 0)) ||
        textcontent.fildID) {
        return;
      }
    }
    _this.setState({
      visible2: true,
      complete: false,
      progress: 0,
      showstate: this.state.search_type == "fast" ? 1 : 2
    }, () => {
      _this.progressRun()

      let sentence = textcontent
      // sentence = sentence.replace(/\s+/g, '')
      // ,exact
      if (this.state.search_type == "fast") {

        var reqXhr = new XMLHttpRequest();
        var form = new FormData();
        form.append("sentence", sentence)
        reqXhr.open('POST', '/wyd/predict/split', false);
        reqXhr.responseType = 'json';
        reqXhr.setRequestHeader("WYD-TOKEN", localStorage.getItem("token") == null ? "" : localStorage.getItem("token"))
        console.log(reqXhr.getResponseHeader('rate-1d'));
        reqXhr.onload = function () {
          console.log(this.response);
          console.log(reqXhr.getResponseHeader('rate-1d'));
          let data = JSON.parse(this.response);
          //console.log('data is',data);
          if (data.code == 200) {
            // 获取响应头
            //一定要在这了清空，不然会崩
            document.getElementsByClassName("content")[0].innerHTML = ""
            let content = data.data.content
            // let content = data.data.source
            let biodian = data.data.index
            _this._deal_textcontent = content
            _this.setState({
              textcontent: data.data.source
            })
            let arr = []
            for (let i = 0; i < _this._deal_textcontent.length; i++) {
              arr.push([_this._deal_textcontent[i].split("")])
            }
            console.log(arr)
            // _this.sl__deal_textcontent = _this._deal_textcontent.split("")
            _this.sl__deal_textcontent = arr
            console.log(_this.sl__deal_textcontent.length)
            _this._biodian_index = biodian
            _this._textcontent = _this.state.textcontent
            let fildID = data.data.id;
            // _this.doText(_this.state.action)

            let obj = {};
            obj._deal_textcontent = _this._deal_textcontent;
            obj.sl__deal_textcontent = _this.sl__deal_textcontent;
            obj.fildID = fildID;

            var rate = reqXhr.getResponseHeader('rate-1d');
            if (rate) {
              obj.rateNum = true
              let arrRate = rate.split("/");
              console.log(arrRate[0]);
              let rateNum = arrRate[1] - arrRate[0];
              let tempToken = localStorage.getItem("token");
              let tempID = localStorage.getItem("id");
              if (tempToken) {
                let rateobj = localStorage.getItem(tempID);
                if (!rateobj) {
                  rateobj = {};
                } else {
                  rateobj = JSON.parse(rateobj);
                }
                rateobj['rateNum'] = rateNum;
                rateobj = JSON.stringify(rateobj);
                localStorage.setItem(tempID, rateobj);

              } else {
                // _this.setState({rateVisile : false})
                localStorage.setItem("rateNum", rateNum);
              }
            }
            _this.props.dispatch({ type: "initAutomaticDuDisposed", data: { duData: obj } });
            _this.props.history.push('/AutomaticDuDisposed')
          } else if (data.code == 500) {
            message.error(data.message)
          }
          callback && callback()
          _this.setState({
            visible2: false,
            complete: true,
          })

        }
        reqXhr.send(form);

      } else {
        reqwest({
          url: '/wyd/predict/punctuation',
          method: 'post',
          type: 'json',
          headers: { "WYD-TOKEN": localStorage.getItem("token") },
          data: { sentence: sentence },
          success: function (data) {
            if (data.code == 200) {
              let content = data.data.content
              let biodian = data.data.index
              _this._deal_textcontent = content
              _this.sl__deal_textcontent = _this._deal_textcontent.split("")
              _this._biodian_index = biodian
              _this._textcontent = textcontent
              _this.setState({
                fildID: data.data.id
              })
              // _this.doText(_this.state.action)
            } else if (data.code == 500) {
              message.error(data.message)
            }
            callback && callback()
            _this.setState({
              visible2: false,
              complete: true,
            })
          }, error: (err) => {
            _this.setState({
              visible2: false,
              complete: true,
            }, () => {
              let mes = JSON.parse(err.response)
              message.error(mes.message)
            })
          }
        })
      }

    })

  }
  componentDidMount() {
    // this.getLastVersion(2)
    // this.props.dispatch({ type: "currentPage", data: { currentPage: "AutomaticDu" } });

    let todoHandleAutomaticDuFlag = this.props.todoHandleData.flag;
    if (todoHandleAutomaticDuFlag == 1) {

      let todoHandleData = this.props.todoHandleData;
      todoHandleData.flag = 0;
      this.props.dispatch({ type: "todoHandleAutomaticDu", data: { todoHandleData: todoHandleData } });
      this.jd(this.props.todoHandleData.content);
    }

    let tempToken = localStorage.getItem("token");
    let tempID = localStorage.getItem("id");
    if (tempToken) {
      let rateobj = localStorage.getItem(tempID);
      if (rateobj) {
        rateobj = JSON.parse(rateobj);
        let tempRateNum = rateobj['rateNum'];
        if (tempRateNum != undefined) {

          this.setState({
            rateNum: tempRateNum
          })
        }
      }
    } else {
      let tempNum = localStorage.getItem("rateNum");
      if (tempNum) {
        this.setState({
          rate: tempNum
        })
      }

    }


  }
  getLastVersion = (action_order) => {
    let _this = this;
    reqwest({
      url: '/text/getLastVersion.htm',
      method: 'get',
      type: 'json',
      data: { action_order: action_order },
      success: function (data) {
        if (data.code === 0) {
          if (data.item && !_this.state.textcontent) {
            Modal.confirm({
              onOk: close => {
                let content = ''
                if (data.item.jd_deal_content && data.item.jd_deal_content.content) {
                  content = data.item.jd_deal_content.content.join('')
                } else if (data.item && data.item.content) {
                  content = data.item.content.join('')
                }
                _this.setState({
                  task_uid: data.item.uid,
                  textcontent: content,
                  // deal_textcontent: data.item.jd_deal_content ? data.item.jd_deal_content : [],
                  task_identify: data.item.identify
                }, () => {
                  let jd_deal_content = data.item.jd_deal_content
                  _this.props.dispatch(set_deal_content(data.item.uid, data.item.content, data.item.identify, data.item.jd_deal_content, data.item.mm_deal_content))
                  if (jd_deal_content && Object.keys(jd_deal_content).length > 0) {
                    let content = jd_deal_content.content.join('')
                    let ner_index = jd_deal_content.ner_index
                    _this._deal_textcontent = jd_deal_content.deal_content ? jd_deal_content.deal_content : []
                    _this._textcontent = content
                    _this._biodian_index = ner_index
                    _this.doText(_this.state.action)
                  } else {
                    _this._textcontent = data.item.content
                  }

                })
                close()
              },
              onCancel: close => {
                close()
              },
              content: `检测到存在已经经过${data.item.action}处理的历史文本数据,是否载入?`,
              okText: '确定',
              cancelText: '取消',
            })
          }

        } else if (data.code === 1) {
          message.error(data.errmsg ? data.errmsg : '未知错误')
        }
      }, error: (err) => {
        _this.setState({
          versionList: []
        })
        // message.error(err.status + ':' + err.statusText)
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
  }
  doText = (type) => {
    let _this = this
    let _deal_textcontent = _this._deal_textcontent
    if (!_deal_textcontent) {
      return
    }
    let _biodian_index = _this._biodian_index


    let arr = []
    for (var key in _biodian_index) {
      arr = [...arr, ..._biodian_index[key]]
      // _deal_textcontent = _deal_textcontent.replace(new RegExp(`${key}`, "g"), '')
    }
    arr.sort((a, b) => {
      return a[0] - b[0]
    })

    let conten = _this._textcontent
    let c = Array.from(conten)
    let dt = arr.map((item, index) => c.slice(item[0], item[1] + 1).join(''))

    if (type === 'b') {
      _this.setState({
        deal_textcontent: [dt.join("ο")]
        // deal_textcontent: [dt.join("<span className={{'ccc'}}>ο</span>")]
      })
    } else {
      _this.setState({
        deal_textcontent: dt
      })
    }

    let uid = _this.state.task_uid
    let identify = _this.state.task_identify
    let origin_content = this.props.dealTextContent && this.props.dealTextContent.content
      && this.props.dealTextContent.content.length > 0 ? this.props.dealTextContent.content : [_this.state.textcontent]
    let jb_deal_content = {
      ner_index: _biodian_index,
      content: [_this.state.textcontent],
      deal_content: [dt.join("ο")],
      // deal_content: [dt.join("<span className={{'ccc'}}>ο</span>")],
      deal_content_ner: [_deal_textcontent],
    }
    _this.props.dispatch(set_deal_content(uid, origin_content, identify, jb_deal_content))
    _this.saveVersion(uid, origin_content, identify, '自动句读', jb_deal_content)


    // if (type === 'b') {

    //   _this.setState({
    //     deal_textcontent: [dt.join('〇')]
    //   })

    //   let uid = _this.state.task_uid
    //   let identify = _this.state.task_identify
    //   let origin_content = this.props.dealTextContent && this.props.dealTextContent.content
    //     && this.props.dealTextContent.content.length > 0 ? this.props.dealTextContent.content : [_this.state.textcontent]
    //   let jb_deal_content = {
    //     ner_index: _biodian_index,
    //     content: [_this.state.textcontent],
    //     deal_content: [dt.join('〇')],
    //     deal_content_ner: [_deal_textcontent],
    //   }
    //   _this.props.dispatch(set_deal_content(uid, origin_content, identify, jb_deal_content))
    //   _this.saveVersion(uid, origin_content, identify, '自动句读', jb_deal_content)
    // } else if (type === 'a') {

    // }
  }
  ban = () => {
    if (this.state.task_uid) {
    } else {
      if (!localStorage.getItem("token")) {
        if (this.state.textcontent.length > 500) {
          message.warn('粘贴文本目前只支持最大500字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 500) })
        }
      } else if (localStorage.getItem("token")) {
        if (this.state.textcontent.length > 1000) {
          message.warn('粘贴文本目前只支持最大1000字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 1000) })
        }
      }
    }

  }
  progressRun = () => {
    let _this = this
    if (!_this.state.complete) {
      if (_this.state.progress < 100) {
        _this.setState({ progress: _this.state.progress + 5 })
      }
      setTimeout(() => {
        _this.progressRun()
      }, 1000)
    }
  }
  //   关闭底部建议字体提示
  closethis = () => {
    let doc = document.getElementsByClassName("closethis")[0]
    doc.style.display = "none"
  }
  delet = () => {
    //  let doc = document.getElementsByClassName("content")[0]
    //  doc.innerHTML =""
    // let _this = this
    // this.sl__deal_textcontent =[]
    if (this.sl__deal_textcontent.length == 0) {
      message.warning("无内容！")
      return
    }
    this.setState({
      show2: false
    })
  }

  leftchange = (img) => {
    this.setState({
      imgstate: !img
    })
    let Acon_L = document.getElementsByClassName("Acon_L")[0]
    let Acon_R = document.getElementsByClassName("Acon_R")[0]
    if (this.state.imgstate == true) {
      Acon_L.style.width = "20%"
      Acon_R.style.width = "80%"
      this.setState({
        imgurl: imgurl1
      })
    } else {
      Acon_L.style.width = "50%"
      Acon_R.style.width = "50%"
      this.setState({
        imgurl: imgurl2
      })
    }
  }

  formatter(value) {
    console.log(this._deal_textcontent)
    let num = (128 + (256 - 128) * (value * 0.01)).toFixed()
    this.setState({
      max_num: num
    })
    //   console.log(this.state.deal_textcontent)
    //   let c = this.props.dealTextContent && this.props.dealTextContent.jd_deal_content && this.props.dealTextContent.jd_deal_content.deal_content ? this.props.dealTextContent.jd_deal_content.deal_content : []
    //    console.log(c[0].slice(0,num))

    //   this.setState({
    //     deal_textcontent:[c[0].slice(0,num)]
    //   })
    // return `${value}%`;
  }
  formatter1(value) {
    return `${value}%`;
  }

  saveVersion = (uid, content, identify, action, jb_deal_content) => {
    reqwest({
      url: '/text/saveVersion.htm',
      method: 'post',
      type: 'json',
      data: {
        uid: uid,
        content: content && JSON.stringify(content),
        identify: identify,
        action: action,
        jd_deal_content: jb_deal_content && JSON.stringify(jb_deal_content),
      },
      success: function (data) {
        if (data.code === 0) {
          message.success('已保存成功')
        } else if (data.code === 1) {
          message.error(data.errmsg ? data.errmsg : '未知错误')
        }
      }, error: (err) => {
        // message.error(err.status + ':' + err.statusText)
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
  }

  send = () => {
    // 获取编辑框对象
    var edit = document.getElementById('edit')
    // 获取输入框对象
    var emojiInput = document.getElementById('emojiInput')
    // 编辑框设置焦点
    edit.focus()
    // 获取选定对象
    var selection = getSelection().anchorNode.childNodes
    // 判断是否有最后光标对象存在
    if (this.lastEditRange) {
      // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
      selection.removeAllRanges()
      selection.addRange(this.lastEditRange)
    }
    // 判断选定对象范围是编辑框还是文本节点
    if (selection.anchorNode.nodeName != '#text') {
      // 如果是编辑框范围。则创建表情文本节点进行插入
      var emojiText = document.createTextNode(emojiInput.value)
      if (edit.childNodes.length > 0) {
        // 如果文本框的子元素大于0，则表示有其他元素，则按照位置插入表情节点
        for (var i = 0; i < edit.childNodes.length; i++) {
          // if (i == selection.anchorOffset) {
          edit.insertBefore(emojiText, edit.childNodes[i])
          // }
        }
      } else {
        // 否则直接插入一个表情元素
        edit.appendChild(emojiText)
      }
      // 创建新的光标对象
      var range = document.createRange()
      // 光标对象的范围界定为新建的表情节点
      range.selectNodeContents(emojiText)
      // 光标位置定位在表情节点的最大长度
      range.setStart(emojiText, emojiText.length)
      // 使光标开始和光标结束重叠
      range.collapse(true)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    } else {
      // 如果是文本节点则先获取光标对象
      var range = selection.getRangeAt(0)
      // 获取光标对象的范围界定对象，一般就是textNode对象
      var textNode = range.startContainer;
      // 获取光标位置
      var rangeStartOffset = range.startOffset;
      // 文本节点在光标位置处插入新的表情内容
      textNode.insertData(rangeStartOffset, emojiInput.value)
      // 光标移动到到原来的位置加上新内容的长度
      range.setStart(textNode, rangeStartOffset + emojiInput.value.length)
      // 光标开始和光标结束重叠
      range.collapse(true)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    }
    // 无论如何都要记录最后光标对象
    this.lastEditRange = selection.getRangeAt(0)
  }

  setting = () => {
    var selection = getSelection()
    this.setState({
      lastEditRange: selection.getRangeAt(0)
    })
  }

  random = () => {
    const count = Math.floor(Math.random() * 100);
    this.setState({ count });
  };

  onChange = show => {
    this.setState({ show });
  };
  render() {
    const { fileList } = this.state
    const props = {
      multiple: false,//不允许多文件上传
      onRemove: (file) => {
        let fs = fileList.filter(item => item.uid !== file.uid)
        this.setState({
          fileList: fs
        })
      },
      // fileList: fileList,
      accept: '.txt',
      beforeUpload: (file, files) => {
        let _this = this
        _this.setState({
          fileList: [file]
        })
        return new Promise((rs, re) => {
          let reader = new FileReader();
          reader.readAsText(file)
          reader.onload = (result) => {
            let content = result.currentTarget.result
            if (content) {
              if (content.length > 1000) {
                message.warn('上传文件内容长度超过限制，目前只支持最大1000字，已自动截取。')
                content = content.substring(0, 1000)
              }
              _this.setState({ textcontent: content, task_uid: file.uid, task_identify: file.name + '_' + file.lastModified }, () => {
                // _this.jd()

              })
            }
          }
        })
      }
    };

    return (
      <Content>
        <div className='content' >
          <div className='Atop' style={{ "background": "none", display: "none" }}>
            {/* <div className='Atop_l'> */}
            {/* <span>{convert('处理功能：', this.locale)}</span> */}
            {/* <Radio.Group value={this.state.action} buttonStyle="solid" onChange={(e) => {
                this.setState({
                  action: e.target.value
                }, () => {
                  if (this.state.task_uid) {
                    this.jd()
                  } else {
                    this.setState({
                      visible1: true
                    })
                  }
                })

              }}>
                <Radio.Button value="a">{convert('断句', this.locale)}</Radio.Button>
                <Radio.Button value="b">{convert('标点', this.locale)}</Radio.Button>
              </Radio.Group> */}
            {/* <div className='Atop_l'> */}
            {/* <Button onClick={() => { document.getElementById('upload').click() }}><UploadOutlined />上传文件</Button>
            </div> */}
            {/* <div className='Atop_r'>
             

              <Dropdown overlay={dowloadMenu} placement="bottomCenter">
                <Button><ArrowRightOutlined />导出数据</Button>
              </Dropdown> */}
            {/* <Popover placement="bottom" content={
                <div className='adwn2244'>
                  <Button>txt</Button>
                  <Button>html</Button>
                  <Button>word</Button>
                  <Button>pdf</Button>
                </div>
              } trigger="click">
                <Button onClick={() => {
                  this.download()
                }}><ArrowRightOutlined />导出数据</Button>
              </Popover> */}
            {/* </div> */}
          </div>

          <div className='Acon'>
            <div className='Acon_L' style={{ "position": "relative" }}>
              {/* <div style={{
                "position": "absolute", "right": "10px",
                "top": "50%", cursor: "pointer",
                "zIndex": "99",
              }} onClick={() => { this.leftchange(this.state.imgstate) }}><img className="img" src={this.state.imgurl}></img></div> */}
              {/* <span style={{"textAlign":"center"}}>{convert(`待处理的文本`, this.locale)}</span> */}
              <div className='Atop_l' style={{ "textAlign": "left", margin: "26px 0 10px", height: "32px" }}>
                <Button
                  icon={<UploadOutlined />}
                  className="left_btn"
                  //  type="primary"
                  style={{
                    "background": "#096dd9",
                    fontFamily: "中粗",
                    "color": "white",
                    "marginBottom": "10px",
                    width: "90px", height: "32px", padding: "0",
                    borderRadius: "3px",
                    "border": "1px solid #096dd9"
                  }}
                  onClick={() => {
                    if (!localStorage.getItem("token")) {
                      message.warning("暂不支持此功能！"); return
                    };
                    document.getElementById('upload').click()
                  }}>
                  {/* <span style={{marginRight:"5px",fontFamily: "中粗"}}>
          <img src={download}/></span> */}
                  上传</Button>
                <Button className="left_btn"
                  icon={<CaretRightOutlined />}
                  style={{
                    "background": " #096dd9",
                    borderRadius: "3px", fontFamily: "中粗",
                    "color": "white", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                    "border": "1px solid #096dd9"
                  }}
                  onClick={() => {
                    this.sl__deal_textcontent = []
                    if (this.state.textcontent) {
                      this.jd(this.state.textcontent)
                    } else {
                      message.error('待处理文本不能为空')
                    }
                  }}> {convert('运行', this.locale)}</Button>
                <Button className="left_btn" style={{
                  "background": " white",
                  borderRadius: "3px", fontFamily: "中粗",
                  "color": "gray", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                  "border": "1px solid #40a9ff"
                }} icon={<DeleteOutlined />}
                  onClick={() => { this.setState({ textcontent: "" }) }}>

                  清空</Button>
                {/* <span style={{ float: 'right', color: "#7d7d7d", fontFamily: "中粗" }}>今日使用剩余<span style={{ color: "#C61414", fontWeight: 900 }}>{localStorage.getItem("token") ? this.state.rateNum : this.state.rate}</span>次</span> */}
                <span style={{ float: 'right', color: "#7d7d7d", fontFamily: "中粗", display: this.state.rateVisile ? 'block' : 'none' }} >今日使用剩余<span style={{ color: "#C61414", fontWeight: 900 }}>{localStorage.getItem("token") ? this.state.rateNum : this.state.rate}</span>次</span>

              </div>
              <div className='Acon_Lco' hidden={true}>
                <div className='right'>
                  <FileTextOutlined style={{ fontSize: "90px", color: "#DDDDDD" }} />
                  <div className='ziwen' >
                    {convert("暂无文档，快来上传吧，支持格式txt", this.locale)}
                  </div>
                  <Upload {...props} id="upload">
                    <Button icon={<UploadOutlined />}>上传文件</Button>
                  </Upload>
                </div>

              </div>
              <div className='right right_A' >

                <Input.TextArea
                  onChange={e => {
                    this.setState({
                      textcontent: e.target.value
                    })
                  }} placeholder={convert('粘贴待处理文本或点击左上角上传文件' + "\n" + '注：夹注文本仅支持英文半角中括号[]')}
                  style={{ minHeight: this.state.deskHeight - 300, fontSize: 18, background: "none", borderRadius: "0px", lineHeight: '1.8' }}
                  value={this.state.textcontent}
                  onMouseMove={() => { this.ban() }}
                  onKeyUp={() => { this.ban() }}
                  // onPressEnter={e => {
                  //   if (this.state.textcontent) {
                  //     this.jd()
                  //   } else {
                  //     this.setState({
                  //       visible1: true
                  //     })
                  //   }
                  // }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>提示：仅支持UTF-8格式文本，游客：建议≤500字/次，24次/24小时；注册用户：建议≤1000字/次，24次/24小时；为了更好的体验，建议字体：<a style={{ color: "#096dd9" }} href="http://fonts.jp/hanazono/" target="blank">花园明朝体</a>
                  {/* <span className="closethis" style={{
                    fontSize: "12px",
                    textAlign: "right", lineHeight: "32px"
                  }}>
                    <span className="_closethis" style={{ float: "right" }} onClick={() => { this.closethis() }}>关闭</span>
                  </span> */}
                  <span style={{ float: "right", marginRight: "20px" }}>当前字数：{this.state.textcontent.length}/{!localStorage.getItem("token") ? 500 : 1000}</span>
                </p>

              </div>
            </div>

          </div>
        </div>
        <Modal
          className='uioio11'
          title="开始新任务"
          visible={this.state.visible1}
          onOk={() => {
            this.setState({
              visible1: false
            }, () => {
              this.jd(this.state.textcontent)
            })
          }}
          onCancel={() => {
            this.setState({
              visible1: false
            })
          }}
          okText="确定"
          cancelText="取消"
          destroyOnClose={true}
        >
          <Input placeholder={convert('请输入任务名称')} onChange={e => {
            this.setState({
              task_uid: e.target.value,
              task_identify: e.target.value
            })
          }}></Input>
        </Modal>
        <Modal
          className='uioio12'
          width={400}
          visible={this.state.visible2}
          onOk={this.handleOkM2}
          onCancel={this.handleCancelM2}
          footer={null}
          closable={false}
        >
          <img src={loading}></img>
          <span style={{ color: "white" }}>正在努力加载，请耐心等待！</span>
          {/* <Progress strokeColor="#3AA4CE" type="circle" percent={this.state.progress} 
          format={percent => `${percent / 5} 秒`} /> */}
          {/* <span>句读处理中，预计20s请耐心等待～</span> */}
          {/* <Button>暂停</Button> */}
        </Modal>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    dealTextContent: state.dealTextContent,
    todoHandleData: state.todoHandleData,
    todoHandleAutomaticDuFlag: state.todoHandleAutomaticDuFlag,
    duData: state.duData
  }
}
export default withRouter(connect(mapStateToProps)(AutomaticDu));
