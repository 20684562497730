import React, { Component } from 'react';
import { Layout, Form, Input, Button, Checkbox, Select, Radio, TreeSelect, notification, message, Upload, Empty, ConfigProvider, Result, Divider } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request'
import * as cont from '../config/constant'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal';
import reqwest from "reqwest"
const { Content } = Layout;
const { OptGroup, Option } = Select
const openNotificationWithIcon = (title, msg) => {
  notification['error']({
    message: title,
    description:
      msg,
  });
};
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只接收JPG/PNG文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图片不要大于2MB!');
  }
  return isJpgOrPng && isLt2M;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 7, span: 10 },
};
class Register extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      checkstate: false,
      departmentList: [],
      positionList: [],
      roleList: [],
      organzationList: [],
      imageUrl: '',
      uploadLoading: false,
      liked: true,
      count: 60,
      loading: false,
      hideVerificationCode: true,
      organzation_loading: true,
      department_loading: true,
      position_loading: true,
      regVisible: false,
      result_code: 0,
      uName: 'aaa',
      isChina: true,
    };
    this.locale = dayjs.locale()
  }

  componentDidMount() {
    // request(cont.ipChina, {}, this.props).then(data => {
    //   if (data.code === 0) {
    //     if (data.hint === "CH") {
    //       this.setState({
    //         isChina: true
    //       })
    //     } else {
    //       this.setState({
    //         isChina: false
    //       })
    //     }
    //   }
    // })
    // this.getOrganzationList()
    // this.getDepartmentList()
    // this.getPositionList()
    // this.getRoleList()
  }
  resetForm = () => {
    this.formRef.current.resetFields()
  }
  getOrganzationList = () => {
    let _this = this
    request(cont.getOrganzationList, {
      body: {
        status: 0
      }
    }, _this.props).then(data => {
      if (data.code == 0) {
        if (!!data.list) {
          _this.setState({
            organzationList: data.list,
            organzation_loading: false,
          })
        } else {
          _this.setState({
            organzation_loading: false
          })
        }
      } else if (data.code == 1) {
        openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
      }
    })
  }
  getDepartmentList = (organzation_id) => {
    let _this = this
    request(cont.getDepartmentList, {
      body: {
        organzation_id: organzation_id,
        valid: 1,
        status: 0
      }
    }, _this.props).then(data => {
      if (data.code == 0) {
        if (!!data.list) {
          let departmentList = _this.getChildrenMenus(0, data.list);
          _this.setState({
            departmentList: departmentList,
            department_loading: false
          })
        } else {
          _this.setState({
            department_loading: false
          })
        }
      } else if (data.code == 1) {
        openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
      }
    })
  }
  getPositionList = (organzation_id, department_id) => {
    let _this = this
    request(cont.getPositionList, {
      body: {
        organzation_id: organzation_id,
        department_id: department_id,
        valid: 1,
        status: 0
      }
    }, _this.props).then(data => {
      if (data.code == 0) {
        if (!!data.list) {
          _this.setState({
            positionList: data.list,
            position_loading: false
          })
        } else {
          _this.setState({
            position_loading: false
          })
        }
      } else if (data.code == 1) {
        openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
      }
    })
  }
  getRoleList = () => {
    let _this = this
    request(cont.getRoleList, {
      body: {
        valid: 1,
        status: 0,
        type: '机构一般用户'
      }
    }, _this.props).then(data => {
      if (data.code == 0) {
        if (!!data.list) {
          _this.setState({
            roleList: data.list
          })
        }
      } else if (data.code == 1) {
        openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
      }
    })
  }
  getChildrenMenus(id, data) {
    let _this = this;
    let ls = [];
    let arr = data.filter(t => t.pid === id);
    arr.forEach(function (item, index) {
      let children = [];
      if (item.rgt - item.lft > 1) { //表示有子目录
        children = _this.getChildrenMenus(item.id, data);
      }
      let ob = {
        title: item.name,
        value: item.id
      };
      if (children.length > 0) {
        ob.children = children
      }
      ls.push(ob);
    })
    return ls;
  }
  handleUploadChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ uploadLoading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          uploadLoading: false,
        }),
      );
    }
  };
  handleSubmit = (values) => {
    if (!this.state.checkstate) {
      message.warning("未同意用户注册及隐私协议无法注册！")
      return
    }



    let _this = this
    values.valid = 1
    // message.loading({ content: convert('正在注册...', this.locale), key: 'register' })
    values.photo = !!values.photo ? values.photo : 0
    values.organzation_id = !!values.organzation_id ? values.organzation_id : 0
    values.department_id = !!values.department_id ? values.department_id : 0
    values.position_id = !!values.position_id ? values.position_id : 0
    values.role_id = !!values.role_id ? values.role_id : 0
    console.log(values.emailCode);
    let user = {
      "email": values.phone,
      // "gender": values.sex,
      "username": values.username,
      "password": values.password,
      "user_type": values.usertype,
      // "avatar": "string",
      "name": values.name,
      // "org_id": 0,
      // "phone": 13836524125,
      "code": values.code,
      "email_code":values.emailCode,
    }

    reqwest({
      url: '/wyd/auth/register',
      method: 'post',
      type: 'json',
      contentType: "application/json",
      data: JSON.stringify(user),
      success: function (data) {
        if (data.code === 200) {
          message.loading({ content: convert('正在注册...', this.locale), key: 'register' })
          _this.setState({
            result_code: 0,
            // regVisible: true
          })
          message.success({ result_code: data.code, content: '注册成功', key: 'register', duration: 2 })
          _this.props.history.push("/login")
          // message.success({ result_code: data.code, content: data.hint, key: 'register', duration: 2 })
          // this.props.history.push(`/register_result/${data.code}`)
        } else if (data.code === 1) {
          this.setState({
            result_code: 1
          })
          message.error({ result_code: data.code, content: '注册失败', key: 'register', duration: 2 })
          // message.error({ result_code: data.code,content: data.errmsg, key: 'register', duration: 2 })
        }
      },
      error: function (err) {
        _this.handleVerify();
        let d = JSON.parse(err.response)
        message.error(d.message)

      }
    })
    document.getElementById("img").src = "/wyd/captcha.jpg?" + Math.random();
  }


  handleCancel = () => {
    this.props.history.push(`/login/${this.state.uName}`)
  }
  countDown() {
    const { count } = this.state
    if (count === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
      this.setState({
        count: 60,
        liked: true,
      })
    } else {
      this.setState({
        count: count - 1,
        liked: false,
      })
      setTimeout(() => this.countDown(), 1000)//每一秒调用一次
    }
  }
  getPhoneCode = (phone, callback) => {
    let _this = this
    if (!phone) {
      message.error(convert("邮箱不能为空", _this.locale))
      callback();
      return
    }
    let code = this.formRef.current.getFieldValue("code")
    if (!code) {
      message.error(convert("图形验证码不能为空", _this.locale))
      callback();
      return
    }
    
    // request( cont.checkEmailCode, {
    //   body: { email: phone,  code:code }
    // }, _this.props).then(data => {
    //   callback();
    //   if (data.code == 1) {
    //     _this.countDown()
    //   } else {
    //     // openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
    //     // if(data.message){
    //     //   message.error(data.message)
    //     // }
    //   }
    // })
    reqwest({
      url: '/wyd/auth/mailto',
      method: 'post',
      type: 'json',
      // contentType: "application/json",
      data: {email: phone,  code:code},
      success: function (data) {
        if (data.code === 1) {
          _this.countDown()
        } else if (data.code === 500) {
          message.error(data.message)
        }
      },
      error: function (err) {
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
    this.handleVerify()
  }

  handleVerify = () => {
    document.getElementById("img").src = "/wyd/captcha.jpg?" + Math.random();
  }
  render() {
    const { isChina, uName, result_code, regVisible, departmentList, positionList, roleList, organzationList, imageUrl, liked, count, hideVerificationCode, loading, organzation_loading, department_loading, position_loading } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
        xl: { span: 7 },
        xxl: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
        lg: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 10 }
      },
    };
    const _loop = (data) => {
      let options = []
      let lobj = {}
      data.forEach(item => {
        if (!!!lobj[`${item.type}`]) {
          lobj[`${item.type}`] = []
        }
        lobj[`${item.type}`].push(item)
      })
      for (var key in lobj) {
        let ops = lobj[key]
        let oops = ops.map(item => <Option value={`${item.id}`} key={`${item.id}`}>{item.name}</Option>)
        let op = <OptGroup label={key} key={key}>{oops}</OptGroup>
        options.push(op)
      }
      return options
    }
    const normFile = e => {
      console.log('Upload event:', e);
      if (e.file.status === 'uploading') {
        return e && e.fileList;
      }
      if (e.file.status === 'done') {
        return e.file.response.id
      }
    };
    const uploadButton = (
      <div>
        {this.state.uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const customizeRenderEmpty = () => (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {convert('如找不到您要的机构，请与我们', this.locale)}<a href="#" onClick={e => {
              e.preventDefault();
              notification.warning({
                key: 'organzationNotify',
                placement: 'bottomRight',
                duration: null,
                description: <div style={{ textAlign: 'left', marginTop: 20 }}><p style={{ textAlign: 'start' }} >客服電話：13651321821</p><p style={{ textAlign: 'start' }} >客服郵箱：zhuhq@ecbdb.com</p><p style={{ textAlign: 'start' }}>客服QQ：1727908018</p></div>,
                message: convert('线上客服', this.locale),
              })
            }}>{convert('联系', this.locale)}</a>
          </span>
        }
      >
      </Empty>
    )
    return (<>
      <Content className='bg-e7ecef'>
        <div className='content bg-fff login-box '>
          <Form className="login-form" onFinish={this.handleSubmit} ref={this.formRef} {...layout} initialValues={{ sex: 0 }}>

            {/* <Form.Item label={convert('邮箱', this.locale)} {...formItemLayout} name="email" rules={[{ required: false, message: convert('请填写邮箱', this.locale) }, { type: 'email', message: convert('邮箱格式不正确', this.locale) }]}>
              <Input placeholder={convert('请填写邮箱', this.locale)} />
            </Form.Item> */}
            {isChina ? <Form.Item label={convert('邮箱', this.locale)} {...formItemLayout} name="phone"
              rules={[{ required: true, message: convert('请输入邮箱', this.locale) },
              {
                message: convert('请输入有效的邮箱', this.locale), type: "email"
                // pattern: /^(1(3|4|5|6|7|8|9)[0-9]{9})?(([a-zA-Z0-9_-])+@(.*?(\.edu|edu\.).*))?$/ 
              },
              {
                validator: (rule, value) => {
                  if (value) {
                    let _this = this
                    // return new Promise((resolve, reject) => {
                    // request(cont.registerCheckPhone, {
                    //   body: {
                    //     phone: value
                    //   }
                    // }, _this.props).then(data => {
                    //   if (data.code === 0) {
                    //     resolve(data.hint && data.hint)
                    //   } else if (data.code === 1) {
                    //     reject(data.errmsg && data.errmsg)
                    //   }
                    // })
                    // })
                  }
                  return Promise.resolve()
                }
              }
              ]}>
              <Input placeholder={convert('请输入邮箱', this.locale)} onFocus={() => {
                this.setState({
                  hideVerificationCode: false
                })
              }} />
            </Form.Item> : <Form.Item label={convert('邮箱', this.locale)}
              {...formItemLayout} name="phone"
              rules={[{ required: true, message: convert('请输入邮箱', this.locale) },
              { message: convert('请输入有效的邮箱', this.locale), type: 'email' },
              {
                validator: (rule, value) => {
                  let isEmail = value.indexOf('@')
                  if (value && isEmail !== -1) {
                    let _this = this
                    return new Promise((resolve, reject) => {
                      request(cont.registerCheckPhone, {
                        body: {
                          phone: value
                        }
                      }, _this.props).then(data => {
                        if (data.code == 0) {
                          resolve(data.hint && data.hint)
                        } else if (data.code == 1) {
                          reject(data.errmsg && data.errmsg)
                        }
                      })
                    })
                  }
                  return Promise.resolve()
                }
              }
              ]}>
                <Input placeholder={convert('请输入邮箱', this.locale)} onFocus={() => {
                  this.setState({
                    hideVerificationCode: false
                  })
                }} />
              </Form.Item>}
            {/* (([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.edu.cn))? (([a-zA-Z0-9_-])+@(qq.com))? */}

            <Form.Item
              className="verificationCode"
              label={convert('图形验证码', this.locale)}
              {...formItemLayout}
              name="code"
              rules={[{ required: true, message: convert('请输入图形验证码', this.locale) },]}>
              <Input placeholder={convert('请输入图形验证码', this.locale)} style={{ width: 'auto' }}
                addonAfter={
                  <Button style={{ marginLeft: 20, padding: 0 }} >
                    <img style={{ width: "100%", height: "100%", marginRight: 10 }} id='img' src='/wyd/captcha.jpg' onClick={this.handleVerify} alt='验证码' />
                    <span style={{ fontSize: 12, color: "#787876" }} onClick={this.handleVerify} >点击图片刷新验证码</span>
                  </Button>
                }
              />
            </Form.Item>

            <Form.Item
              className="verificationCode"
              label={convert('邮箱验证码', this.locale)}
              {...formItemLayout}
              name="emailCode"
              validateTrigger={['onChange']}
              dependencies={['phone']}
              rules={[{ required: true, message: convert('请输入邮箱验证码', this.locale), },
            {
              // validator: (rule, value) => {
              //   let phonecode = this.formRef.current.getFieldValue("phone")
              //   if (value && phonecode) {
              //     let _this = this
              //     return new Promise((resolve, reject) => {
              //       request(cont.checkPhoneCode, {
              //         body: {
              //           phone: phonecode,
              //           code: value
              //         }
              //       }, _this.props).then(data => {
              //         if (data.code === 0) {
              //           resolve(data.hint && data.hint)
              //         } else if (data.code === 1) {
              //           reject(data.errmsg && data.errmsg)
              //         }
              //       })
              //     })
              //   }
              //   return Promise.resolve()
              // },
            }
            ]}>
            <Input
                placeholder={convert('请输入邮箱验证码', this.locale)}
                addonAfter={
                  <Button type="primary" disabled={liked ? false : true} onClick={() => {
                    this.setState({
                      loading: true
                    }, () => {
                      this.getPhoneCode(this.formRef.current.getFieldValue('phone'), () => {
                        this.setState({
                          loading: false
                        })
                      })
                    })

                  }} >
                    {liked ? '获取验证码' : `（${count}）秒后重发`}
                  </Button>}
              />
            </Form.Item>


            {/* <Form.Item label={convert('角色', this.locale)} {...formItemLayout} name="role_id" rules={[{ required: true, message: convert('请选择角色', this.locale) }]}>
              <Select placeholder={convert('请选择角色', this.locale)}>
                {_loop(roleList)}
              </Select>
            </Form.Item> */}
            <Form.Item label={convert('账号', this.locale)} {...formItemLayout} name="username"
              validateTrigger={['onChange']}
              rules={[{ required: true, message: convert('请填写账号', this.locale) }, {
                // validator: (rule, value) => {
                //   this.setState({uName: value})
                //   let _this = this
                //   return new Promise((resolve, reject) => {
                //     request(cont.checkUserName, {
                //       body: {
                //         id: 0,
                //         username: value
                //       }
                //     }, _this.props).then(data => {
                //       if (data.code === 0) {
                //         resolve()
                //       } else {
                //         reject(data.errmsg ? data.errmsg : convert('账号已存在', this.locale));
                //       }
                //     })
                //   })
                // },
              },
              ]}>
              <Input placeholder={convert("请填写账号", this.locale)} />
            </Form.Item>
            <Form.Item label={convert('密码', this.locale)} {...formItemLayout} name="password" rules={[{ required: true, message: convert('请填写密码', this.locale) }]}>
              <Input.Password placeholder={convert("请输入密码", this.locale)} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="confirm"
              label={convert("确认密码", this.locale)}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: convert('请再次输入密码!', this.locale),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(convert('两次密码不一致,请重输!', this.locale));
                  },
                }),
              ]}
            >
              <Input type='password' placeholder={convert('请再次输入密码', this.locale)} />
            </Form.Item>


            <div className="personInfo">
              <div className="infoLineL"></div>
              <div style={{ textAlign: "center" }}>个人信息</div>
              <div className="infoLineR"></div>
            </div>


            <Form.Item label={convert('昵称', this.locale)} {...formItemLayout} name="name" rules={[{ required: true, message: convert('请填写名字', this.locale) }]}>
              <Input placeholder={convert('填写昵称', this.locale)} />
            </Form.Item>
            <Form.Item label={convert('职业', this.locale)} {...formItemLayout}
              name="usertype" rules={[{ required: false, message: convert('请选择职业', this.locale) }]} shouldUpdate={true}>
              <Select
                // loading={position_loading}
                showSearch
                // filterOption={(input, option) => option.children &&
                //   option.children.toLowerCase().indexOf(convert(input.toLowerCase(), this.locale)) >= 0
                // }
                placeholder={convert('请选择职业', this.locale)}>
                <option value="1">{convert('专业古籍整理人员', this.locale)}</option>
                <option value="2">{convert('古籍爱好者', this.locale)}</option>
                <option value="3">{convert('游客', this.locale)}</option>
                {/* <option value="4">{convert('职员', this.locale)}</option> */}
                <option value="9">{convert('其他', this.locale)}</option>
              </Select>
            </Form.Item>
            {/* <Form.Item label={convert('性别', this.locale)} {...formItemLayout} name="sex" rules={[{ required: false, message: convert('请选择性别', this.locale) }]}>
              <Radio.Group>
                <Radio value={0}>{convert('保密', this.locale)}</Radio>
                <Radio value={1}>{convert('男', this.locale)}</Radio>
                <Radio value={2}>{convert('女', this.locale)}</Radio>
              </Radio.Group>
            </Form.Item> */}
            {/* <Form.Item label={convert('用户类型', this.locale)} {...formItemLayout} name="usertype" rules={[{ required: false, message: convert('请选择用户类型', this.locale) }]}>
              <Radio.Group>
                <Radio value={1}>{convert('教师', this.locale)}</Radio>
                <Radio value={2}>{convert('学生', this.locale)}</Radio>
                <Radio value={3}>{convert('家长', this.locale)}</Radio>
                <Radio value={4}>{convert('职员', this.locale)}</Radio>
                <Radio value={9}>{convert('其他', this.locale)}</Radio>
              </Radio.Group>
            </Form.Item> */}



            {/* <Form.Item
              {...formItemLayout}
              name="photo"
              label={convert("头像", this.locale)}
              // valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={cont.uploadPicture}
                beforeUpload={beforeUpload}
                onChange={this.handleUploadChange}
              >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item> */}

            {/* <Form.Item
              className="verificationCode"
              label={convert('图形验证码', this.locale)}
              {...formItemLayout}
              name="code"
              rules={[{ required: true, message: convert('请输入图形验证码', this.locale) },]}>
              <Input placeholder={convert('请输入图形验证码', this.locale)} style={{ width: 'auto' }}
                addonAfter={
                  <Button style={{ marginLeft: 20, padding: 0 }} >
                    <img style={{ width: "100%", height: "100%", marginRight: 10 }} id='img' src='/wyd/captcha.jpg' onClick={this.handleVerify} alt='验证码' />
                    <span style={{ fontSize: 12, color: "#787876" }}>点击图片刷新验证码</span>
                  </Button>
                }
              />
            </Form.Item> */}

            <p style={{ marginLeft: "50%", transform: "translatex(-50%)" }}>
              <Checkbox onChange={e => {
                console.log(e)
                this.setState({ checkstate: e.target.checked })
              }}></Checkbox>
              <span>我已接受<span style={{
                color: "rgba(24, 144, 255,1)",
                cursor: "pointer",
                color: "#096dd9"
              }} onClick={() => { this.props.history.push('/registerment') }}>《用户注册及隐私协议》</span></span>
            </p>
            {/* <Form.Item label={convert('岗位', this.locale)} {...formItemLayout} name="position_id" rules={[{ required: false, message: convert('请选择岗位', this.locale) }]} shouldUpdate={true}>
              <Select
                loading={position_loading}
                showSearch
                filterOption={(input, option) => option.children &&
                  option.children.toLowerCase().indexOf(convert(input.toLowerCase(), this.locale)) >= 0
                }
                placeholder={convert('请选择岗位', this.locale)}>
                {_loop(positionList)}
              </Select>
            </Form.Item> */}

            {/* <Form.Item {...tailLayout} name="agreement" valuePropName="checked">
                  <Checkbox>
                    我已阅读并同意  <a href="">《引得用户协议》</a>
                  </Checkbox>
                </Form.Item> */}

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <div style={{ textAlign: 'center' }}>
                <Button className='pri-btn mb-20' type="primary" htmlType="submit" style={{ width: '160px', background: "#096dd9", marginLeft: 0 }}>{convert("注册", this.locale)}</Button>
                <Button className='mb-20' style={{ width: '160px', marginLeft: '5%' }} onClick={this.resetForm}>{convert("重置", this.locale)}</Button>
              </div>

              <div style={{ textAlign: 'center' }}>
                <span>{convert("已有账号", this.locale)}？</span>
                <Link to='/login' style={{ color: "#096dd9" }}>&nbsp;{convert("去登录 ", this.locale)}&gt;</Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Content >

      <Modal title="提示" visible={regVisible} onCancel={this.handleCancel} footer={null} centered>
        <Result
          status={result_code == '0' ? 'success' : 'warning'}
          title={convert(result_code == '0' ? "注册成功!" : "注册失败!", this.locale)}
          extra={[
            <div style={{ marginBottom: 15 }}>您登陆的账号是：<span style={{ color: '#1890ff' }}>{uName}</span></div>,
            <Link to={`/login/${this.state.uName}`}>
              <span>{convert('去登录', this.locale)}&gt;</span>
            </Link>,
          ]}
        />
      </Modal>
    </>
    );
  }
}
export default withRouter(Register);