import React, { useState, createRef, useEffect, use, useRef } from 'react'
import { Layout, Row, Col, Card, Input, Table, Select, Upload, message, Button, DatePicker, Progress, Modal, ConfigProvider, Empty, List, Avatar, Timeline, Popconfirm, Tabs, Tooltip, notification, Spin, Radio, Form } from 'antd'
import { withRouter } from 'react-router'

import { VerticalAlignTopOutlined, QuestionCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, ExclamationCircleOutlined, ReloadOutlined, DeliveredProcedureOutlined, CheckCircleOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import convert from '../../../utils/locale_zh_convert'
import template1 from '../../../images/1.jpg'
import './tasklist.css'
import { connect } from 'react-redux';
import { scrollScreen } from 'rc-scroll-anim';
import TableRowModal from '../components/tableRowModal'
import PictureViewer from '../components/imgViewer'
import request from './../../../utils/request'
import * as cont from './../../../config/constant'
import * as imageUtil from './../../../utils/imageUtil'
import reqwest from 'reqwest'
import { useRequest } from 'ahooks';

import * as lacs from '../../../actions/textDataAction';

const { Content } = Layout
const { Option } = Select;


const { Dragger } = Upload;

const { RangePicker } = DatePicker;

const { Search } = Input;

function TaskList(props) {
  const locale = dayjs.locale()

  const [imgs, setImgs] = useState([
    {
      index: 0,
      key: 1,
      url: template1
    },
    {
      index: 1,
      key: 2,
      url: template1
    },
    {
      index: 2,
      key: 3,
      url: template1
    },
    {
      index: 3,
      key: 4,
      url: template1
    },
  ])

  const [fileList, setFileList] = useState([])
  const [imgView, setImgView] = useState({ show: false, list: [] })
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [spinProps, setSpinProps] = useState({ spinning: false })
  const [exportType, setExportType] = useState(1)
  const [direction, setDirection] = useState('V')
  const [completeCount, setCompleteCount] = useState()
  const [allCount, setAllCount] = useState()
  const [excutingCount, setExcutingCount] = useState()
  const [type, setType] = useState('')
  const [dataSource, setDataSource] = useState([])
  const [dataSource_Complete, setDataSource_Complete] = useState([])
  const [dataSource_Excuting, setDataSource_Excuting] = useState([])
  const [taskLogs, setTaskLogs] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [taskDescription, setTaskDescription] = useState()
  const [toDoList, setToDoList] = useState([])//待执行清单
  const dataSourceRef = useRef([])
  const dataSource_CompleteRef = useRef([])
  const dataSource_ExcutingRef = useRef([])
  const selectRowKeyRef = useRef()
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const showLogRef = useRef(false)

  const columns = [
    {
      key: 'index',
      dataIndex: 'index',
      title: 'index',
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      key: 'pics',
      dataIndex: 'pics',
      title: 'pics',
      render: (text, record, index) => {
        let pics = []
        if (text) {
          pics = text.split(',')
        }
        return <Col className="imgs"
          onClick={() => {
            show(pics.map(item => ({ url: `${cont.getPicture}?id=${item}` })))
            // props.history.push(`/ocr/text/${record.id}#3`)
          }}
        >
          <Avatar shape="square" key={index} style={{
            width: '100%',
            height: '100%'
          }}
            src={pics.length > 0 && `${cont.getPicture}?id=${pics[0]}`} />
        </Col>
      }

    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
      width: '20%',
      render: (text, record, index) => {
        return <Col className="desc" onClick={() => {
          // show(pics.map(item => ({ url: `${cont.getPicture}?id=${item}` }))) 
          props.history.push(`/ocr/text/${record.id}#3`)
        }}>
          <div>{text}</div>
          <span>{(record.size / 1024).toFixed(2)} KB</span>
        </Col>
      }
    },
    {
      key: 'time',
      dataIndex: 'time',
      title: 'time',
      render: (text, record, index) => {
        return < Col className="time" >
          <div>{convert('识别时间', locale)}</div>
          <span>{record.startDate && dayjs(record.startDate).format('YYYY-MM-DD HH:mm')}-{record.endDate && dayjs(record.endDate).format('YYYY-MM-DD HH:mm')}</span>
        </Col >
      }
    },
    {
      key: 'progress',
      dataIndex: 'progress',
      title: 'progress',
      render: (text, record, index) => {
        let status = 'normal';
        let progress_icon = <div className="progress_icon"><PlayCircleOutlined style={{ color: '#3458cede' }} onClick={() => {
          startUpload(record.id)
        }} />
          <Tooltip title={convert("进度", locale)}><DeliveredProcedureOutlined style={{ color: '#3458cede' }} onClick={() => {
            viewLogs(record)
          }} /></Tooltip>
        </div>;
        switch (record.status) {
          case 'Fail':
            status = 'exception'
            progress_icon = <div className="progress_icon">
              <Tooltip title={record.failMessage ? record.failMessage : '未知错误'}>
                <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
              </Tooltip>
              <Tooltip title={convert('重新识别', locale)}>
                <Popconfirm
                  title={convert("是否跳过错误文件?", locale)}
                  onConfirm={() => { reUpload(record.id, true) }}
                  onCancel={() => { reUpload(record.id, false) }}
                  okText={convert("跳过", locale)}
                  cancelText={convert("不跳过", locale)}
                >
                  <ReloadOutlined style={{ color: '#3458cede' }} />
                </Popconfirm>
              </Tooltip>
              <Tooltip title={convert("进度", locale)}><DeliveredProcedureOutlined style={{ color: '#3458cede' }} onClick={() => {
                viewLogs(record)
              }} /></Tooltip>
            </div >
            break
          case 'Excuting':
            status = 'active'
            progress_icon = <div className="progress_icon"><PauseCircleOutlined style={{ color: '#3458cede' }} onClick={() => {
              pauseUpload(record.id)
            }} />
              <Tooltip title={convert("进度", locale)}><DeliveredProcedureOutlined style={{ color: '#3458cede' }} onClick={() => {
                viewLogs(record)
              }} /></Tooltip>
            </div>
            break

          case 'Complete':
            status = 'success'
            progress_icon = <div className="progress_icon">
              <Tooltip title={convert("识别成功", locale)}><CheckCircleOutlined style={{ color: '#47b511' }} /></Tooltip>
              <Tooltip title={convert("导出", locale)}><TableRowModal
                locale={locale}
                onChange={(e) => {
                  setExportType(e.target.value)
                }}
                value={exportType}
                data={record}
                viewpicture={show}
                children={<DownloadOutlined style={{ color: '#3458cede' }} />}
              /></Tooltip>
              <Tooltip title={convert("进度", locale)}><DeliveredProcedureOutlined style={{ color: '#3458cede' }} onClick={() => {
                viewLogs(record)
              }} /></Tooltip>
            </div >
            break
        }


        return < Col className="progress" >
          <Progress percent={(record.progressPer * 100).toFixed(2)} showInfo={true} status={status} format={(percent, successPercent) => {
            if (record.status === 'Excuting') {
              if (percent > 0) {
                return percent + '%'
              } else {
                return convert('处理中...', locale)
              }

            } else if (record.status === 'Fail') {
              return convert('出错', locale)
            } else if (record.status === 'Success') {
              return convert('成功', locale)
            } else if (record.status === 'Complete') {
              return convert('完成', locale)
            } else if (record.status === 'Pause') {
              return convert('暂停', locale)
            }
          }} />
          {progress_icon}


        </Col >
      }
    },
    {
      key: 'choose',
      dataIndex: 'choose',
      title: 'choose',
      width: '15%',
      render: (text, record, index) => {
        if (record.status != 'Complete') {
          return <Col className="choose">
            <Popconfirm
              title={convert("确定删除该任务?", locale)}
              onConfirm={() => { confirm(record.id) }}
              okText={convert("确定", locale)}
              cancelText={convert("取消", locale)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >

              <h3 className="delate-item">{convert('删除', locale)}</h3>
            </Popconfirm>
          </Col>
        }
        return <Col className="choose">
          <span onClick={() => {
            props.history.push(`/ocr/text/${record.id}#3`)
          }}>{convert('查看', locale)}</span>
          <Popconfirm
            title={convert("确定删除该任务?", locale)}
            onConfirm={() => { confirm(record.id) }}
            okText={convert("确定", locale)}
            cancelText={convert("取消", locale)}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <span>{convert('删除', locale)}</span>
          </Popconfirm>
        </Col>
      }
    },
  ]
  const columnsText = [
    {
      key: 'index',
      dataIndex: 'index',
      title: 'index',
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      key: 'img',
      dataIndex: 'img',
      title: 'img',
      render: (text, record, index) => {
        return <Col className="imgs" onClick={() => { show([{ url: `${text}` }]) }}>
          <Avatar shape="square" key={index} style={{ width: '100%', height: '100%' }} src={`${text}`} />
        </Col>
      }

    },
    // {
    //   key: 'size',
    //   dataIndex: 'size',
    //   title: 'size',
    //   width: '20%',
    //   render: (text, record, index) => {
    //     return <Col className="desc">
    //       <div>{text}</div>
    //     </Col>
    //   }
    // },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
      width: '20%',
      render: (text, record, index) => {
        return <Col className="desc" onClick={() => {
          // show(pics.map(item => ({ url: `${cont.getPicture}?id=${item}` }))) 
          props.history.push(`/ocr/text/${record.uid}#3`)
        }}>
          <div>{text}</div>
          <span>{record.size} KB</span>
        </Col>
      }
    },
    {
      key: 'time',
      dataIndex: 'time',
      title: 'time',
      render: (text, record, index) => {
        return < Col className="time" >
          <div>{convert('识别时间', locale)}</div>
          <span>{record.startDate}-{record.endDate}</span>
        </Col >
      }
    },
    {
      key: 'progress',
      dataIndex: 'progress',
      title: 'progress',
      render: (text, record, index) => {
        return < Col className="progress" >
          <Progress percent={text} showInfo={true} status={'normal'} format={(percent, successPercent) => { return convert('完成', locale); }} />
        </Col >
      }
    },
    {
      key: 'choose',
      dataIndex: 'choose',
      title: 'choose',
      width: '15%',
      render: (text, record, index) => {
        return <Col className="choose">
          <span onClick={() => { props.history.push(`/ocr/text/${record.uid}#3`) }}>{convert('查看', locale)}</span>
        </Col>
      }
    },
  ]

  const reUpload = (key, skip) => {
    message.loading({ content: convert('重新启动中...', locale), key: 'reUpload', duration: 0 })
    request(cont.proceedTask, {
      body: {
        task_id: key,
        direction: direction,
        skip: skip
      }
    }, props).then(data => {
      if (data.code == 0) {
        getTask(data.item)
        message.success({ content: convert('启动成功', locale), key: 'reUpload' });
      } else if (data.code == 1) {
        message.success({ content: convert(data.errmsg ? data.errmsg : '未知错误!', locale), key: 'startUpload' });
      }
    })
  }
  const startUpload = (key) => {
    message.loading({ content: convert('启动中...', locale), key: 'startUpload', duration: 0 })
    request(cont.proceedTask, {
      body: {
        task_id: key,
        direction: direction
      }
    }, props).then(data => {
      if (data.code == 0) {
        getTask(data.item)
        message.success({ content: convert('启动成功', locale), key: 'startUpload' });
      } else if (data.code == 1) {
        message.success({ content: convert(data.errmsg ? data.errmsg : '未知错误!', locale), key: 'startUpload' });
      }
    })
  }
  const pauseUpload = (key) => {
    message.loading({ content: convert('正在暂停任务...', locale), key: 'pauseUpload', duration: 0 })
    request(cont.pauseTask, {
      body: {
        process_id: key
      }
    }, props).then(data => {
      if (data.code == 0) {
        const f = () => {
          getTask(data.item, (task) => {
            if (task.status === 'Excuting') {
              f()
            } else if (task.status === 'Pause') {
              message.success({ content: convert('暂停成功', locale), key: 'pauseUpload' })
            } else if (task.status === 'Complete') {
              message.error({ content: convert('任务已完成，暂停失败', locale), key: 'pauseUpload' })
            }
          })
        }
        f()
      } else if (data.code == 1) {
        message.error({ content: data.errmsg ? data.errmsg : convert('未知错误!', locale), key: 'pauseUpload' })
      }
    })
  }
  const viewLogs = (record) => {
    selectRowKeyRef.current = record.id
    setVisible1(true)
    showLogRef.current = true
    getTaskLog(record.id, true)
  }
  const confirm = (key) => {
    request(cont.deleteTask, {
      body: {
        id: key
      }
    }, props).then(data => {
      if (data.code == 0) {
        getTaskList()
        message.success(convert('删除成功', locale));
      } else if (data.code == 1) {
        message.error(data.errmsg ? data.errmsg : '未知错误!')
      }
    })

  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setDirection(value)
  }
  const show = (fileList, initialViewIndex = 0) => {
    setImgView({
      show: !imgView.show,
      list: [...fileList],
      options: {
        initialViewIndex: initialViewIndex,
      }
    })
  }
  const onChange = (value, dateString) => {
    setStartTime(dateString[0])
    setEndTime(dateString[1])
  }

  useEffect(() => {
    scrollScreen.unMount()
    // getTaskList()
    getReducesFiles();
    return function () {
      console.log('leave?')
    }
  }, [])

  useEffect(() => {
    console.log('toDoList', toDoList)
    if (toDoList.length > 0) {
      toDoList.forEach(item => {
        getTask(item)
      })
    }
  }, [toDoList])

  const getReducesFiles = () => {
    let data = props.files
    console.log(data)
    if (data) {
      setData(props.files);
    } else {
      setData([]);
    }

  }

  const customizeRenderEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 60,
      }}
      description={
        <span>
          {convert('暂无任务,请', locale)}<a href="#" onClick={e => {
            e.preventDefault();
            document.getElementById('upload_dragger').click()
          }}>{convert('上传文件', locale)}</a>{convert('或', locale)}
        </span>
      }
    >
      <Button className='yangli' type="primary" onClick={() => { setVisible(true) }}>{convert('查看样例', locale)}</Button>
    </Empty>
  )
  const getTaskList = (keyword) => {
    setTableLoading(true)
    request(cont.getTaskList, {
      body: {
        keyword: keyword,
        startTime: startTime,
        endTime: endTime
      }
    }, props).then(data => {
      if (data.code == 0) {
        let list = data.list
        let tl = []
        list.forEach(item => {
          item.key = item.id
          if (item.status === 'Excuting') {
            tl.push(item.id)
          }
        })

        setData(list)
        setToDoList(tl)
      } else if (data.code == 1) {
        //圖片識別,需要記錄用戶信息,請先登录
        // notification.error({ description: data.errmsg ? data.errmsg : '未知错误' })
      }
      setTableLoading(false)
    })
  }

  const setData = (list) => {
    let tl_excuting = list.filter(item => item.status === 'Excuting')
    let tl_complete = list.filter(item => item.status !== 'Excuting')
    setExcutingCount(tl_excuting.length)
    setCompleteCount(tl_complete.length)
    setAllCount(list.length)
    dataSourceRef.current = list
    dataSource_CompleteRef.current = tl_complete
    dataSource_ExcutingRef.current = tl_excuting
    setDataSource(list)
    setDataSource_Complete(tl_complete)
    setDataSource_Excuting(tl_excuting)
  }

  const getTaskLog = (key, modalVisible) => {

    request(cont.getTaskLog, {
      body: {
        task_id: key
      }
    }, props).then(data => {
      if (data.code == 0) {
        if (selectRowKeyRef.current && selectRowKeyRef.current == key) {
          setTaskLogs(data.list)
        }
        let task = dataSourceRef.current.find(item => item.id === key)
        if (task.status === 'Excuting' && showLogRef.current) {
          setTimeout(getTaskLog, 1500, key)
        }
      } else if (data.code == 1) {
        message.error(data.errmsg ? data.errmsg : '未知错误')
      }
    })
  }

  const getTask = (key, callback) => {
    request(cont.getTask, {
      body: {
        id: key
      }
    }, props).then(data => {
      if (data.item) {
        let dl = [...dataSourceRef.current]
        let old_task = dl.find(item => item.id === data.item.id)
        if (old_task) {
          dl.splice(dl.indexOf(old_task), 1, data.item)
        } else {
          dl.unshift(data.item)
        }
        console.log("data:", dl)
        setData(dl)
        if (callback) {
          callback(data.item)
        }
      }
      if (data.code == 0) {
        if (data.item.status === 'Excuting') {
          setTimeout(getTask, 1500, key)
        }
      } else if (data.code == 1) {
        message.error(data.errmsg ? data.errmsg : '未知错误')
      }

    })
  }

  const handleUploadText = () => {
    message.loading({ key: 'handleUpload', content: convert('正在创建任务...', locale), duration: 0 });
    if (dataSourceRef.current.filter(item => item.status === 'Excuting').length >= 5) {
      message.warn({ key: 'handleUpload', content: convert('同一时间最多创建5个任务!', locale) })
      return
    }
    if (fileList.length === 0) {
      message.error({ key: 'handleUpload', content: convert('请选择文件!', locale) })
      return
    }
    const formData = new FormData();

    let imgAddress = '';//图片地址
    let uploadStartDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm');//上传时间
    let size = '';//图片大小

    // fileList.forEach(file => {
    //   size = (file.size / 1024).toFixed(2) + 'KB';

    //   const fileReader = new FileReader();
    //   fileReader.readAsDataURL(file);

    //   fileReader.onload = (result) => {
    //     let content = result.currentTarget.result
    //     if (content) {
    //       imgAddress = content;
    //     }
    //   }

    //   formData.append('files[]', file);
    // });
    let files = fileList[0];

    size = (files.size / 1024).toFixed(2) + 'KB';
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files);
    fileReader.onload = (result) => {
      let content = result.currentTarget.result
      if (content) {
        imgAddress = content;
      }
    }

    formData.append('files[]', files);
    formData.append('direction', 'V');

    reqwest({
      url: '/api/upload',
      method: 'post',
      processData: false,
      data: formData,
      success: (res) => {
        let uploadEndDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm');//上传结束时间
        setSpinProps({ spinning: false });

        if (typeof res.success === 'undefined') {
          message.success({ key: 'handleUpload', content: convert('任务创建成功!', locale) })
          setTableLoading(false);

          res.files.map((item, indx) => {
            item['uid'] = files.uid
            item['key'] = indx;
            item['id'] = Math.random();
            item['status'] = 'Complete';
            item['startDate'] = uploadStartDate;
            item['endDate'] = uploadEndDate;
            item['img'] = files.img;
            item['description'] = files.name;
            item['size'] = size;
            item['identify'] = files.identify;
            item['progress'] = 100;
          });

          // console.log('files::::', data.files);

          props.dispatch(lacs.setFilesData([...dataSourceRef.current, ...res.files]));

          console.log([...dataSourceRef.current, ...res.files])

          setData([...dataSourceRef.current, ...res.files]);
        } else {
          message.error({ key: 'handleUpload', content: res.msg ? res.msg : convert('未知错误!', locale) })
        }
      },
      error: (data) => {
        setSpinProps({ spinning: false });
        message.error({ key: 'handleUpload', content: convert('任务创建失败!', locale) })
      },
    });
  }
  const handleUpload = () => {
    message.loading({ key: 'handleUpload', content: convert('正在创建任务...', locale), duration: 0 })
    if (dataSourceRef.current.filter(item => item.status === 'Excuting').length >= 5) {
      message.warn({ key: 'handleUpload', content: convert('同一时间最多创建5个任务!', locale) })
      return
    }
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });
    formData.append('direction', direction)

    request(cont.startTask, {
      body: {
        description: taskDescription
      }
    }, props).then(data => {
      setSpinProps({ spinning: false })
      if (data.code === 0 && data.item) {
        formData.append('task_id', data.item.id)
        reqwest({
          url: window.BASE_URL + cont.ocr_upload,
          method: 'post',
          processData: false,
          data: formData,
          success: (data) => {
            if (data.code == 0) {
              message.success({ key: 'handleUpload', content: convert('任务创建成功!', locale) })
              // getTaskList()
              getTask(data.item)
            } else if (data.code == 1) {
              message.error({ key: 'handleUpload', content: data.errmsg ? data.errmsg : convert('未知错误!', locale) })
            }

          },
          error: (data) => {
            message.error({ key: 'handleUpload', content: convert('任务创建失败!', locale) })
          },
        });
      } else if (data.code === 1) {
        message.error({ key: 'handleUpload', content: data.errmsg ? data.errmsg : convert('未知错误!', locale) })
      }
    })
  };
  const pagination = {
    total: !type ? allCount : (type === 'Excuting' ? excutingCount : completeCount),
    showTotal: (total, range) => {
      return `共 ${total} 条数据`
    },
    hideOnSinglePage: true,
    showSizeChanger: true,
    showQuickJumper: true,
    defaultPageSize: 7,
    pageSizeOptions: ['7', '14', '21', '100'],
    style: { textAlign: 'center', width: '100%' }
  }
  const draggerProps = {
    name: 'file',
    multiple: false,
    action: cont.uploadPicture,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
      if (newFileList.length > 0) {
        setTaskDescription(newFileList[0].name)
      }
    },
    beforeUpload: (file, files) => {
      return new Promise((resolve, reject) => {
        let fds = []
        files.forEach(item => {
          imageUtil.getCanvas_pic(item, newfile => {
            newfile.uid = item.uid
            newfile.identify = item.name + '_' + item.lastModified
            let read = new FileReader()
            read.readAsDataURL(newfile)
            read.onload = (result) => {
              newfile.img = result.target.result
              fds.push(newfile)
              if (fds.length === files.length) {
                const list = [...fds]
                setFileList(list)
                if (list.length > 0) {
                  setTaskDescription(list[0].name)
                }
                reject()
              }
            }
          })
        })
      })
    },
    fileList,
    // accept: ".jpg, .jpeg, .png, .bmp, .pdf",
    accept: ".jpg, .jpeg, .png",
    // directory: true
  }

  const Timeline_pending = () => {
    let tk = dataSourceRef.current.find(item => item.id === selectRowKeyRef.current)
    if (tk && tk.status === 'Excuting' && taskLogs.length > 0) {
      return <span>{taskLogs[taskLogs.length - 1].log_content}</span>
    } else {
      return null
    }
  }
  const Timeline_content = () => {
    const setFontColor = (messageType) => {
      if (messageType === 'error') {
        return 'red'
      } else if (messageType === 'info') {
        return ''
      } else if (messageType === 'warn') {
        return '#faad14'
      } else if (messageType === 'success') {
        return '#52c41a'
      }
    }
    let tk = dataSourceRef.current.find(item => item.id === selectRowKeyRef.current)
    if (tk && tk.status === 'Excuting' && taskLogs.length > 1) {
      let tks = taskLogs.slice(0, taskLogs.length - 1)
      return tks.map(item => <Timeline.Item key={item.id}><span style={{ color: setFontColor(item.type) }}>{item.log_content}</span>{`  ${item.log_time}`}</Timeline.Item>)
    } else if (tk && tk.status !== 'Excuting') {
      return taskLogs.map(item => <Timeline.Item key={item.id}><span style={{ color: setFontColor(item.type) }}>{item.log_content}</span>{`  ${item.log_time}`}</Timeline.Item>)
    } else {
      return null
    }

  }

  return (

    <Layout style={{ padding: '0 30px', backgroundColor: "#FFFFFF" }} className='tasklist' >
      <Spin {...spinProps}>
        {/* <Row style={{ height: '60px', marginBottom: "10px", background: "#fff" }}>
          <div className="direction">
            <Select defaultValue='H' onChange={handleChange} >
              <Option value="H">{convert('竖排', locale)}</Option>
              <Option value="V">{convert('横排', locale)}</Option>
            </Select>
          </div>
          {/* <Dragger {...uploadprops} id="upload_dragger"> */}
        {/* <Row> */}
        {/* <Input style={{ width: 360, height: 40 }} placeholder={convert('粘贴、上传文件或将文件拖拽到这里', locale)} /> */}

        {/* </Row> */}
        {/* </Dragger> */}
        {/* <div className="desctext">{convert('（支持png、bmp、jpg、jpeg、pdf格式，大小不超过10M。）', locale)}</div> */}
        {/* </Row>} */}
        <Content
          className="site-layout-background"
          style={{
            padding: "15px 0",
            margin: 0,
            minHeight: 280,
          }}>
          <Row className="tops">
            <Col className="titled">
              <h1>{convert('任务列表', locale)}</h1>
              <Button className='btnss' size='large' type='text' id="upload_dragger" onClick={
                () => {
                  setFileList([])
                  setVisible2(true)
                }
              }>
                <VerticalAlignTopOutlined /> {convert('上传文件', locale)}
              </Button>
            </Col>
            <Col>
              <Radio.Group defaultValue='' onChange={e => {
                setType(e.target.value)
                if (!e.target.value) {
                  setDataSource(dataSourceRef.current)
                } else if (e.target.value !== 'Excuting') {
                  setDataSource_Complete(dataSourceRef.current.filter(t => t.status !== 'Excuting'))
                } else if (e.target.value === 'Excuting') {
                  setDataSource_Excuting(dataSourceRef.current.filter(t => t.status === 'Excuting'))
                }
              }} >
                <Radio.Button value="">{convert('全部', locale)}<span>({allCount})</span></Radio.Button>
                <Radio.Button value="Complete">{convert('已完成', locale)}<span>({completeCount})</span></Radio.Button>
                <Radio.Button value="Excuting">{convert('识别中', locale)}<span>({excutingCount})</span></Radio.Button>
              </Radio.Group>
            </Col>
            {/* <RangePicker
              style={{ width: "230px", padding: "4px 30px" }}
              placeholder={[convert('开始时间', locale), convert('结束时间', locale)]}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              onChange={onChange}
            />
            <Search
              placeholder={convert('请输入任务描述...', locale)}
              onSearch={value => getTaskList(value)}
              style={{ width: 200 }}
            /> */}

          </Row>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            {/* <Table columns={columns}
              dataSource={!type ? dataSource : (type === 'Excuting' ? dataSource_Excuting : dataSource_Complete)}
              showHeader={false} rowKey='id' loading={tableLoading} pagination={pagination}>  </Table> */}

            <Table columns={columnsText} dataSource={!type ? dataSource : (type === 'Excuting' ? dataSource_Excuting : dataSource_Complete)} showHeader={false} rowKey='id' loading={tableLoading} pagination={pagination}></Table>
          </ConfigProvider>
          <PictureViewer imgView={imgView} />
          <Modal
            className='sad333'
            title={convert('识别样例', locale)}
            visible={visible}
            onCancel={() => { setVisible(false) }}
            footer={null}
            width='60%'
            keyboard={false}
          // centered={true}
          >
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={imgs}
              renderItem={item => (
                <List.Item onClick={() => { show(imgs, item.index) }}>
                  <Card
                    cover={<Avatar shape="square" style={{ height: '100%' }}
                      src={item.url} />}
                  >
                  </Card>
                </List.Item>
              )}
            />
          </Modal>
          <Modal
            title={convert('识别进度', locale)}
            visible={visible1}
            onCancel={() => {
              setVisible1(false);
              showLogRef.current = false
            }}
            footer={null}
            // centered={true}
            destroyOnClose={true}
            bodyStyle={{ maxHeight: 300, overflowY: 'auto' }}
          >
            <div>

              <Timeline pending={Timeline_pending()} reverse>
                {
                  Timeline_content()
                }
              </Timeline>

            </div>
          </Modal>
          <Modal
            className='asadzz999'
            title={convert('上传文件', locale)}
            visible={visible2}
            onCancel={() => {
              setVisible2(false)
              setTaskDescription("")
            }}
            // centered={true}
            bodyStyle={{ maxHeight: 400, overflowY: 'auto' }}
            okText='创建任务'
            onOk={() => {
              setVisible2(false);
              setTaskDescription("")
              setSpinProps({ spinning: true, tip: '正在创建任务...' })
              // handleUpload()
              handleUploadText();
            }}
            destroyOnClose={true}
          >
            <div>
              <Form>
                <Form.Item label='描述'>
                  <Input.TextArea value={taskDescription} placeholder={convert('请输入描述', locale)} onChange={e => {
                    setTaskDescription(e.currentTarget.value)
                  }}></Input.TextArea>
                </Form.Item>
                <Form.Item label='文件'>
                  <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{convert('单击或拖动文件到此区域以上传', locale)}</p>
                    <p className="ant-upload-hint">
                      {/* {convert('支持单次或批量上传', locale)} */}
                    </p>
                    <p className="ant-upload-hint">
                      {/* {convert('支持png、bmp、jpg、jpeg、pdf格式，大小不超过10M。', locale)} */}
                      {convert('支持png、jpg、jpeg', locale)}
                    </p>
                  </Dragger>
                </Form.Item>
              </Form>



            </div>
          </Modal>
        </Content>
      </Spin>
    </Layout >

  )
}

function mapStateToProps(state) {
  return {
    files: state.text.files
  }
}


export default withRouter(connect(mapStateToProps)(TaskList));