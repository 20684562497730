import Immutable from 'immutable';

let initState = {}
export function FileUpdateReducer(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {

        case 'fileUpdate': {
            return action.data.filedata;
        }

        default:
            return state

    }
}