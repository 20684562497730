import React, { useState, useEffect, useRef } from 'react'

import { Layout, Radio, Row, Col, Card, Typography, Divider, Button, Table, Pagination, message, Popover } from 'antd'
import Icon, { ExportOutlined, CaretRightOutlined, UpOutlined, LeftOutlined, DownOutlined, RightOutlined, ProfileFilled, ConsoleSqlOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import convert from '../../../utils/locale_zh_convert'
import * as cont from '../../../config/constant'
import './detail.css'
import PictureViewer from '../components/imgViewer'
import TableRowModal from '../components/tableRowModal'
import CanvasImg from '../components/canvasImg'
import Choices from '../components/choices'
import { useKeyPress } from 'ahooks'
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

//工具栏相关引入
import ToolBar from '../components/toolbar'
import { set } from 'lodash'

import * as lacs from '../../../actions/textDataAction';

const Prevs = props => <Icon component={cont.prevs} {...props} />;
const Nexts = props => <Icon component={cont.nexts} {...props} />;
const { Content } = Layout
const { Text } = Typography;

function Proofread(props) {
    // 生成canvas画布

    const locale = dayjs.locale()
    const { files, modifyList, setModifyList, ssssss } = props;

    const [currentViewindex, setCurrentViewindex] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const [imgView, setImgView] = useState({ show: false, list: [] });
    const [exportType, setExportType] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState();
    const [clickRow, setClickRow] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [selectPositionKeys, setSelectPositionKeys] = useState([]);//点击图片的的位置
    const [deletePositionKeys, setDeletePositionKeys] = useState([]);//需要删除的图片的位置
    // const [modifyList, setModifyList] = useState([]);
    const [wordsData, setWordsData] = useState([]);
    const [positionData, setPositionData] = useState([]);//图片文字切割坐标,宽高列表
    // const [tmpImg, setTmpImg] = useState();//临时图片,用于裁剪
    const [imgOnLoad, setImgOnLoad] = useState(false);
    const [selectTab, setSelectTab] = useState(1);
    const [startEdit, setStartEdit] = useState(false);
    //工具栏用const
    const [lines, setLines] = useState([]);//
    const initScaleSize = 1
    const [scaleSize, setScaleSize] = useState(initScaleSize)
    //图片识别用的状态
    const [distinguish, setDistinguish] = useState(false)
    const options = {
        "inline": true,
        "button": true,
        "navbar": false,
        "title": false,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "initialViewIndex": 0,
        url(image) {
            return image.src.replace('carimages', 'carimages');
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
            align: 'left',
            render: (value, recode, index) => {
                return index + 1
            }
        },
        {
            title: '识别文本',
            dataIndex: 'words',
            key: 'words',
            align: 'left',
            render: (value, recode, index) => {
                // modified={modifyList.find(m => m.position === item.key + '_' + indx && m.value === word.text &&m.change)}
                // modified={modifyList.find(m => m.position === record.key && m.value === text &&m.change)}
                var ml = modifyList.filter(item => item.position.split('_')[0] === (index + ''));
                let rightSmall = []
                let leftSmall = []
                // console.log(value);
                return <Text id={'row_' + index}>{
                    value.map((item, indx) => {
                        var mo = ml.find(it => it.position.split('_')[1] === (indx + ''))
                        if (item.wordFlag === 'RightSmall') {
                            rightSmall.push(<Text style={{ color: (!mo || !mo.change) && '#368c0b' }} className={mo && mo.change && 'active_error'} key={indx}>
                                {item.text}</Text>)
                            if (indx === value.length - 1) {
                                let s = [...rightSmall]
                                let s1 = [...leftSmall]
                                return <span key={indx + '_'} style={{ color: '#368c0b' }}>{(s.length > 0 || s1.length > 0) && '['}{s}
                                    {(s.length > 0 && s1.length > 0) && '/'}{s1}{(s.length > 0 || s1.length > 0) && ']'}</span>
                            } else {
                                return '';
                            }
                        } else if (item.wordFlag === 'LeftSmall') {
                            leftSmall.push(<Text style={{ color: (!mo || !mo.change) && '#368c0b' }} className={mo && mo.change && 'active_error'} key={indx}>
                                {item.text}</Text>)
                            if (indx === value.length - 1) {
                                let s = [...rightSmall]
                                let s1 = [...leftSmall]
                                return <span key={indx + '_'} style={{ color: '#368c0b' }}>{(s.length > 0 || s1.length > 0) && '['}{s}
                                    {(s.length > 0 && s1.length > 0) && '/'}{s1}{(s.length > 0 || s1.length > 0) && ']'}</span>
                            } else {
                                return '';
                            }
                        } else {
                            let s = [...rightSmall]
                            let s1 = [...leftSmall]
                            rightSmall = []
                            leftSmall = []
                            let currpage = false
                            if (mo) {
                                if (mo.posi_page === currentViewindex) {
                                    currpage = true
                                }
                            }
                            return <span key={indx + '_'} >
                                <span style={{ color: (!mo || !mo.change) && '#368c0b' }}>{(s.length > 0 || s1.length > 0) && '['}{s}
                                    {(s.length > 0 && s1.length) > 0 && '/'}{s1}{(s.length > 0 || s1.length > 0) && ']'}</span>
                                <Text className={mo && mo.change && currpage && 'active_error'} key={indx}>
                                    {`${item.wordFlag === 'RightSmall' ? '[' : ''}${item.text}${item.wordFlag === 'LeftSmall' ? ']' : ''}`}</Text>
                            </span>
                        }
                    })
                }</Text >
            }
        },
    ]
    const columns1 = [
        {
            title: '列',
            dataIndex: 'col',
            key: 'col',
            align: 'center',
        },
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (value, recode, index) => {
                return index + 1
            }
        },
        {
            title: '字',
            dataIndex: 'text',
            key: 'text',
            align: 'center',
            width: '10%',
            render: (text, record, index) => {
                return <div
                    className={record.key === clickRow && !startEdit ? 'active' : ''}
                    onClick={() => {
                        setClickRow(record.key)
                        setSelectPositionKeys([record.key])
                        setStartEdit(true)
                    }}>
                    <Choices
                        key={record.key}
                        onClick={() => {
                            setClickRow(record.key)
                            setSelectPositionKeys([record.key])
                            setStartEdit(true)
                        }}
                        currKey={record.key}
                        selectKeys={[clickRow]}
                        startEdit={startEdit}
                        style={{ fontSize: 24 }}
                        modified={modifyList.find(m => m.position === record.key && m.value === text && m.change)}
                        editable
                        choices={record.choices}
                        text={text}
                        onSelect={(value) => {
                            if (value !== text) {
                                var mo = modifyList.find(item => item.position === selectedRowKeys[0] + '_' + index)
                                if (mo) {
                                    mo.value = value;
                                    mo.change = value !== mo.old_value
                                } else {
                                    mo = {
                                        position: selectedRowKeys[0] + '_' + index,
                                        old_value: text,
                                        value: value,
                                        change: value !== text,
                                        posi_page: currentViewindex
                                    }
                                    modifyList.push(mo)
                                }
                                setModifyList(modifyList)

                                record.text = value;
                                handleSave(record, value)
                            }
                            setStartEdit(false)
                        }} /></div>
            },
            // editable: true,
        },
        {
            title: '图像',
            dataIndex: 'pic',
            key: 'pic',
            align: 'center',
            render: (text, record, index) => {
                return <CanvasImg
                    onClick={() => {
                        setClickRow(record.key)
                        setSelectPositionKeys([record.key])
                        setStartEdit(true)
                    }}
                    img={document.getElementById("currentPageImg")}
                    // img={tmpImg}
                    key={record.key}
                    sx={record.x} sy={record.y} swidth={record.width} sheight={record.height}
                    x={0} y={0} width={record.width} height={record.height}
                    onload={imgOnLoad} />
            },
        },
        {
            title: '概率',
            dataIndex: 'gailv',
            key: 'gailv',
            align: 'center',
        },
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            align: 'center',
        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            align: 'center',
        },
        {
            title: '宽',
            dataIndex: 'width',
            key: 'width',
            align: 'center',
        },
        {
            title: '高',
            dataIndex: 'height',
            key: 'height',
            align: 'center',
        },
    ]



    const handleSave = (row, value) => {
        const newData = [...wordsData];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setWordsData(newData)


        const ix = selectedRowKeys[0]
        const p = { ...currentPage }
        const page = { ...p.page }
        const lines = [...page.lines]
        const targetline = { ...lines[ix] };
        const targetwords = [...targetline.words]
        const targetword = { ...targetwords[index], ...{ text: value } }
        targetwords.splice(index, 1, targetword)
        lines.splice(ix, 1, { ...targetline, ...{ words: targetwords } })
        let newPage = {
            ...currentPage,
            ...{
                page: {
                    lines: lines
                }
            }
        }

        ssssss(newPage);

        setCurrentPage(newPage)
        let pkdsd = files[currentViewindex]
        pkdsd.ocr_content = JSON.stringify(newPage);
        files.splice(currentViewindex, 1, pkdsd)
    }


    function getCurrentPage() {

        // let cup = files[currentViewindex] && JSON.parse(files[currentViewindex].ocr_content)
        let cup = files[currentViewindex]
        setCurrentPage(cup)
        // console.log(cup)
        setTotalCount(cup && cup.page.lines && cup.page.lines.reduce(
            (total, currentValue, currentIndex, arr) => {
                return total + currentValue.words.length
            }, 0)
        )
        setSelectedRowKeys([0])
        setClickRow('')
        let posData = []
        cup && cup.page.lines && cup.page.lines.map((line, index) => {
            line.words.map((it, indx) => {
                posData.push(
                    {
                        index: indx,
                        key: index + '_' + indx,
                        col: index + 1,
                        x: it.position[0],
                        y: it.position[1],
                        width: it.position[2] - it.position[0],
                        height: it.position[3] - it.position[1],
                        wordFlag: it.wordFlag,
                        line: line,
                        data: it
                    }
                )
            })
        })

        setPositionData(posData)
    }

    function show(fileList) {
        setImgView({
            show: !imgView.show,
            list: [...fileList],
        })
    }
    //图片切分调整
    const adjust_img = (params) => {
        // message.info({ key: 'adjust', content: convert('重新识别中...', locale) })
        // fetch('/api/ocr/words', {
        //     method: 'POST',
        //     body: params,
        // }).then(response =>
        //     response.json()
        // ).then(data => {
        //     if (!data.success) {
        //         message.error({ key: 'adjust', content: data.msg ? data.msg : '识别失败' })
        //     } else {
        //         message.success({ key: 'adjust', content: convert('识别完成...', locale) })
        //     }
        // })
        message.error({ key: 'adjust', content: convert('重新识别暂不可用', locale) })
    }
    //图片识别
    const distinguish_img = () => {
        setDistinguish(!distinguish)
    }
    // function zoom_in() {
    //     if (scaleView < 4) {
    //         let scale = scaleView + 0.25
    //         // viewref.current.style.zoom = scale
    //         viewref.current.style.transform = 'scale(' + scale + ',' + scale + ')'
    //         setScaleView(scale)
    //     }
    // }
    // function zoom_out() {
    //     if (scaleView > 0.5) {
    //         let scale = scaleView - 0.25
    //         // viewref.current.style.zoom = scale
    //         viewref.current.style.transform = 'scale(' + scale + ',' + scale + ')'
    //         setScaleView(scale)
    //     }
    // }
    // function zoom_init() {
    //     let scale = initScaleSize
    //     // viewref.current.style.zoom = scale
    //     viewref.current.style.transform = 'scale(' + scale + ',' + scale + ')'
    //     setScaleView(initScaleSize)
    // }
    // function magnify() {
    //     setMagnified(!magnified)
    // }
    // function changeFontsize(value) {
    //     setFontsize(value)
    // }
    useEffect(() => {
        if (files && files.length > 0) {
            // console.log(currentViewindex)
            getCurrentPage()
            // if (files[currentViewindex].picture_id) {
            //     setImgOnLoad(false)
            //     // const img = document.createElement('img');//创建临时图片,用于裁剪,
            //     const img = document.getElementById('currentPageImg');//图片容器 ,放置临时图片,用于裁剪 建议用图片容器,不需要每次翻页时创建图片对象
            //     img.src = `${cont.getPicture}?id=${files[currentViewindex].picture_id}`;
            //     img.onload = () => { setImgOnLoad(true) }
            // }


            if (files[currentViewindex]) {
                setImgOnLoad(false)
                // const img = document.createElement('img');//创建临时图片,用于裁剪,
                const img = document.getElementById('currentPageImg');//图片容器 ,放置临时图片,用于裁剪 建议用图片容器,不需要每次翻页时创建图片对象
                // img.src = `${cont.getPicture}?id=${files[currentViewindex]}`;
                img.src = `${files[currentViewindex].img}`;
                img.onload = () => { setImgOnLoad(true) }
            }
        }
        setScaleSize(1)
    }, [currentViewindex, files])


    useEffect(() => {
        currentPage && setWordsData(currentPage.page.lines && currentPage.page.lines[selectedRowKeys[0]].words.map((it, index) => {
            return {
                key: selectedRowKeys[0] + '_' + index,
                col: selectedRowKeys[0] + 1,
                text: it.text,
                pic: it.text,
                choices: it.choices,
                gailv: it.score + '%',
                x: it.position[0],
                y: it.position[1],
                width: it.position[2] - it.position[0],
                height: it.position[3] - it.position[1],
            }
        }))
    }, [selectedRowKeys])



    // useKeyPress(
    //     ['delete'],
    //     event => {
    //         setDeletePositionKeys([...selectPositionKeys])
    //     },
    //     {
    //         events: ['keydown'],
    //         target: document
    //     }
    // )
    // useKeyPress(
    //     ['up', 'down', 'left', 'right'],
    //     event => {
    //         console.log(event)
    //         if (clickRow) {
    //             let lineIndex = parseInt(selectedRowKeys[0])
    //             let wordIndex = parseInt(clickRow.split('_')[1])

    //             if (event.key === 'ArrowDown') {
    //                 wordIndex = wordIndex + 1
    //             }

    //             if (event.key === 'ArrowUp') {
    //                 wordIndex = wordIndex - 1
    //             }
    //             if (event.key === 'ArrowLeft') {
    //                 lineIndex = lineIndex + 1
    //             }
    //             if (event.key === 'ArrowRight') {
    //                 lineIndex = lineIndex - 1
    //             }
    //             let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
    //             if (!word) {
    //                 if (event.key === 'ArrowDown') {
    //                     wordIndex = 0
    //                     lineIndex = lineIndex + 1
    //                     let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
    //                     if (!word) {
    //                         lineIndex = 0
    //                     }
    //                 }

    //                 if (event.key === 'ArrowUp') {
    //                     wordIndex = 0
    //                     lineIndex = lineIndex - 1
    //                     let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
    //                     if (words && words.length > 0) {
    //                         word = words.pop()
    //                     } else {
    //                         word = positionData.pop()
    //                     }
    //                     lineIndex = word.key.split('_')[0]
    //                     wordIndex = word.key.split('_')[1]
    //                 }
    //                 if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    //                     let words = positionData.filter(item => item.key.startsWith(lineIndex + '_'))
    //                     if (words && words.length > 0) {
    //                         let word = words.pop()
    //                         wordIndex = word.key.split('_')[1]
    //                     } else {
    //                         let word_wei = event.key === 'ArrowRight' ? positionData.pop() : positionData[0]
    //                         lineIndex = word_wei.key.split('_')[0]
    //                         let word = positionData.find(item => item.key == lineIndex + '_' + wordIndex)
    //                         if (!word) {
    //                             word = word_wei
    //                         }
    //                         wordIndex = word.key.split('_')[1]
    //                     }

    //                 }

    //             }
    //             setSelectPositionKeys([lineIndex + '_' + wordIndex])
    //             setSelectedRowKeys([lineIndex])
    //             setClickRow(lineIndex + '_' + wordIndex)
    //             setStartEdit(true)
    //         }
    //     },
    //     {
    //         events: ['keydown'],
    //         target: () => document
    //     }
    // )
    const Tub1s = props => <Icon component={cont.Tub1} {...props} />;
    const Tub2s = props => <Icon component={cont.Tub2} {...props} />;
    const text1 = <span>快捷键</span>
    const content1 = (
        <div className='box'>
            <div style={{ display: "flex" }}>
                <p className='xia'>选字</p>
                <div className='jiantou'>
                    <div style={{ textAlign: 'center' }}>
                        <UpOutlined />
                    </div>
                    <div>
                        <LeftOutlined /><DownOutlined /><RightOutlined />
                    </div>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <p>删除</p><span>Delete</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>左右拆分</p><span>S</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>上下拆分</p><span>W</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>大字</p><span>F</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>左小字</p><span>E</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>右小字</p><span>R</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>合并成字</p><span>M</span>
            </div>
            <div style={{ display: "flex" }}>
                <p>连选</p><span>Ctrl</span><span className='jia'>+</span>
            </div>
        </div>
    )
    return (<Content
        className="site-layout-background"
        style={{
            padding: '0',
            margin: 0,
            minHeight: 280,
        }}
    >
        <img id="currentPageImg" style={{ display: 'none' }} />
        <Row>
            <Col span={12}>
                <Card title={files[currentViewindex] && files[currentViewindex].name.split('.').slice(0, files[currentViewindex].name.split('.').length - 1)}
                    headStyle={{ textAlign: 'left', backgroundColor: '#dee1e4', height: 40, border: "1px solid #ccc" }}
                    extra={<Radio.Group defaultValue="" size="large">
                        <Radio.Button value="a" onClick={() => {
                            adjust_img()
                        }}><Tub1s className="tubiao" /></Radio.Button>
                        <Radio.Button value="b" onClick={() => {
                            //每次点击取反
                            distinguish_img()
                        }}><Tub2s className="tubiao" /></Radio.Button>
                        {(currentViewindex + 1)} / {files.length}
                    </Radio.Group>
                    }
                // extra={`${(currentViewindex + 1)} / ${files.length}`}
                >
                    {/* <PictureViewer imgView={{ list: files, options: options }} /> */}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: "wrap" }}>
                        {/* 左侧上一页 */}
                        {/* <Button className="prevs" style={{ height: 'auto' }} disabled={currentViewindex === 0} onClick={() => {
                            let pages = Math.ceil(files && files.length / 1)
                            if (pages === NaN || pages === 0) {
                                return
                            } else if (currentViewindex - 1 <= -1) {
                                message.warning(convert('已是第一页', locale))
                                return
                            } else {
                                setCurrentViewindex(currentViewindex - 1)
                            }
                        }}><Prevs /></Button> */}
                        <div style={{ overflow: 'auto', height: 682, width: 'auto' }}>
                            <CanvasImg
                                adjustImg={adjust_img}
                                distinguish={distinguish}
                                dragable
                                img={document.getElementById("currentPageImg")}
                                onload={imgOnLoad}
                                positionData={positionData}
                                selectPositionKeys={selectPositionKeys}
                                deletePositionKeys={deletePositionKeys}
                                onSelect={(selectPositionKeys, selectPosition, positionList) => {
                                    // console.log("selectPositionKeys:",selectPositionKeys)
                                    // console.log("positionList:",positionList)
                                    let positionListKey = positionList[positionList.length - 1].key;
                                    let table1 = Number(positionListKey.split('_')[0]) + 1;
                                    let selectPositionKeysList = selectPositionKeys[0].split('_');

                                    let tagNameListTr = document.getElementsByTagName('tr');
                                    let one = Number(selectPositionKeysList[0]) + 1;
                                    let two = table1 + Number(selectPositionKeysList[1]) + 2;
                                    if (tagNameListTr[one]) {
                                        tagNameListTr[one].scrollIntoView({ block: 'nearest' });
                                    }
                                    if (tagNameListTr[two]) {
                                        tagNameListTr[two].scrollIntoView({ block: 'nearest' });
                                    }
                                    if (selectPosition && selectPosition.key) {
                                        setSelectedRowKeys([selectPosition.col - 1])
                                        setClickRow(selectPosition.key)
                                        // setSelectPositionKeys(selectPositionKeys)
                                    }
                                }}
                                current={currentViewindex + 1}
                                onPageChange={(page) => {
                                    console.log(page)
                                    setCurrentViewindex(page - 1)
                                }}
                                pageSize={1}
                                total={files && files.length}
                                showFooter={true}
                                initScaleSize={scaleSize}
                                selectLines={selectedRowKeys}
                            />
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: "center" }}>
                            {/* 右侧下一页 */}
                            {/* <Button className="nexts" style={{ height: 'auto' }} disabled={currentViewindex === files.length - 1} onClick={() => {
                                let pages = Math.ceil(files && files.length / 1)
                                if (pages === NaN || pages === 0) {
                                    return
                                } else if (currentViewindex < files && files.length - 1) {
                                    message.warning(convert('已是最后一页', locale))
                                    return
                                } else {
                                    setCurrentViewindex(currentViewindex + 1)
                                }
                            }}><Nexts /></Button> */}
                            {/* 工具栏 */}
                            {
                                <ToolBar
                                    total={
                                        files && files.length
                                    }
                                    pageSize={1}
                                    onPageChange={(page) => {
                                        setCurrentViewindex(page - 1)
                                    }}
                                    initScaleSize={initScaleSize}
                                    onScale={(value) => {
                                        setScaleSize(value)
                                    }}
                                    onResetScale={() => {
                                        setScaleSize(initScaleSize)
                                    }}
                                    current={currentViewindex + 1}
                                    locale={locale}
                                />
                            }
                        </div>
                    </div>

                </Card>
            </Col>

            <Col span={12}>
                <Card title={convert('识别结果', locale)}
                    bodyStyle={{ backgroundColor: 'rgb(222, 226, 229)' }}
                    headStyle={{ textAlign: 'left', backgroundColor: 'rgb(84, 125, 166)', height: 40, color: '#FFF' }}
                    extra={<Row style={{ color: '#FFF' }}>{convert('当前第', locale)}
                        <Pagination
                            pageSize={1} simple
                            current={currentViewindex + 1}
                            onChange={(page, pageSize) => {
                                setCurrentViewindex(page - 1)
                            }}
                            total={files && files.length}
                        />
                        {convert('页', locale)}<Divider type='vertical'></Divider>
                        <TableRowModal
                            locale={locale}
                            onChange={(e) => {
                                setExportType(e.target.value)
                            }}
                            value={exportType}
                            data={currentPage}
                            viewpicture={show}
                            children={<span>{convert('导出', locale)}&nbsp;<ExportOutlined style={{ fontSize: 20, verticalAlign: 'sub' }} /></span>}
                        /></Row>}>
                    <Row>{convert('共纠正', locale)}<span style={{ color: '#b2363d' }}>{modifyList.filter(t => t.change).length}</span>{convert('个文字,本文共', locale)}<span style={{ color: '#3ea10d' }}>{totalCount}</span>{convert('个文字,正确率', locale)}<span style={{ color: '#547da6' }}>{((totalCount - modifyList.filter(t => t.change).length) * 100 / totalCount).toFixed(2)}%</span></Row>
                    <Row span={24} id='table_1'>
                        <Table
                            columns={columns}
                            dataSource={
                                currentPage && currentPage.page.lines && currentPage.page.lines.map((i, index) => (
                                    {
                                        key: index,
                                        ...i
                                    }
                                ))
                            }
                            bordered={true}
                            onRow={record => {
                                return {
                                    onClick: event => {
                                        setSelectedRowKeys([record.key])
                                        setClickRow('')
                                    }, // 点击行
                                    onDoubleClick: event => { },
                                    onContextMenu: event => { },
                                    onMouseEnter: event => { }, // 鼠标移入行
                                    onMouseLeave: event => { },
                                };
                            }}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: selectedRowKeys,
                                renderCell: (checked, record, index, originNode) => {
                                    if (checked) {
                                        return <CaretRightOutlined />
                                    }
                                }
                            }}
                            style={{ width: '100%', height: 300, overflowY: 'auto' }}
                            size='small'
                            pagination={false}></Table>
                    </Row>
                    <Row className='dasd222' span={24} style={{ marginTop: 12 }} id='table_2' >
                        <Table
                            columns={columns1}
                            dataSource={wordsData}
                            rowKey='key'
                            style={{ width: '100%', height: 390, overflowY: 'auto' }} size='small' pagination={false}></Table>
                    </Row>
                </Card>
            </Col>
        </Row>
        <div style={{ position: 'fixed', right: 0, top: "50%", }}>
            <Popover overlayClassName="sad2232" placement="left" title={text1} content={content1} trigger="click">
                <ProfileFilled style={{ fontSize: "40px" }} />
            </Popover>
        </div>
        <PictureViewer imgView={imgView} onChange={(viewindex) => {
            setCurrentViewindex(viewindex)
        }} />
    </Content>)
}

export default Proofread