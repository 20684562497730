import React, { Component } from 'react';
import { Layout, Popover, Button, Upload, Modal, Input, Tabs, Switch, Checkbox, Spin, Radio, Slider, Menu, Dropdown, message, Row, Progress, Divider, Table, Col, Popconfirm, Space, Empty, Select } from 'antd';
import './NamedEntity.css';
import Icon from '@ant-design/icons';
import convert from '../../utils/locale_zh_convert';
import { UploadOutlined, ArrowRightOutlined, PlusOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, DeleteOutlined, CaretRightOutlined, ExportOutlined, FileTextOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';
import { withRouter } from 'react-router-dom'
import reqwest from 'reqwest'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import './../../data/mock_data'
import DemoWordCloud from './DemoWordCloud'
import { connect } from 'react-redux'
import { set_deal_content } from './../../actions/DealTextContentAction'
import loading from "../../images/loading.svg"
import doText from '../../utils/doText'

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;
const CheckboxGroup = Checkbox.Group;

const Back = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" width="11px" height="14px" viewBox="0 0 11 14" enableBackground="new 0 0 11 14">  <image id="image0" width="11" height="14" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAMAAADOvxanAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAe1BMVEXy9/zH2eesxtva5vFU
iLCPsc3o8Phikrhvm77u9Pt3oMJShq/F1+bT4e3i6/RIf6tZjLOeu9Tw9fvj7PXV4u6rxdtViLFw
nL7g6vTO3uvn7/edu9RgkLbL2+lNgq2zyt/q8fnE1+a1zOCMr8tKgKtWibF4ocK+0uL///8Rc2Lj
AAAAAWJLR0QovbC1sgAAAAd0SU1FB+QJAQIIF7wjLYEAAABaSURBVAjXRcxHFoAgDEXR2CtSFOwV
y/53qOIhvsm/J4MAgOOCzfMDyzCKE+s0ywkpPlMT4+Jx+VZJVQv80CiOBsl+V7RAt7RD9wNynGaz
y7rpXX83Qo/zevYGKZAEjhKcqJsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDgtMzFUMTg6MDg6
MjMrMDg6MDCabfaCAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA4LTMxVDE4OjA4OjIzKzA4OjAw
6zBOPgAAACB0RVh0c29mdHdhcmUAaHR0cHM6Ly9pbWFnZW1hZ2ljay5vcme8zx2dAAAAGHRFWHRU
aHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OkhlaWdodAAx
NPOhP0MAAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMTF7ZAtBAAAAGXRFWHRUaHVtYjo6TWlt
ZXR5cGUAaW1hZ2UvcG5nP7JWTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE1OTg4Njg1MDMuR9qZAAAA
EXRFWHRUaHVtYjo6U2l6ZQAzODZCQjg00IkAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAv
dG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNTk4ODYyMjc0Njc0MTE3M18xOF9bMF3NzS3qAAAAAElF
TkSuQmCC" ></image>
  </svg>
)
const Backs = props => <Icon component={Back} {...props} />;
// const plainOptions1 = ['棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注黃鍾初九也下生林鍾之初六林鍾 又上生', '棟案乾鑿度之説與十二律相生圖合鄭 于周 华裔 注云黃鍾初九也下生林鍾之初林鍾 又上生',];
function callback(key) {
  console.log(key);
}
function onChangeSwitch(checked) {
  console.log(`switch to ${checked}`);
}
class NamedEntity extends Component {
  constructor(props) {
    super(props)
    let content = ''
    let editable = true
    let jd_deal_content_ner = ''
    if (props.dealTextContent && props.dealTextContent.jd_deal_content
      && props.dealTextContent.jd_deal_content.deal_content) {
      content = props.dealTextContent.jd_deal_content.deal_content.join('')
      jd_deal_content_ner = props.dealTextContent.jd_deal_content.deal_content_ner.join('')
      editable = false
    } else if (props.dealTextContent && props.dealTextContent.mm_deal_content && props.dealTextContent.mm_deal_content.content) {
      content = props.dealTextContent.mm_deal_content.content.join('')
      editable = false
    } else if (props.dealTextContent && props.dealTextContent.content) {
      content = props.dealTextContent.content.join('')
      editable = false
    }
    console.log(props)
    this.state = {
      color: '#000000',
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      visible7: false,
      visible8: false,
      visible9: false,
      isModalVisible: false,
      value: 1,
      fileList: [],
      editable: editable,
      textcontent: content, //处理前文本
      deal_textcontent: [],//处理后文本
      pretextcontent: [],
      progress: 0,
      complete: true,
      action: '4',
      text_type: 'a',//实体模式
      search_type: "machine_learning",
      tagList: [],
      tag_name: '',
      tag_key: 'peop',
      statisticsList: [],//标记统计,总览列表
      keywordStatisticsList: [],//标记统计,图表
      task_uid: this.props.dealTextContent ? this.props.dealTextContent.uid : '',
      task_identify: this.props.dealTextContent ? this.props.dealTextContent.identify : '',
      versionList: [],
      keyword: '',
      currStyle: undefined,
      searchResult: [],
      selectedRowKeys: [],
      addTagtype: '2',
      selectText: '',
      deal_textcontent2: [],
      threshold: "20",
      all_text_rade: [],
      keyword_arr: {},
      fildId: '',
      deskHeight: document.body.clientHeight,
      rate: !localStorage.getItem("token") ? 12 : 20,
      rateNumMask: 20,
      rateVisile: this.props.handledData ? this.props.handledData.rateNumMask : false,
    };
    this._jd_deal_content_ner = jd_deal_content_ner
    this.locale = dayjs.locale()
    this._textcontent = ''////处理前文本缓存
    this._deal_textcontent = this.props.dealTextContent && this.props.dealTextContent.mm_deal_content && this.props.dealTextContent.mm_deal_content.deal_content_code ? this.props.dealTextContent.mm_deal_content.deal_content_code.join('') : ''////处理后文本缓存
    this._ner_index = null////处理后标注缓存
    this._tagList = []////处理后标注缓存
    this.handledtextcontent = this.props.handledDataToNameEntity.deal_textcontent;
    this.fildID = this.props.handledDataToNameEntity.fildID;
    this.columns1 = [
      {
        key: 'tag_name',
        title: convert('类别', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles && row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_times',
        title: convert('去重', this.locale),
        dataIndex: 'tag_times',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      },
      {
        key: 'tag_times_total',
        title: convert('总计', this.locale),
        dataIndex: 'tag_times_total',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      }
    ]
    this.columns2 = [
      {
        key: 'keyword',
        title: convert('实体', this.locale),
        dataIndex: 'keyword',
        sorter: (a, b) => {
          let acol = a.keyword
          let bcol = b.keyword
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_times',
        title: convert('频次', this.locale),
        dataIndex: 'tag_times',
        sorter: (a, b) => {
          let acol = a.tag_times
          let bcol = b.tag_times
          if (!acol) {
            acol = 0
          }
          if (!bcol) {
            bcol = 0
          }
          return acol - bcol
        },
      },
      {
        key: 'tag_name',
        title: convert('类型', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      }
    ]
    this.columns3 = [
      {
        key: 'tag_name',
        title: convert('类别', this.locale),
        dataIndex: 'tag_name',
        sorter: (a, b) => {
          let acol = a.tag_name
          let bcol = b.tag_name
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'tag_key',
        title: convert('类别标识', this.locale),
        dataIndex: 'tag_key',
        sorter: (a, b) => {
          let acol = a.tag_key
          let bcol = b.tag_key
          if (!acol) {
            acol = ''
          }
          if (!bcol) {
            bcol = ''
          }
          return acol.localeCompare(bcol)
        },
        render: (text, row, index) => {
          return <span style={row.styles[3]}>{text}</span>
        }
      },
      {
        key: 'options',
        title: convert('操作', this.locale),
        dataIndex: 'options',
        render: (text, row, index) => {
          return <Space>
            <Popconfirm
              title={convert("确定删除该类别?", this.locale)}
              onConfirm={() => { this.deleteTag(row) }}
              okText={convert("确定", this.locale)}
              cancelText={convert("取消", this.locale)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <span style={{ color: 'red' }}><Space><DeleteOutlined />{convert('删除', this.locale)}</Space></span>
            </Popconfirm>
            <Divider type='vertical' />
            <Popconfirm
              title={convert("确定" + (row.visible ? '隐藏' : '显示') + "该类别?", this.locale)}
              onConfirm={() => { this.setTagVisible(row.id, !row.visible) }}
              okText={convert("确定", this.locale)}
              cancelText={convert("取消", this.locale)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              {
                row.visible ? <span style={{ color: '#121c80' }}><Space><EyeOutlined />{convert('显示', this.locale)}</Space></span>
                  : <span style={{ color: '#121c80' }}><Space><EyeInvisibleOutlined />{convert('隐藏', this.locale)}</Space></span>
              }
            </Popconfirm>
          </Space>
        }
      },
    ]
  }

  onChangeRa = e => {
    e.stopPropagation();
    this.setState({
      addTagtype: e.target.value,
    });
  };
  onChange1 = e => {
    console.log(e.target.value)
    this.setState({
      tag_key: e.target.value
    })
    // console.log(this.state.value1)
  };

  tagtext = (callback) => {
    let _this = this
    if (!_this.state.textcontent) {
      message.error('待处理文本不能为空,请上传/粘贴文本')
      _this.setState({
        action: '1'
      })
      return
    }
    let uid = _this.state.task_uid
    let identify = _this.state.task_identify

    _this.setState({
      visible5: true,
      complete: false,
      progress: 0,
    }, () => {
      _this.progressRun()
      // let sentence = _this._jd_deal_content_ner ? _this._jd_deal_content_ner : _this.state.textcontent
      let sentence = _this.state.textcontent
      sentence = sentence.replace(/ο/g, "〇")
      //sentence = sentence.replace(/\r\n/g,'◉') //...
      //sentence = sentence.replace(/◉{2,}/g,'◉') //...
      sentence = sentence.replace(/\s+/g, '')
      sentence = sentence.replace(/〔/g,'[')
      sentence = sentence.replace(/〕/g,']')
      console.log(sentence)

      var reqXhr = new XMLHttpRequest();
      var form = new FormData();
      form.append("sentence", sentence)
      reqXhr.open('POST', '/wyd/predict/getEntity', false);
      reqXhr.responseType = 'json';
      reqXhr.setRequestHeader("WYD-TOKEN", localStorage.getItem("token") == null ? "" : localStorage.getItem("token"))

      reqXhr.onload = function () {
        let data = JSON.parse(this.response);
        if (data.code == 200) {
          let fildID = data.data.id;
          let content = data.data.content
          let ner_index = data.data.index;
          let source = data.data.source;
          _this._deal_textcontent = content
          _this._textcontent = _this.state.textcontent
          _this._ner_index = ner_index;
          let dataSou = data;

          // 得到响应头，剩余次数
          var rate = reqXhr.getResponseHeader('rate-1d');
          let rateNum = false;
          if (rate) {
            rateNum = true;
            let arrRate = rate.split("/");
            console.log(arrRate[0]);
            let rateNumMask = arrRate[1] - arrRate[0]
            let tempToken = localStorage.getItem("token");
            let tempID = localStorage.getItem("id");
            if (tempToken) {
              let rateobj = localStorage.getItem(tempID);
              if (!rateobj) {
                rateobj = {};
              } else {
                rateobj = JSON.parse(rateobj);
              }
              rateobj['rateNumMask'] = rateNumMask;
              rateobj = JSON.stringify(rateobj);
              localStorage.setItem(tempID, rateobj);

            } else {
              // _this.setState({rateVisile:false})
              localStorage.setItem("rateNumMask", rateNumMask);
            }
          }
          _this.doTextMask(source,rateNum,fildID,dataSou)
          _this.props.history.push('/NamedEntityDisposed')
        } else if (data.code == 500) {
          message.error(data.message);
        }
        callback && callback()
        _this.setState({
          visible5: false,
          complete: true,
        })
      }
      reqXhr.send(form);

    })

  }

  changeAction = (action) => {

    let _this = this
    if (action === '1' || action === '3' || action === '5') {
      _this.setState({
        visible2: true
      })
      return;
    }
    if (action === '6' || action === '7') {
      return;
    }
    if (!_this.state.textcontent) {
      message.error('待处理文本不能为空,请上传文本')
      _this.setState({
        action: '1'
      })
      return
    }
    // 找到含〇的个数
    // var pattern = /[〇]/g,
    var str = _this.state.textcontent;
    var thisCount = 0;
    str.replace(/[〇，。？！]/g, function (m, i) {
      //m为找到的〇元素、i为索引
      thisCount++;
    });
    // var strs = null;
    // strs=str.split('〇');
    // thisCount=strs.length-1;
    let checkFlag = true;
    if (_this.state.textcontent.length > 20 && thisCount * 100 / _this.state.textcontent.length < 0.06 * 100) {
      checkFlag = false;
    }

    if (!checkFlag) {
      _this.setState({ isModalVisible: true });
      _this.confirm();
    } else {
      _this.setState({
        action: action,
        text_type: 'a',
      }, () => {
        if (action === '2' || action === '4')
          this.tagtext();

      })

    }
  }
  confirm = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请您上传/粘贴带标点的文本或先执行“自动标点/句读”',
      okText: '确认',
      width: '500px',
      onOk: () => this.handleAutomatic(this.state.textcontent),
      onCancel: () => {
        this.setState({
          isModalVisible: false
        })
      },
      cancelText: "取消",
    });
  }
  handleAutomatic = (textcontent) => {
    // 到句读中进行标点处理
    let todoHandleDataObj = {};
    todoHandleDataObj.content = textcontent;
    todoHandleDataObj.flag = 1;
    this.props.dispatch({ type: "todoHandleAutomaticDu", data: { todoHandleData: todoHandleDataObj } });
    this.props.history.push('/BiaoDian')
  }
  progressRun = () => {
    let _this = this
    if (!_this.state.complete) {
      if (_this.state.progress < 100) {
        _this.setState({ progress: _this.state.progress + 5 })
      }
      setTimeout(() => {
        _this.progressRun()
      }, 1000)
    }
  }



  handleColorChange = (colorCode) => {
    this.setState({ color: colorCode.hex })
  }
  componentDidMount() {

    if (this.props.handledDataToNameEntity.deal_textcontent) {
      if (this.props.handledDataToNameEntity.deal_textcontent.indexOf('</span>') != -1) {
        this.setState({
          textcontent: ''
        })
      } else {

        this.setState({
          textcontent: this.props.handledDataToNameEntity.deal_textcontent
        }, () => {

          if (this.props.handledDataToNameEntity.executeNamedEntity == 1) {
            //如果是自动句读页面过来的
            var obj = this.props.handledDataToNameEntity;
            //执行专名查询  已执行
            obj.executeNamedEntity = 0;
            //console.log('execute NER');
            this.props.dispatch({ type: "initNamedEntity", data: { handledDataToNameEntity: obj } });
            if (this.state.textcontent) {
              this.changeAction('4')
            }

          }
        });
      }
    } else {
      this.setState({
        textcontent: ''
      })
    }



    let tempToken = localStorage.getItem("token");
    let tempID = localStorage.getItem("id");
    if (tempToken) {
      let rateobj = localStorage.getItem(tempID);
      if (rateobj) {
        rateobj = JSON.parse(rateobj);
        let tempRateNum = rateobj['rateNumMask'];
        if (tempRateNum != undefined) {
          this.setState({
            rateNumMask: tempRateNum
          })
        }
      }
    } else {
      let tempNum = localStorage.getItem("rateNumMask");
      if (tempNum) {
        this.setState({
          rate: tempNum
        })
      }

    }


  }
  getTagList = (callback) => {
    let _this = this
    reqwest({
      url: '/tag/getList.htm',
      method: 'post',
      type: 'json',
      data: {},
      success: function (data) {
        if (data.code === 0) {
          _this.setState({
            tagList: data.list
          }, () => {
            _this._tagList = data.list
            callback && callback()
          })
        }
      }
    })
  }

  doTextMask = (source,rateNum,fildID,dataSou) => {
    let _this = this;
    if (_this.state.text_type == 'a') {
      _this.setState({
        visible5: true,
        complete: false,
        progress: 0
      })

      let tempContent = doText(source, this._ner_index)

      console.log('tempContent is:',tempContent);
      console.log('source is:',source);
      console.log('ner_index is:',this._ner_index);

      _this.setState({
        deal_textcontent: tempContent
      })
      var obj = {}
      obj.deal_textcontent = tempContent;
      obj.fildID = fildID;
      obj._ner_index = this._ner_index;
      obj.source = source;
      obj.rateNumMask = rateNum;
      obj.dataSou = dataSou;
      _this.props.dispatch({ type: "initNamedEntityDisposed", data: { handledData: obj } });
    }
  }

  search = (keyword) => {
    let _this = this

    if (!keyword) {
      return [];
    }
    let _textcontent = _this._textcontent
    let c = Array.from(_this._textcontent)
    let ks = _textcontent.split(keyword);

    let ps = []
    ks.reduce((t, item, index, arr) => {
      let st = t + Array.from(item).length
      let ed = st + Array.from(keyword).length
      t = ed
      ps.push([st, ed - 1])
      return t
    }, 0)

    // let c = Array.from(_this._textcontent)
    // // let c = _this._textcontent
    // let ws = Array.from(keyword)
    // let ps = this._indexOfText(ws[0], c)
    let searchResult = []
    for (let i = 0; i < ps.length; i++) {
      let r = ''
      let p = ps[i]
      let start = 0;
      let end = -1;
      if (ps.length > (i + 1)) {
        let pa = ps[i + 1];
        if (pa[0] - p[1] > 5) {
          end = p[1] + 5
        } else {
          end = pa[0]
        }
      } else {
        if (c.length - 1 - p[1] > 5) {
          end = p[1] + 5
        }
      }
      if (i > 0) {
        let pb = ps[i - 1]
        if (p[0] - pb[1] > 5) {
          start = p[0] - 5
        } else {
          start = pb[1]
        }
      } else {
        if (p[0] - 0 > 5) {
          start = p[0] - 5
        } else {
          start = 0
        }
      }
      if (end > -1) {
        r = c.slice(start, end)
      } else {
        // r = c.slice(start)
      }
      if (r) {

        let row = _this._loop(keyword, r.join(''), _this.state.currStyle)
        searchResult.push({
          key: i,
          content: row,
          position: p
        })
      }
    }
    return searchResult
  }


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  //将搜索到的文字描红处理
  _loop(value = '', str, customStyle) {
    // console.log(value)
    // console.log(str)
    let data = [];

    let lines = str.split('\n')
    if (!value) {
      return lines
    }
    let v = value

    lines.forEach(line => {
      let ar = [];
      let title = line.replace(new RegExp(value, "g"), '<*>' + value + '<*>')
      if (title === line) {
        title = title.replace(new RegExp(convert(value, this.locale), "g"), '<*>' + convert(value, this.locale) + '<*>');
        if (title !== line) {
          v = convert(value, this.locale)
        }
      }

      let nameArray = title.split('<*>');

      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i] === v) {
          ar.push(<span key={i} style={customStyle}>{v}</span>);
        } else {
          ar.push(nameArray[i]);
        }
      }
      data.push(ar)
    })
    return data
  }

  ban = () => {
    if (this.state.task_uid) {
    } else {
      if (!localStorage.getItem("token")) {
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴内容长度超过限制，目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      } else if (localStorage.getItem("token")) {
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴内容长度超过限制，目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      }
    }
  }

  render() {
    const { fileList } = this.state
    const props = {
      multiple: false,//不允许多文件上传
      onRemove: (file) => {
        let fs = fileList.filter(item => item.uid !== file.uid)
        this.setState({
          fileList: fs
        })
      },
      // fileList: fileList,
      accept: '.txt',
      beforeUpload: (file, files) => {
        let _this = this
        _this.setState({
          fileList: [file]
        })
        return new Promise((rs, re) => {
          let reader = new FileReader();
          reader.readAsText(file)
          reader.onload = (result) => {
            let content = result.currentTarget.result
            if (content) {
              if (content.length > 800) {
                message.warn('上传文件内容长度超过限制，目前只支持最大800字，已自动截取。')
                content = content.substring(0, 800)
              }
              _this.setState({ textcontent: content, task_uid: file.uid, task_identify: file.name + '_' + file.lastModified, editable: true }, () => {
                // _this.tagtext()
                _this._jd_deal_content_ner = ''
              })
            }
          }
        })
      }
    };
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        {
          key: 'odd',
          text: '选择偶数行',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: '选择奇数行',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: '反选',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              let y = this.state.selectedRowKeys.filter(it => it == key)
              if (y.length === 0) {
                return true
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    var reg = new RegExp("ο", "g")
    return (
      <Spin spinning={this.state.visible9}>
        <Content>

          <div className='content'>
            <div className='Acon'>
              <div className='Acon_L' style={{ "position": "relative" }}>
                <div className='Atop_l' style={{ "textAlign": "left", margin: "26px 0 10px", height: "32px" }}>
                  <Button className="left_btn" style={{
                    "background": "#096dd9",
                    fontFamily: "中粗",
                    "color": "white",
                    "marginBottom": "10px",
                    width: "90px", height: "32px", padding: "0",
                    borderRadius: "3px",
                    "border": "1px solid #096dd9"
                  }} icon={<UploadOutlined />}
                    onClick={() => {
                      if (!localStorage.getItem("token")) {
                        message.warning("暂不支持此功能！"); return
                      };
                      document.getElementById('upload').click()
                    }} >上传</Button>

                  <Button className="left_btn"
                    icon={<CaretRightOutlined />}
                    style={{
                      "background": " #096dd9",
                      borderRadius: "3px", fontFamily: "中粗",
                      "color": "white", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                      "border": "1px solid #096dd9",
                      // margin: '0px 0px 0 100px',
                    }}
                    onClick={e => {
                      if (this.state.textcontent) {
                        this.changeAction('4')
                      } else {
                        message.error('待处理文本不能为空')
                      }
                    }}
                    value='4' > {convert('运行', this.locale)}</Button>

                  <Button className="left_btn" style={{
                    // "background": " #096dd9",
                    "background": " white",
                    borderRadius: "3px", fontFamily: "中粗",
                    "color": "black", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                    "border": "1px solid #40a9ff"
                  }} icon={<DeleteOutlined />}
                    onClick={() => { this.setState({ textcontent: "" }) }}>
                    清空</Button>
                  <span style={{ float: 'right', color: "#7d7d7d", fontFamily: "中粗", display: this.state.rateVisile ? 'block' : 'none' }}>今日使用剩余<span style={{ color: "#C61414", fontWeight: 900 }}>{localStorage.getItem("token") ? this.state.rateNumMask : this.state.rate}</span>次</span>
                </div>
                <div className='Acon_Lco' hidden={this.state.action !== '1'}>
                  <div className='right'>
                    <FileTextOutlined style={{ fontSize: "90px", color: "#DDDDDD" }} />
                    <div className='ziwen'>
                      {convert("暂无文档，快来上传吧，支持格式txt", this.locale)}
                    </div>
                    <Upload {...props} id="upload">
                      <Button icon={<UploadOutlined />}>上传文件</Button>
                    </Upload>
                  </div>
                </div>

                <div className='right right_A' style={{ "padding": "0" }} hidden={this.state.action === '1'}>
                  <Input.TextArea onChange={e => {
                    this.setState({
                      textcontent: e.target.value
                    })
                  }} //disabled={!this.state.editable} 
                    placeholder={convert('粘贴待处理文本或点击左上角上传文件')} style={{ minHeight: this.state.deskHeight - 300, fontSize: 18, background: "none", borderRadius: "0px", lineHeight: "1.8" }} value={this.state.textcontent}
                    // onPressEnter={e => {
                    //   if (this.state.task_uid) {
                    //     this.tagtext()
                    //   } else {
                    //     this.setState({
                    //       visible7: true
                    //     })
                    //   }
                    // }}
                    onKeyUp={() => { this.ban() }}
                    onMouseMove={() => { this.ban() }}
                  />
                  <p style={{ fontSize: "12px", color: "black" }}>提示：仅支持UTF-8格式文本，建议≤800字/次，24次/24小时；为了更好的体验，建议字体：<a style={{ color: "#096dd9" }} href="http://fonts.jp/hanazono/" target="blank">花园明朝体</a>
                    {/* <span className="closethis" style={{
                    fontSize: "12px",
                    textAlign: "right", lineHeight: "32px"
                  }}>
                    <span className="_closethis" style={{ float: "right" }} onClick={() => { this.closethis() }}>关闭</span>
                  </span> */}
                    <span style={{ float: "right", marginRight: "20px" }}>当前字数：{this.state.textcontent.length}/{!localStorage.getItem("token") ? 800 : 800}</span>
                  </p>
                </div>

              </div>
            </div>
          </div >

          {/* <Modal
            // className='uioio11'
            title="开始新任务"
            visible={this.state.visible7}
            onOk={() => {
              this.setState({
                visible7: false
              }, () => {
                this.tagtext()
              })
            }}
            onCancel={() => {
              this.setState({
                visible7: false
              })
            }}
            okText="确定"
            cancelText="取消"
            destroyOnClose={true}
          >
            <Input placeholder={convert('请输入任务名称')} onChange={e => {
              this.setState({
                task_uid: e.target.value,
                task_identify: e.target.value
              })
            }}></Input>
          </Modal> */}
          <Modal
            className='qazxc456'
            width={815}
            visible={this.state.visible1}
            onOk={() => {
              this.setState({
                visible1: true
              })
            }}
            onCancel={() => {
              this.setState({
                visible1: false
              })
            }}
            footer={null}
            destroyOnClose={true}
          >
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane className='key1' tab={convert('总览', this.locale)} key="1">
                {/* <div className='top'>
                <div><span>{convert('类别', this.locale)}</span><span><span>{convert('去重', this.locale)}</span><span>{convert('总计', this.locale)}</span></span></div>
                <div><span style={{ color: "#D54444" }}>{convert('人名', this.locale)}</span><span><span>3258</span><span>4937</span></span></div>
                <div><span style={{ color: "#5FAB48" }}>{convert('地名', this.locale)}</span><span><span>325</span><span>493</span></span></div>
                <div><span style={{ color: "#487FAB" }}>{convert('时间', this.locale)}</span><span><span>3258</span><span>4937</span></span></div>
                <div><span style={{ color: "#42BDA8" }}>{convert('职官', this.locale)}</span><span><span>325</span><span>493</span></span></div>
              </div>
              <div className='footer'>
                <Button>{convert('下载', this.locale)}</Button>
              </div> */}
                <Table columns={this.columns1} size='small' dataSource={this.state.statisticsList}></Table>

              </TabPane>
              <TabPane className='key2' tab={convert('图表', this.locale)} key="2">
                <Row gutter={[16, 16]} id='doc'>
                  {/* <div><span>{convert('实体', this.locale)}</span><span><span>{convert('频次', this.locale)}</span><span>{convert('类型', this.locale)}</span></span></div> */}
                  <Col span={12}><Table pagination={false} rowKey='id' columns={this.columns2} size='small' dataSource={this.state.keywordStatisticsList} style={{
                    height: 400,
                    overflowY: 'auto'
                  }}></Table></Col>
                  <Col span={12}>{this.state.keywordStatisticsList.length > 0 ?
                    <DemoWordCloud data={this.state.keywordStatisticsList} /> : <Empty />}</Col>
                  {/* <div className='list'>
                  <div><span style={{ color: "#D54444" }}>{convert('李太白', this.locale)}</span><span><span>12</span><span>person</span></span></div>
                  <div><span style={{ color: "#D54444" }}>{convert('李太白', this.locale)}</span><span><span>12</span><span>person</span></span></div>
                  <div><span style={{ color: "#D54444" }}>{convert('李太白', this.locale)}</span><span><span>12</span><span>person</span></span></div>
                  <div><span style={{ color: "#487FAB" }}>{convert('宁夏', this.locale)}</span><span><span>1</span><span>location</span></span></div>
                  <div><span style={{ color: "#487FAB" }}>{convert('宁夏', this.locale)}</span><span><span>1</span><span>location</span></span></div>
                  <div><span style={{ color: "#487FAB" }}>{convert('宁夏', this.locale)}</span><span><span>1</span><span>location</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                  <div><span style={{ color: "#42BDA8" }}>{convert('中书令', this.locale)}</span><span><span>2</span><span>officer</span></span></div>
                </div> */}

                </Row>

                <div className='footer'>
                  <Button onClick={() => {
                    this.download('pdf', 'doc')
                  }}>{convert('下载', this.locale)}</Button>
                </div>
              </TabPane>
              <TabPane className='key3' tab={convert('管理', this.locale)} key="3">
                {/* <div className='top'>
                <div><span>{convert('类别', this.locale)}</span><span>{convert('操作', this.locale)}</span></div>
                <div><span>{convert('人名', this.locale)}</span><div><span><DeleteOutlined />{convert('删除', this.locale)}</span><span><EyeOutlined />{convert('显示', this.locale)}</span></div></div>
                <div><span>{convert('地名', this.locale)}</span><div><span><DeleteOutlined />{convert('删除', this.locale)}</span><span><EyeOutlined />{convert('显示', this.locale)}</span></div></div>
                <div><span>{convert('时间', this.locale)}</span><div><span><DeleteOutlined />{convert('删除', this.locale)}</span><span><EyeInvisibleOutlined />{convert('隐藏', this.locale)}</span></div></div>
                <div><span>{convert('职官', this.locale)}</span><div><span><DeleteOutlined />{convert('删除', this.locale)}</span><span><EyeOutlined />{convert('显示', this.locale)}</span></div></div>
              </div> */}
                <div><Table columns={this.columns3} size='small' dataSource={this.state.tagList} rowKey='id'></Table></div>
                {/* <div className='footer'>
                <Button>{convert('下载', this.locale)}</Button>
              </div> */}
              </TabPane>
            </Tabs>
          </Modal>
          <Modal
            className='gnl2'
            width={400}
            visible={this.state.visible2}
            onOk={() => {
              this.setState({
                visible2: true
              })
            }}
            onCancel={() => {
              this.setState({
                visible2: false
              })
            }}
            footer={null}
          >
            <span>该功能暂未开通，敬请期待！</span>
          </Modal>
          <Modal
            className='History'
            width={815}
            title={convert("历史修订", this.locale)}
            visible={this.state.visible3}
            onOk={() => {
              this.setState({
                visible3: true
              })
            }}
            onCancel={() => {
              this.setState({
                visible3: false
              })
            }}
            footer={null}
          >
            <div className='Hislist'>
              {
                this.state.versionList.length > 0 ? this.state.versionList.map((item, index) => {
                  return <div className='Hisitem' key={index}>
                    <div>
                      <span>{convert(`${item.identify}`, this.locale)}<span>（:{`${item.action}`}）</span></span>
                      <div>
                        <div>
                          <Button type='link' onClick={() => {
                            this.setState({
                              task_uid: item.uid,
                              task_identify: item.identify,
                              textcontent: item.jd_deal_content && item.jd_deal_content.deal_content && item.jd_deal_content.deal_content.join(''),
                              visible3: false,
                            }, () => {
                              this.props.dispatch(set_deal_content(item.uid, item.content, item.identify, item.jd_deal_content, item.mm_deal_content))
                              message.success('已还原')
                            })
                          }}><Backs />{convert(`还原`, this.locale)}</Button>
                        </div>
                        <Switch defaultChecked onChange={onChangeSwitch} />
                      </div>
                    </div>
                    <div>
                      <span>{convert(`当前操作用户`, this.locale)}</span>
                      <span>{convert(`${item.time}`, this.locale)}</span>
                    </div>
                  </div>
                }) : <Empty />
              }
            </div>
          </Modal>
          <Modal
            className='qwew789r'
            width={815}
            title={convert("批量修改", this.locale)}
            visible={this.state.visible4}
            onOk={() => {
              this.setState({
                visible4: false,
                visible8: false
              })
            }}
            onCancel={() => {
              this.setState({
                visible4: false,
                visible8: false
              })
            }}
            footer={null}
            destroyOnClose={true}
          >
            <div className='top'>
              <Search
                value={this.state.keyword}
                className='Search'
                placeholder={convert('请输入关键字，在本站中检索…', this.locale)}
                enterButton={convert('', this.locale)}
                size="large"
                enterButton="发现"
                onChange={e => {
                  this.setState({
                    keyword: e.target.value,
                    currStyle: {
                      backgroundColor: 'red',
                      color: '#FFFFFF',
                      border: '1px solid red',
                      fontSize: 20,
                    }
                  })
                }}
                onSearch={value => {
                  let searchResult = this.search(value)
                  this.setState({
                    searchResult: searchResult,
                    selectedRowKeys: []
                  })
                }}
              />
            </div>
            <Table columns={[{
              title: '',
              dataIndex: 'content',
              key: 'content',
            }]} rowSelection={rowSelection}
              style={{ height: 340, overflowY: 'auto' }}
              dataSource={this.state.searchResult} pagination={false}></Table>

            <div className='anniu'>
              <Button className='tih' onClick={() => {
                this.deleteTextTag()
              }}>{convert('删除标记', this.locale)}</Button>
              <Popover overlayClassName='awq23' visible={this.state.visible8} title={convert('选择标记方式', this.locale)} placement="topRight" content={
                <div>
                  <Radio.Group onChange={this.onChangeRa} value={this.state.addTagtype}>
                    <Radio value={'2'} key="2">
                      <div style={{ display: 'flex', alignItems: "center" }}>
                        {convert('選擇已存在的標記', this.locale)}
                      </div>
                    </Radio>
                    {this.state.addTagtype === '2' &&
                      // <Select style={{ width: 140 }} placeholder='请选择标记类型' onChange={e => {
                      //   this.setState({
                      //     tag_key: e
                      //   })
                      // }}> {
                      //   this.state.tagList.map((item, index) =>
                      //     <Select.Option style={item.styles[item.styles.length - 1]}
                      //       key={item.tag_key} value={item.tag_key}>
                      //       {item.tag_name}</Select.Option>)
                      //   }</Select>
                      <Radio.Group onChange={this.onChange1.bind(this)}
                        style={{ "padding-left": "25px" }}
                        value={this.state.tag_key}>
                        {this.state.tagList.map((item, index) =>
                          <Radio style={{ "width": "30%", "float": "left" }}
                            key={item.tag_key} value={item.tag_key}>
                            {item.tag_name}</Radio>)}
                      </Radio.Group>
                    }
                    <Radio value={'1'} key="1">
                      <div style={{ display: 'flex', alignItems: "center" }}>
                        {convert('新建标识', this.locale)}
                      </div>
                    </Radio>
                    <div style={{ display: 'flex', width: "230px", "padding-left": "25px" }}>
                      <Input placeholder={convert('标签标识', this.locale)} onChange={e => {
                        this.setState({
                          tag_key: e.target.value
                        })
                      }} />
                      <Input placeholder={convert('标签名称', this.locale)} onChange={e => {
                        this.setState({
                          tag_name: e.target.value
                        })
                      }} />
                      <Popover overlayClassName='sectcolors' placement="top" content={<SketchPicker color={this.state.color} onChange={this.handleColorChange} />} trigger="click">
                        <div style={{ height: 32, width: 70, padding: "5px", border: "1px solid #D7D9DC", borderLeft: "0" }}>
                          <div style={{ backgroundColor: this.state.color }} className='colorsect'>
                          </div>
                        </div>
                      </Popover>
                    </div>
                    <div style={{ "padding-left": "25px", fontSize: '12px', color: "#999999" }}>{convert('（请输入6个汉字以内的标签名并选择颜色）', this.locale)}</div>
                  </Radio.Group>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className='tih' onClick={() => {
                      this.setState({
                        visible8: false
                      })
                    }}>{convert('取消', this.locale)}</Button>
                    <Button className='tih' onClick={() => {
                      let selectedRowKeys = this.state.selectedRowKeys
                      if (selectedRowKeys.length === 0) {
                        message.error('请至少选择一项')
                        return;
                      }

                      let ns = selectedRowKeys.reduce((total, item) => {
                        let a = this.state.searchResult.find(t => t.key === item)
                        if (a) {
                          total.push(a.position)
                        }
                        return total
                      }, [])  //选中的位置
                      if (this.state.addTagtype === '1') {
                        this.addTag((tag_key) => {
                          this.addTextTag(tag_key, ns)
                        })
                      } else {
                        this.addTextTag(this.state.tag_key, ns)
                      }
                      this.setState({
                        visible8: false
                      })
                    }}>{convert('确定', this.locale)}</Button>
                  </div>
                </div>
              } trigger="click">
                <Button className='bc' onClick={() => {
                  this.setState({
                    visible8: true
                  })
                }}>{convert('标记', this.locale)}</Button>
              </Popover>

            </div>
          </Modal>
          <Modal
            className='uioio12'
            width={400}
            visible={this.state.visible5}
            onOk={() => {
              this.setState({
                visible5: true
              })
            }}
            onCancel={() => {
              this.setState({
                visible5: false
              })
            }}
            footer={null}
            closable={false}
          >
            <img src={loading}></img>
            <span style={{ color: "white" }}>正在努力加载，请耐心等待！</span>
            {/* <Progress strokeColor="#3AA4CE" type="circle" percent={this.state.progress} format={percent => `${percent / 5} 秒`} />
          <span>标注处理中，预计20s请耐心等待～</span> */}
            {/* <Button>暂停</Button> */}
          </Modal>
        </Content>
      </Spin>
    );
  }
}
function mapStateToProps(state) {
  return {
    dealTextContent: state.dealTextContent,
    handledDataToNameEntity: state.handledDataToNameEntity,
    handledData: state.handledData
  }
}
export default withRouter(connect(mapStateToProps)(NamedEntity));
