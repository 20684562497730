import Immutable from 'immutable';

let initState = { isLogging: false, login: 0, goToUrl: '/', networksegment: [] }
export function loginReducer(state = initState, action) {
    let fromjs = Immutable.fromJS(state);
    switch (action.type) {
        case 'get_login_success': {
            fromjs = fromjs.set('login', Immutable.fromJS(action.payload.login))
            if (!!action.payload.goToUrl) {
                fromjs = fromjs.set('goToUrl', action.payload.goToUrl)
            }
            return fromjs.toJS();
        }
        case 'set_networkSegment': {
            if (!!action.payload.networksegment) {
                fromjs = fromjs.set('networksegment', Immutable.fromJS(action.payload.networksegment))
            }
            return fromjs.toJS();
        }
        default:
            break;
    }
    return state;
}