import React, { Component } from 'react';
import { Layout, Form, Tabs, Space, List, Typography, Table, Image, Button, Row, Col, Input, message } from 'antd';
import '../App.css';
import '../css/a.css';
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request'
import * as cont from '../config/constant'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal';
import user2 from '../images/user2.png';
import userInfo from '../images/userInfo.png';
import reqwest from 'reqwest'
class MyFiles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nameBorder: false,
            nameDisabled: true,
            setUpBorder: false,
            setUpDisabled: true,
            nameValue: '',
            setUpValue: '',
        }
    }
    onNameChange = e => {
        let value = e.target.value;
        console.log(value);
        this.setState({
            nameValue: value
        })
    };
    updateName = () => {
        let nameBtn = document.getElementsByClassName("nameBtn")[0];
        if (nameBtn.innerText == '修改昵称') {
            this.setState({
                nameBorder: true,
                nameDisabled: false
            })
            nameBtn.innerText = '保存';
        } else if (nameBtn.innerText == '保存') {
            this.setState({
                nameBorder: false,
                nameDisabled: true,
            });
            nameBtn.innerText = '修改昵称'
            let name = this.state.nameValue;
            console.log(name);
            console.log(localStorage.getItem("id"));
            // 保存
            reqwest({
                url: '/wyd/auth/updateName',
                method: 'post',
                type: 'json',
                headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
                data: { name: name, userId: localStorage.getItem("id") },
                success: (data) => {
                    if (data.code == 200) {
                        message.success("保存成功！")
                    } else {
                        message.error("保存失败！")
                    }
                }, error: (err) => {
                    // message.error(err.status + ':' + err.statusText)
                    message.error("保存失败！")
                }
            })
        }

    }
    onSetUpChange = e => {
        let value = e.target.value;
        console.log(value);
        this.setState({
            setUpValue: value
        })
    };
    updateSetup = () => {
        let setupBtn = document.getElementsByClassName("setupBtn")[0];
        if (setupBtn.innerText == '设 置') {
            this.setState({
                setUpBorder: true,
                setUpDisabled: false
            })
            setupBtn.innerText = '保存';
        } else if (setupBtn.innerText == '保存') {
            this.setState({
                setUpBorder: false,
                setUpDisabled: true,
            });
            setupBtn.innerText = '设置';
            let value = this.state.setUpValue;
            console.log(value);
            // 保存
            reqwest({
                url: '/wyd/auth/updateShows',
                method: 'post',
                type: 'json',
                headers: { "WYD-TOKEN": localStorage.getItem("token") == null ? "" : localStorage.getItem("token") },
                data: { shows: value, userId: localStorage.getItem("id") },
                success: function (data) {
                    if (data.code == 200) {
                        message.success("保存成功！")
                    } else {
                        message.error("保存失败！")
                    }
                }, error: (err) => {
                    // message.error(err.status + ':' + err.statusText)
                    message.error("保存失败！")
                }
            })
        }
    }
    render() {
        const { nameBorder, nameDisabled, setUpBorder, setUpDisabled } = this.state;
        const text = ("zhangsan");
        return (<>
            <div className="message">
                <div className="mestitle">
                    <div className="mesLeft"><span><Image src={userInfo} width={"40px"} /> </span></div>
                    <div className="mesRig">
                        <span>个人资料</span>
                    </div>
                </div>

                <div className="mesContent">
                    {/* <div className="avatar">
                        <Row justify="space-around" align="middle" gutter={[16, 16]}>
                            <Col span={2} className="text">头像设置</Col>
                            <Col span={14}>col-8</Col>
                            <Col span={8} >
                                <Button>修改头像</Button>
                                <Modal>

                                </Modal>
                            </Col>
                        </Row>
                    </div> */}
                    <div className="avatar">
                        <Row justify="space-around" align="middle" gutter={[16, 16]}>
                            <Col span={2} className="text">昵称</Col>
                            <Col span={10} className='col14'><Input bordered={nameBorder} defaultValue={localStorage.getItem("name")} disabled={nameDisabled} className="name"
                                onChange={this.onNameChange} style={{ color: "black" }} ></Input></Col>
                            <Col span={8}><Button onClick={this.updateName} className="nameBtn">修改昵称</Button></Col>
                        </Row>
                    </div>
                    <div className="avatar">
                        <Row justify="space-around" align="middle" gutter={[16, 16]}>
                            <Col span={2} className="text">个人简介</Col>
                            <Col span={10} className='col14'><Input bordered={setUpBorder} defaultValue={localStorage.getItem("shows")} disabled={setUpDisabled} className="setup"
                                onChange={this.onSetUpChange} style={{ color: "black" }} ></Input></Col>
                            <Col span={8}><Button onClick={this.updateSetup} className="setupBtn">设置</Button></Col>
                        </Row>
                    </div>
                    <div className="avatar">
                        <Row justify="space-around" align="middle" gutter={[16, 16]}>
                            <Col span={2} className="text">登录密码</Col>
                            <Col span={10} className='col14'><Input bordered={false} defaultValue={"******"} disabled style={{ color: "black" }}></Input></Col>
                            <Col span={8}><Link to="/forgetpwd" onClick={(e) => {
                                sessionStorage.setItem('person', '/personal');
                            }}>修改</Link></Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
export default withRouter(MyFiles);