import React, { Component } from 'react';
import {
  Layout, Popover, Button, Upload, Modal,
  Progress, Input, message, Select, Switch
} from 'antd';
import './BiaoDian.css';
import convert from '../../utils/locale_zh_convert';
import { UploadOutlined, DeleteOutlined, FileTextOutlined, CaretRightOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom'
import BraftEditor from 'braft-editor';
import reqwest from 'reqwest'
import { saveAs } from 'file-saver';
import { jsPDF, } from 'jspdf';
import html2canvas from 'html2canvas'
import 'braft-editor/dist/index.css';
import { set_deal_content } from './../../actions/DealTextContentAction'
import { connect } from 'react-redux';
import imgurl1 from "../../images/u110.svg"
import imgurl2 from "../../images/u31.svg"
import loading from "../../images/loading.svg"

const ButtonGroup = Button.Group;
const { Content } = Layout;
const { Option } = Select;

function handleChange(value) {
  let ziwen = document.getElementsByClassName("content")[0]
  ziwen.style.fontSize = value + "px"
}
function handleChange1(value) {
  console.log(value)
}
class BiaoDian extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
    let content = ''
    // if (props.dealTextContent && props.dealTextContent.content) {
    //   content = props.dealTextContent.content.join('')
    // } else if (props.dealTextContent && props.dealTextContent.jd_deal_content && props.dealTextContent.jd_deal_content.content) {
    //   content = props.dealTextContent.jd_deal_content.content.join('')
    // }
    console.log(props)
    this.state = {
      count: 18,
      show: true,
      fildID: "",
      edit: false,
      imgurl: imgurl2,
      imgstate: true,
      strongstate: true,
      visible1: false,
      visible2: false,
      fileList: [],
      type: 1,//1,上传文件, 2:输入文本,
      textcontent: content, //处理前文本
      deal_textcontent: this.props.dealTextContent && this.props.dealTextContent.jd_deal_content && this.props.dealTextContent.jd_deal_content.deal_content ? this.props.dealTextContent.jd_deal_content.deal_content : [],//处理后文本
      progress: 0,
      complete: false,
      action: 'b',
      task_uid: this.props.dealTextContent ? this.props.dealTextContent.uid : '',
      task_identify: this.props.dealTextContent ? this.props.dealTextContent.identify : '',
      inputValue: 0,
      times: 10,
      max_num: 128,
      search_type: "fast",
      todoHandleData: this.props.todoHandleData,//从识别过来要进行标点的文本
      deskHeight: document.body.clientHeight,
      rate: !localStorage.getItem("token") ? 12 : 20,
      rateNum: 20,
      rateVisile: this.props.duData ? this.props.duData.rateNum : false,
      jubiaoFlag:1,
      placeholder_key:false,
      // rateNumber:!localStorage.getItem(localStorage.getItem("token"))
    };

    this._textcontent = ''////处理前文本缓存
    this._deal_textcontent = ''////处理后文本缓存
    this.sl__deal_textcontent = []
    this._biodian_index = null////处理后标点缓存
    this.lastEditRange = {}
  }

  showModalM1 = () => {
    this.setState({
      visible1: true,
    });
  };
  strong = () => {
    let ziwen = document.getElementsByClassName("content")[0]
    this.setState({
      strongstate: !this.state.strongstate
    })
    if (this.state.strongstate == true) {
      ziwen.style.fontWeight = "900"
    } else {
      ziwen.style.fontWeight = "400"
    }

  }
  handleOkM1 = e => {
    this.setState({
      visible1: false,
    });
  };

  handleCancelM1 = e => {
    this.setState({
      visible1: false,
    });
  };

  showModalM2 = () => {
    this.setState({
      visible2: true,
    });
  };

  handleOkM2 = e => {
    this.setState({
      visible2: false,
    });
  };

  handleCancelM2 = e => {
    this.setState({
      visible2: false,
    });
  };

  componentWillMount() {
    let number = localStorage.getItem("number")
    if (number == 1) {
      localStorage.setItem("number", 2)
      window.location.reload()
    }
  }

  jd = (textcontent, callback) => {
    this.setState({
      show2: true
    })

    let _this = this
    if (!textcontent) {
      message.error('待处理文本不能为空')
      return
    } else {
      // if(!textcontent.content || textcontent.content.length==0){
      if (textcontent.content && textcontent.content.length == 0 ||
        (textcontent.deal_textcontent || (textcontent.deal_textcontent && textcontent.deal_textcontent.length == 0)) ||
        textcontent.fildID) {
        return;
      }
    }
    _this.setState({
      visible2: true,
      complete: false,
      progress: 0,
      showstate: this.state.search_type == "fast" ? 1 : 2
    }, () => {
      _this.progressRun()

      let sentence = textcontent
      //var reg = new RegExp(/〔/g, "g")
      //sentence = sentence.replace(/(\r\n\r\n)+/g,'◉')
      //sentence = sentence.replace(/\s+/g, '')

      //sentence = sentence.replace(/\r/g,'')
      //sentence = sentence.replace(/\n\n+/g,'\n')
      //sentence = sentence.replace(/\r\n/g,'◉')
      //sentence = sentence.replace(/◉{2,}/g,'◉')
      //sentence = sentence.replace(/\s+/g, '')
      //sentence = sentence.replace(/◉{2,}/g,'◉')
      sentence = sentence.replace(/〔/g,'[')
      sentence = sentence.replace(/〕/g,']')
      sentence = sentence.replace(/\r\n/g,'')
      sentence = sentence.replace(/\s+/g,'')
      console.log('sentence is',sentence)
      console.log('token is',localStorage.getItem("token"))
      // ,exact
      if (this.state.search_type == "fast") {
        console.log('fast')

        var reqXhr = new XMLHttpRequest();
        var form = new FormData();
        form.append("sentence", sentence)
        reqXhr.open('POST', '/wyd/predict/split', false);
        reqXhr.responseType = 'json';
        reqXhr.setRequestHeader("WYD-TOKEN", localStorage.getItem("token") == null ? "" : localStorage.getItem("token"))
        console.log(reqXhr.getResponseHeader('rate-1d'));
        reqXhr.onload = function () {
          console.log('response is',this.response);
          console.log(reqXhr.getResponseHeader('rate-1d'));
          let data = JSON.parse(this.response);
          //console.log('data is',data);
          if (data.code == 200) {
            // 获取响应头
            //一定要在这了清空，不然会崩
            document.getElementsByClassName("content")[0].innerHTML = ""
            let content = data.data.content
            // let content = data.data.source
            let biodian = data.data.index
            _this._deal_textcontent = content
            _this.setState({
              textcontent: data.data.source
            })
            let arr = []
            for (let i = 0; i < _this._deal_textcontent.length; i++) {
              arr.push([_this._deal_textcontent[i].split("")])
            }
            console.log(arr)
            // _this.sl__deal_textcontent = _this._deal_textcontent.split("")
            _this.sl__deal_textcontent = arr
            console.log(_this.sl__deal_textcontent.length)
            _this._biodian_index = biodian
            _this._textcontent = _this.state.textcontent
            let fildID = data.data.id;
            // _this.doText(_this.state.action)

            let obj = {};
            obj._deal_textcontent = _this._deal_textcontent;
            obj.sl__deal_textcontent = _this.sl__deal_textcontent;
            obj.fildID = fildID;

            var rate = reqXhr.getResponseHeader('rate-1d');
            if (rate) {
              obj.rateNum = true
              let arrRate = rate.split("/");
              console.log(arrRate[0]);
              let rateNum = arrRate[1] - arrRate[0];
              let tempToken = localStorage.getItem("token");
              let tempID = localStorage.getItem("id");
              if (tempToken) {
                let rateobj = localStorage.getItem(tempID);
                if (!rateobj) {
                  rateobj = {};
                } else {
                  rateobj = JSON.parse(rateobj);
                }
                rateobj['rateNum'] = rateNum;
                rateobj = JSON.stringify(rateobj);
                localStorage.setItem(tempID, rateobj);

              } else {
                // _this.setState({rateVisile : false})
                localStorage.setItem("rateNum", rateNum);
              }
            }
            _this.props.dispatch({ type: "initAutomaticDuDisposed", data: { duData: obj } });
            if (_this.state.jubiaoFlag){
              _this.props.history.push('/BiaoDianDisposed') //句读、标点切换
            } else {
              _this.props.history.push('/AutomaticDuDisposed') //句读、标点切换
            }
          } else if (data.code == 500) {
            message.error(data.message)
          }
          callback && callback()
          _this.setState({
            visible2: false,
            complete: true,
          })

        }
        reqXhr.send(form);

      } else {
        reqwest({
          url: '/wyd/predict/punctuation',
          method: 'post',
          type: 'json',
          headers: { "WYD-TOKEN": localStorage.getItem("token") },
          data: { sentence: sentence },
          success: function (data) {
            if (data.code == 200) {
              let content = data.data.content
              let biodian = data.data.index
              _this._deal_textcontent = content
              _this.sl__deal_textcontent = _this._deal_textcontent.split("")
              _this._biodian_index = biodian
              _this._textcontent = textcontent
              _this.setState({
                fildID: data.data.id
              })
              // _this.doText(_this.state.action)
            } else if (data.code == 500) {
              message.error(data.message)
            }
            callback && callback()
            _this.setState({
              visible2: false,
              complete: true,
            })
          }, error: (err) => {
            _this.setState({
              visible2: false,
              complete: true,
            }, () => {
              let mes = JSON.parse(err.response)
              message.error(mes.message)
            })
          }
        })
      }

    })

  }
  componentDidMount() {
    // this.getLastVersion(2)
    // this.props.dispatch({ type: "currentPage", data: { currentPage: "AutomaticDu" } });

    let todoHandleAutomaticDuFlag = this.props.todoHandleData.flag;
    if (todoHandleAutomaticDuFlag == 1) {

      let todoHandleData = this.props.todoHandleData;
      todoHandleData.flag = 0;
      this.props.dispatch({ type: "todoHandleAutomaticDu", data: { todoHandleData: todoHandleData } });
      this.jd(this.props.todoHandleData.content);
    }

    let tempToken = localStorage.getItem("token");
    let tempID = localStorage.getItem("id");
    if (tempToken) {
      let rateobj = localStorage.getItem(tempID);
      if (rateobj) {
        rateobj = JSON.parse(rateobj);
        let tempRateNum = rateobj['rateNum'];
        if (tempRateNum != undefined) {

          this.setState({
            rateNum: tempRateNum
          })
        }
      }
    } else {
      let tempNum = localStorage.getItem("rateNum");
      if (tempNum) {
        this.setState({
          rate: tempNum
        })
      }

    }


  }
  getLastVersion = (action_order) => {
    let _this = this;
    reqwest({
      url: '/text/getLastVersion.htm',
      method: 'get',
      type: 'json',
      data: { action_order: action_order },
      success: function (data) {
        if (data.code === 0) {
          if (data.item && !_this.state.textcontent) {
            Modal.confirm({
              onOk: close => {
                let content = ''
                if (data.item.jd_deal_content && data.item.jd_deal_content.content) {
                  content = data.item.jd_deal_content.content.join('')
                } else if (data.item && data.item.content) {
                  content = data.item.content.join('')
                }
                _this.setState({
                  task_uid: data.item.uid,
                  textcontent: content,
                  // deal_textcontent: data.item.jd_deal_content ? data.item.jd_deal_content : [],
                  task_identify: data.item.identify
                }, () => {
                  let jd_deal_content = data.item.jd_deal_content
                  _this.props.dispatch(set_deal_content(data.item.uid, data.item.content, data.item.identify, data.item.jd_deal_content, data.item.mm_deal_content))
                  if (jd_deal_content && Object.keys(jd_deal_content).length > 0) {
                    let content = jd_deal_content.content.join('')
                    let ner_index = jd_deal_content.ner_index
                    _this._deal_textcontent = jd_deal_content.deal_content ? jd_deal_content.deal_content : []
                    _this._textcontent = content
                    _this._biodian_index = ner_index
                    _this.doText(_this.state.action)
                  } else {
                    _this._textcontent = data.item.content
                  }

                })
                close()
              },
              onCancel: close => {
                close()
              },
              content: `检测到存在已经经过${data.item.action}处理的历史文本数据,是否载入?`,
              okText: '确定',
              cancelText: '取消',
            })
          }

        } else if (data.code === 1) {
          message.error(data.errmsg ? data.errmsg : '未知错误')
        }
      }, error: (err) => {
        _this.setState({
          versionList: []
        })
        // message.error(err.status + ':' + err.statusText)
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
  }
  doText = (type) => {
    let _this = this
    let _deal_textcontent = _this._deal_textcontent
    if (!_deal_textcontent) {
      return
    }
    let _biodian_index = _this._biodian_index


    let arr = []
    for (var key in _biodian_index) {
      arr = [...arr, ..._biodian_index[key]]
      // _deal_textcontent = _deal_textcontent.replace(new RegExp(`${key}`, "g"), '')
    }
    arr.sort((a, b) => {
      return a[0] - b[0]
    })

    let conten = _this._textcontent
    let c = Array.from(conten)
    let dt = arr.map((item, index) => c.slice(item[0], item[1] + 1).join(''))

    if (type === 'b') {
      _this.setState({
        deal_textcontent: [dt.join("ο")]
        // deal_textcontent: [dt.join("<span className={{'ccc'}}>ο</span>")]
      })
    } else {
      _this.setState({
        deal_textcontent: dt
      })
    }

    let uid = _this.state.task_uid
    let identify = _this.state.task_identify
    let origin_content = this.props.dealTextContent && this.props.dealTextContent.content
      && this.props.dealTextContent.content.length > 0 ? this.props.dealTextContent.content : [_this.state.textcontent]
    let jb_deal_content = {
      ner_index: _biodian_index,
      content: [_this.state.textcontent],
      deal_content: [dt.join("ο")],
      // deal_content: [dt.join("<span className={{'ccc'}}>ο</span>")],
      deal_content_ner: [_deal_textcontent],
    }
    _this.props.dispatch(set_deal_content(uid, origin_content, identify, jb_deal_content))
    _this.saveVersion(uid, origin_content, identify, '自动句读', jb_deal_content)


    // if (type === 'b') {

    //   _this.setState({
    //     deal_textcontent: [dt.join('〇')]
    //   })

    //   let uid = _this.state.task_uid
    //   let identify = _this.state.task_identify
    //   let origin_content = this.props.dealTextContent && this.props.dealTextContent.content
    //     && this.props.dealTextContent.content.length > 0 ? this.props.dealTextContent.content : [_this.state.textcontent]
    //   let jb_deal_content = {
    //     ner_index: _biodian_index,
    //     content: [_this.state.textcontent],
    //     deal_content: [dt.join('〇')],
    //     deal_content_ner: [_deal_textcontent],
    //   }
    //   _this.props.dispatch(set_deal_content(uid, origin_content, identify, jb_deal_content))
    //   _this.saveVersion(uid, origin_content, identify, '自动句读', jb_deal_content)
    // } else if (type === 'a') {

    // }
  }
  ban = () => {
    if (this.state.task_uid) {
    } else {
      if (!localStorage.getItem("token")) {
        //游客字数限制，800
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴文本目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      } else if (localStorage.getItem("token")) {
        //注册用户字数限制，800
        if (this.state.textcontent.length > 800) {
          message.warn('粘贴文本目前只支持最大800字，已自动截取。')
          this.setState({ textcontent: this.state.textcontent.substring(0, 800) })
        }
      }
    }

  }
  progressRun = () => {
    let _this = this
    if (!_this.state.complete) {
      if (_this.state.progress < 100) {
        _this.setState({ progress: _this.state.progress + 5 })
      }
      setTimeout(() => {
        _this.progressRun()
      }, 1000)
    }
  }
  //   关闭底部建议字体提示
  closethis = () => {
    let doc = document.getElementsByClassName("closethis")[0]
    doc.style.display = "none"
  }
  delet = () => {
    //  let doc = document.getElementsByClassName("content")[0]
    //  doc.innerHTML =""
    // let _this = this
    // this.sl__deal_textcontent =[]
    if (this.sl__deal_textcontent.length == 0) {
      message.warning("无内容！")
      return
    }
    this.setState({
      show2: false
    })
  }

  leftchange = (img) => {
    this.setState({
      imgstate: !img
    })
    let Acon_L = document.getElementsByClassName("Acon_L")[0]
    let Acon_R = document.getElementsByClassName("Acon_R")[0]
    if (this.state.imgstate == true) {
      Acon_L.style.width = "20%"
      Acon_R.style.width = "80%"
      this.setState({
        imgurl: imgurl1
      })
    } else {
      Acon_L.style.width = "50%"
      Acon_R.style.width = "50%"
      this.setState({
        imgurl: imgurl2
      })
    }
  }

  formatter(value) {
    console.log(this._deal_textcontent)
    let num = (128 + (256 - 128) * (value * 0.01)).toFixed()
    this.setState({
      max_num: num
    })
    //   console.log(this.state.deal_textcontent)
    //   let c = this.props.dealTextContent && this.props.dealTextContent.jd_deal_content && this.props.dealTextContent.jd_deal_content.deal_content ? this.props.dealTextContent.jd_deal_content.deal_content : []
    //    console.log(c[0].slice(0,num))

    //   this.setState({
    //     deal_textcontent:[c[0].slice(0,num)]
    //   })
    // return `${value}%`;
  }
  formatter1(value) {
    return `${value}%`;
  }

  saveVersion = (uid, content, identify, action, jb_deal_content) => {
    reqwest({
      url: '/text/saveVersion.htm',
      method: 'post',
      type: 'json',
      data: {
        uid: uid,
        content: content && JSON.stringify(content),
        identify: identify,
        action: action,
        jd_deal_content: jb_deal_content && JSON.stringify(jb_deal_content),
      },
      success: function (data) {
        if (data.code === 0) {
          message.success('已保存成功')
        } else if (data.code === 1) {
          message.error(data.errmsg ? data.errmsg : '未知错误')
        }
      }, error: (err) => {
        // message.error(err.status + ':' + err.statusText)
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
  }

  send = () => {
    // 获取编辑框对象
    var edit = document.getElementById('edit')
    // 获取输入框对象
    var emojiInput = document.getElementById('emojiInput')
    // 编辑框设置焦点
    edit.focus()
    // 获取选定对象
    var selection = getSelection().anchorNode.childNodes
    // 判断是否有最后光标对象存在
    if (this.lastEditRange) {
      // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
      selection.removeAllRanges()
      selection.addRange(this.lastEditRange)
    }
    // 判断选定对象范围是编辑框还是文本节点
    if (selection.anchorNode.nodeName != '#text') {
      // 如果是编辑框范围。则创建表情文本节点进行插入
      var emojiText = document.createTextNode(emojiInput.value)
      if (edit.childNodes.length > 0) {
        // 如果文本框的子元素大于0，则表示有其他元素，则按照位置插入表情节点
        for (var i = 0; i < edit.childNodes.length; i++) {
          // if (i == selection.anchorOffset) {
          edit.insertBefore(emojiText, edit.childNodes[i])
          // }
        }
      } else {
        // 否则直接插入一个表情元素
        edit.appendChild(emojiText)
      }
      // 创建新的光标对象
      var range = document.createRange()
      // 光标对象的范围界定为新建的表情节点
      range.selectNodeContents(emojiText)
      // 光标位置定位在表情节点的最大长度
      range.setStart(emojiText, emojiText.length)
      // 使光标开始和光标结束重叠
      range.collapse(true)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    } else {
      // 如果是文本节点则先获取光标对象
      var range = selection.getRangeAt(0)
      // 获取光标对象的范围界定对象，一般就是textNode对象
      var textNode = range.startContainer;
      // 获取光标位置
      var rangeStartOffset = range.startOffset;
      // 文本节点在光标位置处插入新的表情内容
      textNode.insertData(rangeStartOffset, emojiInput.value)
      // 光标移动到到原来的位置加上新内容的长度
      range.setStart(textNode, rangeStartOffset + emojiInput.value.length)
      // 光标开始和光标结束重叠
      range.collapse(true)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    }
    // 无论如何都要记录最后光标对象
    this.lastEditRange = selection.getRangeAt(0)
  }

  setting = () => {
    var selection = getSelection()
    this.setState({
      lastEditRange: selection.getRangeAt(0)
    })
  }

  random = () => {
    const count = Math.floor(Math.random() * 100);
    this.setState({ count });
  };

  onChange = show => {
    this.setState({ show });
  };

  JuduSwitch = (checked) => {
    if(checked) {
      this.setState({
        jubiaoFlag:0
      })
      console.log('Switch to 句读',this.state.jubiaoFlag);
    } else {
      this.setState({
        jubiaoFlag:1
      })
      console.log('Switch to 标点',this.state.jubiaoFlag);
    }

  };

  clear_text = () => {
    let pkey = !this.state.placeholder_key;
    this.setState({
      textcontent:"",
      placeholder_key:pkey,
    })
  }

  upload_text = () => {
    let pkey = !this.state.placeholder_key;
    this.setState({
      placeholder_key:pkey,
    })
  }

  loadExample = (value) => {
    if (value == '1'){
      let initLoad1 = '太祖啟運立極英武睿文神德聖功至明大孝皇帝諱匡胤姓趙氏涿郡人也高祖朓是為僖祖仕唐歷永清文安幽都令朓生珽是為順祖歷藩鎮從事累官兼御史中丞珽生敬是為翼祖歷營薊涿三州刺史敬生弘殷是為宣祖周顯德中宣祖貴贈敬左驍騎衞上將軍宣祖少驍勇善騎射事趙王王鎔為鎔將五百騎援唐莊宗于河上有功莊宗愛其勇留典禁軍漢乾祐中討王景於鳳翔會蜀兵來援戰于陳倉始合矢集左目氣彌盛奮擊大敗之以功遷護聖都指揮使周廣順末改鐵騎第一軍都指揮使轉右廂都指揮領岳州防禦使從征淮南前軍却吳人來乘宣祖邀擊敗之顯德三年督軍平揚州與世宗會壽春壽春賣餅家餅薄小世宗怒執十餘軰將誅之宣祖固諫得釋累官檢校司徒天水縣男與太祖分典禁兵一時榮之卒贈武清軍節度使太尉太祖宣祖仲子也母杜氏後唐天成二年生於洛陽夾馬營赤光繞室異香經宿不散體有金色三日不變既長容貌雄偉器度豁如識者知其非常人學騎射輙出人上嘗試惡馬不施銜勒馬逸上城斜道額觸門楣墜地人以為首必碎太祖徐起更追馬騰上一無所傷'
      this.setState({
        textcontent: initLoad1,
      })
    } else if (value == '2'){
      let initLoad2 = '寧宗沖聖嗣孝皇帝諱懿璘質班明宗第二子也母曰八不沙皇后乃蠻真氏初武宗有子二人長明宗次文宗延祐中明宗封周王出居朔漠泰定之際正統遂偏天曆元年文宗入紹大統內難既平即遣使奉皇帝璽綬北迎明宗明宗崩文宗復即皇帝位明宗有子二人長妥懽帖木耳次即帝也天曆二年二月乙巳封帝為鄜王至順三年八月己酉文宗崩于上都皇后導揚末命申固讓初志傳位於明宗之子時妥懽帖木耳出居靜江帝以文宗眷愛之篤留京師太師太平王右丞相燕鐵木兒請立帝以繼大統於是遣使徵諸王會京師中書百司政務咸啟中宮取進止甲寅中書省臣奉中宮旨預備大朝會賞賜金銀幣帛等物乙卯燕鐵木兒奉中宮旨賜駙馬也不干子歡忒哈赤太尉孛蘭奚句容郡王荅鄰荅里僉事小薛阿麻剌台之子禿帖木兒公主本荅里諸王丑漢妃公主台忽都魯諸王卯澤妃公主完者台及公主本荅里徹里帖木兒等金銀幣鈔有差是月渾源雲內二州隕霜殺禾冀寧路之陽曲河曲二縣及荊門州皆旱江水又溢高郵府之寶應興化二縣德安府之雲夢應城二縣大雨水'
      this.setState({
        textcontent:initLoad2,
      })
    } else if (value == '3'){
      let initLoad3 = '秋七月癸酉太祖自將救洪都癸未次湖口先伏兵涇江口及南湖觜遏友諒歸路檄信州兵守武陽渡友諒聞太祖至解圍逆戰於鄱陽湖友諒兵號六十萬聯巨舟為陣樓櫓高十餘丈綿亘數十里旌旂戈盾望之如山丁亥遇於康郎山太祖分軍十一隊以禦之戊子合戰徐達擊其前鋒俞通海以火礟焚其舟數十殺傷畧相當友諒驍將張定邊直犯太祖舟舟膠於沙不得退危甚常遇春從旁射中定邊通海復來援舟驟進水湧太祖舟乃得脫己丑友諒悉巨艦出戰諸將舟小仰攻不利有怖色太祖親麾之不前斬退縮者十餘人人皆殊死戰會日晡大風起東北乃命敢死士操七舟實火藥蘆葦中縱火焚友諒舟風烈火熾烟燄漲天湖水盡赤友諒兵大亂諸將鼓噪乘之斬首二千餘級焚溺死者無算友諒氣奪辛卯復戰友諒復大敗於是斂舟自守不敢更戰壬辰太祖移軍扼左蠡友諒亦退保渚磯相持三日其左右二金吾將軍皆降友諒勢益蹙忿甚盡殺所獲將士而太祖則悉還所俘傷者傅以善藥且祭其親戚諸將陣亡者八月壬戌友諒食盡趨南湖觜為南湖軍所遏遂突湖口太祖邀之順流搏戰及於涇江涇江軍復遮擊之友諒中流矢死'
      this.setState({
        textcontent:initLoad3,
      })
    } else if (value == '4') {
      let initLoad4 = '初鄭武公娶于申曰武姜生莊公及共叔段莊公寤生驚姜氏故名曰寤生遂惡之愛共叔段欲立之亟請於武公公弗許及莊公即位為之請制公曰制巖邑也虢叔死焉佗邑唯命請京使居之謂之京城大叔祭仲曰都城過百雉國之害也先王之制大都不過參國之一中五之一小九之一今京不度非制也君將不堪公曰姜氏欲之焉辟害對曰姜氏何厭之有不如早為之所無使滋蔓蔓難圖也蔓草猶不可除況君之寵弟乎公曰多行不義必自斃子姑待之既而大叔命西鄙北鄙貳於己公子呂曰國不堪貳君將若之何欲與大叔臣請事之若弗與則請除之無生民心公曰無庸將自及大叔又收貳以為己邑至于廩延子封曰可矣厚將得眾公曰不義不暱厚將崩大叔完聚繕甲兵具卒乘將襲鄭夫人將啟之公聞其期曰可矣命子封帥車二百乘以伐京京叛大叔段段入于鄢公伐諸鄢五月辛丑大叔出奔共'
      this.setState({
        textcontent:initLoad4,
      })
    } else if (value == '5') {
      let initLoad5 = '四年春齊侯以諸侯之師侵蔡蔡潰遂伐楚楚子使與師言曰君處北海寡人處南海唯是風馬牛不相及也不虞君之涉吾地也何故管仲對曰昔召康公命我先君大公曰五侯九伯女實征之以夾輔周室賜我先君履東至于海西至于河南至于穆陵北至于無棣爾貢包茅不入王祭不共無以縮酒寡人是徵昭王南征而不復寡人是問對曰貢之不入寡君之罪也敢不共給昭王之不復君其問諸水濱師進次于陘夏楚子使屈完如師師退次于召陵齊侯陳諸侯之師與屈完乘而觀之齊侯曰豈不穀是為先君之好是繼與不穀同好如何對曰君惠徼福於敝邑之社稷辱收寡君寡君之願也齊侯曰以此眾戰誰能禦之以此攻城何城不克對曰君若以德綏諸侯誰敢不服君若以力楚國方城以為城漢水以為池雖眾無所用之屈完及諸侯盟'
      this.setState({
        textcontent:initLoad5,
      })
    } else if (value == '6') {
      let initLoad6 = '沛公旦日從百餘騎來見項王至鴻門謝曰臣與將軍戮力而攻秦將軍戰河北臣戰河南然不自意能先入關破秦得復見將軍於此今者有小人之言令將軍與臣有卻項王曰此沛公左司馬曹無傷言之不然籍何以至此項王即日因留沛公與飲項王項伯東嚮坐亞父南嚮坐亞父者范增也沛公北嚮坐張良西嚮侍范增數目項王舉所佩玉玦以示之者三項王默然不應范增起出召項莊謂曰君王為人不忍若入前為壽壽畢請以劍舞因擊沛公於坐殺之不者若屬皆且為所虜莊則入為壽壽畢曰君王與沛公飲軍中無以為樂請以劍舞項王曰諾項莊拔劍起舞項伯亦拔劍起舞常以身翼蔽沛公莊不得擊於是張良至軍門見樊噲樊噲曰今日之事何如良曰甚急今者項莊拔劍舞其意常在沛公也噲曰此迫矣臣請入與之同命噲即帶劍擁盾入軍門交戟之衛士欲止不內樊噲側其盾以撞衛士仆地噲遂入披帷西嚮立瞋目視項王頭髮上指目眥盡裂項王按劍而跽曰客何為者張良曰沛公之參乘樊噲者也項王曰壯士賜之卮酒則與斗卮酒'
      this.setState({
        textcontent:initLoad6,
      })
    } else if (value == '7') {
      let initLoad7 = '陳勝者陽城人也字涉吳廣者陽夏人也字叔陳涉少時嘗與人傭耕輟耕之壟上悵恨久之曰苟富貴無相忘庸者笑而應曰若為庸耕何富貴也陳涉太息曰嗟乎燕雀安知鴻鵠之志哉二世元年七月發閭左適戍漁陽九百人屯大澤鄉陳勝吳廣皆次當行為屯長會天大雨道不通度已失期失期法皆斬陳勝吳廣乃謀曰今亡亦死舉大計亦死等死死國可乎陳勝曰天下苦秦久矣吾聞二世少子也不當立當立者乃公子扶蘇扶蘇以數諫故上使外將兵今或聞無罪二世殺之百姓多聞其賢未知其死也項燕為楚將數有功愛士卒楚人憐之或以為死或以為亡今誠以吾眾詐自稱公子扶蘇項燕為天下唱宜多應者吳廣以為然乃行卜卜者知其指意曰足下事皆成有功然足下卜之鬼乎陳勝吳廣喜念鬼曰此教我先威眾耳乃丹書帛曰陳勝王置人所罾魚腹中卒買魚烹食得魚腹中書固以怪之矣又閒令吳廣之次所旁叢祠中夜篝火狐鳴呼曰大楚興陳勝王卒皆夜驚恐旦日卒中往往語皆指目陳勝'
      this.setState({
        textcontent:initLoad7,
      })
    } else if (value == '8') {
      let initLoad8 = '是時秦兵旣盛都下震恐謝玄入問計於謝安安夷然答曰已別有旨旣而寂然玄不敢復言乃令張玄重請安遂命駕出遊山墅親朋畢集與玄圍棋賭墅安棋常劣於玄是日玄懼便為敵手而又不勝安遂游陟至夜乃還桓沖深以根本為憂遣精銳三千入衞京師謝安固卻之曰朝廷處分已定兵甲無闕西藩宜留以為防沖對佐吏歎曰謝安石有廟堂之量不閑將略今大敵垂至方遊談不暇遣諸不經事少年拒之衆又寡弱天下事已可知吾其左衽矣以琅邪王道子錄尚書六條事冬十月秦陽平公融等攻壽陽癸酉克之執平虜將軍徐元喜等融以其參軍河南郭褒為淮南太守慕容垂拔鄖城胡彬聞壽陽陷退保硤石融進攻之秦衞將軍梁成等帥衆五萬屯于洛澗柵淮以遏東兵謝石謝玄等去洛澗二十五里而軍憚成不敢進胡彬糧盡潛遣使告石等曰今賊盛糧盡恐不復見大軍秦人獲之送於陽平公融融馳使白秦王堅曰賊少易擒但恐逃去宜速赴之堅乃留大軍於項城引輕騎八千兼道就融於壽陽'
      this.setState({
        textcontent:initLoad8,
      })
    } else if (value == '9') {
      let initLoad9 = '曹操自江陵將順江東下諸葛亮謂劉備曰事急矣請奉命求救於孫將軍遂與魯肅俱詣孫權亮見權於柴桑說權曰海內大亂將軍起兵江東劉豫州收衆漢南與曹操共爭天下今操芟夷大難略已平矣遂破荊州威震四海英雄無用武之地故豫州遁逃至此願將軍量力而處之若能以吳越之衆與中國抗衡不如早與之絕若不能何不按兵束甲北面而事之今將軍外託服從之名而內懷猶豫之計事急而不斷禍至無日矣權曰苟如君言劉豫州何不遂事之乎亮曰田橫齊之壯士耳猶守義不辱況劉豫州王室之胄英才蓋世衆士慕仰若水之歸海若事之不濟此乃天也安能復為之下乎權勃然曰吾不能舉全吳之地十萬之衆受制於人吾計決矣非劉豫州莫可以當曹操者然豫州新敗之後安能抗此難乎亮曰豫州軍雖敗於長坂今戰士還者及關羽水軍精甲萬人劉琦合江夏戰士亦不下萬人曹操之衆遠來疲敝聞追豫州輕騎一日一夜行三百餘里此所謂強弩之末勢不能穿魯縞者也'
      this.setState({
        textcontent:initLoad9,
      })
    } else if (value == '10') {
      let initLoad9 = ''
      this.setState({
        textcontent:initLoad9,
      })
    }
  }

  render() {
    const { fileList } = this.state
    const props = {
      multiple: false,//不允许多文件上传
      onRemove: (file) => {
        let fs = fileList.filter(item => item.uid !== file.uid)
        this.setState({
          fileList: fs
        })
      },
      // fileList: fileList,
      accept: '.txt',
      beforeUpload: (file, files) => {
        let _this = this
        _this.setState({
          fileList: [file]
        })
        return new Promise((rs, re) => {
          let reader = new FileReader();
          reader.readAsText(file)
          reader.onload = (result) => {
            let content = result.currentTarget.result
            if (content) {
              if (content.length > 1000) {
                message.warn('上传文件内容长度超过限制，目前只支持最大800字，已自动截取。')
                content = content.substring(0, 800)
              }
              _this.setState({ textcontent: content, task_uid: file.uid, task_identify: file.name + '_' + file.lastModified }, () => {
                // _this.jd()

              })
            }
          }
        })
      }
    };

    return (
      <Content>
        <div className='content' >
          <div className='Atop' style={{ "background": "none", display: "none" }}>
            {/* <div className='Atop_l'> */}
            {/* <span>{convert('处理功能：', this.locale)}</span> */}
            {/* <Radio.Group value={this.state.action} buttonStyle="solid" onChange={(e) => {
                this.setState({
                  action: e.target.value
                }, () => {
                  if (this.state.task_uid) {
                    this.jd()
                  } else {
                    this.setState({
                      visible1: true
                    })
                  }
                })

              }}>
                <Radio.Button value="a">{convert('断句', this.locale)}</Radio.Button>
                <Radio.Button value="b">{convert('标点', this.locale)}</Radio.Button>
              </Radio.Group> */}
            {/* <div className='Atop_l'> */}
            {/* <Button onClick={() => { document.getElementById('upload').click() }}><UploadOutlined />上传文件</Button>
            </div> */}
            {/* <div className='Atop_r'>
             

              <Dropdown overlay={dowloadMenu} placement="bottomCenter">
                <Button><ArrowRightOutlined />导出数据</Button>
              </Dropdown> */}
            {/* <Popover placement="bottom" content={
                <div className='adwn2244'>
                  <Button>txt</Button>
                  <Button>html</Button>
                  <Button>word</Button>
                  <Button>pdf</Button>
                </div>
              } trigger="click">
                <Button onClick={() => {
                  this.download()
                }}><ArrowRightOutlined />导出数据</Button>
              </Popover> */}
            {/* </div> */}
          </div>

          <div className='Acon'>
            <div className='Acon_L' style={{ "position": "relative" }}>
              {/* <div style={{
                "position": "absolute", "right": "10px",
                "top": "50%", cursor: "pointer",
                "zIndex": "99",
              }} onClick={() => { this.leftchange(this.state.imgstate) }}><img className="img" src={this.state.imgurl}></img></div> */}
              {/* <span style={{"textAlign":"center"}}>{convert(`待处理的文本`, this.locale)}</span> */}
              <div className='Atop_l' style={{ "textAlign": "left", margin: "26px 0 10px", height: "32px" }}>
                <Button
                  icon={<UploadOutlined />}
                  className="left_btn"
                  //  type="primary"
                  style={{
                    "background": "#096dd9",
                    fontFamily: "中粗",
                    "color": "white",
                    "marginBottom": "10px",
                    width: "90px", height: "32px", padding: "0",
                    borderRadius: "3px",
                    "border": "1px solid #096dd9"
                  }}
                  onClick={() => {
                    if (!localStorage.getItem("token")) {
                      message.warning("暂不支持此功能！"); return
                    };
                    document.getElementById('upload').click();
                    this.upload_text();
                  }}>
                  {/* <span style={{marginRight:"5px",fontFamily: "中粗"}}>
          <img src={download}/></span> */}
                  上传</Button>
                <Button className="left_btn"
                  icon={<CaretRightOutlined />}
                  style={{
                    "background": " #096dd9",
                    borderRadius: "3px", fontFamily: "中粗",
                    "color": "white", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                    "border": "1px solid #096dd9"
                  }}
                  onClick={() => {
                    this.sl__deal_textcontent = []
                    if (this.state.textcontent) {
                      this.jd(this.state.textcontent)
                    } else {
                      message.error('待处理文本不能为空')
                    }
                  }}> {convert('运行', this.locale)}</Button>
                <Button className="left_btn" style={{
                  "background": " white",
                  borderRadius: "3px", fontFamily: "中粗",
                  "color": "black", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                  "border": "1px solid #40a9ff"
                }} icon={<DeleteOutlined />}
                  onClick={() => { this.clear_text()}}>

                  清空</Button>
                
                {/*}
                <Button className="left_btn"
                  style={{
                    "background": " #096dd9",
                    borderRadius: "3px", fontFamily: "中粗",
                    "color": "white", marginLeft: "5px", width: "90px", height: "32px", padding: "0",
                    "border": "1px solid #096dd9"
                  }}
                  onClick={() => {
                    let initLoad = '秋七月癸酉太祖自將救洪都癸未次湖口先伏兵涇江口及南湖觜遏友諒歸路檄信州兵守武陽渡友諒聞太祖至解圍逆戰於鄱陽湖友諒兵號六十萬聯巨舟為陣樓櫓高十餘丈綿亘數十里旌旂戈盾望之如山丁亥遇於康郎山太祖分軍十一隊以禦之戊子合戰徐達擊其前鋒俞通海以火礟焚其舟數十殺傷畧相當友諒驍將張定邊直犯太祖舟舟膠於沙不得退危甚常遇春從旁射中定邊通海復來援舟驟進水湧太祖舟乃得脫己丑友諒悉巨艦出戰諸將舟小仰攻不利有怖色太祖親麾之不前斬退縮者十餘人人皆殊死戰會日晡大風起東北乃命敢死士操七舟實火藥蘆葦中縱火焚友諒舟風烈火熾烟燄漲天湖水盡赤友諒兵大亂諸將鼓噪乘之斬首二千餘級焚溺死者無算友諒氣奪辛卯復戰友諒復大敗於是斂舟自守不敢更戰壬辰太祖移軍扼左蠡友諒亦退保渚磯相持三日其左右二金吾將軍皆降友諒勢益蹙忿甚盡殺所獲將士而太祖則悉還所俘傷者傅以善藥且祭其親戚諸將陣亡者八月壬戌友諒食盡趨南湖觜為南湖軍所遏遂突湖口太祖邀之順流搏戰及於涇江涇江軍復遮擊之友諒中流矢死'
                    this.setState({ textcontent: initLoad })
                  }}> {convert('加载示例', this.locale)}</Button> */}

                <Select id='ant-sel' key={this.state.placeholder_key} placeholder="加载示例" dropdownClassName="dropdown-style"  style={{  width: 120, marginLeft: "5px" }} onChange={this.loadExample}>
                  <Option value="4">左傳1</Option>
                  <Option value="5">左傳2</Option>
                  <Option value="6">史記1</Option>
                  <Option value="7">史記2</Option>
                  <Option value="8">資治通鑒1</Option>
                  <Option value="9">資治通鑒2</Option>
                  <Option value="1">宋史</Option>
                  <Option value="2">元史</Option>
                  <Option value="3">明史</Option>

                </Select>



                {/* <Switch style={{marginLeft: "5px",float: 'right',}} checkedChildren="句读" unCheckedChildren="标点" onChange={this.JuduSwitch} /> */}

                
              
                {/* <span style={{ float: 'right', color: "#7d7d7d", fontFamily: "中粗" }}>今日使用剩余<span style={{ color: "#C61414", fontWeight: 900 }}>{localStorage.getItem("token") ? this.state.rateNum : this.state.rate}</span>次</span> */}
                <span style={{ float: 'right', marginRight: '5px', color: "#7d7d7d", fontFamily: "中粗", display: this.state.rateVisile ? 'block' : 'none' }} >今日使用剩余<span style={{ color: "#C61414", fontWeight: 900 }}>{localStorage.getItem("token") ? this.state.rateNum : this.state.rate}</span>次</span>

              </div>
              <div className='Acon_Lco' hidden={true}>
                <div className='right'>
                  <FileTextOutlined style={{ fontSize: "90px", color: "#DDDDDD" }} />
                  <div className='ziwen' >
                    {convert("暂无文档，快来上传吧，支持格式txt", this.locale)}
                  </div>
                  <Upload {...props} id="upload">
                    <Button icon={<UploadOutlined />}>上传文件</Button>
                  </Upload>
                </div>

              </div>
              <div className='right right_A' >

                <Input.TextArea
                  onChange={e => {
                    if(!e.target.value){
                      this.clear_text();
                    }
                    this.setState({
                      textcontent: e.target.value
                    })
                  }} placeholder={convert('粘贴待处理文本或点击左上角上传文件' + "\n" + '注：夹注文本仅支持英文半角中括号[]')}
                  style={{ minHeight: this.state.deskHeight - 300, fontSize: 18, background: "none", borderRadius: "0px", lineHeight: '1.8' }}
                  value={this.state.textcontent}
                  onMouseMove={() => { this.ban() }}
                  onKeyUp={() => { this.ban() }}
                  // onPressEnter={e => {
                  //   if (this.state.textcontent) {
                  //     this.jd()
                  //   } else {
                  //     this.setState({
                  //       visible1: true
                  //     })
                  //   }
                  // }}
                />

                <p style={{ fontSize: "12px", color: "black" }}>提示：仅支持UTF-8格式文本，建议≤800字/次，24次/24小时；为了更好的体验，建议字体：<a style={{ color: "#096dd9" }} href="http://fonts.jp/hanazono/" target="blank">花园明朝体</a>
                  {/* <span className="closethis" style={{
                    fontSize: "12px",
                    textAlign: "right", lineHeight: "32px"
                  }}>
                    <span className="_closethis" style={{ float: "right" }} onClick={() => { this.closethis() }}>关闭</span>
                  </span> */}
                  <span style={{ float: "right", marginRight: "20px" }}>当前字数：{this.state.textcontent.length}/{!localStorage.getItem("token") ? 800 : 800}</span>
                </p>

              </div>
            </div>

          </div>
        </div>
        <Modal
          className='uioio11'
          title="开始新任务"
          visible={this.state.visible1}
          onOk={() => {
            this.setState({
              visible1: false
            }, () => {
              this.jd(this.state.textcontent)
            })
          }}
          onCancel={() => {
            this.setState({
              visible1: false
            })
          }}
          okText="确定"
          cancelText="取消"
          destroyOnClose={true}
        >
          <Input placeholder={convert('请输入任务名称')} onChange={e => {
            this.setState({
              task_uid: e.target.value,
              task_identify: e.target.value
            })
          }}></Input>
        </Modal>
        <Modal
          className='uioio12'
          width={400}
          visible={this.state.visible2}
          onOk={this.handleOkM2}
          onCancel={this.handleCancelM2}
          footer={null}
          closable={false}
        >
          <img src={loading}></img>
          <span style={{ color: "white" }}>正在努力加载，请耐心等待！</span>
          {/* <Progress strokeColor="#3AA4CE" type="circle" percent={this.state.progress} 
          format={percent => `${percent / 5} 秒`} /> */}
          {/* <span>句读处理中，预计20s请耐心等待～</span> */}
          {/* <Button>暂停</Button> */}
        </Modal>
      </Content>
    );
  }
}
function mapStateToProps(state) {
  return {
    dealTextContent: state.dealTextContent,
    todoHandleData: state.todoHandleData,
    todoHandleAutomaticDuFlag: state.todoHandleAutomaticDuFlag,
    duData: state.duData
  }
}
export default withRouter(connect(mapStateToProps)(BiaoDian));
