import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Form, Input, Button, message } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import { Link, withRouter } from 'react-router-dom'
import request from '../utils/request';
import * as lacs from '../actions/loginActions';
import * as cont from '../config/constant'
import convert from '../utils/locale_zh_convert'
import dayjs from 'dayjs'
import reqwest from 'reqwest'
const { Content } = Layout;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 10 },
};
class VerifyEmail extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.locale = dayjs.locale()
        this.state = {
            uploadLoading: false,
            liked: true,
            count: 60,
            loading: false,
            hideVerificationCode: true,
            regVisible: false,
            result_code: 0,
            isChina: true,
        }
    }
    handleSubmit = (values) => {
        let _this = this;
        reqwest({
            url: '/wyd/auth/verifyMail', // 用户验证邮箱
            method: 'post',
            type: 'json',
            headers:{"WYD-TOKEN":localStorage.getItem("token")},
            data: {
                // client_id: "wyd",
                email : values.phone,
                emailCode : values.emailCode,
                code: values.code,
            },
            success: function (data) {
                if (data.code == 1) {
                    window.location.href = "/#/AutomaticDu"
                }
            },
            error: function (err) {
                _this.handleVerify();
                let mes = JSON.parse(err.response)
                message.error(mes.message)

            }
        })
    }
    getPhoneCode = (phone, callback) => {
        let _this = this
        if (!phone) {
            message.error(convert("邮箱不能为空", _this.locale))
            callback();
            return
        }
        let code = this.formRef.current.getFieldValue("code")
        if (!code) {
            message.error(convert("图形验证码不能为空", _this.locale))
            callback();
            return
        }
      
        reqwest({
            url: '/wyd/auth/mailto',
            method: 'post',
            type: 'json',
            // contentType: "application/json",
            data: { email: phone, code: code },
            success: function (data) {
                if (data.code === 1) {
                    _this.countDown()
                } else if (data.code === 500) {
                    message.error(data.message)
                }
            },
            error: function (err) {
                let mes = JSON.parse(err.response)
                message.error(mes.message)
            }
        })
        this.handleVerify()
    }
    countDown() {
        const { count } = this.state
        if (count === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
          this.setState({
            count: 60,
            liked: true,
          })
        } else {
          this.setState({
            count: count - 1,
            liked: false,
          })
          setTimeout(() => this.countDown(), 1000)//每一秒调用一次
        }
      }


    handleVerify = () => {
        document.getElementById("img").src = "/wyd/captcha.jpg?" + Math.random();
    }
    componentWillMount() {
        let number = localStorage.getItem("number")
        if (number == 1) {
            localStorage.setItem("number", 2)
            window.location.reload()
        }
    }
    render() {
        const { isChina, liked, count, loading, } = this.state;
        return (
            <Content className='bg-e7ecef'>
                <div className='content bg-fff login-box '>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.handleSubmit}
                        ref={this.formRef}
                    >
                        <span className='login-title'>{convert('邮箱验证', this.locale)}</span>
                        {isChina ? <Form.Item label={convert('邮箱', this.locale)}  name="phone"
                            rules={[{ required: true, message: convert('请输入邮箱', this.locale) },
                            {
                                message: convert('请输入有效的邮箱', this.locale), type: "email"
                                // pattern: /^(1(3|4|5|6|7|8|9)[0-9]{9})?(([a-zA-Z0-9_-])+@(.*?(\.edu|edu\.).*))?$/ 
                            },
                            {
                                validator: (rule, value) => {
                                    if (value) {
                                        let _this = this
                                        // return new Promise((resolve, reject) => {
                                        // request(cont.registerCheckPhone, {
                                        //   body: {
                                        //     phone: value
                                        //   }
                                        // }, _this.props).then(data => {
                                        //   if (data.code === 0) {
                                        //     resolve(data.hint && data.hint)
                                        //   } else if (data.code === 1) {
                                        //     reject(data.errmsg && data.errmsg)
                                        //   }
                                        // })
                                        // })
                                    }
                                    return Promise.resolve()
                                }
                            }
                            ]}>
                            <Input placeholder={convert('请输入邮箱', this.locale)} onFocus={() => {
                                this.setState({
                                    hideVerificationCode: false
                                })
                            }} />
                        </Form.Item> : <Form.Item label={convert('邮箱', this.locale)}
                             name="phone"
                            rules={[{ required: true, message: convert('请输入邮箱', this.locale) },
                            { message: convert('请输入有效的邮箱', this.locale), type: 'email' },
                            {
                                validator: (rule, value) => {
                                    let isEmail = value.indexOf('@')
                                    if (value && isEmail !== -1) {
                                        let _this = this
                                        return new Promise((resolve, reject) => {
                                            request(cont.registerCheckPhone, {
                                                body: {
                                                    phone: value
                                                }
                                            }, _this.props).then(data => {
                                                if (data.code == 0) {
                                                    resolve(data.hint && data.hint)
                                                } else if (data.code == 1) {
                                                    reject(data.errmsg && data.errmsg)
                                                }
                                            })
                                        })
                                    }
                                    return Promise.resolve()
                                }
                            }
                            ]}>
                            <Input placeholder={convert('请输入邮箱', this.locale)} onFocus={() => {
                                this.setState({
                                    hideVerificationCode: false
                                })
                            }} />
                        </Form.Item>}

                        <Form.Item
                            className="verificationCode"
                            label={convert('图形验证码', this.locale)}
                            name="code"
                            rules={[{ required: true, message: convert('请输入图形验证码', this.locale) },]}>
                            <Input placeholder={convert('请输入图形验证码', this.locale)} style={{ width: 'auto' }}
                                addonAfter={
                                    <Button style={{ marginLeft: 20, padding: 0 }} >
                                        <img style={{ width: "100%", height: "100%", marginRight: 10 }} id='img' src='/wyd/captcha.jpg' onClick={this.handleVerify} alt='验证码' />
                                        <span style={{ fontSize: 12, color: "#787876" }} onClick={this.handleVerify}>点击图片刷新验证码</span>
                                    </Button>
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            className="verificationCode"
                            label={convert('邮箱验证码', this.locale)}
                            name="emailCode"
                            validateTrigger={['onChange']}
                            dependencies={['phone']}
                            rules={[{ required: true, message: convert('请输入邮箱验证码', this.locale), }, ]}>
                            <Input
                                placeholder={convert('请输入邮箱验证码', this.locale)}
                                addonAfter={
                                    <Button type="primary" disabled={liked ? false : true} onClick={() => {
                                        this.setState({
                                            loading: true
                                        }, () => {
                                            this.getPhoneCode(this.formRef.current.getFieldValue('phone'), () => {
                                                this.setState({
                                                    loading: false
                                                })
                                            })
                                        })
                                    }} >
                                        {liked ? '获取验证码' : `（${count}）秒后重发`}
                                    </Button>}
                            />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button className='pri-btn' type="primary" htmlType="submit" style={{ width: '100%', background: "#096dd9", marginBottom: '30px' }}>{
                                convert('验证', this.locale)
                            }</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        );
    }
}
function mapStateToProps(state) {
    return {
        goToUrl: state.login.goToUrl
    }
}
export default withRouter(connect(mapStateToProps)(VerifyEmail));