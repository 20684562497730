import React, { Component, history } from 'react';
import { Layout, Badge, Button, Select, Input, Radio, Tooltip, Divider, Dropdown, Menu, Avatar, Modal, message, Popover } from 'antd';
import './never.css';
//import logo from './../../images/logonew2.png'
import logo from './../../images/logo3.jpg'
import { CaretDownOutlined, BellOutlined, UserOutlined, DownOutlined, WarningOutlined, CaretRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import convert from '../../utils/locale_zh_convert';
import * as cont from './../../config/constant'
import { init_deal_content } from './../../actions/DealTextContentAction'
import PubSub from 'pubsub-js';
import user from '../../images/user.png'
import user2 from '../../images/user2.png'
import help from '../../images/help.png'

import dayjs from 'dayjs';
import BiaoDianDisposed from '../home/BiaoDianDisposed';
const { Header } = Layout;
const { Option } = Select;
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="/#/personal">个人资料</a>
    </Menu.Item>
    <Menu.Item key="2">
      <a href="/#/myfiles">我的文档</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="/#/login" onClick={refresh}>退出登录</a>
    </Menu.Item>

  </Menu>
);

function onChangeRabtn(e) {
  console.log(`radio checked:${e.target.value}`);
}
function refresh() {
  localStorage.removeItem("token")
  localStorage.removeItem("name")
  localStorage.setItem("number", 1)
  this.props.history.push("/login")
}
class Headeds extends Component {
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
    this.state = {
      value: '1',
      loginmsg: "你好，请登录",
      _name: "",
    };
  }
  state = {
    show: true,
    visible: false,
    url: '/ocr/list',

  };
  componentWillMount() {
    let name = localStorage.getItem("id")
    this.setState({})
    if (!name) {
    } else {
      this.setState({
        _name: name
      })
    }
  }

  render() {
    const { login, networksegment } = this.props;
    const setmenu = (path) => {
      switch (path) {
        case '/ocr/list':
          return '1';
        case '/ocr/text/':
          return '1';
        case '/AutomaticDu':
          return '2';
        case '/AutomaticDuDisposed':
          return '4';
        case '/NamedEntity':
          return '3';
        case '/NamedEntityDisposed':
          return '3';
        case '/BiaoDian':
          return '4';
        case '/BiaoDianDisposed':
          return '4';
        case '/Segmentation':
          return '5';
        case '/SegmentationDisposed':
          return '5';
        case '/RelationExt':
          return '6';
        case '/RelationExtDisposed':
          return '6';
        //case '/Collation':
          //return '4';
        default: return '4';
      }
    }
    const content = () => {
      let selectedKeys = setmenu(this.props.location.pathname)
      switch (selectedKeys) {
        case '1': //   ocr
          return 'ocr识别';
        case '2': //   AutomaticDu
          return <div classname="helpInfoMessage" style={{ width: '400px', fontFamily: "中粗", height: '400px', overflowY: 'scroll' }}>
            <p>自动句读是将文本进行标点断句。</p>
            <p className='helpp'><b>功能按钮使用说明：</b></p>
            <p>1.<font color='#096DD9'>上传</font>或粘贴文本。（上传暂不支持）</p>
            <p>2.点击<font color='#096DD9'>清空</font>会清空文本。</p>
            <p>3.点击<font color='#096DD9'>运行</font>开始将文本进行句读。</p>
            <p>4.点击<font color='#096DD9'>重新上传</font>可重新上传或粘贴文本。</p>
            <p>5.点击<font color='#096DD9'>保存</font>将文本保存到我的文档。（暂不支持）</p>
            <p>6.点击<font color='#096DD9'>导出</font>选择要导出的格式可将文本导出到本地。（暂不支持）</p>
            <p>7.点击<font color='#096DD9'>专名识别</font>可将句读过的文本进行专名识别标记。</p>
            <p>8.点击<font color='#096DD9'>缩小</font>将文本框内的文本字体缩小显示。</p>
            <p>9.点击<font color='#096DD9'>放大</font>将文本框内的文本字体放大显示。</p>
            <p>10.点击<font color='#096DD9'>复制</font>可复制文本框内的文本，方便粘贴。</p>
            <p>11.点击<font color='#096DD9'>编辑</font>为了手动在所要添加句读符的位置添加。</p>
          </div>;
        case '3': //   NamedEntity
          return <div classname="helpInfoMessage" style={{ width: '400px', fontFamily: "中粗", height: '400px', overflowY: 'scroll' }}>
            <p>专名识别，又称作“ 命名实体识别（Named Entity Recognition，简称NER）”，是指识别文本中具有特定意义的实体。主要包括人名、时间、地名、官职、书名、专有名词等。</p>
            {/* <p>通过点击已标注结果进行修改、删除、增加实体标记，修改/查看实体链接。</p>
            <p>通过选择文字手动进行标注修改等。</p> */}
            <p className='helpp'><b>功能按钮使用说明：</b></p>
            <p>1.<font color='#096DD9'>上传</font>或粘贴文本。（上传暂不支持）</p>
            <p>2.点击<font color='#096DD9'>清空</font>会清空文本。</p>
            <p>3.点击<font color='#096DD9'>运行</font>开始将文本进行标注。</p>
            <p>4.点击<font color='#096DD9'>重新上传</font>可重新上传或粘贴文本。</p>
            <p>5.点击<font color='#096DD9'>保存</font>将文本保存到我的文档。（暂不支持）</p>
            <p>6.点击<font color='#096DD9'>导出</font>选择要导出的格式可将文本导出到本地。（暂不支持）</p>
            <p>7.点击<font color='#096DD9'>缩小</font>将文本框内的文本字体缩小显示。</p>
            <p>8.点击<font color='#096DD9'>放大</font>将文本框内的文本字体放大显示。</p>

            <p className='helpp'><b>快速标注快捷键：</b></p>
            <p>shift+p ———— 人名</p>
            <p>shift+t ———— 时间</p>
            <p>shift+l ———— 地名</p>
            <p>shift+o ———— 官职</p>
            <p>shift+b ———— 书名</p>
            {/* <p>shift+z --- 撤回</p>
            <p>shift+y --- 重做</p> */}
          </div>;
        case '4':
          return <div classname="helpInfoMessage" style={{ width: '400px', fontFamily: "中粗", height: '400px', overflowY: 'scroll' }}>
          <p>自动标点是对输入古籍文本标注现代标点符号。</p>
          <p className='helpp'><b>功能按钮使用说明：</b></p>
          <p>1.<font color='#096DD9'>上传</font>或粘贴文本。（上传暂不支持）</p>
          <p>2.点击<font color='#096DD9'>清空</font>会清空文本。</p>
          <p>3.点击<font color='#096DD9'>运行</font>开始将文本进行标点。</p>
          <p>4.点击<font color='#096DD9'>重新上传</font>可重新上传或粘贴文本。</p>
          <p>5.点击<font color='#096DD9'>保存</font>将文本保存到我的文档。（暂不支持）</p>
          <p>6.点击<font color='#096DD9'>导出</font>选择要导出的格式可将文本导出到本地。（暂不支持）</p>
          <p>7.点击<font color='#096DD9'>标点/句读切换</font>选择以标点或句读模式显示处理后文本。</p>
          <p>8.点击<font color='#096DD9'>缩小</font>将文本框内的文本字体缩小显示。</p>
          <p>9.点击<font color='#096DD9'>放大</font>将文本框内的文本字体放大显示。</p>
          <p>10.点击<font color='#096DD9'>复制</font>可复制文本框内的文本，方便粘贴。</p>
        </div>;
        case '5':
          return <div classname="helpInfoMessage" style={{ width: '400px', fontFamily: "中粗", height: '400px', overflowY: 'scroll' }}>
          <p>自动分词是将文本分解为字词单位，词与词之间以空格作为分隔符</p>
          <p className='helpp'><b>功能按钮使用说明：</b></p>
          <p>1.<font color='#096DD9'>上传</font>或粘贴文本。（上传暂不支持）</p>
          <p>2.点击<font color='#096DD9'>清空</font>会清空文本。</p>
          <p>3.点击<font color='#096DD9'>运行</font>开始将文本进行分词。</p>
          <p>4.点击<font color='#096DD9'>重新上传</font>可重新上传或粘贴文本。</p>
          <p>5.点击<font color='#096DD9'>保存</font>将文本保存到我的文档。（暂不支持）</p>
          <p>6.点击<font color='#096DD9'>导出</font>选择要导出的格式可将文本导出到本地。（暂不支持）</p>
          <p>7.点击<font color='#096DD9'>缩小</font>将文本框内的文本字体缩小显示。</p>
          <p>8.点击<font color='#096DD9'>放大</font>将文本框内的文本字体放大显示。</p>
          <p>9.点击<font color='#096DD9'>复制</font>可复制文本框内的文本，方便粘贴。</p>
        </div>;
        case '6':
          return <div classname="helpInfoMessage" style={{ width: '400px', fontFamily: "中粗", height: '400px', overflowY: 'scroll' }}>
          <p>关系抽取是从文本中找出实体间的关系，并基于实体及实体间关系构建图谱</p>
          <p className='helpp'><b>功能按钮使用说明：</b></p>
          <p>1.<font color='#096DD9'>上传</font>或粘贴文本。（上传暂不支持）</p>
          <p>2.点击<font color='#096DD9'>清空</font>会清空文本。</p>
          <p>3.点击<font color='#096DD9'>运行</font>开始将文本进行分词。</p>
          <p>4.点击<font color='#096DD9'>重新上传</font>可重新上传或粘贴文本。</p>
          <p>5.点击<font color='#096DD9'>保存</font>将文本保存到我的文档。（暂不支持）</p>
          <p>6.点击<font color='#096DD9'>导出</font>选择要导出的格式可将文本导出到本地。（暂不支持）</p>
          <p>7.点击<font color='#096DD9'>缩小</font>将文本框内的文本字体缩小显示。</p>
          <p>8.点击<font color='#096DD9'>放大</font>将文本框内的文本字体放大显示。</p>
          <p>9.点击<font color='#096DD9'>复制</font>可复制文本框内的文本，方便粘贴。</p>
        </div>;
        //case '4': //   Collation
          //return '校勘平台';
        default: return '';
      }
    }
    const selectedKeys = [setmenu(this.props.location.pathname)]
    const personCenterMenu = (
      <Menu onClick={(e) => {

      }}>
        <Menu.Item>
          <Link to="/PersonalCenter#1">
            <span>
              {convert('基本设置', this.locale)}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/PersonalCenter#2">
            <span>
              {convert('识别文档', this.locale)}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/PersonalCenter#3">
            <span>
              {convert('整理文档', this.locale)}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/PersonalCenter#4">
            <span>
              {convert('消息通知', this.locale)}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" onClick={this.props.logout} href="#">{convert('退出', this.locale)}</a>
        </Menu.Item>
      </Menu>
    );
    const text = (
      <div>
        {/*
        <Link to='/login'><span style={{ color: "white" }}>{convert('登录', this.locale)}</span></Link>
        <Divider type="vertical" />
        <Link to='/register'><span style={{ color: "white" }}>{convert('注册', this.locale)}</span></Link>
    */}
      </div>
    );
    return (
      <Header id='header'>
        <div className='htopL'>
          <div className='logoimg' >
            <img src={logo} style={{ cursor: "pointer" }} onClick={() => { this.props.history.push("/AutomaticDu") }} />
          </div>
        </div>
        <div className='htopC'>
          <Menu mode="horizontal" selectedKeys={selectedKeys}
            onClick={e => {
              // let url
              // if(e.key == "2"){
              //  url = "/AutomaticDu"
              // }else{
              //   message.warning("暂未开放！敬请期待！")
              // }
              let url = '/BiaoDian'
              switch (e.key) {
                case '1':
                  // url = '/ocr/list'
                  // url = ""
                  message.warning("暂未开放！敬请期待！")
                  break;
                case '2':
                  url = '/AutomaticDu'
                  break;
                case '3':
                  url = '/NamedEntity'
                  break;
                case '4':
                  url = '/BiaoDian'
                  break;
                case '5':
                  url = '/Segmentation'
                  break;
                case '6':
                  url = '/RelationExt'
                // case '4':
                //   url = '/Collation'
                //   break;
              }
              // if (!this.props.dealTextContent || (this.props.dealTextContent.content && this.props.dealTextContent.content.length === 0)) {
              //   this.props.history.push(url)
              //   return
              // }
              if (e.key !== '1' && e.key !== '3' && e.key !== 'help' && e.key !== '5') {
                //判断当前页面
                if (this.props.location.pathname === '/NamedEntityDisposed') {
                  this.setState({
                    visible: true,
                    url: url,
                  })
                } else {
                  this.props.history.push(url)
                }
              } else {
                if (e.key === '3') {
                  //文本传值
                  //判断当前页面
                  if (this.props.location.pathname == '/AutomaticDuDisposed') {
                    PubSub.publish("gotoNamedEntity", "1");//data是被传的数据
                  } else if (this.props.location.pathname == '/BiaoDianDisposed') {
                    PubSub.publish("gotoNamedEntity", "1");//data是被传的数据
                  } else {
                    this.props.history.push(url)
                  }
                } else if (e.key === '5') {
                  if (this.props.location.pathname == '/AutomaticDuDisposed') {
                    PubSub.publish("gotoSegmentation", "1");//data是被传的数据
                  } else if (this.props.location.pathname == '/BiaoDianDisposed') {
                    PubSub.publish("gotoSegmentation", "1");//data是被传的数据
                  } else {
                    this.props.history.push(url)
                  }
                } else {
                  this.props.history.push(url)
                }
              }

            }}>
            {/*
            <Menu.Item key='1' disabled>
              <span>
                {convert('OCR识别', this.locale)}
              </span>
            </Menu.Item>
            */}
            {/*
            <Menu.Item key='2'>
              <span>
                {convert('自动句读', this.locale)}
              </span>
            </Menu.Item>
            */}
            <Menu.Item key='4'>
              <Popover content = '自动标点处理后将对输入文本标注现代标点符号' style={{ width: '500px', fontFamily: "中粗" }}>
                <span>
                  {convert('自动标点/句读', this.locale)}
                </span>
              </Popover>
            </Menu.Item>
            <Menu.Item key='5'>
              <Popover content = '分词处理后词与词之间以空格作为分隔符，并对两个字以上的词添加下划线' style={{ width: '500px', fontFamily: "中粗" }}>
                <span>
                  {convert('自动分词', this.locale)}
                </span>
              </Popover>
            </Menu.Item>
            <Menu.Item key='3'>
              <Popover content = '专名识别后不同类型的实体以不同颜色高亮来进行区分' style={{ width: '500px', fontFamily: "中粗" }}>
                <span>
                  {convert('专名识别', this.locale)}
                </span> 
              </Popover>
            </Menu.Item>
            <Menu.Item key='6'>
              <Popover content = '关系图谱可视化' style={{ width: '500px', fontFamily: "中粗" }}>
                <span>
                  {convert('关系抽取', this.locale)}
                </span> 
              </Popover>
            </Menu.Item>
            <Menu.Item key='help' disabled>
              <Popover content={content} title="功能说明：" placement="bottom" arrowPointAtCenter style={{ width: '500px', fontFamily: "中粗" }}>
                <Button style={{ border: 'none' }} ><QuestionCircleOutlined /></Button>
              </Popover>
            </Menu.Item>

            {/* <Menu.Item key='4'>
              <span>
                {convert('校勘平台', this.locale)}
              </span>
            </Menu.Item> */}
          </Menu>
        </div>

        <div className='htopR' style={{ fontSize: "12px" }}>
          <div style={{ display: "flex", marginRight: "20px" }}>
            {/* <div style={{ cursor: "pointer", float: "left", marginRight: "10px" }}>
              {
                this.state._name == null || this.state._name == "" || this.state._name == undefined ? <div >
                  <Tooltip placement="bottom" title={text} color={"#1890ff"}>  
                     <Avatar size={50} icon={<UserOutlined />} /> 
                    <Avatar size={38} src={user} /> 
                  </Tooltip> 
                </div> : <div className='log-reg'>
                    <Tooltip placement="bottom" title={menu} color={"white"}
                      trigger={['click']} onClick={e => e.preventDefault()}
                    >
                      <Avatar size={38} icon={<UserOutlined />} style={{ backgroundColor: '#2b78e4' }} />
                      <Avatar size={50} src={user2} />
                    </Tooltip>
                  </div>
              }
            </div> */}
            <div style={{ cursor: "pointer", float: 'right' }}
              onClick={() => { this.props.history.push("/usehelp") }}>
              <img src={help} alt='帮助' style={{ width: '33px' }}></img>
            </div>
          </div>
          {/* <div className='tongz'>
            <Button onClick={e => {
              this.props.dispatch(init_deal_content())
              localStorage.clear()
              message.success('清除成功')
            }}>清空缓存</Button>
          </div> */}
          {/* <div className='tongz'>
            <Badge dot={this.state.show}>
              <BellOutlined />
            </Badge>

          </div> */}
          {/* <Popover placement="bottom" content={
            <div className='people'>
              <Link to='/PersonalCenter'><Button>{convert("基本设置", this.locale)}</Button></Link>
              <Link to='/PersonalCenter'><Button>{convert("识别文档", this.locale)}</Button></Link>
              <Link to='/PersonalCenter'><Button>{convert("整理文档", this.locale)}</Button></Link>
              <Link to='/PersonalCenter'><Button>{convert("消息通知", this.locale)}</Button></Link>
              <Button>{convert("退出", this.locale)}</Button>
            </div>
          } trigger="click"> */}
          {/* <div style={{ margin: '0 10px' }}>

            {
              !!login ? <div >
                <Dropdown overlayClassName='add141' overlayStyle={{ width: "123px", height: '196px', minWidth: '123px' }} overlay={personCenterMenu} trigger={['click']}>
                  <span style={{ display: 'flex', alignItems: "center", width: '150px', justifyContent: 'space-around' }} className="action"><Avatar icon={<UserOutlined />} src={`${cont.getPicture}?id=${login.photo}`} style={{ verticalAlign: 'middle' }} size="large"></Avatar>{login.username} <DownOutlined /></span>
                </Dropdown>
              </div> : <div className='log-reg'>
                  <Link to='/login'><span>{convert('登录', this.locale)}</span></Link>
                  <Divider type="vertical" />
                  <Link to='/register'><span>{convert('注册', this.locale)}</span></Link>
                </div>
            }
          </div> */}

          {/* </Popover> */}


          {/* <div className='langue'>
            <Input.Group compact>
              <Select defaultValue="1" onChange={this.props.changeLocale}>
                <Option value="1">简体中文</Option>
                <Option value="2">繁体中文</Option>
                <Option value="3">英文</Option>
              </Select>
            </Input.Group>
          </div> */}
        </div>
        <Modal
          visible={this.state.visible}
          // title="Title"
          // onOk={this.handleOk}
          onCancel={() => {
            this.setState({
              visible: false
            })
          }}
          closable
          footer={[
            <Button key="submit" type="primary" onClick={() => {
              this.setState({
                visible: false
              }, () => {
                let url = this.state.url
                this.props.dispatch(init_deal_content())
                console.log(this.props)
                this.props.history.push(url)
              })
            }}>
              {convert('确定', this.locale)}
            </Button>,
            // <Button key="back" onClick={() => {
            //   this.setState({
            //     visible: false
            //   }, () => {
            //     let url = this.state.url
            //     console.log(this.props)
            //     this.props.history.push(url)
            //   })
            // }}>
            //   {convert('否', this.locale)}
            // </Button>,
            <Button key="cancel" onClick={() => {
              this.setState({
                visible: false
              })
            }}>

              {convert('取消', this.locale)}
            </Button>
          ]}
        >
          {convert('确定将清空专名识别的结果？', this.locale)}
        </Modal>
      </Header >
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state.login.login,
    networksegment: state.login.networksegment,
    dealTextContent: state.dealTextContent
  }
}
export default withRouter(connect(mapStateToProps)(Headeds));