export function setFilesData(files) {

    return {

        type: 'set_files_data',

        payload: { files: files }

    }

}