import React, { Component } from 'react';
import { Layout, Form, Input, Button, Checkbox, message, notification } from 'antd';
import './../App.css';
import './../css/index.css';
import './../css/login.css'
import convert from '../utils/locale_zh_convert'
import request from '../utils/request';
import * as cont from '../config/constant'
import dayjs from 'dayjs'
import { withRouter } from 'react-router';
import reqwest from "reqwest"
const { Content } = Layout;
const openNotificationWithIcon = (title, msg) => {
  notification['error']({
    message: title,
    description:
      msg,
  });
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};
class ForgetPwd extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.locale = dayjs.locale()
    this.state = {
      liked: true,
      count: 60,
      loading: false
    }
  }
  countDown() {
    const { count } = this.state
    if (count === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
      this.setState({
        count: 60,
        liked: true,
      })
    } else {
      this.setState({
        count: count - 1,
        liked: false,
      })
      setTimeout(() => this.countDown(), 1000)//每一秒调用一次
    }
  }
  // getPhoneCode = (phone, callback) => {
  //   let _this = this
  //   if (!phone) {
  //     message.error(convert("邮箱不能为空", _this.locale))
  //     callback();
  //     return
  //   }
  //   request(cont.getPhoneCode, {
  //     body: {
  //       phone: phone
  //     }
  //   }, _this.props).then(data => {
  //     callback();
  //     if (data.code == 0) {
  //       _this.countDown()
  //     } else if (data.code == 1) {
  //       openNotificationWithIcon(convert('错误', this.locale), data.errmsg)
  //     }
  //   })
  // }
  getPhoneCode = (phone, callback) => {
    let _this = this
    if (!phone) {
      message.error(convert("邮箱不能为空", _this.locale))
      callback();
      return
    }
    let code = this.formRef.current.getFieldValue("code")
    if (!code) {
      message.error(convert("图形验证码不能为空", _this.locale))
      callback();
      return
    }
    reqwest({
      url: '/wyd/auth/mailto',
      method: 'post',
      type: 'json',
      // contentType: "application/json",
      data: {
         email: phone, 
         code: code,
         type: "FORGET"
         },
      success: function (data) {
        if (data.code === 1) {
          _this.countDown()
        } else if (data.code === 500) {
          message.error(data.message)
        }
      },
      error: function (err) {
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
    this.handleVerify()
  }
  handleVerify = () => {
    document.getElementById("img").src = "/wyd/captcha.jpg?" + Math.random();
  }
  onFinish = values => {
    let _this = this
    reqwest({
      url: '/wyd/auth/reset',
      method: 'post',
      type: 'json',
      data: {
        email: values.phone,  // 已验证过的信箱
        code: values.code,   // 图形验证码
        emailCode: values.emailCode,  //  邮件验证码
        password: values.password,   // 要设置的新密码
      },
      success: function (data) {
        if (data.code === 200) {
          message.success(data.message);
          console.log(sessionStorage.getItem('login'));
          if(sessionStorage.getItem('login') != null){
            sessionStorage.removeItem('login');
            _this.props.history.push("/login")
          }else if( sessionStorage.getItem('person') != null){
            sessionStorage.removeItem('person');
            _this.props.history.push("/personal");
          }
        } 
      },
      error: function (err) {
        _this.handleVerify();
        let mes = JSON.parse(err.response)
        message.error(mes.message)
      }
    })
  };

  render() {

    const { liked, count, loading } = this.state
    return (
      <Content className='bg-e7ecef'>
        <div className='content bg-fff login-box '>
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            initialValues={{ remember: true, count }}
            onFinish={this.onFinish}
          >
            {/* <span className='login-title'>{convert('找回密码', this.locale)}</span> */}
            <span className='login-title'>{convert('安全中心', this.locale)}</span>
            {/* <Form.Item
              label={convert("账号", this.locale)}
              name="username"
              rules={[{ required: true, message: convert('请输入登录账号!', this.locale) }]}
              onChange={(value) => {
                this.formRef.current.setFieldsValue({ phone: '' })
              }}
            >
              <Input placeholder={convert('请输入登录账号', this.locale)} />
            </Form.Item> */}
            <Form.Item
              label={convert("邮箱", this.locale)}
              name="phone"
              rules={[{ required: true, message: convert('请输入您的邮箱!', this.locale) }, {
                validator: (rule, value) => {
                  let username = this.formRef.current.getFieldValue("username")
                  if (value && username) {
                    let _this = this
                    return new Promise((resolve, reject) => {
                      request(cont.checkPhone, {
                        body: {
                          username: username,
                          phone: value
                        }
                      }, _this.props).then(data => {
                        if (data.code == 0) {
                          resolve(data.hint && data.hint)
                        } else if (data.code == 1) {
                          reject(data.errmsg && data.errmsg)
                        }
                      })
                    })
                  }
                  return Promise.resolve()
                },
              }]}
            >
              <Input placeholder={convert('请输入您的邮箱', this.locale)} />
            </Form.Item>
            <Form.Item
              className="verificationCode"
              label={convert('图形验证码', this.locale)}
              name="code"
              rules={[{ required: true, message: convert('请输入图形验证码', this.locale) },]}>
              <Input placeholder={convert('请输入图形验证码', this.locale)} style={{ width: 'auto' }}
                addonAfter={
                  <Button style={{ marginLeft: 20, padding: 0 }} >
                    <img style={{ width: "100%", height: "100%", marginRight: 10 }} id='img' src='/wyd/captcha.jpg' onClick={this.handleVerify} alt='验证码' />
                    <span style={{ fontSize: 12, color: "#787876" }} onClick={this.handleVerify} >点击图片刷新验证码</span>
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              className="verificationCode"
              label={convert('邮箱验证码', this.locale)}
              name="emailCode"
              validateTrigger={['onChange']}
              dependencies={['phone']}
              rules={[{ required: true, message: convert('请输入邮箱验证码', this.locale), },
              {
                // validator: (rule, value) => {
                //   let phonecode = this.formRef.current.getFieldValue("phone")
                //   if (value && phonecode) {
                //     let _this = this
                //     return new Promise((resolve, reject) => {
                //       request(cont.checkPhoneCode, {
                //         body: {
                //           phone: phonecode,
                //           code: value
                //         }
                //       }, _this.props).then(data => {
                //         if (data.code == 0) {
                //           resolve(data.hint && data.hint)
                //         } else if (data.code == 1) {
                //           reject(data.errmsg && data.errmsg)
                //         }
                //       })
                //     })
                //   }
                //   return Promise.resolve()
                // },
              }
              ]}>
              <Input
                placeholder={convert('请输入邮箱验证码', this.locale)}
                addonAfter={
                  <Button type="primary" disabled={liked ? false : true} onClick={() => {
                    this.setState({
                      loading: true
                    }, () => {
                      this.getPhoneCode(this.formRef.current.getFieldValue('phone'), () => {
                        this.setState({
                          loading: false
                        })
                      })
                    })

                  }}>
                    {liked ? convert('获取验证码', this.locale) : convert(`（${count}）秒后重发`, this.locale)}
                  </Button>}
              />
            </Form.Item>
            <Form.Item
              label={convert("新密码", this.locale)}
              name="password"
              rules={[{ required: true, message: convert('请输入您的登录密码!', this.locale) }]}
            >
              <Input.Password placeholder={convert('请输入您的登录密码', this.locale)} />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={convert('确认密码', this.locale)}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: convert('请确认您的登录密码!', this.locale),
                },
                {
                  validator: (rule, value) => {
                    let password = this.formRef.current.getFieldValue("password")
                    if (value && password) {
                      let _this = this
                      return new Promise((resolve, reject) => {
                        if (value === password) {
                          resolve()
                        } else {
                          reject(convert('两次密码不一致,请重输!', this.locale))
                        }
                      })
                    }
                    return Promise.resolve()
                  },
                }
              ]}
            >
              <Input.Password placeholder={convert('请确认您的登录密码', this.locale)} />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ marginTop: '50px' }}>
              <Button className='pri-btn' type="primary" htmlType="submit" style={{ width: '100%' }}>{convert('确定', this.locale)}</Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    );
  }
}
export default withRouter(ForgetPwd);