import * as literature_data from './literature_data'
import Mock from 'mockjs'
Mock.mock('/tag/getList.htm', 'post', literature_data.getTagList)
Mock.mock('/tag/addTag.htm', 'post', literature_data.addTag)
Mock.mock('/tag/getTagByKey.htm', 'post', literature_data.getTagByKey)
Mock.mock('/tag/getTagStatisticsList.htm', 'post', literature_data.getTagStatisticsList)
Mock.mock('/tag/getKeywordStatisticsList.htm', 'post', literature_data.getKeywordStatisticsList)
Mock.mock('/tag/addTagTextLog.htm', 'post', literature_data.addTagTextLog)
Mock.mock('/tag/setTagVisible.htm', 'post', literature_data.setTagVisible)
Mock.mock('/tag/deleteTag.htm', 'post', literature_data.deleteTag)
Mock.mock('/text/saveVersion.htm', 'post', literature_data.saveVersion)
Mock.mock('/text/getVersionList.htm', 'post', literature_data.getVersionList)
Mock.mock('/text/getLastVersion.htm', 'post', literature_data.getLastVersion)
// Mock.mock('/literature/get.htm', 'post', literature_data.get)
// Mock.mock('/literature/search.htm', 'post', literature_data.search)
// Mock.mock('/literature/getRanking.htm', 'post', literature_data.getRanking)