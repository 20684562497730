import React from 'react'

//裁剪压缩图片
export function getCanvas_pic(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
        // 生成canvas画布
        const canvas = document.createElement('canvas');
        // 生成img
        const img = document.createElement('img');
        img.src = reader.result;
        img.onload = () => {
            const ctx = canvas.getContext('2d');
            //原始图片宽度、高度
            let originImageWidth = img.width, originImageHeight = img.height;
            console.log(originImageWidth, originImageHeight)
            //默认最大尺度的尺寸限制在（1920 * 1080）
            let maxWidth = 500, maxHeight = 1500, ratio = maxWidth / maxHeight;
            //目标尺寸
            let targetWidth = originImageWidth, targetHeight = originImageHeight;
            //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
            if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
                //超过最大宽高比例
                if ((originImageWidth / originImageHeight) > ratio) {
                    //宽度取最大宽度值maxWidth,缩放高度
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth));
                } else {
                    //高度取最大高度值maxHeight,缩放宽度
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
                }
            }
            // canvas对图片进行缩放
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            // 清除画布
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            // 绘制图片
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            console.log('afte:r', targetWidth, targetHeight)
            // quality值越小,图像越模糊,默认图片质量为0.92
            const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', 0.92);
            // 去掉URL的头,并转换为byte
            const imageBytes = window.atob(imageDataURL.split(',')[1]);
            // 处理异常,将ascii码小于0的转换为大于0
            const arrayBuffer = new ArrayBuffer(imageBytes.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < imageBytes.length; i++) {
                uint8Array[i] = imageBytes.charCodeAt(i);
            }
            let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
            let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' });
            console.log(file.size / 1024, 'after compress, the file size is : ', (newFile.size / 1024) + "KB");
            callback(newFile);
        };
    });
}